import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {OrdersApi} from "../../services/api/orders-api"
import {OrderModel, OrderSnapshot} from "../order/order"
import {logInfo} from "../../utils/logs"
import {
    OrderCreateParams,
    OrderDeleteParams,
    OrderFindAllParams,
    OrderFindOneParams,
    OrderUpdateParams
} from "../../services/api";

function orderStoreLog(functionName, message) {
    logInfo({
        fileName: "order-store.ts",
        className: "orderStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const OrderStoreModel = types
    .model("OrderStore")
    .props({
        order: types.optional(types.maybeNull(OrderModel), null),
        orders: types.optional(types.array(OrderModel), []),
        count: types.optional(types.number, 0),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveOrder: (orderSnapshot: OrderSnapshot) => {
            try {
                self.order = cast(orderSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    orderStoreLog("saveOrder", e.message)
                }
                // recordError(e)
            }
        },
        saveOrders: (ordersSnapshot: OrderSnapshot[]) => {
            try {
                detach(self.orders)
                self.orders = cast([...ordersSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    orderStoreLog("saveOrder", e.message)
                }
                // recordError(e)
            }
        },
        saveCount: (count: number) => {
            try {
                self.count = count
            } catch (e) {
                if(e instanceof Error) {
                    orderStoreLog("saveCount", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: OrderCreateParams, token: string) {
            self.environment.addAuth(token)
            const orderApi = new OrdersApi(self.environment.api)
            const result = yield orderApi.create(params)


            if (result.kind === "ok") {
                self.saveOrder(result.order)
            } else {
                orderStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: OrderFindAllParams, token: string, saveToMst: boolean) {
            self.environment.addAuth(token)
            const orderApi = new OrdersApi(self.environment.api)
            const result = yield orderApi.findAll(params)


            if (result.kind === "ok") {
                if (saveToMst) {
                    self.saveOrders(result.orders)
                    self.saveCount(result.count)
                }
            } else {
                orderStoreLog("login", result.kind)
            }
            return result
        }),
        customerStatistics: flow(function* (params: { customerEmail: string, locationId?: number }, token: string) {
            self.environment.addAuth(token)
            const orderApi = new OrdersApi(self.environment.api)
            return yield orderApi.customerStatistics(params)
        }),
        summaryStatistics: flow(function* (params: {
            fromDate: string;
            toDate: string;
            storeId?: number;
            lockboxId?: number;
            timeZone?: string;
        }, token: string) {
            self.environment.addAuthWithTimeout(token, 15000)
            const orderApi = new OrdersApi(self.environment.api)
            return yield orderApi.summaryStatistics(params)
        }),
        summaryList: flow(function* (params: {
            fromDate: string;
            toDate: string;
            storeId?: number;
            lockboxId?: number;
            timeZone?: string;
        }, token: string) {
            self.environment.addAuthWithTimeout(token, 15000)
            const orderApi = new OrdersApi(self.environment.api)
            return yield orderApi.summaryList(params)
        }),
        findOne: flow(function* (params: OrderFindOneParams, token: string) {
            self.environment.addAuth(token)
            const orderApi = new OrdersApi(self.environment.api)
            const result = yield orderApi.findOne(params)


            if (result.kind === "ok") {
                self.saveOrder(result.order)
            } else {
                orderStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: OrderUpdateParams, token: string) {
            self.environment.addAuth(token)
            const orderApi = new OrdersApi(self.environment.api)
            const result = yield orderApi.update(params)


            if (result.kind === "ok") {
                self.saveOrder(result.order)
            } else {
                orderStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: OrderDeleteParams, token: string) {
            self.environment.addAuth(token)
            const orderApi = new OrdersApi(self.environment.api)
            return yield orderApi.remove(params)
        }),

    }))

type OrderStoreType = Instance<typeof OrderStoreModel>

export interface OrderStore extends OrderStoreType {
}

type OrderStoreSnapshotType = SnapshotOut<typeof OrderStoreModel>

export interface OrderStoreSnapshot extends OrderStoreSnapshotType {
}

// export const createOrderStoreDefaultModel = () => types.optional(OrderStoreModel, {})
