import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {BasicBundlesApi} from "../../services/api/basic-bundles-api"
import {BasicBundleModel, BasicBundleSnapshot} from "../basic-bundle/basic-bundle"
import {logInfo} from "../../utils/logs"
import {
    BasicBundleCreateParams,
    BasicBundleDeleteParams,
    BasicBundleFindAllParams,
    BasicBundleFindOneParams,
    BasicBundleUpdateParams
} from "../../services/api";

function basicBundleStoreLog(functionName, message) {
    logInfo({
        fileName: "basic-bundle-store.ts",
        className: "basicBundleStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const BasicBundleStoreModel = types
    .model("BasicBundleStore")
    .props({
        basicBundle: types.optional(types.maybeNull(BasicBundleModel), null),
        basicBundles: types.optional(types.array(BasicBundleModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveBasicBundle: (basicBundleSnapshot: BasicBundleSnapshot) => {
            try {
                self.basicBundle = cast(basicBundleSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    basicBundleStoreLog("saveBasicBundle", e.message)
                }
                // recordError(e)
            }
        },
        saveBasicBundles: (basicBundlesSnapshot: BasicBundleSnapshot[]) => {
            try {
                detach(self.basicBundles)
                self.basicBundles = cast([...basicBundlesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    basicBundleStoreLog("saveBasicBundle", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: BasicBundleCreateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.basicBundleImages
            // @ts-ignore
            delete params.prices

            const basicBundleApi = new BasicBundlesApi(self.environment.api)
            const result = yield basicBundleApi.create(params)


            if (result.kind === "ok") {
                self.saveBasicBundle(result.basicBundle)
            } else {
                basicBundleStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: BasicBundleFindAllParams, token: string) {
            self.environment.addAuth(token)
            const basicBundleApi = new BasicBundlesApi(self.environment.api)
            const result = yield basicBundleApi.findAll(params)

            if (result.kind === "ok") {
                self.saveBasicBundles(result.basicBundles)
            } else {
                basicBundleStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: BasicBundleFindOneParams, token: string) {
            self.environment.addAuth(token)
            const basicBundleApi = new BasicBundlesApi(self.environment.api)
            const result = yield basicBundleApi.findOne(params)


            if (result.kind === "ok") {
                self.saveBasicBundle(result.basicBundle)
            } else {
                basicBundleStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: BasicBundleUpdateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.basicBundleImages
            // @ts-ignore
            delete params.prices

            const basicBundleApi = new BasicBundlesApi(self.environment.api)
            const result = yield basicBundleApi.update(params)


            if (result.kind === "ok") {
                self.saveBasicBundle(result.basicBundle)
            } else {
                basicBundleStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: BasicBundleDeleteParams, token: string) {
            self.environment.addAuth(token)
            const basicBundleApi = new BasicBundlesApi(self.environment.api)
            return yield basicBundleApi.remove(params)
        }),

    }))

type BasicBundleStoreType = Instance<typeof BasicBundleStoreModel>

export interface BasicBundleStore extends BasicBundleStoreType {
}

type BasicBundleStoreSnapshotType = SnapshotOut<typeof BasicBundleStoreModel>

export interface BasicBundleStoreSnapshot extends BasicBundleStoreSnapshotType {
}

export const createBasicBundleStoreDefaultModel = () => types.optional(BasicBundleStoreModel, {})
