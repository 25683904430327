import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {SkuModel} from "../sku/sku";
import {VendorModel} from "../vendor/vendor";

/**
 * Rick and Morty character model.
 */
export const CartSkuModel = types.model("CartSku").props({
    cartSkuAmount: types.number,
    cartSkuSku: SkuModel,
    cartSkuParent: types.optional(types.maybeNull(SkuModel), {skuId: -1}),
    cartSkuVendor: types.optional(types.maybeNull(VendorModel), {vendorId: -1}),
    cartSkuPriceId: types.maybeNull(types.number),
    cartSkuFreeItemsAmount: types.optional(types.number, 0),
    cartSkuBundleId: types.maybeNull(types.number),
    cartSkuBundleTitle: types.maybeNull(types.string),
    cartSkuFreeItemsCount: types.maybeNull(types.number)
})

type CartSkuType = Instance<typeof CartSkuModel>
export interface CartSku extends CartSkuType {}
type CartSkuSnapshotType = SnapshotOut<typeof CartSkuModel>
export interface CartSkuSnapshot extends CartSkuSnapshotType {}
