import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  LocationSettingDeleteParams,
  LocationSettingCreateResult,
  LocationSettingUpdateResult,
  LocationSettingUpdateParams,
  LocationSettingFindAllResult,
  LocationSettingDeleteResult,
  LocationSettingFindAllParams,
  LocationSettingFindOneParams,
  LocationSettingCreateParams,
  LocationSettingFindOneResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class LocationSettingsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: LocationSettingCreateParams): Promise<LocationSettingCreateResult> {
    try {
      // const functionName = "login"
      const uri = "location-settings"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const locationSetting = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", locationSetting}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: LocationSettingFindAllParams): Promise<LocationSettingFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "location-settings"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const locationSettings = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", locationSettings}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: LocationSettingFindOneParams): Promise<LocationSettingFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "location-settings/"+params.locationSettingId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const locationSetting = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", locationSetting}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: LocationSettingUpdateParams): Promise<LocationSettingUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "location-settings/"+params.locationSettingId
      delete params.locationSettingId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const locationSetting = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", locationSetting}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: LocationSettingDeleteParams): Promise<LocationSettingDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "location-settings/"+params.locationSettingId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
