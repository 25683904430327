import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard, CCircularProgress,
    CDialog, CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {Refresh} from "@mui/icons-material";
import {InventoryCreateParams, InventoryUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultInventory} from "../../../models/inventory/inventory";
import {isEmpty} from "validate.js";
import {useEffect} from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";
import {Sku} from "../../../models/sku/sku";
import dayjs from "dayjs";
import {getSkuAvailability} from "../../../utils/sku";

interface InventoryDialogData {
    isOpen: boolean,
    data: InventoryCreateParams | InventoryUpdateParams,
    mode: string,
    isLoading: boolean
}

interface ManageDialogData {
    isOpen: boolean,
    parentSkuId: number | null
    skuId: number | null
    locationId: number | null,
    currentCount: number,
    targetCount: number | null,
    isLoading: boolean
}

let currentAuthToken = ''
let currentUserId: number

export const AdminPanelInventory = observer(
    function AdminPanelInventory() {
        const navigate = useNavigate();

        const {authStore, inventoryStore, skuStore, locationStore, storeItemAvailabilityStore} = useStores()
        const {inventories} = inventoryStore
        const {locations} = locationStore
        // const {skus} = skuStore
        // const {auth} = authStore

        const [inventoryDialog, setInventoryDialog] = React.useState<InventoryDialogData>({
            isOpen: false,
            data: defaultInventory,
            mode: 'add',
            isLoading: false
        });
        const [manageDialog, setManageDialog] = React.useState<ManageDialogData>({
            isOpen: false,
            currentCount: 0,
            targetCount: null,
            parentSkuId: null,
            skuId: null,
            locationId: null,
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            sku: '',
            quantity: '',
            location: '',
            status: '',
        })
        const [parentSkus, setParentSkus] = React.useState<Sku[]>([])
        const [skus, setSkus] = React.useState<Sku[]>([])
        const [editableSkus, setEditableSkus] = React.useState<Sku[]>([])

        const handleClickOpen = (mode: string, inventory?: InventoryCreateParams | InventoryUpdateParams) => {
            if (mode === 'manage') {
                setManageDialog({
                    ...manageDialog,
                    isOpen: true,
                    parentSkuId: null,
                    skuId: null,
                    locationId: null,
                    currentCount: 0,
                    targetCount: 0,
                    isLoading: false,
                });
            } else {
                setInventoryDialog({
                    ...inventoryDialog,
                    isOpen: true,
                    mode,
                    data: inventory ? {...inventory} : defaultInventory
                });
            }

        };

        const handleClose = () => {
            setInventoryDialog({isOpen: false, data: defaultInventory, mode: 'add', isLoading: false});
            setManageDialog({
                ...manageDialog,
                isOpen: false,
                skuId: null,
                locationId: null,
                currentCount: 0,
                targetCount: 0,
                isLoading: false,
            });
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setInventoryDialog({
                    ...inventoryDialog,
                    isLoading: true
                });

                if (inventoryDialog.mode === 'add') {
                    await inventoryStore.create({
                        ...inventoryDialog.data,
                        userId: currentUserId
                    }, currentAuthToken)
                } else if (inventoryDialog.mode === 'edit') {
                    await inventoryStore.update({
                        ...inventoryDialog.data,
                        userId: currentUserId
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (inventory) => {
            await inventoryStore.remove({inventoryId: inventory.inventoryId}, currentAuthToken)
            await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(inventoryDialog.data.skuId)) {
                validationObject.sku = "This field is required."
                isValid = false
            }
            if (isEmpty(inventoryDialog.data.skuQuantity)) {
                validationObject.quantity = "This field is required."
                isValid = false
            }
            if (isEmpty(inventoryDialog.data.locationId)) {
                validationObject.location = "This field is required."
                isValid = false
            }
            if (isEmpty(inventoryDialog.data.inventoryStatus)) {
                validationObject.status = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const getLocations = async () => {
            await locationStore.findAll({
                locationTypeId: 2,
                page: 0,
                perPage: 100
            }, currentAuthToken, true)

        }

        const getSkus = async (parentId?: number) => {
            let query = {}
            if (!parentId) {
                query = {
                    skuHasParent: 0,
                    page: 0,
                    perPage: 1000
                }
            } else {
                query = {
                    skuParentId: parentId,
                    page: 0,
                    perPage: 1000,
                    sortBy: 'skuTitle',
                    sortDirection: 'ASC'
                }
            }
            const result = await skuStore.findAll(query, currentAuthToken, false)

            if (result.kind === 'ok') {
                if (!parentId) {
                    setParentSkus(result.skus)
                } else {
                    const sortedByAvailableInventory = result.skus.sort((a, b) => {
                        if (manageDialog.locationId) {
                            return getSkuAvailability(b, manageDialog.locationId) - getSkuAvailability(a, manageDialog.locationId)
                        } else {
                            return a.skuTitle > b.skuTitle ? 1 : -1
                        }
                    })
                    setSkus(sortedByAvailableInventory)
                    setEditableSkus(sortedByAvailableInventory)
                }
            }
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await inventoryStore.findAll({
                page: paginationInfo.page,
                perPage: paginationInfo.perPage,
                sortBy: 'inventoryCreatedAt',
                sortDirection: 'DESC'
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
                currentUserId = authStore.adminAuth.userId
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Inventory';
            ;(async () => {
                checkAuth()
                await refresh()
                await getLocations()
                await getSkus()
            })()

        }, [])

        const rows: GridRowsProp = inventories;

        const columns: GridColDef[] = [
            {
                field: 'col0',
                valueGetter: (params) => {
                    const {sku} = params.row
                    return sku?.skuCode
                },
                headerName: 'Code',
                width: 100,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'col1',
                valueGetter: (params) => {
                    const {sku} = params.row
                    if (sku.skuHasParent === 1) {
                        return `${sku.parentSku?.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
                    } else {
                        return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
                    }
                },
                headerName: 'Sku',
                width: 250,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'skuQuantity',
                headerName: 'Quantity',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col3',
                valueGetter: (params) => {
                    const {location} = params.row
                    return `${location && location.locationName ? location.locationName : ''}`
                },
                headerName: 'Location',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            // {
            //     field: 'inventoryStatus',
            //     headerName: 'Status',
            //     flex: 1,
            //     headerClassName: "pos-table-header",
            //     cellClassName: "pos-table-cell"
            // },
            {
                field: 'inventoryCreatedAt',
                headerName: 'Created At',
                valueGetter: (params) => {
                    const {inventoryCreatedAt} = params.row
                    return `${dayjs(inventoryCreatedAt).format('MMM D, h:mm A')}`
                },
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onRemoveClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleRemove(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}
                                 onClick={onRemoveClick}>Remove</CButton>
                    </>;
                }
            },

        ];

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                Inventory
                            </CTypography>
                            <CButton variant={"contained"} onClick={() => handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={() => handleClickOpen('manage')} sx={{mr: 2}}>
                                Manage
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.inventoryId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={inventoryDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Inventory
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Sku"} select
                                        // error={hasValue(validation.sku)}
                                        // helperText={hasValue(validation.sku) ? validation.sku : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            // setInventoryDialog({
                                            //     ...inventoryDialog,
                                            //     data: {...inventoryDialog.data, skuId: value}
                                            // });
                                            // if (hasValue(validation.sku)) {
                                            //     setValidation({...validation, sku: ''})
                                            // }
                                            getSkus(value)
                                        }} value={inventoryDialog.data.skuId}>
                                {parentSkus.map(sku => {
                                    if (sku.skuHasParent === 1) {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                    } else {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Variant"} select
                                        disabled={skus.length === 0}
                                        error={hasValue(validation.sku)}
                                        helperText={hasValue(validation.sku) ? validation.sku : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setInventoryDialog({
                                                ...inventoryDialog,
                                                data: {...inventoryDialog.data, skuId: value}
                                            });
                                            if (hasValue(validation.sku)) {
                                                setValidation({...validation, sku: ''})
                                            }
                                        }} value={inventoryDialog.data.skuId}>
                                {skus.map(sku => {
                                    if (sku.skuHasParent === 1) {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.skuTitle}</CMenuItem>
                                        // value={sku.skuId}>{sku.parentSku?.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                    } else {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Quantity"}
                                        error={hasValue(validation.quantity)}
                                        type={"number"}
                                        helperText={hasValue(validation.quantity) ? validation.quantity : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setInventoryDialog({
                                                ...inventoryDialog,
                                                data: {...inventoryDialog.data, skuQuantity: Number(event.target.value)}
                                            });
                                            if (hasValue(validation.quantity)) {
                                                setValidation({...validation, quantity: ''})
                                            }
                                        }} value={inventoryDialog.data.skuQuantity}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Location"} select
                                        error={hasValue(validation.location)}
                                        helperText={hasValue(validation.location) ? validation.location : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setInventoryDialog({
                                                ...inventoryDialog,
                                                data: {...inventoryDialog.data, locationId: value}
                                            });
                                            if (hasValue(validation.location)) {
                                                setValidation({...validation, location: ''})
                                            }
                                        }} value={inventoryDialog.data.locationId}>
                                {locations.map(location => {
                                    return <CMenuItem
                                        value={Number(location.locationId)}>{location.locationName}</CMenuItem>
                                })}
                            </CTextField>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {inventoryDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                            size={24}/> : inventoryDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>

                <CDialog open={manageDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px",
                        display: 'flex'
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Manage Inventory
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>

                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Location"} select
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setManageDialog({
                                                ...manageDialog,
                                                locationId: value
                                            });
                                        }} value={manageDialog.locationId}>
                                {locations.map(location => {
                                    return <CMenuItem
                                        value={Number(location.locationId)}>{location.locationName}</CMenuItem>
                                })}
                            </CTextField>
                        </CGrid>
                        {hasValue(manageDialog.locationId) && <CGrid item xs={6}>
                            <CTextField fullWidth label={"Sku"} select
                                // error={hasValue(validation.sku)}
                                // helperText={hasValue(validation.sku) ? validation.sku : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            // setInventoryDialog({
                                            //     ...inventoryDialog,
                                            //     data: {...inventoryDialog.data, skuId: value}
                                            // });
                                            // if (hasValue(validation.sku)) {
                                            //     setValidation({...validation, sku: ''})
                                            // }
                                            setManageDialog({
                                                ...manageDialog,
                                                parentSkuId: value,
                                                skuId: null,
                                            })
                                            getSkus(value)
                                        }} value={manageDialog.parentSkuId}>
                                {parentSkus.map(sku => {
                                    if (sku.skuHasParent === 1) {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                    } else {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>}
                    </CGridContainer>
                    <Divider></Divider>
                    {hasValue(manageDialog.locationId) && hasValue(manageDialog.parentSkuId) && <CBox sx={{maxHeight: '60vh', overflowY: 'scroll'}}>
                        {manageDialog.locationId && skus.map((sku, index) => {
                            const currentAvailability = getSkuAvailability(sku, manageDialog.locationId)
                            return <><CGridContainer spacing={2} p={2}>
                                <CGrid item xs={4} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CTypography ml={2} fontSize={'subtitle1'} fontWeight={'bold'}>
                                        {sku.skuCode} - {sku.vendor?.vendorName} - {sku.skuTitle}
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={4} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CTypography ml={2} fontSize={'body1'} fontWeight={'bold'}>
                                        CurrentAvailability:
                                    </CTypography>
                                    <CTypography flex={1} ml={2} fontSize={'body1'}>
                                        {currentAvailability}
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={4}>
                                    <CTextField fullWidth label={"Target Quantity"}
                                                type={"number"}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const currentEditableSkus = [...editableSkus]
                                                    currentEditableSkus[index]['targetCount'] = Number(event.target.value)
                                                    setEditableSkus(currentEditableSkus)
                                                }} value={
                                        (editableSkus[index]['targetCount'] !== null && editableSkus[index]['targetCount'] !== undefined) ?
                                            editableSkus[index]['targetCount'] :
                                            ''
                                    }/>
                                </CGrid>
                            </CGridContainer>
                            </>
                        })}
                    </CBox>}

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} disabled={!hasValue(manageDialog.locationId) || !hasValue(manageDialog.parentSkuId)} onClick={async ()=>{
                            if (manageDialog.isLoading) {
                                return;
                            }
                            if (!manageDialog.locationId) {
                                return
                            }
                            if (!manageDialog.parentSkuId) {
                                return
                            }
                            setManageDialog({
                                ...manageDialog,
                                isLoading: true
                            })
                            const currentEditableSkus = [...editableSkus]
                            for (let i = 0; i < editableSkus.length; i++) {
                                const editableSku = editableSkus[i];
                                if (editableSku['targetCount'] !== undefined && editableSku['targetCount'] !== null) {
                                    const result = await storeItemAvailabilityStore.findOne({availabilityId:  Number(`${editableSku.skuId}${manageDialog.locationId}`)}, currentAuthToken)
                                    const availableCount = result.availability && result.availability.availabilityCount ? result.availability.availabilityCount : 0
                                    if (result.kind === "ok" ) {
                                        console.log('submit', {
                                            skuId: editableSku.skuId,
                                            skuQuantity: editableSku['targetCount'] - availableCount,
                                            locationId: manageDialog.locationId,
                                            inventoryStatus: 'active',
                                            userId: currentUserId
                                        })
                                        await inventoryStore.create({
                                            skuId: editableSku.skuId,
                                            skuQuantity: editableSku['targetCount'] - availableCount,
                                            locationId: manageDialog.locationId,
                                            inventoryStatus: 'active',
                                            userId: currentUserId
                                        }, currentAuthToken)
                                    }
                                }
                                currentEditableSkus[i]['targetCount'] = undefined
                            }
                            setEditableSkus(currentEditableSkus)
                            setManageDialog({
                                ...manageDialog,
                                isLoading: false
                            })
                            await getSkus(manageDialog.parentSkuId)
                        }} sx={{mr: 4, minWidth: "100px"}}>
                            {manageDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                            size={24}/> : 'Save Changes' }
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
