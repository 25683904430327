import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {CustomersApi} from "../../services/api/customers-api"
import {CustomerModel, CustomerSnapshot} from "../customer/customer"
import {logInfo} from "../../utils/logs"
import {
    CustomerCreateParams,
    CustomerDeleteParams,
    CustomerFindAllParams,
    CustomerFindOneParams,
    CustomerUpdateParams
} from "../../services/api";

function customerStoreLog(functionName, message) {
    logInfo({
        fileName: "customer-store.ts",
        className: "customerStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const CustomerStoreModel = types
    .model("CustomerStore")
    .props({
        customer: types.optional(types.maybeNull(CustomerModel), null),
        customers: types.optional(types.array(CustomerModel), []),
        count: types.optional(types.number, 0),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveCustomer: (customerSnapshot: CustomerSnapshot) => {
            try {
                self.customer = customerSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    customerStoreLog("saveCustomer", e.message)
                }
                // recordError(e)
            }
        },
        saveCustomers: (customersSnapshot: CustomerSnapshot[]) => {
            try {
                self.customers = cast([...customersSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    customerStoreLog("saveCustomer", e.message)
                }
                // recordError(e)
            }
        },
        saveCount: (count: number) => {
            try {
                self.count = count
            } catch (e) {
                if(e instanceof Error) {
                    customerStoreLog("saveCount", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: CustomerCreateParams, token: string) {
            self.environment.addAuth(token)
            const customerApi = new CustomersApi(self.environment.api)
            const result = yield customerApi.create(params)


            if (result.kind === "ok") {
                self.saveCustomer(result.customer)
            } else {
                customerStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: CustomerFindAllParams, token: string) {
            self.environment.addAuth(token)
            const customerApi = new CustomersApi(self.environment.api)
            const result = yield customerApi.findAll(params)


            if (result.kind === "ok") {
                self.saveCustomers(result.customers)
                self.saveCount(result.count)
            } else {
                customerStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: CustomerFindOneParams, token: string) {
            self.environment.addAuth(token)
            const customerApi = new CustomersApi(self.environment.api)
            const result = yield customerApi.findOne(params)


            if (result.kind === "ok") {
                self.saveCustomer(result.customer)
            } else {
                customerStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: CustomerUpdateParams, token: string) {
            self.environment.addAuth(token)
            const customerApi = new CustomersApi(self.environment.api)
            const result = yield customerApi.update(params)


            if (result.kind === "ok") {
                self.saveCustomer(result.customer)
            } else {
                customerStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: CustomerDeleteParams, token: string) {
            self.environment.addAuth(token)
            const customerApi = new CustomersApi(self.environment.api)
            return yield customerApi.remove(params)
        }),

    }))

type CustomerStoreType = Instance<typeof CustomerStoreModel>

export interface CustomerStore extends CustomerStoreType {
}

type CustomerStoreSnapshotType = SnapshotOut<typeof CustomerStoreModel>

export interface CustomerStoreSnapshot extends CustomerStoreSnapshotType {
}

export const createCustomerStoreDefaultModel = () => types.optional(CustomerStoreModel, {})
