import {Price} from "../models/price/price";

export const getSkuPrice =(prices, locationId) => {
    let currentPrice: Price | null = null
    if (!locationId) {
        return null
    }
    if (!prices) {
        return null
    }
    const index = prices.findIndex(price => locationId === price.locationId)
    if (index > -1) {
        currentPrice = prices[index]
    }
    if (currentPrice && currentPrice.priceAmount === 0) {
        return null
    }
    return currentPrice
}

export const getSkuAvailability = (sku, locationId) => {
    // console.log('getSkuAvailability', sku, locationId)
    let currentAvailability = 0;
    if (sku.storeItemAvailabilities && sku.storeItemAvailabilities.length > 0) {
        const availabilityIndex = sku.storeItemAvailabilities.findIndex(availability => availability.locationId === locationId)
        if (availabilityIndex > -1) {
            const count = sku.storeItemAvailabilities[availabilityIndex].availabilityCount
            if (count && count > 0) {
                currentAvailability = count
            } else {
                currentAvailability = 0
            }
        } else {
            currentAvailability = 0
        }
    } else {
        currentAvailability = 0
    }
    return currentAvailability
}

export function getSkuCompleteName(sku) {
    if (sku.skuHasParent === 1) {
        if (sku.skuCode) {
            return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
        } else {
            return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
        }
    } else {
        if (sku.skuCode) {
            return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
        } else {
            return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
        }
    }
}