import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CContainer,
    CGridContainer,
    CTypography,
    ProductCard,
    DisclaimerDialog,
    DeliveryMethodDialog,
    Footer,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {primary} from "../../../theme";
import {hasValue} from "../../../utils/empty-check";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {ScrollDrag} from "../../../components/scroll-drag";
import ReactGA from "react-ga4";
import {getSkuPrice} from "../../../utils/sku";
import {Sku} from "../../../models/sku/sku";
import {BundleCard} from "../../../components/bundle-card";
import {isTodayBetweenDates} from "../../../utils/date-time";
import PageSlider from "../../../components/page-slider/page-slider";

export const StoreProducts = observer(
    function StoreProducts() {
        const navigate = useNavigate();
        const {skuStore, categoryStore, locationStore, cartStore} = useStores()
        const {categorySkus} = categoryStore

        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, locationInfoState} = guestAuth
        const [isGettingCategorySkus, setIsGettingCategorySkus] = React.useState(!hasValue(categorySkus))
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState<{
            isOpen: boolean;
            skuTitle: null | string;
            vendorName: null | undefined | string
        }>({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const [gridItemWidth, setGridItemWidth] = React.useState(200)

        const init = async () => {
            const gaItems: any[] = []

            if (!hasValue(categorySkus)) {
                setIsGettingCategorySkus(true)
            } else {
                setIsGettingCategorySkus(false)
            }
            const {categories} = await categoryStore.findAll({
                sortBy: 'categoryId',
                categoryStatus: 'active'
            }, guestAuthToken)
            const currentCategorySkus: any[] = []
            for (let j = 0; j < categories.length; j++) {
                const category = categories[j]
                const {skus} = await skuStore.findAll({
                    page: 0,
                    perPage: 20,
                    skuHasParent: 0,
                    categoryId: category.categoryId,
                    skuStatus: 'active'
                }, guestAuthToken, true)

                const checkedSkus: Sku[] = []

                if (skus) {
                    skus.forEach(sku=>{
                        if (getSkuPrice(sku.prices, locationStore.currentLocation?.locationId) !== null) {
                            checkedSkus.push(sku)
                        }
                    })
                }
                if (checkedSkus) {
                    // if (skus.length < 6) {
                    //     const lengthDiff = 6 - skus.length
                    //     for (let i = 0; i < lengthDiff; i++) {
                    //         skus.push({type: 'placeholder'})
                    //     }
                    // }
                    currentCategorySkus.push({
                        category,
                        skus: checkedSkus
                    })
                    checkedSkus.forEach(sku=>{
                        gaItems.push({
                            item_id: sku.skuId,
                            item_name: sku.skuHasParent ? sku.skuCode + ' - ' + sku.vendor?.vendorName + ' - ' + sku.parentSku?.skuTitle + ' - ' + sku.skuTitle : sku.vendor?.vendorName + ' - ' + sku.skuTitle,
                            price: Number(getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                        })
                    })
                }
            }
            categoryStore.saveCategorySkus(currentCategorySkus)

            setIsGettingCategorySkus(false)

            ReactGA.event("view_item_list", {
                item_list_id: "main_screen",
                item_list_name: "Main Screen",
                items: gaItems
            })

            console.log("ReactGA", "StoreProductScreen", "view_item_list", {
                item_list_id: "main_screen",
                item_list_name: "Main Screen",
                items: gaItems
            })
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        // useEffect(() => {
        //     ;(async () => {
        //         if (guestAuthState === 'loaded') {
        //         }
        //     })()
        // }, [guestAuthToken, guestAuthState]);

        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Products`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Products | ${locationInfo.locationName}`;
                    await init()
                }
            })()

        }, [locationInfo, locationInfoState])

        const handleCategoryClick = (category) => {
            navigate(`/store/category/${category.categoryName}`)
        }

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        let basicBundlesCount = 0

        if (locationInfoState === "loaded" && locationInfo && locationInfo.basicBundles.length > 0) {
            locationInfo.basicBundles.forEach(basicBundle => {
                if (basicBundle.basicBundleDeleted === 0 && basicBundle.basicBundleStatus === 'active' && isTodayBetweenDates(basicBundle.basicBundleValidFrom, basicBundle.basicBundleValidTo)) {
                    basicBundlesCount++
                }
            })
        }

        let promotionalBannersCount = 0
        const slides: any[] = []

        if (locationInfoState === "loaded" && locationInfo && locationInfo.promotionalBanners.length > 0) {
            locationInfo.promotionalBanners.forEach(promotionalBanner => {
                if (promotionalBanner.promotionalBannerDeleted === 0 && promotionalBanner.promotionalBannerStatus === 'active' && isTodayBetweenDates(promotionalBanner.promotionalBannerValidFrom, promotionalBanner.promotionalBannerValidTo)) {
                    promotionalBannersCount++
                    slides.push({
                        title: promotionalBanner.promotionalBannerTitle,
                        description: promotionalBanner.promotionalBannerDescriptions,
                        bgColor: promotionalBanner.promotionalBannerBgColor,
                        titleColor: promotionalBanner.promotionalBannerTitleColor,
                        descriptionColor: promotionalBanner.promotionalBannerSubtitleColor,
                    })
                }
            })
        }

        return (
            <>
                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column',}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}
                            // onLogin={()=>init()}
                        />
                        {promotionalBannersCount > 0 && <CBox mx={-1.5} mb={-2}>
                            <PageSlider slides={slides}></PageSlider>
                        </CBox>}

                        {/*<CBox sx={{*/}
                        {/*    width: '100%',*/}
                        {/*    height: '300px',*/}
                        {/*    backgroundImage: `url(https://images.pexels.com/photos/2078008/pexels-photo-2078008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,*/}
                        {/*    backgroundSize: 'cover',*/}
                        {/*    borderRadius: '37px'*/}
                        {/*}}>*/}

                        {/*    <CTypography variant={'h3'}>*/}

                        {/*    </CTypography>*/}
                        {/*</CBox>*/}
                        {/*<CGridContainer>*/}
                        {/*    <CFormControl sx={{width: "100%", my: 8}}>*/}
                        {/*<COutlinedInputWhiteBorder fullWidth placeholder={"Search ..."}*/}
                        {/*                           endAdornment={*/}
                        {/*                               <SearchOutlined*/}
                        {/*                                   sx={{color: "white"}}>*/}

                        {/*                               </SearchOutlined>*/}
                        {/*                           }*/}
                        {/*                           value={searchValue}*/}
                        {/*                           onChange={({target}) => {*/}
                        {/*                               setSearchValue(target.value)*/}
                        {/*                               statelessSearchValue = target.value*/}
                        {/*                               searchSku()*/}
                        {/*                           }}*/}
                        {/*/>*/}

                        {/*    </CFormControl>*/}
                        {/*</CGridContainer>*/}
                        {isGettingCategorySkus && <CGridContainer sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '50vh'
                        }}>
                            <CCircularProgress sx={{mt: 12}}></CCircularProgress>

                        </CGridContainer>}
                        {!isGettingCategorySkus && <ScrollDrag sx={{
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            width: "100%",
                            mb: 2,
                            scrollbarWidth: 'none'
                        }}>
                            {categorySkus.map((categorySku, index) => {

                                return <CBox display={'inline-block'} my={3} mr={2}
                                      width={gridItemWidth} height={160}>
                                    <CCard
                                        key={`${categorySku.category.categoryId}${index}_category_tile`}
                                        sx={{
                                            position: 'relative',
                                            backgroundImage: `url(${categorySku.category.categoryImage})`,
                                            height: '100%',
                                            width: '100%',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 4px 12px",
                                            '&:hover': {
                                                '&:before': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                                }
                                            },
                                            '&:before': {
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                height: '100%',
                                                width: '100%',
                                                content: "''",
                                                transition: 'background-color 0.2s ease-in-out',
                                                backgroundColor: 'rgba(0, 0, 0, 0.3)' /* adjust the alpha value to control the darkness */
                                            }
                                        }}
                                        onClick={() => handleCategoryClick(categorySku.category)}
                                    >
                                        <CTypography color={'white'} fontWeight={'bold'} zIndex={1}>
                                            {categorySku.category.categoryName}
                                        </CTypography>
                                    </CCard>
                                </CBox>
                            })
                            }
                        </ScrollDrag>}
                        {
                            !isGettingCategorySkus && basicBundlesCount > 0 && locationInfo.locationSetting.locationSettingBundlesEnabled === 1 && <>
                                <CGridContainer>
                                    <CTypography mt={0} ml={1} variant={'subtitle1'} fontWeight={'bold'}>
                                        Bundles
                                        <CBox
                                            sx={{
                                                height: 3,
                                                borderRadius: 2,
                                                backgroundColor: primary['500'],
                                                width: '100%'
                                            }}
                                        ></CBox>
                                    </CTypography>

                                </CGridContainer>
                                <CGridContainer mb={4}>
                                    {locationInfo.basicBundles.map((basicBundle, index) => {
                                        if
                                        (
                                            basicBundle.basicBundleDeleted === 1 ||
                                            basicBundle.basicBundleStatus === 'inactive' ||
                                            !isTodayBetweenDates(basicBundle.basicBundleValidFrom, basicBundle.basicBundleValidTo)
                                        ) {
                                            return <></>
                                        }
                                        return <BundleCard
                                            onRef={(ref) => {
                                                if (ref?.offsetWidth) {
                                                    setGridItemWidth(ref?.offsetWidth - 16)
                                                }
                                            }}
                                            key={`${basicBundle.basicBundleId}${index}_bundle_card${new Date().getTime()}`}
                                            md={4}
                                            xs={12}
                                            showInfoButton={false}
                                            imageUrl={hasValue(basicBundle.basicBundleSku.skuImages) ? basicBundle.basicBundleSku.skuImages[0].skuImageLink : ''}
                                            title={basicBundle.basicBundleTitle}
                                            validFrom={basicBundle.basicBundleValidFrom}
                                            validTo={basicBundle.basicBundleValidTo}
                                            descriptions={basicBundle.basicBundleDescriptions}
                                            onClick={() => {
                                                if (!hasValue(cartStore.cart?.cartLockboxId)) {
                                                    setDeliveryMethodDialog({
                                                        isOpen: true,
                                                        vendorName: basicBundle.basicBundleSku?.vendor?.vendorName,
                                                        skuTitle: basicBundle.basicBundleSku?.skuTitle
                                                    })
                                                } else {
                                                    navigate(`/store/product/${basicBundle.basicBundleSku.vendor?.vendorName}-${basicBundle.basicBundleSku.skuTitle}`)
                                                }
                                            }}
                                        />
                                    })}
                                    {/*categorySku.skus.map((sku, index) => sku?.type !== 'placeholder' ? <ProductCard
                                            key={`${sku.skuId}${index}_prd_card${new Date().getTime()}`}
                                            md={2}
                                            showInfoButton={false}
                                            imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                            title={sku.skuTitle}
                                            vendorName={sku.vendor?.vendorName}
                                            price={`$${sku.skuDefaultPrice ? sku.skuDefaultPrice.toFixed(2) : "0.00"}`}
                                            onClick={() => {
                                                if (cartStore.cart?.cartDeliveryMethodSelected !== true) {
                                                    setDeliveryMethodDialog({
                                                        isOpen: true,
                                                        vendorName: sku?.vendor?.vendorName,
                                                        skuTitle: sku?.skuTitle
                                                    })
                                                } else {
                                                    // addSku(sku)
                                                    navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)
                                                }

                                            }}
                                        /> : <CGrid xs={6} md={2} item sx={{
                                            height: "256px",
                                            paddingRight: 2,
                                            paddingTop: 2
                                        }}>
                                            <CCard variant={'outlined'} sx={{
                                                boxShadow: 'none',
                                                position: "relative",
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: "column"
                                            }}>
                                                <CTypography sx={{opacity: 0.5}}>
                                                    Upcoming
                                                </CTypography>
                                            </CCard>
                                        </CGrid>)
                                        */}
                                </CGridContainer>
                            </>
                        }
                        {
                            !isGettingCategorySkus && categorySkus.map((categorySku, index) => {
                                if (categorySku.skus.length === 0) {
                                    return <></>
                                }
                                return <>
                                    <CGridContainer key={`${categorySku.category.categoryId}${index}_1_category_sku_title`}>
                                        <CTypography mt={0} ml={1} variant={'subtitle1'} fontWeight={'bold'}>
                                            {categorySku.category.categoryName}
                                            <CBox
                                                sx={{
                                                    height: 3,
                                                    borderRadius: 2,
                                                    backgroundColor: primary['500'],
                                                    width: '100%'
                                                }}
                                            ></CBox>
                                        </CTypography>

                                    </CGridContainer>
                                    <CGridContainer mb={4} key={`${categorySku.category.categoryId}${index}_2`}>
                                        {categorySku.skus.map((sku, index) => {
                                            if (getSkuPrice(sku.prices, locationStore.currentLocation?.locationId) === null) {
                                                return <></>
                                            }
                                            return <ProductCard
                                                onRef={(ref) => {
                                                    if (ref?.offsetWidth) {
                                                        setGridItemWidth(ref?.offsetWidth - 16)
                                                    }
                                                }}
                                                key={`${sku.skuId}${index}_prd_card${new Date().getTime()}`}
                                                md={2}
                                                showInfoButton={false}
                                                imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                                title={sku.skuTitle}
                                                vendorName={sku.vendor?.vendorName}
                                                price={`$${getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount ? getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount?.toFixed(2) : "0.00"}`}
                                                onClick={() => {
                                                    if (!hasValue(cartStore.cart?.cartLockboxId)) {
                                                        setDeliveryMethodDialog({
                                                            isOpen: true,
                                                            vendorName: sku?.vendor?.vendorName,
                                                            skuTitle: sku?.skuTitle
                                                        })
                                                    } else {
                                                        navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)
                                                    }
                                                }}
                                            />
                                        })}
                                        {/*categorySku.skus.map((sku, index) => sku?.type !== 'placeholder' ? <ProductCard
                                            key={`${sku.skuId}${index}_prd_card${new Date().getTime()}`}
                                            md={2}
                                            showInfoButton={false}
                                            imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                            title={sku.skuTitle}
                                            vendorName={sku.vendor?.vendorName}
                                            price={`$${sku.skuDefaultPrice ? sku.skuDefaultPrice.toFixed(2) : "0.00"}`}
                                            onClick={() => {
                                                if (cartStore.cart?.cartDeliveryMethodSelected !== true) {
                                                    setDeliveryMethodDialog({
                                                        isOpen: true,
                                                        vendorName: sku?.vendor?.vendorName,
                                                        skuTitle: sku?.skuTitle
                                                    })
                                                } else {
                                                    // addSku(sku)
                                                    navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)
                                                }

                                            }}
                                        /> : <CGrid xs={6} md={2} item sx={{
                                            height: "256px",
                                            paddingRight: 2,
                                            paddingTop: 2
                                        }}>
                                            <CCard variant={'outlined'} sx={{
                                                boxShadow: 'none',
                                                position: "relative",
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: "column"
                                            }}>
                                                <CTypography sx={{opacity: 0.5}}>
                                                    Upcoming
                                                </CTypography>
                                            </CCard>
                                        </CGrid>)
                                        */}
                                    </CGridContainer>
                                    {categorySku.skus.length > 21 &&
                                        <CGridContainer mt={2} display={'flex'} justifyContent={'flex-end'}
                                                        key={`${categorySku.category.categoryId}${index}_3`}>
                                            <CButton sx={{mr: 2}} variant={'outlined'}
                                                     onClick={() => handleCategoryClick(categorySku.category)}>
                                                Show All
                                            </CButton>
                                        </CGridContainer>
                                    }
                                </>


                            })
                        }
                        {
                            /*
                            <CGridContainer flex={1}>
                            <CGrid item xs={12} md={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CGrid item sm={12} md={0} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CBox
                                        sx={{width: "100%", height: "90vh", display: "flex", flexDirection: "column"}}>
                                        {!isGettingSkus ? <CBox sx={{
                                            width: "100%",
                                            flex: 1,
                                            overflowY: "scroll",
                                            WebkitOverflowScrolling: 'touch'
                                        }}>
                                            <style>
                                                {`
        ::-webkit-scrollbar {
          display: none;
        }
        `}
                                            </style>
                                            <CGridContainer sx={{paddingLeft: 2}}>
                                                {skus.map((sku, index) => <ProductCard
                                                    key={index}
                                                    md={2}
                                                    showInfoButton={false}
                                                    imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                                    title={sku.skuTitle}
                                                    vendorName={sku.vendor?.vendorName}
                                                    price={`$${sku.skuDefaultPrice ? sku.skuDefaultPrice.toFixed(2) : "0.00"}`}
                                                    onClick={() => {
                                                        // addSku(sku)
                                                        navigate(`/store/product/${sku.skuTitle}-${sku.vendor?.vendorName}`)

                                                    }}
                                                />)}
                                            </CGridContainer>

                                        </CBox> : <CBox
                                            sx={{
                                                width: "100%",
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            <CCircularProgress sx={{color: "white"}}
                                                               size={32}/>
                                        </CBox>}
                                        <CBox sx={{
                                            width: "100%",
                                            height: "90px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-end",
                                            mb: "32px"
                                        }}>
                                            <CBox>
                                                {currentCategoryId === -1 && <CBox
                                                    sx={{
                                                        height: "7px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#083B1C"
                                                    }}>

                                                </CBox>}
                                                <CButton
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: "400",
                                                        padding: 0
                                                    }}
                                                    onClick={async () => {
                                                        setCurrentCategoryId(-1)
                                                        statelessCategoryId = -1
                                                        searchSku()
                                                    }}
                                                >All</CButton>
                                            </CBox>
                                            {categories.map((category, index) => <CBox>
                                                {currentCategoryId === category.categoryId &&
                                                    <CBox key={index} sx={{
                                                        height: "7px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#083B1C"
                                                    }}>

                                                    </CBox>}
                                                <CButton sx={{
                                                    fontSize: "20px",
                                                    color: "#757575",
                                                    fontWeight: "400",
                                                    padding: 0,
                                                    ml: 2
                                                }}
                                                         onClick={async () => {
                                                             setCurrentCategoryId(category.categoryId)
                                                             statelessCategoryId = category.categoryId
                                                             searchSku()
                                                         }}
                                                >{category.categoryName}</CButton></CBox>)}
                                        </CBox>
                                    </CBox>


                                </CGrid>
                                <CGrid item xs={0} md={0} sx={{
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: 'none',
                                        lg: 'none',
                                        xl: 'none'
                                    },
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CCard sx={{
                                        width: "100%",
                                        height: '90vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <CartContent/>
                                    </CCard>
                                </CGrid>

                            </CGrid>
                        </CGridContainer>

                             */
                        }

                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }

                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );

    }
)
