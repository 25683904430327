import {Location} from "../models/location/location";

export function calcCrow({lat1, lon1, lat2, lon2}, inMiles)
{
    const R = 6371; // km
    const dLat = toRad(lat2-lat1);
    const dLon = toRad(lon2-lon1);
    const lat1Rad = toRad(lat1);
    const lat2Rad = toRad(lat2);

    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c;
    if (inMiles) {
        return (d * 0.621371).toFixed(2)
    }
    return d;
}

// Converts numeric degrees to radians
export function toRad(Value)
{
    return Value * Math.PI / 180;
}

export function getLocationFullAddress(location: Location | null) {
    if (!location) {
        return ''
    }
    return location.locationAddress1 + ', ' + location.locationCity + ', ' + location.locationState + ' ' + location.locationZip
}