import Autocomplete from '@mui/material/Autocomplete';
import {styled} from '@mui/material/styles';
import {observer} from "mobx-react-lite";
import {useState} from "react";
import {Sku} from "../../../models/sku/sku";
import {useStores} from "../../../models";
import {CCircularProgress} from "../progress";
import {SearchOutlined} from "@mui/icons-material";
import {CGrid, CGridContainer} from "../grid";
import {CTypography} from "../typography";
import * as React from "react";
import {CTextField} from "../text-field";
import {useNavigate} from "react-router-dom";

export const CAutocomplete = styled(Autocomplete)(
  (/* { theme } */) => ({
  }),
);

interface ProductAutocompleteProps {
  authToken: string
}

export const ProductAutocomplete = observer((props: ProductAutocompleteProps) => {

  const [options, setOptions] = useState<Sku[]>([]);
  // const [selectedOption, setSelectedOption] = useState<Sku | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isGettingOptions, setIsGettingOptions] = useState(false);

  const {skuStore} = useStores()

    const navigate = useNavigate();

    const fetchOptions = async (value) => {
      if(value.length > 1) {
          setIsGettingOptions(true)
          const {skus} = await skuStore.search({keyword: value}, props.authToken)

          // console.log('fetchOptions', skus)
          setOptions(skus);
          setIsGettingOptions(false)
      } else {
          setOptions([])
      }
  }


  return <CAutocomplete
      // disablePortal
      options={options}
      // value={selectedOption}
      // @ts-ignore
      getOptionLabel={(option)=>`${String(option.category.categoryName)} - ${String(option.vendor.vendorName)} - ${String(option.skuTitle)}`}
      onChange={(event, newValue) => {
        // setSelectedOption(newValue);
        // Do something with the selected option
          // @ts-ignore
          navigate(`/store/product/${newValue.vendor?.vendorName}-${newValue.skuTitle}`)

      }}
      inputValue={searchValue}
      fullWidth
      onInputChange={(event, newInputValue) => {
        setSearchValue(newInputValue);
        fetchOptions(newInputValue);
      }}
      renderInput={(params) => (
          // CWhiteTextField
          <CTextField
              {...params}
              fullWidth
              size={'small'}
              placeholder="Search ..."
              InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                      <>
                          {isGettingOptions ? (
                              <CCircularProgress
                                  // sx={{ color: "white" }}
                                  size={16} />
                          ) : (
                              <SearchOutlined
                                  // sx={{ color: 'white' }}
                              />
                          )}
                      </>
                  ),
              }}
          />
      )}
      renderOption={(props, option) => (
          <li {...props} style={{zIndex: 999}}>
            <CGridContainer>
              <CGrid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <img
                    // @ts-ignore
                    src={option.skuImages && option.skuImages.length >= 1 ? String(option.skuImages[0].skuImageLink) : ''}
                    style={{
                      width: 56,
                      height: 56,
                        marginRight: 16
                    }}/>
                  {/*@ts-ignore*/}
                  <CTypography flex={1} variant="body1">{option.category.categoryName} | {option.vendor.vendorName} - {option.skuTitle}</CTypography>

              </CGrid>
            </CGridContainer>
          </li>
      )}
  />
})