import * as React from "react";
import {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CIconButton,
    CMenuItem,
    CTextField,
    CTypography,
    Divider
} from "../../../components";
import {InventoryCreateParams, InventoryUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultInventory} from "../../../models/inventory/inventory";
import {isEmpty} from "validate.js";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";
import {Sku} from "../../../models/sku/sku";
import {getSkuAvailability} from "../../../utils/sku";
import {Refresh} from "@mui/icons-material";

interface InventoryDialogData {
    isOpen: boolean,
    data: InventoryCreateParams | InventoryUpdateParams,
    mode: string,
    isLoading: boolean
}

interface ManageDialogData {
    isOpen: boolean,
    parentSkuId: number | null
    skuId: number | null
    locationId: number | null,
    currentCount: number,
    targetCount: number | null,
    isLoading: boolean
}

let currentAuthToken = ''
let currentUserId: number
let locationIds: number[] = []

let refreshInterval: any

let skuNewValues: any[] = []

export const StorePanelInventory = observer(
    function StorePanelInventory() {
        const navigate = useNavigate();

        const {authStore, inventoryStore, skuStore, locationStore, storeItemAvailabilityStore} = useStores()
        const {locations} = locationStore
        // const {auth} = authStore

        const [inventoryDialog, setInventoryDialog] = React.useState<InventoryDialogData>({
            isOpen: false,
            data: defaultInventory,
            mode: 'add',
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [isGettingSkus, setIsGettingSkus] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            sku: '',
            quantity: '',
            location: '',
            status: '',
        })
        const [parentSkus, setParentSkus] = React.useState<Sku[]>([])
        const [skus, setSkus] = React.useState<Sku[]>([])
        const [manageDialog, setManageDialog] = React.useState<ManageDialogData>({
            isOpen: false,
            currentCount: 0,
            targetCount: null,
            parentSkuId: null,
            skuId: null,
            locationId: null,
            isLoading: false
        });
        const [editableSkus, setEditableSkus] = React.useState<Sku[]>([])
        const [sortBy, setSortBy] = React.useState("Name") // Name, Availability
        const [sortByDirection, setSortByDirection] = React.useState("ASC") // ASC, DESC

        // const handleClickOpen = (mode: string, inventory?: InventoryCreateParams | InventoryUpdateParams) => {
        //     if (mode === 'manage') {
        //         setManageDialog({
        //             ...manageDialog,
        //             isOpen: true,
        //             parentSkuId: null,
        //             skuId: null,
        //             locationId: null,
        //             currentCount: 0,
        //             targetCount: 0,
        //             isLoading: false,
        //         });
        //     } else {
        //         setInventoryDialog({
        //             ...inventoryDialog,
        //             isOpen: true,
        //             mode,
        //             data: inventory ? {...inventory} : defaultInventory
        //         });
        //     }
        // };

        const handleClose = () => {
            setInventoryDialog({isOpen: false, data: defaultInventory, mode: 'add', isLoading: false});
            setManageDialog({
                ...manageDialog,
                isOpen: false,
                skuId: null,
                locationId: null,
                currentCount: 0,
                targetCount: 0,
                isLoading: false,
            });
        };

        const storeNewValue = (skuId, value) => {
            const index = skuNewValues.findIndex((item) => item?.skuId === skuId)
            if (index > -1) {
                skuNewValues[index].targetCount = value
            } else {
                skuNewValues.push({skuId, targetCount: value})
            }
        }

        const restoreNewValues = (skusList) => {
            return skusList.map((item) => {
                const index = skuNewValues.findIndex((newItem) => newItem?.skuId === item.skuId)
                if (index > -1) {
                    item.targetCount = skuNewValues[index].targetCount
                }
                return item
            })
        }

        const handleManageSaveChangesClick = async () => {
            if (manageDialog.isLoading) {
                return;
            }
            if (!manageDialog.locationId) {
                return
            }
            if (!manageDialog.parentSkuId) {
                return
            }
            setManageDialog({
                ...manageDialog,
                isLoading: true
            })
            const currentEditableSkus = [...editableSkus]
            for (let i = 0; i < editableSkus.length; i++) {
                const editableSku = editableSkus[i];
                if (editableSku['targetCount'] !== undefined && editableSku['targetCount'] !== null) {
                    const result = await storeItemAvailabilityStore.findOne({availabilityId: Number(`${editableSku.skuId}${manageDialog.locationId}`)}, currentAuthToken)
                    const availableCount = result.availability && result.availability.availabilityCount ? result.availability.availabilityCount : 0
                    if (result.kind === "ok") {
                        console.log('submit', {
                            skuId: editableSku.skuId,
                            skuQuantity: editableSku['targetCount'] - availableCount,
                            locationId: manageDialog.locationId,
                            inventoryStatus: 'active',
                            userId: currentUserId
                        })
                        await inventoryStore.create({
                            skuId: editableSku.skuId,
                            skuQuantity: editableSku['targetCount'] - availableCount,
                            locationId: manageDialog.locationId,
                            inventoryStatus: 'active',
                            userId: currentUserId
                        }, currentAuthToken)
                    }
                }
                currentEditableSkus[i]['targetCount'] = undefined
            }
            setEditableSkus(currentEditableSkus)
            setManageDialog({
                ...manageDialog,
                isLoading: false
            })
            skuNewValues = []
            await getSkus(manageDialog.parentSkuId)
        }

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setInventoryDialog({
                    ...inventoryDialog,
                    isLoading: true
                });

                if (inventoryDialog.mode === 'add') {
                    await inventoryStore.create({
                        ...inventoryDialog.data,
                        userId: currentUserId
                    }, currentAuthToken)
                } else if (inventoryDialog.mode === 'edit') {
                    await inventoryStore.update({
                        ...inventoryDialog.data,
                        userId: currentUserId
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        // const handleRemove = async (inventory) => {
        //     await inventoryStore.remove({inventoryId: inventory.inventoryId}, currentAuthToken)
        //     await refresh()
        // }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(inventoryDialog.data.skuId)) {
                validationObject.sku = "This field is required."
                isValid = false
            }
            if (isEmpty(inventoryDialog.data.skuQuantity)) {
                validationObject.quantity = "This field is required."
                isValid = false
            }
            if (isEmpty(inventoryDialog.data.locationId)) {
                validationObject.location = "This field is required."
                isValid = false
            }
            if (isEmpty(inventoryDialog.data.inventoryStatus)) {
                validationObject.status = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const getLocations = async () => {
            locationIds = []
            const result = await locationStore.findOne({
                locationId: Number(authStore.storeAdminAuth?.userLocationId)
            }, currentAuthToken, false)

            if (result.kind === 'ok') {
                locationStore.saveLocations([...result.location.lockboxes])
                result.location.lockboxes.forEach(location => {
                    locationIds.push(location.locationId)
                })
                if (result.location.lockboxes.length > 0) {
                    const value = result.location.lockboxes[0].locationId
                    setManageDialog({
                        ...manageDialog,
                        locationId: value
                    });
                }
            }

            console.log('getLocations', locationIds)

        }

        const getSkus = async (parentId?: number) => {
            console.log('getSkus', parentId)
            let query;
            if (!parentId) {
                query = {
                    skuHasParent: 0,
                    page: 0,
                    perPage: 1000
                }
            } else {
                query = {
                    skuParentId: parentId,
                    page: 0,
                    perPage: 1000,
                    sortBy: 'skuTitle',
                    sortDirection: 'ASC'
                }
            }
            const result = await skuStore.findAll(query, currentAuthToken, false)

            if (result.kind === 'ok') {
                if (!parentId) {
                    setParentSkus(result.skus)
                } else {
                    if (refreshInterval) {
                        clearInterval(refreshInterval)
                    }
                    sortAndSetSkus([...result.skus])
                    refreshInterval = setInterval(() => {
                        if (!manageDialog.isLoading) {
                            console.log('refresh')
                            getSkus(parentId)
                        }
                    }, 30000);
                }
            }
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await inventoryStore.findAll({
                locationIds,
                page: paginationInfo.page,
                perPage: paginationInfo.perPage,
                sortBy: 'inventoryCreatedAt',
                sortDirection: 'DESC'
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
                currentUserId = authStore.storeAdminAuth.userId
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            skuNewValues = []
            document.title = 'Store Admin Panel - Inventory';
            ;(async () => {
                checkAuth()
                await getLocations()
                await getSkus()
                await refresh()
            })()

            return ()=> {
                if (refreshInterval) {
                    clearInterval(refreshInterval)
                }
            }

        }, [])

        const sortAndSetSkus = (currentSkus) => {
            let sortedByAvailableInventory = [...currentSkus];
            if (sortBy === "Availability") {
                if (sortByDirection === "ASC") {
                    sortedByAvailableInventory = sortedByAvailableInventory.sort((a, b) => {
                        if (manageDialog.locationId) {
                            return getSkuAvailability(a, manageDialog.locationId) - getSkuAvailability(b, manageDialog.locationId)
                        } else {
                            return `${a.skuCode} - ${a.vendor?.vendorName} - ${a.skuTitle}`.localeCompare(`${b.skuCode} - ${b.vendor?.vendorName} - ${b.skuTitle}`)
                        }
                    })
                } else {
                    sortedByAvailableInventory = sortedByAvailableInventory.sort((a, b) => {
                        if (manageDialog.locationId) {
                            return getSkuAvailability(b, manageDialog.locationId) - getSkuAvailability(a, manageDialog.locationId)
                        } else {
                            return `${b.skuCode} - ${b.vendor?.vendorName} - ${b.skuTitle}`.localeCompare(`${a.skuCode} - ${a.vendor?.vendorName} - ${a.skuTitle}`)
                        }
                    })
                }
                console.log('useEffect', JSON.stringify(sortedByAvailableInventory.map((a)=>getSkuAvailability(a, manageDialog.locationId)), null, 2))
            } else if (sortBy === "Name") {
                if (sortByDirection === "ASC") {
                    sortedByAvailableInventory = sortedByAvailableInventory.sort((a, b) => {
                        return `${a.skuCode} - ${a.vendor?.vendorName} - ${a.skuTitle}`.localeCompare(`${b.skuCode} - ${b.vendor?.vendorName} - ${b.skuTitle}`)
                    })
                } else {
                    sortedByAvailableInventory = sortedByAvailableInventory.sort((a, b) => {
                        return `${b.skuCode} - ${b.vendor?.vendorName} - ${b.skuTitle}`.localeCompare(`${a.skuCode} - ${a.vendor?.vendorName} - ${a.skuTitle}`)
                    })
                }
                console.log('useEffect', JSON.stringify(sortedByAvailableInventory.map((a)=>`${a.skuCode} - ${a.vendor?.vendorName} - ${a.skuTitle}`), null, 2))
            }
            setSkus(sortedByAvailableInventory)
            setEditableSkus(restoreNewValues(sortedByAvailableInventory))
        }

        useEffect(() => {
            console.log('useEffect', sortBy, sortByDirection)
            sortAndSetSkus(skus)
        }, [sortBy, sortByDirection])

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                Inventory
                            </CTypography>
                            {/*<CButton variant={"contained"} onClick={()=>handleClickOpen('add')} sx={{mr: 2}}>*/}
                            {/*    Add New*/}
                            {/*</CButton>*/}
                            {/*<CButton variant={"contained"} onClick={() => handleClickOpen('manage')} sx={{mr: 2}}>*/}
                            {/*    Manage*/}
                            {/*</CButton>*/}
                            {/*<CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>*/}
                            {/*    Refresh*/}
                            {/*</CButton>*/}
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        {/*
                        <CCard sx={{
                        flex: 1,
                        height: "100%",
                        '& .pos-table-header': {
                            fontSize: "20px",
                            fontWeight: "400"
                        },
                        '& .pos-table-header-first': {
                            ml: "24px"
                        },
                        '& .pos-table-cell': {
                            color: "#363636"
                        },
                        '& .pos-table-cell-first': {
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: "400",
                            ml: "24px"
                        },
                    }}>
                        <StripedDataGrid getRowId={(row) => row.inventoryId}
                                         rows={rows}
                                         columns={columns}
                                         getRowClassName={(params) =>
                                             params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                         }
                            // page={paginationInfo.page}
                            // pageSize={paginationInfo.perPage}
                            // onPageChange={handlePageChange}
                                         pageSize={tablePageSize}
                                         onPageSizeChange={handlePageSizeChange}
                                         loading={isRefreshing}
                                         components={{
                                             LoadingOverlay: LinearProgress,
                                         }}
                        />
                    </CCard>
                        */}
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {/*<CCardContent>*/}
                            {/*<CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>*/}
                            {/*    <CTypography fontSize={"24px"} ml={4}>*/}
                            {/*        Manage Inventory*/}
                            {/*    </CTypography>*/}
                            {/*</CBox>*/}
                            {/*<Divider></Divider>*/}
                            <CGridContainer spacing={2} p={4} alignItems={'center'}>
                                <CGrid item xs={3}>
                                    <CTextField fullWidth label={"Location"} select
                                                onChange={(event) => {
                                                    skuNewValues = []
                                                    const value = Number(event.target.value);
                                                    setManageDialog({
                                                        ...manageDialog,
                                                        locationId: value
                                                    });
                                                }} value={manageDialog.locationId ? manageDialog.locationId : ''}>
                                        {locations.map(location => {
                                            return <CMenuItem
                                                value={Number(location.locationId)}>{location.locationName}</CMenuItem>
                                        })}
                                    </CTextField>
                                </CGrid>
                                {hasValue(manageDialog.locationId) && <CGrid item xs={3}>
                                    <CTextField fullWidth label={"Sku"} select
                                        // error={hasValue(validation.sku)}
                                        // helperText={hasValue(validation.sku) ? validation.sku : null}
                                                onChange={(event) => {
                                                    skuNewValues = []
                                                    const value = Number(event.target.value);
                                                    // setInventoryDialog({
                                                    //     ...inventoryDialog,
                                                    //     data: {...inventoryDialog.data, skuId: value}
                                                    // });
                                                    // if (hasValue(validation.sku)) {
                                                    //     setValidation({...validation, sku: ''})
                                                    // }
                                                    setManageDialog({
                                                        ...manageDialog,
                                                        parentSkuId: value,
                                                        skuId: null,
                                                    })
                                                    getSkus(value)
                                                }} value={manageDialog.parentSkuId ? manageDialog.parentSkuId : ''}>
                                        {parentSkus.map(sku => {
                                            if (sku.skuHasParent === 1) {
                                                return <CMenuItem
                                                    value={sku.skuId}>{sku.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                            } else {
                                                return <CMenuItem
                                                    value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                            }
                                        })}
                                    </CTextField>
                                </CGrid>}

                                {hasValue(manageDialog.locationId) && hasValue(manageDialog.parentSkuId) && <CGrid item xs={3}><CTextField fullWidth label={"Sort By"} select
                                                                                                                        onChange={(event) => {
                                                                                                                            const value = event.target.value;
                                                                                                                            setSortBy(value)
                                                                                                                        }} value={sortBy}>
                                    <CMenuItem
                                        value={"Availability"}>Availability</CMenuItem>
                                    <CMenuItem
                                        value={"Name"}>Name</CMenuItem>
                                </CTextField> </CGrid>}
                                {hasValue(manageDialog.locationId) && hasValue(manageDialog.parentSkuId) && <CGrid item xs={2}><CTextField fullWidth label={"Sort By Direction"} select
                                                                                                                        onChange={(event) => {
                                                                                                                            const value = event.target.value;
                                                                                                                            setSortByDirection(value)
                                                                                                                        }} value={sortByDirection}>
                                    <CMenuItem
                                        value={"DESC"}>DESC</CMenuItem>
                                    <CMenuItem
                                        value={"ASC"}>ASC</CMenuItem>
                                </CTextField></CGrid>}
                                {hasValue(manageDialog.locationId) && hasValue(manageDialog.parentSkuId) && <CGrid item xs={1}>
                                    <CIconButton onClick={()=>{
                                        getSkus(Number(manageDialog?.parentSkuId))
                                    }}>
                                        {isGettingSkus ? <CCircularProgress
                                            sx={{color: "primary"}}
                                            size={20}/> : <Refresh color={'primary'}/>}
                                    </CIconButton>
                                </CGrid>}
                            </CGridContainer>
                            <Divider></Divider>
                            {hasValue(manageDialog.locationId) && hasValue(manageDialog.parentSkuId) && <CBox sx={{flex: 1, maxHeight: '53vh', overflowY: 'scroll'}}>
                                {(!manageDialog.locationId || !manageDialog.parentSkuId) && (
                                    <CBox height={'100%'} display={'flex'} flexDirection={'column'}
                                          alignItems={'center'} justifyContent={'center'}>
                                        {!manageDialog.parentSkuId &&
                                            <CTypography variant={'h6'} m={2} color={'darkgrey'}>
                                                No <b>sku</b> is selected.
                                            </CTypography>}
                                        {!manageDialog.locationId &&
                                            <CTypography variant={'h6'} m={2} color={'darkgrey'}>
                                                Please select a <b>Lockbox</b>.
                                            </CTypography>}

                                    </CBox>
                                )}

                                {manageDialog.locationId && skus.map((sku, index) => {
                                    const currentAvailability = getSkuAvailability(sku, manageDialog.locationId);
                                    return <><CGridContainer spacing={2} p={2}>
                                        <CGrid item xs={4}
                                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <CTypography ml={2} fontSize={'subtitle1'} fontWeight={'bold'}>
                                                {sku.skuCode} - {sku.vendor?.vendorName} - {sku.skuTitle}
                                            </CTypography>
                                        </CGrid>
                                        <CGrid item xs={4}
                                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <CTypography ml={2} fontSize={'body1'} fontWeight={'bold'}>
                                                CurrentAvailability:
                                            </CTypography>
                                            <CTypography flex={1} ml={2} fontSize={'body1'}>
                                                {currentAvailability}
                                            </CTypography>
                                        </CGrid>
                                        <CGrid item xs={4}>
                                            <CTextField fullWidth label={"Target Quantity"}
                                                        type={"number"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (Number(event.target.value) >= 0 && Number(event.target.value) <= 999) {
                                                                const currentEditableSkus = [...editableSkus]
                                                                currentEditableSkus[index]['targetCount'] = Number(event.target.value)
                                                                setEditableSkus(currentEditableSkus)
                                                                storeNewValue(sku.skuId, Number(event.target.value))
                                                            }
                                                        }} value={
                                                (editableSkus[index]['targetCount'] !== null && editableSkus[index]['targetCount'] !== undefined) ?
                                                    editableSkus[index]['targetCount'] :
                                                    ''
                                            }/>
                                        </CGrid>
                                    </CGridContainer>
                                    </>
                                })}
                            </CBox>}

                            <Divider></Divider>
                            <CBox
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    py: 3
                                }}>
                                {/*<CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>*/}
                                {/*    Close*/}
                                {/*</CButton>*/}
                                <CButton disabled={!manageDialog.locationId || !manageDialog.parentSkuId}
                                         variant={"contained"} onClick={handleManageSaveChangesClick}
                                         sx={{mr: 4, minWidth: "100px"}}>
                                    {manageDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                                 size={24}/> : 'Save Changes'}
                                </CButton>
                            </CBox>
                            {/*</CCardContent>*/}
                        </CCard>

                    </CBox>
                </CBox>

                <CDialog open={inventoryDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Inventory
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Sku"} select
                                // error={hasValue(validation.sku)}
                                // helperText={hasValue(validation.sku) ? validation.sku : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            // setInventoryDialog({
                                            //     ...inventoryDialog,
                                            //     data: {...inventoryDialog.data, skuId: value}
                                            // });
                                            // if (hasValue(validation.sku)) {
                                            //     setValidation({...validation, sku: ''})
                                            // }
                                            getSkus(value)
                                        }} value={inventoryDialog.data.skuId}>
                                {parentSkus.map(sku => {
                                    if (sku.skuHasParent === 1) {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                    } else {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Variant"} select
                                        disabled={skus.length === 0}
                                        error={hasValue(validation.sku)}
                                        helperText={hasValue(validation.sku) ? validation.sku : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setInventoryDialog({
                                                ...inventoryDialog,
                                                data: {...inventoryDialog.data, skuId: value}
                                            });
                                            if (hasValue(validation.sku)) {
                                                setValidation({...validation, sku: ''})
                                            }
                                        }} value={inventoryDialog.data.skuId}>
                                {skus.map(sku => {
                                    if (sku.skuHasParent === 1) {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.skuTitle}</CMenuItem>
                                        // value={sku.skuId}>{sku.parentSku?.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                    } else {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Quantity"}
                                        error={hasValue(validation.quantity)}
                                        type={"number"}
                                        helperText={hasValue(validation.quantity) ? validation.quantity : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setInventoryDialog({
                                                ...inventoryDialog,
                                                data: {...inventoryDialog.data, skuQuantity: Number(event.target.value)}
                                            });
                                            if (hasValue(validation.quantity)) {
                                                setValidation({...validation, quantity: ''})
                                            }
                                        }} value={inventoryDialog.data.skuQuantity}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Location"} select
                                        error={hasValue(validation.location)}
                                        helperText={hasValue(validation.location) ? validation.location : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setInventoryDialog({
                                                ...inventoryDialog,
                                                data: {...inventoryDialog.data, locationId: value}
                                            });
                                            if (hasValue(validation.location)) {
                                                setValidation({...validation, location: ''})
                                            }
                                        }} value={inventoryDialog.data.locationId}>
                                {locations.map(location => {
                                    return <CMenuItem
                                        value={Number(location.locationId)}>{location.locationName}</CMenuItem>
                                })}
                            </CTextField>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {inventoryDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                            size={24}/> : inventoryDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>

                <CDialog open={manageDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px",
                        display: 'flex'
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Manage Inventory
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Sku"} select
                                // error={hasValue(validation.sku)}
                                // helperText={hasValue(validation.sku) ? validation.sku : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            // setInventoryDialog({
                                            //     ...inventoryDialog,
                                            //     data: {...inventoryDialog.data, skuId: value}
                                            // });
                                            // if (hasValue(validation.sku)) {
                                            //     setValidation({...validation, sku: ''})
                                            // }
                                            setManageDialog({
                                                ...manageDialog,
                                                parentSkuId: value,
                                                skuId: null,
                                            })
                                            getSkus(value)
                                        }} value={manageDialog.parentSkuId}>
                                {parentSkus.map(sku => {
                                    if (sku.skuHasParent === 1) {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.parentSku?.skuTitle} - {sku.skuTitle}</CMenuItem>
                                    } else {
                                        return <CMenuItem
                                            value={sku.skuId}>{sku.vendor?.vendorName} - {sku.skuTitle}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Location"} select
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setManageDialog({
                                                ...manageDialog,
                                                locationId: value
                                            });
                                        }} value={manageDialog.locationId}>
                                {locations.map(location => {
                                    return <CMenuItem
                                        value={Number(location.locationId)}>{location.locationName}</CMenuItem>
                                })}
                            </CTextField>
                        </CGrid>
                    </CGridContainer>
                    <Divider></Divider>
                    <CBox sx={{maxHeight: '60vh', overflowY: 'scroll'}}>
                        {manageDialog.locationId && skus.map((sku, index) => {
                            let currentAvailability = 0;
                            if (sku.storeItemAvailabilities && sku.storeItemAvailabilities.length > 0) {
                                const availabilityIndex = sku.storeItemAvailabilities.findIndex(availability => availability.locationId === manageDialog.locationId)
                                if (availabilityIndex > -1) {
                                    const count = sku.storeItemAvailabilities[availabilityIndex].availabilityCount
                                    if (count && count > 0) {
                                        currentAvailability = count
                                    } else {
                                        currentAvailability = 0
                                    }
                                } else {
                                    currentAvailability = 0
                                }
                            } else {
                                currentAvailability = 0
                            }
                            return <><CGridContainer spacing={2} p={2}>
                                <CGrid item xs={4} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CTypography ml={2} fontSize={'subtitle1'} fontWeight={'bold'}>
                                        {sku.skuCode} - {sku.vendor?.vendorName} - {sku.skuTitle}
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={4} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CTypography ml={2} fontSize={'body1'} fontWeight={'bold'}>
                                        CurrentAvailability:
                                    </CTypography>
                                    <CTypography flex={1} ml={2} fontSize={'body1'}>
                                        {currentAvailability}
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={4}>
                                    <CTextField fullWidth label={"Target Quantity"}
                                                type={"number"}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const currentEditableSkus = [...editableSkus]
                                                    currentEditableSkus[index]['targetCount'] = Number(event.target.value)
                                                    setEditableSkus(currentEditableSkus)
                                                }} value={editableSkus[index]['targetCount']}/>
                                </CGrid>
                            </CGridContainer>
                            </>
                        })}
                    </CBox>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={async () => {
                            if (manageDialog.isLoading) {
                                return;
                            }
                            if (!manageDialog.locationId) {
                                return
                            }
                            if (!manageDialog.parentSkuId) {
                                return
                            }
                            setManageDialog({
                                ...manageDialog,
                                isLoading: true
                            })
                            for (let i = 0; i < editableSkus.length; i++) {
                                const editableSku = editableSkus[i];
                                if (editableSku['targetCount'] !== undefined && editableSku['targetCount'] !== null) {
                                    const result = await storeItemAvailabilityStore.findOne({availabilityId: Number(`${editableSku.skuId}${manageDialog.locationId}`)}, currentAuthToken)
                                    const availableCount = result.availability && result.availability.availabilityCount ? result.availability.availabilityCount : 0
                                    if (result.kind === "ok") {
                                        console.log('submit', {
                                            skuId: editableSku.skuId,
                                            skuQuantity: editableSku['targetCount'] - availableCount,
                                            locationId: manageDialog.locationId,
                                            inventoryStatus: 'active',
                                            userId: currentUserId
                                        })
                                        await inventoryStore.create({
                                            skuId: editableSku.skuId,
                                            skuQuantity: editableSku['targetCount'] - availableCount,
                                            locationId: manageDialog.locationId,
                                            inventoryStatus: 'active',
                                            userId: currentUserId
                                        }, currentAuthToken)
                                    }
                                }
                            }
                            await getSkus(manageDialog.parentSkuId)
                            setManageDialog({
                                ...manageDialog,
                                isLoading: false
                            })
                        }} sx={{mr: 4, minWidth: "100px"}}>
                            {manageDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                         size={24}/> : 'Save Changes'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
