import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {LocationStoresLockboxesApi} from "../../services/api/location-stores-lockboxes-api"
import {LocationStoreLockboxModel, LocationStoreLockboxSnapshot} from "../location-store-lockbox/location-store-lockbox"
import {logInfo} from "../../utils/logs"
import {
    LocationStoreLockboxCreateParams,
    LocationStoreLockboxDeleteParams,
    LocationStoreLockboxFindAllParams,
} from "../../services/api";

function locationStoreLockboxStoreLog(functionName, message) {
    logInfo({
        fileName: "locationStoreLockbox-store.ts",
        className: "locationStoreLockboxStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const LocationStoreLockboxStoreModel = types
    .model("LocationStoreLockboxStore")
    .props({
        locationStoreLockbox: types.optional(types.maybeNull(LocationStoreLockboxModel), null),
        locationStoreLockboxs: types.optional(types.array(LocationStoreLockboxModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveLocationStoreLockbox: (locationStoreLockboxSnapshot: LocationStoreLockboxSnapshot) => {
            try {
                self.locationStoreLockbox = locationStoreLockboxSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    locationStoreLockboxStoreLog("saveLocationStoreLockbox", e.message)
                }
                // recordError(e)
            }
        },
        saveLocationStoreLockboxes: (locationStoreLockboxsSnapshot: LocationStoreLockboxSnapshot[]) => {
            try {
                self.locationStoreLockboxs = cast([...locationStoreLockboxsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    locationStoreLockboxStoreLog("saveLocationStoreLockbox", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: LocationStoreLockboxCreateParams, token: string) {
            self.environment.addAuth(token)
            const locationStoreLockboxApi = new LocationStoresLockboxesApi(self.environment.api)
            return yield locationStoreLockboxApi.create(params)
        }),
        findAll: flow(function* (params: LocationStoreLockboxFindAllParams, token: string) {
            self.environment.addAuth(token)
            const locationStoreLockboxApi = new LocationStoresLockboxesApi(self.environment.api)
            const result = yield locationStoreLockboxApi.findAll(params)


            if (result.kind === "ok") {
                self.saveLocationStoreLockboxes(result.locationStoreLockboxes)
            } else {
                locationStoreLockboxStoreLog("findAll", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: LocationStoreLockboxDeleteParams, token: string) {
            self.environment.addAuth(token)
            const locationStoreLockboxApi = new LocationStoresLockboxesApi(self.environment.api)
            return yield locationStoreLockboxApi.remove(params)
        }),

    }))

type LocationStoreLockboxStoreType = Instance<typeof LocationStoreLockboxStoreModel>

export interface LocationStoreLockboxStore extends LocationStoreLockboxStoreType {
}

type LocationStoreLockboxStoreSnapshotType = SnapshotOut<typeof LocationStoreLockboxStoreModel>

export interface LocationStoreLockboxStoreSnapshot extends LocationStoreLockboxStoreSnapshotType {
}

export const createLocationStoreLockboxStoreDefaultModel = () => types.optional(LocationStoreLockboxStoreModel, {})
