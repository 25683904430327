import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  InventoryDeleteParams,
  InventoryCreateResult,
  InventoryUpdateResult,
  InventoryUpdateParams,
  InventoryFindAllResult,
  InventoryDeleteResult,
  InventoryFindAllParams,
  InventoryFindOneParams,
  InventoryCreateParams,
  InventoryFindOneResult, InventorySkuCountResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
import {hasValue} from "../../utils/empty-check";
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class InventoriesApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: InventoryCreateParams): Promise<InventoryCreateResult> {
    try {
      // const functionName = "login"
      const uri = "inventories"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const inventory = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", inventory}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: InventoryFindAllParams): Promise<InventoryFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "inventories"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const inventories = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", inventories}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async skuCount(id: number, lockboxId: number): Promise<InventorySkuCountResult> {
    try {
      // const functionName = "login"
      const uri = "inventories/sku-count/"+id
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, {lockboxId})
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const count = hasValue(response.data.sum) ? Number(response.data.sum) : 0

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", count}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: InventoryFindOneParams): Promise<InventoryFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "inventories/"+params.inventoryId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const inventory = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", inventory}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: InventoryUpdateParams): Promise<InventoryUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "inventories/"+params.inventoryId
      delete params.inventoryId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const inventory = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", inventory}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: InventoryDeleteParams): Promise<InventoryDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "inventories/"+params.inventoryId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
