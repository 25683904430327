import {CInterleavedTableRow, CTableCell} from "../components";

export const getCellValue = (row, column) => {
    // console.log(row, column)
    if (column.renderCell) {
        return column.renderCell({row});
    }
    if (column.valueGetter) {
        return column.valueGetter({row});
    }
    return row[column.field];
};

export const generateTableRows = (rows, columns) => {

    return rows.map((row, index) => (
        <CInterleavedTableRow key={index}
                              sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
            {
                columns.map((column, cIndex)=>(
                    <CTableCell key={`${index}${cIndex}`} sx={{
                        paddingLeft: cIndex === 0 ? '32px' : '16px',
                        fontWeight: cIndex === 0 ? 'bold' : 'regular',
                        fontSize: cIndex === 0 ? '16px' : '14px',
                    }} scope="row">{getCellValue(row, column)}</CTableCell>
                ))
            }
        </CInterleavedTableRow>
    ));
};