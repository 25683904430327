import {useEffect, useState} from "react";
import { CBox } from "..";
import {CTypography} from "../mui-extracted/typography";

let statelessCount

export const MomentCountdown = (props) => {

    const [count, setCount] = useState(props.count);


    let interval

    useEffect(()=>{
        statelessCount = props.count

        interval = setInterval(()=>{
            if(statelessCount > 0) {
                setCount((prevCounter) => prevCounter - 1)
                statelessCount -= 1
            } else {
                props.onCountdownFinish()
                clearInterval(interval)
            }
        }, 1000)

        return ()=>clearInterval(interval)
    },[])

    return <CBox sx={{
        border: "1px solid #FFFFFF",
        width: "28px",
        height: "28px",
        borderRadius: "14px",
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    }}>
        <CTypography>
            {count}
        </CTypography>
    </CBox>
}