import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {SkusApi} from "../../services/api/sku-api"
import {SkuModel, SkuSnapshot} from "../sku/sku"
import {logInfo} from "../../utils/logs"
import {
    SkuCreateParams,
    SkuDeleteParams,
    SkuFindAllParams,
    SkuFindOneParams,
    SkuSearchParams,
    SkuUpdateParams
} from "../../services/api";

function skuStoreLog(functionName, message) {
    logInfo({
        fileName: "sku-store.ts",
        className: "skuStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const SkuStoreModel = types
    .model("SkuStore")
    .props({
        sku: types.optional(types.maybeNull(SkuModel), null),
        skus: types.optional(types.array(SkuModel), []),
        count: types.optional(types.number, 0),
        skuVarieties: types.optional(types.array(SkuModel), []),
        isGettingSkuVarieties: types.optional(types.boolean, false),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveSku: (skuSnapshot: SkuSnapshot) => {
            try {
                self.sku = cast(skuSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    skuStoreLog("saveSku", e.message)
                }
                // recordError(e)
            }
        },
        saveSkus: (skusSnapshot: SkuSnapshot[]) => {
            try {
                detach(self.skus)
                const immutableSkus: SkuSnapshot[] = []
                skusSnapshot.forEach(skuSnapshot=>{
                    if (skuSnapshot.vendor) {
                        immutableSkus.push({
                            ...skuSnapshot,
                            vendor: {
                                vendorId: skuSnapshot?.vendor?.vendorId,
                                vendorName: skuSnapshot?.vendor?.vendorName,
                                vendorDesc: skuSnapshot?.vendor?.vendorDesc,
                                vendorUrl: skuSnapshot?.vendor?.vendorUrl,
                                vendorStatus: skuSnapshot?.vendor?.vendorStatus,
                                vendorCreatedAt: skuSnapshot?.vendor?.vendorCreatedAt,
                                vendorUpdatedAt: skuSnapshot?.vendor?.vendorUpdatedAt,
                            }
                        })
                    } else {
                        immutableSkus.push({
                            ...skuSnapshot
                        })
                    }

                })
                console.log('saveSkus.immutableSkus', immutableSkus)
                self.skus = cast([...immutableSkus])
            } catch (e) {
                if(e instanceof Error) {
                    skuStoreLog("saveSkus", e.message)
                }
                // recordError(e)
            }
        },
        saveSkuVarieties: (skusSnapshot: SkuSnapshot[]) => {
            try {
                detach(self.skuVarieties)
                self.skuVarieties = cast([...skusSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    skuStoreLog("saveSkuVarieties", e.message)
                }
                // recordError(e)
            }
        },
        saveCount: (count: number) => {
            try {
                self.count = count
            } catch (e) {
                if(e instanceof Error) {
                    skuStoreLog("saveCount", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: SkuCreateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.skuImages
            // @ts-ignore
            delete params.prices
            // @ts-ignore
            delete params.category
            // @ts-ignore
            delete params.vendor
            // @ts-ignore
            delete params.parentSku
            // @ts-ignore
            delete params.storeItemAvailabilities
            // @ts-ignore
            delete params.basicBundles

            const skuApi = new SkusApi(self.environment.api)
            const result = yield skuApi.create(params)


            if (result.kind === "ok") {
                // self.saveSku(result.sku)
            } else {
                skuStoreLog("create", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: SkuFindAllParams, token: string, saveToMst: boolean) {
            self.environment.addAuth(token)
            self.isGettingSkuVarieties = true
            const skuApi = new SkusApi(self.environment.api)
            const result = yield skuApi.findAll(params)

            if (saveToMst) {
                if (result.kind === "ok") {
                    self.saveSkus(result.skus)
                    self.saveCount(result.count)
                } else {
                    skuStoreLog("findAll", result.kind)
                }
            }
            self.isGettingSkuVarieties = false
            return result
        }),
        search: flow(function* (params: SkuSearchParams, token: string) {
            self.environment.addAuth(token)
            const skuApi = new SkusApi(self.environment.api)
            // if (result.kind === "ok") {
            //     self.saveSkus(result.skus)
            // } else {
            //     skuStoreLog("search", result.kind)
            // }
            return yield skuApi.search(params)
        }),
        findAllVarieties: flow(function* (params: SkuFindAllParams, token: string) {
            self.environment.addAuth(token)
            const skuApi = new SkusApi(self.environment.api)
            const result = yield skuApi.findAll(params)

            if (result.kind === "ok") {
                self.saveSkuVarieties(result.skus)
            } else {
                skuStoreLog("findAllVarieties", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: SkuFindOneParams, token: string) {
            self.environment.addAuth(token)
            const skuApi = new SkusApi(self.environment.api)
            const result = yield skuApi.findOne(params)


            if (result.kind === "ok") {
                self.saveSku(result.sku)
            } else {
                skuStoreLog("findOne", result.kind)
            }
            return result
        }),
        update: flow(function* (params: SkuUpdateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.skuImages
            // @ts-ignore
            delete params.prices
            // @ts-ignore
            delete params.category
            // @ts-ignore
            delete params.vendor
            // @ts-ignore
            delete params.parentSku
            // @ts-ignore
            delete params.storeItemAvailabilities
            // @ts-ignore
            delete params.basicBundles

            const skuApi = new SkusApi(self.environment.api)
            const result = yield skuApi.update(params)

            if (result.kind === "ok") {
                self.saveSku(result.sku)
            } else {
                skuStoreLog("update", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: SkuDeleteParams, token: string) {
            self.environment.addAuth(token)
            const skuApi = new SkusApi(self.environment.api)
            return yield skuApi.remove(params)
        }),

    }))

type SkuStoreType = Instance<typeof SkuStoreModel>

export interface SkuStore extends SkuStoreType {
}

// type SkuStoreSnapshotType = SnapshotOut<typeof SkuStoreModel>

// export interface SkuStoreSnapshot extends SkuStoreSnapshotType {
// }

// export const createSkuStoreDefaultModel = () => types.optional(SkuStoreModel, {})
