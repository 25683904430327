import TextField, {TextFieldProps} from '@mui/material/TextField';

import {styled} from '@mui/material/styles';
import {useState} from "react";
import {Autocomplete, useLoadScript} from '@react-google-maps/api';

export const CTextField = styled(TextField)<TextFieldProps>(
  (/* { theme } */) => ({

  }),
);

export const CPlacesACTextField = (props) => {
    const [searchResult, setSearchResult] = useState<any>(null)

    const { isLoaded } =  useLoadScript({
        googleMapsApiKey: 'AIzaSyCCzq3G9J8lkO2AAU8WDyDhzBah09MS81g',
        libraries: ['places']
    });

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

    function onPlaceChanged() {
        //variable to store the result
        const place = searchResult?.getPlace();
        // //variable to store the name from place details result
        // const name = place.name;
        // //variable to store the status from place details result
        // const status = place.business_status;
        //variable to store the formatted address from place details result
        const formattedAddress = place.formatted_address;
        const latLong = place?.geometry?.location;

        //console log all results
        props.onPlaceSelected(formattedAddress, latLong);

    }

    if(!isLoaded) {
        return <div>Loading...</div>
    };

    return (
        <Autocomplete
            onLoad={onLoad}
            options={{ types: ['address'] }}
            fields={['formatted_address']}
            onPlaceChanged={onPlaceChanged}
            className={'google-map-ac'}
         >
            <TextField
                sx={{ flex: 1 }}
                size="small"
                label="Full-Address"
            />
        </Autocomplete>
    );
};


export const CTextFieldRoundedCorners = styled(TextField)<TextFieldProps>(
    (/* { theme } */) => ({
      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: 32
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: 32
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderRadius: 32
      }
    }),
);

export const CWhiteTextField = styled(TextField)({
    '& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused': {
        color: 'white',
    },
    '& .MuiInputBase-input, & .MuiInputBase-input::placeholder': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
        '& .MuiSelect-root': {
            color: 'white',
        },
    },

});