import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {GatewayModel} from "../gateway/gateway";

/**
 * Rick and Morty character model.
 */
export const LocationSettingModel = types.model("LocationSetting").props({
    locationSettingId: types.number,
    locationId: types.maybeNull(types.number),
    locationSettingLogo: types.maybeNull(types.string),
    locationSettingColor: types.maybeNull(types.string),
    locationSettingGaTrackingNo: types.maybeNull(types.string),
    locationSettingCopyright: types.maybeNull(types.string),
    locationSettingPhone: types.maybeNull(types.string),
    locationSettingEmail: types.maybeNull(types.string),
    locationSettingAddress: types.maybeNull(types.string),
    locationSettingTimezone: types.maybeNull(types.string),
    locationSettingTaxPercent: types.maybeNull(types.number),
    locationSettingFee: types.maybeNull(types.number),
    locationSettingAuthorizeSeal: types.maybeNull(types.string),
    locationSettingDeliveryFee: types.maybeNull(types.number),
    locationSettingCreatedAt: types.maybeNull(types.string),
    locationSettingUpdatedAt: types.maybeNull(types.string),
    locationSettingBundlesEnabled: types.maybeNull(types.number),
    locationSettingDeliveryEnabled: types.maybeNull(types.number),
    gatewayId: types.maybeNull(types.number),
    gateway: types.optional(types.maybeNull(GatewayModel),{gatewayId: -1}),
})

type LocationSettingType = Instance<typeof LocationSettingModel>
export interface LocationSetting extends LocationSettingType {}
type LocationSettingSnapshotType = SnapshotOut<typeof LocationSettingModel>
export interface LocationSettingSnapshot extends LocationSettingSnapshotType {}

export const defaultLocationSetting = {
    locationSettingId: undefined,
    locationId: undefined,
    locationSettingLogo: '',
    locationSettingColor: '#083B1C',
}