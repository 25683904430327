import {forwardRef, useEffect, useImperativeHandle} from "react";
import * as React from "react";
import {CButton} from "../mui-extracted";
import {useStores} from "../../models";
import {Table} from "../table";
import {Price} from "../../models/price/price";

interface SkusTableProps {
    handleClickOpen: (action: string, sku: any) => void,
    handleClickImageOpen: (action: string, skuImages: any[], skuId: any) => void,
    handleClickVarietiesOpen: (sku: any) => void,
    handleClickPricesOpen: (sku: any, prices: any[]) => void,
    module: string
}

let currentAuthToken = ''
let statelessPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessOrderInfo = {
    orderBy: 'skuTitle',
    order: 'ASC'
}

export const SkusTable = forwardRef(function SkusTable({
                                                           handleClickOpen,
                                                           handleClickVarietiesOpen,
                                                           handleClickPricesOpen,
                                                           handleClickImageOpen,
                                                           module
                                                       }: SkusTableProps, ref) {

    const {authStore, skuStore} = useStores()
    const {skus, count} = skuStore

    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'skuTitle',
        order: 'DESC'
    })

    useImperativeHandle(ref, () => ({
        refreshContents() {
            // Call your API here
            refresh()
        },
    }));

    const handleRemove = async (sku) => {
        await skuStore.remove({skuId: sku.skuId}, currentAuthToken)
        await refresh()
    }


    const checkAuth = () => {
        if (module === 'admin') {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
        } else if (module === 'store admin') {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            }
        } else if (module === 'lockbox') {
            if (authStore.lockboxAuth !== null) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            }
        }

    }

    useEffect(() => {
        ;(async () => {
            checkAuth()
            await refresh()
        })()

    }, [])

    const refresh = async () => {
        setIsRefreshing(true)

        await skuStore.findAll({
            skuHasParent: 0,
            page: statelessPaginationInfo.page,
            perPage: statelessPaginationInfo.perPage,
            sortBy: statelessOrderInfo.orderBy,
            sortDirection: statelessOrderInfo.order,
        }, currentAuthToken, true)
        setIsRefreshing(false)
    }

    const handlePageChange = async (details, page) => {
        console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            page
        }
        await refresh()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await refresh()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessOrderInfo.orderBy === property && statelessOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        await refresh()
    };

    const rows = skus;

    let priceColumn: any[] = []

    if (module !== 'admin') {
        priceColumn = [
            {
                field: 'skuDefaultPrice',
                headerName: 'Price',
                valueGetter: (params) => {
                    const {prices} = params.row
                    let currentPrice: Price | null = null
                    const index = prices.findIndex(price => authStore.storeAdminAuth?.userLocationId === price.locationId)
                    if (index > -1) {
                        currentPrice = prices[index]
                    }
                    return currentPrice?.priceAmount?.toFixed(2)
                },
                width: 70,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            }
        ]
    }

    const columns: any[] = [
        {
            field: 'skuId',
            headerName: 'ID',
            width: 44,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'skuTitle',
            headerName: 'Title',
            width: 150,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'skuCategory',
            valueGetter: (params) => {
                const {category} = params.row
                return `${category.categoryName ? category.categoryName : ''}`
            },
            headerName: 'Category',
            width: 100,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'skuVendor',
            valueGetter: (params) => {
                const {vendor} = params.row
                return `${vendor.vendorName ? vendor.vendorName : ''}`
            },
            headerName: 'Vendor',
            width: 80,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'skuStatus',
            valueGetter: (params) => {
                const {skuStatus} = params.row
                if (skuStatus && skuStatus.length > 0) {
                    return `${skuStatus[0].toUpperCase()}${skuStatus.slice(1, skuStatus.length)}`
                } else {
                    return ''
                }
            },
            headerName: 'Status',
            width: 70,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
            ...priceColumn,
        {
            field: 'col5', headerName: 'Actions', flex: 1, headerClassName: "pos-table-header"
            , cellClassName: "pos-table-cell",
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickOpen('edit', params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                const onClickImage = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickImageOpen('image', params.row.skuImages, params.row.skuId)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                const onClickVrieties = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickVarietiesOpen(params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                const onClickPrices = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickPricesOpen(params.row, params.row.prices)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                const onRemoveClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleRemove(params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                return <><CButton variant={"contained"}
                                  size={"small"}
                                  sx={
                                      {
                                          height: '32px', mr: 2
                                      }
                                  }
                                  onClick={onClick}> Edit </CButton>
                    <CButton
                        variant={"contained"}
                        size={"small"}
                        sx={
                            {
                                height: '32px', mr: 2
                            }
                        }
                        onClick={onClickImage}> Images </CButton>
                    <CButton
                        variant={"contained"}
                        size={"small"}
                        sx={
                            {
                                height: '32px', mr: 2
                            }
                        }
                        onClick={onClickVrieties}> Varieties </CButton>
                    <CButton
                        variant={"contained"}
                        size={"small"}
                        sx={
                            {
                                height: '32px', mr: 2
                            }
                        }
                        onClick={onClickPrices}> Prices </CButton>
                    {module === 'admin' && <CButton
                        variant={"contained"}
                        color={"error"}
                        size={"small"}
                        sx={
                            {
                                height: '32px'
                            }
                        }
                        onClick={onRemoveClick}> Remove </CButton>}
                </>;
            }
        },
    ];

    return (
        <Table
            columns={columns}
            rows={rows}
            count={count}
            page={paginationInfo.page}
            rowsPerPage={paginationInfo.perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            orderBy={orderInfo.orderBy}
            order={orderInfo.order}
            onRequestSort={handleRequestSort}
            isLoading={isRefreshing}
        />)

})