import {hasValue} from "../utils/empty-check";
import * as React from "react";
import {useStores} from "../models";
import {useEffect} from "react";
import {currentDomain} from "../utils/domain";
import ReactGA from "react-ga4";

let statelessGuestAuthToken = ''
let statelessIsAnalyticsInitialized = false
let statelessConfigsInitialized = false

export function useAppInit() {

    const {authStore, locationStore, configStore} = useStores()

    const [isLoadingGuestAuth, setIsLoadingGuestAuth] = React.useState(false)
    const [guestAuthToken, setGuestAuthToken] = React.useState('')
    const [isLoadingLocationInfo, setIsLoadingLocationInfo] = React.useState(false)
    const [locationInfo, setLocationInfo] = React.useState<any>(null)
    const [locationInfoState, setLocationInfoState] = React.useState<any>('not_loaded')
    const [locationLockboxes, setLocationLockboxes] = React.useState<any[]>([])
    const [locationLockboxesState, setLocationLockboxesState] = React.useState<any>('not_loaded')
    const [gaState, setGAState] = React.useState<any>('not_loaded')

    const [guestAuthState, setGuestAuthState] = React.useState<any>('not_loaded')

    const guestLogin = async () => {
        setIsLoadingGuestAuth(true)
        const result = await authStore.login({
            username: 'g.u.e.s.t',
            password: 'guest2000@112113'
        })
        // console.log("guestLogin.result", JSON.stringify(result, null, 2))
        // console.log("guestLogin.result.kind", result.kind)
        // console.log("result.auth", JSON.stringify(result.auth, null, 2))
        if (result.kind === 'ok') {
            if (result.auth) {
                const guestAuthObject = result.auth
                await configStore.findAll({
                    page: 0,
                    perPage: 100
                }, guestAuthObject.accessToken)
                // console.log("guestLogin.guestAuthObject.accessToken", guestAuthObject.accessToken)
                statelessGuestAuthToken = guestAuthObject.accessToken
                setGuestAuthToken(guestAuthObject.accessToken)
                setGuestAuthState('loaded')
            }
        }
        setIsLoadingGuestAuth(false)
    }

    const checkAuth = async () => {
        if (statelessGuestAuthToken !== null && statelessGuestAuthToken.length > 0) {
            // console.log("checkAuth.authStore.auth", JSON.stringify(authStore.auth, null, 2))
            // statelessGuestAuthToken = authStore.auth.accessToken
            setGuestAuthToken(statelessGuestAuthToken)
            await configStore.findAll({
                page: 0,
                perPage: 100
            }, statelessGuestAuthToken)
            statelessConfigsInitialized = true
            setGuestAuthState('loaded')
        } else {
            await guestLogin()
        }
    }

    const loadLocation = async (displayLoading: boolean) => {
        if (displayLoading) {
            setIsLoadingLocationInfo(true)
        }
        const domain = currentDomain()
        // console.log("loadLocation.guestAuthToken", statelessGuestAuthToken)
        const result = await locationStore.findBySlug(domain, statelessGuestAuthToken)
        if (result.kind === 'ok') {
            setLocationInfo(locationStore.currentLocation)
            const lockboxesParams = {
                locationTypeId: 2,
            }
            if (locationStore.currentLocation?.locationStoreType === 'local') {
                if (locationStore.currentLocation.lockboxes) {
                    locationStore.saveLockboxes(JSON.parse(JSON.stringify([...locationStore.currentLocation.lockboxes])))
                    setLocationLockboxes(locationStore.currentLocation.lockboxes)
                    setLocationLockboxesState('loaded')
                }
            } else {
                const lockboxesResult = await locationStore.findAll(lockboxesParams, statelessGuestAuthToken, false)
                if (lockboxesResult.kind === 'ok') {
                    locationStore.saveLockboxes(lockboxesResult.locations)
                    setLocationLockboxes(lockboxesResult.locations)
                    setLocationLockboxesState('loaded')
                }
            }

        }
        setLocationInfoState('loaded')
        if (displayLoading) {
            setIsLoadingLocationInfo(false)
        }
    }

    const checkLocationInfo = async () => {
        if (hasValue(locationStore.currentLocation)) {
            if (locationStore.currentLocation?.locationSlug !== currentDomain()) {
                await loadLocation(true)
            } else {
                setLocationInfo(locationStore.currentLocation)
                await loadLocation(false)
            }
            setLocationInfoState('loaded')
        } else {
            await loadLocation(true)
        }
    }

    /**
     * Initiates the first events which happen inside this function
     */
    useEffect(() => {

        ;(async () => {
            await checkAuth()
            await checkLocationInfo()
            if (!statelessIsAnalyticsInitialized &&
                hasValue(locationStore.currentLocation?.locationSetting?.locationSettingGaTrackingNo) &&
                locationStore.currentLocation?.locationSetting?.locationSettingGaTrackingNo &&
                locationStore.currentLocation?.locationSetting?.locationSettingGaTrackingNo.length > 0
            ) {
                console.log('GA initialized', locationStore.currentLocation?.locationSetting?.locationSettingGaTrackingNo)
                ReactGA.initialize(locationStore.currentLocation?.locationSetting?.locationSettingGaTrackingNo, {
                    gtagOptions: {}
                });
                statelessIsAnalyticsInitialized = true;
                ReactGA.event({
                    category: "App",
                    action: "app_open"
                });
                setGAState('loaded')
            }
        })()

    }, [])

    return {
        isLoadingGuestAuth,
        isLoadingLocationInfo,
        guestAuthToken,
        locationInfo,
        locationInfoState,
        guestAuthState,
        locationLockboxes,
        locationLockboxesState,
        gaState
    }
}
