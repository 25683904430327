import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCheckbox,
    CDialog,
    CFormControlLabel,
    CGrid,
    CGridContainer,
    CTextField,
    CTypography,
    StripedDataGrid,
    Divider
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";

export const AdminPanelRoles = observer(
    function AdminPanelRoles() {

        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const rows: GridRowsProp = [
            {id: 1, col1: 'Admin', col4: 'All', col2: 'All', col3: '-',},
            {id: 2, col1: 'Editor', col4: 'Lockbox, Panel', col2: 'Sku, Orders', col3: 'This user can manage the products'},
            {id: 3, col1: 'Accountant', col4: 'Kiosk, Panel', col2: 'Payments, Orders', col3: 'This user is na accountant'},
            {id: 4, col1: 'Kiosk', col4: 'Kiosk', col2: 'None', col3: '-'},
            {id: 5, col1: 'Lockbox', col4: 'Lockbox', col2: 'None', col3: '-'}

        ];

        const columns: GridColDef[] = [
            {
                field: 'col1',
                headerName: 'Name',
                width: 150,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'col4',
                headerName: 'Authorized Modules',
                width: 180,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col2',
                headerName: 'Authorized Panel Sections',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },

            {
                field: 'col3',
                headerName: 'Notes',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen()
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}} onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}} onClick={onClick}>Remove</CButton>
                    </>;
                }
            },

        ];

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                                <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                    Roles
                                </CTypography>
                            <CButton variant={"contained"} onClick={handleClickOpen}>
                                Add New
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid rows={rows} columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Role
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Name"}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={9}>
                            <CTextField fullWidth label={"Notes"}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} ml={1} mt={2}>
                            <CTypography>Authorized Modules:</CTypography>

                        </CGrid>
                        <CGrid item xs={12} ml={1}>
                            <CFormControlLabel control={<CCheckbox defaultChecked />} label="Panel" />
                            <CFormControlLabel control={<CCheckbox />} label="Kiosk" />
                            <CFormControlLabel control={<CCheckbox />} label="Lockbox" />

                        </CGrid>
                        <CGrid item xs={12} ml={1} mt={2}>
                            <CTypography>Authorized Panel Sections:</CTypography>
                        </CGrid>
                        <CGrid item xs={12} ml={1}>
                            <CFormControlLabel control={<CCheckbox defaultChecked />} label="Dashboard" />
                            <CFormControlLabel control={<CCheckbox />} label="Locations" />
                            <CFormControlLabel control={<CCheckbox />} label="Users" />
                            <CFormControlLabel control={<CCheckbox />} label="Roles" />
                            <CFormControlLabel control={<CCheckbox />} label="SKU" />
                            <CFormControlLabel control={<CCheckbox />} label="Category" />
                            <CFormControlLabel control={<CCheckbox />} label="Vendors" />
                            <CFormControlLabel control={<CCheckbox />} label="Inventory" />
                            <CFormControlLabel control={<CCheckbox />} label="Payments" />
                            <CFormControlLabel control={<CCheckbox />} label="Orders" />

                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleClose} sx={{mr: 4, minWidth: "100px"}}>
                            Add
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
