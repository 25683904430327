import {grey, primary} from "../../theme";
import {CBox, CTypography} from "../mui-extracted";
import * as React from "react";

export const ChipSelect = (props) => {
    const {selected, onClick, title, disabled = false} = props
    return <CBox sx={{
        borderRadius: "34px",
        height: "25px",
        px: 2,
        py: 1,
        margin: 1,
        backgroundColor: disabled ? grey['300'] : selected ? primary['500'] : 'transparent',
        borderWidth: 1,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
        cursor: disabled ? 'default' : 'pointer'
    }}
                 onClick={()=>{
                     if (!disabled) {
                         onClick()
                     }
                 }}
    >
        <CTypography sx={{color: disabled ? grey['500'] : selected ? 'white' : '#212121'}}>
            {title}
        </CTypography>
    </CBox>
}