import { Instance, SnapshotOut, types } from "mobx-state-tree"


// Define a late reference to the SkuModel
const SkuModel = types.late(() => require("../sku/sku").SkuModel)

export const BasicBundleModel = types.model("BasicBundle").props({
    basicBundleId: types.number,
    basicBundleSkuId: types.number,
    basicBundleStoreId: types.maybeNull(types.number),
    basicBundleLockboxId: types.maybeNull(types.number),
    basicBundlePaidCount: types.maybeNull(types.number),
    basicBundleFreeCount: types.maybeNull(types.number),
    basicBundleTitle: types.maybeNull(types.string),
    basicBundleDescriptions: types.maybeNull(types.string),
    basicBundleImageUrl: types.maybeNull(types.string),
    basicBundleStatus: types.maybeNull(types.string),
    basicBundleValidFrom: types.maybeNull(types.string),
    basicBundleValidTo: types.maybeNull(types.string),
    basicBundleCreatedAt: types.maybeNull(types.string),
    basicBundleUpdatedAt: types.maybeNull(types.string),
    basicBundleDeleted: types.maybeNull(types.number),
    basicBundleSku: types.maybeNull(SkuModel)
})

type BasicBundleType = Instance<typeof BasicBundleModel>
export interface BasicBundle extends BasicBundleType {}
type BasicBundleSnapshotType = SnapshotOut<typeof BasicBundleModel>
export interface BasicBundleSnapshot extends BasicBundleSnapshotType {}
