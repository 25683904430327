import {Config} from "../models/config/config";

export const getConfig = (configs: Config[],slug: string) => {
    const index = configs.findIndex((item)=>item.configSlug === slug)
    let value = ''
    if(index >= 0) {
        value = String(configs[index].configValue)
    }
    return value
}
