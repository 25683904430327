import FormControl, {FormControlProps} from '@mui/material/FormControl';
import FormLabel, {FormLabelProps} from '@mui/material/FormLabel';
import FormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel';
import {styled} from '@mui/material/styles';
import {FormGroup, FormGroupProps} from "@mui/material";

export const CFormControl = styled(FormControl)<FormControlProps>(
  (/* { theme } */) => ({
  }),
);

export const CFormLabel = styled(FormLabel)<FormLabelProps>(
  (/* { theme } */) => ({
  }),
);

export const CFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  (/* { theme } */) => ({
      fontFamily: "Asap Condensed"
  }),
);

export const CFormGroup = styled(FormGroup)<FormGroupProps>(
    (/* { theme } */) => ({
    }),
);
