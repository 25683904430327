import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CTextField,
    CTypography,
    Divider
} from "../../../components";
import {Refresh} from "@mui/icons-material";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useNavigate} from "react-router-dom";
import {Table} from "../../../components/table";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {Dayjs} from "dayjs";
import {convertToCurrentTimezone, stringToDayjs} from "../../../utils/date-time";
import {PromotionalBannerSnapshot} from "../../../models/promotional-banner/promotional-banner";
import {ensureString} from "../../../utils/type";

let currentAuthToken = ''

interface BannerDialogFormDataType {
    promotionalBannerId?: number,
    promotionalBannerTitle: string,
    promotionalBannerDescriptions: string,
    promotionalBannerValidFrom: Dayjs | null,
    promotionalBannerValidTo: Dayjs | null,
    promotionalBannerBgColor: string,
    promotionalBannerTitleColor: string,
    promotionalBannerSubtitleColor: string,
}

export const StorePanelBanners = observer(
    function StorePanelBanners() {

        const navigate = useNavigate();

        const {authStore, promotionalBannerStore} = useStores()

        const [showAddPromotionalBannerDialog, setShowAddPromotionalBannerDialog] = React.useState(false);
        const [promotionalBannerDialogMode, setPromotionalBannerDialogMode] = React.useState<'create' | 'edit'>('create');
        const [bannerDialogFormData, setBannerDialogFormData] = React.useState<BannerDialogFormDataType>({
            promotionalBannerTitle: '',
            promotionalBannerDescriptions: '',
            promotionalBannerValidFrom: null,
            promotionalBannerValidTo: null,
            promotionalBannerBgColor: '',
            promotionalBannerTitleColor: '',
            promotionalBannerSubtitleColor: '',
        });
        const [isCreatingPromotionalBanner, setIsCreatingPromotionalBanner] = React.useState(false);
        const [isFromDateCalendarOpen, setIsFromDateCalendarOpen] = React.useState<boolean>(false)
        const [isToDateCalendarOpen, setIsToDateCalendarOpen] = React.useState<boolean>(false)

        const checkAuth = () => {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Store Admin Panel - Banners';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        const handlePromotionalBannerInactivate = async (promotionalBannerId: number) => {

            await promotionalBannerStore.update({
                promotionalBannerId,
                promotionalBannerStatus: 'inactive'
            }, currentAuthToken)
            await refresh()
        }

        const handlePromotionalBannerActivate = async (promotionalBannerId: number) => {

            await promotionalBannerStore.update({
                promotionalBannerId,
                promotionalBannerStatus: 'active'
            }, currentAuthToken)
            await refresh()
        }

        const handlePromotionalBannerEdit = async (promotionalBanner: PromotionalBannerSnapshot) => {
            setPromotionalBannerDialogMode('edit')
            setBannerDialogFormData({
                promotionalBannerId: promotionalBanner.promotionalBannerId,
                promotionalBannerTitle: ensureString(promotionalBanner.promotionalBannerTitle),
                promotionalBannerDescriptions: ensureString(promotionalBanner.promotionalBannerDescriptions),
                promotionalBannerValidFrom: stringToDayjs(ensureString(promotionalBanner.promotionalBannerValidFrom)),
                promotionalBannerValidTo: stringToDayjs(ensureString(promotionalBanner.promotionalBannerValidTo)),
                promotionalBannerBgColor: ensureString(promotionalBanner.promotionalBannerBgColor),
                promotionalBannerTitleColor: ensureString(promotionalBanner.promotionalBannerTitleColor),
                promotionalBannerSubtitleColor: ensureString(promotionalBanner.promotionalBannerSubtitleColor),
            })
            setShowAddPromotionalBannerDialog(true)
        }

        const refresh = async () => {
            if (!authStore.storeAdminAuth?.userLocationId) {
                return
            }

            await promotionalBannerStore.findAll({
                locationId: authStore.storeAdminAuth?.userLocationId
            }, currentAuthToken)

        }

        const handlePromotionalBannerCreate = async () => {
            setIsCreatingPromotionalBanner(true)

            if (promotionalBannerDialogMode === 'create') {
                // @ts-ignore
                await promotionalBannerStore.create({
                    ...bannerDialogFormData,
                    promotionalBannerValidFrom: bannerDialogFormData.promotionalBannerValidFrom?.toISOString(),
                    promotionalBannerValidTo: bannerDialogFormData.promotionalBannerValidTo?.toISOString(),
                    locationId: Number(authStore.storeAdminAuth?.userLocationId)
                }, currentAuthToken)
            } else {
                await promotionalBannerStore.update({
                    ...bannerDialogFormData,
                    promotionalBannerValidFrom: bannerDialogFormData.promotionalBannerValidFrom?.toISOString(),
                    promotionalBannerValidTo: bannerDialogFormData.promotionalBannerValidTo?.toISOString(),
                }, currentAuthToken)
            }
            await refresh()
            setIsCreatingPromotionalBanner(false)
            setBannerDialogFormData({
                promotionalBannerTitle: '',
                promotionalBannerDescriptions: '',
                promotionalBannerValidFrom: null,
                promotionalBannerValidTo: null,
                promotionalBannerBgColor: '',
                promotionalBannerTitleColor: '',
                promotionalBannerSubtitleColor: '',
            })
            setShowAddPromotionalBannerDialog(false)
        }

        const handlePromotionalBannerDelete = async (promotionalBannerId: number) => {
            await promotionalBannerStore.remove({promotionalBannerId}, currentAuthToken)
            await refresh()
        }

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CGridContainer spacing={2} my={2}>
                                <CGrid item xs={12} md={1.5}>
                                    <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                        Banner
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={6} md={1.5}>
                                    <CButton variant={"contained"} fullWidth onClick={async () => {
                                        setShowAddPromotionalBannerDialog(true)
                                        setBannerDialogFormData({
                                            promotionalBannerTitle: '',
                                            promotionalBannerDescriptions: '',
                                            promotionalBannerValidFrom: null,
                                            promotionalBannerValidTo: null,
                                            promotionalBannerBgColor: '',
                                            promotionalBannerTitleColor: '',
                                            promotionalBannerSubtitleColor: '',
                                        })
                                        setPromotionalBannerDialogMode('create')
                                    }}
                                    >
                                        Add New
                                    </CButton>
                                </CGrid>
                                <CGrid item xs={6} md={1}>
                                    <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                        Refresh
                                    </CButton>
                                </CGrid>
                            </CGridContainer>



                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <Table
                                columns={[
                                    {
                                        field: 'promotionalBannerId',
                                        headerName: 'ID',
                                        width: 14,
                                        headerClassName: "pos-table-header pos-table-header-first",
                                        cellClassName: "pos-table-cell pos-table-cell-first"
                                    },
                                    {
                                        field: 'promotionalBannerTitle',
                                        headerName: 'Title',
                                        width: 100,
                                        headerClassName: "pos-table-header pos-table-header-first",
                                        cellClassName: "pos-table-cell pos-table-cell-first"
                                    },
                                    {
                                        field: 'promotionalBannerDescriptions',
                                        headerName: 'Descriptions',
                                        flex: 1,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'promotionalBannerValidFrom',
                                        headerName: 'From',
                                        valueGetter: (params) => {
                                            const {promotionalBannerValidFrom} = params.row
                                            return convertToCurrentTimezone(promotionalBannerValidFrom, 'MMM D')
                                        },
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'promotionalBannerValidTo',
                                        headerName: 'To',
                                        valueGetter: (params) => {
                                            const {promotionalBannerValidTo} = params.row
                                            return convertToCurrentTimezone(promotionalBannerValidTo, 'MMM D')
                                        },
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'promotionalBannerStatus',
                                        headerName: 'Status',
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'actions',
                                        headerName: 'Action',
                                        width: 250,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell",
                                        renderCell: (params) => {

                                            const onInactivateClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handlePromotionalBannerInactivate(params.row.promotionalBannerId)
                                                // return alert(JSON.stringify(thisRow, null, 4));
                                            };

                                            const onActivateClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handlePromotionalBannerActivate(params.row.promotionalBannerId)
                                                // return alert(JSON.stringify(thisRow, null, 4));
                                            };

                                            const onEditClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handlePromotionalBannerEdit(params.row)
                                            };

                                            const onDeleteClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handlePromotionalBannerDelete(params.row.promotionalBannerId)
                                            };

                                            return <>
                                                {params.row.promotionalBannerStatus === 'active' ? <CButton variant={"contained"} color={"error"} size={"small"}
                                                                                                            sx={{height: '32px', mr: 2}}
                                                                                                            onClick={onInactivateClick}>Inactivate</CButton> : <CButton variant={"contained"} size={"small"}
                                                                                                                                                                        sx={{height: '32px', mr: 2}}
                                                                                                                                                                        onClick={onActivateClick}>Activate</CButton>}

                                                <CButton variant={"contained"} color={"primary"} size={"small"}
                                                         sx={{height: '32px', mr: 2}}
                                                         onClick={onEditClick}>Edit</CButton>

                                                <CButton variant={"contained"} color={"error"} size={"small"}
                                                         sx={{height: '32px'}}
                                                         onClick={onDeleteClick}>Remove</CButton>
                                            </>;
                                        }
                                    }
                                ]}
                                rows={promotionalBannerStore.promotionalBanners}
                                count={promotionalBannerStore.promotionalBanners.length}
                                page={0}
                                rowsPerPage={100}
                                onPageChange={() => {
                                    console.log('tmp')
                                }}
                                onRowsPerPageChange={() => {
                                    console.log('tmp')
                                }}
                                orderBy={'tmp'}
                                order={'DESC'}
                                onRequestSort={() => {
                                    console.log('tmp')
                                }}
                                isLoading={false}
                                noRowTitleOnSmallSize={true}
                            />

                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={showAddPromotionalBannerDialog} onClose={() => setShowAddPromotionalBannerDialog(false)} fullWidth
                         maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Banner
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2} alignItems={'center'}>
                        <CGrid item xs={12} md={9}>
                            <CTextField fullWidth label={"Title"}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBannerDialogFormData({
                                                ...bannerDialogFormData,
                                                promotionalBannerTitle: value
                                            });
                                        }} value={bannerDialogFormData?.promotionalBannerTitle ? bannerDialogFormData?.promotionalBannerTitle : ''}>
                            </CTextField>
                        </CGrid>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <>
                                <CGrid item xs={12} md={1.5}
                                       sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <MobileDatePicker
                                        label="From Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={bannerDialogFormData.promotionalBannerValidFrom}
                                        open={isFromDateCalendarOpen}
                                        onOpen={() => setIsFromDateCalendarOpen(true)}
                                        onChange={(newValue: Dayjs | null) => {
                                            // console.log('From Date', newValue)
                                            if (newValue) {
                                                setBannerDialogFormData({
                                                    ...bannerDialogFormData,
                                                    promotionalBannerValidFrom: newValue
                                                });
                                            }
                                            setIsFromDateCalendarOpen(false)
                                        }}
                                        onClose={() => setIsFromDateCalendarOpen(false)}
                                        renderInput={(params) =>
                                            <CTextField fullWidth {...params}/>}
                                    />
                                </CGrid>
                                <CGrid item xs={12} md={1.5}
                                       sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <MobileDatePicker
                                        label="To Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={bannerDialogFormData.promotionalBannerValidTo}
                                        open={isToDateCalendarOpen}
                                        onOpen={() => setIsToDateCalendarOpen(true)}
                                        onClose={() => setIsToDateCalendarOpen(false)}
                                        onChange={(newValue: Dayjs | null) => {
                                            // console.log('To Date', newValue)
                                            if (newValue) {
                                                setBannerDialogFormData({
                                                    ...bannerDialogFormData,
                                                    promotionalBannerValidTo: newValue
                                                });
                                            }
                                            setIsToDateCalendarOpen(false)
                                        }}
                                        renderInput={(params) =>
                                            <CTextField fullWidth {...params} />}
                                    />
                                </CGrid>
                            </>
                        </LocalizationProvider>
                        <CGrid item xs={12} md={4}>
                            <CTextField fullWidth label={"Background Color"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBannerDialogFormData({
                                                ...bannerDialogFormData,
                                                promotionalBannerBgColor: value
                                            });
                                        }} value={bannerDialogFormData?.promotionalBannerBgColor ? bannerDialogFormData?.promotionalBannerBgColor : ''}>
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={4}>
                            <CTextField fullWidth label={"Title Color"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBannerDialogFormData({
                                                ...bannerDialogFormData,
                                                promotionalBannerTitleColor: value
                                            });
                                        }} value={bannerDialogFormData?.promotionalBannerTitleColor ? bannerDialogFormData?.promotionalBannerTitleColor : ''}>
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={4}>
                            <CTextField fullWidth label={"Descriptions Color"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBannerDialogFormData({
                                                ...bannerDialogFormData,
                                                promotionalBannerSubtitleColor: value
                                            });
                                        }} value={bannerDialogFormData?.promotionalBannerSubtitleColor ? bannerDialogFormData?.promotionalBannerSubtitleColor : ''}>
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12}>
                            <CTextField fullWidth multiline={true} rows={2} label={"Descriptions"}
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBannerDialogFormData({
                                                ...bannerDialogFormData,
                                                promotionalBannerDescriptions: value
                                            });
                                        }} value={bannerDialogFormData?.promotionalBannerDescriptions ? bannerDialogFormData?.promotionalBannerDescriptions : ''}>
                            </CTextField>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={() => setShowAddPromotionalBannerDialog(false)}
                                 sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handlePromotionalBannerCreate}
                                 sx={{mr: 4, minWidth: "100px"}}>
                            {isCreatingPromotionalBanner ? <CCircularProgress sx={{color: "white"}}
                                                                        size={24}/> : 'Submit'}
                        </CButton>
                    </CBox>
                </CDialog>

            </>
        );
    }
)
