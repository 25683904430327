import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {StoreItemAvailabilityApi} from "../../services/api/store-item-availability"
import {
    StoreItemAvailabilityFindOneParams,
} from "../../services/api";

/**
 * Model description here for TypeScript hints.
 */
export const StoreItemAvailabilityStoreModel = types
    .model("StoreItemAvailabilityStore")
    .extend(withEnvironment)
    .actions((self) => ({
        findOne: flow(function* (params: StoreItemAvailabilityFindOneParams, token: string) {
            self.environment.addAuth(token)
            const storeItemAvailabilityApi = new StoreItemAvailabilityApi(self.environment.api)
            return yield storeItemAvailabilityApi.findOne(params)
        }),
    }))

type StoreItemAvailabilityStoreType = Instance<typeof StoreItemAvailabilityStoreModel>

export interface StoreItemAvailabilityStore extends StoreItemAvailabilityStoreType {
}

type StoreItemAvailabilityStoreSnapshotType = SnapshotOut<typeof StoreItemAvailabilityStoreModel>

export interface StoreItemAvailabilityStoreSnapshot extends StoreItemAvailabilityStoreSnapshotType {
}

export const createStoreItemAvailabilityStoreDefaultModel = () => types.optional(StoreItemAvailabilityStoreModel, {})
