import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer, CIconButton,
    CTextField,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {PageCreateParams, PageUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultPage} from "../../../models/page/page";
import {isEmpty} from "validate.js";
import {useEffect} from "react";
import {ImageOutlined, Refresh} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";
import Editor, {composeDecorators} from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import {
    EditorState,
    ContentState,
    convertToRaw,
    RichUtils,
    convertFromRaw,
    AtomicBlockUtils,
    Modifier,
    DefaultDraftBlockRenderMap
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import {
    AlignTextCenterButton,
    AlignTextLeftButton,
    AlignTextRightButton,
    BlockquoteButton,
    BoldButton,
    CodeBlockButton,
    CodeButton,
    HeadlineOneButton,
    HeadlineThreeButton,
    HeadlineTwoButton,
    ItalicButton,
    OrderedListButton,
    SubButton,
    SupButton,
    UnderlineButton,
    UnorderedListButton,
} from "@draft-js-plugins/buttons";
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createImagePlugin from '@draft-js-plugins/image';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import {uploadImage} from "../../../utils/firebase-storage";
import { Resizable } from 'react-resizable';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
// @ts-ignore
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createAlignmentPlugin from '@draft-js-plugins/alignment';

import "@draft-js-plugins/emoji/lib/plugin.css"
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';

interface PageDialogData {
    isOpen: boolean,
    data: PageCreateParams | PageUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

const staticToolbarPlugin = createToolbarPlugin();
const {Toolbar} = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];
const emojiPlugin = createEmojiPlugin({
    useNativeArt: true
});
const {EmojiSuggestions, EmojiSelect} = emojiPlugin;
const imagePlugin = createImagePlugin();
const linkifyPlugin = createLinkifyPlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();

const { AlignmentTool } = alignmentPlugin;

const ImageBlock = React.forwardRef(
    (
        {        // @ts-ignore
            block,
            // @ts-ignore
            blockProps,
            // @ts-ignore
            customStyleMap,
            // @ts-ignore
            customStyleFn,
            // @ts-ignore
            decorator,
            // @ts-ignore
            forceSelection,
            // @ts-ignore
            offsetKey,
            // @ts-ignore
            selection,
            // @ts-ignore
            tree,
            // @ts-ignore
            contentState,
            // @ts-ignore
            blockStyleFn,
            // @ts-ignore
            preventScroll,
            // @ts-ignore
            style,
        ...elementProps
        },
        ref
    ) => {

        const entity = contentState.getEntity(block.getEntityAt(0));

        const { src } = entity.getData();
        return  <img
            // @ts-ignore
            ref={ref}
            src={src}
            alt="Editor content"
            style={{ objectFit: 'cover', ...style }}
            {...elementProps}
        />
    }


);

const createImageBlockPlugin = (config = {}) => {
    // @ts-ignore
    const component = config.decorator
        // @ts-ignore
        ? config.decorator(ImageBlock)
        : ImageBlock;
    return {
        blockRendererFn: (block, { getEditorState }) => {
            if (block.getType() === 'atomic') {
                const contentState = getEditorState().getCurrentContent();
                const entity = contentState.getEntity(block.getEntityAt(0));
                const type = entity.getType();

                if (type === 'IMAGE') {
                    const { src, width, height } = entity.getData();
                    console.log('block', { src, width, height })
                    return {
                        component,
                        editable: false,
                    };
                }
            }
            return null;
        },
    };
};

const decorator = composeDecorators(
    resizeablePlugin.decorator,
    focusPlugin.decorator,
    alignmentPlugin.decorator,
    blockDndPlugin.decorator,
);

const imageBlockPlugin = createImageBlockPlugin({ decorator });

export const AdminPanelPages = observer(
    function AdminPanelPages() {
        const navigate = useNavigate();

        const {authStore, pageStore} = useStores()
        const {pages} = pageStore

        const [pageDialog, setPageDialog] = React.useState<PageDialogData>({
            isOpen: false,
            data: defaultPage,
            mode: 'add',
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            title: '',
            slug: '',
            content: ''
        })
        const [editorState, setEditorState] = React.useState(
            () => EditorState.createEmpty(),
        );


        const handleClickOpen = (mode: string, page?: PageCreateParams | PageUpdateParams) => {
            setPageDialog({
                ...pageDialog,
                isOpen: true,
                mode,
                data: page ? {...page} : defaultPage
            });

            if (mode === 'edit') {
                try {
                    const parsedContentState = JSON.parse(page?.pageContent || '');
                    const editorState = EditorState.createWithContent(convertFromRaw(parsedContentState));

                    setEditorState(editorState)

                } catch (e) {
                    const contentState = ContentState.createFromText(page?.pageContent || '');
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState)
                }

            }
        };

        const handleClose = () => {
            setPageDialog({isOpen: false, data: defaultPage, mode: 'add', isLoading: false});
            setEditorState(() => EditorState.createEmpty())
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setPageDialog({
                    ...pageDialog,
                    isLoading: true
                });

                if (pageDialog.mode === 'add') {
                    await pageStore.create({
                        ...pageDialog.data
                    }, currentAuthToken)
                } else if (pageDialog.mode === 'edit') {
                    await pageStore.update({
                        ...pageDialog.data
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (page) => {
            await pageStore.remove({pageId: page.pageId}, currentAuthToken)
            await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(pageDialog.data.pageSlug)) {
                validationObject.slug = "This field is required."
                isValid = false
            }
            if (isEmpty(pageDialog.data.pageTitle)) {
                validationObject.title = "This field is required."
                isValid = false
            }
            if (isEmpty(pageDialog.data.pageContent)) {
                validationObject.content = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await pageStore.findAll({
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        const handleKeyCommand = (command, editorState) => {
            const newState = RichUtils.handleKeyCommand(editorState, command);

            if (newState) {
                setEditorState(newState);
                return 'handled';
            }

            return 'not-handled';
        }

        // Handle insertion of images
        const handleInsertImage = (url) => {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {src: url});
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {currentContent: contentStateWithEntity});
            setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
        };

        const ResizeableImageComponent = ({ block, entityKey, editorState, setEditorState }) => {
            const contentState = editorState.getCurrentContent();
            const entity = contentState.getEntity(entityKey);
            const { src, width, height } = entity.getData();

            const handleImageResize = (event, { size }) => {
                const { width, height } = size;
                const newEntityData = {
                    ...entity.getData(),
                    width,
                    height,
                };

                const newContentState = Modifier.mergeEntityData(contentState, entityKey, newEntityData);
                const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
                setEditorState(newEditorState);
            };

            return (
                <Resizable
                    width={width}
                    height={height}
                    onResize={handleImageResize}
                    draggableOpts={{ grid: [1, 1] }}
                    style={{ position: 'relative', overflow: 'hidden' }}
                >
                    <img src={src} alt="Editor content" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Resizable>
            );
        };

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Pages';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        const rows: GridRowsProp = pages;

        const columns: GridColDef[] = [
            {
                field: 'pageTitle',
                headerName: 'Title',
                width: 200,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'pageSlug',
                headerName: 'Slug',
                width: 200,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'pageContent',
                headerName: 'Content',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onRemoveClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleRemove(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}
                                 onClick={onRemoveClick}>Remove</CButton>
                    </>;
                }
            },

        ];

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                Pages
                            </CTypography>
                            <CButton variant={"contained"} onClick={() => handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.pageId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={pageDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Page
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={6}>
                            <CTextField multiline fullWidth label={"Title"}
                                        maxRows={15}
                                        error={hasValue(validation.title)}

                                        helperText={hasValue(validation.title) ? validation.title : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPageDialog({
                                                ...pageDialog,
                                                data: {...pageDialog.data, pageTitle: event.target.value}
                                            });
                                            if (hasValue(validation.title)) {
                                                setValidation({...validation, title: ''})
                                            }
                                        }}
                                        value={pageDialog.data.pageTitle}/>

                        </CGrid>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Slug"}
                                        error={hasValue(validation.slug)}
                                        helperText={hasValue(validation.slug) ? validation.slug : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPageDialog({
                                                ...pageDialog,
                                                data: {...pageDialog.data, pageSlug: event.target.value}
                                            });
                                            if (hasValue(validation.slug)) {
                                                setValidation({...validation, slug: ''})
                                            }
                                        }} value={pageDialog.data.pageSlug}/>
                        </CGrid>
                        <CGrid item xs={12}>
                            <Toolbar>
                                {
                                    // may be use React.Fragment instead of div to improve perfomance after React 16
                                    (externalProps) => (
                                        <div>
                                            <AlignTextCenterButton {...externalProps} />
                                            <AlignTextLeftButton {...externalProps} />
                                            <AlignTextRightButton {...externalProps} />
                                            <SubButton {...externalProps} />
                                            <SupButton {...externalProps} />
                                            <BoldButton {...externalProps} />
                                            <ItalicButton {...externalProps} />
                                            <UnderlineButton {...externalProps} />
                                            <CodeButton {...externalProps} />
                                            <HeadlineOneButton {...externalProps} />
                                            <HeadlineTwoButton {...externalProps} />
                                            <HeadlineThreeButton {...externalProps} />
                                            <UnorderedListButton {...externalProps} />
                                            <OrderedListButton {...externalProps} />
                                            <BlockquoteButton {...externalProps} />
                                            <CodeBlockButton {...externalProps} />
                                            {/*@ts-ignore*/}
                                            <CIconButton variant="contained" component="label">
                                                <ImageOutlined></ImageOutlined>
                                                <input hidden accept="image/*" multiple type="file"
                                                       onChange={({target}) => {

                                                           uploadImage(
                                                               target,
                                                               'pages',
                                                               (url) => {
                                                                   handleInsertImage(url)
                                                               },
                                                               (error) => {
                                                                   console.log(error)
                                                                   // Handle any errors
                                                               }
                                                           )
                                                       }}/>
                                            </CIconButton>
                                            <EmojiSuggestions />
                                            <EmojiSelect closeOnEmojiSelect  />

                                        </div>
                                    )
                                }
                            </Toolbar>
                            <CBox sx={{
                                boxSizing: 'border-box',
                                height: '40vh',
                                overflow: 'scroll',
                                borderRadius: '4px',
                                border: 'solid 1px #BDBDBD',
                            }}>

                                <Editor
                                    editorState={editorState}
                                    handleKeyCommand={handleKeyCommand}
                                    // onChange={(newEditorState) => {
                                    //     setEditorState(newEditorState);
                                    //     setPageDialog({
                                    //         ...pageDialog,
                                    //         data: {
                                    //             ...pageDialog.data,
                                    //             pageContent: newEditorState.getCurrentContent().getPlainText(),
                                    //         },
                                    //     });
                                    //     if (hasValue(validation.content)) {
                                    //         setValidation({...validation, content: ''});
                                    //     }
                                    // }}
                                    onChange={(newEditorState) => {
                                        setEditorState(newEditorState);
                                        const rawContentState = convertToRaw(editorState.getCurrentContent());
                                        const pageContent = JSON.stringify(rawContentState);
                                        setPageDialog({
                                            ...pageDialog,
                                            data: {
                                                ...pageDialog.data,
                                                pageContent,
                                            },
                                        });
                                        if (hasValue(validation.content)) {
                                            setValidation({...validation, content: ''});
                                        }
                                    }}
                                    plugins={
                                        [
                                            ...plugins,
                                            imagePlugin,
                                            linkifyPlugin,
                                            emojiPlugin,
                                            focusPlugin,
                                            resizeablePlugin,
                                            imageBlockPlugin,
                                            blockDndPlugin,
                                            alignmentPlugin,
                                        ]}
                                />
                                <AlignmentTool />

                            </CBox>
                            {/*<CTextField multiline fullWidth label={"Content"}*/}
                            {/*            maxRows={15}*/}
                            {/*            error={hasValue(validation.content)}*/}

                            {/*            helperText={hasValue(validation.content)?validation.content:null}*/}
                            {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                            {/*                setPageDialog({*/}
                            {/*                    ...pageDialog,*/}
                            {/*                    data: {...pageDialog.data, pageContent: event.target.value}*/}
                            {/*                });*/}
                            {/*                if(hasValue(validation.content)) {*/}
                            {/*                    setValidation({...validation, content: ''})*/}
                            {/*                }*/}
                            {/*            }} value={pageDialog.data.pageContent}/>*/}

                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {pageDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                       size={24}/> : pageDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
