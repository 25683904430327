import { ApiResponse } from "apisauce"
import { Api } from "./api"
import {
  PromotionalBannerDeleteParams,
  PromotionalBannerCreateResult,
  PromotionalBannerUpdateResult,
  PromotionalBannerUpdateParams,
  PromotionalBannerFindAllResult,
  PromotionalBannerDeleteResult,
  PromotionalBannerFindAllParams,
  PromotionalBannerFindOneParams,
  PromotionalBannerCreateParams,
  PromotionalBannerFindOneResult,
} from "./api.types"
import { getGeneralApiProblem } from "./api-problem"

export class PromotionalBannersApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: PromotionalBannerCreateParams): Promise<PromotionalBannerCreateResult> {
    try {
      const uri = "promotional-banners"
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }

      const promotionalBanner = response.data

      return { kind: "ok", promotionalBanner }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async findAll(params: PromotionalBannerFindAllParams): Promise<PromotionalBannerFindAllResult> {
    try {
      const uri = "promotional-banners"
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }

      const promotionalBanners = response.data

      return { kind: "ok", promotionalBanners }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async findOne(params: PromotionalBannerFindOneParams): Promise<PromotionalBannerFindOneResult> {
    try {
      const uri = `promotional-banners/${params.promotionalBannerId}`
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }

      const promotionalBanner = response.data

      return { kind: "ok", promotionalBanner }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async update(params: PromotionalBannerUpdateParams): Promise<PromotionalBannerUpdateResult> {
    try {
      const uri = `promotional-banners/${params.promotionalBannerId}`
      delete params.promotionalBannerId

      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }

      const promotionalBanner = response.data

      return { kind: "ok", promotionalBanner }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async remove(params: PromotionalBannerDeleteParams): Promise<PromotionalBannerDeleteResult> {
    try {
      const uri = `promotional-banners/${params.promotionalBannerId}`
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }

      return { kind: "ok" }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}