import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCancelOrderConfirmationDialog,
    CCard,
    CCircularProgress,
    CContainer,
    CGrid,
    CGridContainer,
    CTextField,
    CTypography,
    POSTitle,
    CIconButton,
    Divider,
    CMenuItem,
    CSnackbar, CAlert, CRadio, CFormControlLabel, CRadioGroup, CConfirmationDialog, CCheckbox
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBackIosNew} from "@mui/icons-material";
import {navigationParams} from "../../../utils/navigation";
import {useStores} from "../../../models";
import {isEmpty} from "validate.js";
import {hasValue} from "../../../utils/empty-check";
import {useEffect, useState} from "react";
import {currentDomain} from "../../../utils/domain";
import {useAppInit} from "../../../hooks/use-app-init";
import {CheckoutBreadcrumb} from "../../../components/checkout-breadcrumb";
import {getSkuPrice} from "../../../utils/sku";
import {AlertColor} from "@mui/material/Alert/Alert";

export const StoreInformation = observer(
    function KioskInformation() {
        const navigate = useNavigate();

        const params = useParams();

        const {cartStore, customerProfileStore, customerStore, authStore, locationStore, userDetailStore} = useStores()

        const [validation, setValidation] = React.useState({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address1: '',
            city: '',
            state: '',
            zip: '',
            cardNo: '',
            month: '',
            year: '',
            cvc: '',
            cartInfoCardPayer: ''

        })
        const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)
        const [isSavingDetails, setIsSavingDetails] = React.useState(false)
        const [cardType, setCardType] = React.useState('')
        const [messageSnackbar, setMessageSnackbar] = React.useState<{
            isOpen: boolean;
            message: string;
            severity: AlertColor,
            verticalAnchor: "bottom" | "top"
        }>({isOpen: false, message: '', severity: 'info', verticalAnchor: 'bottom'})
        const [deleteCardDialog, setDeleteCardDialog] = React.useState({
            cardNo: '',
            isOpen: false,
        })

        const guestAuth = useAppInit()
        const {locationInfo, locationInfoState} = guestAuth

        // useEffect(() => {
        //
        //
        // }, [])

        function checkCardType(input) {
            const t = {
                visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
                mastercard: /^5[1-5][0-9]{14}$/,
                amex: /^3[47][0-9]{13}$/,
                diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                jcb: /^(?:2131|1800|35\d{3})\d{11}$/
            };
            for (const n in t) {
                if (t[n].test(input)) {
                    return n
                }
            }

            return ''
        }

        useEffect(() => {
            ;(async () => {
                document.title = `Information`;
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Information | ${locationInfo.locationName}`;
                    if (authStore.auth !== null && authStore.auth?.userDetail !== null) {
                        const userDetail = authStore.auth?.userDetail
                        cartStore.saveCart({
                            ...cartStore.cart,
                            cartUserId: authStore.auth.userId,
                            cartKioskId: locationInfo.locationId,
                            cartKioskMode: params.mode,
                            cartInfoFirstName: hasValue(customerProfileStore.customerProfile) ?
                                customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.firstName :
                                userDetail.userDetailFirstName,
                            cartInfoLastName: hasValue(customerProfileStore.customerProfile) ?
                                customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.lastName :
                                userDetail.userDetailLastName,
                            cartInfoPhone: userDetail.userDetailPhone,
                            cartInfoEmail: userDetail.userDetailEmail,
                            cartInfoAddress1: hasValue(customerProfileStore.customerProfile) ?
                                customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.address :
                                userDetail.userDetailAddress1,
                            cartInfoAddress2: userDetail.userDetailAddress2,
                            cartInfoCity: hasValue(customerProfileStore.customerProfile) ?
                                customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.city :
                                userDetail.userDetailCity,
                            cartInfoState: hasValue(customerProfileStore.customerProfile) ?
                                customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.state :
                                userDetail.userDetailState,
                            cartInfoZip: hasValue(customerProfileStore.customerProfile) ?
                                customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.zip :
                                userDetail.userDetailZip,
                            cartInfoCardNo: '',
                            cartInfoCardMonth: '',
                            cartInfoCardYear: '',
                            cartInfoCardCVC: '',
                            cartInfoCardPayer: '',
                            cartInfoCardZip: ''
                        })
                    } else if (
                        authStore.auth !== null &&
                        (authStore.auth?.role?.roleName === "Guest" ||
                            authStore.auth?.role?.roleName === "Customer"
                        )
                    ) {
                        cartStore.saveCart({
                            ...cartStore.cart,
                            cartUserId: authStore.auth.userId,
                            cartKioskId: locationInfo.locationId,
                            cartKioskMode: params.mode,
                            cartInfoCardNo: '',
                            cartInfoCardMonth: '',
                            cartInfoCardYear: '',
                            cartInfoCardCVC: '',
                            cartInfoCardPayer: '',
                            cartInfoCardZip: ''
                        })
                    }
                }
            })()

        }, [locationInfo, locationInfoState])

        const validateZipCode = (value) => {
            // Regular expression to validate zip code format
            const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
            return zipCodeRegex.test(value);
        };

        const validateStateName = (value) => {
            // List of valid US state short names
            // const validStateNames = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

            // return validStateNames.includes(value.toUpperCase());
            return value?.length == 2
        };

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(cartStore.cart?.cartInfoFirstName)) {
                validationObject.firstName = "This field is required."
                isValid = false
            }
            if (isEmpty(cartStore.cart?.cartInfoLastName)) {
                validationObject.lastName = "This field is required."
                isValid = false
            }
            if (isEmpty(cartStore.cart?.cartInfoPhone)) {
                validationObject.phone = "This field is required."
                isValid = false
            } else if (cartStore.cart?.cartInfoPhone && cartStore.cart?.cartInfoPhone.length != 10) {
                validationObject.phone = "Phone number must be 10 digits."
                isValid = false
            }
            if (isEmpty(cartStore.cart?.cartInfoEmail)) {
                validationObject.email = "This field is required."
                isValid = false
            } else {
                if (!validateEmail(cartStore.cart?.cartInfoEmail)) {
                    validationObject.email = "Please enter a valid email address."
                    isValid = false
                }
            }
            if (isEmpty(cartStore.cart?.cartInfoAddress1)) {
                validationObject.address1 = "This field is required."
                isValid = false
            }
            if (isEmpty(cartStore.cart?.cartInfoCity)) {
                validationObject.city = "This field is required."
                isValid = false
            }
            if (isEmpty(cartStore.cart?.cartInfoState)) {
                validationObject.state = "This field is required."
                isValid = false
            } else {
                if (!validateStateName(cartStore.cart?.cartInfoState)) {
                    validationObject.email = "Please enter a valid state name."
                    isValid = false
                }
            }
            if (isEmpty(cartStore.cart?.cartInfoZip)) {
                validationObject.zip = "This field is required."
                isValid = false
            } else {
                if (!validateZipCode(cartStore.cart?.cartInfoZip)) {
                    validationObject.email = "Please enter a valid zip code."
                    isValid = false
                }
            }
            if (!cartStore.cart?.useCustomerProfile) {

                if (isEmpty(cartStore.cart?.cartInfoCardPayer)) {
                    validationObject.cartInfoCardPayer = "This field is required."
                    isValid = false
                }
                if (isEmpty(cartStore.cart?.cartInfoCardNo)) {
                    validationObject.cardNo = "This field is required."
                    isValid = false
                } else if (checkCardType(cartStore.cart?.cartInfoCardNo) === '') {
                    validationObject.cardNo = "Please enter a valid card number."
                    isValid = false
                }
                if (isEmpty(cartStore.cart?.cartInfoCardMonth)) {
                    validationObject.month = "This field is required."
                    isValid = false
                }

                if (isEmpty(cartStore.cart?.cartInfoCardYear)) {
                    validationObject.year = "This field is required."
                    isValid = false
                } else {
                    const currentYear = new Date().getFullYear();
                    const lastTwoCharacters = Number(String(currentYear).slice(-2));

                    if (cartStore.cart?.cartInfoCardYear && Number(cartStore.cart?.cartInfoCardYear) < lastTwoCharacters) {
                        validationObject.year = "The entered year is wrong."
                        isValid = false
                    }
                }

                if (isEmpty(cartStore.cart?.cartInfoCardCVC)) {
                    validationObject.cvc = "This field is required."
                    isValid = false
                } else if (cartStore.cart?.cartInfoCardCVC && (
                    cartStore.cart?.cartInfoCardCVC?.length > 4 ||
                    cartStore.cart?.cartInfoCardCVC?.length < 3)
                ) {
                    validationObject.cvc = "Entered CVV should be 3 or 4 characters."
                    isValid = false

                }
            }

            setValidation(validationObject)
            return isValid;
        }

        const handleContinueClick = async () => {
            if (!validate()) {
                return
            }

            if (authStore.auth !== null && authStore.auth?.role?.roleName === "Guest" && cartStore.cart !== null) {
                setIsSavingDetails(true)
                const flaggedCustomerResult = await customerStore.findAll({
                    customerEmail: String(cartStore.cart.cartInfoEmail),
                    customerFlag: 'red'
                }, authStore.auth.accessToken)
                let isRedFlag = false
                console.log('handleContinueClick', JSON.stringify(flaggedCustomerResult, null, 2))
                if (flaggedCustomerResult.kind === "ok") {
                    if (
                        flaggedCustomerResult.count > 0 &&
                        hasValue(flaggedCustomerResult?.customers) &&
                        flaggedCustomerResult.customers?.length > 0) {
                        isRedFlag = true
                    }
                }
                /// Check if customer with the same location & email exists
                const findCustomerResult = await customerStore.findAll({
                    customerEmail: String(cartStore.cart.cartInfoEmail),
                    customerLocationId: Number(locationInfo.locationId)
                }, authStore.auth.accessToken)
                if (findCustomerResult.kind !== 'ok') {
                    setMessageSnackbar({
                        isOpen: true,
                        message: `Something went wrong (${findCustomerResult.kind}). Please try again later.`,
                        severity: 'error',
                        verticalAnchor: 'top'
                    })
                    return
                }
                let customerId
                if (hasValue(findCustomerResult.customers) && findCustomerResult.customers.length > 0) {
                    /// If it does, update the customer instead of creating also retrieve the id
                    customerId = findCustomerResult.customers[0].customerId
                    const updateCustomerResult = await customerStore.update({
                        customerId,
                        customerFname: String(cartStore.cart.cartInfoFirstName),
                        customerLname: String(cartStore.cart.cartInfoLastName),
                        customerEmail: String(cartStore.cart.cartInfoEmail),
                        customerPhone: String(cartStore.cart.cartInfoPhone),
                        customerAddress1: String(cartStore.cart.cartInfoAddress1),
                        customerAddress2: String(cartStore.cart.cartInfoAddress2),
                        customerCity: String(cartStore.cart.cartInfoCity),
                        customerState: String(cartStore.cart.cartInfoState),
                        customerZip: Number(cartStore.cart.cartInfoZip),
                        customerLocationId: Number(locationInfo.locationId),
                        customerFlag: isRedFlag ? 'red' : undefined
                    }, authStore.auth.accessToken)

                    if (updateCustomerResult.kind !== 'ok') {
                        setMessageSnackbar({
                            isOpen: true,
                            message: `Something went wrong (${updateCustomerResult.kind}). Please try again later.`,
                            severity: 'error',
                            verticalAnchor: 'top'
                        })
                        return
                    }
                    console.log('handleContinueClick', 'update', customerId)

                } else {
                    /// If it does not, create the customer and retrieve the id
                    const customerResult = await customerStore.create({
                        customerFname: String(cartStore.cart.cartInfoFirstName),
                        customerLname: String(cartStore.cart.cartInfoLastName),
                        customerEmail: String(cartStore.cart.cartInfoEmail),
                        customerPhone: String(cartStore.cart.cartInfoPhone),
                        customerAddress1: String(cartStore.cart.cartInfoAddress1),
                        customerAddress2: String(cartStore.cart.cartInfoAddress2),
                        customerCity: String(cartStore.cart.cartInfoCity),
                        customerState: String(cartStore.cart.cartInfoState),
                        customerZip: Number(cartStore.cart.cartInfoZip),
                        customerLocationId: Number(locationInfo.locationId),
                        customerFlag: isRedFlag ? 'red' : undefined
                    }, authStore.auth.accessToken)

                    if (customerResult.kind !== 'ok') {
                        setMessageSnackbar({
                            isOpen: true,
                            message: `Something went wrong (${customerResult.kind}). Please try again later.`,
                            severity: 'error',
                            verticalAnchor: 'top'
                        })
                        return
                    }
                    console.log('handleContinueClick', 'create', customerResult.customer.identifiers[0].customerId)

                    customerId = customerResult.customer.identifiers[0].customerId
                }

                cartStore.saveCart({
                    ...cartStore.cart,
                    cartCustomerId: customerId
                })

                console.log('handleContinueClick', cartStore.cart)

                navigate(`/${navigationParams(params.mode)}order-details`)

                setIsSavingDetails(false)

            } else if (authStore.auth !== null && authStore.auth?.role?.roleName === "Customer" && cartStore.cart !== null) {
                setIsSavingDetails(true)
                const customerResult = await userDetailStore.update({
                    userDetailId: authStore.auth?.userDetail?.userDetailId,
                    userId: authStore.auth?.userId,
                    userDetailAddress1: String(cartStore.cart.cartInfoAddress1),
                    userDetailAddress2: String(cartStore.cart.cartInfoAddress2),
                    userDetailCity: String(cartStore.cart.cartInfoCity),
                    userDetailState: String(cartStore.cart.cartInfoState),
                    userDetailZip: String(cartStore.cart.cartInfoZip),
                }, authStore.auth.accessToken)
                if (customerResult.kind !== 'ok') {
                    setMessageSnackbar({
                        isOpen: true,
                        message: `Something went wrong (${customerResult.kind}). Please try again later.`,
                        severity: 'error',
                        verticalAnchor: 'top'
                    })
                } else {
                    navigate(`/${navigationParams(params.mode)}order-details`)
                }
                setIsSavingDetails(false)
            }

            //
        }

        let cartTotal = 0.00
        let locationFee = 0.00
        let locationDeliveryFee = 0.00

        if (cartStore.cart) {
            const currentCartSkus = [...cartStore.cart.cartSkus]
            if (currentCartSkus.length > 0) {
                currentCartSkus.forEach(cartSku => {
                    cartTotal += (cartSku.cartSkuAmount - cartSku.cartSkuFreeItemsAmount) * Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)
                })

                let cartTaxMultiplier = 0
                const locationTaxPercent = locationStore.currentLocation?.locationSetting?.locationSettingTaxPercent
                if (locationTaxPercent && locationTaxPercent > 0) {
                    cartTaxMultiplier = locationTaxPercent / 100
                }
                if (cartTaxMultiplier && cartTaxMultiplier > 0) {
                    cartTotal += (cartTaxMultiplier * cartTotal)
                }

                if (locationStore.currentLocation?.locationSetting?.locationSettingFee) {
                    locationFee = locationStore.currentLocation?.locationSetting?.locationSettingFee
                }
                if (locationFee) {
                    cartTotal += locationFee
                }

                // if (cartStore.cart.cartDeliveryMethod === 'local-delivery') {
                if (locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee) {
                    locationDeliveryFee = locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee
                }
                if (locationDeliveryFee) {
                    cartTotal += locationDeliveryFee
                }
                // } else {
                //     locationDeliveryFee = 0.00
                // }


            }
        }

        const isCustomer = authStore.auth !== null && authStore.auth?.role?.roleName === "Customer"

        const validateEmail = (value) => {
            // Regular expression to validate email format
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailRegex.test(value);
        };

        const validatePhone = (value) => {
            if (value && value.length === 10) {
                return true
            }
            return false
        }

        const hasCustomerAuthorizeProfile = hasValue(customerProfileStore.customerProfile)

        return (
            <>

                <main style={{minHeight: '100vh'}}>

                    {/*<CBox sx={{*/}
                    {/*    position: 'absolute', top: 0, right: 0, left: 0, bottom: 0*/}
                    {/*}}>*/}
                    <CContainer sx={{display: 'flex', flexDirection: 'column',}}>
                        <form name="ccform" action="">

                            <CGridContainer flex={1} spacing={2}>
                                <CGrid item xs={12} mt={2}>
                                    <CheckoutBreadcrumb allowedPages={['cart', 'information']}
                                                        currentPage={'information'}/>
                                </CGrid>
                                <CGrid item xs={12} md={12} mb={2} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CCard sx={{
                                        width: "100%",
                                        // height: '90vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <CBox sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: '100%',
                                            alignItems: "center"
                                        }}>
                                            <CBox sx={{flex: 1, pl: 4}}>
                                                <CIconButton
                                                    onClick={() => navigate(`/${navigationParams(params.mode)}cart`)}
                                                >
                                                    <ArrowBackIosNew></ArrowBackIosNew>
                                                </CIconButton>
                                            </CBox>

                                            <POSTitle sx={{
                                                mb: 2,
                                                mt: 2,
                                                textAlign: "center",
                                                fontFamily: "Passion One"
                                            }}>
                                                Information
                                            </POSTitle>
                                            <CBox sx={{flex: 1}}></CBox>
                                        </CBox>
                                        <Divider></Divider>
                                        <CBox sx={{flex: 1, width: "100%", overflowY: "auto"}}>
                                            <CGridContainer sx={{p: 5}} spacing={2}>
                                                <CGrid item xs={12}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        About You
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        error={hasValue(validation.firstName)}
                                                        helperText={hasValue(validation.firstName) ? validation.firstName : null}
                                                        fullWidth label={"First Name"}
                                                        disabled={isCustomer}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoFirstName: event.target.value
                                                            })

                                                            if (hasValue(validation.firstName)) {
                                                                setValidation({...validation, firstName: ''})
                                                            }
                                                        }}
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        value={cartStore.cart?.cartInfoFirstName}>

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        error={hasValue(validation.lastName)}
                                                        helperText={hasValue(validation.lastName) ? validation.lastName : null}
                                                        fullWidth label={"Last Name"}
                                                        disabled={isCustomer}
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoLastName: event.target.value
                                                            })

                                                            if (hasValue(validation.lastName)) {
                                                                setValidation({...validation, lastName: ''})
                                                            }
                                                        }}
                                                        value={cartStore.cart?.cartInfoLastName}>

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        error={hasValue(validation.phone)}
                                                        inputMode={"tel"}
                                                        disabled={isCustomer}
                                                        inputProps={{
                                                            maxLength: 10,
                                                        }}
                                                        placeholder={"2223334444"}
                                                        helperText={hasValue(validation.phone) ? validation.phone : null}
                                                        fullWidth label={"Phone Number"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const checkedPhone = event.target.value.replace(/[^0-9]/g, '')
                                                            if (checkedPhone.length > 10) {
                                                                return
                                                            }
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoPhone: checkedPhone
                                                            })

                                                            if (validatePhone(checkedPhone)) {
                                                                if (hasValue(validation.phone)) {
                                                                    setValidation({...validation, phone: ''})
                                                                }
                                                            } else {
                                                                setValidation({
                                                                    ...validation,
                                                                    phone: 'Phone number must be 10 digits.'
                                                                })
                                                            }

                                                        }}
                                                        value={cartStore.cart?.cartInfoPhone}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        error={hasValue(validation.email)}
                                                        helperText={hasValue(validation.email) ? validation.email : null}
                                                        fullWidth label={"Email"}
                                                        inputMode={"email"}
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        placeholder={"email@domain.com"}
                                                        disabled={isCustomer}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const value = event.target.value
                                                            if (value.length > 50) {
                                                                return
                                                            }
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoEmail: event.target.value
                                                            })
                                                            if (validateEmail(value)) {
                                                                if (hasValue(validation.email)) {
                                                                    setValidation({...validation, email: ''})
                                                                }
                                                            } else {
                                                                setValidation({
                                                                    ...validation,
                                                                    email: 'Please enter a valid email address.'
                                                                })
                                                            }

                                                        }}
                                                        value={cartStore.cart?.cartInfoEmail}>

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={12}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        Billing Address
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        error={hasValue(validation.address1)}
                                                        helperText={hasValue(validation.address1) ? validation.address1 : null}
                                                        fullWidth label={"Address Line 1"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (event.target.value.length > 87) {
                                                                return
                                                            }
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoAddress1: event.target.value
                                                            })

                                                            if (hasValue(validation.address1)) {
                                                                setValidation({...validation, address1: ''})
                                                            }
                                                        }}
                                                        value={cartStore.cart?.cartInfoAddress1}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        fullWidth label={"Address Line 2"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (event.target.value.length > 49) {
                                                                return
                                                            }
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoAddress2: event.target.value
                                                            })

                                                        }}
                                                        value={cartStore.cart?.cartInfoAddress2 && cartStore.cart?.cartInfoAddress2 !== 'null' ? cartStore.cart?.cartInfoAddress2 : ''}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                {/*<CGrid item xs={12}>*/}
                                                {/*    <CBox sx={{width: '100%', my: 1}}>*/}
                                                {/*        {currentIsMapLoaded && <Autocomplete*/}
                                                {/*            onLoad={onAutocompleteLoad}*/}
                                                {/*            options={{types: ['address']}}*/}
                                                {/*            fields={['formatted_address', 'address_component']}*/}
                                                {/*            onPlaceChanged={onPlaceChanged}*/}
                                                {/*            className={'google-map-ac'}*/}
                                                {/*        >*/}
                                                {/*            <TextField*/}
                                                {/*                sx={{flex: 1}}*/}
                                                {/*                // size="small"*/}
                                                {/*                placeholder={'11111 Address, City, State 22222'}*/}
                                                {/*                error={*/}
                                                {/*                    hasValue(validation.address1) ||*/}
                                                {/*                    hasValue(validation.city) ||*/}
                                                {/*                    hasValue(validation.state) ||*/}
                                                {/*                    hasValue(validation.zip)*/}
                                                {/*                }*/}
                                                {/*                helperText={hasValue(validation.address1) ||*/}
                                                {/*                hasValue(validation.city) ||*/}
                                                {/*                hasValue(validation.state) ||*/}
                                                {/*                hasValue(validation.zip) ? `Please enter a valid address. Make sure the zip code is correct.` : null}*/}
                                                {/*                label="Full-Address"*/}
                                                {/*                defaultValue={(cartStore.cart?.cartInfoAddress1 && cartStore.cart?.cartInfoAddress1 !== '') ? `${cartStore.cart?.cartInfoAddress1}, ${cartStore.cart?.cartInfoCity}, ${cartStore.cart?.cartInfoState} ${cartStore.cart?.cartInfoZip}` : ''}*/}
                                                {/*                onChange={(event) => {*/}
                                                {/*                    // cartStore.saveCart({*/}
                                                {/*                    //     ...cartStore.cart,*/}
                                                {/*                    //     cartDeliveryFullAddress: event.target.value*/}
                                                {/*                    // })*/}
                                                {/*                    if (hasValue(validation.address1)) {*/}
                                                {/*                        setValidation({...validation, address1: ''})*/}
                                                {/*                    }*/}
                                                {/*                    if (hasValue(validation.city)) {*/}
                                                {/*                        setValidation({...validation, city: ''})*/}
                                                {/*                    }*/}
                                                {/*                    if (hasValue(validation.state)) {*/}
                                                {/*                        setValidation({...validation, state: ''})*/}
                                                {/*                    }*/}
                                                {/*                    if (hasValue(validation.zip)) {*/}
                                                {/*                        setValidation({...validation, zip: ''})*/}
                                                {/*                    }*/}
                                                {/*                }}*/}
                                                {/*                // value={cartStore.cart?.cartDeliveryFullAddress}*/}
                                                {/*            />*/}
                                                {/*        </Autocomplete>}*/}
                                                {/*    </CBox>*/}
                                                {/*</CGrid>*/}
                                                <CGrid item xs={6} md={4}>
                                                    <CTextField
                                                        error={hasValue(validation.city)}
                                                        helperText={hasValue(validation.city) ? validation.city : null}
                                                        fullWidth label={"City"}
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (event.target.value.length > 59) {
                                                                return
                                                            }
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoCity: event.target.value
                                                            })

                                                            if (event.target.value.length < 2) {
                                                                setValidation({
                                                                    ...validation,
                                                                    city: 'City name must be more than 2 characters.'
                                                                })
                                                            } else {
                                                                if (hasValue(validation.city)) {
                                                                    setValidation({...validation, city: ''})
                                                                }
                                                            }

                                                        }}
                                                        value={cartStore.cart?.cartInfoCity}>

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={6} md={4}>
                                                    <CTextField
                                                        error={hasValue(validation.state)}
                                                        // disabled={true}
                                                        helperText={hasValue(validation.state) ? validation.state : null}
                                                        fullWidth label={"State"}
                                                        inputProps={{
                                                            maxLength: 2
                                                        }}
                                                        placeholder={"CA"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const value = event.target.value.toUpperCase()
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoState: value
                                                            })
                                                            if (validateStateName(value)) {


                                                                if (hasValue(validation.state)) {
                                                                    setValidation({...validation, state: ''})
                                                                }
                                                            } else {
                                                                setValidation({
                                                                    ...validation,
                                                                    state: 'Please enter a valid state name.'
                                                                })
                                                            }

                                                        }}
                                                        value={cartStore.cart?.cartInfoState}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={6} md={4}>
                                                    <CTextField
                                                        type={"number"}
                                                        error={hasValue(validation.zip)}
                                                        helperText={hasValue(validation.zip) ? validation.zip : null}
                                                        fullWidth label={"Zip"}
                                                        inputProps={{
                                                            maxLength: 5
                                                        }}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const value = event.target.value
                                                            if (value.length > 5) {
                                                                return
                                                            }
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoZip: value
                                                            })
                                                            if (validateZipCode(value)) {
                                                                if (hasValue(validation.zip)) {
                                                                    setValidation({...validation, zip: ''})
                                                                }
                                                            } else {
                                                                setValidation({
                                                                    ...validation,
                                                                    zip: 'Please enter a valid zip code.'
                                                                })

                                                            }


                                                        }}
                                                        value={cartStore.cart?.cartInfoZip}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                {/*
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        error={hasValue(validation.address1)}
                                                        helperText={hasValue(validation.address1) ? validation.address1 : null}
                                                        fullWidth label={"Address Line 1"}
                                                        disabled={isCustomer}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoAddress1: event.target.value
                                                            })

                                                            if (hasValue(validation.address1)) {
                                                                setValidation({...validation, address1: ''})
                                                            }
                                                        }}
                                                        value={cartStore.cart?.cartInfoAddress1}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={12} md={6}>
                                                    <CTextField
                                                        fullWidth label={"Address Line 2"}
                                                        disabled={isCustomer}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoAddress2: event.target.value
                                                            })

                                                        }}
                                                        value={cartStore.cart?.cartInfoAddress2}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={6} md={4}>
                                                    <CTextField
                                                        error={hasValue(validation.city)}
                                                        helperText={hasValue(validation.city) ? validation.city : null}
                                                        fullWidth label={"City"}
                                                        disabled={isCustomer}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoCity: event.target.value
                                                            })

                                                            if (hasValue(validation.city)) {
                                                                setValidation({...validation, city: ''})
                                                            }
                                                        }}
                                                        value={cartStore.cart?.cartInfoCity}>

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={6} md={4}>
                                                    <CTextField
                                                        error={hasValue(validation.state)}
                                                        disabled={true}
                                                        helperText={hasValue(validation.state) ? validation.state : null}
                                                        fullWidth label={"State"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoState: event.target.value
                                                            })

                                                            if (hasValue(validation.state)) {
                                                                setValidation({...validation, state: ''})
                                                            }
                                                        }}
                                                        value={cartStore.cart?.cartInfoState}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                <CGrid item xs={6} md={4}>
                                                    <CTextField
                                                        type={"number"}
                                                        error={hasValue(validation.zip)}
                                                        helperText={hasValue(validation.zip) ? validation.zip : null}
                                                        fullWidth label={"Zip"}
                                                        disabled={isCustomer}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            cartStore.saveCart({
                                                                ...cartStore.cart,
                                                                cartInfoZip: event.target.value
                                                            })

                                                            if (hasValue(validation.zip)) {
                                                                setValidation({...validation, zip: ''})
                                                            }
                                                        }}
                                                        value={cartStore.cart?.cartInfoZip}
                                                    >

                                                    </CTextField>
                                                </CGrid>
                                                */}

                                                <CGrid item xs={12}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        Card Info
                                                    </CTypography>
                                                </CGrid>
                                                {hasValue(customerProfileStore?.customerProfile) &&
                                                    <CGrid item xs={12} display={'flex'} flexDirection={'row'}>
                                                        <CRadioGroup
                                                            // row
                                                            // style={{flex: 1}}
                                                            aria-labelledby="demo-form-control-label-placement"
                                                            name="position"
                                                            onChange={(event) => {
                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    useCustomerProfile: event.target.value === "true"
                                                                })
                                                            }}
                                                            defaultValue={cartStore.cart?.useCustomerProfile}
                                                        >
                                                            <CFormControlLabel
                                                                value={true}
                                                                control={<CRadio/>}
                                                                label={`Use ${customerProfileStore?.customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardNumber} (${customerProfileStore?.customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardType})`}
                                                            />
                                                            <CFormControlLabel
                                                                value={false}
                                                                control={<CRadio/>}
                                                                label="New Card"
                                                            />
                                                        </CRadioGroup>
                                                        <CButton onClick={async () => {
                                                            setDeleteCardDialog({
                                                                cardNo: customerProfileStore?.customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardNumber,
                                                                isOpen: true
                                                            })
                                                        }} variant={'outlined'}>Delete Card</CButton>
                                                    </CGrid>}

                                                {(!cartStore.cart?.useCustomerProfile) && <>
                                                    <CGrid item xs={12} md={12}>
                                                        <CTextField fullWidth label={"Card Holder Name"}
                                                                    name={"cc-full-name"}
                                                                    autoComplete="cc-full-name"
                                                                    error={hasValue(validation.cartInfoCardPayer)}
                                                                    helperText={hasValue(validation.cartInfoCardPayer) ? validation.cartInfoCardPayer : null}
                                                                    inputProps={{
                                                                        maxLength: 50
                                                                    }}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        cartStore.saveCart({
                                                                            ...cartStore.cart,
                                                                            cartInfoCardPayer: event.target.value
                                                                        })
                                                                        if (hasValue(validation.cvc)) {
                                                                            setValidation({
                                                                                ...validation,
                                                                                cartInfoCardPayer: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    value={cartStore.cart?.cartInfoCardPayer}
                                                        >

                                                        </CTextField>
                                                    </CGrid>
                                                    <CGrid item xs={12}>
                                                        <CTextField
                                                            type={"number"}
                                                            error={hasValue(validation.cardNo)}
                                                            helperText={hasValue(validation.cardNo) ? validation.cardNo : null}
                                                            fullWidth
                                                            name={"cc-number"}
                                                            autoComplete="cc-number"
                                                            label={`Card Number ${cardType.length !== 0 ? `( ${cardType} ) ` : ''}`}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (event.target.value.length > 16) {
                                                                    return
                                                                }
                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    cartInfoCardNo: event.target.value
                                                                })

                                                                if (hasValue(validation.cardNo)) {
                                                                    setValidation({...validation, cardNo: ''})
                                                                }

                                                                if (checkCardType(event.target.value) !== '') {
                                                                    setCardType(checkCardType(event.target.value))
                                                                } else {
                                                                    setCardType('')
                                                                }
                                                            }}
                                                            value={cartStore.cart?.cartInfoCardNo}
                                                        >

                                                        </CTextField>

                                                    </CGrid>

                                                    <CGrid item xs={4} md={4}>
                                                        <CTextField
                                                            type={"number"}
                                                            error={hasValue(validation.month)}
                                                            helperText={hasValue(validation.month) ? validation.month : null}
                                                            fullWidth label={"MM"}
                                                            select
                                                            name={"cc-exp-month"}
                                                            autoComplete="cc-exp-month"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (event.target.value.length > 2) {
                                                                    return
                                                                }
                                                                if (Number(event.target.value) > 12) {
                                                                    cartStore.saveCart({
                                                                        ...cartStore.cart,
                                                                        cartInfoCardMonth: 12
                                                                    })
                                                                    return
                                                                }
                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    cartInfoCardMonth: event.target.value
                                                                })

                                                                if (hasValue(validation.month)) {
                                                                    setValidation({...validation, month: ''})
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    cartInfoCardMonth: cartStore.cart?.cartInfoCardMonth?.padStart(2, "0")
                                                                })
                                                            }}
                                                            value={cartStore.cart?.cartInfoCardMonth}
                                                        >
                                                            {Array.from({length: 12}, (_, index) => {
                                                                const month = `${index + 1}`.padStart(2, "0")
                                                                return (
                                                                    <CMenuItem key={month} value={month}>
                                                                        {month}
                                                                    </CMenuItem>
                                                                );
                                                            })}
                                                        </CTextField>
                                                    </CGrid>
                                                    <CGrid item xs={4} md={4}>
                                                        <CTextField
                                                            type={"number"}
                                                            error={hasValue(validation.year)}
                                                            helperText={hasValue(validation.year) ? validation.year : null}
                                                            fullWidth label={"YY"}
                                                            select
                                                            name={"cc-exp-year"}
                                                            autoComplete="cc-exp-year"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (event.target.value.length > 2) {
                                                                    return
                                                                }

                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    cartInfoCardYear: event.target.value
                                                                })

                                                                if (hasValue(validation.year)) {
                                                                    setValidation({...validation, year: ''})
                                                                }
                                                            }}
                                                            value={cartStore.cart?.cartInfoCardYear}
                                                        >
                                                            {Array.from({length: 6}, (_, index) => {
                                                                const year = `${(new Date().getFullYear() % 100) + index}`.padStart(2, '0');
                                                                return (
                                                                    <CMenuItem key={year} value={year}>
                                                                        {year}
                                                                    </CMenuItem>
                                                                );
                                                            })}
                                                        </CTextField>
                                                    </CGrid>
                                                    <CGrid item xs={4} md={4}>
                                                        <CTextField
                                                            type={"number"}
                                                            error={hasValue(validation.cvc)}
                                                            helperText={hasValue(validation.cvc) ? validation.cvc : null}
                                                            fullWidth label={"CVV"}
                                                            name={"cc-csc"}
                                                            autoComplete="cc-csc"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (event.target.value.length > 4) {
                                                                    return
                                                                }
                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    cartInfoCardCVC: event.target.value
                                                                })
                                                                if (event.target.value.length < 3) {
                                                                    setValidation({
                                                                        ...validation,
                                                                        cvc: 'Please enter valid CVV number.'
                                                                    })
                                                                } else {
                                                                    if (hasValue(validation.cvc)) {
                                                                        setValidation({...validation, cvc: ''})
                                                                    }
                                                                }

                                                            }}
                                                            value={cartStore.cart?.cartInfoCardCVC}
                                                        >

                                                        </CTextField>
                                                    </CGrid>
                                                    {console.log('cartStore.cart?.savePaymentInfo', cartStore.cart?.savePaymentInfo)}
                                                    {authStore.auth?.role?.roleName !== "Guest" && <CGrid item xs={12}>
                                                        <CFormControlLabel control={<CCheckbox
                                                            checked={cartStore.cart?.savePaymentInfo === true}
                                                            defaultChecked={cartStore.cart?.savePaymentInfo === true}
                                                            onChange={(value) => {
                                                                cartStore.saveCart({
                                                                    ...cartStore.cart,
                                                                    savePaymentInfo: !(cartStore.cart?.savePaymentInfo)
                                                                })
                                                            }}/>} label="Save Payment Info"/>
                                                    </CGrid>}
                                                </>}


                                            </CGridContainer>
                                        </CBox>
                                        <Divider></Divider>
                                        <CGridContainer spacing={2} py={4} px={2}>
                                            <CGrid item xs={12} md={9}>
                                                <CTypography sx={{fontWeight: "600", fontSize: "24px"}}>
                                                    {`To be paid:   $${cartTotal.toFixed(2)}`}
                                                </CTypography>
                                            </CGrid>


                                            {/*<CGrid item xs={12} md={3}>*/}
                                            {/*    <CButton*/}
                                            {/*        fullWidth*/}
                                            {/*        variant={"outlined"}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            // navigate(`/${navigationParams(params.mode)}disclaimer`)*/}
                                            {/*            // setShowCancelOrderDialog(true)*/}
                                            {/*            navigate('/')*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Back to Store*/}
                                            {/*    </CButton>*/}
                                            {/*</CGrid>*/}
                                            <CGrid item xs={12} md={3}>

                                                <CButton fullWidth variant={"contained"} onClick={handleContinueClick}>
                                                    {isSavingDetails ? <CCircularProgress
                                                        sx={{color: "white"}}
                                                        size={24}/> : 'Continue'}
                                                </CButton>
                                            </CGrid>
                                        </CGridContainer>

                                    </CCard>


                                </CGrid>
                            </CGridContainer>
                        </form>
                    </CContainer>
                    {/*</CBox>*/
                    }
                </main>

                <CCancelOrderConfirmationDialog
                    mode={String(params.mode)}
                    open={showCancelOrderDialog}
                    onDecline={() => {
                        setShowCancelOrderDialog(false)
                    }}
                />

                <CSnackbar open={messageSnackbar.isOpen} autoHideDuration={3000} onClose={() => {
                    setMessageSnackbar({
                        isOpen: false,
                        message: "",
                        severity: 'error',
                        verticalAnchor: 'bottom'
                    })
                }} anchorOrigin={{
                    vertical: messageSnackbar.verticalAnchor,
                    horizontal: 'right'
                }}>
                    <CAlert onClose={() => {
                        setMessageSnackbar({
                            isOpen: false,
                            message: "",
                            severity: 'info',
                            verticalAnchor: 'bottom'
                        })
                    }} severity={messageSnackbar.severity} sx={{width: '100%'}}>
                        {messageSnackbar.message}
                    </CAlert>
                </CSnackbar>
                <CConfirmationDialog
                    text={`Are you sure you want to delete the ${deleteCardDialog.cardNo} card from your saved payment methods?`}
                    onAccept={async () => {
                        customerProfileStore.remove(authStore.auth?.userId ?? -1, authStore.auth?.accessToken ?? '')
                        cartStore.saveCart({
                            ...cartStore.cart,
                            useCustomerProfile: false,
                            customerProfileId: '',
                            customerPaymentProfileId: ''
                        })
                        setDeleteCardDialog({...deleteCardDialog, isOpen: false})
                    }}
                    acceptTitle={"Delete"}
                    declineTitle={"Close"}
                    onDecline={() => setDeleteCardDialog({...deleteCardDialog, isOpen: false})}
                    open={deleteCardDialog.isOpen}
                />
            </>
        )
            ;
    }
)
