import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CCircularProgress,
    CGrid,
    CGridContainer,
    CTypography,
    CIconButton,
    CContainer,
    CCard,
    POSTitle,
    DisclaimerDialog,
    DeliveryMethodDialog,
    Footer,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {
    ArrowBackIosNew,
} from "@mui/icons-material";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {Page as PageModel} from "../../../models/page/page";
import Editor, {composeDecorators} from '@draft-js-plugins/editor';
import {ContentState, EditorState, convertFromRaw} from 'draft-js';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createImagePlugin from '@draft-js-plugins/image';
import createEmojiPlugin from '@draft-js-plugins/emoji';

import createAlignmentPlugin from '@draft-js-plugins/alignment';

import "@draft-js-plugins/emoji/lib/plugin.css"
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';

const emojiPlugin = createEmojiPlugin({
    useNativeArt: true
});

const imagePlugin = createImagePlugin();

const linkifyPlugin = createLinkifyPlugin();

const alignmentPlugin = createAlignmentPlugin();

const ImageBlock = React.forwardRef(
    (
        {        // @ts-ignore
            block,
            // @ts-ignore
            blockProps,
            // @ts-ignore
            customStyleMap,
            // @ts-ignore
            customStyleFn,
            // @ts-ignore
            decorator,
            // @ts-ignore
            forceSelection,
            // @ts-ignore
            offsetKey,
            // @ts-ignore
            selection,
            // @ts-ignore
            tree,
            // @ts-ignore
            contentState,
            // @ts-ignore
            blockStyleFn,
            // @ts-ignore
            preventScroll,
            // @ts-ignore
            style,
            ...elementProps
        },
        ref
    ) => {

        const entity = contentState.getEntity(block.getEntityAt(0));

        const { src } = entity.getData();
        return  <img
            // @ts-ignore
            ref={ref}
            src={src}
            alt="Editor content"
            style={{ maxWidth: '100%', objectFit: 'cover', ...style }}
            {...elementProps}
        />
    }


);

const createImageBlockPlugin = (config = {}) => {
    // @ts-ignore
    const component = config.decorator
        // @ts-ignore
        ? config.decorator(ImageBlock)
        : ImageBlock;
    return {
        blockRendererFn: (block, { getEditorState }) => {
            if (block.getType() === 'atomic') {
                const contentState = getEditorState().getCurrentContent();
                const entity = contentState.getEntity(block.getEntityAt(0));
                const type = entity.getType();

                if (type === 'IMAGE') {
                    const { src, width, height } = entity.getData();
                    console.log('block', { src, width, height })
                    return {
                        component,
                        editable: false,
                    };
                }
            }
            return null;
        },
    };
};

const decorator = composeDecorators(
    alignmentPlugin.decorator,
);

const imageBlockPlugin = createImageBlockPlugin({ decorator });

export const Page = observer(
    function Page() {
        const navigate = useNavigate();

        const {cartStore, pageStore, locationStore} = useStores()

        const params = useParams();
        const [isGettingPage, setIsGettingPage] = React.useState(true)
        const [page, setPage] = React.useState<PageModel | null>(null)
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const [isGettingSku, setIsGettingSku] = React.useState(true)
        const [editorState, setEditorState] = React.useState(() =>
            EditorState.createEmpty()
        );

        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, locationInfoState, guestAuthState} = guestAuth

        const loadPage = async () => {
            setIsGettingPage(true)
            const result = await pageStore.findAll({
                pageSlug: params.slug
            }, guestAuthToken)
            if (result.kind === "ok") {
                if (result.pages.length > 0) {
                    setPage(result.pages[0])
                    document.title = `Pages | ${result.pages[0].pageTitle}`;
                }
            }
            setIsGettingPage(false)

        };

        const init = async () => {
            setIsGettingSku(true)
            await loadPage()
            setIsGettingSku(false)
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        useEffect(() => {
            ;(async () => {
                if (guestAuthState === 'loaded') {
                    await init()
                }
            })()
        }, [guestAuthToken, guestAuthState, params]);

        useEffect(() => {

            if (page && page.pageContent) {
                try {
                    const parsedContentState = JSON.parse(page?.pageContent || '');
                    const editorState = EditorState.createWithContent(convertFromRaw(parsedContentState));

                    setEditorState(editorState)

                } catch (e) {
                    const contentState = ContentState.createFromText(page?.pageContent || '');
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState)
                }
            }
        }, [page]);


        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Pages`;
            })()

        }, [locationInfo, locationInfoState])

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        return (
            <>

                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>


                        <CGridContainer sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CCard sx={{
                                width: "100%",
                                // minHeight: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 1
                            }}>
                                {isGettingSku ?
                                    <CBox sx={{
                                        display: 'flex',
                                        minHeight: '60vh',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%'
                                    }}>
                                        <CCircularProgress
                                            size={32}/>
                                    </CBox>

                                    :
                                    <>
                                        <CBox sx={{display: "flex", flexDirection: "row", width: '100%', pt: '22px'}}>
                                            <CIconButton
                                                sx={{width: '64px', ml: '22px'}}
                                                onClick={() => navigate(-1)}
                                            >
                                                <ArrowBackIosNew></ArrowBackIosNew>
                                            </CIconButton>
                                            <POSTitle sx={{
                                                mb: 2,
                                                mt: 2,
                                                flex: 1,
                                                textAlign: "center",
                                                fontFamily: "Passion One",
                                            }}>
                                                {page?.pageTitle}
                                            </POSTitle>
                                            <CBox
                                                sx={{width: '64px', mr: '22px'}}
                                            />
                                        </CBox>
                                        <CGridContainer spacing={2} p={2} height={'100%'}>
                                            <CGrid item xs={12}>
                                                <CBox sx={{margin: '17px'}}>
                                                    <Editor
                                                        editorState={editorState}
                                                        readOnly={true}
                                                        plugins={[
                                                            emojiPlugin,
                                                            imagePlugin,
                                                            linkifyPlugin,
                                                            alignmentPlugin,
                                                            imageBlockPlugin]}
                                                    />
                                                </CBox>
                                            </CGrid>

                                            {/*<CGrid item xs={12}>*/}
                                            {/*    <CBox sx={{margin: '17px'}}>*/}
                                            {/*        {page?.pageContent && page.pageContent.split("\n").map((i, key) => {*/}
                                            {/*            return <CTypography fontSize={16} key={key}>{i}</CTypography>;*/}
                                            {/*        })}*/}
                                            {/*    </CBox>*/}

                                            {/*</CGrid>*/}

                                        </CGridContainer>
                                    </>

                                }

                            </CCard>
                        </CGridContainer>

                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );
    }
)
