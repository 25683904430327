import {
    CBox,
    CButton, CCheckbox,
    CCircularProgress, CDialog,
    CGrid,
    CGridContainer, CInterleavedTableRow,
    CLinearProgress,
    CTable, CTableBody, CTableCell, CTableHead, CTableRow,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {isEmpty} from "validate.js";
import * as React from "react";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../firebase";
import {makeId} from "../../utils/logs";
import {useStores} from "../../models";

export const SkuImagesDialog = (props) => {

    const {skuImagesDialog, setSkuImagesDialog, handleClose, itemRows, currentAuthToken} = props;

    const {skuImageStore} = useStores()
    const {isGettingSkuImages} = skuImageStore

    const getSkuImages = async (skuId) => {
        await skuImageStore.findAll({
            skuId,
            page: 0,
            perPage: 100
        }, currentAuthToken)
    }

    const handlePickedFile = ({target}) => {
        const storageRef = ref(storage, `${skuImagesDialog.skuId}/${makeId(16)}`);
        const metadata = {
            contentType: 'image/jpeg',
        };
        uploadBytes(storageRef, target.files[0], metadata).then(() => {
            getDownloadURL(storageRef)
                .then(async (url) => {
                    const result = await skuImageStore.create({
                        skuImageLink: url,
                        skuId: skuImagesDialog.skuId
                    }, currentAuthToken)
                    setSkuImagesDialog({
                        ...skuImagesDialog,
                        data: [...skuImagesDialog.data, {
                            skuImageId: Number(result.skuImage.identifiers[0].skuImageId),
                            skuImageLink: url,
                            skuId: Number(skuImagesDialog.skuId),
                            skuImageCreatedAt: '',
                            skuImageUpdatedAt: ''
                        }]
                    })
                    await getSkuImages(skuImagesDialog.skuId)
                })
                .catch((error) => {
                    console.log(error)
                    // Handle any errors
                });

        });

    }

    const handleImageRemove = async (id: number) => {
        let currentImages = [...skuImagesDialog.data]
        currentImages = currentImages.filter(image => image.skuImageId !== id)
        setSkuImagesDialog({
            ...skuImagesDialog,
            data: currentImages
        })
        console.log('handleImageRemove', id)
        await skuImageStore.remove({skuImageId: id}, currentAuthToken)
        await getSkuImages(skuImagesDialog.skuId)
    }

    return(
        <CDialog open={skuImagesDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
            sx: {
                borderRadius: "37px"
            }
        }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4} mr={2}>
                    Sku Images
                </CTypography>

                {/*<CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">*/}
                {/*    <CTab label="General" {...a11yProps(0)} />*/}
                {/*    <CTab label="Images" {...a11yProps(1)} />*/}
                {/*    /!*<CTab label="Prices" {...a11yProps(2)} />*!/*/}
                {/*</CTabs>*/}
            </CBox>
            <Divider></Divider>
            {isGettingSkuImages && <CLinearProgress></CLinearProgress>}
            <CGridContainer spacing={2} p={0}>
                <CGrid item xs={12} maxHeight={'70vh'} sx={{
                    overflowY: 'scroll',
                }}>
                    {isEmpty(itemRows) ?
                        <CTypography m={4} textAlign={'center'} variant={'body1'}> This Sku has no images.
                            Please upload new images using the {/*@ts-ignore*/}
                            <CButton mx={2} variant="contained" component="label">
                                {isGettingSkuImages ?
                                    <CCircularProgress sx={{color: "white"}} size={24}/> : 'Upload'}
                                <input hidden accept="image/*" multiple type="file"
                                       onChange={handlePickedFile}/>
                            </CButton> button. </CTypography> :
                        <CTable aria-label="simple table">
                            <CTableHead>
                                <CTableRow>
                                    <CTableCell sx={{
                                        fontSize: "20px",
                                        paddingLeft: "32px",
                                        width: "80px"
                                    }}>
                                        {/*@ts-ignore*/}
                                        <CButton variant="contained" component="label">
                                            Upload
                                            <input hidden accept="image/*" multiple type="file"
                                                   onChange={handlePickedFile}/>
                                        </CButton>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        fontSize: "20px",
                                        paddingRight: "32px"
                                    }} align="left">Link</CTableCell>
                                    <CTableCell sx={{
                                        fontSize: "20px",
                                        paddingRight: "32px"
                                    }} align="right">Actions</CTableCell>
                                </CTableRow>
                            </CTableHead>

                            <CTableBody>

                                {itemRows.map((row, index) => (
                                    <CInterleavedTableRow
                                        key={row.id + index}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <CTableCell component="th" scope="row" sx={{
                                            paddingLeft: "24px",
                                            flexDirection: "row",
                                            display: "flex",
                                            width: "80px"
                                        }}>
                                            <CCheckbox
                                                color="primary"
                                                // checked={isItemSelected}
                                                // inputProps={{
                                                //     'aria-labelledby': labelId,
                                                // }}
                                            />
                                            <CBox
                                                style={{
                                                    background: `url(${row.link})`,
                                                    borderRadius: "22px",
                                                    height: "44px",
                                                    width: "44px",
                                                    backgroundSize: "cover",
                                                }}
                                            />
                                        </CTableCell>
                                        <CTableCell sx={{
                                            paddingRight: "32px"
                                        }} align="left">
                                            <a href={row.link}>
                                                <CTypography>
                                                    Preview
                                                </CTypography>
                                            </a>
                                        </CTableCell>
                                        <CTableCell sx={{
                                            paddingRight: "32px"
                                        }} align="right">
                                            <CButton variant={"contained"} color={"error"} size={"small"}
                                                     sx={{height: '32px'}}
                                                     onClick={() => handleImageRemove(row.id)}>Remove</CButton>
                                        </CTableCell>
                                    </CInterleavedTableRow>
                                ))}
                            </CTableBody>
                        </CTable>
                    }
                </CGrid>

            </CGridContainer>
            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
            </CBox>
        </CDialog>
    )
}