import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const LocationTypeModel = types.model("LocationType").props({
    locationTypeId: types.number,
    locationTypeName: types.maybeNull(types.string),
    locationTypeStatus: types.maybeNull(types.string),
    locationTypeCreatedAt: types.maybeNull(types.string),
    locationTypeUpdatedAt: types.maybeNull(types.string),
})

type LocationTypeType = Instance<typeof LocationTypeModel>
export interface LocationType extends LocationTypeType {}
type LocationTypeSnapshotType = SnapshotOut<typeof LocationTypeModel>
export interface LocationTypeSnapshot extends LocationTypeSnapshotType {}

