import {
    CBox,
    CButton,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {isMapLoaded} from "../../app";
import {GoogleMap} from "@react-google-maps/api";
import {LocationOn} from "@mui/icons-material";

export const LocationDialog = (props) => {
    const {
        locationDialog,
        setLocationDialog,
        handleClose,
        storeAdmins,
        validation,
        setValidation,
        containerStyle,
        onLoad,
        onUnmount,
        handleSubmit
    } = props

    return (
        <CDialog
            open={locationDialog.isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={"lg"}
            PaperProps={{
                sx: {
                    borderRadius: "37px"
                }
            }}
        >
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4}>
                    Add Location
                </CTypography>
            </CBox>
            <Divider></Divider>
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Name"}
                                error={hasValue(validation.name)}
                                helperText={hasValue(validation.name) ? validation.name : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationName: event.target.value}
                                    });
                                    if (hasValue(validation.name)) {
                                        setValidation({...validation, name: ''})
                                    }
                                }} value={locationDialog.data.locationName}/>
                </CGrid>

                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Store Type"} select
                                error={hasValue(validation.storeType)}
                                helperText={hasValue(validation.storeType) ? validation.storeType : null}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationStoreType: value}
                                    });
                                    if (hasValue(validation.storeType)) {
                                        setValidation({...validation, storeType: ''})
                                    }
                                }} value={locationDialog.data.locationStoreType}>
                        <CMenuItem value={'global'}>Global</CMenuItem>
                        <CMenuItem value={'local'}>Local</CMenuItem>
                    </CTextField>
                </CGrid>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Slug"}
                                disabled={locationDialog.data.locationTypeId !== 1}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationSlug: event.target.value}
                                    });
                                }} value={locationDialog.data.locationSlug}/>
                </CGrid>
                {/*<CGrid item xs={2}>*/}
                {/*    <CTextField fullWidth label={"Email"}*/}
                {/*                error={hasValue(validation.email)}*/}
                {/*                helperText={hasValue(validation.email) ? validation.email : null}*/}
                {/*                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                {/*                    setLocationDialog({*/}
                {/*                        ...locationDialog,*/}
                {/*                        data: {...locationDialog.data, locationEmail: event.target.value}*/}
                {/*                    });*/}
                {/*                    if (hasValue(validation.email)) {*/}
                {/*                        setValidation({...validation, email: ''})*/}
                {/*                    }*/}
                {/*                }} value={locationDialog.data.locationEmail}/>*/}
                {/*</CGrid>*/}
                {/*<CGrid item xs={2}>*/}
                {/*    <CTextField fullWidth label={"Phone Number"}*/}
                {/*                error={hasValue(validation.phoneNumber)}*/}
                {/*                helperText={hasValue(validation.phoneNumber) ? validation.phoneNumber : null}*/}
                {/*                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                {/*                    setLocationDialog({*/}
                {/*                        ...locationDialog,*/}
                {/*                        data: {...locationDialog.data, locationPhone: event.target.value}*/}
                {/*                    });*/}
                {/*                    if (hasValue(validation.phoneNumber)) {*/}
                {/*                        setValidation({...validation, phoneNumber: ''})*/}
                {/*                    }*/}
                {/*                }} value={locationDialog.data.locationPhone}/>*/}
                {/*</CGrid>*/}
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Admin"} select
                                disabled={!hasValue(locationDialog.data.locationTypeId)}
                                error={hasValue(validation.admin)}
                                helperText={hasValue(validation.admin) ? validation.admin : null}
                                onChange={(event) => {
                                    const value = Number(event.target.value);
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationAdminId: value}
                                    });
                                    if (hasValue(validation.admin)) {
                                        setValidation({...validation, admin: ''})
                                    }
                                }} value={locationDialog.data.locationAdminId}>
                        <CMenuItem value={0}>None</CMenuItem>
                        {storeAdmins.map(user => {
                            if (locationDialog.mode === 'add') {
                                if (user.userLocationId === null || user.userLocationId === undefined || user.userLocationId === 0) {
                                    return <CMenuItem value={user.userId}>{user.userUsername}</CMenuItem>
                                } else {
                                    return null
                                }
                            } else {
                                return <CMenuItem value={user.userId}>{user.userUsername}</CMenuItem>
                            }
                        })}
                    </CTextField>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Address Line 1"}
                                error={hasValue(validation.addressLine1)}
                                helperText={hasValue(validation.addressLine1) ? validation.addressLine1 : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationAddress1: event.target.value}
                                    });
                                    if (hasValue(validation.addressLine1)) {
                                        setValidation({...validation, addressLine1: ''})
                                    }
                                }} value={locationDialog.data.locationAddress1}/>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Address Line 2"}
                                error={hasValue(validation.addressLine2)}
                                helperText={hasValue(validation.addressLine2) ? validation.addressLine2 : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationAddress2: event.target.value}
                                    });
                                    if (hasValue(validation.addressLine2)) {
                                        setValidation({...validation, addressLine2: ''})
                                    }
                                }} value={locationDialog.data.locationAddress2}/>
                </CGrid>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"City"}
                                error={hasValue(validation.city)}
                                helperText={hasValue(validation.city) ? validation.city : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationCity: event.target.value}
                                    });
                                    if (hasValue(validation.city)) {
                                        setValidation({...validation, city: ''})
                                    }
                                }} value={locationDialog.data.locationCity}/>
                </CGrid>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"State"}
                                error={hasValue(validation.state)}
                                helperText={hasValue(validation.state) ? validation.state : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationState: event.target.value}
                                    });
                                    if (hasValue(validation.state)) {
                                        setValidation({...validation, state: ''})
                                    }
                                }} value={locationDialog.data.locationState}/>
                </CGrid>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Zip"}
                                error={hasValue(validation.zip)}
                                helperText={hasValue(validation.zip) ? validation.zip : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationDialog({
                                        ...locationDialog,
                                        data: {...locationDialog.data, locationZip: Number(event.target.value)}
                                    });
                                    if (hasValue(validation.zip)) {
                                        setValidation({...validation, zip: ''})
                                    }
                                }} value={locationDialog.data.locationZip}/>
                </CGrid>
                <CGrid item xs={12}>
                    <CBox mb={4} sx={{
                        position: "relative",
                        flexGrow: 1,
                        width: "100%",
                        borderRadius: '34px',
                        border: "1px solid #CACACA",
                        overflow: "hidden",
                        height: "200px"
                    }}>
                        {
                            isMapLoaded ? (
                                <GoogleMap
                                    center={locationDialog.data.locationLat &&
                                    locationDialog.data.locationLong ?
                                        {
                                            lat: locationDialog.data.locationLat,
                                            lng: locationDialog.data.locationLong
                                        }
                                        :
                                        {lat: 36.778259, lng: -119.417931}}
                                    mapContainerStyle={containerStyle}
                                    zoom={15}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    options={{
                                        disableDefaultUI: true
                                    }}
                                >
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <CBox sx={{zIndex: 999}}>
                                        <LocationOn sx={{fontSize: 48}} color={"primary"}/>

                                    </CBox>

                                </GoogleMap>
                            ) : <></>
                        }

                    </CBox>
                </CGrid>
            </CGridContainer>

            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
                <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                    {locationDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                   size={24}/> : locationDialog.mode === 'add' ? 'Add' : 'Edit'}
                </CButton>
            </CBox>
        </CDialog>
    )
}