import {CBox, CTypography, CIconButton} from ".."
import * as React from "react";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";

export const CartItem = (props) => {

    const {index, title, subtitle, hideDivider, quantity, price , sum, discountSum, onRemoveClick, onAddClick} = props

    return <CBox sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: index %2 == 0 ? `rgba(8, 59, 28, 0.03)` : 'transparent'
    }}>
        <CBox sx={{display: "flex", flexDirection: "row", pl: "23px", pr: "10px", marginBottom: "8px", alignItems: "center"}}>
            <CTypography sx={{fontWeight: "600", fontSize: "20px"}}>
                {title}
            </CTypography>

            <CBox sx={{flex: 1}}/>

            <CIconButton onClick={onRemoveClick}>
                <RemoveCircle sx={{color: "#F44336"}}></RemoveCircle>
            </CIconButton>
            <CTypography>
                {quantity}
            </CTypography>
            <CIconButton onClick={onAddClick}>
                <AddCircle sx={{color: "#4CAF50"}}></AddCircle>
            </CIconButton>
        </CBox>
        <CBox sx={{display: "flex", flexDirection: "row", pl: "23px", pr: "32px", marginBottom: "13px"}}>
        <CTypography sx={{fontWeight: "300", fontSize: "18px"}}>
            {subtitle}
        </CTypography>
        </CBox>
        <CBox sx={{display: "flex", flexDirection: "row", pl: "23px", pr: "32px", marginBottom: "13px"}}>

            <CTypography fontSize={'15px'}>
                Price
            </CTypography>
            <CBox sx={{flex: 1}}/>
            <CTypography fontSize={'15px'}>
                {price}
            </CTypography>
        </CBox>
        <CBox sx={{display: "flex", flexDirection: "row", pl: "23px", pr: "32px", marginBottom: "23px"}}>
            <CTypography fontSize={'15px'}>
                Sum
            </CTypography>
            <CBox sx={{flex: 1}}/>
            {hasValue(discountSum) && (Number(discountSum.replace('$', '')) < Number(sum.replace('$', ''))) && <CTypography fontSize={'15px'} sx={{textDecoration: "line-through", mr: 2, color: "#757575"}}>
                {sum}
            </CTypography>}
            <CTypography fontSize={'15px'}>
                {discountSum}
            </CTypography>
        </CBox>
        {hideDivider !== true && <Divider></Divider>}
    </CBox>
}