import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {LocationsApi} from "../../services/api/locations-api"
import {LocationModel, LocationSnapshot} from "../location/location"
import {logInfo} from "../../utils/logs"
import {
    LocationCreateParams,
    LocationDeleteParams,
    LocationFindAllParams,
    LocationFindOneParams,
    LocationUpdateParams
} from "../../services/api";
import {hasValue} from "../../utils/empty-check";

function locationStoreLog(functionName, message) {
    logInfo({
        fileName: "location-store.ts",
        className: "locationStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const LocationStoreModel = types
    .model("LocationStore")
    .props({
        location: types.optional(types.maybeNull(LocationModel), null),
        currentLocation: types.maybeNull(LocationModel),
        locations: types.optional(types.array(LocationModel), []),
        lockboxes: types.optional(types.array(LocationModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveLocation: (locationSnapshot: LocationSnapshot) => {
            try {
                self.location = cast(locationSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    locationStoreLog("saveLocation", e.message)
                }
                // recordError(e)
            }
        },
        saveCurrentLocation: (locationSnapshot: LocationSnapshot) => {
            try {
                self.currentLocation = cast(locationSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    locationStoreLog("saveCurrentLocation", e.message)
                }
                // recordError(e)
            }
        },
        saveLocations: (locationsSnapshot: LocationSnapshot[]) => {
            try {
                detach(self.locations);
                self.locations = cast([...locationsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    locationStoreLog("saveLocation", e.message)
                }
            }
        },
        saveLockboxes: (locationsSnapshot: any[]) => {
            try {
                detach(self.locations);
                self.lockboxes = cast([...locationsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    locationStoreLog("saveLockboxes", e.message)
                }
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: LocationCreateParams, token: string) {
            self.environment.addAuth(token)
            const locationApi = new LocationsApi(self.environment.api)
            const result = yield locationApi.create(params)


            if (result.kind === "ok") {
                self.saveLocation(result.location)
            } else {
                locationStoreLog("create", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: LocationFindAllParams, token: string, saveToMst:boolean) {
            self.environment.addAuth(token)
            const locationApi = new LocationsApi(self.environment.api)
            const result = yield locationApi.findAll(params)


            if (result.kind === "ok") {
                if (saveToMst) {
                    self.saveLocations(result.locations)
                }
            } else {
                locationStoreLog("findAll", result.kind)
            }
            return result
        }),
        findBySlug: flow(function* (locationSlug: string, token: string) {
            self.environment.addAuth(token)
            const locationApi = new LocationsApi(self.environment.api)
            const result = yield locationApi.findAll({locationSlug})


            if (result.kind === "ok") {
                if (hasValue(result.locations)) {
                    self.saveCurrentLocation(result.locations[0])
                }
            } else {
                locationStoreLog("findBySlug", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: LocationFindOneParams, token: string,  saveToMst:boolean) {
            self.environment.addAuth(token)
            const locationApi = new LocationsApi(self.environment.api)
            const result = yield locationApi.findOne(params)


            if (result.kind === "ok") {
                if (saveToMst) {
                    self.saveLocation(result.location)
                }
            } else {
                locationStoreLog("findOne", result.kind)
            }
            return result
        }),
        update: flow(function* (params: LocationUpdateParams, token: string) {
            self.environment.addAuth(token)
            const locationApi = new LocationsApi(self.environment.api)
            const result = yield locationApi.update(params)


            if (result.kind === "ok") {
                self.saveLocation(result.location)
            } else {
                locationStoreLog("update", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: LocationDeleteParams, token: string) {
            self.environment.addAuth(token)
            const locationApi = new LocationsApi(self.environment.api)
            return yield locationApi.remove({locationId: params.locationId})
        }),

    }))

type LocationStoreType = Instance<typeof LocationStoreModel>

export interface LocationStore extends LocationStoreType {
}

type LocationStoreSnapshotType = SnapshotOut<typeof LocationStoreModel>

export interface LocationStoreSnapshot extends LocationStoreSnapshotType {
}

export const createLocationStoreDefaultModel = () => types.optional(LocationStoreModel, {})
