import {
    CBox, CGridContainer,
    CLinearProgress,
    CTable,
    CTableBody,
    CTableCell,
    CTableContainer,
    CTableHead,
    CTablePagination,
    CTableRow,
    CTableSortLabel
} from "../mui-extracted";
import {generateTableRows, getCellValue} from "../../utils/table-rows";
import {visuallyHidden} from '@mui/utils';
import {CTypography} from "../mui-extracted/typography";
import {useMediaQuery} from "@mui/material";
import {Divider} from "../divider";
import { generateListRows } from "../../utils/list-rows";

export const Table = ({
                          columns,
                          rows,
                          rowsPerPage,
                          page,
                          onPageChange,
                          onRowsPerPageChange,
                          count,
                          orderBy,
                          order,
                          onRequestSort,
                          isLoading,
                          sx = {},
                          noRowTitleOnSmallSize = false,
                      }) => {

    const createSortHandler =
        (property) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const shouldRenderList = isSmallScreen || isExtraSmallScreen

    if (shouldRenderList) {
        return <CBox sx={{width: '100%', maxHeight: '100%', overflowY: 'scroll'}}>
            {
                generateListRows(rows, columns, noRowTitleOnSmallSize)
            }
            {count > 10 && <CTablePagination
                page={page}
                sx={{
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: 'sticky',
                    bottom: 0,
                    background: '#fff',
                    zIndex: 100,
                }}
                rowsPerPageOptions={[5, 10, 25, 50, 100] as number[]}
                labelRowsPerPage={<CTypography variant={'body2'}>Rows</CTypography>}
                count={count}
                rowsPerPage={rowsPerPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />}
        </CBox>
    }

    return (
        <>
            <CTableContainer sx={{maxHeight: '100%', ...sx}}>
                {isLoading && <CLinearProgress/>}

                <CTable stickyHeader>
                    <CTableHead sx={{zIndex: 100}}>

                        <CTableRow>
                            {columns.map((column, index) => (
                                <CTableCell
                                    component="th"
                                    key={column.field}
                                    className={column.headerClassName}
                                    style={{
                                        width: column.width,
                                        flex: column.flex,
                                        paddingLeft: index === 0 ? '32px' : '16px',
                                    }}
                                >
                                    <CTableSortLabel
                                        active={orderBy === column.field}
                                        direction={orderBy === column.field ? order.toLowerCase() : 'asc'}
                                        onClick={createSortHandler(column.field)}
                                    >
                                        {column.headerName}
                                        {orderBy === column.field ? (
                                            <CBox component="span" sx={visuallyHidden}>
                                                {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                            </CBox>
                                        ) : null}
                                    </CTableSortLabel>

                                </CTableCell>
                            ))}
                        </CTableRow>

                    </CTableHead>

                    <CTableBody sx={{overflowX: 'auto', height: '100%'}}>{generateTableRows(rows, columns)}</CTableBody>

                </CTable>

            </CTableContainer>
            {count > 10 && <CTablePagination
                page={page}
                sx={{
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: 'sticky',
                    bottom: 0,
                    background: '#fff',
                    zIndex: 100,
                }}
                rowsPerPageOptions={[5, 10, 25, 50, 100] as number[]}
                labelRowsPerPage={<CTypography variant={'body2'}>Rows</CTypography>}
                count={count}
                rowsPerPage={rowsPerPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />}
        </>
    );
};