import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../firebase";

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export const uploadImage = (target, folderName, onSuccess, onFail) => {


    const storageRef = ref(storage, `${folderName}/${makeid(16)}`);
    const metadata = {
        contentType: 'image/jpeg',
    };
    uploadBytes(storageRef, target.files[0], metadata).then((snapshot) => {
        getDownloadURL(storageRef)
            .then(async (url) => {
                onSuccess(url)
            })
            .catch((error) => {
                onFail(error)
                // Handle any errors
            });
    });
}