import {
    CBox,
    CButton,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Dayjs} from "dayjs";
import {Divider} from "../divider";
import {isEmpty} from "validate.js";
import {Table} from "../table";
import * as React from "react";
import {convertToCurrentTimezone} from "../../utils/date-time";

export const BasicBundlesDialog = (props) => {

    const {
        basicBundlesDialog,
        setBasicBundlesDialog,
        parentSkus,
        isFromDateCalendarOpen,
        setIsFromDateCalendarOpen,
        isToDateCalendarOpen,
        setIsToDateCalendarOpen,
        handleClose,
        handleBasicBundleCreate,
        isCreatingBasicBundle,
        handleBasicBundleInactivate,
        handleBasicBundleActivate,
        handleBasicBundleEdit,
        handleBasicBundleEditCancel
    } = props

    return (
        <CDialog
            open={basicBundlesDialog.isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={"lg"}
            PaperProps={{
                sx: {
                    borderRadius: "37px"
                }
            }}
        >
            <CGridContainer spacing={2} p={2} alignItems={'center'}>
                <CGrid item xs={12}>
                    <CTypography fontSize={"24px"} ml={4}>
                        {basicBundlesDialog.storeName} Bundles
                    </CTypography>
                </CGrid>
                <CGrid item xs={3}>
                    <CTextField select fullWidth label={"Sku"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setBasicBundlesDialog({
                                        ...basicBundlesDialog,
                                        formData: {
                                            ...basicBundlesDialog.formData,
                                            basicBundleSkuId: Number(value)
                                        }
                                    });
                                }}
                                value={basicBundlesDialog.formData?.basicBundleSkuId ? basicBundlesDialog.formData?.basicBundleSkuId : ''}>
                        {parentSkus.map(sku => {
                            return <CMenuItem
                                value={Number(sku.skuId)}>{sku.skuTitle}</CMenuItem>
                        })}
                    </CTextField>
                </CGrid>
                <CGrid item xs={3}>
                    <CTextField fullWidth label={"Title"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setBasicBundlesDialog({
                                        ...basicBundlesDialog,
                                        formData: {
                                            ...basicBundlesDialog.formData,
                                            basicBundleTitle: value
                                        }
                                    });
                                }}
                                value={basicBundlesDialog.formData?.basicBundleTitle ? basicBundlesDialog.formData?.basicBundleTitle : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={1.5}>
                    <CTextField fullWidth type={"number"} label={"Paid Count"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setBasicBundlesDialog({
                                        ...basicBundlesDialog,
                                        formData: {
                                            ...basicBundlesDialog.formData,
                                            basicBundlePaidCount: Number(value)
                                        }
                                    });
                                }}
                                value={basicBundlesDialog.formData?.basicBundlePaidCount ? basicBundlesDialog.formData?.basicBundlePaidCount : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={1.5}>
                    <CTextField fullWidth type={"number"} label={"Free Count"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setBasicBundlesDialog({
                                        ...basicBundlesDialog,
                                        formData: {
                                            ...basicBundlesDialog.formData,
                                            basicBundleFreeCount: Number(value)
                                        }
                                    });
                                }}
                                value={basicBundlesDialog.formData?.basicBundleFreeCount ? basicBundlesDialog.formData?.basicBundleFreeCount : ''}>
                    </CTextField>
                </CGrid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <>
                        <CGrid item xs={12} md={1.5}
                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <MobileDatePicker
                                label="From Date"
                                inputFormat="MM/DD/YYYY"
                                value={basicBundlesDialog.formData.basicBundleValidFrom}
                                open={isFromDateCalendarOpen}
                                onOpen={() => setIsFromDateCalendarOpen(true)}
                                onChange={(newValue: Dayjs | null) => {
                                    // console.log('From Date', newValue)
                                    if (newValue) {
                                        setBasicBundlesDialog({
                                            ...basicBundlesDialog,
                                            formData: {
                                                ...basicBundlesDialog.formData,
                                                basicBundleValidFrom: newValue
                                            }
                                        });
                                    }
                                    setIsFromDateCalendarOpen(false)
                                }}
                                onClose={() => setIsFromDateCalendarOpen(false)}
                                renderInput={(params) =>
                                    <CTextField fullWidth {...params}/>}
                            />
                        </CGrid>
                        <CGrid item xs={12} md={1.5}
                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <MobileDatePicker
                                label="To Date"
                                inputFormat="MM/DD/YYYY"
                                value={basicBundlesDialog.formData.basicBundleValidTo}
                                open={isToDateCalendarOpen}
                                onOpen={() => setIsToDateCalendarOpen(true)}
                                onClose={() => setIsToDateCalendarOpen(false)}
                                onChange={(newValue: Dayjs | null) => {
                                    // console.log('To Date', newValue)
                                    if (newValue) {
                                        setBasicBundlesDialog({
                                            ...basicBundlesDialog,
                                            formData: {
                                                ...basicBundlesDialog.formData,
                                                basicBundleValidTo: newValue
                                            }
                                        });
                                    }
                                    setIsToDateCalendarOpen(false)
                                }}
                                renderInput={(params) =>
                                    <CTextField fullWidth {...params} />}
                            />
                        </CGrid>
                    </>
                </LocalizationProvider>
                <CGrid item xs={basicBundlesDialog.mode === 'create' ? 11 : 10}>
                    <CTextField fullWidth label={"Descriptions"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setBasicBundlesDialog({
                                        ...basicBundlesDialog,
                                        formData: {
                                            ...basicBundlesDialog.formData,
                                            basicBundleDescriptions: value
                                        }
                                    });
                                }}
                                value={basicBundlesDialog.formData?.basicBundleDescriptions ? basicBundlesDialog.formData?.basicBundleDescriptions : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={1}>
                    <CButton
                        fullWidth
                        variant={"contained"}
                        onClick={handleBasicBundleCreate}
                    >
                        {isCreatingBasicBundle ? <CCircularProgress sx={{color: "white"}}
                                                                    size={24}/> : basicBundlesDialog.mode === 'create' ? 'Add': 'Edit'}
                    </CButton>
                </CGrid>
                {basicBundlesDialog.mode === 'edit' && <CGrid item xs={1}><CButton
                    fullWidth
                    variant={"outlined"}
                    onClick={handleBasicBundleEditCancel}
                >
                    Cancel
                </CButton></CGrid>}
            </CGridContainer>
            <Divider></Divider>
            <CGridContainer spacing={0} p={0}>
                <CGrid item xs={12} maxHeight={'50vh'} sx={{
                    overflowY: 'scroll',
                }}>
                    {isEmpty(basicBundlesDialog.data) ?
                        <CTypography m={4} textAlign={'center'} variant={'body1'}> No basic bundles found
                            for store. </CTypography> : <Table
                            columns={[
                                {
                                    field: 'basicBundleId',
                                    headerName: 'ID',
                                    width: 80,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'sku',
                                    headerName: 'Sku',
                                    valueGetter: (params) => {
                                        const {basicBundleSku} = params.row
                                        return basicBundleSku?.skuTitle
                                    },
                                    width: 80,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'basicBundleTitle',
                                    headerName: 'Title',
                                    width: 150,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'basicBundlePaidCount',
                                    headerName: 'Paid Count',
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'basicBundleFreeCount',
                                    headerName: 'Free Count',
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'basicBundleValidFrom',
                                    headerName: 'From',
                                    valueGetter: (params) => {
                                        const {basicBundleValidFrom} = params.row
                                        return convertToCurrentTimezone(basicBundleValidFrom, 'MMM D')
                                    },
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'basicBundleValidTo',
                                    headerName: 'To',
                                    valueGetter: (params) => {
                                        const {basicBundleValidTo} = params.row
                                        return convertToCurrentTimezone(basicBundleValidTo, 'MMM D')
                                    },
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'basicBundleStatus',
                                    headerName: 'Status',
                                    width: 50,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'actions',
                                    headerName: 'Action',
                                    width: 250,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell",
                                    renderCell: (params) => {

                                        const onInactivateClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handleBasicBundleInactivate(params.row.basicBundleId, Number(basicBundlesDialog.storeId))
                                            // return alert(JSON.stringify(thisRow, null, 4));
                                        };

                                        const onActivateClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handleBasicBundleActivate(params.row.basicBundleId, Number(basicBundlesDialog.storeId))
                                            // return alert(JSON.stringify(thisRow, null, 4));
                                        };

                                        const onEditClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handleBasicBundleEdit(params.row)
                                        };

                                        return <>
                                            <CButton variant={"contained"} color={"primary"} size={"small"}
                                                     sx={{height: '32px', mr: 2}}
                                                     onClick={onEditClick}>Edit</CButton>

                                            {params.row.basicBundleStatus === 'active' ?
                                                <CButton variant={"contained"} color={"error"} size={"small"}
                                                         sx={{height: '32px', mr: 2}}
                                                         onClick={onInactivateClick}>Inactivate</CButton> :
                                                <CButton variant={"contained"} size={"small"}
                                                         sx={{height: '32px', mr: 2}}
                                                         onClick={onActivateClick}>Activate</CButton>}

                                        </>;
                                    }
                                }
                            ]}
                            rows={basicBundlesDialog.data}
                            count={basicBundlesDialog.data.length}
                            page={0}
                            rowsPerPage={100}
                            onPageChange={() => {
                                console.log('tmp')
                            }}
                            onRowsPerPageChange={() => {
                                console.log('tmp')
                            }}
                            orderBy={'tmp'}
                            order={'DESC'}
                            onRequestSort={() => {
                                console.log('tmp')
                            }}
                            isLoading={false}
                            noRowTitleOnSmallSize={true}
                        />}
                </CGrid>
            </CGridContainer>
            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
            </CBox>
        </CDialog>
    )
}
