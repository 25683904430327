import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Typography, Card, CardContent } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {CCard, CCardContent} from "../mui-extracted";

const Slide = ({ title, description, bgColor, titleColor,  descriptionColor }) => {
    return (
        <CCard sx={{m:1.5, mb: 3, backgroundColor: bgColor, boxShadow: "rgba(149, 157, 165, 0.2) 0px 4px 12px", position: "relative", display: "flex", flexDirection: "column"}}>
            <CCardContent sx={{pt: 2}}>
                <Typography variant="h6" component="h2" mb={2} color={titleColor}>
                    {title}
                </Typography>
                <Typography variant="body2" color={descriptionColor || "textSecondary"}>
                    {description}
                </Typography>
            </CCardContent>
        </CCard>
    );
};

const PageSlider = ({ slides }) => {
    return (
        <Carousel showArrows={true} autoPlay={true} showStatus={false} showIndicators={false} showThumbs={false}>
            {slides.map((slide, index) => (
                <Slide key={index} title={slide.title} description={slide.description} bgColor={slide.bgColor} titleColor={slide.titleColor} descriptionColor={slide.descriptionColor} />
            ))}
        </Carousel>
    );
};

export default PageSlider;
