import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const VendorModel = types.model("Vendor").props({
    vendorId: types.number,
    vendorName: types.maybeNull(types.string),
    vendorDesc: types.maybeNull(types.string),
    vendorUrl: types.maybeNull(types.string),
    vendorStatus: types.maybeNull(types.string),
    vendorCreatedAt: types.maybeNull(types.string),
    vendorUpdatedAt: types.maybeNull(types.string),
})

type VendorType = Instance<typeof VendorModel>
export interface Vendor extends VendorType {}
type VendorSnapshotType = SnapshotOut<typeof VendorModel>
export interface VendorSnapshot extends VendorSnapshotType {}

export const defaultVendor = {
    vendorId: undefined,
    vendorName: '',
    vendorDesc: '',
    vendorStatus: '',
    vendorUrl: ''
}