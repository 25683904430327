import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {PosLogsApi} from "../../services/api/pos-logs-api"
import {logInfo} from "../../utils/logs"
import {
    PosLogCreateParams,
} from "../../services/api";

function posLogStoreLog(functionName, message) {
    logInfo({
        fileName: "pos-log-store.ts",
        className: "posLogStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const PosLogStoreModel = types
    .model("PosLogStore")
    .extend(withEnvironment)
    .actions((self) => ({
        create: flow(function* (params: PosLogCreateParams, token: string) {
            self.environment.addAuth(token)
            const posLogApi = new PosLogsApi(self.environment.api)
            return yield posLogApi.create(params)
        }),
    }))

type PosLogStoreType = Instance<typeof PosLogStoreModel>

export interface PosLogStore extends PosLogStoreType {
}

type PosLogStoreSnapshotType = SnapshotOut<typeof PosLogStoreModel>

export interface PosLogStoreSnapshot extends PosLogStoreSnapshotType {
}

export const createPosLogStoreDefaultModel = () => types.optional(PosLogStoreModel, {})
