import {observer} from "mobx-react-lite";
import {
    CButton,
    CCircularProgress,
    CGrid,
    CGridContainer,
    CIconButton,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {CalendarMonth, Clear, ClearAll, Refresh, Search} from "@mui/icons-material";
import * as React from "react";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Dayjs} from "dayjs";
import {Divider} from "../divider";

interface OrdersHeaderProps {
    refresh: () => void,
    isRefreshing: boolean,
    fromDate: Dayjs | null,
    setFromDate: (value: Dayjs | null) => void,
    toDate: Dayjs | null,
    setToDate: (value: Dayjs | null) => void,
    orderStatusFilter: string,
    setOrderStatusFilter: (value: string) => void,
    setOrderNoFilter: (value: string) => void,
    orderNoFilter: string,
    setOrderTransIdFilter: (value: string) => void,
    orderTransIdFilter: string,
    setOrderCustomerFullNameFilter: (value: string) => void,
    orderCustomerFullNameFilter: string,
    setOrderCardNoFilter: (value: string) => void,
    orderCardNoFilter: string,
    setOrderBillingNameFilter: (value: string) => void,
    orderBillingNameFilter: string,
}

export const OrdersHeader = observer(function OrdersHeader({
                                                               refresh,
                                                               isRefreshing,
                                                               fromDate,
                                                               setFromDate,
                                                               toDate,
                                                               setToDate,
                                                               orderStatusFilter,
                                                               setOrderStatusFilter,
                                                               setOrderNoFilter,
                                                               setOrderTransIdFilter,
                                                               orderTransIdFilter,
                                                               orderNoFilter,
                                                               setOrderCustomerFullNameFilter,
                                                               orderCustomerFullNameFilter,
                                                               setOrderCardNoFilter,
                                                               orderCardNoFilter,
                                                               setOrderBillingNameFilter,
                                                               orderBillingNameFilter,
                                                           }: OrdersHeaderProps) {

    const [showFilters, setShowFilters] = React.useState(false)
    const [isFromDateCalendarOpen, setIsFromDateCalendarOpen] = React.useState(false)
    const [isToDateCalendarOpen, setIsToDateCalendarOpen] = React.useState(false)

    return (
        <CGridContainer spacing={2} py={2}>
            <CGrid item xs={6} sm={2} md={1}>
                <CTypography sx={{fontFamily: "Passion One", fontSize: "32px",}}>
                    Orders
                </CTypography>
            </CGrid>
            {!showFilters && <CGrid item xs={6} md={1.5}
                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <CTextField label={"Order Status"}
                            size={"small"}
                            select
                            fullWidth
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setOrderStatusFilter(!event.target.value ? '' : event.target.value)
                            }} value={orderStatusFilter}>
                    <CMenuItem value={'all'}>All</CMenuItem>
                    <CMenuItem value={'received'}>Received</CMenuItem>
                    <CMenuItem value={'ready'}>Ready</CMenuItem>
                    <CMenuItem value={'picked_up'}>Picked Up</CMenuItem>
                    <CMenuItem value={'canceled'}>Canceled</CMenuItem>
                    <CMenuItem value={'unpaid'}>Unpaid</CMenuItem>
                </CTextField>
            </CGrid>}
            <CGrid item xs={10} md={1.5} display={'flex'} alignItems={'center'}>
                <CButton variant={"contained"} fullWidth onClick={async () => {
                    if (showFilters) {
                        setFromDate(null)
                        setToDate(null)
                        setOrderCardNoFilter('')
                        setOrderBillingNameFilter('')
                        setOrderCustomerFullNameFilter('')
                        setOrderNoFilter('')
                        setTimeout(()=>{
                            setOrderStatusFilter('received')
                        }, 100)
                    } else {
                        setOrderStatusFilter('all')
                    }
                    setShowFilters(!showFilters)
                }}>
                    {showFilters ? 'Hide' : 'Show'} Filters
                </CButton>
            </CGrid>
            <CGrid item xs={2} md={0.5} display={'flex'} alignItems={'center'}>

                <CIconButton onClick={refresh}>
                    {isRefreshing ? <CCircularProgress
                        sx={{color: "primary"}}
                        size={20}/> : <Refresh color={'primary'}/>}
                </CIconButton>
            </CGrid>
            {showFilters && <CGrid item xs={0} md={12}></CGrid>}

            {showFilters && <Divider></Divider>}



            {/*</CGridContainer>*/}
            {/*<CGridContainer spacing={2} my={2}>*/}
            {showFilters && <>
                <CGrid item xs={12} md={1.5}
                       sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <CTextField label={"Order Status"}
                                size={"small"}
                                select
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderStatusFilter(!event.target.value ? '' : event.target.value)
                                }} value={orderStatusFilter}>
                        <CMenuItem value={'all'}>All</CMenuItem>
                        <CMenuItem value={'received'}>Received</CMenuItem>
                        <CMenuItem value={'ready'}>Ready</CMenuItem>
                        <CMenuItem value={'picked_up'}>Picked Up</CMenuItem>
                        <CMenuItem value={'canceled'}>Canceled</CMenuItem>
                        <CMenuItem value={'unpaid'}>Unpaid</CMenuItem>
                    </CTextField>
                </CGrid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <>
                        <CGrid item xs={12} md={1.5}
                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <MobileDatePicker
                                label="From Date"
                                inputFormat="MM/DD/YYYY"
                                value={fromDate}
                                open={isFromDateCalendarOpen}
                                onOpen={() => setIsFromDateCalendarOpen(true)}
                                onChange={(newValue: Dayjs | null) => {
                                    // console.log('From Date', newValue)
                                    if (newValue) {
                                        setFromDate(newValue?.set('hour', 0).set('minute', 0).set('second', 0))
                                    }
                                    setIsFromDateCalendarOpen(false)
                                }}
                                onClose={() => setIsFromDateCalendarOpen(false)}
                                renderInput={(params) =>
                                    <CTextField fullWidth size={"small"} {...params}/>}
                            />
                        </CGrid>
                        <CGrid item xs={12} md={1.5}
                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <MobileDatePicker
                                label="To Date"
                                inputFormat="MM/DD/YYYY"
                                value={toDate}
                                open={isToDateCalendarOpen}
                                onOpen={() => setIsToDateCalendarOpen(true)}
                                onClose={() => setIsToDateCalendarOpen(false)}
                                onChange={(newValue: Dayjs | null) => {
                                    // console.log('To Date', newValue)
                                    if (newValue) {
                                        setToDate(newValue?.set('hour', 23).set('minute', 59).set('second', 59))
                                    }
                                    setIsToDateCalendarOpen(false)
                                }}
                                renderInput={(params) =>
                                    <CTextField fullWidth size={"small"} {...params} />}
                            />
                        </CGrid>
                    </>
                </LocalizationProvider>
                <CGrid item xs={12} md={1.5} display={'flex'} alignItems={'center'}>
                    <CTextField label={"Order No"}
                        // type={"number"}
                                sx={{minWidth: '128px', flex: 1}}
                                size={"small"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderNoFilter(event.target.value)
                                }} value={orderNoFilter}/>
                </CGrid>
                <CGrid item xs={12} md={1.5} display={'flex'} alignItems={'center'}>
                    <CTextField label={"Customer Name"}
                        // type={"number"}
                                sx={{minWidth: '128px', flex: 1}}
                                size={"small"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderCustomerFullNameFilter(event.target.value)
                                }} value={orderCustomerFullNameFilter}/>
                </CGrid>
                <CGrid item xs={12} md={1.5} display={'flex'} alignItems={'center'}>
                    <CTextField label={"Card No"}
                        // type={"number"}
                                sx={{minWidth: '128px', flex: 1}}
                                size={"small"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderCardNoFilter(event.target.value)
                                }} value={orderCardNoFilter}/>
                </CGrid>
                <CGrid item xs={12} md={1.5} display={'flex'} alignItems={'center'}>
                    <CTextField label={"Billing Name"}
                        // type={"number"}
                                sx={{minWidth: '128px', flex: 1}}
                                size={"small"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderBillingNameFilter(event.target.value)
                                }} value={orderBillingNameFilter}/>
                </CGrid>
                <CGrid item xs={12} md={1.5} display={'flex'} alignItems={'center'}>
                    <CTextField label={"Trans ID"}
                        // type={"number"}
                                sx={{minWidth: '128px', flex: 1}}
                                size={"small"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderTransIdFilter(event.target.value)
                                }} value={orderTransIdFilter}/>
                </CGrid>
                <CGrid item xs={0} md={9} sx={{display: {xs: 'none', md: 'block'}}}></CGrid>
                <CGrid item xs={6} md={1.5} display={'flex'} alignItems={'center'}>
                    <CButton fullWidth variant={"contained"} onClick={() => {
                        setFromDate(null)
                        setToDate(null)
                        setOrderCardNoFilter('')
                        setOrderBillingNameFilter('')
                        setOrderCustomerFullNameFilter('')
                        setOrderNoFilter('')
                        setOrderStatusFilter('all')
                        setTimeout(() => {
                            refresh()
                        }, 100)
                    }} startIcon={<ClearAll/>}>
                        Clear
                    </CButton>
                </CGrid>
                <CGrid item xs={6} md={1.5} display={'flex'} alignItems={'center'}>
                    <CButton fullWidth variant={"contained"} onClick={() => {
                        refresh()
                    }} startIcon={<Search/>}>
                        Search
                    </CButton>
                </CGrid>
            </>}
        </CGridContainer>
    )
})