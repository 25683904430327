import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {logInfo} from "../../utils/logs"
import {CartModel, defaultCart} from "../cart/cart";
import {SkuSnapshot} from "../sku/sku";
import {OrderDetailsApi} from "../../services/api/order-details-api";
import {InventoriesApi} from "../../services/api/inventories-api";
import {hasValue} from "../../utils/empty-check";
import {BasicBundle} from "../basic-bundle/basic-bundle";
import {getSkuPrice} from "../../utils/sku";
import {ensureString} from "../../utils/type";

function cartStoreLog(functionName, message) {
    logInfo({
        fileName: "cart-store.ts",
        className: "cartStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const CartStoreModel = types
    .model("CartStore")
    .props({
        previousCart: types.optional(types.maybeNull(CartModel), defaultCart),
        cart: types.optional(types.maybeNull(CartModel), defaultCart)
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveCart: (cartSnapshot) => {
            try {
                self.cart = cast(cartSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("saveCart", e.message)
                }
                // recordError(e)
            }
        },
        savePreviousCart: (cartSnapshot) => {
            try {
                const currentSnapshot = {...cartSnapshot, cartSkus: []}
                cartSnapshot.cartSkus.forEach(item=>currentSnapshot.cartSkus.push({cartSkuAmount: item.cartSkuAmount, cartSkuSku: {...item.cartSkuSku}}))
                self.previousCart = cast({...currentSnapshot})
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("savePreviousCart", e.message)
                }
                // recordError(e)
            }
        },
        increaseSkuNumber: (index) => {
            try {
                if (self.cart && self.cart.cartSkus.length > 0) {
                    const currentCart = {...self.cart}
                    currentCart.cartSkus[index].cartSkuAmount += 1
                    self.cart = currentCart
                }
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("increaseSkuNumber", e.message)
                }
                // recordError(e)
            }
        },
        addToSkuNumber: (index, amount) => {
            try {
                if (self.cart && self.cart.cartSkus.length > 0) {
                    const currentCart = {...self.cart}
                    currentCart.cartSkus[index].cartSkuAmount += amount
                    self.cart = currentCart
                }
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("increaseSkuNumber", e.message)
                }
                // recordError(e)
            }
        },
        addSkuToCart: (sku: SkuSnapshot, priceId: number, amount: number) => {
            try {
                if (self.cart) {
                    const currentSku = JSON.parse(JSON.stringify(sku))
                    const currentCart = {...self.cart}
                    const currentParent = currentSku.parentSku ? {...currentSku.parentSku} : {skuId: -1}
                    const currentVendor = currentSku.vendor ? {...currentSku.vendor} : {vendorId: -1}
                    // @ts-ignore
                    delete currentParent.category
                    // @ts-ignore
                    delete currentParent.vendor
                    // @ts-ignore
                    delete currentParent.skuImages
                    // @ts-ignore
                    // delete currentParent.prices
                    // @ts-ignore
                    delete currentParent.storeItemAvailabilities
                    // @ts-ignore
                    delete currentParent.parentSku
                    // @ts-ignore
                    delete currentParent.basicBundles

                    // @ts-ignore
                    delete currentSku.category
                    // @ts-ignore
                    delete currentSku.vendor
                    // @ts-ignore
                    delete currentSku.skuImages
                    // @ts-ignore
                    // delete sku.prices
                    // @ts-ignore
                    delete currentSku.storeItemAvailabilities
                    // @ts-ignore
                    delete currentSku.parentSku
                    // @ts-ignore
                    delete currentSku.basicBundles

                    currentCart.cartSkus.push({
                        cartSkuAmount: hasValue(amount) ? Number(amount) : 1,
                        cartSkuSku: currentSku,
                        cartSkuParent: {...currentParent},
                        cartSkuVendor: {...currentVendor},
                        cartSkuPriceId: priceId,
                        cartSkuFreeItemsAmount: 0,
                        cartSkuBundleId: null
                    })
                    self.cart = currentCart
                }
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("addSkuToCart", e.message)
                }
                // recordError(e)
            }
        },
        decreaseSkuNumber: (index) => {
            try {
                if (self.cart && self.cart.cartSkus.length > 0) {
                    const currentCart = {...self.cart}
                    currentCart.cartSkus[index].cartSkuAmount -= 1
                    self.cart = currentCart
                }
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("increaseSkuNumber", e.message)
                }
                // recordError(e)
            }
        },
        removeSkuFromCart: (index) => {
            try {
                if (self.cart) {
                    const currentCart = {...self.cart}
                    currentCart.cartSkus.splice(index, 1)
                    self.cart = currentCart
                }
            } catch (e) {
                if(e instanceof Error) {
                    cartStoreLog("addSkuToCart", e.message)
                }
                // recordError(e)
            }
        },
        applyBundle: (basicBundle: BasicBundle, locationId: number) => {
            if (!self.cart || !self.cart.cartSkus) {
                return
            }
            let currentFreeCount = 0;
            const {basicBundleFreeCount, basicBundlePaidCount, basicBundleSkuId } = basicBundle;

            const currentCartSkus = self.cart.cartSkus
            console.log('bundles.applyBundle.applyBundle', 1)

            currentCartSkus.forEach((cartSku, index) => {
                if (cartSku.cartSkuParent) {
                    currentCartSkus[index].cartSkuFreeItemsAmount = 0
                    const skuPrice = getSkuPrice(cartSku.cartSkuParent.prices, locationId)
                    const bundleSkuPrice = getSkuPrice(basicBundle.basicBundleSku.prices, locationId)
                    console.log('bundles.applyBundle.applyBundle', 2, skuPrice, bundleSkuPrice, basicBundleFreeCount)
                    if (skuPrice && bundleSkuPrice && basicBundleFreeCount && basicBundlePaidCount) {
                        console.log('bundles.applyBundle.applyBundle', 3)
                        const skuPriceAmount = skuPrice.priceAmount
                        const bundleSkuPriceAmount = bundleSkuPrice.priceAmount
                        if (skuPriceAmount && bundleSkuPriceAmount && skuPriceAmount <= bundleSkuPriceAmount) {
                            console.log('bundles.applyBundle.applyBundle', 4)
                            for (let i = 0; i < cartSku.cartSkuAmount; i++) {
                                if (currentFreeCount < basicBundleFreeCount) {
                                    console.log('bundles.applyBundle.applyBundle', 4.5, cartSku.cartSkuParent.skuId, basicBundleSkuId, cartSku.cartSkuAmount, basicBundlePaidCount)
                                    if (!(cartSku.cartSkuParent.skuId === basicBundleSkuId && cartSku.cartSkuAmount === basicBundlePaidCount)) {
                                        console.log('bundles.applyBundle.applyBundle', 5)
                                        currentFreeCount ++
                                        currentCartSkus[index].cartSkuFreeItemsAmount ++
                                        currentCartSkus[index].cartSkuBundleId = basicBundle.basicBundleId
                                        currentCartSkus[index].cartSkuBundleTitle = ensureString(basicBundle.basicBundleTitle)
                                    }
                                }
                            }
                        }
                    }
                }

            });

            self.cart = {...self.cart, cartSkus: currentCartSkus}


        },
        clearBundles: () => {
            if (!self.cart || !self.cart.cartSkus) {
                return
            }

            const currentCartSkus = self.cart.cartSkus
            console.log('bundles.clearBundles', 1)

            currentCartSkus.forEach((cartSku, index) => {
                currentCartSkus[index].cartSkuFreeItemsAmount = 0

            });

            self.cart = {...self.cart, cartSkus: currentCartSkus}


        },
        clearCartSkus: () => {
            // @ts-ignore
            self.cart = {...self.cart, cartSkus: cast([])}
        }
    }))
    .actions((self) => ({
        skuCount: flow(function* (id: number, lockboxId:number, token: string) {

            self.environment.addAuth(token)

            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            const orderedSkuCountResult = yield orderDetailApi.skuCount(id, lockboxId)

            const inventoryApi = new InventoriesApi(self.environment.api)
            const inventorySkuCountResult = yield inventoryApi.skuCount(id, lockboxId)

            let count = 0

            console.log('lockboxId', lockboxId)
            console.log('inventorySkuCountResult.count', inventorySkuCountResult.count)
            console.log('orderedSkuCountResult.count', orderedSkuCountResult.count)

            if (orderedSkuCountResult.kind === "ok" && inventorySkuCountResult.kind === "ok") {
                count = inventorySkuCountResult.count - orderedSkuCountResult.count
                if (count < 0) {
                    count = 0
                }
            } else {
                cartStoreLog("skuCount", orderedSkuCountResult.kind)
            }
            return count
        }),
    }))

type CartStoreType = Instance<typeof CartStoreModel>

export interface CartStore extends CartStoreType {
}

type CartStoreSnapshotType = SnapshotOut<typeof CartStoreModel>

export interface CartStoreSnapshot extends CartStoreSnapshotType {
}

// export const createCartStoreDefaultModel = () => types.optional(CartStoreModel, {})
