import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  GatewayDeleteParams,
  GatewayCreateResult,
  GatewayUpdateResult,
  GatewayUpdateParams,
  GatewayFindAllResult,
  GatewayDeleteResult,
  GatewayFindAllParams,
  GatewayFindOneParams,
  GatewayCreateParams,
  GatewayFindOneResult, PosLogCreateParams,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class PosLogsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: PosLogCreateParams): Promise<any> {
    try {
      // const functionName = "login"
      const uri = "pos-logs"
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
