import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const CategoryModel = types.model("Category").props({
    categoryId: types.number,
    categoryName: types.maybeNull(types.string),
    categoryDesc: types.maybeNull(types.string),
    categoryImage: types.maybeNull(types.string),
    categoryStatus: types.maybeNull(types.string),
    categoryCreatedAt: types.maybeNull(types.string),
    categoryUpdatedAt: types.maybeNull(types.string),
})


type CategoryType = Instance<typeof CategoryModel>
export interface Category extends CategoryType {}
type CategorySnapshotType = SnapshotOut<typeof CategoryModel>
export interface CategorySnapshot extends CategorySnapshotType {}

export const defaultCategory = {
    categoryId: undefined,
    categoryName: '',
    categoryImage: '',
    categoryDesc: '',
    categoryStatus: ''
}