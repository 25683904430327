import {
    CBox,
    CButton,
    CCircularProgress, CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";

export const LocationSettings = (props) => {

    const {
        handleClose,
        locationSettingDialog,
        isUploadingLogo,
        handlePickedFile,
        setLocationSettingDialog,
        gateways,
        handleLocationSettingSubmit
    } = props

    return (
        <CDialog open={locationSettingDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"}
                 PaperProps={{
                     sx: {
                         borderRadius: "37px"
                     }
                 }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4}>
                    Location Settings
                </CTypography>
            </CBox>
            <Divider></Divider>
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={6} alignItems={'center'} display={'flex'}>
                    {hasValue(locationSettingDialog.data.locationSettingLogo) &&
                        <img src={locationSettingDialog.data.locationSettingLogo} style={{
                            width: 41,
                            height: 41,
                            borderRadius: 22,
                            marginRight: 8,
                            objectFit: "contain"
                        }}/>
                    }
                    {/*@ts-ignore*/}
                    <CButton variant="contained" component="label">
                        {isUploadingLogo ? <CCircularProgress sx={{color: "white"}}
                                                              size={24}/> : 'Pick Logo'}
                        <input hidden accept="image/*" multiple type="file" onChange={handlePickedFile}/>
                    </CButton>
                </CGrid>
                <CGrid item xs={6}>
                    <CTextField fullWidth label={"Color"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingColor: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingColor}/>
                </CGrid>
                <CGrid item xs={6}>
                    <CTextField fullWidth label={"Google Analytics Tracking No"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingGaTrackingNo: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingGaTrackingNo}/>
                </CGrid>
                <CGrid item xs={6}>
                    <CTextField fullWidth label={"Copyright"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingCopyright: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingCopyright}/>
                </CGrid>
                <CGrid item xs={6}>
                    <CTextField fullWidth label={"Authorize.net Seal"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingAuthorizeSeal: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingAuthorizeSeal}/>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Phone"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingPhone: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingPhone}/>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Address"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingAddress: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingAddress}/>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Email"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingEmail: event.target.value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingEmail}/>
                </CGrid>
                <CGrid item xs={4}>
                    {/*For now it's fixed but it should be dynamic*/}
                    <CTextField fullWidth label={"Timezone"}
                                select
                                disabled
                                value={"PDT"}
                    >
                        <CMenuItem value={"PDT"}>PDT</CMenuItem>
                    </CTextField>
                </CGrid>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Fee (USD)"}
                                type={"number"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingFee: Number(event.target.value)
                                        }
                                    });
                                }}
                                value={locationSettingDialog.data.locationSettingFee}
                    />
                </CGrid>
                <CGrid item xs={2}>
                    <CTextField fullWidth label={"Delivery Fee (USD)"}
                                type={"number"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingDeliveryFee: Number(event.target.value)
                                        }
                                    });
                                }}
                                value={locationSettingDialog.data.locationSettingDeliveryFee}
                    />
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Tax (Percent)"}
                                type={"number"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingTaxPercent: Number(event.target.value)
                                        }
                                    });
                                }}
                                value={locationSettingDialog.data.locationSettingTaxPercent}
                    />
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Default Gateway"} select
                                onChange={(event) => {
                                    const value = Number(event.target.value);
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            gatewayId: value
                                        }
                                    });
                                }} value={locationSettingDialog.data.gatewayId}>
                        {gateways.map(gateway => {
                            return <CMenuItem
                                value={Number(gateway.gatewayId)}>{gateway.gatewayName}</CMenuItem>
                        })}
                    </CTextField>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Bundles Enabled"} select
                                onChange={(event) => {
                                    const value = Number(event.target.value);
                                    setLocationSettingDialog({
                                        ...locationSettingDialog,
                                        data: {
                                            ...locationSettingDialog.data,
                                            locationSettingBundlesEnabled: value
                                        }
                                    });
                                }} value={locationSettingDialog.data.locationSettingBundlesEnabled}>
                        <CMenuItem
                            value={1}>True</CMenuItem>
                        <CMenuItem
                            value={0}>False</CMenuItem>
                    </CTextField>
                </CGrid>
            </CGridContainer>

            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
                <CButton variant={"contained"} onClick={handleLocationSettingSubmit}
                         sx={{mr: 4, minWidth: "100px"}}>
                    {locationSettingDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                          size={24}/> : 'Edit'}
                </CButton>
            </CBox>
        </CDialog>
    )
}