import moment from "moment";
import {useEffect, useState} from "react";

export const MomentTimer = () => {

    const [currentTime, setCurrentTime] = useState(moment().format('MMM DD, YYYY  h:mm:ss A'));

    useEffect(()=>{
        setInterval(()=>{
            setCurrentTime(moment().format('MMM DD, YYYY  h:mm:ss A'))
        }, 500)
    },[])

    return <div>
        {currentTime}
    </div>
}