import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {CategoryModel, defaultCategory} from "../category/category";
import {SkuModel} from "../sku/sku";

/**
 * Rick and Morty character model.
 */
export const CategorySkuModel = types.model("CategorySku").props({
    category: types.optional(CategoryModel, {...defaultCategory, categoryId: -1}),
    skus: types.optional(types.array(SkuModel), []),
})


type CategorySkuType = Instance<typeof CategorySkuModel>
export interface CategorySku extends CategorySkuType {}
type CategorySkuSnapshotType = SnapshotOut<typeof CategorySkuModel>
export interface CategorySkuSnapshot extends CategorySkuSnapshotType {}