import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const RoleModel = types.model("Role").props({
    roleId: types.number,
    roleName: types.string,
    roleModules: types.maybeNull(types.string),
    rolePanelSections: types.maybeNull(types.string),
    roleNote: types.maybeNull(types.string),
    roleStatus: types.maybeNull(types.string),
    roleCreatedAt: types.maybeNull(types.string),
    roleUpdatedAt: types.maybeNull(types.string),
})

type RoleType = Instance<typeof RoleModel>
export interface Role extends RoleType {}
type RoleSnapshotType = SnapshotOut<typeof RoleModel>
export interface RoleSnapshot extends RoleSnapshotType {}

