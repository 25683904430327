import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {SkuModel} from "../sku/sku";
import {CategoryModel} from "../category/category";
import {VendorModel} from "../vendor/vendor";

/**
 * Rick and Morty character model.
 */
export const DiscountModel = types.model("Discount").props({
    discountId: types.number,
    discountType: types.maybeNull(types.string),
    skuId: types.maybeNull(types.number),
    categoryId: types.maybeNull(types.number),
    vendorId: types.maybeNull(types.number),
    discountVoucher: types.maybeNull(types.string),
    discountVoucherUsed: types.maybeNull(types.string),
    discountPercent: types.maybeNull(types.string),
    discountDateFrom: types.maybeNull(types.string),
    discountDateTo: types.number,
    discountTs: types.maybeNull(types.string),
    discountCreatedAt: types.string,
    discountUpdatedAt: types.string,
    sku: types.optional(types.maybeNull(SkuModel), {skuId: -1}),
    category: types.optional(types.maybeNull(CategoryModel), {categoryId: -1}),
    vendor: types.optional(types.maybeNull(VendorModel), {vendorId: -1})
})

type DiscountType = Instance<typeof DiscountModel>
export interface Discount extends DiscountType {}
type DiscountSnapshotType = SnapshotOut<typeof DiscountModel>
export interface DiscountSnapshot extends DiscountSnapshotType {}

