import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {InventoriesApi} from "../../services/api/inventories-api"
import {InventoryModel, InventorySnapshot} from "../inventory/inventory"
import {logInfo} from "../../utils/logs"
import {
    InventoryCreateParams,
    InventoryDeleteParams,
    InventoryFindAllParams,
    InventoryFindOneParams,
    InventoryUpdateParams
} from "../../services/api";

function inventoryStoreLog(functionName, message) {
    logInfo({
        fileName: "inventory-store.ts",
        className: "inventoryStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const InventoryStoreModel = types
    .model("InventoryStore")
    .props({
        inventory: types.optional(types.maybeNull(InventoryModel), null),
        inventories: types.optional(types.array(InventoryModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveInventory: (inventorySnapshot: InventorySnapshot) => {
            try {
                self.inventory = cast(inventorySnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    inventoryStoreLog("saveInventory", e.message)
                }
                // recordError(e)
            }
        },
        saveInventories: (inventoriesSnapshot: InventorySnapshot[]) => {
            try {
                detach(self.inventories)
                self.inventories = cast([...inventoriesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    inventoryStoreLog("saveInventory", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: InventoryCreateParams, token: string) {
            self.environment.addAuth(token)
            const inventoryApi = new InventoriesApi(self.environment.api)
            return yield inventoryApi.create(params)
        }),
        findAll: flow(function* (params: InventoryFindAllParams, token: string) {
            self.environment.addAuth(token)
            const inventoryApi = new InventoriesApi(self.environment.api)
            const result = yield inventoryApi.findAll(params)


            if (result.kind === "ok") {
                self.saveInventories(result.inventories)
            } else {
                inventoryStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: InventoryFindOneParams, token: string) {
            self.environment.addAuth(token)
            const inventoryApi = new InventoriesApi(self.environment.api)
            const result = yield inventoryApi.findOne(params)


            if (result.kind === "ok") {
                self.saveInventory(result.inventory)
            } else {
                inventoryStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: InventoryUpdateParams, token: string) {
            self.environment.addAuth(token)
            const inventoryApi = new InventoriesApi(self.environment.api)
            const result = yield inventoryApi.update(params)


            if (result.kind === "ok") {
                self.saveInventory(result.inventory)
            } else {
                inventoryStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: InventoryDeleteParams, token: string) {
            self.environment.addAuth(token)
            const inventoryApi = new InventoriesApi(self.environment.api)
            return yield inventoryApi.remove(params)
        }),

    }))

type InventoryStoreType = Instance<typeof InventoryStoreModel>

export interface InventoryStore extends InventoryStoreType {
}

type InventoryStoreSnapshotType = SnapshotOut<typeof InventoryStoreModel>

export interface InventoryStoreSnapshot extends InventoryStoreSnapshotType {
}

export const createInventoryStoreDefaultModel = () => types.optional(InventoryStoreModel, {})
