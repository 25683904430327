import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CButton,
    CCard,
    CCircularProgress,
    CContainer,
    CGrid,
    CGridContainer,
    CTypography,
    ProductCard,
    DisclaimerDialog,
    DeliveryMethodDialog,
    Footer,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {Sku} from "../../../models/sku/sku";
import {hasValue} from "../../../utils/empty-check";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import ReactGA from "react-ga4";
import {getSkuPrice} from "../../../utils/sku";

export const StoreCategory = observer(
    function StoreCategory() {
        const navigate = useNavigate();

        const {skuStore, categoryStore, cartStore, locationStore} = useStores()

        const params = useParams();

        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, locationInfoState, guestAuthState} = guestAuth
        const [categorySkus, setCategorySkus] = React.useState<any>([])
        const [isGettingCategorySkus, setIsGettingCategorySkus] = React.useState(true)
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })

        const init = async () => {

            const gaItems: any[] = []

            setIsGettingCategorySkus(true)
            console.log(guestAuthToken)
            const {categories} = await categoryStore.findAll({categoryName: params.categoryName,sortBy: 'categoryId', categoryStatus: 'active'}, guestAuthToken)
            if (!categories) {
                setIsGettingCategorySkus(false)
                return
            }
            const category = categories[0]
            const currentCategorySkus: any[] = []
                const {skus} = await skuStore.findAll({
                    page: 0,
                    perPage: 100,
                    skuHasParent: 0,
                    categoryId: category.categoryId,
                    skuStatus: 'active'
                }, guestAuthToken, true)
            const checkedSkus: Sku[] = []
            if (skus) {
                skus.forEach(sku=>{
                    if (getSkuPrice(sku.prices, locationStore.currentLocation?.locationId) !== null) {
                        checkedSkus.push(sku)
                    }
                })
            }
             currentCategorySkus.push({
                        category,
                        skus: checkedSkus
                    })
            setCategorySkus(currentCategorySkus)

            setIsGettingCategorySkus(false)

            skus.forEach(sku=>{
                gaItems.push({
                    item_id: sku.skuId,
                    item_name: sku.skuHasParent ? sku.skuCode + ' - ' + sku.vendor.vendorName + ' - ' + sku.parentSku?.skuTitle + ' - ' + sku.skuTitle : sku.vendor.vendorName + ' - ' + sku.skuTitle,
                    price: Number(getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                })
            })

            ReactGA.event("view_item_list", {
                item_list_id: `category_screen_${category.categoryId}`,
                item_list_name: `Category Screen ${category.categoryName}`,
                items: gaItems
            })
            console.log("ReactGA", "StoreCategoryScreen", "view_item_list", {
                item_list_id: `category_screen_${category.categoryId}`,
                item_list_name: `Category Screen ${category.categoryName}`,
                items: gaItems
            })
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        useEffect(() => {
            ;(async () => {
                if (guestAuthToken && guestAuthState === 'loaded') {
                    await init()
                }
            })()
        }, [guestAuthToken, params.categoryName, guestAuthState]);

        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Products`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Products | ${locationInfo.locationName}`;
                }
            })()

        }, [locationInfo, locationInfoState])

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        return (
            <>

                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={()=>{
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>
                        {isGettingCategorySkus && <CGridContainer sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '70vh'
                        }}>
                            <CCircularProgress></CCircularProgress>

                        </CGridContainer>}
                        {!isGettingCategorySkus && <CGridContainer zIndex={100} mt={2} spacing={2}>
                            <CGrid item xs={12} height={{xs: 200, sm: 200, md: 200}}>
                                <CCard
                                    sx={{
                                        position: 'relative',
                                        backgroundImage: `url(${categorySkus[0].category.categoryImage})`,
                                        height: '100%',
                                        width: '100%',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            '&:before': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                            }
                                        },
                                        '&:before': {
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            height: '100%',
                                            width: '100%',
                                            content: "''",
                                            transition: 'background-color 0.2s ease-in-out',
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)' /* adjust the alpha value to control the darkness */
                                        }
                                    }}
                                >
                                    <CButton variant={'text'} sx={{color: 'white', fontWeight: 'bold', zIndex: 1}}
                                             onClick={() => navigate('/')}>
                                        Home
                                    </CButton>
                                    <CTypography color={'white'} zIndex={1}>
                                        / {params.categoryName}
                                    </CTypography>
                                </CCard>
                            </CGrid>
                        </CGridContainer>}
                        {
                            !isGettingCategorySkus &&  categorySkus.map(categorySku=>{
                                return <>
                                    <CGridContainer>
                                    {categorySku.skus.map((sku, index) =>  <ProductCard
                                        key={`${sku.skuId}${index}_category_product`}
                                        md={2}
                                        showInfoButton={false}
                                        imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                        title={sku.skuTitle}
                                        vendorName={sku.vendor?.vendorName}
                                        price={`$${getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount ? getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount?.toFixed(2) : "0.00"}`}
                                        onClick={() => {
                                            // addSku(sku)
                                            navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)

                                        }}
                                    />)}
                                </CGridContainer>
                                </>
                            })
                        }
                        {
                            /*
                                                    <CGridContainer flex={1}>
                            <CGrid item xs={12} md={12} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CGrid item sm={12} md={0} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CBox
                                        sx={{width: "100%", height: "90vh", display: "flex", flexDirection: "column"}}>
                                        {!isGettingSkus ? <CBox sx={{
                                            width: "100%",
                                            flex: 1,
                                            overflowY: "scroll",
                                            WebkitOverflowScrolling: 'touch'
                                        }}>
                                            <style>
                                                {`
        ::-webkit-scrollbar {
          display: none;
        }
        `}
                                            </style>
                                            <CGridContainer sx={{paddingLeft: 2}}>
                                                {skus.map((sku, index) => <ProductCard
                                                    key={index}
                                                    md={2}
                                                    showInfoButton={false}
                                                    imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                                    title={sku.skuTitle}
                                                    vendorName={sku.vendor?.vendorName}
                                                    price={`$${sku.skuDefaultPrice ? sku.skuDefaultPrice.toFixed(2) : "0.00"}`}
                                                    onClick={() => {
                                                        // addSku(sku)
                                                        navigate(`/store/product/${sku.skuTitle}-${sku.vendor?.vendorName}`)

                                                    }}
                                                />)}
                                            </CGridContainer>

                                        </CBox> : <CBox
                                            sx={{
                                                width: "100%",
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            <CCircularProgress sx={{color: "white"}}
                                                               size={32}/>
                                        </CBox>}
                                        <CBox sx={{
                                            width: "100%",
                                            height: "90px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-end",
                                            mb: "32px"
                                        }}>
                                            <CBox>
                                                {currentCategoryId === -1 && <CBox
                                                    sx={{
                                                        height: "7px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#083B1C"
                                                    }}>

                                                </CBox>}
                                                <CButton
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: "400",
                                                        padding: 0
                                                    }}
                                                    onClick={async () => {
                                                        setCurrentCategoryId(-1)
                                                        statelessCategoryId = -1
                                                        searchSku()
                                                    }}
                                                >All</CButton>
                                            </CBox>
                                            {categories.map((category, index) => <CBox>
                                                {currentCategoryId === category.categoryId &&
                                                    <CBox key={index} sx={{
                                                        height: "7px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#083B1C"
                                                    }}>

                                                    </CBox>}
                                                <CButton sx={{
                                                    fontSize: "20px",
                                                    color: "#757575",
                                                    fontWeight: "400",
                                                    padding: 0,
                                                    ml: 2
                                                }}
                                                         onClick={async () => {
                                                             setCurrentCategoryId(category.categoryId)
                                                             statelessCategoryId = category.categoryId
                                                             searchSku()
                                                         }}
                                                >{category.categoryName}</CButton></CBox>)}
                                        </CBox>
                                    </CBox>


                                </CGrid>
                                <CGrid item xs={0} md={0} sx={{
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: 'none',
                                        lg: 'none',
                                        xl: 'none'
                                    },
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CCard sx={{
                                        width: "100%",
                                        height: '90vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <CartContent/>
                                    </CCard>
                                </CGrid>

                            </CGrid>
                        </CGridContainer>

                             */
                        }

                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );

    }
)
