import {CBox, CCard, CGrid, CTypography} from ".."
import * as React from "react";
import {CIconButton} from "../mui-extracted";
import {InfoRounded, NewReleasesOutlined} from "@mui/icons-material";
import {ButtonBase, useMediaQuery} from "@mui/material";
import {useEffect, useRef} from "react";
import {convertToCurrentTimezone} from "../../utils/date-time";

export const BundleCard = (props) => {

    const {imageUrl, onClick, title, descriptions, validFrom, validTo, onInfoClick, md, xs, showInfoButton, onRef} = props

    const gridItemRef = useRef(null);

    useEffect(() => {
        if (gridItemRef.current && onRef) {
            onRef(gridItemRef.current);
            // Do something with the grid item width
        }
    }, []);

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    return (
        <CGrid
            ref={gridItemRef}
            item
            xs={xs || 6}
            md={md || 3}
            key={props.key}
            sx={{
                height: "144px",
                paddingRight: 2,
                paddingTop: 2
            }}
        >
            <CCard variant={"outlined"} sx={{    boxShadow: "none", position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "column"}}>
                <ButtonBase
                    onClick={onClick}
                    sx={{height: "100%", width: "100%", flex: 1, display: "flex", flexDirection: "row"}}>
                {/*<CBox*/}
                {/*    sx={{height: "100%", width: "100%", flex: 1, display: "flex", flexDirection: "row"}}>*/}

                    <CBox
                        style={{
                            display: "flex",
                            background: `url(${imageUrl})`,
                            borderRadius:isSmallScreen || isExtraSmallScreen
                                ? '18px'
                                : '37px',
                            margin: "4px",
                            width: "128px",
                            height: "100%",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center"
                        }}
                    />
                    <CBox
                        sx={{display: "flex", flex: 1, py: 2, flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                        <CTypography sx={{fontSize: "16px", mb: 1, mx: 1}}>
                            {title}
                        </CTypography>
                        <CTypography sx={{fontSize: "14px", mb: 1, mx: 1}}>
                            {descriptions}
                        </CTypography>

                        <CTypography sx={{backgroundColor: 'green',borderRadius: 4, color: 'white', p: 0.5, pr: 2,fontSize: "12px", mb: 1, display: 'flex', alignItems: 'center',}}>
                            <NewReleasesOutlined sx={{mr: 2, fontSize: '24px'}} />
                            Bundle is valid {convertToCurrentTimezone(validFrom, 'MMM D')} - {convertToCurrentTimezone(validTo, 'MMM D')}
                        </CTypography>
                    </CBox>
                {/*</CBox>*/}
                </ButtonBase>
                {showInfoButton !== false &&
                    <CIconButton onClick={onInfoClick} color={"primary"}
                                 sx={{position: "absolute", bottom: 4, right: 4}}>
                        <InfoRounded></InfoRounded>
                    </CIconButton>}
            </CCard>
        </CGrid>)
}
