import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CButton,
    CCard,
    CCardContent,
    CCircularProgress,
    CContainer,
    CGrid,
    CGridContainer,
    CInputAdornment,
    CTextField,
    POSTitle,
    CTypography
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {CIconButton} from "../../../components";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useStores} from "../../../models";
import {useEffect} from "react";
import {hasValue} from "../../../utils/empty-check";
import {useAppInit} from "../../../hooks/use-app-init";

export const ResetPassword = observer(
    function ResetPassword() {

        const { resetPasswordStore, locationStore } = useStores();

        const navigate = useNavigate();

        const guestAuth = useAppInit()
        const {guestAuthToken} = guestAuth

        const params = useParams();
        const token = params.token

        const [showPassword, setShowPassword] = React.useState(false);
        const [password, setPassword] = React.useState("");
        const [confirmPassword, setConfirmPassword] = React.useState("");
        const [isLoading, setIsLoading] = React.useState(false);
        const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        };

        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        };

        const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value);
        };
        const handleResetPasswordClick = async () => {
            setErrorMessage(null)
            if (!hasValue(password) && !hasValue(confirmPassword)) {
                setErrorMessage('New password and confirm new password are empty.')
                return
            } else if (!hasValue(password)) {
                setErrorMessage('new password is empty.')
                return

            } else if (!hasValue(confirmPassword)) {
                setErrorMessage('Confirm new password is empty.')
                return
            } else if (password !== confirmPassword) {
                setErrorMessage('New password and confirm new password fields should be identical.')
                return
            } else if (password.length < 8) {
                setErrorMessage('New password must have at least 8 characters.')
                return
            }
            if (!token) {
                navigate('/')
                return
            }
            setIsLoading(true)

            const result = await resetPasswordStore.reset({
                token,
                newPassword: password
            }, guestAuthToken)


            if(result.kind == "ok") {
                navigate('/')
            } else {
                setErrorMessage('Something went wrong. Please try again.')
            }
            setIsLoading(false)
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = `ResetPassword`;
            if (!token) {
                navigate('/not-found')
            }
        }, [])

        // let moduleName;
        // if(params.module === 'store-admin') {
        //     moduleName = 'Store Admin'
        // } else {
        //     moduleName = params.module
        // }

        return (
            <>
                <main style={{minHeight: '100vh'}}>
                    {/*
                    <CBox height={90} sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "#212121",
                        zIndex: 0
                    }}>
                        <CContainer>
                            <CGridContainer height={90}>
                                <CGrid item xs={2} md={4}
                                       sx={{
                                           textAlign: "center",
                                           color: "white",
                                           alignItems: "center",
                                           justifyContent: "center",
                                           display: "flex"
                                       }}
                                >
                                    <POSTitle fontSize={32}>
                                        POS
                                    </POSTitle>

                                </CGrid>
                                <CGrid item xs={8} md={4} sx={{
                                    textAlign: "center",
                                    color: "white",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }
                                }} >
                                    <MomentTimer/>
                                </CGrid>

                                <CGrid item xs={2} md={4} sx={{
                                    textAlign: "center",
                                    color: "white",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                                }}>
                                    <CFormControl sx={{ m: 1, minWidth: 80 }}>
                                        <CSelectWhiteBorder
                                            id="demo-simple-select-autowidth"
                                            value={"EN"}
                                            onChange={(event)=>{
                                                const value = event.target.value;
                                            }}
                                            autoWidth
                                            sx={{
                                                fontColor:  "white",
                                            }}
                                            size={"small"}
                                        >
                                            <CMenuItem value={"EN"}>EN</CMenuItem>
                                            <CMenuItem value={"ES"}>ES</CMenuItem>
                                        </CSelectWhiteBorder>
                                    </CFormControl>

                                </CGrid>
                            </CGridContainer>
                        </CContainer>
                    </CBox>
                    */}

                    <CContainer
                        style={{position: "absolute", top: 0, right: 0, left: 0, bottom: 0, display: 'flex', flexDirection: "column", minHeight: '100vh'}}>

                        <CGridContainer sx={{flex:1, display: "flex"}}>
                            <CGrid item xs={0} md={4}/>
                            <CGrid item xs={12} md={4} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CCard sx={{width: "100%"}}>
                                    <CCardContent sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>

                                        <POSTitle sx={{
                                            mb: 4,
                                            textAlign: "center",
                                            fontFamily: "Passion One"}} fontSize={43}>Reset Password
                                        </POSTitle>
                                        <CTextField
                                            sx={{mb: 4}}
                                            label={"New Password"}
                                            onChange={handlePasswordChange}
                                            InputProps={{
                                                type: showPassword ? 'text' : 'password',
                                                endAdornment: <CInputAdornment position="end">
                                                    <CIconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </CIconButton>
                                                </CInputAdornment>
                                            }}
                                        >

                                        </CTextField>
                                        <CTextField
                                            sx={{mb: 4}}
                                            label={"Confirm New Password"}
                                            onChange={handleNewPasswordChange}
                                            InputProps={{
                                                type: showPassword ? 'text' : 'password',
                                                endAdornment: <CInputAdornment position="end">
                                                    <CIconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </CIconButton>
                                                </CInputAdornment>
                                            }}
                                        >

                                        </CTextField>
                                        <CButton variant={"contained"} onClick={handleResetPasswordClick}>
                                            {isLoading ? <CCircularProgress sx={{color: "white"}} size={24} /> : "Reset Password"}
                                        </CButton>
                                        {hasValue(errorMessage) && <CTypography textAlign={'center'} mt={2} variant={'caption'} color={'red'}>
                                            {errorMessage}
                                        </CTypography>}

                                    </CCardContent>
                                </CCard>

                            </CGrid>
                            <CGrid item xs={0} md={4}/>
                        </CGridContainer>
                        <CGridContainer height={90}>
                            <CGrid item xs={4}/>
                            <CGrid item xs={4} sx={{
                                textAlign: "center",
                                color: "#6C6C6C",
                                fontWeight: "bold",
                                fontSize: 12,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex"
                            }}>
                                {hasValue(locationStore.currentLocation?.locationSetting?.locationSettingCopyright) ? locationStore.currentLocation?.locationSetting?.locationSettingCopyright : ''}
                            </CGrid>

                            <CGrid item xs={4}/>
                        </CGridContainer>

                    </CContainer>

                </main>
            </>
        );
    }
)
