import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {OrderModel} from "../order/order";

/**
 * Rick and Morty character model.
 */
export const PaymentModel = types.model("Payment").props({
    paymentId: types.identifierNumber,
    orderId: types.maybeNull(types.number),
    paymentStatus: types.maybeNull(types.string),
    paymentTs: types.maybeNull(types.string),
    paymentAmount: types.maybeNull(types.number),
    paymentCreatedAt: types.maybeNull(types.string),
    paymentUpdatedAt: types.maybeNull(types.string),
    paymentMetaData: types.maybeNull(types.string),
    paymentGatewayName: types.maybeNull(types.string),
    paymentTransactionId: types.maybeNull(types.string),
    paymentStoreName: types.maybeNull(types.string),
    paymentGatewayId: types.maybeNull(types.number),
    paymentStoreId: types.maybeNull(types.number),
    paymentCardNumber: types.maybeNull(types.string),
    order: types.optional(types.maybeNull(OrderModel), {orderId: -1}),
    basicBundleId: types.maybeNull(types.number),
    paymentCardholderName: types.maybeNull(types.string),
    paymentAddress: types.maybeNull(types.string),
    paymentZip: types.maybeNull(types.number),
    paymentEmail: types.maybeNull(types.string),
    paymentPhone: types.maybeNull(types.string),
    paymentOrderNo: types.maybeNull(types.string),
    paymentCartHash: types.maybeNull(types.string),
})

type PaymentType = Instance<typeof PaymentModel>
export interface Payment extends PaymentType {}
type PaymentSnapshotType = SnapshotOut<typeof PaymentModel>
export interface PaymentSnapshot extends PaymentSnapshotType {}

export const defaultPayment = {
    paymentId: undefined,
    orderId: undefined,
    paymentStatus: '',
    paymentAmount: undefined,
    paymentStoreName: '',
    paymentStoreId: undefined
}