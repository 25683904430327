import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const CogsModel = types.model("Cogs").props({
    cogsId: types.maybeNull(types.number),
    cogsTitle: types.maybeNull(types.string),
    cogsDescription: types.maybeNull(types.string),
    cogsCost: types.maybeNull(types.number),
    cogsValidUntil: types.maybeNull(types.string),
})

type CogsType = Instance<typeof CogsModel>
export interface Cogs extends CogsType {}
type CogsSnapshotType = SnapshotOut<typeof CogsModel>
export interface CogsSnapshot extends CogsSnapshotType {}

export const defaultCogs = {
    cogsId: undefined,
    cogsTitle: '',
    cogsDescription: '',
    cogsCost: undefined,
    cogsValidUntil: '',
}