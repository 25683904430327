import {useStores} from "../../models";
import * as React from "react";
import dayjs, {Dayjs} from "dayjs";
import {addCurrentTimezone} from "../../utils/date-time";
import {useEffect} from "react";
import {SummaryHeader} from "../../components/summary-header";
import {SummaryContent} from "../../components/summary-content";
import {CBox, CCard} from "../../components";

interface SummaryScreenContentProps {
    module: string
}

let statelessSummaryDialogFromDate: Dayjs | null = dayjs().subtract(2, 'days');
let statelessSummaryDialogToDate: Dayjs | null = dayjs();
let currentAuthToken = ''

let statelessSummaryDialogPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessSummaryDialogOrderInfo = {
    orderBy: 'orderCreatedAt',
    order: 'DESC'
}

export const SummaryScreenContent = ({
                                         module
                                     }: SummaryScreenContentProps) => {

    const {orderStore, authStore} = useStores();

    const [summaryDialogIsLoading, setSummaryDialogIsLoading] = React.useState(false);
    const [summaryDialogFromDate, setSummaryDialogFromDate] = React.useState<Dayjs | null>(dayjs().subtract(2, 'days'))
    const [summaryDialogToDate, setSummaryDialogToDate] = React.useState<Dayjs | null>(dayjs())
    const [summaryDialogData, setSummaryDialogData] = React.useState<{
        totalSum: number,
        totalItems: number,
        totalGross: number,
        totalSales: number,
        totalPaidOrders: number,
        ordersList: any[],
        totalOrders: number,
    }>({
        totalSum: 0,
        totalItems: 0,
        totalGross: 0,
        totalSales: 0,
        totalPaidOrders: 0,
        ordersList: [],
        totalOrders: 0,
    })
    const [tabValue, setTabValue] = React.useState(0);
    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'orderCreatedAt',
        order: 'DESC'
    })

    const handlePageChange = async (details, page) => {
        console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessSummaryDialogPaginationInfo = {
            ...paginationInfo,
            page
        }
        await getSummaryList()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessSummaryDialogPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await getSummaryList()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessSummaryDialogOrderInfo.orderBy === property && statelessSummaryDialogOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessSummaryDialogOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        await getSummaryList()
    };


    const clearSummary = () => {
        setSummaryDialogData({
            totalSum: 0,
            totalItems: 0,
            totalGross: 0,
            totalSales: 0,
            totalPaidOrders: 0,
            ordersList: [],
            totalOrders: 0,
        })
    }


    const getSummaryStatistics = async () => {
        if (
            (authStore.storeAdminAuth && authStore.storeAdminAuth?.userLocationId) ||
            (authStore.lockboxAuth && authStore.lockboxAuth?.userLocationId) ||
            (authStore.adminAuth)
        ) {
            setSummaryDialogIsLoading(true)
            const data = {
                fromDate: statelessSummaryDialogFromDate ? addCurrentTimezone(statelessSummaryDialogFromDate.set('hour', 0).set('minute', 0).set('second', 0)) : null,
                toDate: statelessSummaryDialogToDate ? addCurrentTimezone(statelessSummaryDialogToDate.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999)) : null,
            }

            if (module === 'store admin') {
                data['storeId'] = authStore.storeAdminAuth?.userLocationId
            } else if (module === 'lockbox') {
                data['lockboxId'] = authStore.lockboxAuth?.userLocationId
            }
            console.log('getSummary', data)
            // @ts-ignore
            const result = await orderStore.summaryStatistics(data, currentAuthToken)
            if (result.kind === "ok") {
                setSummaryDialogData({
                    ...summaryDialogData, ...{
                        totalSum: result.summary.totalSum,
                        totalItems: result.summary.totalItems,
                        totalGross: result.summary.totalGross,
                        totalSales: result.summary.totalSales,
                        totalPaidOrders: result.summary.totalPaidOrders,
                    }
                })
            }
            setSummaryDialogIsLoading(false)
        }
    }

    const getSummaryList = async () => {
        if (
            (authStore.storeAdminAuth && authStore.storeAdminAuth?.userLocationId) ||
            (authStore.lockboxAuth && authStore.lockboxAuth?.userLocationId) ||
            (authStore.adminAuth)
        ) {
            setSummaryDialogIsLoading(true)
            const data = {
                fromDate: statelessSummaryDialogFromDate ? addCurrentTimezone(statelessSummaryDialogFromDate.set('hour', 0).set('minute', 0).set('second', 0)) : null,
                toDate: statelessSummaryDialogToDate ? addCurrentTimezone(statelessSummaryDialogToDate.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999)) : null,
                page: statelessSummaryDialogPaginationInfo.page,
                perPage: statelessSummaryDialogPaginationInfo.perPage,
                sortBy: statelessSummaryDialogOrderInfo.orderBy,
                sortDirection: statelessSummaryDialogOrderInfo.order,
            }

            if (module === 'store admin') {
                data['storeId'] = authStore.storeAdminAuth?.userLocationId
            } else if (module === 'lockbox') {
                data['lockboxId'] = authStore.lockboxAuth?.userLocationId
            }
            console.log('getSummary', data)
            // @ts-ignore
            const result = await orderStore.summaryList(data, currentAuthToken)
            if (result.kind === "ok") {
                setSummaryDialogData({
                    ...summaryDialogData, ...{
                        ordersList: result.summary.ordersList,
                        totalOrders: result.summary.totalOrders,
                    }
                })

            }
            setSummaryDialogIsLoading(false)
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        ;(async () => {
            if (module === 'store admin' && authStore.storeAdminAuth) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else if (module === 'lockbox' && authStore.lockboxAuth) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            } else if (module === 'admin' && authStore.adminAuth) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
            await getSummaryStatistics()
            await getSummaryList()
        })()

    }, [])


    return (
        <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>
            <CBox m={2}>
                <CCard sx={{height: "100%", display: "flex", alignItems: "center"}}>
                    <SummaryHeader
                        tabValue={tabValue}
                        handleChange={handleChange}
                        summaryDialogToDate={summaryDialogToDate}
                        summaryDialogFromDate={summaryDialogFromDate}
                        setSummaryDialogFromDate={(value) => {
                            setSummaryDialogFromDate(value)
                            statelessSummaryDialogFromDate = value
                        }}
                        setSummaryDialogToDate={(value) => {
                            setSummaryDialogToDate(value)
                            statelessSummaryDialogToDate = value
                        }}
                        getSummary={()=>{
                            if (tabValue === 0) {
                                getSummaryStatistics()
                            } else {
                                getSummaryList()
                            }
                        }}
                        clearSummary={clearSummary}
                    />
                </CCard>
            </CBox>
            <CBox flexGrow={1} mx={2} mb={2}>
                <CCard sx={{
                    flex: 1,
                    height: "100%",
                    '& .pos-table-header': {
                        fontSize: "16px",
                        fontWeight: "400"
                    },
                    '& .pos-table-header-first': {
                        ml: "20px"
                    },
                    '& .pos-table-cell': {
                        color: "#363636"
                    },
                    '& .pos-table-cell-first': {
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "400",
                        ml: "24px"
                    },
                }}>
                    <SummaryContent
                        tabValue={tabValue}
                        summaryDialogIsLoading={summaryDialogIsLoading}
                        summaryDialogData={summaryDialogData}
                        paginationInfo={paginationInfo}
                        handlePageChange={handlePageChange}
                        handlePageSizeChange={handlePageSizeChange}
                        orderInfo={orderInfo}
                        handleRequestSort={handleRequestSort}
                        module={module}
                    />
                </CCard>
            </CBox>
        </CBox>
    )
}