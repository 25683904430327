import {hasValue} from "./empty-check";

export const getCustomerName = (customer, user) => {
    if (hasValue(customer?.customerFname)) {
        return `${customer.customerFname} ${customer.customerLname}`
    } else if (hasValue(user?.userDetail)) {
        return `${user?.userDetail?.userDetailFirstName} ${user?.userDetail?.userDetailLastName}`
    } else {
        return ''
    }
}

export const getCustomerPhone = (customer, user) => {
    if (hasValue(customer?.customerPhone)) {
        return `${customer.customerPhone}`
    } else if (hasValue(user?.userDetail)) {
        return `${user?.userDetail?.userDetailPhone}`
    } else {
        return ''
    }
}

export const getCustomerEmail = (customer, user) => {
    if (hasValue(customer?.customerEmail)) {
        return `${customer.customerEmail}`
    } else if (hasValue(user?.userDetail)) {
        return `${user?.userDetail?.userDetailEmail} ${user?.userDetail?.userDetailEmail}`
    } else {
        return ''
    }
}

export const getCustomerAddress = (customer, user) => {
    if (hasValue(customer?.customerAddress1)) {
        return `${customer?.customerAddress1} ${customer?.customerAddress2}, ${customer?.customerCity}, ${customer?.customerState}, ${customer?.customerZip}`
    } else if (hasValue(user?.userDetail)) {
        return `${user?.userDetail?.userDetailAddress1} ${user?.userDetail?.userDetailAddress2}, ${user?.userDetail?.userDetailCity}, ${user?.userDetail?.userDetailState}, ${user?.userDetail?.userDetailZip}`
    } else {
        return ''
    }
}

export const getCustomerZip = (customer, user) => {
    if (hasValue(customer?.customerZip)) {
        return customer?.customerZip
    } else if (hasValue(user?.userDetail)) {
        return user?.userDetail?.userDetailZip
    } else {
        return ''
    }
}