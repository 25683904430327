import {CTypography} from ".."
import { primary } from "../../theme"
import {ButtonBase} from "@mui/material";

const generalContainerStyle = {
    display: "inline-block",
    padding: "12px",
    border: "1px solid #CBCBCB",
    borderRadius: "4px",
    minWidth: "150px",
    mr: 2,
    textAlign: 'left'
}

const generalTitleStyle = {
    fontWeight: "600",
    fontSize: "16px",
    color: "#424242"
}

const selectedContainerStyle = {
    backgroundColor: primary["500"]
}

const selectedTitleStyle = {
    fontWeight: "600",
    fontSize: "16px",
    color: "white"
}

export const LockboxLocationSelectable = (props) => {

    let containerStyle = generalContainerStyle
    let titleStyle = generalTitleStyle

    if(props.isSelected) {
        containerStyle = {...containerStyle, ...selectedContainerStyle}
        titleStyle = {...titleStyle, ...selectedTitleStyle}
    }

    return <ButtonBase key={props.key} onClick={props.onClick} sx={containerStyle}>
        <CTypography
            // mb={1}
            sx={titleStyle}>
            {props.title}
        </CTypography>
        {/*<CTypography sx={subtitleStyle}>*/}
        {/*    {props.subtitle}*/}
        {/*</CTypography>*/}
    </ButtonBase>
}