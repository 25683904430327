import {CBox, CButton, CContainer, CGrid, CGridContainer, CTypography} from "../mui-extracted";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Email, LocationOn, Phone} from "@mui/icons-material";
import ListItemButton from "@mui/material/ListItemButton";
import {ListItemText} from "@mui/material";
import {useStores} from "../../models";
import {scrollToTop} from "../../utils/navigation";
import {hasValue} from "../../utils/empty-check";

export const Footer = ( ) => {

    const {categoryStore, locationStore} = useStores()

    const navigate = useNavigate();

    return (
        <>
            <CBox m={4} mt={8} border={'5px solid black'} bgcolor={'white'} textAlign={'center'}>
                <CTypography variant={'h5'} mt={8} fontWeight={'bold'}>
                    {`WARNING: This product contains nicotine.`}
                </CTypography>
                <CTypography variant={'h5'} mt={2} mb={8} fontWeight={'bold'}>
                    {`Nicotine is an addictive chemical.`}
                </CTypography>
            </CBox>
            <CBox  width={'100%'} sx={{backgroundColor: "#212121"}}>
                <CContainer sx={{py: 2}}>
                    <CGridContainer spacing={4}>
                        <CGrid item xs={12} md={4} color={'whitesmoke'}>
                            <CTypography variant={'h5'}> Contact </CTypography>
                            <CBox display={'flex'} my={3} flexDirection={'row'}>
                                <Email></Email>
                                <CTypography ml={2}>{hasValue(locationStore.currentLocation?.locationSetting?.locationSettingEmail) ? locationStore.currentLocation?.locationSetting?.locationSettingEmail : ''}</CTypography>
                            </CBox>
                            <CBox display={'flex'} my={3} flexDirection={'row'}>
                                <Phone/>
                                <CTypography ml={2}>{hasValue(locationStore.currentLocation?.locationSetting?.locationSettingPhone) ? locationStore.currentLocation?.locationSetting?.locationSettingPhone : ''}</CTypography>
                            </CBox>
                            <CBox display={'flex'} my={3} flexDirection={'row'}>
                                <LocationOn/>
                                <CTypography ml={2}>{hasValue(locationStore.currentLocation?.locationSetting?.locationSettingAddress) ? locationStore.currentLocation?.locationSetting?.locationSettingAddress : ''}</CTypography>
                            </CBox>
                        </CGrid>
                        <CGrid item xs={6} md={4} color={'whitesmoke'}>
                            <CTypography variant={'h5'}> Links </CTypography>
                            <CBox display={'flex'} my={0} flexDirection={'row'}>
                                <ListItemButton sx={{pl:0, color:'white'}} onClick={()=> {
                                    navigate('/store/promotions')
                                    scrollToTop()
                                }}>
                                    <ListItemText>
                                        Promotions
                                    </ListItemText>
                                </ListItemButton>
                            </CBox>
                            <CBox display={'flex'} my={0} flexDirection={'row'}>
                                <ListItemButton sx={{pl:0, color:'white'}} onClick={()=> {
                                    navigate('/pages/about-us')
                                    scrollToTop()
                                }}>
                                    <ListItemText>
                                        About US
                                    </ListItemText>
                                </ListItemButton>
                            </CBox>
                            <CBox display={'flex'} my={0} flexDirection={'row'}>
                                <ListItemButton sx={{pl:0, color:'white'}} onClick={()=> {
                                    navigate('/pages/faq')
                                    scrollToTop()
                                }}>
                                    <ListItemText>
                                        FAQ
                                    </ListItemText>
                                </ListItemButton>
                            </CBox>
                            <CBox display={'flex'} my={0} flexDirection={'row'}>
                                <ListItemButton sx={{pl:0, color:'white'}} onClick={()=> {
                                    navigate('/')
                                    scrollToTop()
                                }}>
                                    <ListItemText>
                                        Home
                                    </ListItemText>
                                </ListItemButton>
                            </CBox>
                        </CGrid>
                        <CGrid item xs={6} md={4} color={'whitesmoke'}>
                            <CTypography variant={'h5'}> Categories </CTypography>
                            {categoryStore.categories && categoryStore.categories.map((category, index)=>{
                                return  <CBox key={`${category.categoryId}${index}_FTR`} display={'flex'} my={0} flexDirection={'row'}>
                                    <ListItemButton sx={{pl:0, color:'white'}} onClick={()=> {
                                        navigate(`/store/category/${category.categoryName}`)
                                        scrollToTop()
                                    }}>
                                        <ListItemText>
                                            {category.categoryName}
                                        </ListItemText>
                                    </ListItemButton>
                                </CBox>
                            })}
                        </CGrid>
                    </CGridContainer>

                </CContainer>
            </CBox>
            <CBox minHeight={90} width={'100%'} sx={{backgroundColor: "#111111", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CContainer>
                    <CGridContainer>
                        <CGrid item xs={12} color={'whitesmoke'}>
                            {/*© 2023, Badiz LLC. All rights reserved.*/}
                            {hasValue(locationStore.currentLocation?.locationSetting?.locationSettingCopyright) ? locationStore.currentLocation?.locationSetting?.locationSettingCopyright : ''}
                            <CButton sx={{
                                color: 'white'
                            }}

                                     onClick={()=>{
                                         navigate('/pages/terms')
                                         scrollToTop()
                                     }}>
                                Terms of Service
                            </CButton> |
                            <CButton sx={{
                                color: 'white'
                            }}
                                     onClick={()=>{
                                         navigate('/pages/privacy')
                                         scrollToTop()
                                     }}
                            >
                                Privacy
                            </CButton>
                            {(hasValue(locationStore.currentLocation?.locationSetting?.locationSettingAuthorizeSeal) &&
                                locationStore.currentLocation?.locationSetting?.locationSettingAuthorizeSeal) ?
                                <div dangerouslySetInnerHTML={{ __html: locationStore.currentLocation?.locationSetting?.locationSettingAuthorizeSeal }} /> :
                                ""
                            }
                        </CGrid>
                    </CGridContainer>
                </CContainer>
            </CBox>
        </>
    )
}