import {useNavigate} from "react-router-dom";
import {CCard, CCardContent, CIconButton, CTypography} from "../mui-extracted";
import {
    Payment,
    PermDeviceInformation,
    Receipt,
    ShoppingCart,
} from "@mui/icons-material";
import * as React from "react";

export const CheckoutBreadcrumb = (props) => {

    const {allowedPages = [], currentPage} = props

    const navigate = useNavigate();

    return (
        <CCard>
            <CCardContent sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <CIconButton
                    sx={{
                        mb: 2,
                        mx: 1,
                        flexDirection: 'column'
                    }}
                    disabled={!allowedPages.includes('cart')}
                    color={currentPage === 'cart' ? 'primary' : 'default'}
                    onClick={() => navigate(`/store/cart`)}
                >
                    <ShoppingCart/>
                    <CTypography variant={'button'}>
                        Cart
                    </CTypography>
                </CIconButton>
                <CIconButton
                    sx={{
                        mb: 2,
                        mx: 1,
                        flexDirection: 'column'
                    }}
                    disabled={!allowedPages.includes('information')}
                    color={currentPage === 'information' ? 'primary' : 'default'}
                    onClick={() => navigate(`/store/information`)}
                >
                    <PermDeviceInformation/>
                    <CTypography variant={'button'}>
                        Information
                    </CTypography>
                </CIconButton>
                <CIconButton
                    sx={{
                        mb: 2,
                        mx: 1,
                        flexDirection: 'column'
                    }}
                    disabled={!allowedPages.includes('payment')}
                    color={currentPage === 'payment' ? 'primary' : 'default'}
                    onClick={() => navigate(`/store/order-details`)}
                >
                    <Payment/>
                    <CTypography variant={'button'}>
                        Payment
                    </CTypography>
                </CIconButton>
                <CIconButton
                    sx={{
                        mb: 2,
                        mx: 1,
                        flexDirection: 'column'
                    }}
                    disabled={!allowedPages.includes('receipt')}
                    color={currentPage === 'receipt' ? 'primary' : 'default'}
                    onClick={() => navigate(`/store/receipt`)}
                >
                    <Receipt/>
                    <CTypography variant={'button'}>
                        Receipt
                    </CTypography>
                </CIconButton>
            </CCardContent>
        </CCard>
    )
}