import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  ResetPasswordResetParams,
  ResetPasswordResetResult,
  ResetPasswordCreateParams,
  ResetPasswordCreateResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class ResetPasswordApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: ResetPasswordCreateParams): Promise<ResetPasswordCreateResult> {
    try {
      // const functionName = "login"
      const uri = "reset-password/create"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      console.log('create', response)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const data = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", data}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async reset(params: ResetPasswordResetParams): Promise<ResetPasswordResetResult> {
    try {
      // const functionName = "login"
      const uri = "reset-password/reset"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }
      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
