/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./localization"
import React, {useState, useEffect} from "react"
import {initFonts} from "./theme/fonts" // expo
import {AppNavigator} from "./navigators"
import {RootStore, RootStoreProvider, setupRootStore} from "./models"
import {Chart, registerables} from 'chart.js';
import {createTheme, ThemeProvider} from "@mui/material";
import {primary} from "./theme";
import {useJsApiLoader} from "@react-google-maps/api";
import {suppressMessages} from "./utils/logs";
import {HelmetProvider} from 'react-helmet-async';

Chart.register(...registerables);

const libraries = ['places']

export let isMapLoaded;

function App() {
    const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCCzq3G9J8lkO2AAU8WDyDhzBah09MS81g",
        // @ts-ignore
        libraries
    })

    const theme = createTheme({
        typography: {
            fontFamily: [
                'Asap Condensed',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        palette: {
            primary: primary,
        }
    });

    useEffect(() => {
        ;(async () => {
            await initFonts() // expo
            suppressMessages()
            setupRootStore().then(setRootStore)
        })()
    }, [])

    useEffect(() => {
        isMapLoaded = isLoaded
    }, [isLoaded])

    if (!rootStore) return null

    // otherwise, we're ready to render the app
    return (
        <HelmetProvider>
            <RootStoreProvider value={rootStore}>
                <ThemeProvider theme={theme}>
                    <AppNavigator/>
                </ThemeProvider>
            </RootStoreProvider>
        </HelmetProvider>
    )
}

export default App
