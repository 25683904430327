import * as React from "react";
import {observer} from "mobx-react-lite";
import {SummaryScreenContent} from "../../../sections/summary-screen-content";


export const StorePanelSummary = observer(
    function StorePanelSummary() {
        return (
            <SummaryScreenContent
                module={'store admin'}
            />
        );
    }
)
