import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CCard,
    CCardContent,
    CGrid,
    CGridContainer,
} from "../../../components";

export const AdminPanelDashboard = observer(
    function AdminPanelDashboard() {

        return (
            <>
                <CGridContainer spacing={2} padding={"12px"}>

                    <CGrid item xs={6}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                    <CGrid item xs={6}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                    <CGrid item xs={4}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                    <CGrid item xs={4}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                    <CGrid item xs={4}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                    <CGrid item xs={6}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                    <CGrid item xs={6}>
                        <CCard sx={{height: 300}}>
                            <CCardContent>

                            </CCardContent>
                        </CCard>
                    </CGrid>
                </CGridContainer>
            </>
        );
    }
)
