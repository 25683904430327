import {
    CBox,
    CButton,
    CCard, CCircularProgress, CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTab,
    CTabs,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {TabPanel} from "../tab-panel";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {getCustomerAddress, getCustomerEmail, getCustomerName, getCustomerPhone} from "../../utils/customer";
import {isEmpty} from "validate.js";
import {Table} from "../table";
import {convertToCurrentTimezone} from "../../utils/date-time";
import {useMediaQuery} from "@mui/material";
import dayjs from "dayjs";
import * as qrcode from 'qrcode';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const OrderDialog = ({
                                orderDialog,
                                handleSubmit,
                                handleClose,
                                handleSaveChanges,
                                orderDetailRows,
                                validation,
                                setOrderDialog,
                                setValidation,
                                orderPayments,
                                module,
                                viewOnly = false,
                                handlePaymentDialogOpen
                            }) => {

    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const textBoxSize = isSmallScreen || isExtraSmallScreen ? 'small' : 'medium'

    const headerButtonsSize = isSmallScreen || isExtraSmallScreen ? 'small' : 'medium'

    const handlePrintLabel = async () => {

        const qrCodeUrl = `${orderDialog.data.kiosk.locationSlug}/order/${orderDialog.data.orderHash}`;
        const qrCodeImage = await qrcode.toDataURL(qrCodeUrl);

        const printContent = document.createElement("div");
        printContent.style.width = "4in";
        printContent.style.height = "6in";

        let receiverAddressComponent;

        if (hasValue(orderDialog.data.localDeliveryAddress)) {
            receiverAddressComponent = `<b style="width: 4in; font-size: medium">${orderDialog.data.localDeliveryAddress?.split(',')[0]} ${hasValue(orderDialog.data.localDeliveryAddress?.split(',')[1] ) ? '<br>'+orderDialog.data.localDeliveryAddress?.split(',')[1] : ''}${hasValue(orderDialog.data.localDeliveryAddress?.split(',')[2]) ? ', '+orderDialog.data.localDeliveryAddress?.split(',')[2] : ''}${orderDialog.data.localDeliveryAddress?.split(',')[3] ? `, ${hasValue(orderDialog.data.localDeliveryAddress?.split(',')[3]) ? orderDialog.data.localDeliveryAddress?.split(',')[3] : ''}` : ''} </b><br><br>`
        } else {
            receiverAddressComponent = `   <b style="font-size: medium">${ /* @ts-ignore */ orderDialog.data.lockbox.locationAddress1}<br>${ /* @ts-ignore */ orderDialog.data.lockbox.locationCity}, ${ /* @ts-ignore */ orderDialog.data.lockbox.locationState}<br>${ /* @ts-ignore */ orderDialog.data.lockbox.locationZip} </b><br>`
        }

        let customerNotesComponent = '';

        if (hasValue(orderDialog.data.orderCustomerNote)) {
            customerNotesComponent = `<tr>
<td colspan="2">
<p style="font-size: medium">Customer Notes:<b> ${orderDialog.data.orderCustomerNote}</b></p>
</td>
</tr>`
        }


        printContent.innerHTML = `<style>
tr, td {
  border:2px solid black;
  padding: 12px;
}
</style><button onclick="window.print()">Print</button><table style="width: 100%">
<tr>
<td>
<p style="font-size: medium">FROM:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
<b>WA FINEST</b><br>
${orderDialog.data.kiosk.locationAddress1}<br>
${orderDialog.data.kiosk.locationCity}, ${orderDialog.data.kiosk.locationState} ${orderDialog.data.kiosk.locationZip}<br>
</td>
<td>
<p style="font-size: medium"> SHIP DATE: ${dayjs(orderDialog.data.orderCreatedAt).format('MM/DD/YY')}<br>

ACT WGT: 1.00 LB <br>
DIMMED: 6 x 9 IN <br>
BILL SENDER
</p>
</td>
</tr>
<tr>
<td colspan="2">
<p>
TO:
   <h3 style="margin-bottom: -4px; margin-top: -16px">${ /* @ts-ignore */ getCustomerName(orderDialog.data?.customer, orderDialog.data?.user)}</h3>
   ${receiverAddressComponent}
   <b style="margin-top: -4px; font-size: medium">${ /* @ts-ignore */ getCustomerPhone(orderDialog.data?.customer, orderDialog.data?.user)}</b>
</p>
</td>
</tr>
<tr>
<td colspan="2">
<p style="font-size: medium">Order No:<b> #${orderDialog.data.orderNo}</b></p>
</td>

</tr>
${customerNotesComponent}
<tr>
<td colspan="2">
<img width="300px" height="200px" style="object-fit: contain" src="${qrCodeImage}" alt="QR Code" />
</td>

</tr>
<tr>
<td colspan="2">
<img width="300px" height="90px" style="object-fit: contain" src="https://i.ibb.co/2jqWPVt/Screenshot-2023-05-21-132529.png"/>
</td>
</tr>
</table>`
        // @ts-ignore

        const windowUrl = 'about:blank';
        const uniqueName = new Date();
        const windowName = `Print ${uniqueName.getTime()}`;

        const printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');
        if (printWindow) {
            printWindow.document.write(printContent.innerHTML);
            // printWindow.document.close();
            // printWindow.focus();
            // printWindow.print();
            // printWindow.close();
        }


    }

    return (<CDialog open={orderDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
        sx: {
            borderRadius: "37px"
        }
    }}>
        <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
            <CGridContainer spacing={2}>
                <CGrid item xs={12} md={3} display={'flex'} alignItems={'center'}>
                    <CTypography fontSize={"24px"} mx={4}>
                        Order #{orderDialog.data.orderNo}
                    </CTypography>
                </CGrid>
                <CGrid item xs={12} md={9}>
                    <CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <CTab label="Items" {...a11yProps(0)} />
                        <CTab label="General" {...a11yProps(1)} />
                        {orderPayments !== null && <CTab label="Payment" {...a11yProps(2)} />}
                    </CTabs>
                </CGrid>
            </CGridContainer>
        </CBox>
        <Divider></Divider>
        <TabPanel value={tabValue} index={0} style={{height: '70vh', overflowY: 'scroll'}}>
            <CGridContainer spacing={0} p={0}>
                <CGrid item xs={12} maxHeight={'70vh'} sx={{
                    overflowY: 'scroll',
                }}>
                    {isEmpty(orderDetailRows) ?
                        <CTypography m={4} textAlign={'center'} variant={'body1'}> No order details found
                            for this order. </CTypography> : <Table
                            columns={(isSmallScreen || isExtraSmallScreen) ? [
                                {
                                    field: 'col5',
                                    headerName: 'Actions',
                                    width: 250,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell",
                                    renderCell: (params) => {

                                        const row = params.row

                                        return <CBox
                                            sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                            <CBox sx={{
                                                flex: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                px: 2
                                            }}>
                                                <CTypography variant={'h5'}
                                                             sx={{mb: 1}}>{row.name.split('-')[1]}x{row.quantity}</CTypography>
                                                <CTypography variant={'h6'}
                                                             color={'error'}
                                                             sx={{mb: 1}}>{row.code}</CTypography>
                                                <CTypography variant={'subtitle2'}
                                                             sx={{mb: 1}}>{row.name}</CTypography>
                                                {row.availability && <CTypography variant={'subtitle2'}>
                                                    Currently Available: {row.availability}
                                                </CTypography>}
                                                {/*<CTypography variant={'subtitle2'}*/}
                                                {/*             sx={{mb: 1}}>${row.price}</CTypography>*/}
                                            </CBox>
                                        </CBox>;
                                    }
                                },
                            ] : [
                                {
                                    field: 'code',
                                    headerName: 'Code',
                                    width: 80,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'name',
                                    headerName: 'Sku',
                                    width: 150,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'quantity',
                                    headerName: 'Amount',
                                    width: 50,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'price',
                                    headerName: 'Price',
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                            ]}
                            rows={orderDetailRows}
                            count={orderDetailRows.length}
                            page={0}
                            rowsPerPage={100}
                            onPageChange={() => {
                                console.log('tmp')
                            }}
                            onRowsPerPageChange={() => {
                                console.log('tmp')
                            }}
                            orderBy={'tmp'}
                            order={'DESC'}
                            onRequestSort={() => {
                                console.log('tmp')
                            }}
                            isLoading={false}
                            noRowTitleOnSmallSize={true}
                        />}
                </CGrid>
            </CGridContainer>
        </TabPanel>
        <TabPanel value={tabValue} index={1} style={{height: '70vh', overflowY: 'scroll'}}>
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={12} md={6}>
                    <CTextField fullWidth label={"Customer Notes"}
                                disabled
                                multiline={true}
                                rows={4}
                        // @ts-ignore
                                value={orderDialog.data.orderCustomerNote}/>
                </CGrid>
                <CGrid item xs={12} md={6}>
                    <CTextField fullWidth label={"Store/Lockbox Notes"}
                                multiline={true}
                                rows={4}
                                onChange={(event) => {
                                    setOrderDialog({
                                        ...orderDialog,
                                        data: {...orderDialog.data, orderLocationNote: event.target.value}
                                    });
                                }}
                        // @ts-ignore
                                value={orderDialog.data.orderLocationNote}/>
                </CGrid>
                <CGrid item xs={6} md={2}>
                    <CTextField disabled fullWidth label={"Total (USD)"}
                                error={hasValue(validation.total)}
                                type={"number"}
                                helperText={hasValue(validation.total) ? validation.total : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderDialog({
                                        ...orderDialog,
                                        data: {...orderDialog.data, orderTotal: Number(event.target.value)}
                                    });
                                    if (hasValue(validation.total)) {
                                        setValidation({...validation, total: ''})
                                    }
                                }} value={orderDialog.data.orderTotal?.toFixed(2)}/>
                </CGrid>
                <CGrid item xs={6} md={2}>
                    <CTextField disabled={module === 'lockbox'} fullWidth label={"Status"} select
                                error={hasValue(validation.status)}
                                helperText={hasValue(validation.status) ? validation.status : null}
                                onChange={(event) => {
                                    setOrderDialog({
                                        ...orderDialog,
                                        data: {...orderDialog.data, orderStatus: event.target.value}
                                    });
                                    if (hasValue(validation.status)) {
                                        setValidation({...validation, status: ''})
                                    }
                                }} value={orderDialog.data.orderStatus}>
                        <CMenuItem value={'received'}>Received</CMenuItem>
                        <CMenuItem value={'ready'}>Ready</CMenuItem>
                        <CMenuItem value={'picked_up'}>Picked Up</CMenuItem>
                        <CMenuItem value={'canceled'}>Canceled</CMenuItem>
                    </CTextField>
                </CGrid>
                <CGrid item xs={6} md={2}>
                    <CTextField fullWidth label={"Delivery Method"} select
                                onChange={(event) => {
                                    setOrderDialog({
                                        ...orderDialog,
                                        data: {...orderDialog.data, deliveryMethod: event.target.value}
                                    });
                                }} value={orderDialog.data.deliveryMethod}>
                        <CMenuItem value={'local-delivery'}>Local Delivery</CMenuItem>
                        <CMenuItem value={'lockbox'}>Lockbox</CMenuItem>
                    </CTextField>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Store"}
                                disabled
                        // @ts-ignore
                                value={orderDialog.data.kiosk ? orderDialog.data?.kiosk?.locationName : ''}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Lockbox"}
                                disabled
                        // @ts-ignore
                                value={orderDialog.data.lockbox ? orderDialog.data?.lockbox.locationName : ''}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Created At"}
                                disabled
                        // @ts-ignore
                                value={orderDialog.data.orderCreatedAt}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Name"}
                                disabled
                        // @ts-ignore
                                value={getCustomerName(orderDialog.data.customer, orderDialog.data.user)}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Phone"}
                                disabled
                        // @ts-ignore
                                value={getCustomerPhone(orderDialog.data.customer, orderDialog.data.user)}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Email"}
                                disabled
                        // @ts-ignore
                                value={getCustomerEmail(orderDialog.data.customer, orderDialog.data.user)}/>
                </CGrid>
                {/*<CGrid item xs={3}>*/}
                {/*    <CTextField fullWidth label={"Pickup Time"}*/}
                {/*                disabled*/}
                {/*        // @ts-ignore*/}
                {/*                value={convertTo12Hour(orderDialog.data.orderPickupTime)}/>*/}
                {/*</CGrid>*/}
                <CGrid item xs={12}>
                    <CTextField fullWidth label={"Customer Billing Address"}
                                disabled
                        // @ts-ignore
                                value={getCustomerAddress(orderDialog.data.customer, orderDialog.data.user)}/>
                </CGrid>
                {orderDialog.data.deliveryMethod === 'local-delivery' && <CGrid item xs={12}>
                    <CTextField fullWidth label={"Local Delivery Address"}
                                onChange={(event) => {
                                    setOrderDialog({
                                        ...orderDialog,
                                        data: {...orderDialog.data, localDeliveryAddress: event.target.value}
                                    });
                                }}
                        // @ts-ignore
                                value={orderDialog.data.localDeliveryAddress}/>
                </CGrid>}

            </CGridContainer>
        </TabPanel>
        {orderPayments !== null && <TabPanel value={tabValue} index={2} style={{height: '70vh', overflowY: 'scroll'}}>
            <CGridContainer spacing={0} p={0}>
                <CGrid item xs={12} maxHeight={'70vh'} sx={{
                    overflowY: 'scroll',
                }}>
                    {isEmpty(orderPayments) ? <CTypography m={4}>
                        No Payments for this order.
                    </CTypography> : orderPayments.map(orderPayment => <CCard variant={'outlined'} sx={{
                        m: 1,
                        borderRadius: '18px'
                    }}>
                        <CGridContainer spacing={2} p={2}>
                            <CGrid item xs={6}>
                                <CTypography>
                                    Transaction
                                    ID: {orderPayment?.paymentMetaData ? JSON.parse(orderPayment?.paymentMetaData)?.transactionResponse?.transId : ''}
                                </CTypography>
                            </CGrid>
                            <CGrid item xs={6}>
                                <CTypography>
                                    Amount: {orderPayment.paymentAmount?.toFixed(2)}
                                </CTypography>
                            </CGrid>
                            <CGrid item xs={6}>
                                <CTypography>
                                    Created
                                    At: {orderPayment?.paymentCreatedAt ? convertToCurrentTimezone(orderPayment?.paymentCreatedAt, 'MMM D, h:mm A') : ''}
                                </CTypography>
                            </CGrid>
                            <CGrid item xs={6}>
                                <CTypography>
                                    Status: {orderPayment?.paymentStatus}
                                </CTypography>
                            </CGrid>
                            <CGrid item xs={12}>
                                <CTypography>
                                    Meta
                                    Data: {orderPayment?.paymentMetaData ? JSON.stringify(JSON.parse(orderPayment?.paymentMetaData), null, 2) : ''}
                                </CTypography>
                            </CGrid>
                        </CGridContainer>
                    </CCard>)}
                </CGrid>
            </CGridContainer>
        </TabPanel>}
        <Divider></Divider>
        <CGridContainer
            sx={{width: "100%", p: 2}} spacing={2}>
            {(orderDialog.data.orderStatus !== "canceled" && !viewOnly) && (
                <CGrid
                    item
                    xs={6}
                    md={tabValue === 1 && (orderDialog.data.orderStatus === "received" || orderDialog.data.orderStatus === "ready") ? 4 : (orderDialog.data.orderStatus === "received" || orderDialog.data.orderStatus === "ready" || (orderDialog.data.orderStatus === "unpaid" && module === 'store admin' && !viewOnly)) ? 6 :  tabValue === 1 ? 6 : 8}>
                    <CButton
                        size={headerButtonsSize}
                        variant={"contained"}
                        onClick={() => {
                            if (window.confirm('Are you sure you want to cancel this order?')) {
                                handleSubmit("canceled")
                            }
                        }}
                        color={'error'}
                        fullWidth={(isSmallScreen || isExtraSmallScreen)}>
                        {orderDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                    size={24}/> : 'Cancel Order'}
                    </CButton>
                </CGrid>)}
            <CGrid item xs={6} md={2}>
                <CButton size={headerButtonsSize} variant={"outlined"} onClick={handleClose} fullWidth>
                    Close
                </CButton></CGrid>
            {!viewOnly && <CGrid item xs={6} md={2}><CButton size={headerButtonsSize} variant={"outlined"}
                                                onClick={handlePrintLabel} fullWidth>
                Print
            </CButton></CGrid>}
            {(tabValue === 1 && !viewOnly) &&
                <CGrid item xs={6} md={2}><CButton size={headerButtonsSize} variant={"contained"}
                                                   onClick={()=>{handleSaveChanges()}} fullWidth>
                    {module === "lockbox" ? 'Save Notes' : 'Update'}
                </CButton></CGrid>}
            {(orderDialog.data.orderStatus === "received" && !viewOnly) &&
                <CGrid item xs={6} md={2}><CButton size={headerButtonsSize} variant={"contained"}
                                                   onClick={() => handleSubmit("ready")} fullWidth>
                    {orderDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                size={24}/> : 'Ready'}
                </CButton></CGrid>}
            {(orderDialog.data.orderStatus === "ready" && !viewOnly) &&
                <CGrid item xs={6} md={2}><CButton size={headerButtonsSize} variant={"contained"}
                                                   onClick={() => handleSubmit("picked_up")} fullWidth>
                    {orderDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                size={24}/> : 'Picked Up'}
                </CButton></CGrid>}
            {(orderDialog.data.orderStatus === "unpaid" && !viewOnly && module === 'store admin') &&
                <CGrid item xs={6} md={2}><CButton size={headerButtonsSize} variant={"contained"}
                                                   onClick={() => handlePaymentDialogOpen()} fullWidth>
                    Pay
                </CButton></CGrid>}
        </CGridContainer>
    </CDialog>)
}