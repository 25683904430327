import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {RolesApi} from "../../services/api/roles-api"
import {RoleModel, RoleSnapshot} from "../role/role"
import {logInfo} from "../../utils/logs"
import {
    RoleCreateParams,
    RoleDeleteParams,
    RoleFindAllParams,
    RoleFindOneParams,
    RoleUpdateParams
} from "../../services/api";

function roleStoreLog(functionName, message) {
    logInfo({
        fileName: "role-store.ts",
        className: "roleStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const RoleStoreModel = types
    .model("RoleStore")
    .props({
        role: types.optional(types.maybeNull(RoleModel), null),
        roles: types.optional(types.array(RoleModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveRole: (roleSnapshot: RoleSnapshot) => {
            try {
                self.role = roleSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    roleStoreLog("saveRole", e.message)
                }
                // recordError(e)
            }
        },
        saveRoles: (rolesSnapshot: RoleSnapshot[]) => {
            try {
                self.roles = cast([...rolesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    roleStoreLog("saveRole", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: RoleCreateParams, token: string) {
            self.environment.addAuth(token)
            const roleApi = new RolesApi(self.environment.api)
            const result = yield roleApi.create(params)


            if (result.kind === "ok") {
                self.saveRole(result.role)
            } else {
                roleStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: RoleFindAllParams, token: string) {
            self.environment.addAuth(token)
            const roleApi = new RolesApi(self.environment.api)
            const result = yield roleApi.findAll(params)


            if (result.kind === "ok") {
                self.saveRoles(result.role)
            } else {
                roleStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: RoleFindOneParams, token: string) {
            self.environment.addAuth(token)
            const roleApi = new RolesApi(self.environment.api)
            const result = yield roleApi.findOne(params)


            if (result.kind === "ok") {
                self.saveRole(result.role)
            } else {
                roleStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: RoleUpdateParams, token: string) {
            self.environment.addAuth(token)
            const roleApi = new RolesApi(self.environment.api)
            const result = yield roleApi.update(params)


            if (result.kind === "ok") {
                self.saveRole(result.role)
            } else {
                roleStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: RoleDeleteParams, token: string) {
            self.environment.addAuth(token)
            const roleApi = new RolesApi(self.environment.api)
            return yield roleApi.remove(params)
        }),

    }))

type RoleStoreType = Instance<typeof RoleStoreModel>

export interface RoleStore extends RoleStoreType {
}

type RoleStoreSnapshotType = SnapshotOut<typeof RoleStoreModel>

export interface RoleStoreSnapshot extends RoleStoreSnapshotType {
}

export const createRoleStoreDefaultModel = () => types.optional(RoleStoreModel, {})
