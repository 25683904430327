import * as React from "react";
import {hasValue} from "../../utils/empty-check";
import {CButton, CIconButton} from "../mui-extracted";
import {useStores} from "../../models";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {Table} from "../table";
import {Flag} from "@mui/icons-material";

let statelessPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessOrderInfo = {
    orderBy: 'customerCreatedAt',
    order: 'DESC'
}
let currentAuthToken = ''

interface GuestsTableProps {
    module: string,
    handleCustomerDetailsClickOpen: (user: any, type: string) => void,
    onRefreshingChange: (isRefreshing: boolean) => void
}

let previousData: any = null
let renderedOnce = false

let statelessShowFlagged = false

export const GuestsTable = forwardRef(({
                                              module,
                                              handleCustomerDetailsClickOpen,
                                              onRefreshingChange
                                          }: GuestsTableProps, ref) => {

    const {authStore, customerStore} = useStores()
    const {customers, count} = customerStore

    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'customerCreatedAt',
        order: 'DESC'
    })

    useImperativeHandle(ref, () => ({
        refreshContents(showFlagged) {
            statelessShowFlagged = showFlagged === true

            // Call your API here
            refresh(true)
        },
    }));

    const refresh = async (force?: boolean) => {
        setIsRefreshing(true)
        onRefreshingChange(true)

        const data = {
            customerLocationId: module === 'store admin' ? authStore.storeAdminAuth?.userLocationId ?
                authStore.storeAdminAuth?.userLocationId :
                undefined : undefined,
            customerFlag: statelessShowFlagged ? 'red' : undefined,
            groupByEmail: true,
            page: statelessPaginationInfo.page,
            perPage: statelessPaginationInfo.perPage,
            sortBy: statelessOrderInfo.orderBy,
            sortDirection: statelessOrderInfo.order,
        }
        const isDataTheSame = previousData !== null && (Object.keys(previousData).length === Object.keys(data).length) &&
            Object.keys(previousData).every(key => previousData[key] === data[key]);

        if (!isDataTheSame || force === true) {
            previousData = data
            await customerStore.findAll(data, currentAuthToken)
        }
        setIsRefreshing(false)
        onRefreshingChange(false)
    }

    const checkAuth = () => {
        if (module === 'admin') {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
        } else if (module === 'store admin') {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            }
        } else if (module === 'lockbox') {
            if (authStore.lockboxAuth !== null) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            }
        }

    }

    const handlePageChange = async (details, page) => {
        // console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            page
        }
        await refresh()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await refresh()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessOrderInfo.orderBy === property && statelessOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        await refresh()
    };

    useEffect(() => {
        ;(async () => {
            if (!renderedOnce) {
                checkAuth()
                renderedOnce = true
                previousData = null
                await refresh()
            }
        })()

    }, [])

    const rows = customers;

    let storeNameColumn: any[] = []

    if (module === 'admin') {
        storeNameColumn = [{
            field: 'locationName',
            valueGetter: (params) => {
                const {location} = params.row
                if (hasValue(location)) {
                    return location.locationName
                } else {
                    return ''
                }
            },
            headerName: 'Store Name',
            flex: 1,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        }]
    }

    const columns = [
        {
            field: 'customerId',
            headerName: 'ID',
            width: 60,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'customerFullName',
            valueGetter: (params) => {
                const {customerFname, customerLname} = params.row
                return `${customerFname ? customerFname : ''} ${customerLname ? customerLname : ''}`
            },
            headerName: 'Name',
            width: 150,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'customerEmail',
            // valueGetter: (params) => {
            //     const {customerFname, customerLname} = params.row
            //     return `${customerFname ? customerFname : ''} ${customerLname ? customerLname : ''}`
            // },
            headerName: 'Email',
            width: 240,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
            ...storeNameColumn,
        {
            field: 'col7', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
            , cellClassName: "pos-table-cell",
            renderCell: (params) => {
                const onCustomerDetailsClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleCustomerDetailsClickOpen(params.row, 'guest')
                }

                const isFlagged =  hasValue(params.row?.customerFlag)
                const flagColor = params.row?.customerFlag

                // flagColor = flagColor === 'red' ? 'error' : flagColor === 'yellow' ? 'warning' : null


                return <>
                    <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                             onClick={onCustomerDetailsClick}>Edit & Details</CButton>
                    {isFlagged &&<CIconButton>
                        <Flag style={{color: flagColor}}></Flag>
                    </CIconButton>}
                </>;
            }
        },

    ];

    return (
        <Table
            columns={columns}
            rows={rows}
            count={count}
            page={paginationInfo.page}
            rowsPerPage={paginationInfo.perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            orderBy={orderInfo.orderBy}
            order={orderInfo.order}
            onRequestSort={handleRequestSort}
            isLoading={isRefreshing}
            noRowTitleOnSmallSize={true}
        />
    )
})