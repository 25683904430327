import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const SkuImageModel = types.model("SkuImage").props({
    skuImageId: types.number,
    skuImageLink: types.maybeNull(types.string),
    skuId: types.maybeNull(types.number),
    skuImageCreatedAt: types.maybeNull(types.string),
    skuImageUpdatedAt: types.maybeNull(types.string),
})

type SkuImageType = Instance<typeof SkuImageModel>
export interface SkuImage extends SkuImageType {}
type SkuImageSnapshotType = SnapshotOut<typeof SkuImageModel>
export interface SkuImageSnapshot extends SkuImageSnapshotType {}

