import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CCircularProgress,
    CGrid,
    CGridContainer,
    CTypography,
    CIconButton,
    CContainer,
    CCard,
    POSTitle,
    DisclaimerDialog,
    DeliveryMethodDialog,
    Footer,
    CTextField,
    CInputAdornment,
    CCardContent,
    CButton,
    StripedDataGrid,
    CTabs,
    CTab,
    Divider,
    TabPanel,
    CMenuItem,
    CTable,
    CTableHead,
    CTableRow,
    CTableCell,
    CTableBody,
    CInterleavedTableRow,
    CDialog,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {
    ArrowBackIosNew, Visibility, VisibilityOff,
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {hasValue} from "../../../utils/empty-check";
import {isEmpty} from "validate.js";
import LinearProgress from "@mui/material/LinearProgress";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {OrderUpdateParams} from "../../../services/api";
import {defaultOrder} from "../../../models/order/order";
import {Autocomplete} from "@react-google-maps/api";
import TextField from "@mui/material/TextField";
import {isMapLoaded} from "../../../app";

interface OrderDialogData {
    isOpen: boolean,
    data: OrderUpdateParams,
    isLoading: boolean
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const StoreProfile = observer(
    function StoreProfile() {
        const navigate = useNavigate();

        const params = useParams();
        const [isGettingOrders, setIsGettingOrders] = React.useState(true)
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, locationInfoState, guestAuthState} = guestAuth

        const loadOrders = async () => {
            setIsGettingOrders(true)
            await orderStore.findAll({
                email: String(authStore.auth?.userEmail),
                // userId: Number(authStore.auth?.userId),
                page: 0,
                perPage: 100
            }, String(authStore.auth?.accessToken), true)
            setIsGettingOrders(false)

        };

        const [tabValue, setTabValue] = React.useState(0);

        const handleChange = (event: React.SyntheticEvent, newValue: number) => {
            setTabValue(newValue);
        };

        const handleClose = () => {
            setOrderDialog({isOpen: false, data: defaultOrder, isLoading: false});
        };


        const init = async () => {
            await loadOrders()
            setTimeout(()=>{
                setCurrentIsMapLoaded(isMapLoaded)
            }, 150)
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        useEffect(() => {
            ;(async () => {
                if (guestAuthState === 'loaded') {
                    await init()
                }
            })()
        }, [guestAuthToken, guestAuthState, params]);

        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Profile`;
            })()

        }, [locationInfo, locationInfoState])


        const { authStore, userStore, userDetailStore, orderStore, orderDetailStore, locationStore, cartStore } = useStores();
        const { orders } = orderStore

        const [showPassword, setShowPassword] = React.useState(false);
        const [username, setUsername] = React.useState(String(authStore.auth?.userUsername));
        const [password, setPassword] = React.useState("");
        const [confirmPassword, setConfirmPassword] = React.useState("");
        const [firstName, setFirstName] = React.useState(String(authStore.auth?.userDetail?.userDetailFirstName));
        const [lastName, setLastName] = React.useState(String(authStore.auth?.userDetail?.userDetailLastName));
        const [email, setEmail] = React.useState(String(authStore.auth?.userEmail));
        const [phone, setPhone] = React.useState(String(authStore.auth?.userPhone));
        const [address1, setAddress1] = React.useState(String(authStore.auth?.userDetail?.userDetailAddress1));
        const [address2, setAddress2] = React.useState(String(authStore.auth?.userDetail?.userDetailAddress2));
        const [city, setCity] = React.useState(String(authStore.auth?.userDetail?.userDetailCity));
        const [state, setState] = React.useState(String(authStore.auth?.userDetail?.userDetailState));
        const [zip, setZip] = React.useState(String(authStore.auth?.userDetail?.userDetailZip));
        const [validation, setValidation] = React.useState({
            username: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address1: '',
            city: '',
            state: '',
            zip: '',
        })
        const [orderDialog, setOrderDialog] = React.useState<OrderDialogData>({
            isOpen: false,
            data: defaultOrder,
            isLoading: false
        });
        const [tablePageSize, setTablePageSize] = React.useState(25)

        const [isLoading, setIsLoading] = React.useState(false);
        const [isLoggingOut, setIsLoggingOut] = React.useState(false);
        const [searchResult, setSearchResult] = useState<any>(null)
        const [currentIsMapLoaded, setCurrentIsMapLoaded] = useState(false)

        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        };

        const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
            if(hasValue(validation.username)) {
                setValidation({...validation, username: ''})
            }
        };

        const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setFirstName(event.target.value);
            if(hasValue(validation.firstName)) {
                setValidation({...validation, firstName: ''})
            }
        };

        const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setLastName(event.target.value);
            if(hasValue(validation.lastName)) {
                setValidation({...validation, lastName: ''})
            }
        };

        const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPhone(event.target.value);
            if(hasValue(validation.phone)) {
                setValidation({...validation, phone: ''})
            }
        };

        const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            if(hasValue(validation.email)) {
                setValidation({...validation, email: ''})
            }
        };

        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
            if(hasValue(validation.password)) {
                setValidation({...validation, password: ''})
            }
        };

        const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value);
            if(hasValue(validation.confirmPassword)) {
                setValidation({...validation, confirmPassword: ''})
            }
        };

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true

            if(isEmpty(firstName)) {
                validationObject.firstName = "This field is required."
                isValid = false
            }
            if(isEmpty(lastName)) {
                validationObject.lastName = "This field is required."
                isValid = false
            }
            if(isEmpty(phone)) {
                validationObject.phone = "This field is required."
                isValid = false
            }
            if(isEmpty(email)) {
                validationObject.email = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const handleSaveClick = async () => {
            if(!validate()) {
                return
            }
            if (password !== confirmPassword) {
                return
            }
            setIsLoading(true)
            await userStore.update({
                userId: authStore.auth?.userId,
                userPassword: password,
                userFirstName: firstName,
                userLastName: lastName,
                userPhone: phone,
                userNote: "",
                roleId: 4,
                userLocationId: locationInfo?.locationId,
                userStatus: "active",
            }, guestAuthToken)

            await userDetailStore.update({
                userDetailId: authStore.auth?.userDetail?.userDetailId,
                userId: authStore.auth?.userId,
                userDetailFirstName: firstName,
                userDetailLastName: lastName,
                userDetailPhone: phone,
                userDetailAddress1: address1,
                userDetailAddress2: address2,
                userDetailCity: city,
                userDetailState: state,
                userDetailZip: zip,
            }, guestAuthToken)
            await authStore.customerLogin({
                username,
                password,
                locationId: locationInfo.locationId
            }, currentDomain())

            setIsLoading(false)
        }

        const handleLogout = async () => {
            setIsLoggingOut(true)
            await authStore.login({
                username: 'g.u.e.s.t',
                password: 'guest2000@112113'
            })
            navigate('/')
            setIsLoggingOut(false)
        }


        const getCustomerName = (customer, user) => {
            if (hasValue(customer?.customerFname)) {
                return `${customer.customerFname} ${customer.customerLname}`
            } else if (hasValue(user?.userDetail)) {
                return `${user?.userDetail?.userDetailFirstName} ${user?.userDetail?.userDetailLastName}`
            } else {
                return ''
            }
        }

        const getCustomerPhone = (customer, user) => {
            if (hasValue(customer?.customerPhone)) {
                return `${customer.customerPhone}`
            } else if (hasValue(user?.userDetail)) {
                return `${user?.userDetail?.userDetailPhone}`
            } else {
                return ''
            }
        }

        const getCustomerEmail = (customer, user) => {
            if (hasValue(customer?.customerEmail)) {
                return `${customer.customerEmail}`
            } else if (hasValue(user?.userDetail)) {
                return `${user?.userDetail?.userDetailEmail} ${user?.userDetail?.userDetailEmail}`
            } else {
                return ''
            }
        }

        const getCustomerAddress = (customer, user) => {
            if (hasValue(customer?.customerAddress1)) {
                return `${customer?.customerAddress1} ${customer?.customerAddress2}, ${customer?.customerCity}, ${customer?.customerState}, ${customer?.customerZip}`
            } else if (hasValue(user?.userDetail)) {
                return `${user?.userDetail?.userDetailAddress1} ${user?.userDetail?.userDetailAddress2}, ${user?.userDetail?.userDetailCity}, ${user?.userDetail?.userDetailState}, ${user?.userDetail?.userDetailZip}`
            } else {
                return ''
            }
        }

        const handleClickOpen = async (order?: OrderUpdateParams) => {
            setOrderDialog({
                ...orderDialog,
                isOpen: true,
                data: order ? {...order} : defaultOrder
            });
            await orderDetailStore.findAll({
                orderId: Number(order?.orderId)
            }, String(authStore.auth?.accessToken))
        };

        const rows: GridRowsProp = orders;

        const columns: GridColDef[] = [
            {
                field: 'orderId',
                headerName: 'Id',
                width: 50,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'customerName',
                headerName: 'Customer Name',
                valueGetter: (params) => {
                    const {customer, user} = params.row
                    return getCustomerName(customer, user)
                },
                width: 200,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'items',
                headerName: 'Items',
                valueGetter: (params) => {
                    const {orderDetails} = params.row
                    return orderDetails.length
                },
                width: 60,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'orderTotal',
                headerName: 'Total (USD)',
                valueGetter: (params) => {
                    const {orderTotal} = params.row
                    return `$${orderTotal ? orderTotal.toFixed(2) : ''}`
                },
                width: 100,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'orderStatus',
                headerName: 'Status',
                width: 80,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'orderCreatedAt',
                headerName: 'Created At',
                valueGetter: (params) => {
                    const {orderCreatedAt} = params.row
                    return `${dayjs(orderCreatedAt).format('MMM D, h:mm A')}`
                },
                width: 110,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'orderUpdatedAt',
                headerName: 'Updated At',
                valueGetter: (params) => {
                    const {orderUpdatedAt} = params.row
                    return `${dayjs(orderUpdatedAt).format('MMM D, h:mm A')}`
                },
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 200, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };
                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Details</CButton>
                    </>;
                }
            },

        ];

        function skuCompleteName (sku) {
            if (sku.skuHasParent === 1) {
                return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
            } else {
                return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
            }
        }

        function createOrderDetailData(
            id: number,
            code: string,
            name: string,
            quantity: number,
            price: number,
        ) {
            return {id, code, name, quantity, price};
        }

        const orderDetailRows = orderDetailStore.orderDetails.map(
            orderDetail =>
                createOrderDetailData(
                    orderDetail.orderDetailId,
                    String(orderDetail.sku?.skuCode),
                    skuCompleteName(orderDetail.sku),
                    Number(orderDetail.skuQuantity),
                    Number(orderDetail.skuPrice),
                )
        )

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        function onAutocompleteLoad(autocomplete) {
            setSearchResult(autocomplete)
        }

        function onPlaceChanged() {
            //variable to store the result
            const place = searchResult?.getPlace();
            // //variable to store the name from place details result
            // const name = place.name;
            // //variable to store the status from place details result
            // const status = place.business_status;
            //variable to store the formatted address from place details result
            // const latLong = place?.geometry?.location;

            //console log all results
            // props.onPlaceSelected(formattedAddress, latLong);

            if (hasValue(place.address_components)) {
                place.address_components.forEach(component=>{
                    if(component.types.includes('route')) {
                        setAddress1(component.long_name)
                    }
                    if(component.types.includes('locality')) {
                        setCity(component.short_name)
                    }
                    if(component.types.includes('administrative_area_level_1')) {
                        setState(component.short_name)
                    }
                    if(component.types.includes('postal_code')) {
                        setZip(component.long_name)
                    }
                })
            }

        }


        return (
            <>

                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={()=>{
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>


                        <CGridContainer sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CCard sx={{
                                width: "100%",
                                // minHeight: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 1
                            }}>
                                <>
                                    <CBox sx={{display: "flex", flexDirection: "row", width: '100%', pt: '22px'}}>
                                        <CIconButton
                                            sx={{width: '64px', ml: '22px'}}
                                            onClick={() => navigate(-1)}
                                        >
                                            <ArrowBackIosNew></ArrowBackIosNew>
                                        </CIconButton>
                                        <POSTitle sx={{
                                            mb: 2,
                                            mt: 2,
                                            flex: 1,
                                            textAlign: "center",
                                            fontFamily: "Passion One",
                                        }}>
                                            Profile
                                        </POSTitle>
                                        <CBox
                                            sx={{width: '64px', mr: '22px'}}
                                        />
                                    </CBox>
                                    <CCardContent>
                                        <CGridContainer spacing={2} >

                                            <CGrid item xs={12}>
                                                <CTextField
                                                    disabled
                                                    error={hasValue(validation.username)}
                                                    helperText={hasValue(validation.username)?validation.username:null}
                                                    fullWidth label={"Username"} value={username} onChange={handleUsernameChange}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12} md={6}>
                                                <CTextField
                                                    disabled
                                                    error={hasValue(validation.email)}
                                                    helperText={hasValue(validation.email)?validation.email:null}

                                                    fullWidth label={"Email"} value={email} onChange={handleEmailChange}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12} md={6}>
                                                <CTextField
                                                    error={hasValue(validation.phone)}
                                                    helperText={hasValue(validation.phone)?validation.phone:null}
                                                    fullWidth label={"Phone"} value={phone} onChange={handlePhoneChange}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12} md={6}>
                                                <CTextField
                                                    fullWidth
                                                    // sx={{mb: 4}}
                                                    error={hasValue(validation.password)}
                                                    helperText={hasValue(validation.password)?validation.password:null}
                                                    label={"Password"}
                                                    onChange={handlePasswordChange}
                                                    InputProps={{
                                                        type: showPassword ? 'text' : 'password',
                                                        endAdornment: <CInputAdornment position="end">
                                                            <CIconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </CIconButton>
                                                        </CInputAdornment>
                                                    }}
                                                >

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12} md={6}>
                                                <CTextField
                                                    fullWidth
                                                    label={"Confirm Password"}
                                                    error={hasValue(validation.confirmPassword)}
                                                    helperText={hasValue(validation.confirmPassword)?validation.confirmPassword:null}
                                                    onChange={handleConfirmPasswordChange}
                                                    InputProps={{
                                                        type: showPassword ? 'text' : 'password',
                                                        endAdornment: <CInputAdornment position="end">
                                                            <CIconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                            </CIconButton>
                                                        </CInputAdornment>
                                                    }}
                                                >

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12} md={6}>
                                                <CTextField
                                                    error={hasValue(validation.firstName)}
                                                    helperText={hasValue(validation.firstName)?validation.firstName:null}
                                                    fullWidth label={"First Name"} value={firstName} onChange={handleFirstNameChange}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12} md={6}>
                                                <CTextField
                                                    error={hasValue(validation.lastName)}
                                                    helperText={hasValue(validation.lastName)?validation.lastName:null}

                                                    fullWidth label={"Last Name"} value={lastName} onChange={handleLastNameChange}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12}>
                                                <CBox sx={{width: '100%', my: 1}}>
                                                    {currentIsMapLoaded && <Autocomplete
                                                        onLoad={onAutocompleteLoad}
                                                        options={{types: ['address']}}
                                                        fields={['formatted_address', 'address_component']}
                                                        onPlaceChanged={onPlaceChanged}
                                                        className={'google-map-ac'}
                                                    >
                                                        <TextField
                                                            sx={{flex: 1}}
                                                            // size="small"
                                                            placeholder={'11111 Address, City, State 22222'}
                                                            error={
                                                                hasValue(validation.address1) ||
                                                                hasValue(validation.city) ||
                                                                hasValue(validation.state) ||
                                                                hasValue(validation.zip)
                                                            }
                                                            helperText={hasValue(validation.address1) ||
                                                            hasValue(validation.city) ||
                                                            hasValue(validation.state) ||
                                                            hasValue(validation.zip) ? `Please enter a valid address. Make sure the zip code is correct.` : null}
                                                            label="Full-Address"
                                                            onChange={() => {
                                                                // cartStore.saveCart({
                                                                //     ...cartStore.cart,
                                                                //     cartDeliveryFullAddress: event.target.value
                                                                // })
                                                                if (hasValue(validation.address1)) {
                                                                    setValidation({...validation, address1: ''})
                                                                }
                                                                if (hasValue(validation.city)) {
                                                                    setValidation({...validation, city: ''})
                                                                }
                                                                if (hasValue(validation.state)) {
                                                                    setValidation({...validation, state: ''})
                                                                }
                                                                if (hasValue(validation.zip)) {
                                                                    setValidation({...validation, zip: ''})
                                                                }
                                                            }}
                                                            defaultValue={address1 !== 'null' ? `${address1}, ${city}, ${state} ${zip}` : ''}
                                                            // value={cartStore.cart?.cartDeliveryFullAddress}
                                                        />
                                                    </Autocomplete>}
                                                </CBox>
                                            </CGrid>
                                            {/*
                                            <CGrid item xs={12}>
                                                <CTextField fullWidth label={"Address1"} value={address1} onChange={handleAddress1Change}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={12}>
                                                <CTextField fullWidth label={"Address2"} value={address2} onChange={handleAddress2Change}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={6} md={4}>
                                                <CTextField fullWidth label={"City"} value={city} onChange={handleCityChange}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={6} md={4}>
                                                <CTextField fullWidth disabled label={"State"} value={state}>

                                                </CTextField>
                                            </CGrid>
                                            <CGrid item xs={6} md={4}>
                                                <CTextField fullWidth label={"Zip"} value={zip} onChange={handleZipChange}>

                                                </CTextField>
                                            </CGrid>
                                            */}


                                        </CGridContainer>
                                        <CGridContainer spacing={2} sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>

                                            {!isLoggingOut && <CGrid item xs={12} md={4}>
                                                 <CButton fullWidth variant={"contained"} size={'large'} onClick={() => {
                                                    handleSaveClick()
                                                }}>
                                                    {isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                                    size={24}/> : "Save Changes"}
                                                </CButton>
                                            </CGrid>}
                                            <CGrid item xs={12} md={4} >
                                                <CButton fullWidth variant={"outlined"} size={'large'} onClick={()=> {
                                                    handleLogout()
                                                }}>
                                                    {isLoggingOut ? <CCircularProgress size={24} /> : "Logout"}
                                                </CButton>
                                            </CGrid>
                                        </CGridContainer>
                                    </CCardContent>
                                </>

                            </CCard>
                        </CGridContainer>
                        <CGridContainer sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center",

                        }}>
                            <CCard sx={{
                                width: "100%",
                                '& .pos-table-header': {
                                    fontSize: "20px",
                                    fontWeight: "400"
                                },
                                '& .pos-table-header-first': {
                                    ml: "24px"
                                },
                                '& .pos-table-cell': {
                                    color: "#363636"
                                },
                                '& .pos-table-cell-first': {
                                    color: "#000000",
                                    fontSize: "20px",
                                    fontWeight: "400",
                                    ml: "24px"
                                },
                            }}>
                                <StripedDataGrid
                                    sx={{height: '50vh'}}
                                    /// For now we're loading 100 items by default
                                    /// More than 100 items needs pro plan
                                    /// Also we never need to load like 1000 items at once, Worst case we can filter them
                                    getRowId={(row) => row.orderId}
                                    rows={rows}
                                    columns={columns}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                    }
                                    // page={paginationInfo.page}
                                    // pageSize={paginationInfo.perPage}
                                    // onPageChange={handlePageChange}
                                    pageSize={tablePageSize}
                                    onPageSizeChange={handlePageSizeChange}
                                    loading={isGettingOrders}
                                    components={{
                                        LoadingOverlay: LinearProgress,
                                    }}
                                />
                            </CCard>
                        </CGridContainer>
                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
                <CDialog open={orderDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px",
                        pb: 2
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} mx={4}>
                            Order #{orderDialog.data.orderId}
                        </CTypography>
                        <CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <CTab label="General" {...a11yProps(0)} />
                            <CTab label="Items" {...a11yProps(1)} />
                        </CTabs>
                    </CBox>
                    <Divider></Divider>
                    <TabPanel value={tabValue} index={0}>
                        <CGridContainer spacing={2} p={2}>
                            <CGrid item xs={3}>
                                <CTextField disabled fullWidth label={"Total (USD)"}
                                            type={"number"}
                                            value={orderDialog.data.orderTotal?.toFixed(2)}/>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField disabled fullWidth label={"Status"} select
                                            value={orderDialog.data.orderStatus}>
                                    <CMenuItem value={'received'}>Received</CMenuItem>
                                    <CMenuItem value={'ready'}>Ready</CMenuItem>
                                    <CMenuItem value={'picked_up'}>Picked Up</CMenuItem>
                                </CTextField>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField fullWidth label={"Kiosk"}
                                            disabled
                                    // @ts-ignore
                                            value={orderDialog.data.kiosk ? orderDialog.data?.kiosk?.locationName : ''}/>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField fullWidth label={"Lockbox"}
                                            disabled
                                    // @ts-ignore
                                            value={orderDialog.data.lockbox ? orderDialog.data?.lockbox.locationName : ''}/>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField fullWidth label={"Created At"}
                                            disabled
                                    // @ts-ignore
                                            value={orderDialog.data.orderCreatedAt}/>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField fullWidth label={"Customer Name"}
                                            disabled
                                    // @ts-ignore
                                            value={getCustomerName(orderDialog.data.customer, orderDialog.data.user)}/>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField fullWidth label={"Customer Phone"}
                                            disabled
                                    // @ts-ignore
                                            value={getCustomerPhone(orderDialog.data.customer, orderDialog.data.user)}/>
                            </CGrid>
                            <CGrid item xs={3}>
                                <CTextField fullWidth label={"Customer Email"}
                                            disabled
                                    // @ts-ignore
                                            value={getCustomerEmail(orderDialog.data.customer, orderDialog.data.user)}/>
                            </CGrid>
                            <CGrid item xs={6}>
                                <CTextField fullWidth label={"Customer Address"}
                                            disabled
                                    // @ts-ignore
                                            value={getCustomerAddress(orderDialog.data.customer, orderDialog.data.user)}/>
                            </CGrid>
                        </CGridContainer>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <CGridContainer spacing={0} p={0}>
                            <CGrid item xs={12} maxHeight={'70vh'} sx={{
                                overflowY: 'scroll',
                            }}>
                                {isEmpty(orderDetailRows) ? <CTypography m={4} textAlign={'center'} variant={'body1'}> No order details found for this order. </CTypography> :
                                    <CTable aria-label="simple table">
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableCell sx={{
                                                    fontSize: "20px",
                                                    paddingLeft: "32px",
                                                    width: "180px"
                                                }}>
                                                    Sku
                                                </CTableCell>
                                                <CTableCell sx={{
                                                    fontSize: "20px",
                                                    paddingRight: "32px"
                                                }} align="left">Amount</CTableCell>
                                                <CTableCell sx={{
                                                    fontSize: "20px",
                                                    paddingRight: "32px"
                                                }}>Price</CTableCell>
                                            </CTableRow>
                                        </CTableHead>

                                        <CTableBody>

                                            {orderDetailRows.map((row, index) => (
                                                <CInterleavedTableRow
                                                    key={row.id.toString() + index}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <CTableCell component="th" scope="row" sx={{
                                                        paddingLeft: "32px",
                                                        width: "180px"
                                                    }}>
                                                        <CTypography>
                                                            {row.name}
                                                        </CTypography>
                                                    </CTableCell>
                                                    <CTableCell scope="row" sx={{
                                                        width: "80px"
                                                    }}>
                                                        <CTypography>
                                                            {row.quantity}
                                                        </CTypography>
                                                    </CTableCell>
                                                    <CTableCell sx={{
                                                        paddingRight: "32px"
                                                    }}>
                                                        <CTypography>
                                                            {row.price?.toFixed(2)}
                                                        </CTypography>
                                                    </CTableCell>
                                                </CInterleavedTableRow>
                                            ))}
                                        </CTableBody>
                                    </CTable>
                                }
                            </CGrid>

                        </CGridContainer>
                    </TabPanel>
                </CDialog>
            </>
        );

        // return (
        //     <>
        //         <main style={{minHeight: '100vh'}}>
        //             <CBox height={90} sx={{
        //                 position: "absolute",
        //                 top: 0,
        //                 left: 0,
        //                 right: 0,
        //                 backgroundColor: "#212121",
        //                 zIndex: 0
        //             }}>
        //                 <CContainer>
        //                     <CGridContainer height={90}>
        //                         <CGrid item xs={2}/>
        //                         <CGrid item xs={8} sx={{
        //                             textAlign: "center",
        //                             color: "white",
        //                             alignItems: "center",
        //                             justifyContent: "center",
        //                             display: "flex"
        //                         }}>
        //
        //                         </CGrid>
        //
        //                         <CGrid item xs={2} sx={{
        //                             textAlign: "center",
        //                             color: "white",
        //                             alignItems: "center",
        //                             justifyContent: "center",
        //                             display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
        //                         }}>
        //                             <MomentTimer/>
        //
        //                         </CGrid>
        //                     </CGridContainer>
        //                 </CContainer>
        //             </CBox>
        //             <CContainer
        //                 style={{
        //                     position: "absolute",
        //                     top: 0,
        //                     right: 0,
        //                     left: 0,
        //                     bottom: 0,
        //                     display: 'flex',
        //                     flexDirection: "column",
        //                     minHeight: '100vh'
        //                 }}>
        //                 <CGridContainer sx={{flex: 1, display: "flex"}}>
        //                     <CGrid item xs={0} md={2} height={90} sx={{
        //                         display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center",
        //                     }}>
        //                         <CFormControl sx={{display: {xs: 'none', sm: 'none', md: 'flex', lg: 'block', xl: 'block' }, m: 1, minWidth: 80}}>
        //                             <CSelectWhiteBorder
        //                                 id="demo-simple-select-autowidth"
        //                                 value={"EN"}
        //                                 onChange={(event) => {
        //                                     const value = event.target.value;
        //                                 }}
        //                                 autoWidth
        //                                 sx={{
        //                                     fontColor: "white",
        //                                 }}
        //                                 size={"small"}
        //                             >
        //                                 <CMenuItem value={"EN"}>EN</CMenuItem>
        //                                 <CMenuItem value={"ES"}>ES</CMenuItem>
        //                             </CSelectWhiteBorder>
        //                         </CFormControl>
        //                     </CGrid>
        //                     <CGrid item xs={12} md={8} sx={{
        //                         display: 'flex',
        //                         alignItems: "center",
        //                         justifyContent: "center"
        //                     }}>
        //                         <CCard sx={{
        //                             width: "100%",
        //                             height: '90vh',
        //                             display: 'flex',
        //                             flexDirection: 'column',
        //                             alignItems: 'center',
        //                             justifyContent: 'center',
        //                         }}>
        //                             {isGettingSku ?
        //                                 <CCircularProgress
        //                                     size={32}/>
        //                                 :
        //                                 <>
        //                                     <CBox sx={{display: "flex", flexDirection: "row", width: '100%', pt: '22px'}}>
        //                                         <CIconButton
        //                                             sx={{width: '96px', ml: '22px'}}
        //                                             onClick={() => navigate(-1)}
        //                                         >
        //                                             <ArrowBackIosNew></ArrowBackIosNew>
        //                                         </CIconButton>
        //                                         <POSTitle sx={{
        //                                             mb: 2,
        //                                             mt: 2,
        //                                             flex: 1,
        //                                             textAlign: "center",
        //                                             fontFamily: "Passion One",
        //                                         }}>
        //                                             {skuDialog.data?.skuTitle}
        //                                         </POSTitle>
        //                                         <CIconButton
        //                                             sx={{width: '96px', mr: '22px'}}
        //                                             onClick={() => {
        //                                                 navigate(`/store/cart`)
        //                                             }}
        //                                         >
        //                                             <ShoppingCart></ShoppingCart>
        //                                             {cart && cart.cartSkus && cart.cartSkus.length > 0 &&
        //                                                 <CBox sx={{
        //                                                     position: 'absolute',
        //                                                     top: 8,
        //                                                     left: 8,
        //                                                     borderRadius: 20,
        //                                                     minWidth: 8,
        //                                                     padding: '2px',
        //                                                     backgroundColor: 'red',
        //                                                     color: 'white',
        //                                                     fontSize: 12
        //                                                 }}>
        //                                                     {cart && cart.cartSkus.length}
        //                                                 </CBox>}
        //                                         </CIconButton>
        //                                     </CBox>
        //                                     <CGridContainer spacing={2} p={2} height={'100%'}>
        //
        //                                         <CGrid item xs={12} md={5}>
        //                                             <CBox
        //                                                 sx={{
        //                                                     display: "flex",
        //                                                     flex: 1,
        //                                                     background: `url(${hasValue(skuDialog.data?.skuImages) ? skuDialog.data?.skuImages[0].skuImageLink : ''})`,
        //                                                     borderRadius: "34px",
        //                                                     margin: "4px",
        //                                                     width: "100%",
        //                                                     height: {xs: "40vh", md: '100%'},
        //                                                     backgroundSize: "contain",
        //                                                     backgroundPosition: "center",
        //                                                     backgroundPositionX: "center",
        //                                                     backgroundPositionY: "center",
        //                                                     backgroundRepeat: "no-repeat",
        //                                                     // borderWidth: 1,
        //                                                     // borderColor: 'lightgrey',
        //                                                     // borderStyle: 'solid'
        //                                                 }}
        //                                             />
        //                                         </CGrid>
        //                                         <CGrid item xs={12} md={7}>
        //                                             <CBox
        //                                                 sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        //                                                 {/*<CTypography fontSize={"24px"} mr={2}>*/}
        //                                                 {/*    {skuDialog.data?.skuTitle}*/}
        //                                                 {/*</CTypography>*/}
        //                                                 <CBox sx={{
        //                                                     flex: 1,
        //                                                     borderWidth: 1,
        //                                                     borderColor: 'lightgrey',
        //                                                     borderStyle: 'solid',
        //                                                     borderRadius: "34px",
        //                                                     margin: "4px",
        //                                                     display: 'flex',
        //                                                     flexDirection: 'column'
        //                                                 }}>
        //                                                     <CTypography variant={'h6'} sx={{ml: 2, mt: 2}}>
        //                                                         ${selectedSkuId === skuDialog.data?.skuId ? Number(skuDialog.data?.skuDefaultPrice).toFixed(2) : selectedSkuPrice.toFixed(2)}
        //                                                     </CTypography>
        //                                                     {skuDialog.data?.skuHasVarieties === 1 && <CTypography variant={'h4'} sx={{ml: 2, mt: 2}}>
        //                                                         Varieties
        //                                                     </CTypography>}
        //                                                     {skuDialog.isLoadingVarieties ?
        //
        //                                                         <CBox sx={{
        //                                                             flex: 1,
        //                                                             display: 'flex',
        //                                                             alignItems: 'center',
        //                                                             justifyContent: 'center'
        //                                                         }}>
        //                                                             <CCircularProgress size={32}/>
        //                                                         </CBox>
        //                                                         :
        //
        //                                                         <CBox sx={{flex: 1, display: 'flex', ml: 1, mt: 2}}>
        //
        //                                                             {skuDialog.data?.skuHasVarieties === 1 ? skuVarieties.map((variety, index) => {
        //                                                                 return <ChipSelect
        //                                                                     key={index + variety.skuId + String(variety.skuTitle)}
        //                                                                     selected={selectedSkuId === variety.skuId}
        //                                                                     title={variety.skuTitle}
        //                                                                     onClick={async () => {
        //                                                                         setSelectedSkuId(variety.skuId)
        //                                                                         setSelectedSku({...variety})
        //                                                                         setSelectedSkuPrice(Number(variety.skuDefaultPrice))
        //                                                                         setSkuDialog({
        //                                                                             ...skuDialog,
        //                                                                             isLoadingInventory: true
        //                                                                         })
        //                                                                         const skuCount = await cartStore.skuCount(variety.skuId, Number(cart?.cartLockboxId), guestAuthToken)
        //                                                                         const cartSkuIndex = cartStore.cart?.cartSkus.findIndex(cartSku => cartSku.cartSkuSku.skuId === variety.skuId)
        //                                                                         calculateSkuCount(skuCount, cartSkuIndex)
        //                                                                         setSkuDialog({
        //                                                                             ...skuDialog,
        //                                                                             isLoadingInventory: false
        //                                                                         })
        //                                                                     }}
        //                                                                 />
        //                                                             }) : <></>}
        //                                                         </CBox>}
        //                                                     <CBox sx={{
        //                                                         display: 'flex',
        //                                                         flexDirection: {xs: 'column', md: 'row'},
        //                                                         alignItems: {xs: 'flex-start', md: 'center'},
        //                                                         justifyContent: 'flex-end',
        //                                                         mb: 2
        //                                                     }}>
        //                                                         {(skuDialog.isLoadingInventory) &&
        //                                                             <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}>
        //                                                                 Loading Inventory Count ...
        //                                                             </CTypography>}
        //                                                         {(isOutOfStock && !skuDialog.isLoadingInventory && selectedSkuId !== -1) &&
        //                                                             <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}
        //                                                                          color={'red'}>
        //                                                                 Out of stock
        //                                                             </CTypography>}
        //                                                         {(!isOutOfStock && !skuDialog.isLoadingInventory && selectedSkuId !== -1) &&
        //                                                             <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}>
        //                                                                 Available: {selectedSkuCount}
        //                                                             </CTypography>}
        //                                                         <CBox sx={{
        //                                                             display: 'flex',
        //                                                             flexDirection: 'row',
        //                                                             alignItems: 'center',
        //                                                             ml: {xs: 1, md: 0}
        //                                                         }}>
        //                                                             <CIconButton
        //                                                                 disabled={
        //                                                                     skuDialog.isLoadingInventory ||
        //                                                                     selectedSkuId === -1 ||
        //                                                                     isOutOfStock
        //                                                                 }
        //                                                                 onClick={() => {
        //                                                                     if (selectedSkuQuantity - 1 >= 0) {
        //                                                                         setSelectedSkuQuantity(selectedSkuQuantity - 1)
        //                                                                     }
        //                                                                 }}>
        //                                                                 <RemoveCircle sx={{
        //                                                                     color: (skuDialog.isLoadingInventory ||
        //                                                                         selectedSkuId === -1 ||
        //                                                                         isOutOfStock) ? 'grey' : "#F44336"
        //                                                                 }}></RemoveCircle>
        //                                                             </CIconButton>
        //                                                             <CTypography>
        //                                                                 {selectedSkuQuantity}
        //                                                             </CTypography>
        //                                                             <CIconButton
        //                                                                 disabled={
        //                                                                     skuDialog.isLoadingInventory ||
        //                                                                     selectedSkuId === -1 ||
        //                                                                     isOutOfStock
        //                                                                 }
        //                                                                 onClick={() => {
        //                                                                     if (selectedSkuCount - 1 >= 0) {
        //                                                                         setSelectedSkuQuantity(selectedSkuQuantity + 1)
        //                                                                         setSelectedSkuCount(selectedSkuCount - 1)
        //                                                                     }
        //                                                                 }}>
        //                                                                 <AddCircle sx={{
        //                                                                     color: (skuDialog.isLoadingInventory ||
        //                                                                         selectedSkuId === -1 ||
        //                                                                         isOutOfStock) ? 'grey' : "#4CAF50"
        //                                                                 }}></AddCircle>
        //                                                             </CIconButton>
        //                                                             <CButton
        //                                                                 disabled={
        //                                                                     skuDialog.isLoadingInventory ||
        //                                                                     isOutOfStock ||
        //                                                                     selectedSkuId === -1 ||
        //                                                                     selectedSkuQuantity === 0
        //                                                                 } variant={"contained"} onClick={() => {
        //                                                                 addSku(selectedSku)
        //                                                             }}
        //                                                                 sx={{mr: 2, minWidth: "100px"}}>
        //                                                                 Add To Cart
        //                                                             </CButton>
        //                                                         </CBox>
        //
        //
        //                                                     </CBox>
        //
        //
        //                                                 </CBox>
        //                                             </CBox>
        //
        //
        //                                         </CGrid>
        //
        //                                     </CGridContainer>
        //                                 </>
        //
        //                             }
        //
        //                         </CCard>
        //                     </CGrid>
        //                     <CGrid item xs={0} md={2}/>
        //                 </CGridContainer>
        //             </CContainer>
        //
        //         </main>
        //     </>
        // )
    }
)
