import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const UserDetailModel = types.model("UserDetail").props({
    userDetailId: types.number,
    userId: types.number,
    userDetailFirstName: types.maybeNull(types.string),
    userDetailLastName: types.maybeNull(types.string),
    userDetailEmail: types.maybeNull(types.string),
    userDetailPhone: types.maybeNull(types.string),
    userDetailAddress1: types.maybeNull(types.string),
    userDetailAddress2: types.maybeNull(types.string),
    userDetailCity: types.maybeNull(types.string),
    userDetailState: types.maybeNull(types.string),
    userDetailZip: types.maybeNull(types.string),
    userDetailCreatedAt: types.maybeNull(types.string),
    userDetailUpdatedAt: types.maybeNull(types.string),
})

type UserDetailType = Instance<typeof UserDetailModel>
export interface UserDetail extends UserDetailType {}
type UserDetailSnapshotType = SnapshotOut<typeof UserDetailModel>
export interface UserDetailSnapshot extends UserDetailSnapshotType {}

export const defaultUserDetail = {
    userDetailId: undefined,
    userId: undefined,
    userDetailFirstName: '',
    userDetailLastName: '',
    userDetailEmail: '',
    userDetailPhone: '',
    userDetailAddress1: '',
    userDetailAddress2: '',
    userDetailCity: '',
    userDetailState: '',
    userDetailZip: '',
}