import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CContainer,
    CGrid,
    CGridContainer,
    CTypography,
    POSTitle,
    MomentCountdown,
    Divider
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {CheckCircleOutline} from "@mui/icons-material";
import {navigationParams} from "../../../utils/navigation";
import {useStores} from "../../../models";
import {useEffect} from "react";
import {currentDomain} from "../../../utils/domain";
import {useAppInit} from "../../../hooks/use-app-init";
import {CheckoutBreadcrumb} from "../../../components/checkout-breadcrumb";
import {getConfig} from "../../../utils/config";
import * as qrcode from 'qrcode';
import {getSkuPrice} from "../../../utils/sku";

export const StoreReceipt = observer(
    function KioskReceipt() {
        const navigate = useNavigate();

        const params = useParams();

        const {previousCartStore, configStore, locationStore} = useStores()
        const {configs} = configStore

        const guestAuth = useAppInit()
        const {locationInfo, locationInfoState} = guestAuth

        let cartTotal = 0.00
        let locationFee = 0.00
        let locationDeliveryFee = 0.00
        let taxUSD = 0.00

        const [qr, setQr] = React.useState<any>(null)

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Receipt`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Receipt | ${locationInfo.locationName}`;
                    if (previousCartStore.previousCart) {
                        const qrCodeUrl = `${locationInfo.locationSlug}/order/${previousCartStore.previousCart.cartOrderHash}`;
                        const qrCodeImage = await qrcode.toDataURL(qrCodeUrl);
                        setQr(qrCodeImage)
                    }
                }


            })()

        }, [locationInfo, locationInfoState])


        if (previousCartStore.previousCart) {
            const currentCartSkus = [...previousCartStore.previousCart.cartSkus]
            if (currentCartSkus.length > 0) {
                currentCartSkus.forEach(cartSku => {
                    cartTotal += (cartSku.cartSkuAmount - cartSku.cartSkuFreeItemsAmount) * Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)
                })
            }

            let cartTaxMultiplier = 0
            const locationTaxPercent = locationStore.currentLocation?.locationSetting?.locationSettingTaxPercent
            if (locationTaxPercent && locationTaxPercent > 0) {
                cartTaxMultiplier = locationTaxPercent/100
            }
            if (cartTaxMultiplier && cartTaxMultiplier > 0) {
                taxUSD = (cartTaxMultiplier * cartTotal)
                cartTotal += taxUSD
            }

            if (locationStore.currentLocation?.locationSetting?.locationSettingFee) {
                locationFee = locationStore.currentLocation?.locationSetting?.locationSettingFee
            }
            if (locationFee) {
                cartTotal += locationFee
            }

            // if (previousCartStore.cart?.cartDeliveryMethod === 'local-delivery') {
                if (locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee) {
                    locationDeliveryFee = locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee
                }
                if (locationDeliveryFee) {
                    cartTotal += locationDeliveryFee
                }
            // }


        }

        let cartScreenStrings = getConfig(configs, 'cart-screen-strings')

        if (cartScreenStrings) {
            cartScreenStrings = JSON.parse(cartScreenStrings)
        }


        return (
            <>

                <main style={{minHeight: '100vh'}}>

                    {/*<CBox sx={{*/}
                    {/*    position: 'absolute', top: 0, right: 0, left: 0, bottom: 0*/}
                    {/*}}>*/}
                        <CContainer sx={{display: 'flex', flexDirection: 'column',}}>
                            <CGridContainer flex={1} spacing={2}>
                                <CGrid item xs={12} mt={2}>
                                    <CheckoutBreadcrumb allowedPages={['receipt']} currentPage={'receipt'}/>
                                </CGrid>

                                <CGrid item xs={12} md={12} mb={2} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <CCard sx={{
                                        width: "100%",
                                        // height: '90vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <CBox sx={{display: "flex", flexDirection: "row", width: '100%', alignItems: "center"}}>
                                            <CBox sx={{flex: 1, pl: 4}}>
                                                <CheckCircleOutline fontSize={"large"} sx={{color: "#4CAF50"}}>

                                                </CheckCircleOutline>
                                            </CBox>

                                            <POSTitle sx={{
                                                mb: 2,
                                                mt: 2,
                                                textAlign: "center",
                                                fontFamily: "Passion One"
                                            }}>
                                                Receipt
                                            </POSTitle>
                                            <CBox sx={{flex: 1}}></CBox>
                                        </CBox>
                                        <Divider></Divider>
                                        <CBox sx={{flex: 1, width: "100%", overflowY: "scroll"}}>

                                            <CGridContainer p={2}>
                                                <CGrid item xs={3} md={2}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        Order No
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            #{previousCartStore.previousCart?.cartOrderNo}
                                                        </CTypography>
                                                    </CBox>
                                                </CGrid>
                                            </CGridContainer>
                                            <Divider></Divider>
                                            <CGridContainer p={2}>
                                                <CGrid item xs={3} md={2}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        Name
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={9} md={10} display={"flex"}>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {`${previousCartStore.previousCart?.cartInfoFirstName} ${previousCartStore.previousCart?.cartInfoLastName}`}
                                                        </CTypography>
                                                    </CBox>
                                                </CGrid>
                                            </CGridContainer>
                                            <Divider></Divider>
                                            <CGridContainer p={2}>
                                                <CGrid item xs={3} md={2}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        Items
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                    {previousCartStore.previousCart && previousCartStore.previousCart.cartSkus.map(cartSku=><CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {cartSku.cartSkuSku.skuCode} x
                                                        </CTypography>
                                                        <CTypography>
                                                            {cartSku.cartSkuAmount}
                                                        </CTypography>
                                                    </CBox>)}
                                                </CGrid>
                                            </CGridContainer>
                                            <Divider></Divider>
                                            <CGridContainer p={2}>
                                                <CGrid item xs={3} md={2}>
                                                    <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                        {previousCartStore.previousCart?.cartDeliveryMethod === "lockbox" ? "Lockbox" : "Deliver To"}
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        minHeight: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {previousCartStore.previousCart?.cartDeliveryMethod === "lockbox" ?
                                                                previousCartStore.previousCart?.cartLockboxText
                                                                :
                                                                `${previousCartStore.previousCart?.cartDeliveryFullAddress}`
                                                            }
                                                        </CTypography>
                                                    </CBox>
                                                </CGrid>
                                            </CGridContainer>
                                            <CGridContainer p={2}>
                                                <CGrid item xs={3} md={2}>
                                                    <CTypography sx={{ml: 1,fontWeight: "600",}}>
                                                        Customer info
                                                    </CTypography>
                                                </CGrid>
                                                <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {`${previousCartStore.previousCart?.cartInfoFirstName} ${previousCartStore.previousCart?.cartInfoLastName}`}
                                                        </CTypography>
                                                    </CBox>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {`${previousCartStore.previousCart?.cartInfoPhone}`}
                                                        </CTypography>
                                                    </CBox>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {`${previousCartStore.previousCart?.cartInfoEmail}`}
                                                        </CTypography>
                                                    </CBox>
                                                    <CBox sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                        <CTypography>
                                                            {`${previousCartStore.previousCart?.cartInfoAddress1} ${previousCartStore.previousCart?.cartInfoAddress2}, ${previousCartStore.previousCart?.cartInfoCity} ${previousCartStore.previousCart?.cartInfoState} ${previousCartStore.previousCart?.cartInfoZip}`}
                                                        </CTypography>
                                                    </CBox>
                                                </CGrid>
                                            </CGridContainer>

                                        </CBox>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>

                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Tax & Fee
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {/* @ts-ignore*/}
                                                        {cartScreenStrings?.deliveryFee ? cartScreenStrings?.deliveryFee : 'Delivery Fee'}
                                                    </CTypography>
                                                    <CTypography sx={{ml: 2}}>
                                                        ${locationDeliveryFee?.toFixed(2)}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {/* @ts-ignore*/}
                                                        {cartScreenStrings?.fee ? cartScreenStrings?.fee : 'Fee'}
                                                    </CTypography>
                                                    <CTypography sx={{ml: 2}}>
                                                        ${locationFee?.toFixed(2)}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {/* @ts-ignore*/}
                                                        {cartScreenStrings?.tax ? cartScreenStrings?.tax : 'Tax'}
                                                    </CTypography>
                                                    <CTypography sx={{ml: 2}}>
                                                        ${taxUSD?.toFixed(2)}
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CTypography sx={{fontWeight: "bold", color: "#4CAF50", ml: 1,}}>
                                                Thank you for your purchase! Your order #{previousCartStore.previousCart?.cartOrderNo} has been successfully received. Please check your email for detailed information regarding your order.
                                            </CTypography>
                                        </CGridContainer>
                                        {qr && <CGridContainer p={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <img src={`${qr}`} alt="QR Code" />
                                        </CGridContainer>}
                                        <CGridContainer spacing={2} p={2}>
                                            <CGrid item xs={12} md={12}>
                                                <CTypography sx={{fontWeight: "600", fontSize: "24px"}}>
                                                    {`Total Amount Paid:   $${cartTotal.toFixed(2)}`}
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <CButton sx={{px: 8}} variant={"contained"} onClick={()=>{
                                                    if (params.mode === 'store') {
                                                       navigate(`/`)
                                                       previousCartStore.savePreviousCart({...previousCartStore.previousCart, cartSkus: [], cartInfoCardNo: '', cartInfoCardMonth: '', cartInfoCardYear: '', cartInfoCardPayer: ''})
                                                    } else {
                                                        navigate(`/${navigationParams(params.mode)}disclaimer`)
                                                    }
                                                }}
                                                         endIcon={params.mode === 'kiosk' ? <MomentCountdown
                                                             count={30}
                                                             onCountdownFinish={()=>{
                                                                 if (params.mode === 'store') {
                                                                     navigate(`/`)
                                                                 } else {
                                                                     navigate(`/${navigationParams(params.mode)}disclaimer`)
                                                                 }
                                                             }}
                                                         /> : <></>}
                                                >
                                                    New Order
                                                </CButton>
                                            </CGrid>
                                        </CGridContainer>

                                    </CCard>


                                </CGrid>
                            </CGridContainer>
                        </CContainer>
                    {/*</CBox>*/}
                </main>
            </>
        );
    }
)
