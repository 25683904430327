import {Table} from "../table";
import * as React from "react";
import {CBox, CButton, CIconButton, CTypography} from "../mui-extracted";
import dayjs, {Dayjs} from "dayjs";
import {getCustomerName, getCustomerZip} from "../../utils/customer";
import {hasValue} from "../../utils/empty-check";
import {useStores} from "../../models";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {addCurrentTimezone} from "../../utils/date-time";
import {Flag, InfoRounded, Receipt, SettingsOutlined} from "@mui/icons-material";
import {useMediaQuery} from "@mui/material";

let statelessPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessOrderInfo = {
    orderBy: 'paymentCreatedAt',
    order: 'DESC'
}
let currentAuthToken = ''

interface PaymentsTableProps {
    module: string,
    handleClickOpen: (mode, row) => void,
    handleOrderClickOpen: (row) => void
    orderNoFilter?: string
    cardNoFilter?: string
    transIdFilter?: string
    fromDate?: Dayjs | null,
    toDate?: Dayjs | null,
    autoRefresh: boolean,
    onRefreshingChange: (isRefreshing: boolean) => void
}

let statelessFromDate: Dayjs | null | undefined = null
let statelessToDate: Dayjs | null | undefined = null
let statelessOrderNoFilter: string | undefined = ''
let statelessCardNoFilter: string | undefined = ''
let statelessTransIdFilter: string | undefined = ''

let previousData: any = null
let renderedOnce = false

export const PaymentsTable = forwardRef(function PaymentsTable({
                                                                   handleClickOpen,
                                                                   handleOrderClickOpen,
                                                                   orderNoFilter,
                                                                   transIdFilter,
                                                                   cardNoFilter,
                                                                   fromDate,
                                                                   toDate,
                                                                   module,
                                                                   autoRefresh,
                                                                   onRefreshingChange
                                                               }: PaymentsTableProps, ref) {

    const {authStore, paymentStore} = useStores()
    const {payments, count} = paymentStore

    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'orderCreatedAt',
        order: 'DESC'
    })

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));


    useImperativeHandle(ref, () => ({
        refreshContents() {
            // Call your API here
            refresh(true)
        },
    }));

    const checkAuth = () => {
        if (module === 'admin') {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
        } else if (module === 'store admin') {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            }
        } else if (module === 'lockbox') {
            if (authStore.lockboxAuth !== null) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            }
        }

    }

    useEffect(() => {
        ;(async () => {
            if (!renderedOnce) {
                checkAuth()
                renderedOnce = true
                previousData = null
                await refresh()
            }
        })()

    }, [])

    useEffect(() => {
        ;(async () => {
            statelessOrderNoFilter = orderNoFilter
            if (statelessOrderNoFilter === null || statelessOrderNoFilter === undefined || statelessOrderNoFilter.length > 11) {
                // console.log('paymentsTable.orderNoFilter', orderNoFilter)
                if (autoRefresh) {
                    await refresh()
                }
            }
        })()
    }, [orderNoFilter])

    useEffect(() => {
        ;(async () => {
            statelessTransIdFilter = transIdFilter
            // console.log('paymentsTable.orderNoFilter', orderNoFilter)
            if (autoRefresh) {
                await refresh()
            }
        })()
    }, [transIdFilter])

    useEffect(() => {
        ;(async () => {
            statelessCardNoFilter = cardNoFilter
            if (autoRefresh) {
                // console.log('paymentsTable.cardNoFilter', cardNoFilter)
                await refresh()
            }
        })()

    }, [cardNoFilter])

    useEffect(() => {
        ;(async () => {
            // console.log('paymentsTable.fromDate', fromDate)
            statelessFromDate = fromDate
            if (autoRefresh) {
                await refresh()
            }
        })()

    }, [fromDate])

    useEffect(() => {
        ;(async () => {
            // console.log('paymentsTable.toDate', toDate)
            statelessToDate = toDate
            if (autoRefresh) {
                await refresh()
            }
        })()

    }, [toDate])

    const handlePageChange = async (details, page) => {
        // console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            page
        }
        await refresh()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await refresh()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessOrderInfo.orderBy === property && statelessOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        await refresh()
    };

    const refresh = async (force?: boolean) => {
        setIsRefreshing(true)
        onRefreshingChange(true)
        const data = {
            orderNo: '',
            paymentTransactionId: '',
            fromDate: '',
            toDate: '',
            paymentCardNumber: '',
            page: statelessPaginationInfo.page,
            perPage: statelessPaginationInfo.perPage,
            sortBy: statelessOrderInfo.orderBy,
            sortDirection: statelessOrderInfo.order,
        }
        if (statelessOrderNoFilter && hasValue(statelessOrderNoFilter) && statelessOrderNoFilter.length > 0) {
            data['orderNo'] = statelessOrderNoFilter
        } else {
            // @ts-ignore
            delete data.orderNo
        }

        if (statelessTransIdFilter && hasValue(statelessTransIdFilter) && statelessTransIdFilter.length > 0) {
            data['paymentTransactionId'] = statelessTransIdFilter
        } else {
            // @ts-ignore
            delete data.paymentTransactionId
        }
        if (statelessFromDate) {
            data['fromDate'] = addCurrentTimezone(statelessFromDate)
        } else {
            // @ts-ignore
            delete data.fromDate
        }

        if (statelessToDate) {
            data['toDate'] = addCurrentTimezone(statelessToDate)
        } else {
            // @ts-ignore
            delete data.toDate
        }
        if (statelessCardNoFilter && hasValue(statelessCardNoFilter) && statelessCardNoFilter.length > 0) {
            data['paymentCardNumber'] = statelessCardNoFilter
        } else {
            // @ts-ignore
            delete data.paymentCardNumber
        }
        const isDataTheSame = previousData !== null && (Object.keys(previousData).length === Object.keys(data).length) &&
            Object.keys(previousData).every(key => previousData[key] === data[key]);

        if (!isDataTheSame || force === true) {
            previousData = data
            await paymentStore.findAll(data, currentAuthToken, true)
        }
        setIsRefreshing(false)
        onRefreshingChange(false)
    }

    const rows = payments;

    const columns = [
        {
            field: 'col5', headerName: 'Action', width: 250, headerClassName: "pos-table-header"
            , cellClassName: "pos-table-cell",
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickOpen('edit', params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };
                const onOrderClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleOrderClickOpen(params.row.order)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                console.log(params.row?.order?.customer?.customerFlag)

                const isFlagged =  hasValue(params.row?.order?.customer?.customerFlag) || hasValue(params.row?.order?.user?.userFlag)
                let flagColor

                if (hasValue(params.row?.order?.customer?.customerFlag)) {
                    flagColor = params.row?.order?.customer?.customerFlag
                } else if (hasValue(params.row?.order?.user?.userFlag)) {
                    flagColor = params.row?.order?.user?.userFlag
                }

                // flagColor = flagColor === 'red' ? 'error' : flagColor === 'yellow' ? 'warning' : null

                return <>
                    {isFlagged &&<CIconButton>
                        <Flag style={{color: flagColor}}></Flag>
                    </CIconButton>}
                    <CIconButton onClick={onClick}>
                        <SettingsOutlined color={'primary'}/>
                    </CIconButton>
                    <CIconButton onClick={onOrderClick}>
                        <Receipt color={'primary'}></Receipt>
                    </CIconButton>

                </>
                // return <>
                //     <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                //                   onClick={onClick}>Edit & Details</CButton>
                //     <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                //              onClick={onOrderClick}>Order</CButton>
                // </>;
            }
        },

        // {
        //     field: 'paymentId',
        //     headerName: 'Id',
        //     width: 40,
        //     headerClassName: "pos-table-header pos-table-header-first",
        //     cellClassName: "pos-table-cell pos-table-cell-first"
        // },
        // {
        //     field: 'orderId',
        //     headerName: 'Order Id',
        //     width: 80,
        //     headerClassName: "pos-table-header pos-table-header-first",
        //     cellClassName: "pos-table-cell pos-table-cell-first"
        // },
        {
            field: 'transactionId',
            headerName: 'Trans Id',
            valueGetter: (params) => {
                const {paymentMetaData} = params.row
                const jsonMetaData = JSON.parse(paymentMetaData)
                const {transactionResponse} = jsonMetaData
                if (transactionResponse) {
                    return `${transactionResponse.transId}`
                }
                return ``
            },
            width: 130,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'orderNo',
            headerName: 'Order No',
            valueGetter: (params) => {
                const {order} = params.row
                return order?.orderNo
            },
            width: 150,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'paymentAmount',
            headerName: 'Amount',
            valueGetter: (params) => {
                const {paymentAmount} = params.row
                return `$${paymentAmount?.toFixed(2)}`
            },
            width: 120,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'paymentCreatedAt',
            headerName: 'Timestamp',
            valueGetter: (params) => {
                const {paymentCreatedAt} = params.row
                return `${dayjs(paymentCreatedAt).format('MMM D, h:mm A')}`
            },
            width: 110,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            valueGetter: (params) => {
                const {order} = params.row
                if (order) {
                    const {customer, user} = order
                    return getCustomerName(customer, user)
                } else {
                    return ''
                }
            },
            width: 120,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'customerZip',
            headerName: 'Zip',
            valueGetter: (params) => {
                const {order} = params.row
                if (order) {
                    const {customer, user} = order
                    return getCustomerZip(customer, user)
                } else {
                    return ''
                }
            },
            width: 70,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'paymentCardNumber',
            headerName: 'Card No',
            width: 70,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderStatus',
            headerName: 'Order Status',
            valueGetter: (params) => {
                const {order} = params.row
                if (order) {
                    const {orderStatus} = order
                    return orderStatus
                } else {
                    return ''
                }
            },
            width: 110,

            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'paymentStatus',
            headerName: 'Status',
            flex: 1,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },

    ];

    const mobileColumns = [
        {
            field: 'col5',
            headerName: 'Actions',
            width: 250,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell",
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickOpen('edit', params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };
                const onOrderClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleOrderClickOpen(params.row.order)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                const paymentStatus = params.row.paymentStatus;
                const transactionId = params.row.paymentTransactionId;
                const customerName = getCustomerName(params.row.order.customer, params.row.order.user);
                const paymentCreatedAt = dayjs(params.row.paymentCreatedAt).format('MMM D, h:mm A')

                let bgColor

                if (paymentStatus === 'successful') {
                    bgColor = 'green'
                } else {
                    bgColor = 'red'
                }

                const isFlagged =  hasValue(params.row?.order?.customer?.customerFlag) || hasValue(params.row?.order?.user?.userFlag)
                let flagColor

                if (hasValue(params.row?.order?.customer?.customerFlag)) {
                    flagColor = params.row?.order?.customer?.customerFlag
                } else if (hasValue(params.row?.order?.user?.userFlag)) {
                    flagColor = params.row?.order?.user?.userFlag
                }

                // flagColor = flagColor === 'red' ? 'error' : flagColor === 'yellow' ? 'warning' : null



                return <CBox sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <CBox sx={{width: 8, borderRadius: 4, backgroundColor: bgColor}}></CBox>
                    <CBox sx={{flex: 1, display: 'flex', flexDirection: 'column', px: 2}}>
                        <CTypography variant={'h6'} sx={{mb: 1}}>{transactionId} ({paymentStatus})</CTypography>
                        <CTypography variant={'subtitle2'} sx={{mb: 1}}>{customerName}</CTypography>
                        <CTypography variant={'body2'} sx={{color: 'grey'}}>Created at {paymentCreatedAt}</CTypography>
                    </CBox>
                    <CBox sx={{display: 'flex', flexDirection: 'column'}}>
                        {isFlagged &&<CIconButton>
                            <Flag style={{color: flagColor}}></Flag>
                        </CIconButton>}

                            <CIconButton onClick={onClick}>
                            <SettingsOutlined color={'primary'}/>
                        </CIconButton>
                        <CIconButton onClick={onOrderClick}>
                            <Receipt color={'primary'}></Receipt>
                        </CIconButton>
                    </CBox>

                </CBox>;
            }
        },


    ];

    if (!rows || rows.length === 0) {
        return <CBox sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CTypography> No payments found with current filters.</CTypography>
        </CBox>
    }

    return (
        <Table
            columns={(isSmallScreen || isExtraSmallScreen) ? mobileColumns : columns}
            rows={rows}
            count={count}
            page={paginationInfo.page}
            rowsPerPage={paginationInfo.perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            orderBy={orderInfo.orderBy}
            order={orderInfo.order}
            onRequestSort={handleRequestSort}
            isLoading={isRefreshing}
            noRowTitleOnSmallSize={true}
        />
    )
})