import {I18n} from "i18n-js"
import en from "./en_us.json"
import es from "./es_mx.json"
import fr from "./fr_ca.json"

export const i18n = new I18n({ en, es, fr }, {
  enableFallback: true,
  defaultLocale: "en"
})

export const switchLocale = (code: string) => {
  i18n.locale = code
}
