import {CBox, CCard, CGrid, CTypography} from ".."
import * as React from "react";
import {CIconButton} from "../mui-extracted";
import {InfoRounded} from "@mui/icons-material";
import {ButtonBase, useMediaQuery} from "@mui/material";
import {useEffect, useRef} from "react";

export const ProductCard = (props) => {

    const {imageUrl, title, vendorName, onClick, onInfoClick, md, xs, showInfoButton, onRef} = props

    const gridItemRef = useRef(null);

    useEffect(() => {
        if (gridItemRef.current && onRef) {
            onRef(gridItemRef.current);
            // Do something with the grid item width
        }
    }, []);

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    return (
        <CGrid
            ref={gridItemRef}
            item
            xs={xs || 6}
            md={md || 3}
            key={props.key}
            sx={{
                height: "256px",
                paddingRight: 2,
                paddingTop: 2
            }}
        >
            <CCard sx={{    boxShadow: "rgba(149, 157, 165, 0.2) 0px 4px 12px", position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "column"}}>
                <ButtonBase
                    onClick={onClick}
                    sx={{height: "100%", width: "100%", flex: 1, display: "flex", flexDirection: "column"}}>

                    <CBox
                        style={{
                            display: "flex",
                            flex: 1,
                            background: `url(${imageUrl})`,
                            borderRadius:isSmallScreen || isExtraSmallScreen
                                ? '18px'
                                : '37px',
                            margin: "4px",
                            width: "100%",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center"
                        }}
                    />
                    <CBox
                        sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <CTypography sx={{fontSize: "16px", mb: 1, mx: 2}}>
                            {vendorName} - {title}
                        </CTypography>
                        {/*<CTypography sx={{fontSize: "16px", mb: 1}}>*/}
                        {/*    {price}*/}
                        {/*</CTypography>*/}
                    </CBox>

                </ButtonBase>
                {showInfoButton !== false &&
                    <CIconButton onClick={onInfoClick} color={"primary"}
                                 sx={{position: "absolute", bottom: 4, right: 4}}>
                        <InfoRounded></InfoRounded>
                    </CIconButton>}
            </CCard>
        </CGrid>)
}
