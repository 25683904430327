import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {GoogleMap} from "@react-google-maps/api";
import {LocationOn, Refresh} from "@mui/icons-material";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {defaultLocation, Location} from "../../../models/location/location";
import {LocationCreateParams, LocationSettingUpdateParams, LocationUpdateParams} from "../../../services/api";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {isEmpty} from "validate.js";
import { defaultLocationSetting } from "../../../models/location-setting/location-setting";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../../firebase";
import {useNavigate} from "react-router-dom";
import {isMapLoaded} from "../../../app";
import {User} from "../../../models/user/user";

const containerStyle = {
    width: '100%',
    height: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

interface LocationDialogData {
    isOpen: boolean,
    data: LocationCreateParams | LocationUpdateParams,
    mode: string,
    isLoading: boolean
}

interface LocationSettingDialogData {
    isOpen: boolean,
    data: LocationSettingUpdateParams,
    isLoading: boolean
}

let currentAuthToken = ''
let previousStoreAdminId = 0

export const StorePanelLockboxes = observer(
    function StorePanelLockboxes() {

        const navigate = useNavigate();

        const {authStore, locationStore, locationSettingStore, userStore} = useStores()
        const {locations} = locationStore
        // const {users} = userStore

        const [locationDialog, setLocationDialog] = React.useState<LocationDialogData>({
            isOpen: false,
            data: defaultLocation,
            mode: 'add',
            isLoading: false
        });
        const [locationSettingDialog, setLocationSettingDialog] = React.useState<LocationSettingDialogData>({
            isOpen: false,
            data: defaultLocationSetting,
            isLoading: false
        });

        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [isUploadingLogo, setIsUploadingLogo] = React.useState(false);

        const [map, setMap] = React.useState(null)
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            name: '',
            type: '',
            admin: '',
            email: '',
            phoneNumber: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            storeId: '',
        })
        const [stores, setStores] = React.useState<Location[]>([])
        const [lockboxAdmins, setLockboxAdmins] = React.useState<User[]>([])

        const handleClickOpen = (mode: string, location?: LocationCreateParams | LocationUpdateParams) => {
            if (location?.locationAdminId !== null && location?.locationAdminId !== undefined) {
                previousStoreAdminId = location?.locationAdminId
            } else {
                previousStoreAdminId = 0
            }
            setLocationDialog({
                ...locationDialog,
                isOpen: true,
                mode,
                data: location ? {...location} : {...defaultLocation, locationTypeId: 2}
            });
        };

        const handleClickLocationSettingOpen = (locationSetting?: LocationSettingUpdateParams ) => {
            setLocationSettingDialog({
                ...locationSettingDialog,
                isOpen: true,
                data: locationSetting ? {...locationSetting} : defaultLocationSetting
            });
        };

        const handleClose = () => {
            setLocationDialog({isOpen: false, data: defaultLocation, mode: 'add', isLoading: false});
            setLocationSettingDialog({isOpen: false, data: defaultLocationSetting, isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }

                // @ts-ignore
                const newCenter = map.getCenter();
                locationDialog.data.locationLat = newCenter.lat().toFixed(8)
                locationDialog.data.locationLong = newCenter.lng().toFixed(8)

                setLocationDialog({
                    ...locationDialog,
                    isLoading: true
                });

                let locationId;

                if (locationDialog.mode === 'add') {
                    const result = await locationStore.create({
                        ...locationDialog.data
                    }, currentAuthToken)
                    locationId = Number(result.location.identifiers[0].locationId)
                    if(locationDialog.data.locationTypeId === 1) {
                        const locationSettingResult = await locationSettingStore.create({
                            // @ts-ignore
                            locationId: locationId,
                            locationSettingLogo: '',
                            locationSettingColor: '#083B1C'
                        }, currentAuthToken)
                        await locationStore.update({
                            // @ts-ignore
                            locationId: locationId,
                            // @ts-ignore
                            locationSettingId: Number(locationSettingResult.locationSetting.identifiers[0].locationSettingId)
                        }, currentAuthToken)
                    }
                } else if (locationDialog.mode === 'edit') {
                    if(
                        locationDialog.data?.locationAdminId !== null &&
                        locationDialog.data?.locationAdminId !== undefined &&
                        locationDialog.data?.locationAdminId > 0
                    ) {
                        const locationResult = await locationStore.findAll({
                            locationAdminId: locationDialog.data?.locationAdminId
                        }, currentAuthToken, false)

                        if (locationResult.kind === 'ok') {
                            locationResult.locations.forEach(async item=>{
                                return await locationStore.update({
                                    locationId: item.locationId,
                                    locationAdminId: 0
                                }, currentAuthToken)

                            })
                        }
                    }
                    await locationStore.update({
                        ...locationDialog.data
                    }, currentAuthToken)
                    // @ts-ignore
                    locationId = Number(locationDialog.data.locationId)
                }

                if (
                    locationDialog.data?.locationAdminId !== null &&
                    locationDialog.data?.locationAdminId !== undefined &&
                    locationDialog.data?.locationAdminId > 0
                ) {
                    if (previousStoreAdminId > 0) {
                        console.log("previousStoreAdminId", previousStoreAdminId)
                        await userStore.update({
                            // @ts-ignore
                            userId: previousStoreAdminId,
                            // @ts-ignore
                            userLocationId: 0
                        }, currentAuthToken)
                    }
                    await userStore.update({
                        // @ts-ignore
                        userId: Number(locationDialog.data.locationAdminId),
                        // @ts-ignore
                        userLocationId: locationId
                    }, currentAuthToken)
                } else if (previousStoreAdminId > 0) {
                    await userStore.update({
                        // @ts-ignore
                        userId: previousStoreAdminId,
                        // @ts-ignore
                        userLocationId: 0
                    }, currentAuthToken)
                }

                await refresh()
                await getLockboxAdmins()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleLocationSettingSubmit = async () => {
            try {

                setLocationSettingDialog({
                    ...locationSettingDialog,
                    isLoading: true
                });

                await locationSettingStore.update({...locationSettingDialog.data}, currentAuthToken)

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(locationDialog.data.locationName)) {
                validationObject.name = "This field is required."
                isValid = false
            }
            // if(isEmpty(locationDialog.data.locationEmail)) {
            //     validationObject.email = "This field is required."
            //     isValid = false
            // }
            if (isEmpty(locationDialog.data.locationTypeId)) {
                validationObject.type = "This field is required."
                isValid = false
            }
            // if (isEmpty(locationDialog.data.locationAdminId)) {
            //     validationObject.admin = "This field is required."
            //     isValid = false
            // }
            if (isEmpty(locationDialog.data.locationPhone)) {
                validationObject.phoneNumber = "This field is required."
                isValid = false
            }
            if (isEmpty(locationDialog.data.locationAddress1)) {
                validationObject.addressLine1 = "This field is required."
                isValid = false
            }
            // if(isEmpty(locationDialog.data.locationAddress2)) {
            //     validationObject.addressLine2 = "This field is required."
            //     isValid = false
            // }
            if (isEmpty(locationDialog.data.locationCity)) {
                validationObject.city = "This field is required."
                isValid = false
            }
            if (isEmpty(locationDialog.data.locationState)) {
                validationObject.state = "This field is required."
                isValid = false
            }
            if (isEmpty(locationDialog.data.locationZip)) {
                validationObject.zip = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const checkAuth = () => {
            if(authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Store Admin Panel - Lockboxes';
            ;(async () => {
                checkAuth()
                await refresh()
                await getStores()
                await getLockboxAdmins()
            })()

        }, [])

        const rows: GridRowsProp = locations;

        const columns: GridColDef[] = [
            {
                field: 'locationName',
                headerName: 'Name',
                width: 250,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'locationAddress1',
                valueGetter: (params) => {
                    const {locationAddress1, locationAddress2, locationCity, locationState, locationZip} = params.row
                    return `${locationAddress1 ? locationAddress1 : ''} ${locationAddress2 ? locationAddress2 : ''}${locationAddress1 && locationAddress2 ? ', ' : ''}${locationCity}, ${locationZip} ${locationState}`
                },
                headerName: 'Address',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationPhone',
                headerName: 'Phone',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationTypeName',
                valueGetter: (params) => {
                    if (params.row?.locationType?.locationTypeName) {
                        return params.row.locationType.locationTypeName
                    }
                },
                headerName: 'Type',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationId', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                    </>;
                }
            },

        ];

        const onLoad = React.useCallback(function callback(map) {
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            // const bounds = new window.google.maps.LatLngBounds(center);
            // map.fitBounds(bounds);

            map.setZoom(15)
            setMap(map)
        }, [])

        const onUnmount = React.useCallback(function callback(map) {
            setMap(null)
        }, [])

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const getLockboxAdmins = async () => {
            const result = await userStore.findAll({
                roleId: 3,
                // userLocationId: 0,
                page: 0,
                perPage: 100
            }, currentAuthToken, false)
            if (result.kind === 'ok') {
                setLockboxAdmins(result.users)
            }
        }

        const getStores = async () => {
            const result = await locationStore.findAll({
                locationTypeId: 1,
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken, false)
            if (result.kind === 'ok') {
                setStores(result.locations)
            }
        }

        const refresh = async () => {
            if (!authStore.storeAdminAuth?.userLocationId) {
                return
            }
            setIsRefreshing(true)

            const result = await locationStore.findOne({
                locationId: authStore.storeAdminAuth?.userLocationId
            }, currentAuthToken, false)

            if (result.kind === 'ok') {
                locationStore.saveLocations([...result.location.lockboxes])
            }
            setIsRefreshing(false)
        }

        function makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        const handlePickedFile = ({ target }) => {
            setIsUploadingLogo(true)
            const storageRef = ref(storage, `${locationSettingDialog.data.locationId}/${makeid(16)}`);
            const metadata = {
                contentType: 'image/jpeg',
            };
            uploadBytes(storageRef, target.files[0], metadata).then((snapshot) => {
                getDownloadURL(storageRef)
                    .then(async (url) => {
                        setIsUploadingLogo(false)
                        setLocationSettingDialog({
                            ...locationSettingDialog,
                            data: {...locationSettingDialog.data, locationSettingLogo: url}
                        });
                    })
                    .catch((error) => {
                        setIsUploadingLogo(false)

                        console.log(error)
                        // Handle any errors
                    });

            });

        }

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                Lockboxes
                            </CTypography>
                            {/*<CButton variant={"contained"} onClick={() => handleClickOpen('add')} sx={{mr: 2}}>*/}
                            {/*    Add New*/}
                            {/*</CButton>*/}
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid
                                /// For now we're loading 100 items by default
                                /// More than 100 items needs pro plan
                                /// Also we never need to load like 1000 items at once, Worst case we can filter them
                                getRowId={(row) => row.locationId}
                                rows={rows}
                                columns={columns}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                pageSize={tablePageSize}
                                onPageSizeChange={handlePageSizeChange}
                                loading={isRefreshing}
                                components={{
                                    LoadingOverlay: LinearProgress,
                                }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={locationDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Location
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={2}>
                            <CTextField fullWidth label={"Name"}
                                        error={hasValue(validation.name)}
                                        helperText={hasValue(validation.name) ? validation.name : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationName: event.target.value}
                                            });
                                            if (hasValue(validation.name)) {
                                                setValidation({...validation, name: ''})
                                            }
                                        }} value={locationDialog.data.locationName}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Email"}
                                        error={hasValue(validation.email)}
                                        helperText={hasValue(validation.email) ? validation.email : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationEmail: event.target.value}
                                            });
                                            if (hasValue(validation.email)) {
                                                setValidation({...validation, email: ''})
                                            }
                                        }} value={locationDialog.data.locationEmail}/>
                        </CGrid>
                        <CGrid item xs={2}>
                            <CTextField fullWidth label={"Phone Number"}
                                        error={hasValue(validation.phoneNumber)}
                                        helperText={hasValue(validation.phoneNumber) ? validation.phoneNumber : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationPhone: event.target.value}
                                            });
                                            if (hasValue(validation.phoneNumber)) {
                                                setValidation({...validation, phoneNumber: ''})
                                            }
                                        }} value={locationDialog.data.locationPhone}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Admin"} select
                                        disabled={!hasValue(locationDialog.data.locationTypeId)}
                                        error={hasValue(validation.admin)}
                                        helperText={hasValue(validation.admin) ? validation.admin : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationAdminId: value}
                                            });
                                            if (hasValue(validation.admin)) {
                                                setValidation({...validation, admin: ''})
                                            }
                                        }} value={locationDialog.data.locationAdminId}>
                                <CMenuItem value={0}>None</CMenuItem>
                                {lockboxAdmins.map(user => {
                                    if (locationDialog.mode === 'add') {
                                        if (user.userLocationId === null || user.userLocationId === undefined || user.userLocationId === 0 ) {
                                            return <CMenuItem value={user.userId}>{user.userUsername}</CMenuItem>
                                        } else {
                                            return null
                                        }
                                    } else {
                                        return <CMenuItem value={user.userId}>{user.userUsername}</CMenuItem>
                                    }
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Address Line 1"}
                                        error={hasValue(validation.addressLine1)}
                                        helperText={hasValue(validation.addressLine1) ? validation.addressLine1 : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationAddress1: event.target.value}
                                            });
                                            if (hasValue(validation.addressLine1)) {
                                                setValidation({...validation, addressLine1: ''})
                                            }
                                        }} value={locationDialog.data.locationAddress1}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Address Line 2"}
                                        error={hasValue(validation.addressLine2)}
                                        helperText={hasValue(validation.addressLine2) ? validation.addressLine2 : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationAddress2: event.target.value}
                                            });
                                            if (hasValue(validation.addressLine2)) {
                                                setValidation({...validation, addressLine2: ''})
                                            }
                                        }} value={locationDialog.data.locationAddress2}/>
                        </CGrid>
                        <CGrid item xs={2}>
                            <CTextField fullWidth label={"City"}
                                        error={hasValue(validation.city)}
                                        helperText={hasValue(validation.city) ? validation.city : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationCity: event.target.value}
                                            });
                                            if (hasValue(validation.city)) {
                                                setValidation({...validation, city: ''})
                                            }
                                        }} value={locationDialog.data.locationCity}/>
                        </CGrid>
                        <CGrid item xs={2}>
                            <CTextField fullWidth label={"State"}
                                        error={hasValue(validation.state)}
                                        helperText={hasValue(validation.state) ? validation.state : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationState: event.target.value}
                                            });
                                            if (hasValue(validation.state)) {
                                                setValidation({...validation, state: ''})
                                            }
                                        }} value={locationDialog.data.locationState}/>
                        </CGrid>
                        <CGrid item xs={2}>
                            <CTextField fullWidth label={"Zip"}
                                        error={hasValue(validation.zip)}
                                        helperText={hasValue(validation.zip) ? validation.zip : null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationDialog({
                                                ...locationDialog,
                                                data: {...locationDialog.data, locationZip: Number(event.target.value)}
                                            });
                                            if (hasValue(validation.zip)) {
                                                setValidation({...validation, zip: ''})
                                            }
                                        }} value={locationDialog.data.locationZip}/>
                        </CGrid>
                        <CGrid item xs={12}>
                            <CBox mb={4} sx={{
                                position: "relative",
                                flexGrow: 1,
                                width: "100%",
                                borderRadius: '34px',
                                border: "1px solid #CACACA",
                                overflow: "hidden",
                                height: "200px"
                            }}>
                                {
                                    isMapLoaded ? (
                                        <GoogleMap
                                            center={locationDialog.data.locationLat &&
                                            locationDialog.data.locationLong ?
                                                {
                                                    lat: locationDialog.data.locationLat,
                                                    lng: locationDialog.data.locationLong
                                                }
                                                :
                                                {lat: 36.778259, lng: -119.417931}}
                                            mapContainerStyle={containerStyle}
                                            zoom={15}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                            options={{
                                                disableDefaultUI: true
                                            }}
                                        >
                                            { /* Child components, such as markers, info windows, etc. */}
                                            <CBox sx={{zIndex: 999}}>
                                                <LocationOn sx={{fontSize: 48}} color={"primary"}/>

                                            </CBox>

                                        </GoogleMap>
                                    ) : <></>
                                }

                            </CBox>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {locationDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : locationDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>

                <CDialog open={locationSettingDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Location Settings
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={6} alignItems={'center'} display={'flex'}>
                            {hasValue(locationSettingDialog.data.locationSettingLogo) &&
                                <img src={locationSettingDialog.data.locationSettingLogo} style={{
                                    width: 41,
                                    height: 41,
                                    borderRadius: 22,
                                    marginRight: 8,
                                    objectFit: "contain"
                                }}/>
                            }
                            {/*@ts-ignore*/}
                            <CButton variant="contained" component="label">
                                {isUploadingLogo ? <CCircularProgress sx={{color: "white"}}
                                                                               size={24}/> : 'Pick Logo' }
                                <input hidden accept="image/*" multiple type="file" onChange={handlePickedFile}/>
                            </CButton>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Color"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLocationSettingDialog({
                                                ...locationSettingDialog,
                                                data: {...locationSettingDialog.data, locationSettingColor: event.target.value}
                                            });
                                        }} value={locationSettingDialog.data.locationSettingColor}/>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleLocationSettingSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {locationSettingDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : 'Edit' }
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
