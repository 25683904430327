import {
    CBox,
    CGrid,
    CGridContainer,
    CTypography,
    Divider
} from "../components";

export const getCellValue = (row, column) => {
    // console.log(row, column)
    if (column.renderCell) {
        return column.renderCell({row});
    }
    if (column.valueGetter) {
        return column.valueGetter({row});
    }
    return row[column.field];
};

export const generateListRows = (rows, columns, noRowTitleOnSmallSize) => {

    return rows.map((row, index) => (
        <CBox
            // sx={(theme)=>({backgroundColor: index%2 === 0 ? 'white' : theme.palette.action.hover})}
        >
            <CGridContainer px={2} my={0} mb={2} spacing={2}>
                {
                    columns.map((column, cIndex)=>(
                        <>
                            {!noRowTitleOnSmallSize && <CGrid item xs={6} sx={{display: 'flex', alignItems: 'center'}} key={`${index}${cIndex}`}>
                                <CTypography variant={'body2'}>
                                    {column.headerName}
                                </CTypography>
                            </CGrid>}
                            <CGrid item xs={noRowTitleOnSmallSize ? 12 : 6} key={`${index}${cIndex}`}>{getCellValue(row, column)}</CGrid>
                        </>


                    ))
                }
            </CGridContainer>
            <Divider></Divider>

        </CBox>

    ));
};