import * as React from "react";

export const WaveBackground = () => {
    return (
        <div
            style={{
                position:'absolute',
                top: 0,
                right:0,
                left:0,
                zIndex: 0
            }}
        >
            {/*<CBox sx={{*/}
            {/*    width: '100vw',*/}
            {/*    height: {xs: '30vh', sm: '30vh', md: '20vh', lg: '10vh', xl: 90},*/}
            {/*    maxWidth: '100%',*/}
            {/*    backgroundColor: primary['500']*/}
            {/*}}>*/}

            {/*</CBox>*/}
            {/*<img src={curves} style={{width: '100%', marginTop: '-1px'}}>*/}

            {/*</img>*/}

        </div>
    )
}