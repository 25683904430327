import * as React from "react";
import {hasValue} from "../../utils/empty-check";
import {CButton, CIconButton} from "../mui-extracted";
import {useStores} from "../../models";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {Table} from "../table";
import {Flag} from "@mui/icons-material";

let statelessPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessOrderInfo = {
    orderBy: 'userId',
    order: 'DESC'
}
let currentAuthToken = ''

interface CustomersTableProps {
    module: string,
    handleCustomerDetailsClickOpen: (user: any, type: string) => void,
    onRefreshingChange: (isRefreshing: boolean) => void
}

let previousData: any = null
let renderedOnce = false

let statelessShowFlagged = false

export const CustomersTable = forwardRef(({
                                              module,
                                              handleCustomerDetailsClickOpen,
                                              onRefreshingChange
                                          }: CustomersTableProps, ref) => {

    const {authStore, userStore} = useStores()
    const {users, count} = userStore

    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'userId',
        order: 'DESC'
    })

    const handleRemove = async (user) => {
        await userStore.remove({userId: user.userId}, currentAuthToken)
        await refresh()
    }

    useImperativeHandle(ref, () => ({
        refreshContents(showFlagged) {
            statelessShowFlagged = showFlagged === true
            // Call your API here
            refresh(true)
        },
    }));

    const refresh = async (force?: boolean) => {
        setIsRefreshing(true)
        onRefreshingChange(true)

        const data = {
            roleId: 4,
            userLocationId: module === 'store admin' ? authStore.storeAdminAuth?.userLocationId ?
                authStore.storeAdminAuth?.userLocationId :
                undefined : undefined,
            userFlag: statelessShowFlagged ? 'red' : undefined,
            page: statelessPaginationInfo.page,
            perPage: statelessPaginationInfo.perPage,
            sortBy: statelessOrderInfo.orderBy,
            sortDirection: statelessOrderInfo.order,
        }
        const isDataTheSame = previousData !== null && (Object.keys(previousData).length === Object.keys(data).length) &&
            Object.keys(previousData).every(key => previousData[key] === data[key]);

        if (!isDataTheSame || force === true) {
            previousData = data
            await userStore.findAll(data, currentAuthToken, true)
        }
        setIsRefreshing(false)
        onRefreshingChange(false)
    }

    const checkAuth = () => {
        if (module === 'admin') {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
        } else if (module === 'store admin') {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            }
        } else if (module === 'lockbox') {
            if (authStore.lockboxAuth !== null) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            }
        }

    }

    const handlePageChange = async (details, page) => {
        // console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            page
        }
        await refresh()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await refresh()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessOrderInfo.orderBy === property && statelessOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        await refresh()
    };

    useEffect(() => {
        ;(async () => {
            if (!renderedOnce) {
                checkAuth()
                renderedOnce = true
                previousData = null
                await refresh()
            }
        })()

    }, [])

    const rows = users;

    let storeNameColumn: any[] = []

    if (module === 'admin') {
        storeNameColumn = [
            {
                field: 'locationName',
                valueGetter: (params) => {
                    const {location} = params.row
                    if (hasValue(location)) {
                        return location.locationName
                    } else {
                        return ''
                    }
                },
                headerName: 'Store Name',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
        ]
    }


    const columns = [
        {
            field: 'userId',
            headerName: 'ID',
            width: 60,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'userFullName',
            valueGetter: (params) => {
                const {userFirstName, userLastName} = params.row
                return `${userFirstName ? userFirstName : ''} ${userLastName ? userLastName : ''}`
            },
            headerName: 'Name',
            width: 150,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'userUsername',
            headerName: 'Username',
            width: 150,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'userRole',
            headerName: 'Role',
            valueGetter: (params) => {
                const {roleName} = params.row.role
                return `${roleName ? roleName : ''}`
            },
            width: 100,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        ...storeNameColumn,
        // {
        //     field: 'userLastLoginTs',
        //     headerName: 'Last Seen',
        //     flex: 1,
        //     headerClassName: "pos-table-header",
        //     cellClassName: "pos-table-cell"
        // },
        {
            field: 'col7', headerName: 'Actions', width: 400, headerClassName: "pos-table-header"
            , cellClassName: "pos-table-cell",
            renderCell: (params) => {

                const onRemoveClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleRemove(params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };
                const onCustomerDetailsClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleCustomerDetailsClickOpen(params.row, 'registered')
                }

                const isFlagged =  hasValue(params.row?.userFlag)
                const flagColor = params.row?.userFlag

                // flagColor = flagColor === 'red' ? 'error' : flagColor === 'yellow' ? 'warning' : null


                return <>
                    <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                             onClick={onCustomerDetailsClick}>Edit & Details</CButton>
                    {/*<CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}*/}
                    {/*              onClick={onClick}>Edit</CButton>*/}
                    {/*<CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}*/}
                    {/*         onClick={onStatisticsClick}>Statistics</CButton>*/}
                    {/*<CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}*/}
                    {/*         onClick={onRemoveClick}>Remove</CButton>*/}
                    {isFlagged &&<CIconButton>
                        <Flag style={{color: flagColor}}></Flag>
                    </CIconButton>}
                </>;
            }
        },

    ];

    return (
        <Table
            columns={columns}
            rows={rows}
            count={count}
            page={paginationInfo.page}
            rowsPerPage={paginationInfo.perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            orderBy={orderInfo.orderBy}
            order={orderInfo.order}
            onRequestSort={handleRequestSort}
            isLoading={isRefreshing}
            noRowTitleOnSmallSize={true}
        />
    )
})