import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {MinifiedLocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const LocationStoreLockboxModel = types.model("LocationStoreLockbox").props({
    locationStoreLockboxId: types.number,
    storeId: types.maybeNull(types.number),
    lockboxId: types.maybeNull(types.number),
    store: types.optional(MinifiedLocationModel, {locationId: -1}),
    lockbox: types.optional(MinifiedLocationModel, {locationId: -1}),
})

type LocationStoreLockboxType = Instance<typeof LocationStoreLockboxModel>
export interface LocationStoreLockbox extends LocationStoreLockboxType {}
type LocationStoreLockboxSnapshotType = SnapshotOut<typeof LocationStoreLockboxModel>
export interface LocationStoreLockboxSnapshot extends LocationStoreLockboxSnapshotType {}

export const defaultLocationStoreLockbox = {
    locationStoreLockboxId: undefined,
    storeId: undefined,
    lockboxId: undefined,
}