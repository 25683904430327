import Card, {CardProps} from '@mui/material/Card';
import CardActions, {CardActionsProps} from '@mui/material/CardActions';
import CardContent, {CardContentProps} from '@mui/material/CardContent';
import CardActionArea, {CardActionAreaProps} from '@mui/material/CardActionArea';
import CardMedia, {CardMediaProps} from '@mui/material/CardMedia';
import CardHeader, {CardHeaderProps} from '@mui/material/CardHeader';

import {styled} from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";

// export const CCard = styled(Card)<CardProps>(
//   ({ theme }) => ({
//       boxShadow: theme.breakpoints.down('sm') || theme.breakpoints.down('xs')
//           ? 'rgba(149, 157, 165, 0.2) 0px 3px 8px'
//           : 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
//       borderRadius: theme.breakpoints.down('sm') || theme.breakpoints.down('xs')
//           ? 18
//           : 37,
//       transition: theme.transitions.create('width', {
//           easing: theme.transitions.easing.easeInOut,
//           duration: theme.transitions.duration.enteringScreen,
//       }),
//   }),
// );

export const CCard = (props: CardProps)=>{
    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));


    return (
        <Card  {...props} sx={{
            boxShadow: isSmallScreen || isExtraSmallScreen
                ? 'rgba(149, 157, 165, 0.2) 0px 3px 8px'
                : 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            borderRadius:isSmallScreen || isExtraSmallScreen
                ? '18px'
                : '37px',
            // transition: theme.transitions.create('width', {
            //     easing: theme.transitions.easing.easeInOut,
            //     duration: theme.transitions.duration.enteringScreen,
            // }),

            ...props.sx
        }} >
            {props.children}
        </Card>
    )
};

export const CCardActions = styled(CardActions)<CardActionsProps>(
  (/* { theme } */) => ({
  }),
);

export const CCardContent = styled(CardContent)<CardContentProps>(
  (/* { theme } */) => ({
      padding: 37
  }),
);

export const CCardActionArea = styled(CardActionArea)<CardActionAreaProps>(
  (/* { theme } */) => ({
  }),
);

export const CCardMedia = styled(CardMedia)<CardMediaProps>(
  (/* { theme } */) => ({
  }),
);

export const CCardHeader = styled(CardHeader)<CardHeaderProps>(
  (/* { theme } */) => ({
  }),
);
