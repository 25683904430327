import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {RoleModel} from "../role/role";
import {UserDetailModel} from "../user-detail/user-detail";

/**
 * Rick and Morty character model.
 */
export const AuthModel = types.model("Auth").props({
    userId: types.number,
    userUsername: types.string,
    userFirstName: types.maybeNull(types.string),
    userLastName: types.maybeNull(types.string),
    userEmail: types.maybeNull(types.string),
    userPhone: types.maybeNull(types.string),
    userNote: types.maybeNull(types.string),
    userLastLoginTs: types.maybeNull(types.string),
    userLastLoginIp: types.maybeNull(types.string),
    roleId: types.number,
    userLocationId: types.maybeNull(types.number),
    userStatus: types.maybeNull(types.string),
    userCreatedAt: types.string,
    userUpdatedAt: types.string,
    accessToken: types.string,
    role: types.optional(types.maybeNull(RoleModel), {roleId: -1, roleName: ""}),
    userDetail: types.maybeNull(UserDetailModel)
})

type AuthType = Instance<typeof AuthModel>
export interface Auth extends AuthType {}
type AuthSnapshotType = SnapshotOut<typeof AuthModel>
export interface AuthSnapshot extends AuthSnapshotType {}

