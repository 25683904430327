import {
    CBox,
    CButton,
    CCircularProgress, CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {
    getCustomerAddress,
    getCustomerEmail,
    getCustomerName,
    getCustomerPhone,
    getCustomerZip
} from "../../utils/customer";

export const PaymentDialog = ({
                                    paymentDialog,
                                    setPaymentDialog,
                                    handleClose,
                                    handleSubmit,
                                    validation,
                                    setValidation
                              }) => {
    return (
        <CDialog open={paymentDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
            sx: {
                borderRadius: "37px"
            }
        }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4}>
                    {paymentDialog.mode === 'add' ? 'Add Payment' : 'Edit Payment'}
                </CTypography>
            </CBox>
            <Divider></Divider>
            <CGridContainer spacing={2} p={2} sx={{
                height: '70vh',
                overflowY: 'scroll'
            }}>
                <CGrid item xs={6} md={4}>
                    <CTextField fullWidth label={"Order Id"}
                                error={hasValue(validation.order)}
                                type={"number"}
                                helperText={hasValue(validation.order) ? validation.order : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPaymentDialog({
                                        ...paymentDialog,
                                        data: {...paymentDialog.data, orderId: Number(event.target.value)}
                                    });
                                    if (hasValue(validation.order)) {
                                        setValidation({...validation, order: ''})
                                    }
                                }} value={paymentDialog.data.orderId}/>
                </CGrid>
                <CGrid item xs={6} md={4}>
                    <CTextField fullWidth label={"Amount"}
                                error={hasValue(validation.amount)}
                                type={"number"}
                                helperText={hasValue(validation.amount) ? validation.amount : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPaymentDialog({
                                        ...paymentDialog,
                                        data: {...paymentDialog.data, paymentAmount: Number(event.target.value)}
                                    });
                                    if (hasValue(validation.amount)) {
                                        setValidation({...validation, amount: ''})
                                    }
                                }} value={paymentDialog.data.paymentAmount}/>
                </CGrid>
                <CGrid item xs={12} md={4}>
                    <CTextField fullWidth label={"Status"} select
                                error={hasValue(validation.status)}
                                helperText={hasValue(validation.status) ? validation.status : null}
                                onChange={(event) => {
                                    setPaymentDialog({
                                        ...paymentDialog,
                                        data: {...paymentDialog.data, paymentStatus: event.target.value}
                                    });
                                    if (hasValue(validation.status)) {
                                        setValidation({...validation, status: ''})
                                    }
                                }} value={paymentDialog.data.paymentStatus}>
                        <CMenuItem value={'paid'}>Paid</CMenuItem>
                        <CMenuItem value={'not paid'}>Not paid</CMenuItem>
                        <CMenuItem value={'pending'}>Pending</CMenuItem>
                        <CMenuItem value={'rejected'}>Rejected</CMenuItem>
                        <CMenuItem value={'refunded'}>Refunded</CMenuItem>
                        <CMenuItem value={'successful'}>Successful</CMenuItem>
                        <CMenuItem value={'failed'}>Failed</CMenuItem>
                    </CTextField>
                </CGrid>
                <CGrid item xs={12}>
                    <CTextField multiline fullWidth label={"Meta Data"}
                                maxRows={15}
                                disabled
                        // @ts-ignore
                                value={paymentDialog.data.paymentMetaData}/>

                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Name"}
                                disabled
                        // @ts-ignore
                                value={getCustomerName(paymentDialog.data.order?.customer, paymentDialog.data.order?.user)}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Phone"}
                                disabled
                        // @ts-ignore
                                value={getCustomerPhone(paymentDialog.data.order?.customer, paymentDialog.data.order?.user)}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Email"}
                                disabled
                        // @ts-ignore
                                value={getCustomerEmail(paymentDialog.data.order?.customer, paymentDialog.data.order?.user)}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Customer Address"}
                                disabled
                        // @ts-ignore
                                value={getCustomerAddress(paymentDialog.data.order?.customer, paymentDialog.data.order?.user)}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Store Name"}
                                disabled
                        // @ts-ignore
                                value={paymentDialog.data.paymentStoreName}/>
                </CGrid>
                <CGrid item xs={6} md={3}>
                    <CTextField fullWidth label={"Gateway Name"}
                                disabled
                        // @ts-ignore
                                value={paymentDialog.data.paymentGatewayName}/>
                </CGrid>
                <CGrid item xs={6} md={2}>
                    <CTextField fullWidth label={"Gateway ID"}
                                disabled
                        // @ts-ignore
                                value={paymentDialog.data.paymentGatewayId}/>
                </CGrid>
                <CGrid item xs={6} md={2}>
                    <CTextField fullWidth label={"Card Number"}
                                disabled
                        // @ts-ignore
                                value={paymentDialog.data.paymentCardNumber}/>
                </CGrid>
                <CGrid item xs={6} md={2}>
                    <CTextField fullWidth label={"Zip"}
                                disabled
                        // @ts-ignore
                                value={getCustomerZip(paymentDialog.data.order?.customer, paymentDialog.data.order?.user)}/>
                </CGrid>
            </CGridContainer>

            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
                <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                    {paymentDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                  size={24}/> : paymentDialog.mode === 'add' ? 'Add' : 'Edit'}
                </CButton>
            </CBox>
        </CDialog>
    )
}