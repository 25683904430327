import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  OrderDeleteParams,
  OrderCreateResult,
  OrderUpdateResult,
  OrderUpdateParams,
  OrderFindAllResult,
  OrderDeleteResult,
  OrderFindAllParams,
  OrderFindOneParams,
  OrderCreateParams,
  OrderFindOneResult, OrderCustomerStatisticsResult, OrderSummaryResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class OrdersApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: OrderCreateParams): Promise<OrderCreateResult> {
    try {
      // const functionName = "login"
      const uri = "orders"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      console.log('create', response)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const order = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", order}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: OrderFindAllParams): Promise<OrderFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "orders"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      console.log('findAll', response, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const orders = response.data.orders
      const count = response.data.count

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", orders, count}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: OrderFindOneParams): Promise<OrderFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "orders/"+params.orderId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const order = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", order}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: OrderUpdateParams): Promise<OrderUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "orders/"+params.orderId
      delete params.orderId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const order = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", order}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: OrderDeleteParams): Promise<OrderDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "orders/"+params.orderId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      console.log('remove', response)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async customerStatistics(params: { customerEmail: string, locationId?: number }): Promise<OrderCustomerStatisticsResult> {
    try {
      // const functionName = "login"
      const uri = "orders/customer-statistics"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      console.log('customerStatistics', response, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const statistics = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", statistics}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async summaryStatistics(params: {
    fromDate: string;
    toDate: string;
    storeId?: number;
    lockboxId?: number;
  }): Promise<OrderSummaryResult> {
    try {
      // const functionName = "login"
      const uri = "orders/summary-statistics"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      console.log('summaryStatistics', response, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const summary = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", summary}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async summaryList(params: {
    fromDate: string;
    toDate: string;
    storeId?: number;
    lockboxId?: number;
  }): Promise<OrderSummaryResult> {
    try {
      // const functionName = "login"
      const uri = "orders/summary-list"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      console.log('summaryList', response, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const summary = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", summary}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
