import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard, CCircularProgress,
    CDialog, CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {GatewayCreateParams, GatewayUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultGateway} from "../../../models/gateway/gateway";
import {isEmpty} from "validate.js";
import {useEffect} from "react";
import {Refresh} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";

interface GatewayDialogData {
    isOpen: boolean,
    data: GatewayCreateParams | GatewayUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

export const AdminPanelGateways = observer(
    function AdminPanelGateways() {
        const navigate = useNavigate();

        const {authStore, gatewayStore, configStore} = useStores()
        const {gateways} = gatewayStore

        const [gatewayDialog, setGatewayDialog] = React.useState<GatewayDialogData>({
            isOpen: false,
            data: defaultGateway,
            mode: 'add',
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            name: '',
            type: '',
            data: ''
        })

        const [defaultGatewayConfigId, setDefaultGatewayConfigId] = React.useState<number|null>(null)
        const [defaultGatewayId, setDefaultGatewayId] = React.useState<number|null>(null)
        const [isLoadingDefaultGatewayId, setIsLoadingDefaultGatewayId] = React.useState<boolean>(true)

        const handleClickOpen = (mode: string, gateway?: GatewayCreateParams | GatewayUpdateParams) => {
            setGatewayDialog({
                ...gatewayDialog,
                isOpen: true,
                mode,
                data: gateway ? {...gateway} : defaultGateway
            });
        };

        const handleClose = () => {
            setGatewayDialog({isOpen: false, data: defaultGateway, mode: 'add', isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setGatewayDialog({
                    ...gatewayDialog,
                    isLoading: true
                });

                if (gatewayDialog.mode === 'add') {
                    await gatewayStore.create({
                        ...gatewayDialog.data
                    }, currentAuthToken)
                } else if (gatewayDialog.mode === 'edit') {
                    await gatewayStore.update({
                        ...gatewayDialog.data
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (gateway) => {
            await gatewayStore.remove({gatewayId: gateway.gatewayId}, currentAuthToken)
            await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if(isEmpty(gatewayDialog.data.gatewayName)) {
                validationObject.name = "This field is required."
                isValid = false
            }
            if(isEmpty(gatewayDialog.data.gatewayType)) {
                validationObject.type = "This field is required."
                isValid = false
            }
            if(isEmpty(gatewayDialog.data.gatewayData)) {
                validationObject.data = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await gatewayStore.findAll({
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if(authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        const getDefaultGateway = async () => {
            setIsLoadingDefaultGatewayId(true)
            const defaultGatewayConfigResult = await configStore.findAll({
                configSlug: 'default-gateway-id'
            }, currentAuthToken)
            if (defaultGatewayConfigResult.kind === 'ok') {
                if (defaultGatewayConfigResult.configs.length > 0) {
                    setDefaultGatewayId(Number(defaultGatewayConfigResult.configs[0].configValue))
                    setDefaultGatewayConfigId(defaultGatewayConfigResult.configs[0].configId)
                }
            }
            setIsLoadingDefaultGatewayId(false)
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Gateways';
            ;(async () => {
                checkAuth()
                await refresh()
                await getDefaultGateway()
            })()

        }, [])

        const rows: GridRowsProp = gateways;

        const columns: GridColDef[] = [
            {
                field: 'gatewayName',
                headerName: 'Name',
                width: 256,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'gatewayData',
                headerName: 'Data',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onRemoveClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleRemove(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}
                                 onClick={onRemoveClick}>Remove</CButton>
                    </>;
                }
            },

        ];

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                                <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                    Gateways
                                </CTypography>
                            <CButton variant={"contained"} onClick={()=>handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                            {!isLoadingDefaultGatewayId && <CTextField size={'small'} sx={{ml: 2}} label={"Default Gateway"} select
                                         onChange={(event) => {
                                             const value = Number(event.target.value);
                                             setDefaultGatewayId(value)
                                             configStore.update({
                                                 configId: Number(defaultGatewayConfigId),
                                                 configValue: event.target.value
                                             }, currentAuthToken)
                                         }} value={defaultGatewayId}>
                                {gateways.map(gateway => {
                                    return <CMenuItem
                                        value={Number(gateway.gatewayId)}>{gateway.gatewayName}</CMenuItem>
                                })}
                            </CTextField>}
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.gatewayId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={gatewayDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Gateway
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Name"}
                                        error={hasValue(validation.name)}
                                        helperText={hasValue(validation.name)?validation.name:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setGatewayDialog({
                                                ...gatewayDialog,
                                                data: {...gatewayDialog.data, gatewayName: event.target.value}
                                            });
                                            if(hasValue(validation.name)) {
                                                setValidation({...validation, name: ''})
                                            }
                                        }} value={gatewayDialog.data.gatewayName}/>
                        </CGrid>
                        <CGrid item xs={6} >
                            <CTextField multiline fullWidth label={"Type"}
                                        // disabled
                                        maxRows={15}
                                        error={hasValue(validation.type)}

                                        helperText={hasValue(validation.type)?validation.type:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setGatewayDialog({
                                                ...gatewayDialog,
                                                data: {...gatewayDialog.data, gatewayType: event.target.value}
                                            });
                                            if(hasValue(validation.type)) {
                                                setValidation({...validation, type: ''})
                                            }
                                        }} value={gatewayDialog.data.gatewayType}/>

                        </CGrid>
                        <CGrid item xs={12} >
                            <CTextField multiline fullWidth label={"Data (JSON)"}
                                        maxRows={15}
                                        error={hasValue(validation.data)}

                                        helperText={hasValue(validation.data)?validation.data:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setGatewayDialog({
                                                ...gatewayDialog,
                                                data: {...gatewayDialog.data, gatewayData: event.target.value}
                                            });
                                            if(hasValue(validation.data)) {
                                                setValidation({...validation, data: ''})
                                            }
                                        }} value={gatewayDialog.data.gatewayData}/>

                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {gatewayDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : gatewayDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
