import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  SkuDeleteParams,
  SkuCreateResult,
  SkuUpdateResult,
  SkuUpdateParams,
  SkuFindAllResult,
  SkuDeleteResult,
  SkuFindAllParams,
  SkuFindOneParams,
  SkuCreateParams,
  SkuFindOneResult, SkuSearchParams, SkuSearchResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class SkusApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: SkuCreateParams): Promise<SkuCreateResult> {
    try {
      // const functionName = "login"
      const uri = "sku"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const sku = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", sku}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: SkuFindAllParams): Promise<SkuFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "sku"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const skus = response.data.skus
      const count = response.data.count

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", skus, count}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async search(params: SkuSearchParams): Promise<SkuSearchResult> {
    try {
      // const functionName = "login"
      const uri = "sku/search"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      console.log('search', response)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const skus = response.data

      console.log('search', skus)

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", skus}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: SkuFindOneParams): Promise<SkuFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "sku/"+params.skuId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const sku = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", sku}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: SkuUpdateParams): Promise<SkuUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "sku/"+params.skuId
      delete params.skuId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const sku = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", sku}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: SkuDeleteParams): Promise<SkuDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "sku/"+params.skuId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
