// @ts-nocheck

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCardContent, CCircularProgress, CConfirmationDialog,
    CDrawer,
    CFormControl,
    CGrid,
    CGridContainer, CIconButton,
    COutlinedInput,
    CTextFieldRoundedCorners,
    CustomerDialog,
    Divider,
    MomentTimer,
    POSTitle,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import {
    AllInbox,
    CalendarMonth,
    Cancel,
    CheckOutlined,
    CreditCardOff,
    LocalShipping,
    Menu,
    RefreshOutlined,
    RestartAltOutlined,
    Search,
    Summarize,
} from "@mui/icons-material";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useStores} from "../../../models";
import {defaultOrder} from "../../../models/order/order";
import {OrderUpdateParams} from "../../../services/api";
import {isEmpty} from "validate.js";
import {hasValue} from "../../../utils/empty-check";
import {OrdersTable} from "../../../components/orders-table";
import {Payment} from "../../../models/payment/payment";
import {ListItemText, useMediaQuery} from "@mui/material";
import {primary} from "../../../theme";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {OrderDialog} from "../../../components/order-dialog";
import {getSkuAvailability} from "../../../utils/sku";
import {Location} from "../../../models/location/location";
import {SummaryScreenContent} from "../../../sections/summary-screen-content";


// Load the necessary plugins
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(localizedFormat);

interface OrderDialogData {
    isOpen: boolean,
    data: OrderUpdateParams,
    isLoading: boolean
}


let currentAuthToken = ''
let interval
let refreshIntervalStatus = 'started'
let countDownInterval
let statelessCountDown = 30
export const LockboxOrders = observer(
    function LockboxOrders() {
        const navigate = useNavigate();

        const {authStore, orderStore, orderDetailStore, paymentStore, locationStore} = useStores()

        const [orderNoFilter, setOrderNoFilter] = React.useState('');
        const [orderCustomerFullNameFilter, setOrderCustomerFullNameFilter] = React.useState('');
        const [orderCardNoFilter, setOrderCardNoFilter] = React.useState('');
        const [orderBillingNameFilter, setOrderBillingNameFilter] = React.useState('');
        const [orderStatusFilter, setOrderStatusFilter] = React.useState('received');
        const [orderDialog, setOrderDialog] = React.useState<OrderDialogData>({
            isOpen: false,
            data: defaultOrder,
            isLoading: false
        });
        const [validation, setValidation] = React.useState({
            order: '',
            status: '',
            total: '',
            kiosk: '',
            lockbox: ''
        })
        const [fromDate, setFromDate] = React.useState<Dayjs | null>(null)
        const [toDate, setToDate] = React.useState<Dayjs | null>(null)
        const [isCustomerDialogOpen, setIsCustomerDialogOpen] = React.useState(false);
        const [customerDialogData, setCustomerDialogData] = React.useState({
            id: 0,
            customerType: 'guest'
        });
        const [orderPayments, setOrderPayments] = React.useState<Payment[]>([])
        const [isDrawerOpen, setIsDrawerOpen] = useState(false)
        const [currentLocation, setCurrentLocation] = useState<Location | null>(null)
        const [currentUsername, setCurrentUsername] = useState<string | null>(null)
        const [showLogoutConfirmDialog, setShowLogoutConfirmDialog] = useState(false)
        const [isRefreshing, setIsRefreshing] = useState(false)
        const [countDown, setCountDown] = useState(30)

        const gridRef = useRef(null);
        const ordersTableRef = useRef(null);

        const startCountDownInterval = () => {
            if(hasValue(countDownInterval)) {
                clearCountDownInterval()
            }
            console.log('startCountDownInterval', countDown)
            countDownInterval = setInterval(() => {
                if (statelessCountDown-1 >= 0) {
                    console.log('countDownInterval', countDown)
                    statelessCountDown -= 1
                    setCountDown(statelessCountDown)

                }
            }, 1000); // Interval of 1 second (1000 milliseconds)

        }

        const clearCountDownInterval = () => {
            if (countDownInterval) {
                console.log('clearCountDownInterval')
                clearInterval(countDownInterval)
                setCountDown(30)
                statelessCountDown = 30
            }
        }

        const startRefreshInterval = () => {
            if (refreshIntervalStatus !== 'started') {
                refreshIntervalStatus = 'started'
                console.log('startRefreshInterval')
                interval = setInterval(() => {
                    statelessCountDown = 30
                    clearCountDownInterval()
                    refresh()
                    startCountDownInterval()
                }, 30000); // Interval of 1 second (1000 milliseconds)
            }
        }

        const clearRefreshInterval = () => {
            try {
                if (refreshIntervalStatus !== 'cleared') {
                    refreshIntervalStatus = 'cleared'
                    if (interval) {
                        console.log('clearRefreshInterval')
                        clearInterval(interval);
                        clearCountDownInterval()
                    }
                }
            } catch (e) {
                console.log(e.message)
            }
        }

        const refresh = () => {
            if (ordersTableRef.current) {
                // @ts-ignore
                ordersTableRef.current.refreshContents();
            }
        }
        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = `Lockbox - Orders`;
            ;(async () => {
                startCountDownInterval()
                resetStatelessValues()
                checkAuth()
                startRefreshInterval()
                const result = await locationStore.findOne({
                    locationId: authStore.lockboxAuth?.userLocationId
                }, currentAuthToken, false)

                if (result.kind === 'ok') {
                    setCurrentLocation({...result.location})
                }
            })()
            if (gridRef.current) {
                gridRef.current.scrollLeft = gridRef.current.scrollWidth;
            }

            return () => {
                clearRefreshInterval();
            };
        }, [])

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(orderDialog.data.orderStatus)) {
                validationObject.status = "This field is required."
                isValid = false
            }
            if (isEmpty(orderDialog.data.orderTotal)) {
                validationObject.total = "This field is required."
                isValid = false
            }
            if (isEmpty(orderDialog.data.kioskId)) {
                validationObject.kiosk = "This field is required."
                isValid = false
            }
            if (isEmpty(orderDialog.data.lockboxId)) {
                validationObject.lockbox = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const handleClickOpen = async (order?: OrderUpdateParams) => {
            setOrderDialog({
                ...orderDialog,
                isOpen: true,
                data: order ? {...order} : defaultOrder
            });
            await orderDetailStore.findAll({
                orderId: Number(order?.orderId)
            }, currentAuthToken)
            const result = await paymentStore.findAll({
                orderId: Number(order?.orderId)
            }, currentAuthToken, false)
            if (result.kind === 'ok') {
                setOrderPayments(result.payments)
            }
        };


        const handleClose = () => {
            setOrderDialog({isOpen: false, data: defaultOrder, isLoading: false});
        };

        const handleSubmit = async (status) => {
            try {


                if (!validate()) {
                    return
                }

                setOrderDialog({
                    ...orderDialog,
                    isLoading: true
                });

                await orderStore.update({
                    orderId: orderDialog.data.orderId,
                    // ...orderDialog.data,
                    orderStatus: status
                }, currentAuthToken)

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleSaveChanges = async () => {
            try {


                if (!validate()) {
                    return
                }

                setOrderDialog({
                    ...orderDialog,
                    isLoading: true
                });

                await orderStore.update({
                    orderId: orderDialog.data.orderId,
                    // ...orderDialog.data,
                    orderLocationNote: orderDialog.data.orderLocationNote,
                    deliveryMethod: orderDialog.data.deliveryMethod
                }, currentAuthToken)

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };


        const checkAuth = () => {
            if (authStore.lockboxAuth !== null) {
                currentAuthToken = authStore.lockboxAuth.accessToken
                setCurrentUsername(authStore.lockboxAuth.userUsername)
                // currentAuthLockboxId = Number(authStore.lockboxAuth.userLocationId)
            } else {
                navigate(`/system-login`)
            }
        }

        const resetStatelessValues = () => {
            currentAuthToken = ''
            // currentAuthLockboxId = -1
            // statelessOrderNoFilter = ''
            // statelessOrderStatusFilter = ''
            // statelessFromDate = null
            // statelessToDate = null
        }

        const handleCustomerDetailsClickOpen = async (order) => {
            const customer = order.customer
            const user = order.user
            if (hasValue(customer?.customerFname)) {
                setCustomerDialogData({
                    id: customer?.customerId,
                    customerType: 'guest'
                })
                setIsCustomerDialogOpen(true)
            } else if (hasValue(user?.userDetail)) {
                setCustomerDialogData({
                    id: user?.userId,
                    customerType: 'registered'
                })
                setIsCustomerDialogOpen(true)
            }
        };

        function skuCompleteName(sku) {
            if (sku.skuHasParent === 1) {
                if (sku.skuCode) {
                    return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
                } else {
                    return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
                }
            } else {
                if (sku.skuCode) {
                    return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
                } else {
                    return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
                }
            }
        }

        function createOrderDetailData(
            id: number,
            code: string,
            name: string,
            quantity: number,
            price: number,
            availability: number
        ) {
            return {id, code, name, quantity, price, availability};
        }

        const orderDetailRows = orderDetailStore.orderDetails.map(
            orderDetail =>
                createOrderDetailData(
                    orderDetail.orderDetailId,
                    orderDetail.sku.skuCode,
                    skuCompleteName(orderDetail.sku),
                    Number(orderDetail.skuQuantity),
                    Number(orderDetail.skuPrice),
                    getSkuAvailability(orderDetail.sku, orderDialog.data.lockboxId)
                )
        )

        // @ts-ignore
        const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
        // @ts-ignore
        const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

        const textBoxSize = isSmallScreen || isExtraSmallScreen ? 'small' : 'small'

        const headerButtonsSize = isSmallScreen || isExtraSmallScreen ? 'small' : 'medium'

        return (
            <>
                <main style={{minHeight: '100vh'}}>
                    <CDrawer
                        anchor={'left'}
                        open={isDrawerOpen}
                        onClose={() => setIsDrawerOpen(false)}
                    >
                        <CBox
                            sx={{width: 300}}
                            role="presentation"
                            // onClick={() => setIsDrawerOpen(false)}
                            // onKeyDown={() => setIsDrawerOpen(false)}
                        >
                            <POSTitle sx={{
                                m: 3,
                                // textAlign: "center",
                                fontFamily: "Passion One",
                                color: primary['500'],
                                zIndex: 9,
                            }}
                                      fontSize={28}>
                                {hasValue(currentLocation) ? currentLocation.locationName : 'Lockbox'}
                            </POSTitle>
                            <Divider></Divider>
                            <ListItemButton onClick={() => {
                                setOrderStatusFilter("")
                                clearRefreshInterval()
                                setIsDrawerOpen(false)
                            }}>
                                <ListItemIcon>
                                    {orderStatusFilter === "" ? <CheckOutlined/> : <AllInbox/>}
                                </ListItemIcon>
                                <ListItemText>
                                    All
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => {
                                setOrderStatusFilter("picked_up")
                                clearRefreshInterval()
                                setIsDrawerOpen(false)
                            }}>
                                <ListItemIcon>
                                    {orderStatusFilter === "picked_up" ? <CheckOutlined/> : <LocalShipping/>}
                                </ListItemIcon>
                                <ListItemText>
                                    Picked Up
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => {
                                setOrderStatusFilter("canceled")
                                clearRefreshInterval()
                                setIsDrawerOpen(false)
                            }}>
                                <ListItemIcon>
                                    {orderStatusFilter === "canceled" ? <CheckOutlined/> : <Cancel/>}
                                </ListItemIcon>
                                <ListItemText>
                                    Canceled
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => {
                                setOrderStatusFilter("unpaid")
                                clearRefreshInterval()
                                setIsDrawerOpen(false)
                            }}>
                                <ListItemIcon>
                                    {orderStatusFilter === "unpaid" ? <CheckOutlined/> : <CreditCardOff/>}
                                </ListItemIcon>
                                <ListItemText>
                                    Unpaid
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => {
                                setOrderStatusFilter("summary")
                                clearRefreshInterval()
                                setIsDrawerOpen(false)
                            }}>
                                <ListItemIcon>
                                    {orderStatusFilter === "summary" ? <CheckOutlined/> : <Summarize/>}
                                </ListItemIcon>
                                <ListItemText>
                                    Summary
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => {
                                setShowLogoutConfirmDialog(true)
                            }}>
                                <ListItemIcon>
                                    <AllInbox/>
                                </ListItemIcon>
                                <ListItemText>
                                    {hasValue(currentUsername) ? `Logout (${currentUsername})` : 'Logout'}
                                </ListItemText>
                            </ListItemButton>
                        </CBox>
                    </CDrawer>
                    <CBox height={90} sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "#212121",
                        zIndex: 0
                    }}/>
                    {/*<CContainer*/}
                    {/*    style={{*/}
                    {/*        position: "absolute",*/}
                    {/*        top: 0,*/}
                    {/*        right: 0,*/}
                    {/*        left: 0,*/}
                    {/*        bottom: 0,*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: "column",*/}
                    {/*        minHeight: '100vh'*/}
                    {/*    }}>*/}
                    {/*</CContainer>*/}
                    <CGridContainer sx={{display: "flex", height: 90}}>
                        <CGrid item md={2} xs={0} sx={{
                            display: {xs: 'none', sm: 'none', md: "flex", lg: "flex", xl: "flex"},
                            alignItems: "center",
                            justifyContent: "center",
                            height: 90
                        }}>

                            <POSTitle sx={{
                                textAlign: "center",
                                fontFamily: "Passion One",
                                color: "white",
                                fontWeight: "400",
                                fontSize: "32px"
                            }}>
                                Orders
                            </POSTitle>
                        </CGrid>
                        <CGrid
                            item
                            xs={12}
                            md={8}
                            ref={gridRef}
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                pb: {xs: 2, sm: 2, md: 3, lg: 3, xl: 3},
                                justifyContent: "center",
                                // flexDirection: 'row-reverse',
                                height: 90,
                                overflowX: 'scroll',
                                scrollSnapAlign: 'end'
                            }}
                        >
                            {(isSmallScreen || isExtraSmallScreen) && <>
                                <CIconButton sx={{ml: 3}} onClick={() => {
                                    setIsDrawerOpen(true)
                                }}>
                                    {/*<Menu fontSize={'large'} sx={{color: 'white'}}></Menu>*/}
                                    <Menu fontSize={'large'} sx={{color: 'white'}}></Menu>
                                </CIconButton>
                                <CBox sx={{flex: '1'}}/>
                            </>}
                            {!(isSmallScreen || isExtraSmallScreen) && <CBox>
                                <CButton variant={'outlined'} sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: "white",
                                    fontWeight: "400",
                                    padding: 0,
                                    ml: {xs: 24, sm: 0, lg: 0, md: 0, xl: 0},
                                    borderColor: 'white',
                                    px: 2
                                }}
                                         onClick={() => {
                                             setShowLogoutConfirmDialog(true)
                                         }}
                                         size={headerButtonsSize}
                                >Logout</CButton>
                            </CBox>}

                            <CBox>
                                {orderStatusFilter === 'received' &&
                                    <CBox sx={{height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: orderStatusFilter === 'received' ? "white" : "#757575",
                                    fontWeight: "400",
                                    padding: 0,
                                    ml: 2
                                }}
                                         onClick={() => {
                                             setOrderStatusFilter("received")
                                             startRefreshInterval()
                                         }}
                                         size={headerButtonsSize}

                                >Received</CButton>
                            </CBox>

                            <CBox>
                                {orderStatusFilter === 'ready' &&
                                    <CBox sx={{ml: 2, height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: orderStatusFilter === 'ready' ? "white" : "#757575",
                                    fontWeight: "400",
                                    padding: 0,
                                    ml: 2
                                }}
                                         onClick={() => {
                                             setOrderStatusFilter("ready")
                                             startRefreshInterval()
                                         }}
                                         size={headerButtonsSize}

                                >Ready</CButton>
                            </CBox>

                            {!(isSmallScreen || isExtraSmallScreen) && <CBox>
                                {orderStatusFilter === 'unpaid' &&
                                    <CBox sx={{height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: orderStatusFilter === 'unpaid' ? "white" : "#757575",
                                    fontWeight: "400",
                                    padding: 0,
                                    ml: 2
                                }}
                                         onClick={() => {
                                             setOrderStatusFilter("unpaid")
                                             clearRefreshInterval()
                                         }}
                                         size={headerButtonsSize}

                                >Unpaid</CButton>
                            </CBox>}

                            {!(isSmallScreen || isExtraSmallScreen) && <CBox>
                                {orderStatusFilter === 'canceled' &&
                                    <CBox sx={{height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: orderStatusFilter === 'canceled' ? "white" : "#757575",
                                    fontWeight: "400",
                                    padding: 0,
                                    ml: 2
                                }}
                                         onClick={() => {
                                             setOrderStatusFilter("canceled")
                                             clearRefreshInterval()
                                         }}
                                         size={headerButtonsSize}

                                >Canceled</CButton>
                            </CBox>}


                            {!(isSmallScreen || isExtraSmallScreen) && <CBox>
                                {orderStatusFilter === 'picked_up' &&
                                    <CBox sx={{ml: 2, height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: orderStatusFilter === 'picked_up' ? "white" : "#757575",
                                    fontWeight: "400",
                                    padding: 0,
                                    ml: 2
                                }}
                                         onClick={() => {
                                             setOrderStatusFilter("picked_up")
                                             clearRefreshInterval()
                                         }}
                                         size={headerButtonsSize}


                                >Picked Up</CButton>
                            </CBox>}

                            <CBox>
                                {orderStatusFilter === '' &&
                                    <CBox sx={{height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton
                                    sx={{
                                        fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                        fontWeight: "400",
                                        padding: 0,
                                        color: orderStatusFilter === '' ? "white" : "#757575"
                                    }}

                                    onClick={() => {
                                        setOrderStatusFilter("")
                                        clearRefreshInterval()
                                    }}
                                    size={headerButtonsSize}


                                >All</CButton>
                            </CBox>
                            {!(isSmallScreen || isExtraSmallScreen) && <CBox>
                                {orderStatusFilter === 'summary' &&
                                    <CBox sx={{height: "7px", borderRadius: "4px", backgroundColor: "white"}}>

                                    </CBox>}
                                <CButton sx={{
                                    fontSize: {xs: '12px', sm: '12px', md: "20px", lg: "20px", xl: "20px"},
                                    color: orderStatusFilter === 'summary' ? "white" : "#757575",
                                    fontWeight: "400",
                                    padding: 0,
                                }}
                                         onClick={() => {
                                             setOrderStatusFilter("summary")
                                             clearRefreshInterval()
                                         }}
                                         size={headerButtonsSize}

                                >Summary</CButton>
                            </CBox>}
                            {(isSmallScreen || isExtraSmallScreen) && <CBox sx={{flex: 1}}></CBox>}
                            <CIconButton sx={{mx: 1, mr: 3, mb: .5}} onClick={() => {
                                clearRefreshInterval()
                                refresh()
                                startCountDownInterval()
                                startRefreshInterval()
                            }}>
                                {/*<Menu fontSize={'large'} sx={{color: 'white'}}></Menu>*/}
                                {isRefreshing ? <CCircularProgress
                                    sx={{ color: "white" }}
                                    size={24} /> : <RefreshOutlined fontSize={'medium'} sx={{color: 'white'}}></RefreshOutlined>}
                            </CIconButton>
                            <CBox sx={{width: 24, zIndex: 999, color: 'white', height: '30px'}}>
                                {countDown}
                            </CBox>
                        </CGrid>
                        <CGrid item xs={0} md={2} sx={{
                            display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex'},
                            alignItems: "center",
                            justifyContent: "center",
                            height: 90,
                            color: "white"
                        }}>

                            <MomentTimer/>
                        </CGrid>
                    </CGridContainer>
                    {orderStatusFilter !== 'summary' ?
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CGridContainer sx={{
                                flex: 1,
                                display: "flex",
                                padding: 2,
                                height: '88vh'
                            }}>
                                <CGrid item xs={12} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    flexDirection: "column"
                                }}>
                                    {orderStatusFilter === '' && <CCard sx={{
                                        // height: "117px",
                                        mb: (isSmallScreen || isExtraSmallScreen) ? 2 : 2,
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <CCardContent sx={{width: '100%'}}>
                                            <CGridContainer fullWidth spacing={2}>
                                                <CGrid item xs={10} md={1.5}>
                                                    <CFormControl fullWidth>
                                                        <COutlinedInput
                                                            fullWidth
                                                            placeholder={"Order No ..."}
                                                            sx={{borderRadius: 32}}
                                                            type={"number"}
                                                            size={textBoxSize}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                setOrderNoFilter(event.target.value)
                                                            }} value={orderNoFilter}
                                                        />
                                                    </CFormControl>
                                                </CGrid>
                                                <CGrid item xs={2} md={0.4}>
                                                    <CIconButton
                                                        onClick={() => {
                                                            refresh()
                                                        }}
                                                    >
                                                        <Search color={'primary'}/>
                                                    </CIconButton>
                                                </CGrid>
                                                {/*<CGrid item xs={10} md={1.5}>*/}
                                                {/*    <CFormControl fullWidth>*/}
                                                {/*        <COutlinedInput*/}
                                                {/*            fullWidth*/}
                                                {/*            placeholder={"Customer Name"}*/}
                                                {/*            sx={{borderRadius: 32}}*/}
                                                {/*            size={textBoxSize}*/}
                                                {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                                {/*                setOrderCustomerFullNameFilter(event.target.value)*/}
                                                {/*            }} value={orderCustomerFullNameFilter}*/}
                                                {/*        />*/}
                                                {/*    </CFormControl>*/}
                                                {/*</CGrid>*/}
                                                {/*<CGrid item xs={2} md={0.4}>*/}
                                                {/*    <CIconButton*/}
                                                {/*        onClick={() => {*/}
                                                {/*            refresh()*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        <Search color={'primary'}/>*/}
                                                {/*    </CIconButton>*/}
                                                {/*</CGrid>*/}
                                                {/*<CGrid item xs={10} md={1}>*/}
                                                {/*    <CFormControl fullWidth>*/}
                                                {/*        <COutlinedInput*/}
                                                {/*            fullWidth*/}
                                                {/*            placeholder={"Card No"}*/}
                                                {/*            sx={{borderRadius: 32}}*/}
                                                {/*            size={textBoxSize}*/}
                                                {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                                {/*                setOrderCardNoFilter(event.target.value)*/}
                                                {/*            }} value={orderCardNoFilter}*/}
                                                {/*        />*/}
                                                {/*    </CFormControl>*/}
                                                {/*</CGrid>*/}
                                                {/*<CGrid item xs={2} md={0.4}>*/}
                                                {/*    <CIconButton*/}
                                                {/*        onClick={() => {*/}
                                                {/*            refresh()*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        <Search color={'primary'}/>*/}
                                                {/*    </CIconButton>*/}
                                                {/*</CGrid>*/}
                                                {/*<CGrid item xs={10} md={1.5}>*/}
                                                {/*    <CFormControl fullWidth>*/}
                                                {/*        <COutlinedInput*/}
                                                {/*            fullWidth*/}
                                                {/*            placeholder={"Billing Name"}*/}
                                                {/*            sx={{borderRadius: 32}}*/}
                                                {/*            size={textBoxSize}*/}
                                                {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                                {/*                setOrderBillingNameFilter(event.target.value)*/}
                                                {/*            }} value={orderBillingNameFilter}*/}
                                                {/*        />*/}
                                                {/*    </CFormControl>*/}
                                                {/*</CGrid>*/}
                                                {/*<CGrid item xs={2} md={0.4}>*/}
                                                {/*    <CIconButton*/}
                                                {/*        onClick={() => {*/}
                                                {/*            refresh()*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        <Search color={'primary'}/>*/}
                                                {/*    </CIconButton>*/}
                                                {/*</CGrid>*/}
                                                <CGrid item xs={6} md={1.4}>
                                                    <CFormControl fullWidth>

                                                        <MobileDatePicker
                                                            label="From Date"
                                                            inputFormat="MM/DD/YYYY"
                                                            value={fromDate}
                                                            onChange={(newValue: Dayjs | null) => {
                                                                setFromDate(newValue?.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0))
                                                            }}
                                                            renderInput={(params) =>
                                                                <CTextFieldRoundedCorners {...params} size={textBoxSize}
                                                                />}
                                                        />
                                                    </CFormControl>
                                                </CGrid>
                                                <CGrid item xs={6} md={1.4}>
                                                    <CFormControl fullWidth>

                                                        <MobileDatePicker
                                                            label="To Date"
                                                            inputFormat="MM/DD/YYYY"
                                                            value={toDate}
                                                            onChange={(newValue: Dayjs | null) => {
                                                                setToDate(newValue?.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999))
                                                            }}
                                                            renderInput={(params) =>
                                                                <CTextFieldRoundedCorners {...params} size={textBoxSize}
                                                                />}
                                                        />
                                                    </CFormControl>
                                                </CGrid>
                                                <CGrid item xs={6} md={1}>
                                                    <CButton fullWidth variant={"contained"} onClick={async () => {
                                                        refresh()
                                                    }} startIcon={<CalendarMonth/>}>
                                                        Show
                                                    </CButton>
                                                </CGrid>
                                                <CGrid item xs={6} md={1}>
                                                    <CButton fullWidth variant={"contained"}
                                                             onClick={() => {
                                                                 setOrderNoFilter("")
                                                                 setOrderCardNoFilter("")
                                                                 setOrderCustomerFullNameFilter("")
                                                                 setOrderBillingNameFilter("")
                                                                 setFromDate(null)
                                                                 setToDate(null)
                                                                 setOrderStatusFilter("")
                                                                 setTimeout(refresh,150)
                                                             }}
                                                             startIcon={<RestartAltOutlined/>}>
                                                        Clear
                                                    </CButton>
                                                </CGrid>
                                            </CGridContainer>

                                        </CCardContent>

                                    </CCard>}
                                    <CCard sx={{
                                        flex: 1,
                                        width: "100%",
                                        '& .pos-table-header': {
                                            fontSize: "16px",
                                            fontWeight: "400"
                                        },
                                        '& .pos-table-header-first': {
                                            ml: "20px"
                                        },
                                        '& .pos-table-cell': {
                                            color: "#363636"
                                        },
                                        '& .pos-table-cell-first': {
                                            color: "#000000",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            ml: "24px"
                                        },
                                    }}>
                                        <OrdersTable
                                            handleClickOpen={handleClickOpen}
                                            handleCustomerDetailsClickOpen={handleCustomerDetailsClickOpen}
                                            orderNoFilter={orderNoFilter}
                                            orderCustomerFullNameFilter={orderCustomerFullNameFilter}
                                            orderCardNoFilter={orderCardNoFilter}
                                            orderBillingNameFilter={orderBillingNameFilter}
                                            orderStatusFilter={orderStatusFilter}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            module={'lockbox'}
                                            ref={ordersTableRef}
                                            autoRefresh={false}
                                            onRefreshingChange={(val)=>setIsRefreshing(val)}
                                        />
                                    </CCard>
                                </CGrid>
                            </CGridContainer>
                        </LocalizationProvider>
                        :
                        <SummaryScreenContent module={'lockbox'}/>
                    }

                </main>

                <CConfirmationDialog
                    text={'Are you sure you want to logout?'}
                    onAccept={() => {
                        authStore.removeLockboxAuth()
                        navigate('/system-login')
                        setIsDrawerOpen(false)
                        setShowLogoutConfirmDialog(false)
                    }}
                    acceptTitle={'Logout'}
                    declineTitle={'Cancel'}
                    onDecline={()=>setShowLogoutConfirmDialog(false)}
                    open={showLogoutConfirmDialog}/>

                <OrderDialog
                    orderDialog={orderDialog}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                    handleSaveChanges={handleSaveChanges}
                    orderDetailRows={orderDetailRows}
                    validation={validation}
                    setOrderDialog={setOrderDialog}
                    setValidation={setValidation}
                    orderPayments={orderPayments}
                    module={'lockbox'}
                    handlePaymentDialogOpen={()=>{console.log('ignore')}}
                />
                <CustomerDialog
                    isOpen={isCustomerDialogOpen}
                    onClose={() => {
                        setIsCustomerDialogOpen(false)
                    }}
                    id={customerDialogData.id}
                    customerType={customerDialogData.customerType}
                    authToken={currentAuthToken}
                    module={'lockbox'}
                />

            </>
        );
    }
)
