import * as React from "react";
import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {
    CartItem,
    CBox,
    CButton,
    CCancelOrderConfirmationDialog,
    CCard,
    CCardContent,
    CCircularProgress,
    CContainer,
    CGrid,
    CGridContainer,
    CIconButton,
    CSnackbar,
    CTextField,
    CTypography,
    DeliveryMethodDialog,
    DeliveryMethodSelector,
    DisclaimerDialog,
    Divider,
    Footer,
    ForgotPasswordDialog,
    LoginDialog,
    POSTitle,
    SignupDialog,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import {AppRegistration, ArrowBackIosNew, ClearAll, Login, ShoppingCartCheckout,} from "@mui/icons-material";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {hasValue} from "../../../utils/empty-check";
import {isEmpty} from "validate.js";
import {CheckoutBreadcrumb} from "../../../components/checkout-breadcrumb";
import {CartSku} from "../../../models/cart-sku/cart-sku";
import {getConfig} from "../../../utils/config";
import ReactGA from "react-ga4";
import {getSkuPrice} from "../../../utils/sku";
import {
    calculateBundlePrice,
    calculateFreeItems,
    checkForDeletedBundles,
    isEligibleByBundle
} from "../../../utils/cart";
import {BasicBundle} from "../../../models/basic-bundle/basic-bundle";

let currentAuthToken = ''

interface CartContentProps {
    onItemsAmountChange: () => void
}

const CartContent = observer((props: CartContentProps) => {

    const {onItemsAmountChange} = props

    const navigate = useNavigate();

    const {cartStore, locationStore} = useStores()
    const {cart} = cartStore
    let cartTotal = 0.00

    if (cart) {
        const currentCartSkus = [...cart.cartSkus]
        if (currentCartSkus.length > 0) {
            currentCartSkus.forEach(cartSku => {
                cartTotal += (cartSku.cartSkuAmount - cartSku.cartSkuFreeItemsAmount) * Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)
            })
        }
    }

    const addSku = (inputSku) => {
        let currentCartSkus: CartSku[] = []
        if (cart) {
            // @ts-ignore
            currentCartSkus = [...cart.cartSkus]
        }
        // @ts-ignore
        const index = currentCartSkus.findIndex(cartSku => inputSku.skuId === cartSku.cartSkuSku.skuId)

        if (index > -1) {
            if (currentCartSkus[index]?.cartSkuAmount + 1 <= 10) {
                const {
                    freeSkuCount,
                    bundleId
                } = calculateFreeItems(locationStore.currentLocation, cartStore.cart, 1, currentCartSkus[index]?.cartSkuParent)
                console.log('addSku.calculateFreeItems', freeSkuCount, bundleId)
                cartStore.increaseSkuNumber(index)

                onItemsAmountChange()
            }
        }
    }

    const removeSku = (inputSku, inputParentSku) => {
        let currentCartSkus: CartSku[] = []
        if (cart) {
            // @ts-ignore
            currentCartSkus = [...cart.cartSkus]
        }
        // @ts-ignore
        const index = currentCartSkus.findIndex(cartSku => inputSku.skuId === cartSku.cartSkuSku.skuId)
        if (index > -1) {
            // @ts-ignore
            if (currentCartSkus[index].cartSkuAmount === 1) {
                cartStore.removeSkuFromCart(index)
            } else {
                cartStore.decreaseSkuNumber(index)
            }

            onItemsAmountChange()

            ReactGA.event("remove_from_cart", {
                currency: "USD",
                value: getSkuPrice(inputParentSku.prices, locationStore.currentLocation?.locationId)?.priceAmount,
                items: [
                    {
                        item_id: inputSku.skuId,
                        item_name: inputSku.skuHasParent ? inputSku.skuCode + ' - ' + inputSku.vendor?.vendorName + ' - ' + inputSku.parentSku?.skuTitle + ' - ' + inputSku.skuTitle : inputSku.vendor?.vendorName + ' - ' + inputSku.skuTitle,
                        price: Number(getSkuPrice(inputParentSku.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                        quantity: 1
                    }
                ]
            })

            console.log("ReactGA", "StoreCartScreen", "remove_from_cart", {
                currency: "USD",
                value: getSkuPrice(inputParentSku.prices, locationStore.currentLocation?.locationId)?.priceAmount,
                items: [
                    {
                        item_id: inputSku.skuId,
                        item_name: inputSku.skuHasParent ? inputSku.skuCode + ' - ' + inputSku.vendor?.vendorName + ' - ' + inputSku.parentSku?.skuTitle + ' - ' + inputSku.skuTitle : inputSku.vendor?.vendorName + ' - ' + inputSku.skuTitle,
                        price: Number(getSkuPrice(inputParentSku.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                        quantity: 1
                    }
                ]
            })
        }
    }

    return <>
        <CBox sx={{
            display: "flex", flexDirection: "row", width: '100%',
            py: '22px'
        }}>
            <CIconButton
                sx={{width: '64px', ml: '22px'}}
                // onClick={() => navigate(-1)}
                onClick={() => navigate('/')}

            >
                <ArrowBackIosNew></ArrowBackIosNew>
            </CIconButton>
            <POSTitle sx={{
                mb: 2,
                mt: 2,
                flex: 1,
                textAlign: "center",
                fontFamily: "Passion One"
            }}>
                Cart
            </POSTitle>
            <CBox sx={{width: '64px', mr: '22px'}}></CBox>
        </CBox>

        <Divider/>
        {cart && cart.cartSkus.length > 0 ? <CBox sx={{flex: 1, width: "100%", overflowY: "scroll"}}>
                {
                    cart && cart.cartSkus.map((sku, index) => <CartItem
                        index={`${sku.cartSkuSku.skuId}${index}_cart_item_sku`}
                        title={`${sku.cartSkuParent?.skuTitle} (${sku.cartSkuSku.skuCode})`}
                        subtitle={`${sku.cartSkuSku.skuTitle}`}
                        quantity={Number(sku.cartSkuAmount)}
                        price={`$${getSkuPrice(sku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount?.toFixed(2)}`}
                        sum={`$${((sku.cartSkuAmount) * Number(getSkuPrice(sku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)).toFixed(2)}`}
                        discountSum={`$${((sku.cartSkuAmount - sku.cartSkuFreeItemsAmount) * Number(getSkuPrice(sku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)).toFixed(2)}`}
                        hideDivider={cart.cartSkus.length === index + 1}
                        onRemoveClick={() => removeSku(sku.cartSkuSku, sku.cartSkuParent)}
                        onAddClick={() => addSku(sku.cartSkuSku)}
                    />)
                }
            </CBox> :
            <CBox sx={{flex: 1, width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                <CTypography sx={{opacity: 0.5, my: 5}}>
                    No cart items yet ...
                </CTypography>
            </CBox>

        }
    </>
})

const CheckoutContent = observer((props) => {

    const navigate = useNavigate();

    const {
        cartStore,
        customerProfileStore,
        locationStore,
        authStore,
        configStore,
        storeItemAvailabilityStore
    } = useStores()
    const {cart} = cartStore
    const {configs} = configStore
    const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)
    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false)
    const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = useState(false)
    const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(false)
    const [validationMessage, setValidationMessage] = useState<string | null>(null)
    const [messageSnackbar, setMessageSnackbar] = useState({
        isOpen: false,
        message: ''
    })
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)

    let cartScreenStrings = getConfig(configs, 'cart-screen-strings')

    if (cartScreenStrings) {
        cartScreenStrings = JSON.parse(cartScreenStrings)
    }

    let cartTotal = 0.00
    let cartTotalSku = 0.00
    let cartTotalSkuDiscount = 0.00
    let locationFee = 0.00
    let locationDeliveryFee = 0.00
    let taxUSD = 0.00

    if (cart) {
        const currentCartSkus = [...cart.cartSkus]
        if (currentCartSkus.length > 0) {
            currentCartSkus.forEach(cartSku => {
                cartTotal += (cartSku.cartSkuAmount - cartSku.cartSkuFreeItemsAmount) * Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)
                cartTotalSku += (cartSku.cartSkuAmount) * Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)
                cartTotalSkuDiscount = cartTotal
            })

            let cartTaxMultiplier = 0
            const locationTaxPercent = locationStore.currentLocation?.locationSetting?.locationSettingTaxPercent
            // console.log("locationTaxPercent", locationTaxPercent);
            if (locationTaxPercent && locationTaxPercent > 0) {
                cartTaxMultiplier = locationTaxPercent / 100
            }
            if (cartTaxMultiplier && cartTaxMultiplier > 0) {
                taxUSD = (cartTaxMultiplier * cartTotal)
                cartTotal += taxUSD
            }

            if (locationStore.currentLocation?.locationSetting?.locationSettingFee) {
                locationFee = locationStore.currentLocation?.locationSetting?.locationSettingFee
            }
            if (locationFee) {
                cartTotal += locationFee
            }

            // if (cart.cartDeliveryMethod === 'local-delivery') {
            if (locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee) {
                locationDeliveryFee = locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee
            }
            if (locationDeliveryFee) {
                cartTotal += locationDeliveryFee
            }
            // } else {
            //     locationDeliveryFee = 0.00
            // }


        }
    }
    //
    // const addSku = (inputSku) => {
    //     let currentCartSkus = []
    //     if (cart) {
    //         // @ts-ignore
    //         currentCartSkus = [...cart.cartSkus]
    //     }
    //     // @ts-ignore
    //     const index = currentCartSkus.findIndex(cartSku => inputSku.skuId === cartSku.cartSkuSku.skuId)
    //     if (index > -1) {
    //         cartStore.increaseSkuNumber(index)
    //     } else {
    //         cartStore.addSkuToCart({...inputSku})
    //     }
    // }
    //
    // const removeSku = (inputSku) => {
    //     let currentCartSkus = []
    //     if (cart) {
    //         // @ts-ignore
    //         currentCartSkus = [...cart.cartSkus]
    //     }
    //     // @ts-ignore
    //     const index = currentCartSkus.findIndex(cartSku => inputSku.skuId === cartSku.cartSkuSku.skuId)
    //     if (index > -1) {
    //         // @ts-ignore
    //         if (currentCartSkus[index].cartSkuAmount === 1) {
    //             cartStore.removeSkuFromCart(index)
    //         } else {
    //             cartStore.decreaseSkuNumber(index)
    //         }
    //     }
    // }
    //
    // const checkForCustomerAuth = async () => {
    //     const isCustomerAuthAvailable = await authStore.isAuthAvailable(currentDomain(), "Customer")
    //     if (isCustomerAuthAvailable) {
    //         await authStore.loadAuth(currentDomain(), "Customer")
    //         navigate(`/store/information`)
    //     } else {
    //         navigate(`/store/continue-as`)
    //     }
    // }

    function generateUniqueString() {
        // Get current timestamp
        const timestamp = Date.now();

        // Generate a random string
        const randomString = Math.random().toString(36).substring(2, 10);

        // Concatenate timestamp and random string
        return `${timestamp}_${randomString}`;
    }

    const handleCheckoutClick = async () => {
        let isValid = true
        let currentMessage = ''
        setIsCheckoutLoading(true)
        setValidationMessage(currentMessage)
        if (cartStore.cart?.cartDeliveryMethod === "lockbox") {
            if (cartStore.cart?.cartLockboxId === null || cartStore.cart?.cartLockboxId < 0) {
                isValid = false
                currentMessage += 'Please select the lockbox.'
            }
            if (isEmpty(cartStore.cart?.cartPickupDate)) {
                isValid = false
                currentMessage += 'Please select the pick up date & time.'
            }
            // if (isEmpty(cartStore.cart?.cartPickupTime)) {
            //     isValid = false
            //     currentMessage += 'Please select the pick up time.'
            // }
        } else if (cartStore.cart?.cartDeliveryMethod === "local-delivery") {
            if (isEmpty(cartStore.cart?.cartDeliveryFullAddress)) {
                isValid = false
                currentMessage += 'Please search and select the delivery address.'
            }
        } else {
            isValid = false
            currentMessage += 'Please select a delivery method.'
        }

        if (cartStore.cart?.cartSkus) {
            const cart = cartStore.cart
            const cartSkus = cart?.cartSkus
            for (let i = 0; i < cartSkus.length; i++) {
                const sku = cartSkus[i].cartSkuSku
                // const count = await cartStore.skuCount(sku.skuId, Number(cart.cartLockboxId), currentAuthToken)
                const result = await storeItemAvailabilityStore.findOne({availabilityId: Number(`${sku.skuId}${cartStore.cart?.cartLockboxId}`)}, currentAuthToken)
                const count = result.availability && result.availability.availabilityCount ? result.availability.availabilityCount : 0
                console.log('handleCheckoutClick', 'result', result)

                // const count = await cartStore.skuCount(sku.skuId, Number(cart.cartLockboxId), currentAuthToken)
                console.log('handleCheckoutClick', 'id', `${sku.skuId}${cartStore.cart?.cartLockboxId}`)
                console.log('handleCheckoutClick', 'count', count)
                console.log('handleCheckoutClick', 'cartSkuAmount', cartSkus[i].cartSkuAmount)
                console.log('handleCheckoutClick', 'skuId', sku.skuId)
                if (count < cartSkus[i].cartSkuAmount) {
                    isValid = false
                    currentMessage += `The quantity of ${sku.skuTitle} is not available. Please remove a few from the cart and try again.`
                }
            }
        }


        if (isValid) {
            cartStore.saveCart({
                ...cartStore.cart,
                cartTax: taxUSD,
                cartFee: locationFee,
                cartDeliveryFee: locationDeliveryFee
            })

            const gaItems: any[] = []

            if (cartStore.cart?.cartSkus) {
                cartStore.cart?.cartSkus.forEach(cartSku => {
                    gaItems.push({
                        item_id: cartSku.cartSkuSku.skuId,
                        item_name: cartSku.cartSkuSku?.skuCode + ' - ' + cartSku.cartSkuVendor?.vendorName + ' - ' + cartSku.cartSkuParent?.skuTitle + ' - ' + cartSku.cartSkuSku?.skuTitle,
                        price: getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount || 0,
                        quantity: cartSku.cartSkuAmount,
                        item_brand: cartSku.cartSkuVendor?.vendorName,
                    })
                })
            }

            console.log('ReactGA', 'begin_checkout', gaItems)

            ReactGA.event("begin_checkout", {
                items: gaItems
            })
            const isGuest = authStore.auth?.role?.roleName === "Guest"

            customerProfileStore.saveCustomerProfile(null)
            cartStore.saveCart({
                ...cartStore.cart,
                cartHash: generateUniqueString(),
                useCustomerProfile: false,
                customerProfileId: null,
                customerPaymentProfileId: null,
                savePaymentInfo: false
            })

            if (!isGuest) {
                await customerProfileStore.authorize(authStore.auth?.userId ?? -1, currentAuthToken)
                if (hasValue(customerProfileStore.customerProfile)) {
                    cartStore.saveCart({
                        ...cartStore.cart,
                        cartHash: generateUniqueString(),
                        useCustomerProfile: hasValue(customerProfileStore.customerProfile),
                        customerProfileId: customerProfileStore.customerProfile?.customerProfileId,
                        customerPaymentProfileId: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.customerPaymentProfileId,
                        cartInfoFirstName: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.firstName,
                        cartInfoLastName: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.lastName,
                        cartInfoAddress1: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.address,
                        cartInfoCity: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.city,
                        cartInfoState: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.state,
                        cartInfoZip: customerProfileStore.customerProfile?.paymentProfiles?.[0]?.billTo?.zip,
                    })
                }
            }

            console.log('cartHash', cartStore.cart?.cartHash)

            navigate(`/store/information`)
            /*
            const isGuest = authStore.auth?.role?.roleName === "Guest"

            if (isGuest) {
                await checkForCustomerAuth()
            } else {
                const isCustomer = authStore.auth?.role?.roleName === "Customer"
                if (isCustomer) {
                    navigate(`/store/information`)
                } else {
                    await checkForCustomerAuth()
                }
            }
            /// If logged in => pass the information and go to pre-filled information screen
            /// If not logged in => go to continue as screen

             */
        } else {
            setValidationMessage(currentMessage)
        }
        setIsCheckoutLoading(false)
    }

    const isCustomerAuthAvailable = authStore.auth?.role?.roleName === "Customer"

    return <>
        <CBox sx={{display: "flex", flexDirection: "column", width: "100%", mt: 2, mb: 1}}>
            <CBox sx={{display: "flex", flexDirection: "row", px: "23px"}}>
                <CTypography>
                    {/* @ts-ignore*/}
                    {cartScreenStrings?.sum ? cartScreenStrings?.sum : 'Sum'}
                </CTypography>
                <CBox sx={{flex: 1}}/>
                {cartTotalSkuDiscount && cartTotalSkuDiscount !== cartTotalSku &&
                    <CTypography sx={{textDecoration: "line-through", mr: 2, color: "text.disabled"}}>
                        ${cartTotalSku.toFixed(2)}
                    </CTypography>}
                <CTypography>
                    ${cartTotalSkuDiscount.toFixed(2)}
                </CTypography>
            </CBox>

        </CBox>
        <CBox sx={{display: "flex", flexDirection: "column", width: "100%", my: 1}}>
            <CBox sx={{display: "flex", flexDirection: "row", px: "23px"}}>
                <CTypography>
                    {/* @ts-ignore*/}
                    {cartScreenStrings?.deliveryFee ? cartScreenStrings?.deliveryFee : 'Delivery Fee'}
                </CTypography>
                <CBox sx={{flex: 1}}/>
                <CTypography>
                    ${locationDeliveryFee.toFixed(2)}
                </CTypography>
            </CBox>
        </CBox>
        <CBox sx={{display: "flex", flexDirection: "column", width: "100%", my: 1}}>
            <CBox sx={{display: "flex", flexDirection: "row", px: "23px"}}>
                <CTypography>
                    {/* @ts-ignore*/}
                    {cartScreenStrings?.fee ? cartScreenStrings?.fee : 'Fee'}
                </CTypography>
                <CBox sx={{flex: 1}}/>
                <CTypography>
                    ${locationFee.toFixed(2)}
                </CTypography>
            </CBox>

        </CBox>
        <CBox sx={{display: "flex", flexDirection: "column", width: "100%", my: 1}}>
            <CBox sx={{display: "flex", flexDirection: "row", px: "23px"}}>
                <CTypography>
                    {/* @ts-ignore*/}
                    {cartScreenStrings?.tax ? cartScreenStrings?.tax : 'Tax'}
                </CTypography>
                <CBox sx={{flex: 1}}/>
                <CTypography>
                    ${taxUSD.toFixed(2)}
                </CTypography>
            </CBox>

        </CBox>
        <CBox sx={{display: "flex", flexDirection: "column", width: "100%", my: 1}}>
            <CBox sx={{display: "flex", flexDirection: "row", px: "23px"}}>
                <CTypography fontWeight={"600"}>
                    {/* @ts-ignore*/}
                    {cartScreenStrings?.total ? cartScreenStrings?.total : 'Total'}
                </CTypography>
                <CBox sx={{flex: 1}}/>
                <CTypography fontWeight={"600"}>
                    ${cartTotal.toFixed(2)}
                </CTypography>
            </CBox>

        </CBox>

        <CBox sx={{
            display: "flex",
            flexDirection: {
                xs: 'column',
                // md: 'row'
            },
            width: "100%",
            my: 2,
            justifyContent: 'flex-end'
        }}>
            {hasValue(validationMessage) && <CTypography mx={3} textAlign={'center'} color={'red'} variant={'caption'}>
                {validationMessage}
            </CTypography>
            }
            <CButton sx={{
                mx: {
                    xs: '23px',
                    // md: '8px'
                },
                ml: {
                    xs: '23px',
                    // md: 0
                },
                mt: {
                    xs: 2,
                    // md: 0
                },
                minWidth: '200px'
            }}
                     variant={"contained"} disabled={!cart || cart.cartSkus.length === 0 || isCheckoutLoading}
                     onClick={handleCheckoutClick}
                     startIcon={!isCheckoutLoading && <ShoppingCartCheckout></ShoppingCartCheckout>}>
                {isCheckoutLoading ? <CCircularProgress sx={{color: "white"}}
                                                        size={24}/> : 'Checkout'}

            </CButton>
            {(cartStore.cart && cartStore.cart.cartSkus.length > 0) &&
                <CButton sx={{
                    mx: {
                        xs: '23px',
                        // md: '8px'
                    },
                    mt: {
                        xs: 2,
                        // md: 0
                    }
                }} variant={"outlined"}
                         onClick={() => {
                             if (window.confirm("Are you sure you want to clear the cart?")) {
                                 cartStore.clearCartSkus()
                             }
                         }} startIcon={<ClearAll/>}>
                    Clear Cart
                </CButton>}
            {!isCustomerAuthAvailable &&
                <CButton sx={{
                    mx: {
                        xs: '23px',
                        // md: '8px'
                    },
                    mt: {
                        xs: 2,
                        // md: 0
                    }
                }} variant={"outlined"}
                         onClick={() => setIsLoginDialogOpen(true)} startIcon={<Login></Login>}>
                    Login
                </CButton>}
            {!isCustomerAuthAvailable &&
                <CButton sx={{
                    mx: {
                        xs: '23px',
                        // md: '8px'
                    },
                    mr: {
                        xs: '23px',
                        // md: '23px'
                    },
                    mt: {
                        xs: 2,
                        // md: 0
                    }
                }}
                         variant={"outlined"}
                         onClick={() => setIsSignupDialogOpen(true)} startIcon={<AppRegistration></AppRegistration>}>
                    Signup
                </CButton>}
        </CBox>
        <CCancelOrderConfirmationDialog
            mode={'store'}
            open={showCancelOrderDialog}
            onDecline={() => {
                setShowCancelOrderDialog(false)
            }}
        />
        <ForgotPasswordDialog isOpen={isForgotPasswordDialogOpen}
                              onClose={() => {
                                  setIsForgotPasswordDialogOpen(false)
                              }}
                              onSubmit={() => {
                                  setIsForgotPasswordDialogOpen(false)
                              }}/>
        <LoginDialog isOpen={isLoginDialogOpen} onLogin={() => {
            // props.onLogin()
            setIsLoginDialogOpen(false)
        }}
                     onForgotPassword={() => {
                         setIsLoginDialogOpen(false)
                         setIsForgotPasswordDialogOpen(true)
                     }}
                     onClose={() => setIsLoginDialogOpen(false)}/>
        <SignupDialog
            isOpen={isSignupDialogOpen}
            onSignup={() => {
                // props.onLogin()
                setIsSignupDialogOpen(false)
            }}
            onClose={() => setIsSignupDialogOpen(false)}
            onMessage={(message) => setMessageSnackbar({
                isOpen: true,
                message: message
            })}
        />
        <CSnackbar open={messageSnackbar.isOpen} autoHideDuration={3000} onClose={() => {
            setMessageSnackbar({
                isOpen: false,
                message: ""
            })
        }} message={messageSnackbar.message} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}/>
    </>
})

export const StoreCart = observer(
    function StoreCart() {
        const navigate = useNavigate();

        const {skuStore, categoryStore, cartStore, locationStore} = useStores()
        const {cart} = cartStore

        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, locationInfoState, guestAuthState} = guestAuth
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const [eligibleBundle, setEligibleBundle] = React.useState<BasicBundle | null>(null)
        const [isUserEligible, setIsUserEligible] = React.useState<boolean>(false)

        const getBestBundle = (): BasicBundle | null => {
            if (!cart || !cart.cartSkus) {
                return null
            }

            const currentBundles = checkForDeletedBundles(locationInfo)
            const bundlePrices: {
                bundle: BasicBundle,
                price: number
            }[] = []

            console.log('bundles.getBestBundle.currentBundles', currentBundles)

            if (!currentBundles || currentBundles.length === 0) {
                return null
            }

            currentBundles.forEach(bundle => {
                if (isEligibleByBundle(cart.cartSkus, bundle).isEligible) {
                    console.log('bundles.getBestBundle.bundlePrices.push')
                    bundlePrices.push({
                        bundle: bundle,
                        price: calculateBundlePrice(cart.cartSkus, bundle, locationInfo)
                    })
                }
            })

            console.log('bundles.getBestBundle.bundlePrices', bundlePrices)

            if (!bundlePrices || bundlePrices.length === 0) {
                return null
            }

            const cheapestBundlePrice = bundlePrices.reduce((minItem, currentItem) => {
                if (currentItem.price < minItem.price) {
                    return currentItem;
                } else {
                    return minItem;
                }
            });

            console.log('bundles.getBestBundle.cheapestBundlePrice', cheapestBundlePrice)

            return cheapestBundlePrice.bundle
        }

        const checkAndApplyBundle = () => {
            if (!cart || !cart.cartSkus) {
                return
            }
            const bestBundle = getBestBundle()
            console.log('bundles.checkAndApplyBundle.bestBundle', bestBundle)
            if (bestBundle) {
                const {eligibilityStatus} = isEligibleByBundle(cart.cartSkus, bestBundle)
                if (eligibilityStatus === 'eligible') {
                    cartStore.applyBundle(bestBundle, locationInfo.locationId)
                } else if (eligibilityStatus === 'banner') {
                    cartStore.clearBundles()
                }
                setEligibleBundle(bestBundle)
                setIsUserEligible(true)
            } else {
                cartStore.clearBundles()
                if (eligibleBundle) {
                    setEligibleBundle(null)
                }
                if (isUserEligible) {
                    setIsUserEligible(false)
                }

            }


        }

        const init = async () => {
            await skuStore.findAll({skuHasParent: 0}, guestAuthToken, true)
            await categoryStore.findAll({categoryStatus: 'active'}, guestAuthToken)

            if (!cart) {
                return
            }

            if (!cart.cartSkus) {
                return
            }

            const gaItems: any[] = []

            cart.cartSkus.forEach(cartSku => {
                const inputSku = cartSku.cartSkuSku
                const inputParentSku = cartSku.cartSkuParent
                gaItems.push({
                    item_id: inputSku.skuId,
                    item_name: cartSku.cartSkuSku.skuHasParent ? cartSku.cartSkuSku.skuCode + ' - ' + cartSku.cartSkuVendor?.vendorName + ' - ' + cartSku.cartSkuParent?.skuTitle + ' - ' + cartSku.cartSkuSku.skuTitle : cartSku.cartSkuVendor?.vendorName + ' - ' + cartSku.cartSkuSku.skuTitle,
                    price: Number(getSkuPrice(inputParentSku?.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                    quantity: cartSku.cartSkuAmount
                })
            })
            checkAndApplyBundle()
            // console.log('GA', {
            //     items: gaItems
            // })
            ReactGA.event("view_cart", {
                items: gaItems
            })
            console.log("ReactGA", "StoreCartScreen", "view_cart", {
                items: gaItems
            })
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        useEffect(() => {
            ;(async () => {
                if (guestAuthState === 'loaded') {
                    await init()
                    currentAuthToken = guestAuthToken
                }
            })()
        }, [guestAuthToken, guestAuthState]);

        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Cart`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Cart | ${locationInfo.locationName}`;
                }
            })()

        }, [locationInfo, locationInfoState])


        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        return (
            <>

                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>


                        <CGridContainer sx={{
                            mt: 3,
                            display: 'flex',
                            zIndex: 100
                        }} spacing={2}>


                            <CGrid item xs={12}>
                                <CheckoutBreadcrumb allowedPages={['cart']} currentPage={'cart'}/>
                            </CGrid>
                            {isUserEligible && eligibleBundle && <CGrid item xs={12}>
                                <CCard variant={'outlined'} sx={{
                                    boxShadow: 'none',
                                    borderColor: '#388E3C',
                                    backgroundColor: '#DCEDC8',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    p: 3
                                }}>
                                    <CTypography sx={{color: '#388E3C', fontWeight: 'bold'}}>Congratulations! You are
                                        eligible for {eligibleBundle.basicBundleTitle}</CTypography>
                                    <CTypography sx={{color: '#388E3C', fontSize: '14px'}}>You can
                                        add {eligibleBundle.basicBundleFreeCount} items with a price up to
                                        ${getSkuPrice(eligibleBundle.basicBundleSku?.prices, locationInfo?.locationId)?.priceAmount} for
                                        free.</CTypography>

                                </CCard>
                            </CGrid>}
                            <CGrid item xs={12} md={8}>
                                <CCard sx={{
                                    width: "100%",
                                    // height: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1
                                }}>
                                    <CartContent onItemsAmountChange={() => {
                                        checkAndApplyBundle()
                                    }}/>
                                </CCard>
                            </CGrid>
                            <CGrid item xs={12} md={4}>
                                <CCard sx={{
                                    width: "100%",
                                    // height: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                    display: 'flex',
                                    flexDirection: 'column',
                                    zIndex: 1
                                }}>
                                    <CCardContent>
                                        <DeliveryMethodSelector isOpen={true}
                                                                autoSave={true}
                                                                onSelect={() => {
                                                                    // console.log(onSelect)
                                                                }}
                                                                onClose={() => {
                                                                    // console.log(onClose)
                                                                }}
                                        />
                                        <CTextField label={"Notes"}
                                                    fullWidth
                                                    type={"number"}
                                                    multiline={true}
                                                    rows={4}
                                                    sx={{mr: 2}}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        cartStore.saveCart({
                                                            ...cartStore.cart,
                                                            cartCustomerNote: event.target.value,
                                                            // cartSkus: []
                                                        })
                                                    }} value={cartStore.cart?.cartCustomerNote}/>
                                    </CCardContent>

                                </CCard>
                                <CCard sx={{
                                    mt: 2,
                                    width: "100%",
                                    // height: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1
                                }}>
                                    <CheckoutContent/>
                                </CCard>
                            </CGrid>
                        </CGridContainer>

                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );

    }
)
