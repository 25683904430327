import {TabPanel} from "../tab-panel";
import {CCard, CCardContent, CCircularProgress, CGrid, CGridContainer, CTypography} from "../mui-extracted";
import {Table} from "../table";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {getCustomerName} from "../../utils/customer";
import {convertToCurrentTimezone} from "../../utils/date-time";
import {useMediaQuery} from "@mui/material";

interface SummaryContentProps {
    tabValue: number,
    summaryDialogIsLoading: boolean,
    summaryDialogData: {
        totalSum: number,
        totalItems: number,
        totalGross: number,
        totalSales: number,
        totalPaidOrders: number,
        ordersList: any[],
        totalOrders: number,
    },
    paginationInfo: any,
    handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void,
    handlePageSizeChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    orderInfo: any,
    handleRequestSort: (event: React.MouseEvent<unknown>, property: any) => void,
    module: string
}

export const SummaryContent = observer(
    function SummaryContent(
        {
            tabValue,
            summaryDialogIsLoading,
            summaryDialogData,
            paginationInfo,
            handlePageChange,
            handlePageSizeChange,
            orderInfo,
            handleRequestSort,
            module
        }: SummaryContentProps) {

        // @ts-ignore
        const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
        // @ts-ignore
        const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const columns = [
        {
            field: 'customerName',
            headerName: 'Customer Name',
            valueGetter: (params) => {
                const {customer, user} = params.row
                return `${getCustomerName(customer, user)}`
            },
            width: 210,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderStatus',
            headerName: 'Status',
            width: 120,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'deliveryMethod',
            headerName: 'Method',
            valueGetter: (params) => {
                const {deliveryMethod} = params.row
                if (deliveryMethod === 'local-delivery') {
                    return `Delivery`

                } else {
                    return `Pickup`
                }
            },
            width: 100,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderCreatedAt',
            headerName: 'Created At',
            valueGetter: (params) => {
                const {orderCreatedAt} = params.row
                // console.log('orderCreatedAt', orderCreatedAt)
                return `${convertToCurrentTimezone(orderCreatedAt, 'MMM D, h:mm A')}`
            },
            width: 130,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderTotalItems',
            headerName: 'Items Count',
            width: 80,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderTotal',
            headerName: 'Total',
            valueGetter: (params) => {
                const {orderTotal} = params.row
                return `$${orderTotal?.toFixed(2)}`
            },
            width: 80,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },


    ];

    const tabHeight = module === 'lockbox' ? (isSmallScreen || isExtraSmallScreen) ? '52vh' : '70vh' : (isSmallScreen || isExtraSmallScreen) ? '64vh' : '83vh'
        const statisticsBorderRadius = (isSmallScreen || isExtraSmallScreen) ? 2 : 8

    return (
        <>
            <TabPanel value={tabValue} index={0} style={{height: tabHeight, overflowY: 'scroll'}}>
                {summaryDialogIsLoading ? <CCircularProgress sx={{m: 4}}/> :
                    <CGridContainer spacing={2} py={2} px={2}>
                        <CGrid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                            <CCard variant={'outlined'} sx={{boxShadow: 'none', borderRadius: statisticsBorderRadius, width: '100%'}}>
                                <CCardContent sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
                                    <CTypography variant={'h6'} flex={1}>Total Gross:</CTypography>
                                    <CTypography
                                        variant={'body1'}> ${summaryDialogData.totalGross?.toFixed(2)} </CTypography>
                                </CCardContent>
                            </CCard>
                        </CGrid>
                        <CGrid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                            <CCard variant={'outlined'} sx={{boxShadow: 'none', borderRadius: statisticsBorderRadius, width: '100%'}}>
                                <CCardContent sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
                                    <CTypography variant={'h6'} flex={1}>Total Sales:</CTypography>
                                    <CTypography
                                        variant={'body1'}> ${summaryDialogData.totalSales?.toFixed(2)} </CTypography>
                                </CCardContent>
                            </CCard>
                        </CGrid>
                        <CGrid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                            <CCard variant={'outlined'} sx={{boxShadow: 'none', borderRadius: statisticsBorderRadius, width: '100%'}}>
                                <CCardContent sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
                                    <CTypography variant={'h6'} flex={1}>Total Item Sold:</CTypography>
                                    <CTypography
                                        variant={'body1'}> {summaryDialogData.totalItems} Items </CTypography>
                                </CCardContent>
                            </CCard>
                        </CGrid>
                        <CGrid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                            <CCard variant={'outlined'} sx={{boxShadow: 'none', borderRadius: statisticsBorderRadius, width: '100%'}}>
                                <CCardContent sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
                                    <CTypography variant={'h6'} flex={1}>Total Paid Orders:</CTypography>
                                    <CTypography
                                        variant={'body1'}> {summaryDialogData.totalPaidOrders} Orders </CTypography>
                                </CCardContent>
                            </CCard>
                        </CGrid>
                    </CGridContainer>}
            </TabPanel>
            <TabPanel value={tabValue} index={1} style={{height: tabHeight, overflowY: 'scroll'}}>
                {summaryDialogIsLoading ? <CCircularProgress sx={{m: 4}}/> :
                    <Table
                        columns={columns}
                        rows={summaryDialogData.ordersList}
                        count={summaryDialogData.totalOrders}
                        page={paginationInfo.page}
                        rowsPerPage={paginationInfo.perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handlePageSizeChange}
                        orderBy={orderInfo.orderBy}
                        order={orderInfo.order}
                        onRequestSort={handleRequestSort}
                        isLoading={summaryDialogIsLoading}
                    />}
            </TabPanel>
        </>
    )
})