import {CartSku} from "../models/cart-sku/cart-sku";
import {BasicBundle} from "../models/basic-bundle/basic-bundle";
import {getSkuPrice} from "./sku";
import {isTodayBetweenDates} from "./date-time";

export const cartFreeItemsCount = (cartSkus: CartSku[]) => {
    let count = 0;

    cartSkus.forEach(cartSku=>{
        if (cartSku.cartSkuFreeItemsAmount) {
            count += cartSku.cartSkuFreeItemsAmount
        }
    })
    return count
}

export const checkForDeletedBundles = (locationInfo) => {
    const checkedBasicBundles: BasicBundle[] = []

    if (locationInfo.basicBundles && locationInfo.locationSetting.locationSettingBundlesEnabled === 1) {
        locationInfo.basicBundles.forEach(basicBundle => {
            console.log('bundles.checkForDeletedBundles', {
                basicBundle: basicBundle.toJSON(),
                isActive: basicBundle.basicBundleDeleted === 0 && basicBundle.basicBundleStatus === 'active' && isTodayBetweenDates(basicBundle.basicBundleValidFrom, basicBundle.basicBundleValidTo)
            })
            if (basicBundle.basicBundleDeleted === 0 && basicBundle.basicBundleStatus === 'active' && isTodayBetweenDates(basicBundle.basicBundleValidFrom, basicBundle.basicBundleValidTo)) {
                checkedBasicBundles.push(basicBundle)
            }
        })
    }
    return checkedBasicBundles
}

export const calculateBundlePrice = (cartSkus: CartSku[], bundle: BasicBundle, locationInfo): number => {
    try {

        console.log('bundles.calculateBundlePrice', "=========================================================================")

    let price = 0;
    let currentFreeCount = 0;
    const {basicBundleFreeCount, basicBundlePaidCount, basicBundleSkuId } = bundle;
    console.log('bundles.calculateBundlePrice', 1)
    cartSkus.forEach(cartSku => {
        // @ts-ignore
        console.log('bundles.calculateBundlePrice', 1.1, cartSku.cartSkuParent.skuTitle)
        if (cartSku.cartSkuParent) {
            const skuPrice = getSkuPrice(cartSku.cartSkuParent.prices, locationInfo.locationId)
            const bundleSkuPrice = getSkuPrice(bundle.basicBundleSku.prices, locationInfo.locationId)
            console.log('bundles.calculateBundlePrice', 2)
            if (skuPrice && bundleSkuPrice && basicBundleFreeCount && basicBundlePaidCount) {
                const skuPriceAmount = skuPrice.priceAmount
                const bundleSkuPriceAmount = bundleSkuPrice.priceAmount
                console.log('bundles.calculateBundlePrice', 3, skuPriceAmount, bundleSkuPriceAmount)
                if (skuPriceAmount && bundleSkuPriceAmount && skuPriceAmount <= bundleSkuPriceAmount) {
                    for (let i = 0; i < cartSku.cartSkuAmount; i++) {
                        if (currentFreeCount < basicBundleFreeCount) {
                            if (cartSku.cartSkuParent.skuId === basicBundleSkuId && cartSku.cartSkuAmount === basicBundlePaidCount) {
                                price += skuPriceAmount
                                console.log('bundles.calculateBundlePrice', 3.1, price)
                            } else {
                                currentFreeCount++
                                console.log('bundles.calculateBundlePrice', 3.2, currentFreeCount)
                            }
                        } else {
                            price += skuPriceAmount
                            console.log('bundles.calculateBundlePrice', 3.3, price)
                        }
                        console.log('bundles.calculateBundlePrice', 4)
                    }
                } else {
                    if (skuPriceAmount) {
                        price += cartSku.cartSkuAmount * skuPriceAmount
                    }
                }
            }
        }

    });
        console.log('bundles.calculateBundlePrice', 5, basicBundleSkuId, price)
        console.log('bundles.calculateBundlePrice', "//////////////////////////////////////////////////////////////////////")
        return price
    } catch (e) {
        console.log('bundles.calculateBundlePrice.e', e)
        console.log('bundles.calculateBundlePrice', "//////////////////////////////////////////////////////////////////////")
        return 0
    }


}

export const isEligibleByBundle = (cartSkus: CartSku[], bundle: BasicBundle): {
    isEligible: boolean,
    eligibilityStatus: string
} => {
    const { basicBundleSkuId, basicBundlePaidCount } = bundle;

    let isEligible = false;
    let eligibilityStatus = 'not-eligible';

    let matchingCartSkusAmount = 0

    cartSkus.forEach(cartSku => {
        if (cartSku.cartSkuParent?.skuId === basicBundleSkuId) {
            matchingCartSkusAmount += cartSku.cartSkuAmount
        }
    })

    if (basicBundlePaidCount && matchingCartSkusAmount >= basicBundlePaidCount ) {
        isEligible = true;
        if (matchingCartSkusAmount > basicBundlePaidCount) {
            eligibilityStatus = 'eligible';
        } else {
            eligibilityStatus = 'banner';
        }
    }

    return { isEligible, eligibilityStatus };
}

// Check if the user is eligible for a bundle
const isEligibleForBundle = (locationInfo, cart) => {

    if (locationInfo.locationSetting.locationSettingBundlesEnabled === 0) {
        return false
    }

     const cartSkus = cart.cartSkus;

    const checkedBasicBundles: BasicBundle[] = checkForDeletedBundles(locationInfo)

    // Iterate over the basicBundles
    for (const bundle of checkedBasicBundles) {
        if (isEligibleByBundle(cartSkus, bundle)) {
            return true;
        }
    }

    return false;
};

// Find the eligible bundle for the user
const findEligibleBundle = (locationInfo, cart) => {

    if (locationInfo.locationSetting.locationSettingBundlesEnabled === 0) {
        return null
    }

    const cartSkus = cart.cartSkus;

    const checkedBasicBundles: BasicBundle[] = checkForDeletedBundles(locationInfo)

    for (const bundle of checkedBasicBundles) {
        const { basicBundleSkuId, basicBundlePaidCount } = bundle;

        // Check if the required bundle SKU is present in the user's cart
        const matchingCartSku = cartSkus.find((cartSku) => cartSku.cartSkuParent?.skuId === basicBundleSkuId);
        if (matchingCartSku) {
            // Get the total amount of the bundle SKU in the cart
            const bundleSkuAmount = cartSkus
                .filter((cartSku) => cartSku.cartSkuParent?.skuId === basicBundleSkuId)
                .reduce((totalAmount, cartSku) => totalAmount + cartSku.cartSkuAmount, 0);

            // Check if the user has added enough paid items to the cart to qualify for the bundle
            // @ts-ignore
            if (bundleSkuAmount >= basicBundlePaidCount) {
                return bundle;
            }
        }
    }

    return null; // User is not eligible for any bundle
};

export const checkBundleEligibility = (locationInfo, cart) => {
    return {
        isEligible: isEligibleForBundle(locationInfo, cart),
        eligibleBundle: findEligibleBundle(locationInfo, cart)
    }
}

export const calculateFreeItems = (locationInfo, cart, selectedSkuQuantity, inputSku) => {

    let freeSkuCount = 0;
    let bundleId: number | null = null

    if (locationInfo.locationSetting.locationSettingBundlesEnabled === 0) {
        return {
            freeSkuCount,
            bundleId
        }
    }

    const eligibility = checkBundleEligibility(locationInfo, cart)

    if (eligibility.isEligible && eligibility.eligibleBundle) {
        const maxFreeItems = eligibility.eligibleBundle.basicBundleFreeCount
        if (cart && maxFreeItems) {
            if (maxFreeItems > cartFreeItemsCount(cart.cartSkus)) {
                const bundlePrice = getSkuPrice(eligibility.eligibleBundle.basicBundleSku?.prices, locationInfo?.locationId)?.priceAmount
                const skuPrice = getSkuPrice(inputSku.prices, locationInfo?.locationId)?.priceAmount
                if (bundlePrice && skuPrice) {
                    if (bundlePrice >= skuPrice) {
                        if (selectedSkuQuantity >= (maxFreeItems - cartFreeItemsCount(cart.cartSkus))) {
                            freeSkuCount = (maxFreeItems - cartFreeItemsCount(cart.cartSkus))
                        } else {
                            freeSkuCount = selectedSkuQuantity
                        }
                        bundleId = eligibility.eligibleBundle.basicBundleId
                    }
                }
            }
        }

    } else {
        const checkedBasicBundles: BasicBundle[] = checkForDeletedBundles(locationInfo)
        if (!checkedBasicBundles) {
            return {
                freeSkuCount,
                bundleId
            }
        }
        const bundleSkuIds = checkedBasicBundles.map(bundle => bundle.basicBundleSkuId)
        const skuIdIndex = bundleSkuIds.indexOf(inputSku.skuId)
        if (
            skuIdIndex > -1) {
            const paidCount = checkedBasicBundles[skuIdIndex].basicBundlePaidCount
            const freeCount = checkedBasicBundles[skuIdIndex].basicBundleFreeCount
            if (
                freeCount &&
                paidCount &&
            selectedSkuQuantity > paidCount) {
                const diff = selectedSkuQuantity - paidCount
                if (diff >= freeCount ) {
                    freeSkuCount = freeCount
                } else {
                    freeSkuCount = diff
                }
                bundleId = checkedBasicBundles[skuIdIndex].basicBundleId
            }
        }
    }

    console.log('calculateFreeItems', {
        freeSkuCount,
        bundleId
    })

    return {
        freeSkuCount,
        bundleId
    }
}

export const cartBundleId = ( cartSkus: CartSku[]) => {
    for (const cartSku of cartSkus) {
        if (cartSku.cartSkuBundleId) {
            return cartSku.cartSkuBundleId
        }
    }
    return 0
}

export const getBundleBySkuId = (skuId, locationInfo) => {
    // Check if locationInfo contains bundles
    if (locationInfo && locationInfo.basicBundles && locationInfo.basicBundles.length > 0 && locationInfo.locationSetting.locationSettingBundlesEnabled === 1) {
        // Iterate through each bundle
        for (const bundle of locationInfo.basicBundles) {
            if (bundle.basicBundleDeleted === 0 && bundle.basicBundleStatus === 'active' && bundle.basicBundleSkuId === skuId) {
                return bundle; // SKU has a bundle
            }
        }
    }

    return null; // SKU does not have a bundle
};