import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {AuthStoreModel} from "../auth-store/auth-store";
import {CategoryStoreModel} from "../category-store/category-store";
import {VendorStoreModel} from "../vendor-store/vendor-store";
import {UserLocationStoreModel} from "../user-location-store/user-location-store";
import {UserStoreModel} from "../user-store/user-store";
import {SkuImageStoreModel} from "../sku-image-store/sku-image-store";
import {SkuStoreModel} from "../sku-store/sku-store";
import {RoleStoreModel} from "../role-store/role-store";
import {PriceStoreModel} from "../price-store/price-store";
import {PaymentStoreModel} from "../payment-store/payment-store";
import {OrderDetailStoreModel} from "../order-detail-store/order-detail-store";
import {OrderStoreModel} from "../order-store/order-store";
import {LocationTypeStoreModel} from "../location-type-store/location-type-store";
import {LocationStoreModel} from "../location-store/location-store";
import {InventoryStoreModel} from "../inventory-store/inventory-store";
import {DiscountStoreModel} from "../discount-store/discount-store";
import {CustomerStoreModel} from "../customer-store/customer-store";
import {LocationSettingStoreModel} from "../location-setting-store/location-setting-store";
import {BundleStoreModel} from "../bundle-store/bundle-store";
import {BundleSkuStoreModel} from "../bundle-sku-store/bundle-sku-store";
import {ConfigStoreModel} from "../config-store/config-store";
import {CartStoreModel} from "../cart-store/cart-store";
import {UserDetailStoreModel} from "../user-detail-store/user-detail-store";
import {GatewayStoreModel} from "../gateway-store/gateway-store";
import {PageStoreModel} from "../page-store/page-store";
import {ResetPasswordStoreModel} from "../reset-password-store/reset-password-store";
import {PreviousCartStoreModel} from "../previous-cart-store/previous-cart-store";
import {StoreItemAvailabilityStoreModel} from "../store-item-availability-store/store-item-availability-store";
import {LocationStoreLockboxStoreModel} from "../location-store-lockbox-store/location-store-lockbox-store";
import {BasicBundleStoreModel} from "../basic-bundle-store/basic-bundle-store";
import {PromotionalBannerStoreModel} from "../promotional-banner-store/promotional-banner-store";
import {PosLogStoreModel} from "../pos-logs-store/pos-logs-store";
import {CogsStoreModel} from "../cogs-store/cogs-store";
import {CustomerProfileStoreModel} from "../customer-profile-store/customer-profile-store";
// import {CharacterStoreModel} from "../character-store/character-store";

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  // characterStore: types.optional(CharacterStoreModel, {} as any),
    authStore: types.optional(AuthStoreModel, {} as any),
    categoryStore: types.optional(CategoryStoreModel, {} as any),
    customerStore: types.optional(CustomerStoreModel, {} as any),
    discountStore: types.optional(DiscountStoreModel, {} as any),
    inventoryStore: types.optional(InventoryStoreModel, {} as any),
    locationStore: types.optional(LocationStoreModel, {} as any),
    locationTypeStore: types.optional(LocationTypeStoreModel, {} as any),
    locationSettingStore: types.optional(LocationSettingStoreModel, {} as any),
    orderStore: types.optional(OrderStoreModel, {} as any),
    orderDetailStore: types.optional(OrderDetailStoreModel, {} as any),
    paymentStore: types.optional(PaymentStoreModel, {} as any),
    priceStore: types.optional(PriceStoreModel, {} as any),
    roleStore: types.optional(RoleStoreModel, {} as any),
    skuStore: types.optional(SkuStoreModel, {} as any),
    bundleStore: types.optional(BundleStoreModel, {} as any),
    bundleSkuStore: types.optional(BundleSkuStoreModel, {} as any),
    skuImageStore: types.optional(SkuImageStoreModel, {} as any),
    userStore: types.optional(UserStoreModel, {} as any),
    userLocationStore: types.optional(UserLocationStoreModel, {} as any),
    vendorStore: types.optional(VendorStoreModel, {} as any),
    configStore: types.optional(ConfigStoreModel, {} as any),
    cartStore: types.optional(CartStoreModel, {} as any),
    previousCartStore: types.optional(PreviousCartStoreModel, {} as any),
    userDetailStore: types.optional(UserDetailStoreModel, {} as any),
    gatewayStore: types.optional(GatewayStoreModel, {} as any),
    pageStore: types.optional(PageStoreModel, {} as any),
    resetPasswordStore: types.optional(ResetPasswordStoreModel, {} as any),
    storeItemAvailabilityStore: types.optional(StoreItemAvailabilityStoreModel, {} as any),
    locationStoreLockboxStore: types.optional(LocationStoreLockboxStoreModel, {} as any),
    basicBundleStore: types.optional(BasicBundleStoreModel, {} as any),
    promotionalBannerStore: types.optional(PromotionalBannerStoreModel, {} as any),
    posLogStore: types.optional(PosLogStoreModel, {} as any),
    cogsStore: types.optional(CogsStoreModel, {} as any),
    customerProfileStore: types.optional(CustomerProfileStoreModel, {} as any)
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
