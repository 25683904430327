import {hasValue} from "./empty-check";

export const navigationParams = (mode) => {
    let output = ''

    if (hasValue(mode)) {
        output = `${mode}/`
    } else {
        output = ''
    }
    return output;
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}