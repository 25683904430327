import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  BundleDeleteParams,
  BundleCreateResult,
  BundleUpdateResult,
  BundleUpdateParams,
  BundleFindAllResult,
  BundleDeleteResult,
  BundleFindAllParams,
  BundleFindOneParams,
  BundleCreateParams,
  BundleFindOneResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class BundlesApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: BundleCreateParams): Promise<BundleCreateResult> {
    try {
      // const functionName = "login"
      const uri = "bundles"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundle = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundle}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: BundleFindAllParams): Promise<BundleFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "bundles"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundles = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundles}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: BundleFindOneParams): Promise<BundleFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "bundles/"+params.bundleId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundle = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundle}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: BundleUpdateParams): Promise<BundleUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "bundles/"+params.bundleId
      delete params.bundleId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundle = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundle}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: BundleDeleteParams): Promise<BundleDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "bundles/"+params.bundleId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
