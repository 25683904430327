import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CCard,
} from "../../../components";
import {OrderUpdateParams, PaymentCreateParams, PaymentUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultPayment} from "../../../models/payment/payment";
import {isEmpty} from "validate.js";
import {useEffect, useRef} from "react";
import {defaultOrder} from "../../../models/order/order";
import {useNavigate} from "react-router-dom";
import {Dayjs} from "dayjs";
import {getSkuAvailability, getSkuCompleteName} from "../../../utils/sku";
import {PaymentsTable} from "../../../components/payments-table";
import {OrderDialog} from "../../../components/order-dialog";
import {PaymentDialog} from "../../../components/payment-dialog";
import {PaymentsHeader} from "../../../components/payments-header";

interface PaymentDialogData {
    isOpen: boolean,
    data: PaymentCreateParams | PaymentUpdateParams,
    mode: string,
    isLoading: boolean
}

interface OrderDialogData {
    isOpen: boolean,
    data: OrderUpdateParams,
    isLoading: boolean
}

let currentAuthToken = ''

export const StorePanelPayments = observer(
    function StorePanelPayments() {
        const navigate = useNavigate();

        const {authStore, paymentStore, orderDetailStore} = useStores()

        const [paymentDialog, setPaymentDialog] = React.useState<PaymentDialogData>({
            isOpen: false,
            data: defaultPayment,
            mode: 'add',
            isLoading: false
        });
        const [orderNoFilter, setOrderNoFilter] = React.useState('');
        const [transIdFilter, setTransIdFilter] = React.useState('');
        const [fromDate, setFromDate] = React.useState<Dayjs | null>(null)
        const [toDate, setToDate] = React.useState<Dayjs | null>(null)
        const [cardNoFilter, setCardNoFilter] = React.useState('');
        const [orderDialog, setOrderDialog] = React.useState<OrderDialogData>({
            isOpen: false,
            data: defaultOrder,
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [validation, setValidation] = React.useState({
            order: '',
            status: '',
            amount: ''
        })

        const paymentsTableRef = useRef(null);

        const refresh = () => {
            if (paymentsTableRef.current) {
                // @ts-ignore
                paymentsTableRef.current.refreshContents();
            }
        }


        const handleClickOpen = (mode: string, payment?: PaymentCreateParams | PaymentUpdateParams) => {
            setPaymentDialog({
                ...paymentDialog,
                isOpen: true,
                mode,
                data: payment ? {...payment} : defaultPayment
            });
        };

        const handleOrderClickOpen = async (order?: OrderUpdateParams) => {
            setOrderDialog({
                ...orderDialog,
                isOpen: true,
                data: order ? {...order} : defaultOrder
            });
            await orderDetailStore.findAll({
                orderId: Number(order?.orderId)
            }, currentAuthToken)
        };

        const handleClose = () => {
            setPaymentDialog({isOpen: false, data: defaultPayment, mode: 'add', isLoading: false});
            setOrderDialog({isOpen: false, data: defaultOrder, isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }

                setPaymentDialog({
                    ...paymentDialog,
                    isLoading: true
                });

                if (paymentDialog.mode === 'add') {
                    await paymentStore.create({
                        ...paymentDialog.data
                    }, currentAuthToken)
                } else if (paymentDialog.mode === 'edit') {
                    await paymentStore.update({
                        ...paymentDialog.data
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(paymentDialog.data.orderId)) {
                validationObject.order = "This field is required."
                isValid = false
            }
            if (isEmpty(paymentDialog.data.paymentAmount)) {
                validationObject.amount = "This field is required."
                isValid = false
            }
            if (isEmpty(paymentDialog.data.paymentStatus)) {
                validationObject.status = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const checkAuth = () => {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Payments';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        function createOrderDetailData(
            id: number,
            code: string,
            name: string,
            quantity: number,
            price: number,
            availability: number
        ) {
            return {id, code, name, quantity, price, availability};
        }

        const orderDetailRows = orderDetailStore.orderDetails.map(
            orderDetail =>
                createOrderDetailData(
                    orderDetail.orderDetailId,
                    String(orderDetail.sku?.skuCode),
                    getSkuCompleteName(orderDetail.sku),
                    Number(orderDetail.skuQuantity),
                    Number(orderDetail.skuPrice),
                    getSkuAvailability(orderDetail.sku, orderDialog.data.lockboxId)
                )
        )

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>
                        <CBox m={2}>
                            <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                                <PaymentsHeader
                                    refresh={refresh}
                                    orderNoFilter={orderNoFilter}
                                    setOrderNoFilter={setOrderNoFilter}
                                    transIdFilter={transIdFilter}
                                    setTransIdFilter={setTransIdFilter}
                                    fromDate={fromDate}
                                    setFromDate={setFromDate}
                                    toDate={toDate}
                                    setToDate={setToDate}
                                    cardNoFilter={cardNoFilter}
                                    setCardNoFilter={setCardNoFilter}
                                    isRefreshing={isRefreshing}
                                />
                            </CCard>
                        </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "16px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "20px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "16px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <PaymentsTable
                                handleClickOpen={handleClickOpen}
                                handleOrderClickOpen={handleOrderClickOpen}
                                orderNoFilter={orderNoFilter}
                                transIdFilter={transIdFilter}
                                cardNoFilter={cardNoFilter}
                                fromDate={fromDate}
                                toDate={toDate}
                                ref={paymentsTableRef}
                                module={'store admin'}
                                autoRefresh={false}
                                onRefreshingChange={setIsRefreshing}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <PaymentDialog
                    paymentDialog={paymentDialog}
                    setPaymentDialog={setPaymentDialog}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    validation={validation}
                    setValidation={setValidation}
                />

                <OrderDialog
                    orderDialog={orderDialog}
                    handleSubmit={null}
                    handleClose={handleClose}
                    handleSaveChanges={null}
                    orderDetailRows={orderDetailRows}
                    validation={validation}
                    setOrderDialog={setOrderDialog}
                    setValidation={setValidation}
                    orderPayments={null}
                    module={'store admin'}
                    viewOnly={true}
                    handlePaymentDialogOpen={()=>{console.log('ignore')}}
                />
            </>
        );
    }
)
