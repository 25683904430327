import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {CartSkuModel} from "../cart-sku/cart-sku";
import {hasValue} from "../../utils/empty-check";

/**
 * Rick and Morty character model.
 */
export const CartModel = types.model("Cart").props({
    cartDeliveryMethodSelected: types.optional(types.boolean, false),
    cartLockboxId: types.maybeNull(types.number),
    cartLockboxLat: types.maybeNull(types.number),
    cartLockboxLng: types.maybeNull(types.number),
    cartKioskId: types.maybeNull(types.number),
    cartUserId: types.maybeNull(types.number),
    cartCustomerId: types.maybeNull(types.number),
    cartKioskMode: types.maybeNull(types.string), /// store or kiosk
    cartLockboxText: types.maybeNull(types.string),
    cartDeliveryMethod: types.maybeNull(types.string),
    cartSkus: types.optional(types.array(CartSkuModel), []),
    cartDeliveryFullAddress: types.maybeNull(types.string),
    cartDeliveryAddress1: types.maybeNull(types.string),
    cartDeliveryAddress2: types.maybeNull(types.string),
    cartDeliveryCity: types.maybeNull(types.string),
    cartDeliveryZip: types.maybeNull(types.string),
    cartInfoFirstName: types.maybeNull(types.string),
    cartInfoLastName: types.maybeNull(types.string),
    cartInfoPhone: types.maybeNull(types.string),
    cartInfoEmail: types.maybeNull(types.string),
    cartInfoAddress1: types.maybeNull(types.string),
    cartInfoAddress2: types.maybeNull(types.string),
    cartInfoCity: types.maybeNull(types.string),
    cartInfoState: types.maybeNull(types.string),
    cartInfoZip: types.maybeNull(types.string),
    cartInfoCardNo: types.maybeNull(types.string),
    cartInfoCardMonth: types.maybeNull(types.string),
    cartInfoCardYear: types.maybeNull(types.string),
    cartInfoCardCVC: types.maybeNull(types.string),
    cartInfoCardPayer: types.maybeNull(types.string),
    cartInfoCardZip: types.maybeNull(types.string),
    cartOrderId: types.maybeNull(types.number),
    cartOrderNo: types.maybeNull(types.string),
    cartOrderHash: types.maybeNull(types.string),
    // cartPickupTime: types.maybeNull(types.string),
    cartPickupDate: types.maybeNull(types.string),
    cartTax: types.maybeNull(types.number),
    cartFee: types.maybeNull(types.number),
    cartDeliveryFee: types.maybeNull(types.number),
    cartCustomerNote: types.maybeNull(types.string),
    cartHash: types.maybeNull(types.string),
    useCustomerProfile: types.maybeNull(types.boolean),
    customerProfileId: types.maybeNull(types.string),
    customerPaymentProfileId: types.maybeNull(types.string),
    savePaymentInfo: types.maybeNull(types.boolean),
})

type CartType = Instance<typeof CartModel>
export interface Cart extends CartType {}
type CartSnapshotType = SnapshotOut<typeof CartModel>
export interface CartSnapshot extends CartSnapshotType {}

export const defaultCart = {
    cartDeliveryMethodSelected: false,
    cartLockboxId: null,
    cartLockboxText: '',
    cartLockboxLat: null,
    cartLockboxLng: null,
    cartDeliveryMethod: 'lockbox',
    cartSkus: [],
    cartStatus: '',
    cartDeliveryFullAddress: '',
    cartDeliveryAddress1: '',
    cartDeliveryAddress2: '',
    cartDeliveryCity: '',
    cartDeliveryZip: '',
    cartInfoFirstName: '',
    cartInfoLastName: '',
    cartInfoPhone: '',
    cartInfoEmail: '',
    cartInfoAddress1: '',
    cartInfoAddress2: '',
    cartInfoCity: '',
    cartInfoState: '',
    cartInfoZip: '',
    cartInfoCardNo: '',
    cartInfoCardMonth: '',
    cartInfoCardYear: '',
    cartInfoCardCVC: '',
    cartInfoCardPayer: '',
    cartInfoCardZip: '',
    cartOrderId: null,
    // cartPickupTime: null,
    cartPickupDate: null,
    cartTax: 0.00,
    cartFee: 0.00,
    cartDeliveryFee: 0.00,
    useCustomerProfile: false,
    customerProfileId: '',
    customerPaymentProfileId: '',
    savePaymentInfo: false
}