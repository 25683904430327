import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CButton,
    CCard,
    CCircularProgress,
    CContainer,
    CGrid,
    CGridContainer,
    CTypography,
    ProductCard,
    DisclaimerDialog, DeliveryMethodDialog, Footer, CCardContent,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {hasValue} from "../../../utils/empty-check";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import ReactGA from "react-ga4";
import {getSkuPrice} from "../../../utils/sku";
import {Sku} from "../../../models/sku/sku";

export const StoreVendor = observer(
    function StoreVendor() {
        const navigate = useNavigate();

        const {skuStore, vendorStore, locationStore, cartStore} = useStores()

        const params = useParams();

        const guestAuth = useAppInit()
        const {guestAuthToken, guestAuthState, isLoadingGuestAuth, locationInfo, locationInfoState} = guestAuth
        const [vendorSkus, setVendorSkus] = React.useState<any>([])
        const [isGettingVendorSkus, setIsGettingVendorSkus] = React.useState(true)
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })

        const init = async () => {
            const gaItems: any[] = []

            setIsGettingVendorSkus(true)
            const {vendors} = await vendorStore.findAll({
                vendorName: params.vendorName,
                sortBy: 'vendorName',
                vendorStatus: 'active'
            }, guestAuthToken)
            console.log('2')
            if (!vendors || vendors.length === 0) {
                return
            }
            console.log('3')
            const vendor = vendors[0]
            const currentVendorSkus: any[] = []
            const {skus} = await skuStore.findAll({
                page: 0,
                perPage: 100,
                skuHasParent: 0,
                vendorId: vendor.vendorId,
                skuStatus: 'active'
            }, guestAuthToken, true)
            const checkedSkus: Sku[] = []
            if (skus) {
                skus.forEach(sku=>{
                    if (getSkuPrice(sku.prices, locationStore.currentLocation?.locationId) !== null) {
                        checkedSkus.push(sku)
                    }
                })
            }
            currentVendorSkus.push({
                vendor,
                skus: checkedSkus
            })
            setVendorSkus(currentVendorSkus)

            setIsGettingVendorSkus(false)

            skus.forEach(sku=>{
                gaItems.push({
                    item_id: sku.skuId,
                    item_name: sku.skuHasParent ? sku.skuCode + ' - ' + sku.vendor.vendorName + ' - ' + sku.parentSku?.skuTitle + ' - ' + sku.skuTitle : sku.vendor.vendorName + ' - ' + sku.skuTitle,
                    price: Number(getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                })
            })

            ReactGA.event("view_item_list", {
                item_list_id: `vendor_screen_${vendor.vendorId}`,
                item_list_name: `Vendor Screen ${vendor.vendorName}`,
                items: gaItems
            })

            console.log("ReactGA", "StoreVendorScreen", "view_item_list", {
                item_list_id: `vendor_screen_${vendor.vendorId}`,
                item_list_name: `Vendor Screen ${vendor.vendorName}`,
                items: gaItems
            })
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        useEffect(() => {
            ;(async () => {
                console.log('0', guestAuthState,
                guestAuthToken)
                if (guestAuthState === 'loaded') {
                    console.log('1')
                    await init()
                }
            })()
        }, [guestAuthState, guestAuthToken]);


        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Products`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Products | ${locationInfo.locationName}`;
                }
            })()

        }, [locationInfo, locationInfoState])


        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        return (
            <>

                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>
                        <CGridContainer zIndex={100} mt={0} spacing={2}>
                            <CGrid item xs={12} height={{xs: 100, sm: 100, md: 100}}>
                                <CCard
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        pl: 2
                                    }}
                                >
                                    <CButton variant={'text'} sx={{color: 'primary', fontWeight: 'bold', zIndex: 1}}
                                             onClick={() => navigate('/')}>
                                        Home
                                    </CButton>
                                    <CTypography color={'primary'} zIndex={1}>
                                        / {params.vendorName}
                                    </CTypography>
                                </CCard>
                            </CGrid>
                        </CGridContainer>
                        {isGettingVendorSkus && <CGridContainer sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '50vh',
                            mt: 2
                        }}>
                            <CCircularProgress/>

                        </CGridContainer>}

                        {
                            (!isGettingVendorSkus && hasValue(vendorSkus) && vendorSkus[0].skus.length > 0) && vendorSkus.map(vendorSku => {
                                return <>
                                    <CGridContainer minHeight={'51vh'}>
                                        {vendorSku.skus.map((sku, index) => <ProductCard
                                            key={`${sku.skuId}${index}_category_product`}
                                            md={2}
                                            showInfoButton={false}
                                            imageUrl={hasValue(sku.skuImages) ? sku.skuImages[0].skuImageLink : ''}
                                            title={sku.skuTitle}
                                            vendorName={sku.vendor?.vendorName}
                                            price={`$${getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount ? getSkuPrice(sku.prices, locationStore.currentLocation?.locationId)?.priceAmount?.toFixed(2) : "0.00"}`}
                                            onClick={() => {
                                                // addSku(sku)
                                                navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)

                                            }}
                                        />)}
                                    </CGridContainer>
                                </>
                            })
                        }
                        {
                            (!isGettingVendorSkus && (!hasValue(vendorSkus) || vendorSkus[0].skus.length === 0)) &&
                            <CGridContainer minHeight={'50vh'} zIndex={999} width={'100%'} mt={2}>
                                <CCard sx={{width: '100%'}}>
                                    <CCardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                        <CTypography>
                                            No sku found for this vendor.
                                        </CTypography>
                                    </CCardContent>
                                </CCard>

                            </CGridContainer>
                        }
                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );

    }
)
