import {
    CBox,
    CButton,
    CCircularProgress, CDialog,
    CDivider,
    CGrid,
    CGridContainer,
    CTextField,
    CTypography
} from "../mui-extracted";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {isEmpty} from "validate.js";
import {useStores} from "../../models";

export const SkuPriceDialog = (props) => {

    const {
        skuPriceDialog,
        setSkuPriceDialog,
        setSkuPricesDialog,
        skuPricesDialog,
        currentAuthToken,
        refresh,
        module
    } = props;

    const {skuStore, priceStore} = useStores()

    const [editPriceValidation, setEditPriceValidation] = React.useState({
        price: ''
    })

    const handleEditSkuPrice = async () => {
        if (!editPriceValidate()) {
            return
        }

        setEditPriceValidation({
            price: ''
        })

        setSkuPriceDialog({
            ...skuPriceDialog,
            isLoading: true
        })

        const result = await priceStore.findAll({
            skuId: skuPriceDialog.formData.skuId,
            locationId: skuPriceDialog.formData.locationId,
        }, currentAuthToken, false)
        if (result.kind === "ok" && result.prices && result.prices.length > 0) {
            /// update
            const price = result.prices[0]
            await priceStore.update({
                priceId: price.priceId,
                priceAmount: skuPriceDialog.formData.priceAmount
            }, currentAuthToken)
        } else {
            /// create
            await priceStore.create({
                skuId: skuPriceDialog.formData.skuId,
                locationId: skuPriceDialog.formData.locationId,
                priceAmount: skuPriceDialog.formData.priceAmount
            }, currentAuthToken)
        }

        if (module === 'admin') {
            console.log('handleEditSkuPrice', skuPricesDialog)
            setSkuPricesDialog({
                ...skuPricesDialog,
                formData: {
                    locationId: '',
                    price: ''
                },
                isOpen: true,
                isLoading: false
            })
        }

        if (module === 'store admin' && refresh) {
            refresh()
        }


        setSkuPriceDialog({
            ...skuPriceDialog,
            formData: {
                priceId: null,
                skuId: null,
                locationId: null,
                priceAmount: ''
            },
            isOpen: false,
            isLoading: false
        })

    }

    const editPriceValidate = () => {
        const validationObject = {...editPriceValidation}
        let isValid = true
        if (isEmpty(skuPriceDialog.formData.priceAmount)) {
            validationObject.price = "This field is required."
            isValid = false
        }
        setEditPriceValidation(validationObject)
        return isValid;
    }

    return (
        <CDialog open={skuPriceDialog.isOpen} maxWidth={"lg"} PaperProps={{
            sx: {
                borderRadius: "37px",
                overflowX: 'hidden'
            }
        }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4} mr={2}>
                    Edit Price
                </CTypography>
            </CBox>
            <CDivider>

            </CDivider>
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={12}>
                    <CTextField fullWidth label={"Price"}
                                error={hasValue(editPriceValidation.price)}
                                helperText={hasValue(editPriceValidation.price) ? editPriceValidation.price : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuPriceDialog({
                                        ...skuPriceDialog,
                                        formData: {
                                            ...skuPriceDialog.formData,
                                            priceAmount: event.target.value
                                        }
                                    });
                                    if (hasValue(editPriceValidation.price)) {
                                        setEditPriceValidation({...editPriceValidation, price: ''})
                                    }
                                }} value={skuPriceDialog.formData.priceAmount}/>
                </CGrid>
                <CGrid item xs={12}>
                    <CDivider></CDivider>

                </CGrid>
                <CGrid item xs={12} display={'flex'} justifyContent={'flex-end'}>

                    <CButton variant={"outlined"} onClick={() => {
                        setSkuPriceDialog({
                            isOpen: false,
                            formData: {
                                priceId: null,
                                skuId: null,
                                locationId: null,
                                priceAmount: ''
                            },
                            isLoading: false
                        })
                        setSkuPricesDialog({
                            ...skuPricesDialog,
                            isOpen: true
                        })
                    }} sx={{mr: 2, height: '100%'}}>
                        Close
                    </CButton>
                    <CButton variant={"contained"} onClick={handleEditSkuPrice} sx={{height: '100%'}}>
                        {skuPriceDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                         size={24}/> : 'Save'}
                    </CButton>
                </CGrid>
            </CGridContainer>
        </CDialog>
    )
}