import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  PriceDeleteParams,
  PriceCreateResult,
  PriceUpdateResult,
  PriceUpdateParams,
  PriceFindAllResult,
  PriceDeleteResult,
  PriceFindAllParams,
  PriceFindOneParams,
  PriceCreateParams,
  PriceFindOneResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class PricesApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: PriceCreateParams): Promise<PriceCreateResult> {
    try {
      // const functionName = "login"
      const uri = "prices"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const price = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", price}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: PriceFindAllParams): Promise<PriceFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "prices"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)

      console.log("findAll.response", response)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const prices = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", prices}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: PriceFindOneParams): Promise<PriceFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "prices/"+params.priceId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const price = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", price}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: PriceUpdateParams): Promise<PriceUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "prices/"+params.priceId
      delete params.priceId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const price = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", price}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: PriceDeleteParams): Promise<PriceDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "prices/"+params.priceId
      console.log('remove', uri)
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
