export function ensureString(input: unknown): string {
    if (typeof input === 'string') {
        return input;
    }
    return '';
}

export function ensureNumber(input: unknown): number {
    if (typeof input === 'number') {
        return input;
    }
    return -1;
}
