import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer, CMenuItem,
    CTextField,
    CTypography,
    Divider
} from "../../../components";
import {Refresh} from "@mui/icons-material";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useNavigate} from "react-router-dom";
import {Table} from "../../../components/table";
import {Sku} from "../../../models/sku/sku";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {Dayjs} from "dayjs";
import {convertToCurrentTimezone, stringToDayjs} from "../../../utils/date-time";
import {ensureNumber, ensureString} from "../../../utils/type";
import {PromotionalBannerSnapshot} from "../../../models/promotional-banner/promotional-banner";
import {BasicBundleSnapshot} from "../../../models/basic-bundle/basic-bundle";

let currentAuthToken = ''

interface BundleDialogFormDataType {
    basicBundleId?: number,
    basicBundleSkuId: number | null,
    basicBundleTitle: string,
    basicBundlePaidCount: number | null,
    basicBundleFreeCount: number | null,
    basicBundleDescriptions: string,
    basicBundleValidFrom: Dayjs | null,
    basicBundleValidTo: Dayjs | null,
}

export const StorePanelBundles = observer(
    function StorePanelBundles() {

        const navigate = useNavigate();

        const {authStore, basicBundleStore, skuStore} = useStores()

        const [showAddBasicBundleDialog, setShowAddBasicBundleDialog] = React.useState(false);
        const [bundleDialogFormData, setBundleDialogFormData] = React.useState<BundleDialogFormDataType>({
            basicBundleSkuId: null,
            basicBundleTitle: '',
            basicBundlePaidCount: null,
            basicBundleFreeCount: null,
            basicBundleDescriptions: '',
            basicBundleValidFrom: null,
            basicBundleValidTo: null,
        });
        const [isCreatingBasicBundle, setIsCreatingBasicBundle] = React.useState(false);
        const [basicBundleDialogMode, setBasicBundleDialogMode] = React.useState<'create' | 'edit'>('create');
        const [parentSkus, setParentSkus] = React.useState<Sku[]>([])
        const [isFromDateCalendarOpen, setIsFromDateCalendarOpen] = React.useState<boolean>(false)
        const [isToDateCalendarOpen, setIsToDateCalendarOpen] = React.useState<boolean>(false)

        const checkAuth = () => {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Store Admin Panel - Bundles';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        const handleBasicBundleInactivate = async (basicBundleId: number) => {

            await basicBundleStore.update({
                basicBundleId,
                basicBundleStatus: 'inactive'
            }, currentAuthToken)
            await refresh()
        }

        const handleBasicBundleActivate = async (basicBundleId: number) => {

            await basicBundleStore.update({
                basicBundleId,
                basicBundleStatus: 'active'
            }, currentAuthToken)
            await refresh()
        }

        const refresh = async () => {
            if (!authStore.storeAdminAuth?.userLocationId) {
                return
            }

            await basicBundleStore.findAll({
                basicBundleStoreId: authStore.storeAdminAuth?.userLocationId
            }, currentAuthToken)

        }

        const handleBasicBundleCreate = async () => {
            if (
                bundleDialogFormData.basicBundleFreeCount === null ||
                bundleDialogFormData.basicBundlePaidCount === null
            ) {
                return
            }
            setIsCreatingBasicBundle(true)

            if (basicBundleDialogMode === 'create') {
                // @ts-ignore
                await basicBundleStore.create({
                    ...bundleDialogFormData,
                    basicBundleValidFrom: bundleDialogFormData.basicBundleValidFrom?.toISOString(),
                    basicBundleValidTo: bundleDialogFormData.basicBundleValidTo?.toISOString(),
                    // @ts-ignore
                    basicBundleStoreId: authStore.storeAdminAuth?.userLocationId
                }, currentAuthToken)
            } else {
                await basicBundleStore.update({
                    ...bundleDialogFormData,
                    basicBundleFreeCount: ensureNumber(bundleDialogFormData.basicBundleFreeCount),
                    basicBundlePaidCount: ensureNumber(bundleDialogFormData.basicBundlePaidCount),
                    basicBundleId: ensureNumber(bundleDialogFormData.basicBundleId),
                    basicBundleValidFrom: bundleDialogFormData.basicBundleValidFrom?.toISOString(),
                    basicBundleValidTo: bundleDialogFormData.basicBundleValidTo?.toISOString(),
                }, currentAuthToken)
            }
            await refresh()
            setIsCreatingBasicBundle(false)
            setBundleDialogFormData({
                basicBundleSkuId: null,
                basicBundleTitle: '',
                basicBundlePaidCount: null,
                basicBundleFreeCount: null,
                basicBundleDescriptions: '',
                basicBundleValidFrom: null,
                basicBundleValidTo: null,
            })
            setShowAddBasicBundleDialog(false)
        }

        const handleBasicBundleEdit = async (basicBundle: BasicBundleSnapshot) => {

            setBasicBundleDialogMode('edit')
            setBundleDialogFormData({
                basicBundleId: basicBundle.basicBundleId,
                basicBundleSkuId: basicBundle.basicBundleSkuId,
                basicBundleTitle: ensureString(basicBundle.basicBundleTitle),
                basicBundlePaidCount: basicBundle.basicBundlePaidCount,
                basicBundleFreeCount: basicBundle.basicBundleFreeCount,
                basicBundleDescriptions: ensureString(basicBundle.basicBundleDescriptions),
                basicBundleValidFrom: stringToDayjs(ensureString(basicBundle.basicBundleValidFrom)),
                basicBundleValidTo: stringToDayjs(ensureString(basicBundle.basicBundleValidTo)),
            })
            setShowAddBasicBundleDialog(true)
            const result = await skuStore.findAll({
                skuHasParent: 0
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                setParentSkus([...result.skus])
            }
        }

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CGridContainer spacing={2} my={2}>
                                <CGrid item xs={12} md={1.5}>
                                    <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                        Bundle
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={6} md={1.5}>
                                    <CButton variant={"contained"} fullWidth onClick={async () => {
                                        setShowAddBasicBundleDialog(true)
                                        setBasicBundleDialogMode('create')
                                        setBundleDialogFormData({
                                            basicBundleSkuId: null,
                                            basicBundleTitle: '',
                                            basicBundlePaidCount: null,
                                            basicBundleFreeCount: null,
                                            basicBundleDescriptions: '',
                                            basicBundleValidFrom: null,
                                            basicBundleValidTo: null,
                                        })
                                        const result = await skuStore.findAll({
                                            skuHasParent: 0
                                        }, currentAuthToken, false)
                                        if (result.kind === "ok") {
                                            setParentSkus([...result.skus])
                                        }
                                    }}
                                    >
                                        Add New
                                    </CButton>
                                </CGrid>
                                <CGrid item xs={6} md={1}>
                                    <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                        Refresh
                                    </CButton>
                                </CGrid>
                            </CGridContainer>



                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <Table
                                columns={[
                                    {
                                        field: 'basicBundleId',
                                        headerName: 'ID',
                                        width: 80,
                                        headerClassName: "pos-table-header pos-table-header-first",
                                        cellClassName: "pos-table-cell pos-table-cell-first"
                                    },
                                    {
                                        field: 'sku',
                                        headerName: 'Sku',
                                        valueGetter: (params) => {
                                            const {basicBundleSku} = params.row
                                            return basicBundleSku?.skuTitle
                                        },
                                        width: 120,
                                        headerClassName: "pos-table-header pos-table-header-first",
                                        cellClassName: "pos-table-cell pos-table-cell-first"
                                    },
                                    {
                                        field: 'basicBundleTitle',
                                        headerName: 'Title',
                                        width: 100,
                                        headerClassName: "pos-table-header pos-table-header-first",
                                        cellClassName: "pos-table-cell pos-table-cell-first"
                                    },
                                    {
                                        field: 'basicBundlePaidCount',
                                        headerName: 'Paid Count',
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'basicBundleFreeCount',
                                        headerName: 'Free Count',
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'basicBundleValidFrom',
                                        headerName: 'From',
                                        valueGetter: (params) => {
                                            const {basicBundleValidFrom} = params.row
                                            return convertToCurrentTimezone(basicBundleValidFrom, 'MMM D')
                                        },
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'basicBundleValidTo',
                                        headerName: 'To',
                                        valueGetter: (params) => {
                                            const {basicBundleValidTo} = params.row
                                            return convertToCurrentTimezone(basicBundleValidTo, 'MMM D')
                                        },
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'basicBundleStatus',
                                        headerName: 'Status',
                                        width: 50,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell"
                                    },
                                    {
                                        field: 'actions',
                                        headerName: 'Action',
                                        width: 250,
                                        headerClassName: "pos-table-header",
                                        cellClassName: "pos-table-cell",
                                        renderCell: (params) => {

                                            const onInactivateClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handleBasicBundleInactivate(params.row.basicBundleId)
                                                // return alert(JSON.stringify(thisRow, null, 4));
                                            };

                                            const onActivateClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handleBasicBundleActivate(params.row.basicBundleId)
                                                // return alert(JSON.stringify(thisRow, null, 4));
                                            };

                                            const onEditClick = (e) => {
                                                e.stopPropagation(); // don't select this row after clicking
                                                return handleBasicBundleEdit(params.row)
                                            };

                                            return <>
                                                <CButton variant={"contained"} color={"primary"} size={"small"}
                                                         sx={{height: '32px', mr: 2, mt: {xs: 1 , md: 0}}}
                                                         onClick={onEditClick}>Edit</CButton>

                                                {params.row.basicBundleStatus === 'active' ? <CButton variant={"contained"} color={"error"} size={"small"}
                                                                                                      sx={{height: '32px', mr: 2}}
                                                                                                      onClick={onInactivateClick}>Inactivate</CButton> : <CButton variant={"contained"} size={"small"}
                                                                                                                                                                  sx={{height: '32px', mr: 2}}
                                                                                                                                                                  onClick={onActivateClick}>Activate</CButton>}

                                            </>;
                                        }
                                    }
                                ]}
                                rows={basicBundleStore.basicBundles}
                                count={basicBundleStore.basicBundles.length}
                                page={0}
                                rowsPerPage={100}
                                onPageChange={() => {
                                    console.log('tmp')
                                }}
                                onRowsPerPageChange={() => {
                                    console.log('tmp')
                                }}
                                orderBy={'tmp'}
                                order={'DESC'}
                                onRequestSort={() => {
                                    console.log('tmp')
                                }}
                                isLoading={false}
                                noRowTitleOnSmallSize={false}
                            />

                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={showAddBasicBundleDialog} onClose={() => setShowAddBasicBundleDialog(false)} fullWidth
                         maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            {basicBundleDialogMode === 'create' ? 'Add Bundle' : 'Edit Bundle'}
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2} alignItems={'center'}>
                        <CGrid item xs={12} md={3}>
                            <CTextField select fullWidth label={"Sku"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBundleDialogFormData({
                                                ...bundleDialogFormData,
                                                basicBundleSkuId: Number(value)
                                            });
                                        }} value={bundleDialogFormData?.basicBundleSkuId ? bundleDialogFormData?.basicBundleSkuId : ''}>
                                {parentSkus.map(sku => {
                                    return <CMenuItem
                                        value={Number(sku.skuId)}>{sku.skuTitle}</CMenuItem>
                                })}
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={3}>
                            <CTextField fullWidth label={"Title"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBundleDialogFormData({
                                                ...bundleDialogFormData,
                                                basicBundleTitle: value
                                            });
                                        }}
                                        value={bundleDialogFormData?.basicBundleTitle ? bundleDialogFormData?.basicBundleTitle : ''}>
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={1.5}>
                            <CTextField fullWidth type={"number"} label={"Paid Count"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBundleDialogFormData({
                                                ...bundleDialogFormData,
                                                basicBundlePaidCount: Number(value)
                                            });
                                        }}
                                        value={bundleDialogFormData?.basicBundlePaidCount ? bundleDialogFormData?.basicBundlePaidCount : ''}>
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={1.5}>
                            <CTextField fullWidth type={"number"} label={"Free Count"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBundleDialogFormData({
                                                ...bundleDialogFormData,
                                                basicBundleFreeCount: Number(value)
                                            });

                                        }}
                                        value={bundleDialogFormData?.basicBundleFreeCount ? bundleDialogFormData?.basicBundleFreeCount : ''}>
                            </CTextField>
                        </CGrid>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <>
                                <CGrid item xs={12} md={1.5}
                                       sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <MobileDatePicker
                                        label="From Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={bundleDialogFormData.basicBundleValidFrom}
                                        open={isFromDateCalendarOpen}
                                        onOpen={() => setIsFromDateCalendarOpen(true)}
                                        onChange={(newValue: Dayjs | null) => {
                                            // console.log('From Date', newValue)
                                            if (newValue) {
                                                setBundleDialogFormData({
                                                    ...bundleDialogFormData,
                                                    basicBundleValidFrom: newValue
                                                });
                                            }
                                            setIsFromDateCalendarOpen(false)
                                        }}
                                        onClose={() => setIsFromDateCalendarOpen(false)}
                                        renderInput={(params) =>
                                            <CTextField fullWidth {...params}/>}
                                    />
                                </CGrid>
                                <CGrid item xs={12} md={1.5}
                                       sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <MobileDatePicker
                                        label="To Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={bundleDialogFormData.basicBundleValidTo}
                                        open={isToDateCalendarOpen}
                                        onOpen={() => setIsToDateCalendarOpen(true)}
                                        onClose={() => setIsToDateCalendarOpen(false)}
                                        onChange={(newValue: Dayjs | null) => {
                                            // console.log('To Date', newValue)
                                            if (newValue) {
                                                setBundleDialogFormData({
                                                    ...bundleDialogFormData,
                                                    basicBundleValidTo: newValue
                                                });
                                            }
                                            setIsToDateCalendarOpen(false)
                                        }}
                                        renderInput={(params) =>
                                            <CTextField fullWidth {...params} />}
                                    />
                                </CGrid>
                            </>
                        </LocalizationProvider>
                        <CGrid item xs={12}>
                            <CTextField fullWidth label={"Descriptions"}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setBundleDialogFormData({
                                                ...bundleDialogFormData,
                                                basicBundleDescriptions: value
                                            });
                                        }}
                                        value={bundleDialogFormData?.basicBundleDescriptions ? bundleDialogFormData?.basicBundleDescriptions : ''}>
                            </CTextField>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={() => setShowAddBasicBundleDialog(false)}
                                 sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleBasicBundleCreate}
                                 sx={{mr: 4, minWidth: "100px"}}>
                            {isCreatingBasicBundle ? <CCircularProgress sx={{color: "white"}}
                                                                        size={24}/> : 'Submit'}
                        </CButton>
                    </CBox>
                </CDialog>

            </>
        );
    }
)
