import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {LocationTypeModel} from "../location-type/location-type";
import {LocationSettingModel} from "../location-setting/location-setting";
import {BasicBundleModel} from "../basic-bundle/basic-bundle";
import {PromotionalBannerModel} from "../promotional-banner/promotional-banner";

export const MinifiedLocationModel = types.model("MinifiedLocation").props({
    locationId: types.maybeNull(types.number),
    locationName: types.maybeNull(types.string),
    locationSlug: types.maybeNull(types.string),
    locationDesc: types.maybeNull(types.string),
    locationLat: types.maybeNull(types.number),
    locationLong: types.maybeNull(types.number),
    locationAddress1: types.maybeNull(types.string),
    locationAddress2: types.maybeNull(types.string),
    locationCity: types.maybeNull(types.string),
    locationState: types.maybeNull(types.string),
    locationZip: types.maybeNull(types.number),
    locationPhone: types.maybeNull(types.string),
    locationEmail: types.maybeNull(types.string),
    locationTypeId: types.maybeNull(types.number),
    locationAdminId: types.maybeNull(types.number),
    locationStatus: types.maybeNull(types.string),
    // locationStoreId: types.maybeNull(types.number),
    locationStoreType: types.maybeNull(types.string),
    locationCreatedAt: types.maybeNull(types.string),
    locationUpdatedAt: types.maybeNull(types.string),
})

/**
 * Rick and Morty character model.
 */
export const LocationModel = types.model("Location").props({
    locationId: types.maybeNull(types.number),
    locationName: types.maybeNull(types.string),
    locationSlug: types.maybeNull(types.string),
    locationDesc: types.maybeNull(types.string),
    locationLat: types.maybeNull(types.number),
    locationLong: types.maybeNull(types.number),
    locationAddress1: types.maybeNull(types.string),
    locationAddress2: types.maybeNull(types.string),
    locationCity: types.maybeNull(types.string),
    locationState: types.maybeNull(types.string),
    locationZip: types.maybeNull(types.number),
    locationPhone: types.maybeNull(types.string),
    locationEmail: types.maybeNull(types.string),
    locationTypeId: types.maybeNull(types.number),
    locationAdminId: types.maybeNull(types.number),
    locationStatus: types.maybeNull(types.string),
    // locationStoreId: types.maybeNull(types.number),
    locationStoreType: types.maybeNull(types.string),
    locationCreatedAt: types.maybeNull(types.string),
    locationUpdatedAt: types.maybeNull(types.string),
    locationType: types.optional(types.maybeNull(LocationTypeModel), {locationTypeId: -1}),
    locationSetting: types.optional(types.maybeNull(LocationSettingModel), {locationSettingId: -1}),
    stores: types.optional(types.array(MinifiedLocationModel), []),
    lockboxes: types.optional(types.array(MinifiedLocationModel), []),
    basicBundles: types.optional(types.array(BasicBundleModel), []),
    promotionalBanners: types.optional(types.array(PromotionalBannerModel), []),
})

type LocationType = Instance<typeof LocationModel>
export interface Location extends LocationType {}
type LocationSnapshotType = SnapshotOut<typeof LocationModel>
export interface LocationSnapshot extends LocationSnapshotType {}

export const defaultLocation = {
    locationId: undefined,
    locationName: '',
    locationSlug: '',
    locationDesc: '',
    locationLat: undefined,
    locationLong: undefined,
    locationAddress1: '',
    locationAddress2: '',
    locationCity: '',
    locationState: '',
    locationZip: undefined,
    locationPhone: '',
    locationEmail: '',
    locationTypeId: undefined,
    locationAdminId: undefined,
    // locationStoreId: undefined,
    locationStoreType: ''
}