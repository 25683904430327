import {
    CBox,
    CButton,
    CCard,
    CCardContent,
    CCircularProgress,
    CDrawer,
    CGrid,
    CGridContainer,
    CIconButton,
    CSnackbar,
    Divider,
    ForgotPasswordDialog,
    LoginDialog,
    POSTitle,
    ProductAutocomplete,
    SignupDialog
} from "../../components";
import {hasValue} from "../../utils/empty-check";
import {
    ApartmentOutlined,
    AppRegistration,
    ArrowDropDownOutlined, CardGiftcardOutlined,
    CategoryOutlined,
    DeliveryDining,
    ExpandLess,
    ExpandMore, GifBoxOutlined, GifTwoTone,
    Home,
    Login,
    Logout,
    Menu,
    Person,
    ShoppingCart,
    TextSnippet
} from "@mui/icons-material";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../models";
import {useNavigate} from "react-router-dom";
import {primary} from "../../theme";
import {navigationParams} from "../../utils/navigation";
import {useEffect, useState} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import {Collapse, ListItemText} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {defaultCart} from "../../models/cart/cart";
import {useAppInit} from "../../hooks/use-app-init";
import {CategorySku} from "../../models/category-sku/category-sku";
import List from "@mui/material/List";

interface StoreToolbarProps {
    locationInfo: any,
    additionalComponent?: React.ReactNode,
    onDeliveryMethodDialog?: () => void
    onLogin?: () => void
}

interface CategorySkuDropDownType {
    show: boolean,
    categorySku: CategorySku | null
}

interface VendorDropDownType {
    show: boolean
}

interface AboutDropDown {
    show: boolean
}

const buttonDisplayProp = {xs: 'none', sm: 'none', md: 'inline-flex'}
const menuDisplayProp = {xs: 'inline-flex', sm: 'inline-flex', md: 'none'}

// const DropdownButton = (props) => {
//
//     const {categorySku, onSkuSelect, onCategorySelect, onMouseIn, onMouseOut} = props
//
//     const [anchorEl, setAnchorEl] = useState(null);
//
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//
//     const handleClose = () => {
//         setAnchorEl(null);
//     };
//
//     const handleSelect = (sku) => {
//         onSkuSelect(sku)
//     };
//
//     const handleCategorySelect = (category) => {
//         onCategorySelect(category)
//         setAnchorEl(null);
//     };
//
//     return (
//         <>
//             <CButton
//                 onClick={()=>{
//                     handleCategorySelect(categorySku.category)
//                 }}
//                 onMouseEnter={handleClick}
//                 variant={"outlined"}
//                 sx={{color: 'white'}}
//                 endIcon={<ArrowDropDownOutlined></ArrowDropDownOutlined>}
//             >{categorySku.category.categoryName}</CButton>
//             <CMenu
//                 hideBackdrop={true}
//                 anchorEl={anchorEl}
//                 open={Boolean(anchorEl)}
//                 onClose={handleClose}
//             >
//                 {categorySku.skus.map(sku => <CMenuItem onClick={() => handleSelect(sku)}>{sku.skuTitle}</CMenuItem>)}
//             </CMenu>
//         </>
//     );
// };

const DropdownButton = (props) => {

    const {text, onClick, onMouseIn, onMouseOut} = props

    return (
        <>
            <CButton
                onClick={onClick}
                onMouseEnter={onMouseIn}
                onMouseLeave={onMouseOut}
                variant={"text"}
                // sx={{color: 'white'}}
                endIcon={<ArrowDropDownOutlined></ArrowDropDownOutlined>}
            >{text}</CButton>
        </>
    );
};

export const StoreToolbar = observer((props: StoreToolbarProps) => {

    const navigate = useNavigate();

    const guestAuth = useAppInit()

    const {guestAuthToken, locationInfo, guestAuthState} = guestAuth

    const {cartStore, categoryStore, skuStore, vendorStore, authStore, locationStore} = useStores()
    const {cart} = cartStore
    const {categorySkus} = categoryStore
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false)
    const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = useState(false)
    const [messageSnackbar, setMessageSnackbar] = useState({
        isOpen: false,
        message: ''
    })
    const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(false)
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const [categorySkuDropDown, setCategorySkuDropDown] = useState<CategorySkuDropDownType>({
        show: false,
        categorySku: null
    })
    const [vendorDropDown, setVendorDropDown] = useState<VendorDropDownType>({
        show: false,
    })
    const [aboutDropDown, setAboutDropDown] = useState<AboutDropDown>({
        show: false,
    })
    const [currentVendors, setCurrentVendors] = useState<any[]>([])

    const isCustomerAuthAvailable = authStore.auth?.role?.roleName === "Customer"

    const getCategorySkus = async () => {
        if (!hasValue(categorySkus) || categorySkus.length === 0) {
            const {categories} = await categoryStore.findAll({
                sortBy: 'categoryId',
                categoryStatus: 'active'
            }, guestAuthToken)
            const currentCategorySkus: any[] = []
            for (let j = 0; j < categories.length; j++) {
                const category = categories[j]
                const {skus} = await skuStore.findAll({
                    page: 0,
                    perPage: 5,
                    skuHasParent: 0,
                    categoryId: category.categoryId,
                    skuStatus: 'active'
                }, guestAuthToken, true)
                // console.log("skus", skus)
                // console.log("skus", skus.length)

                if (skus) {
                    // if (skus.length < 6) {
                    //     const lengthDiff = 6 - skus.length
                    //     for (let i = 0; i < lengthDiff; i++) {
                    //         skus.push({type: 'placeholder'})
                    //     }
                    // }
                    if (skus.length > 0) {
                        currentCategorySkus.push({
                            category,
                            skus
                        })
                    }

                }
            }
            categoryStore.saveCategorySkus(currentCategorySkus)
        }
    }

    const getVendors = async () => {
        if (!hasValue(currentVendors) || currentVendors.length === 0) {
            const {vendors} = await vendorStore.findAll({
                page: 0,
                perPage: 100,
                vendorStatus: 'active'
            }, guestAuthToken)
            setCurrentVendors([...vendors])
        }
    }

    const calculateItemsLength = () => {
        if (cart) {
            let length = 0
            cart.cartSkus.forEach((cartSku) => {
                length += cartSku.cartSkuAmount
            })
            return length
        } else {
            return 0
        }

    }

    const init = async () => {
        getCategorySkus()
        getVendors()
    }

    useEffect(() => {
        ;(async () => {
            if (guestAuthState === 'loaded') {
                await init()
            }
        })()
    }, [guestAuthToken, guestAuthState]);


    // @ts-ignore
    return <>
        <CDrawer
            anchor={'left'}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            <CBox
                sx={{width: 300}}
                role="presentation"
                // onClick={() => setIsDrawerOpen(false)}
                // onKeyDown={() => setIsDrawerOpen(false)}
            >
                <POSTitle sx={{
                    m: 3,
                    // textAlign: "center",
                    fontFamily: "Passion One",
                    color: primary['500'],
                    zIndex: 9,
                    cursor: 'pointer'
                }}
                          fontSize={28}
                          onClick={() => navigate('/')}
                >
                    {locationInfo?.locationName}
                </POSTitle>
                <Divider></Divider>
                <ListItemButton onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <Home/>
                    </ListItemIcon>
                    <ListItemText>
                        Home
                    </ListItemText></ListItemButton>

                {categorySkus.map(categorySku =>
                    categorySku.skus.length > 0 && <>
                        <ListItemButton onClick={() => {
                            setCategorySkuDropDown({
                                show: !categorySkuDropDown.show,
                                categorySku
                            })
                            setAboutDropDown({
                                show: false
                            })
                            setVendorDropDown({
                                show: false
                            })
                        }}>
                            <ListItemIcon>
                                <CategoryOutlined/>
                            </ListItemIcon>
                            <ListItemText primary={categorySku.category.categoryName}/>
                            {categorySkuDropDown.show && categorySkuDropDown.categorySku?.category.categoryId === categorySku.category.categoryId ?
                                <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse
                            in={categorySkuDropDown.show && categorySkuDropDown.categorySku?.category.categoryId === categorySku.category.categoryId}
                            timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {categorySkuDropDown.categorySku?.skus?.map(sku => (
                                    <ListItemButton sx={{pl: 4}}
                                                    onClick={() => navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)}>
                                        <ListItemText primary={sku.skuTitle}/>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </>)}

                <ListItemButton onClick={() => {
                    setCategorySkuDropDown({
                        show: false,
                        categorySku: null
                    })
                    setAboutDropDown({
                        show: false
                    })
                    setVendorDropDown({
                        show: !vendorDropDown.show
                    })
                }}>
                    <ListItemIcon>
                        <ApartmentOutlined/>
                    </ListItemIcon>
                    <ListItemText primary="Shop by Brand"/>
                    {vendorDropDown.show ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={vendorDropDown.show} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {currentVendors.map(vendor => (
                            <ListItemButton sx={{pl: 4}}
                                            onClick={() => navigate(`/store/vendor/${vendor?.vendorName}`)}>
                                <ListItemText primary={vendor.vendorName}/>
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
                <ListItemButton onClick={() => {
                    setCategorySkuDropDown({
                        show: false,
                        categorySku: null
                    })
                    setAboutDropDown({
                        show: false
                    })
                    setVendorDropDown({
                        show: !vendorDropDown.show
                    })
                    navigate('/store/promotions')
                }}>
                    <ListItemIcon>
                        <CardGiftcardOutlined/>
                    </ListItemIcon>
                    <ListItemText primary="Promotions"/>
                </ListItemButton>
                <ListItemButton onClick={() => {
                    setCategorySkuDropDown({
                        show: false,
                        categorySku: null
                    })
                    setAboutDropDown({
                        show: false
                    })
                    setVendorDropDown({
                        show: !vendorDropDown.show
                    })
                    const win = window.open('https://loyalty.is/rhl4md', '_blank');
                    win?.focus();
                }}>
                    <ListItemIcon>
                        <CardGiftcardOutlined/>
                    </ListItemIcon>
                    <ListItemText primary="Rewards"/>
                </ListItemButton>
                <ListItemButton onClick={() => {
                    setCategorySkuDropDown({
                        show: false,
                        categorySku: null
                    })
                    setAboutDropDown({
                        show: !aboutDropDown.show
                    })
                    setVendorDropDown({
                        show: false
                    })
                }}>
                    <ListItemIcon>
                        <TextSnippet/>
                    </ListItemIcon>
                    <ListItemText primary="About"/>
                    {aboutDropDown.show ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={aboutDropDown.show} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} onClick={() => navigate(`/pages/faq`)}>
                            <ListItemText primary="FAQ"/>
                        </ListItemButton>
                        <ListItemButton sx={{pl: 4}} onClick={() => navigate(`/pages/about-us`)}>
                            <ListItemText primary="About Us"/>
                        </ListItemButton>
                    </List>
                </Collapse>
                {isCustomerAuthAvailable ?
                    <ListItemButton sx={{}} onClick={() => {
                        navigate(`/${navigationParams('store')}profile`)
                    }}>
                        <ListItemIcon>
                            <Person/>
                        </ListItemIcon>
                        <ListItemText sx={{color: primary['500']}}>{authStore.auth?.userUsername}</ListItemText>

                    </ListItemButton>
                    :
                    <>
                        <ListItemButton sx={{}} onClick={() => {
                            // navigate(`/${navigationParams('store')}customer-auth/login`, {
                            //     state: {
                            //         sourceAddress: location.pathname
                            //     }
                            // })
                            setIsLoginDialogOpen(true)

                        }}><ListItemIcon>
                            <Login/>
                        </ListItemIcon> <ListItemText>
                            Login
                        </ListItemText> </ListItemButton>
                        <ListItemButton sx={{}} onClick={() => {
                            // navigate(`/${navigationParams('store')}customer-auth/sign-up`, {
                            //     state: {
                            //         sourceAddress: location.pathname
                            //     }
                            // })
                            setIsSignupDialogOpen(true)
                        }}><ListItemIcon>
                            <AppRegistration/>
                        </ListItemIcon> <ListItemText>
                            Sign Up
                        </ListItemText> </ListItemButton>
                    </>
                }
                {/*hasSelectedDeliveryMethod ? isLocalDelivery ?
                    <ListItemButton onClick={() => {
                        if (props.onDeliveryMethodDialog) {
                            props.onDeliveryMethodDialog()
                        }
                    }}>
                        <ListItemIcon>
                            <LocationOn></LocationOn>
                        </ListItemIcon>
                        <ListItemText>
                            Local Delivery
                        </ListItemText></ListItemButton>
                    :
                    <ListItemButton sx={{
                        maxLines: 1,
                    }} onClick={() => {
                        if (props.onDeliveryMethodDialog) {
                            props.onDeliveryMethodDialog()
                        }
                    }} aria-multiline={false}>
                        <ListItemIcon>
                            <LocationOn></LocationOn>
                        </ListItemIcon>
                        <ListItemText>
                            {lockboxTitle} ({capitalizeFirstLetter(cartStore.cart?.cartDeliveryMethod)})
                        </ListItemText>
                    </ListItemButton> : <ListItemButton sx={{}} onClick={() => {
                    if (props.onDeliveryMethodDialog) {
                        props.onDeliveryMethodDialog()
                    }
                }}> <ListItemIcon>
                    <LocationOn></LocationOn>
                </ListItemIcon>
                    <ListItemText>
                        Delivery Method
                    </ListItemText></ListItemButton>*/}
                <ListItemButton sx={{}} onClick={() => {
                    navigate(`/store/cart`)
                }}><ListItemIcon>
                    <ShoppingCart/>
                </ListItemIcon> <ListItemText>
                    Cart
                </ListItemText>
                    {cart && cart.cartSkus && cart.cartSkus.length > 0 && <CBox sx={{
                        borderRadius: 20,
                        minWidth: 8,
                        padding: '2px',
                        backgroundColor: 'red',
                        color: 'white',
                        fontSize: 12
                    }}>
                        {calculateItemsLength()}
                    </CBox>}
                </ListItemButton>
                {isCustomerAuthAvailable &&
                    <ListItemButton sx={{}} onClick={async () => {
                        setIsLoggingOut(true)
                        await authStore.login({
                            username: 'g.u.e.s.t',
                            password: 'guest2000@112113'
                        })
                        cartStore.saveCart({...defaultCart})
                        setIsLoggingOut(false)
                    }}>
                        <ListItemIcon>
                            {isLoggingOut ? <CCircularProgress size={22}/> : <Logout/>}
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>

                    </ListItemButton>}
            </CBox>
        </CDrawer>
        <CGridContainer mt={2}>

            {/*<CGrid item xs={9} md={3} display={"flex"} alignItems={'center'} justifyContent={'flex-start'}>*/}
            {/*    */}
            {/*</CGrid>*/}
            <CGrid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <CIconButton sx={{
                    display: menuDisplayProp
                }} onClick={() => {
                    setIsDrawerOpen(true)
                }}>
                    {/*<Menu fontSize={'large'} sx={{color: 'white'}}></Menu>*/}
                    <Menu fontSize={'large'} sx={{color: 'primary'}}></Menu>
                </CIconButton>
                {hasValue(locationStore.currentLocation?.locationSetting?.locationSettingLogo) ? <><CBox
                        sx={{
                            width: 200,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            backgroundImage: `url(${locationStore.currentLocation?.locationSetting?.locationSettingLogo})`,
                            height: 44,
                            marginRight: 8,
                            zIndex: 9,
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate('/')}
                        // @ts-ignore
                        src={locationStore.currentLocation?.locationSetting?.locationSettingLogo}/>
                    <CBox
                    sx={{
                        flex: {xs: 1, sm: 1, md: 0, lg: 0, xl: 0}

                    }}
                    />

                    </> :
                    <POSTitle sx={{
                        textAlign: "flex-start",
                        fontFamily: "Passion One",
                        // color: 'white',
                        color: 'primary',
                        zIndex: 9,
                        cursor: 'pointer',
                        mr: 2,
                        flex: {xs: 1, sm: 1, md: 0, lg: 0, xl: 0}
                    }}
                              onClick={() => navigate('/')}
                    >
                        {locationInfo?.locationName}
                    </POSTitle>}
                <CBox flex={1} display={{xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex'}}>
                    <ProductAutocomplete authToken={String(authStore.auth?.accessToken)}/>
                </CBox>
                {/*hasSelectedDeliveryMethod ? isLocalDelivery ?
                        <CButton size={'small'} variant={'outlined'} sx={{
                            color: 'white',
                            display: buttonDisplayProp
                        }}
                                 startIcon={<DeliveryDining></DeliveryDining>}
                                 onClick={() => {
                                     if (props.onDeliveryMethodDialog) {
                                         props.onDeliveryMethodDialog()
                                     }
                                 }}> Local Delivery </CButton>
                        :
                        <CButton size={'small'}
                                 startIcon={<LocationOn></LocationOn>}
                                 variant={'outlined'} sx={{
                            color: 'white',
                            maxLines: 1,
                            display: buttonDisplayProp
                        }} onClick={() => {
                            if (props.onDeliveryMethodDialog) {
                                props.onDeliveryMethodDialog()
                            }
                        }} aria-multiline={false}> {lockboxTitle} </CButton> :
                    <CButton size={'small'} variant={'outlined'} sx={{
                        color: 'white',
                        display: buttonDisplayProp
                    }} onClick={() => {
                        if (props.onDeliveryMethodDialog) {
                            props.onDeliveryMethodDialog()
                        }
                    }}> Delivery Method </CButton>*/}
                {isCustomerAuthAvailable ?
                    <CButton size={'small'} variant={'outlined'}
                             startIcon={<Person/>}
                             onClick={() => {
                                 navigate('/store/profile')
                             }}
                             sx={{
                                 ml: 2,
                                 // color: 'white',
                                 display: buttonDisplayProp
                             }}> {authStore.auth?.userUsername} </CButton>
                    :
                    <>
                        <CButton size={'small'} variant={'text'}
                                 onClick={() => {
                                     // navigate(`/${navigationParams('store')}customer-auth/login`, {
                                     //     state: {
                                     //         sourceAddress: location.pathname
                                     //     }
                                     // })
                                     setIsLoginDialogOpen(true)
                                 }}
                                 startIcon={<Login/>}
                                 sx={{
                                     ml: 2,
                                     // color: 'white',
                                     display: buttonDisplayProp
                                 }}> Login </CButton>
                        <CButton size={'small'} variant={'text'}
                                 onClick={() => {
                                     // navigate(`/${navigationParams('store')}customer-auth/sign-up`, {
                                     //     state: {
                                     //         sourceAddress: location.pathname
                                     //     }
                                     // })
                                     setIsSignupDialogOpen(true)
                                 }}
                                 startIcon={<AppRegistration/>}
                                 sx={{
                                     // color: 'white',
                                     display: buttonDisplayProp
                                 }}> Sign Up </CButton>
                    </>
                }
                {/*<CWhiteTextField*/}
                {/*    select*/}
                {/*    label={"Delivery Method"}*/}
                {/*    id="demo-simple-select-autowidth"*/}
                {/*    value={cartStore.cart?.cartDeliveryMethod}*/}
                {/*    onChange={(event) => {*/}
                {/*        const value = event.target.value;*/}
                {/*        cartStore.saveCart({...cartStore.cart, cartDeliveryMethod: value})*/}

                {/*    }}*/}
                {/*    fullWidth*/}
                {/*    sx={{*/}
                {/*        // fontColor: "white",*/}
                {/*        // color: 'white'*/}
                {/*    }}*/}
                {/*    size={"small"}*/}
                {/*>*/}
                {/*    <CMenuItem value={'lockbox'}>Lockbox</CMenuItem>*/}
                {/*    <CMenuItem value={'local-delivery'}>Local Delivery</CMenuItem>*/}
                {/*</CWhiteTextField>*/}
                <CIconButton sx={{display: menuDisplayProp}} onClick={() => {
                    if (props.onDeliveryMethodDialog) {
                        props.onDeliveryMethodDialog()
                    }
                }}>
                    {/*<DeliveryDining sx={{color: 'white'}}></DeliveryDining>*/}
                    <DeliveryDining sx={{color: 'primary'}}></DeliveryDining>
                </CIconButton>
                <CIconButton onClick={() => {
                    navigate(`/store/cart`)
                }}>
                    {/*<ShoppingCart sx={{color: 'white'}}></ShoppingCart>*/}
                    <ShoppingCart sx={{color: 'primary'}}></ShoppingCart>
                    {cart && cart.cartSkus && cart.cartSkus.length > 0 &&
                        <CBox sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            borderRadius: 20,
                            minWidth: 8,
                            padding: '2px',
                            backgroundColor: 'red',
                            color: 'white',
                            fontSize: 12
                        }}>
                            {calculateItemsLength()}
                        </CBox>}
                </CIconButton>
            </CGrid>
            {/*<CGrid xs={3}>*/}
            {/*    <CTextField fullWidth label={"Lockbox"} select*/}
            {/*                onChange={(event) => {*/}
            {/*                    const value = Number(event.target.value);*/}
            {/*                    */}
            {/*                }} value={userDialog.data.roleId}>*/}
            {/*        <CMenuItem value={1}>Admin</CMenuItem>*/}
            {/*        <CMenuItem value={2}>Kiosk Admin</CMenuItem>*/}
            {/*        <CMenuItem value={5}>Kiosk Client</CMenuItem>*/}
            {/*        <CMenuItem value={3}>Lockbox</CMenuItem>*/}
            {/*        <CMenuItem value={4}>Customer</CMenuItem>*/}
            {/*    </CTextField>*/}
            {/*</CGrid>*/}
            <CGrid xs={12} display={{xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none'}} mb={1} mt={4}>
                <ProductAutocomplete authToken={String(authStore.auth?.accessToken)}/>
            </CGrid>
            <CGrid onMouseLeave={() => {
                setCategorySkuDropDown(
                    {
                        show: false,
                        categorySku: null
                    }
                )
                setVendorDropDown(
                    {
                        show: false,
                    }
                )
                setAboutDropDown(
                    {
                        show: false
                    }
                )
            }} xs={12} display={{xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}} mb={1} mt={4}>
                {categorySkus.map((categorySku) => hasValue(categorySku?.skus) && categorySku?.skus.length > 0 && <>

                        <DropdownButton
                            onMouseIn={() => {
                                setCategorySkuDropDown(
                                    {
                                        show: true,
                                        categorySku
                                    }
                                )
                                setVendorDropDown(
                                    {
                                        show: false,
                                    }
                                )
                                setAboutDropDown(
                                    {
                                        show: false
                                    }
                                )
                            }}
                            onClick={() => {
                                navigate(`/store/category/${categorySku.category.categoryName}`)
                                setCategorySkuDropDown(
                                    {
                                        show: false,
                                        categorySku: null
                                    }
                                )
                            }}
                            text={categorySku.category.categoryName}/>

                    </>
                )}
                <DropdownButton
                    onMouseIn={() => {
                        setCategorySkuDropDown({
                            ...categorySkuDropDown,
                            show: false
                        })
                        setVendorDropDown(
                            {
                                show: true,
                            }
                        )
                        setAboutDropDown(
                            {
                                show: false
                            }
                        )
                    }}
                    onClick={() => {
                        // navigate(`/sore/category/${categorySku.category.categoryName}`)
                        setVendorDropDown(
                            {
                                show: false,
                            }
                        )
                    }}
                    text={"Shop By Brand"}/>
                <CButton
                    onMouseEnter={() => {
                        setCategorySkuDropDown({
                            ...categorySkuDropDown,
                            show: false
                        })
                        setVendorDropDown(
                            {
                                show: false,
                            }
                        )
                        setAboutDropDown(
                            {
                                show: false,
                            }
                        )
                    }}
                    onClick={()=>navigate('/store/promotions')}
                    variant={"text"}
                >Promotions</CButton>
                <CButton
                    onMouseEnter={() => {
                        setCategorySkuDropDown({
                            ...categorySkuDropDown,
                            show: false
                        })
                        setVendorDropDown(
                            {
                                show: false,
                            }
                        )
                        setAboutDropDown(
                            {
                                show: false,
                            }
                        )
                    }}
                    onClick={()=> {
                        const win = window.open('https://loyalty.is/rhl4md', '_blank');
                        win?.focus();
                    }}
                    variant={"text"}
                >Rewards</CButton>
                <DropdownButton
                    onMouseIn={() => {
                        setCategorySkuDropDown({
                            ...categorySkuDropDown,
                            show: false
                        })
                        setVendorDropDown(
                            {
                                show: false,
                            }
                        )
                        setAboutDropDown(
                            {
                                show: true,
                            }
                        )
                    }}
                    onClick={() => {
                        // navigate(`/sore/category/${categorySku.category.categoryName}`)
                        setAboutDropDown(
                            {
                                show: false,
                            }
                        )
                    }}
                    text={"About"}/>
                {categorySkuDropDown?.show && (
                    <CCard sx={{position: 'absolute', width: 'auto', zIndex: 999}}
                           onMouseLeave={() => setCategorySkuDropDown(
                               {
                                   show: false,
                                   categorySku: null
                               }
                           )}>
                        <CCardContent sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <POSTitle sx={{
                                textAlign: "center",
                                fontFamily: "Passion One",
                                color: 'primary',
                                opacity: 0.2,
                                mr: 4,
                                mb: 1
                            }}>
                                {categorySkuDropDown?.categorySku?.category.categoryName}
                            </POSTitle>
                            {categorySkuDropDown?.categorySku?.skus.map(sku => (
                                <CButton
                                    sx={{mb: 1, mr: 1}}
                                    variant={'text'}
                                    onClick={() => {
                                        navigate(`/store/product/${sku.vendor?.vendorName}-${sku.skuTitle}`)
                                        setCategorySkuDropDown(
                                            {
                                                show: false,
                                                categorySku: null
                                            }
                                        )
                                    }}
                                >
                                    {sku.skuTitle}
                                </CButton>
                            ))}
                        </CCardContent>
                    </CCard>)}
                {vendorDropDown?.show && (
                    <CCard sx={{position: 'absolute', left: '20%', width: 'auto', zIndex: 999}}
                           onMouseLeave={() => setVendorDropDown(
                               {
                                   show: false,
                               }
                           )}>
                        <CCardContent sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <POSTitle sx={{
                                textAlign: "center",
                                fontFamily: "Passion One",
                                color: 'primary',
                                opacity: 0.2,
                                mr: 4,
                                mb: 1
                            }}>
                                Brands
                            </POSTitle>
                            {currentVendors.map(vendor => (
                                <CButton
                                    sx={{mb: 1, mr: 1}}
                                    variant={'text'}
                                    onClick={() => {
                                        navigate(`/store/vendor/${vendor.vendorName}`)
                                        setVendorDropDown(
                                            {
                                                show: false
                                            }
                                        )
                                    }}
                                >
                                    {vendor.vendorName}
                                </CButton>
                            ))}
                        </CCardContent>
                    </CCard>)}
                {aboutDropDown.show && (
                    <CCard sx={{position: 'absolute', left: '30%', width: 'auto', zIndex: 999}}
                           onMouseLeave={() => setAboutDropDown(
                               {
                                   show: false,
                               }
                           )}>
                        <CCardContent sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <POSTitle sx={{
                                textAlign: "center",
                                fontFamily: "Passion One",
                                color: 'primary',
                                opacity: 0.2,
                                mr: 4,
                                mb: 1
                            }}>
                                About
                            </POSTitle>
                            <CButton
                                sx={{mb: 1, mr: 1}}
                                variant={'text'}
                                onClick={() => {
                                    navigate(`/pages/about-us`)
                                    setAboutDropDown(
                                        {
                                            show: false
                                        }
                                    )
                                }}
                            >
                                About Us
                            </CButton>
                            <CButton
                                sx={{mb: 1, mr: 1}}
                                variant={'text'}
                                onClick={() => {
                                    navigate(`/pages/faq`)
                                    setAboutDropDown(
                                        {
                                            show: false
                                        }
                                    )
                                }}
                            >
                                FAQ
                            </CButton>
                        </CCardContent>
                    </CCard>)}
            </CGrid>
        </CGridContainer>

        <ForgotPasswordDialog
            isOpen={isForgotPasswordDialogOpen}
            onClose={() => {
                setIsForgotPasswordDialogOpen(false)
            }}
            onSubmit={() => {
                setIsForgotPasswordDialogOpen(false)
                setMessageSnackbar({
                    isOpen: true,
                    message: 'A link was sent to your email.'
                })
            }}/>
        <LoginDialog isOpen={isLoginDialogOpen} onLogin={() => {
            // props.onLogin()
            setIsLoginDialogOpen(false)
        }} onForgotPassword={() => {
            setIsLoginDialogOpen(false)
            setIsForgotPasswordDialogOpen(true)
        }} onClose={() => setIsLoginDialogOpen(false)}/>
        <SignupDialog
            isOpen={isSignupDialogOpen}
            onSignup={() => {
                // props.onLogin()
                setIsSignupDialogOpen(false)
            }}
            onClose={() => setIsSignupDialogOpen(false)}
            onMessage={(message) => setMessageSnackbar({
                isOpen: true,
                message: message
            })}
        />
        <CSnackbar open={messageSnackbar.isOpen} autoHideDuration={3000} onClose={() => {
            setMessageSnackbar({
                isOpen: false,
                message: ""
            })
        }} message={messageSnackbar.message} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}/>
    </>
})