import * as React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {
    StoreCart,
    Login,
    StoreProduct,
    StoreProducts,
    StoreCategory,
    StoreInformation,
    StoreVendor,
    StoreReceipt,
    StoreOrderDetails,
    StoreProfile,
    Page,
    LockboxOrders,
    NotFound,
    ResetPassword,
    OrderScreen
} from "../screens";
import {observer} from "mobx-react-lite";
import {AdminPanelNavigator} from "./admin-panel-navigator";
import {StorePanelNavigator} from "./store-panel-navigator";
import {Helmet} from 'react-helmet';
import {currentDomain} from "../utils/domain";
import {StorePromotions} from "../screens/store/store-promotions/store-promotions-screen";

export const AppNavigator = observer(function AppNavigator() {

    let helmetTitle = ''
    const domain = currentDomain()
    if (domain === 'calibarco.com') {
        helmetTitle = 'Cali Bar Co Vape Delivery'
    } else if (domain === 'cal.express') {
        helmetTitle = 'Cal.Express Vape Delivery'
    } else {
        helmetTitle = 'Vape Delivery'
    }

    return (
        <div className="AppNavigator" style={{
            flexGrow: 1,
            // backgroundColor: "#F5F5F5"
            // backgroundImage: `url(${bgNoise})`
        }}>
            <Helmet>
                <meta property="og:title" content={helmetTitle}/>
                <meta property="og:description"
                      content="Shop Vape for a premium selection. We offer vape delivery in the United States and Canada."/>
                <meta property="og:image" content="/logo512.png"/>
            </Helmet>

            <Routes>
                {/*<Route path="/" element={<Home/>}/>*/}
                {/*<Route path="/" element={<Landing/>}/>*/}
                {/*<Route path="/" element={<Navigate to={'store/disclaimer'}/>}/>*/}
                <Route path="/" element={<StoreProducts/>}/>

                {/*Default Login Route*/}
                <Route path="/system-login" element={<Login/>}/>

                {/* Information Routes */}
                <Route path="/:mode/information" element={<StoreInformation/>}/>

                {/* Cart Routes */}
                <Route path="/store/products" element={<StoreProducts/>}/>


                {/* Cart Routes */}
                <Route path="/store/promotions" element={<StorePromotions/>}/>

                {/* Cart Routes */}
                <Route path="/store/category/:categoryName" element={<StoreCategory/>}/>

                {/* Cart Routes */}
                <Route path="/store/cart" element={<StoreCart/>}/>

                {/* Cart Routes */}
                <Route path="/store/product/:vendor_name" element={<StoreProduct/>}/>

                {/* Cart Routes */}
                <Route path="/store/vendor/:vendorName" element={<StoreVendor/>}/>

                {/* Receipt Routes */}
                <Route path="/:mode/receipt" element={<StoreReceipt/>}/>

                {/* Order Details Routes */}
                <Route path="/:mode/order-details" element={<StoreOrderDetails/>}/>

                {/* Order Details Routes */}
                <Route path="/store/profile" element={<StoreProfile/>}/>

                {/* Order Details Routes */}
                <Route path="/pages/:slug" element={<Page/>}/>

                {/* Lockbox Routes */}
                <Route path="/lockbox/orders" element={
                    // <RequireAuth redirectTo="/login/admin" prefix={"lockbox"} roleName={"Lockbox"}>
                    <LockboxOrders/>
                    // </RequireAuth>
                }/>
                {/* Admin Panel Routes */}
                <Route path="/admin-panel/*" element={
                    // <RequireAuth redirectTo="/login/lockbox" prefix={"admin"} roleName={"Admin"}>
                    <AdminPanelNavigator/>
                    // </RequireAuth>
                }/>

                {/* Store Panel Routes */}
                <Route path="/store-admin-panel/*" element={
                    // <RequireAuth redirectTo="/login/lockbox" prefix={"admin"} roleName={"Admin"}>
                    <StorePanelNavigator/>
                    // </RequireAuth>
                }/>

                {/* Not found */}
                <Route path="/not-found" element={<NotFound redirect={true}/>}/>

                {/* Reset Password */}
                <Route path="/reset-password/:token" element={<ResetPassword/>}/>

                {/* Order */}
                <Route path="/order/:token" element={<OrderScreen/>}/>

                {/* Not found route */}
                <Route path="*" element={<NotFound redirect={true}/>} />

            </Routes>
        </div>
    );
})
