import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {UserModel} from "../user/user";
import {LocationModel} from "../location/location";
import {OrderDetailModel} from "../order-detail/order-detail";
import {CustomerModel} from "../customer/customer";

/**
 * Rick and Morty character model.
 */
export const OrderModel = types.model("Order").props({
    orderId: types.identifierNumber,
    orderTs: types.maybeNull(types.string),
    orderTotal: types.maybeNull(types.number),
    orderStatus: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    kioskId: types.maybeNull(types.number),
    lockboxId: types.maybeNull(types.number),
    customerId: types.maybeNull(types.number),
    deliveryMethod: types.maybeNull(types.string),
    localDeliveryAddress: types.maybeNull(types.string),
    orderBillingAddress: types.maybeNull(types.string),
    orderBillingName: types.maybeNull(types.string),
    orderCustomerFullName: types.maybeNull(types.string),
    orderCardNo: types.maybeNull(types.string),
    orderNo: types.maybeNull(types.string),
    orderHash: types.maybeNull(types.string),
    orderPickupDateTime: types.maybeNull(types.string),
    orderFee: types.maybeNull(types.number),
    orderDeliveryFee: types.maybeNull(types.number),
    orderTax: types.maybeNull(types.number),
    orderCustomerNote: types.maybeNull(types.string),
    orderLocationNote: types.maybeNull(types.string),
    orderCreatedAt: types.maybeNull(types.string),
    orderUpdatedAt: types.maybeNull(types.string),
    orderDetails: types.optional(types.array(OrderDetailModel), []),
    customer: types.optional(types.maybeNull(CustomerModel), {customerId: -1}),
    user: types.optional(types.maybeNull(UserModel), {userId: -1}),
    kiosk: types.optional(types.maybeNull(LocationModel), {locationId: -1}),
    lockbox: types.optional(types.maybeNull(LocationModel), {locationId: -1}),
    orderTotalItems: types.maybeNull(types.number),
    orderTotalGross: types.maybeNull(types.number),
    basicBundleId: types.maybeNull(types.number),
    orderCartHash: types.maybeNull(types.string),
    orderFlag: types.maybeNull(types.string),
})

type OrderType = Instance<typeof OrderModel>
export interface Order extends OrderType {}
type OrderSnapshotType = SnapshotOut<typeof OrderModel>
export interface OrderSnapshot extends OrderSnapshotType {}

export const defaultOrder = {
    orderId: undefined,
    orderTs: '',
    orderTotal: undefined,
    orderStatus: '',
    orderNo: '',
    orderHash: '',
    orderPickupDateTime: '',
    orderTax: undefined,
    orderFee: undefined,
    orderDeliveryFee: undefined,
    userId: undefined,
    kioskId: undefined,
    lockboxId: undefined,
}