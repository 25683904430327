import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {UserLocationsApi} from "../../services/api/user-locations-api"
import {UserLocationModel, UserLocationSnapshot} from "../user-location/user-location"
import {logInfo} from "../../utils/logs"
import {
    UserLocationCreateParams,
    UserLocationDeleteParams,
    UserLocationFindAllParams,
    UserLocationFindOneParams,
    UserLocationUpdateParams
} from "../../services/api";

function userLocationStoreLog(functionName, message) {
    logInfo({
        fileName: "userLocation-store.ts",
        className: "userLocationStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const UserLocationStoreModel = types
    .model("UserLocationStore")
    .props({
        userLocation: types.optional(types.maybeNull(UserLocationModel), null),
        userLocations: types.optional(types.array(UserLocationModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveUserLocation: (userLocationSnapshot: UserLocationSnapshot) => {
            try {
                self.userLocation = cast(userLocationSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    userLocationStoreLog("saveUserLocation", e.message)
                }
                // recordError(e)
            }
        },
        saveUserLocations: (userLocationsSnapshot: UserLocationSnapshot[]) => {
            try {
                self.userLocations = cast([...userLocationsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    userLocationStoreLog("saveUserLocation", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: UserLocationCreateParams, token: string) {
            self.environment.addAuth(token)
            const userLocationApi = new UserLocationsApi(self.environment.api)
            const result = yield userLocationApi.create(params)


            if (result.kind === "ok") {
                self.saveUserLocation(result.userLocation)
            } else {
                userLocationStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: UserLocationFindAllParams, token: string) {
            self.environment.addAuth(token)
            const userLocationApi = new UserLocationsApi(self.environment.api)
            const result = yield userLocationApi.findAll(params)


            if (result.kind === "ok") {
                self.saveUserLocations(result.userLocation)
            } else {
                userLocationStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: UserLocationFindOneParams, token: string) {
            self.environment.addAuth(token)
            const userLocationApi = new UserLocationsApi(self.environment.api)
            const result = yield userLocationApi.findOne(params)


            if (result.kind === "ok") {
                self.saveUserLocation(result.userLocation)
            } else {
                userLocationStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: UserLocationUpdateParams, token: string) {
            self.environment.addAuth(token)
            const userLocationApi = new UserLocationsApi(self.environment.api)
            const result = yield userLocationApi.update(params)


            if (result.kind === "ok") {
                self.saveUserLocation(result.userLocation)
            } else {
                userLocationStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: UserLocationDeleteParams, token: string) {
            self.environment.addAuth(token)
            const userLocationApi = new UserLocationsApi(self.environment.api)
            return yield userLocationApi.remove(params)
        }),

    }))

type UserLocationStoreType = Instance<typeof UserLocationStoreModel>

export interface UserLocationStore extends UserLocationStoreType {
}

type UserLocationStoreSnapshotType = SnapshotOut<typeof UserLocationStoreModel>

export interface UserLocationStoreSnapshot extends UserLocationStoreSnapshotType {
}

export const createUserLocationStoreDefaultModel = () => types.optional(UserLocationStoreModel, {})
