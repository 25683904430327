import {CListItemButton, CListItemIcon, CListItemText} from "../mui-extracted";
import * as React from "react";

export const PanelDrawerItem = (props) => {

    const {icon ,text, onClick, selected, open} = props

    return (
        <CListItemButton
            key={text}
            onClick={onClick}
            selected={selected}
            sx={{
                m: 1,
                borderRadius: 4,
            }}
        >
            <CListItemIcon sx={{color: theme => theme.palette.primary.light}}>
                {icon}
            </CListItemIcon>
            <CListItemText
                primary={open ? text : "|"}
                sx={{
                    color: '#212121',/// theme => theme.palette.primary.light,
                    opacity: open ? 1 : 0,
                }}
            />
        </CListItemButton>
    )
}