import {
    CBox,
    CButton,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {isEmpty} from "validate.js";
import {Table} from "../table";
import * as React from "react";

export const LockboxesDialog = (props) => {

    const {
        lockboxesDialog,
        setLockboxesDialog,
        lockboxes,
        handleLocationStoreLockboxCreate,
        handleLocationStoreLockboxRemove,
        isCreatingLocationStoreLockbox,
        handleClose,
    } = props

    return (
        <CDialog
            open={lockboxesDialog.isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={"lg"}
            PaperProps={{
                sx: {
                    borderRadius: "37px"
                }
            }}
        >
            <CGridContainer spacing={2} p={2} alignItems={'center'}>
                <CGrid item xs={4}>
                    <CTypography fontSize={"24px"} ml={4}>
                        {lockboxesDialog.storeName} Lockboxes
                    </CTypography>
                </CGrid>
                <CGrid item xs={3}>
                    <CTextField fullWidth size={'small'} label={"Lockbox"} select
                                onChange={(event) => {
                                    const value = Number(event.target.value);
                                    setLockboxesDialog({
                                        ...lockboxesDialog,
                                        lockboxId: value
                                    });
                                }} value={lockboxesDialog.lockboxId ? lockboxesDialog.lockboxId : ''}>
                        {lockboxes.map(lockbox => {
                            return <CMenuItem
                                value={Number(lockbox.locationId)}>{lockbox.locationName}</CMenuItem>
                        })}
                    </CTextField>
                </CGrid>
                <CGrid item xs={2}>
                    <CButton
                        variant={"contained"}
                        disabled={!lockboxesDialog.lockboxId}
                        onClick={handleLocationStoreLockboxCreate}
                    >
                        {isCreatingLocationStoreLockbox ? <CCircularProgress sx={{color: "white"}}
                                                                             size={24}/> : 'Add'}
                    </CButton>
                </CGrid>
            </CGridContainer>
            <Divider></Divider>
            <CGridContainer spacing={0} p={0}>
                <CGrid item xs={12} maxHeight={'70vh'} sx={{
                    overflowY: 'scroll',
                }}>
                    {isEmpty(lockboxesDialog.data) ?
                        <CTypography m={4} textAlign={'center'} variant={'body1'}> No lockboxes found
                            for store. </CTypography> : <Table
                            columns={[
                                {
                                    field: 'locationId',
                                    headerName: 'ID',
                                    width: 80,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'locationName',
                                    headerName: 'Name',
                                    width: 150,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'actions',
                                    headerName: 'Action',
                                    width: 300,
                                    headerClassName: "pos-table-header"
                                    ,
                                    cellClassName: "pos-table-cell",
                                    renderCell: (params) => {

                                        const onRemoveClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handleLocationStoreLockboxRemove(params.row.locationId)
                                            // return alert(JSON.stringify(thisRow, null, 4));
                                        };

                                        return <>
                                            <CButton variant={"contained"} color={"error"} size={"small"}
                                                     sx={{height: '32px', mr: 2}}
                                                     onClick={onRemoveClick}>Remove</CButton>
                                        </>;
                                    }
                                }
                            ]}
                            rows={lockboxesDialog.data}
                            count={lockboxesDialog.data.length}
                            page={0}
                            rowsPerPage={100}
                            onPageChange={() => {
                                console.log('tmp')
                            }}
                            onRowsPerPageChange={() => {
                                console.log('tmp')
                            }}
                            orderBy={'tmp'}
                            order={'DESC'}
                            onRequestSort={() => {
                                console.log('tmp')
                            }}
                            isLoading={false}
                            noRowTitleOnSmallSize={true}
                        />}
                </CGrid>
            </CGridContainer>
            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
            </CBox>
        </CDialog>
    )
}