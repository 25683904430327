import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const BundleModel = types.model("Bundle").props({
    bundleId: types.number,
    bundleTitle: types.maybeNull(types.string),
    bundleDesc: types.maybeNull(types.string),
    bundleStatus: types.maybeNull(types.string),
    bundleImage: types.maybeNull(types.string),
    bundleDefaultPrice: types.maybeNull(types.number),
    bundleValidFrom: types.maybeNull(types.string),
    bundleValidTo: types.maybeNull(types.string),
    bundleCreatedAt: types.maybeNull(types.string),
    bundleUpdatedAt: types.maybeNull(types.string)
})

type BundleType = Instance<typeof BundleModel>
export interface Bundle extends BundleType {}
type BundleSnapshotType = SnapshotOut<typeof BundleModel>
export interface BundleSnapshot extends BundleSnapshotType {}

export const defaultBundle = {
    bundleId: undefined,
    bundleTitle: '',
    bundleDesc: '',
    bundleStatus: 'inactive',
    bundleImage: '',
    bundleDefaultPrice: undefined,
    bundleValidFrom: '',
    bundleValidTo: ''
}