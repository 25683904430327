import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton, CCancelOrderConfirmationDialog,
    CCard,
    CCheckbox, CCircularProgress,
    CContainer,
    CFormControlLabel,
    CGrid,
    CGridContainer,
    CTypography,
    POSTitle,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBackIosNew} from "@mui/icons-material";
import {CIconButton} from "../../../components";
import {Divider} from "../../../components";
import VisaPng from "../../../assets/bank-logo/visa.png";
import {navigationParams} from "../../../utils/navigation";
import {useStores} from "../../../models";
import {useEffect, useState} from "react";
import {useAppInit} from "../../../hooks/use-app-init";
import {CheckoutBreadcrumb} from "../../../components/checkout-breadcrumb";
import {hasValue} from "../../../utils/empty-check";
import ReactGA from "react-ga4";
import {getConfig} from "../../../utils/config";
import {getSkuPrice} from "../../../utils/sku";
import {cartBundleId} from "../../../utils/cart";
import {OrderDetailCreateParams} from "../../../services/api";
import {ensureNumber} from "../../../utils/type";
import {CartSkuSnapshot} from "../../../models/cart-sku/cart-sku";
import {types} from "mobx-state-tree";

let lastOrderId = null
let statelessCartTotal = 0.00
let statelessLocationFee = 0.00
let statelessLocationDeliveryFee = 0.00
let statelessTaxUSD = 0.00
let statelessIsCreatingOrder = false

export const StoreOrderDetails = observer(
    function StoreOrderDetails() {
        const navigate = useNavigate();

        const params = useParams();

        const guestAuth = useAppInit()
        const {guestAuthToken, locationInfo, locationInfoState} = guestAuth

        const {
            cartStore,
            previousCartStore,
            authStore,
            orderStore,
            orderDetailStore,
            paymentStore,
            locationStore,
            configStore,
            posLogStore,
            gatewayStore
        } = useStores()
        const {configs} = configStore
        const {gateway} = gatewayStore

        const [isCreatingOrder, setIsCreatingOrder] = useState(false)
        const [agreeChecked, setAgreeChecked] = useState(false)
        const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)
        const [paymentError, setPaymentError] = useState<string | null>(null)
        const [locationFee, setLocationFee] = useState<number>(0.00)
        const [locationDeliveryFee, setLocationDeliveryFee] = useState<number>(0.00)
        const [taxUSD, setTaxUSD] = useState<number>(0.00)
        const [cartTotal, setCartTotal] = useState<number>(0.00)
        const [isCalculatingTotal, setIsCalculatingTotal] = useState<boolean>(false)

        const calculateCartTotal = () => {

            statelessCartTotal = 0.00

            if (cartStore.cart) {
                const currentCartSkus = [...cartStore.cart.cartSkus]
                if (currentCartSkus.length > 0) {
                    currentCartSkus.forEach(cartSku => {
                        statelessCartTotal += (cartSku.cartSkuAmount - cartSku.cartSkuFreeItemsAmount) * Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount)
                    })
                }

                let cartTaxMultiplier = 0
                const locationTaxPercent = locationStore.currentLocation?.locationSetting?.locationSettingTaxPercent
                // console.log("locationTaxPercent", locationTaxPercent);
                if (locationTaxPercent && locationTaxPercent > 0) {
                    cartTaxMultiplier = locationTaxPercent / 100
                }
                if (cartTaxMultiplier && cartTaxMultiplier > 0) {
                    statelessTaxUSD = cartTaxMultiplier * statelessCartTotal
                    statelessCartTotal += (statelessTaxUSD)
                    setTaxUSD(statelessTaxUSD)
                }

                if (locationStore.currentLocation?.locationSetting?.locationSettingFee) {
                    statelessLocationFee = locationStore.currentLocation?.locationSetting?.locationSettingFee
                    setLocationFee(statelessLocationFee)
                }
                if (statelessLocationFee) {
                    statelessCartTotal += statelessLocationFee
                }

                // if (cartStore.cart?.cartDeliveryMethod === 'local-delivery') {
                if (locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee) {
                    statelessLocationDeliveryFee = locationStore.currentLocation?.locationSetting?.locationSettingDeliveryFee
                    setLocationDeliveryFee(statelessLocationDeliveryFee)
                }
                if (statelessLocationDeliveryFee) {
                    statelessCartTotal += statelessLocationDeliveryFee
                }

                setCartTotal(statelessCartTotal)
                // }
            }
            setIsCalculatingTotal(false)
        }

        let cartScreenStrings = getConfig(configs, 'cart-screen-strings')
        const internalLogsEnabled = getConfig(configs, 'internal-logs-enabled') === 'true'

        if (cartScreenStrings) {
            cartScreenStrings = JSON.parse(cartScreenStrings)
        }

        const createPosLog = (paymentId, orderId, payload) => {
            if (internalLogsEnabled && authStore.auth?.accessToken) {
                posLogStore.create({
                    paymentId: paymentId ? paymentId : undefined,
                    orderId: orderId ? orderId : undefined,
                    userId: cartStore.cart?.cartUserId ? Number(cartStore.cart?.cartUserId) : undefined,
                    customerId: cartStore.cart?.cartCustomerId ? Number(cartStore.cart?.cartCustomerId) : undefined,
                    storeId: locationInfo.locationId ? ensureNumber(locationInfo.locationId) : undefined,
                    logType: 'error',
                    logTag: 'fe.payment',
                    logModule: 'store',
                    logPayload: payload
                }, authStore.auth.accessToken)
            }
        }

        const handleAgreeCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setAgreeChecked(event.target.checked);
        };

        function cardType(input) {
            const t = {
                visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
                mastercard: /^5[1-5][0-9]{14}$/,
                amex: /^3[47][0-9]{13}$/,
                diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                jcb: /^(?:2131|1800|35\d{3})\d{11}$/
            };
            for (const n in t) {
                if (t[n].test(input)) {
                    return n
                }
            }

            // return ''
            return 'visa' /// For debug
        }

        const createOrder = async () => {

            if (!cartStore.cart) {
                return
            }

            let formattedPickupDate = ''

            if (cartStore.cart?.cartPickupDate) {
                // @ts-ignore
                const utcIsoStringDate = new Date(new Date(cartStore.cart?.cartPickupDate).toUTCString()).toISOString()
                formattedPickupDate = `${utcIsoStringDate.split('T')[0]} ${utcIsoStringDate.split('T')[1].split('.')[0]}`
            }
            // console.log('createOrder', formattedPickupDate)
            // return {kind: 'not-ok'}

            calculateCartTotal()

            const orderCreateData = {
                orderTotal: Number(statelessCartTotal.toFixed(2)),
                orderStatus: 'unpaid',
                userId: cartStore.cart?.cartUserId ? Number(cartStore.cart?.cartUserId) : undefined,
                kioskId: cartStore.cart?.cartKioskId ? Number(cartStore.cart?.cartKioskId) : undefined,
                kioskMode: cartStore.cart?.cartKioskMode ? String(cartStore.cart?.cartKioskMode) : undefined,
                lockboxId: cartStore.cart?.cartLockboxId ? Number(cartStore.cart?.cartLockboxId) : undefined,
                customerId: cartStore.cart?.cartCustomerId ? Number(cartStore.cart?.cartCustomerId) : undefined,
                deliveryMethod: cartStore.cart?.cartDeliveryMethod ? cartStore.cart?.cartDeliveryMethod : undefined,
                localDeliveryAddress: cartStore.cart?.cartDeliveryFullAddress ? cartStore.cart?.cartDeliveryFullAddress : undefined,
                orderPickupDateTime: cartStore.cart?.cartPickupDate ? formattedPickupDate : undefined,
                orderTax: cartStore.cart?.cartTax ? cartStore.cart?.cartTax : undefined,
                orderFee: cartStore.cart?.cartFee ? cartStore.cart?.cartFee : undefined,
                orderDeliveryFee: cartStore.cart?.cartDeliveryFee ? cartStore.cart?.cartDeliveryFee : undefined,
                orderCustomerNote: cartStore.cart?.cartCustomerNote ? cartStore.cart?.cartCustomerNote : undefined,
                orderBillingAddress: `${cartStore.cart.cartInfoAddress1 ? cartStore.cart.cartInfoAddress1 : ''} ${cartStore.cart.cartInfoAddress2 ? cartStore.cart.cartInfoAddress2 : ''}, ${cartStore.cart.cartInfoCity ? cartStore.cart.cartInfoCity : ''}, ${cartStore.cart.cartInfoState ? cartStore.cart.cartInfoState : ''} ${cartStore.cart.cartInfoZip ? cartStore.cart.cartInfoZip : ''}`,
                orderBillingName: cartStore.cart.cartInfoCardPayer ? cartStore.cart.cartInfoCardPayer : undefined,
                orderCustomerFullName: `${cartStore.cart.cartInfoFirstName} ${cartStore.cart.cartInfoLastName}`,
                orderCardNo: cartStore.cart.cartInfoCardNo ? cartStore.cart.cartInfoCardNo.slice(-4) : undefined,
                basicBundleId: cartBundleId(cartStore.cart.cartSkus),
                orderCartHash: cartStore?.cart?.cartHash
            }
            // @ts-ignore
            return await orderStore.create(orderCreateData, guestAuthToken)
        }

        const purchase = async (orderId) => {
            if (!cartStore.cart) {
                return null
            }
            if (!authStore.auth) {
                return null
            }
            const purchaseData = {
                orderId: Number(orderId),
                paymentStoreId: Number(locationInfo.locationId),
                // amount: Number(cartTotal.toFixed(2).replace('.','')),
                amount: Number(statelessCartTotal.toFixed(2)),
                cardType: cardType(cartStore.cart.cartInfoCardNo),
                cardHolderName: String(cartStore.cart.cartInfoCardPayer),
                cardNumber: String(cartStore.cart.cartInfoCardNo),
                cardExpDate: `${cartStore.cart.cartInfoCardMonth}${cartStore.cart.cartInfoCardYear}`,
                cardCVV: `${cartStore.cart.cartInfoCardCVC}`,
                basicBundleId: cartBundleId(cartStore.cart.cartSkus),
                paymentCartHash: cartStore?.cart?.cartHash ? cartStore?.cart?.cartHash : undefined,
                useCustomerProfile: cartStore?.cart?.useCustomerProfile,
                customerProfileId: cartStore?.cart?.customerProfileId,
                customerPaymentProfileId: cartStore?.cart?.customerPaymentProfileId,
                savePaymentInfo: cartStore?.cart?.savePaymentInfo
            }

            console.log('purchaseData', purchaseData)

            return await paymentStore.purchase(purchaseData, authStore.auth.accessToken)
        }

        // const removeOrderDetails = async (orderId) => {
        //   const orderDetailsResult = await orderDetailStore.findAll({
        //     orderId
        //   }, guestAuthToken)
        //   if (orderDetailsResult.kind === 'ok') {
        //     for (const orderDetail of orderDetailsResult.orderDetails) {
        //       await orderDetailStore.remove({
        //         orderDetailId: orderDetail.orderDetailId
        //       }, guestAuthToken)
        //     }
        //   }
        // }

        // const removeOrder = async (orderId) => {
        //   await orderStore.remove({
        //     orderId,
        //   }, guestAuthToken)
        //   await removeOrderDetails(orderId)
        // }

        const createOrderDetails = async (orderId) => {
            if (!cartStore.cart) {
                return null
            }
            for (let i = 0; i < cartStore.cart?.cartSkus.length; i++) {
                const cartSku = cartStore.cart?.cartSkus[i]
                const currentOrderDetailData: OrderDetailCreateParams = {
                    orderId: orderId,
                    skuId: cartSku.cartSkuSku.skuId,
                    skuQuantity: cartSku.cartSkuAmount,
                    skuPrice: Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                    skuPriceId: Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceId),
                    basicBundleId: 0
                }
                if (cartSku.cartSkuBundleId && cartSku.cartSkuBundleId > 0) {
                    console.log('createOrderDetails.cartSkuBundleId', cartSku.cartSkuBundleId && cartSku.cartSkuBundleId > 0, cartSku.cartSkuBundleId)
                    currentOrderDetailData.basicBundleId = (!hasValue(cartSku.cartSkuFreeItemsAmount) || cartSku.cartSkuFreeItemsAmount === 0 ) ?
                        0 :
                        cartSku.cartSkuBundleId
                    currentOrderDetailData.orderDetailFreeItemsCount =
                        (!hasValue(cartSku.cartSkuFreeItemsAmount) || cartSku.cartSkuFreeItemsAmount === 0 ) ?
                            undefined :
                            cartSku.cartSkuFreeItemsAmount
                }
                console.log('createOrderDetails', currentOrderDetailData)
                await orderDetailStore.create(currentOrderDetailData, guestAuthToken)
            }
            return 'success'
        }

        const testHandlePayPress = async () => {
            /// Test
            calculateCartTotal()

            if (!cartStore.cart) {
                return
            }
            if (!cartStore.cart?.cartSkus) {
                return
            }
            console.log('handlePayPress', {
                    orderTotal: Number(statelessCartTotal.toFixed(2)),
                    orderStatus: 'unpaid',
                    userId: cartStore.cart?.cartUserId ? Number(cartStore.cart?.cartUserId) : undefined,
                    kioskId: cartStore.cart?.cartKioskId ? Number(cartStore.cart?.cartKioskId) : undefined,
                    kioskMode: cartStore.cart?.cartKioskMode ? String(cartStore.cart?.cartKioskMode) : undefined,
                    lockboxId: cartStore.cart?.cartLockboxId ? Number(cartStore.cart?.cartLockboxId) : undefined,
                    customerId: cartStore.cart?.cartCustomerId ? Number(cartStore.cart?.cartCustomerId) : undefined,
                    deliveryMethod: cartStore.cart?.cartDeliveryMethod ? cartStore.cart?.cartDeliveryMethod : undefined,
                    localDeliveryAddress: cartStore.cart?.cartDeliveryFullAddress ? cartStore.cart?.cartDeliveryFullAddress : undefined,
                    orderTax: cartStore.cart?.cartTax ? cartStore.cart?.cartTax : undefined,
                    orderFee: cartStore.cart?.cartFee ? cartStore.cart?.cartFee : undefined,
                    orderDeliveryFee: cartStore.cart?.cartDeliveryFee ? cartStore.cart?.cartDeliveryFee : undefined,
                    orderCustomerNote: cartStore.cart?.cartCustomerNote ? cartStore.cart?.cartCustomerNote : undefined,
                },
                {
                    orderId: 123,
                    paymentStoreId: Number(locationInfo.locationId),
                    // amount: Number(cartTotal.toFixed(2).replace('.','')),
                    amount: Number(statelessCartTotal.toFixed(2)),
                    cardType: cardType(cartStore.cart.cartInfoCardNo),
                    cardHolderName: String(cartStore.cart.cartInfoCardPayer),
                    cardNumber: String(cartStore.cart.cartInfoCardNo),
                    cardExpDate: `${cartStore.cart.cartInfoCardMonth}${cartStore.cart.cartInfoCardYear}`,
                    cardCVV: `${cartStore.cart.cartInfoCardCVC}`
                })
            return
            /// End test

        }

        const archiveCartAndFinalize = () => {
            previousCartStore.savePreviousCart({...cartStore.cart})

            navigate(`/${navigationParams(params.mode)}receipt`)

            cartStore.saveCart({
                ...cartStore.cart,
                cartSkus: [],
                cartInfoCardNo: '',
                cartDeliveryFullAddress: '',
                cartInfoCardCVC: '',
                cartCustomerNote: '',
                cartInfoCardMonth: '',
                cartInfoCardYear: '',
                cartInfoCardPayer: '',
                cartInfoFirstName: '',
                cartInfoLastName: '',
                cartInfoPhone: '',
                cartInfoEmail: '',
                cartInfoAddress1: '',
                cartInfoAddress2: '',
                cartInfoCity: '',
                cartInfoState: '',
                cartInfoZip: '',

            })
        }

        const logPurchaseResults = async (orderId: number) => {
            const currentOrderResult = await orderStore.findOne({orderId}, guestAuthToken)
            cartStore.saveCart({
                ...cartStore.cart,
                cartOrderId: orderId,
                cartOrderNo: currentOrderResult.order.orderNo,
                cartOrderHash: currentOrderResult.order.orderHash,
            })

            const gaItems: any[] = []
            if (!cartStore.cart?.cartSkus) {
                return
            }
            for (let i = 0; i < cartStore.cart?.cartSkus?.length; i++) {

                const cartSku = cartStore.cart?.cartSkus[i]
                const currentGaItem = {
                    item_id: cartSku.cartSkuSku.skuId,
                    item_name: cartSku.cartSkuSku.skuHasParent ? cartSku.cartSkuSku.skuCode + ' - ' + cartSku.cartSkuVendor?.vendorName + ' - ' + cartSku.cartSkuParent?.skuTitle + ' - ' + cartSku.cartSkuSku.skuTitle : cartSku.cartSkuVendor?.vendorName + ' - ' + cartSku.cartSkuSku.skuTitle,
                    price: Number(getSkuPrice(cartSku.cartSkuParent?.prices, locationStore.currentLocation?.locationId)?.priceAmount),
                    quantity: cartSku.cartSkuAmount
                }
                if (hasValue(cartSku.cartSkuBundleId) && cartSku.cartSkuBundleId && cartSku.cartSkuBundleId > 0) {
                    currentGaItem['promotion_id'] = cartSku.cartSkuBundleId
                    currentGaItem['promotion_name'] = cartSku.cartSkuBundleTitle
                }
                gaItems.push(currentGaItem)
            }

            ReactGA.event("purchase", {
                transaction_id: currentOrderResult.order.orderNo,
                value: 25.42,
                tax: 0.00,
                shipping: 0.00,
                currency: "USD",
                items: gaItems
            })

            console.log("ReactGA", "StoreOrderDetailsScreen", "purchase", {
                transaction_id: currentOrderResult.order.orderNo,
                value: 25.42,
                tax: 0.00,
                shipping: 0.00,
                currency: "USD",
                items: gaItems
            })
        }

        const handlePayPress = async () => {


            if (!authStore.auth) {
                return
            }
            if (!cartStore.cart) {
                return
            }
            if (isCreatingOrder) {
                return
            }
            if (statelessIsCreatingOrder) {
                return
            }
            if (statelessCartTotal === undefined || statelessCartTotal === null) {
                setPaymentError("Something went wrong please go back and try again.")
                createPosLog(null, null, 'No Cart Total')
                return
            }


            setPaymentError(null)
            setIsCreatingOrder(true)
            statelessIsCreatingOrder = true

            let orderId = null
            let alreadyExists = false
            if (lastOrderId === null) {
                const result = await createOrder()

                if (result.kind !== 'ok') {
                    setPaymentError('Failed to create the order. Please try again.')
                    createPosLog(null, null, 'Failed to create the order.')
                    setIsCreatingOrder(false)
                    statelessIsCreatingOrder = false
                    return
                }

                alreadyExists = hasValue(result?.order?.message) && result?.order?.message === 'Already Exists.'

                console.log('alreadyExists', alreadyExists)

                orderId = alreadyExists ? result?.order?.orderId : result.order.identifiers[0].orderId

                console.log('orderId', orderId)

                if (orderId !== null && !alreadyExists) {
                    console.log('createOrderDetails')
                    await createOrderDetails(orderId)
                }

                lastOrderId = orderId

            } else {
                orderId = lastOrderId
            }

            if (orderId === null && !alreadyExists) {
                setPaymentError('Payment failed. Please try again.')
                createPosLog(null, null, 'No order id.')
                return;
            }

            const purchaseResult = await purchase(orderId)

            if (purchaseResult === null) {
                // await removeOrder(orderId)
                setPaymentError('Payment failed. Please try again.')
                createPosLog(null, orderId, 'No payment result.')
                return;
            }

            if (purchaseResult.kind === "ok" && purchaseResult.data.status === 'successful') {
                try {
                    if (!orderId) {
                        archiveCartAndFinalize()
                        return
                    }
                    await logPurchaseResults(orderId)
                    archiveCartAndFinalize()
                } catch (e) {
                    if (e instanceof Error) {
                        createPosLog(null, orderId, "Post successful purchase issue" + e?.message)
                    }
                    navigate(`/${navigationParams(params.mode)}receipt`)
                }
            } else {
                // await removeOrder(orderId)
                if (purchaseResult?.data?.message) {
                    createPosLog(null, orderId, purchaseResult?.data?.message)
                    if (purchaseResult?.data?.message.includes('errorMessage')) {
                        try {
                            setPaymentError(JSON.parse(purchaseResult?.data?.message).errorMessage)
                            console.log(purchaseResult?.data?.message)
                        } catch (e) {
                            console.log(e, purchaseResult?.data?.message)
                        }
                    } else {
                        setPaymentError(purchaseResult?.data?.message)
                    }
                } else {
                    setPaymentError('Please check your email, order status or call customer support to check your order and payment status.')
                    createPosLog(null, orderId, `Unknown error. (${purchaseResult.kind})`)
                }
            }
            setIsCreatingOrder(false)
            statelessIsCreatingOrder = false
        }

        // useEffect(() => {
        // }, [])

        const getDefaultGateway = async () => {
            await gatewayStore.findOne({
                gatewayId: Number(getConfig(configs, 'default-gateway-id'))
            }, guestAuthToken)
        }

        useEffect(() => {

            ;(async () => {
                lastOrderId = null
                setIsCalculatingTotal(true)
                document.title = `Order Details`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Order Details | ${locationInfo.locationName}`;
                    calculateCartTotal()
                    getDefaultGateway()
                }
            })()

        }, [locationInfo, locationInfoState])

        return (
            <>

                <main style={{minHeight: '100vh'}}>

                    {/*<CBox sx={{*/}
                    {/*  position: 'absolute', top: 0, right: 0, left: 0, bottom: 0*/}
                    {/*}}>*/}
                    <CContainer sx={{display: 'flex', flexDirection: 'column',}}>
                        <CGridContainer flex={1} spacing={2}>
                            <CGrid item xs={12} mt={2}>
                                <CheckoutBreadcrumb allowedPages={['information', 'payment']}
                                                    currentPage={'payment'}/>
                            </CGrid>
                            <CGrid item xs={12} md={12} mb={2} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CCard sx={{
                                    width: "100%",
                                    // height: '90vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <CBox sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: '100%',
                                        alignItems: "center"
                                    }}>
                                        <CBox sx={{flex: 1, pl: 4}}>
                                            <CIconButton
                                                onClick={() => navigate(`/${navigationParams(params.mode)}information`)}
                                            >
                                                <ArrowBackIosNew></ArrowBackIosNew>
                                            </CIconButton>
                                        </CBox>

                                        <POSTitle sx={{
                                            mb: 2,
                                            mt: 2,
                                            textAlign: "center",
                                            fontFamily: "Passion One"
                                        }}>
                                            Order Details
                                        </POSTitle>
                                        <CBox sx={{flex: 1}}></CBox>
                                    </CBox>
                                    <Divider></Divider>
                                    <CBox sx={{flex: 1, width: "100%", overflowY: "scroll"}}>
                                        <CGridContainer p={2}>
                                            <CTypography sx={{fontWeight: "400", color: "#424242", ml: 1}}>
                                                Please confirm the details before payment
                                            </CTypography>
                                        </CGridContainer>
                                        <CGridContainer p={2}>

                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Items
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                {cartStore.cart && cartStore.cart.cartSkus.map(cartSku => <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {cartSku.cartSkuSku.skuCode} x
                                                    </CTypography>
                                                    <CTypography>
                                                        {cartSku.cartSkuAmount}
                                                    </CTypography>
                                                </CBox>)}
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    {cartStore.cart?.cartDeliveryMethod === "lockbox" ? "Lockbox" : "Deliver To"}
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    minHeight: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mr: 2
                                                }}>
                                                    <CTypography>
                                                        {cartStore.cart?.cartDeliveryMethod === "lockbox" ?
                                                            cartStore.cart?.cartLockboxText
                                                            :
                                                            `${cartStore.cart?.cartDeliveryFullAddress}`
                                                        }
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Card
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10}>
                                                <CBox sx={{
                                                    border: "1px solid #757575",
                                                    borderRadius: "18px",
                                                    display: "flex", flexDirection: "column",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mr: 2,
                                                    width: "205px",
                                                    py: "14px"
                                                }}>
                                                    <CBox sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        mb: "29px"
                                                    }}>
                                                        <CTypography sx={{fontWeight: "400", flex: 1}}>
                                                            **** **** **** {cartStore.cart?.cartInfoCardNo?.slice(-4)}
                                                        </CTypography>
                                                        <img src={VisaPng} style={{
                                                            width: 41,
                                                            height: 41,
                                                            objectFit: "contain"
                                                        }}/>
                                                    </CBox>
                                                    <CBox sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row"
                                                    }}>
                                                        <CTypography sx={{fontWeight: "400", flex: 1}}>
                                                            {cartStore.cart?.cartInfoCardPayer}
                                                        </CTypography>
                                                        <CTypography sx={{fontWeight: "400"}}>
                                                            {cartStore.cart?.cartInfoCardMonth}/{cartStore.cart?.cartInfoCardYear}
                                                        </CTypography>
                                                    </CBox>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Customer info
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${cartStore.cart?.cartInfoFirstName} ${cartStore.cart?.cartInfoLastName}`}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${cartStore.cart?.cartInfoPhone}`}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${cartStore.cart?.cartInfoEmail}`}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    minHeight: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${cartStore.cart?.cartInfoAddress1} ${cartStore.cart?.cartInfoAddress2}, ${cartStore.cart?.cartInfoCity} ${cartStore.cart?.cartInfoState} ${cartStore.cart?.cartInfoZip}`}
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>

                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Tax & Fee
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {/* @ts-ignore*/}
                                                        {cartScreenStrings?.deliveryFee ? cartScreenStrings?.deliveryFee : 'Delivery Fee'}
                                                    </CTypography>
                                                    {isCalculatingTotal ?
                                                        <CCircularProgress sx={{ml: 2}} size={24}></CCircularProgress> :
                                                        <CTypography sx={{ml: 2}}>
                                                            ${locationDeliveryFee?.toFixed(2)}
                                                        </CTypography>}
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {/* @ts-ignore*/}
                                                        {cartScreenStrings?.fee ? cartScreenStrings?.fee : 'Fee'}
                                                    </CTypography>
                                                    {isCalculatingTotal ?
                                                        <CCircularProgress sx={{ml: 2}} size={24}></CCircularProgress> :
                                                        <CTypography sx={{ml: 2}}>
                                                            ${locationFee?.toFixed(2)}
                                                        </CTypography>}
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {/* @ts-ignore*/}
                                                        {cartScreenStrings?.tax ? cartScreenStrings?.tax : 'Tax'}
                                                    </CTypography>
                                                    {isCalculatingTotal ?
                                                        <CCircularProgress sx={{ml: 2}} size={24}></CCircularProgress> :
                                                        <CTypography sx={{ml: 2}}>
                                                            ${taxUSD?.toFixed(2)}
                                                        </CTypography>}
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Total
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10}>
                                                {isCalculatingTotal ?
                                                    <CCircularProgress size={32}></CCircularProgress> :
                                                    <CTypography sx={{fontWeight: "400", fontSize: "24px"}}>
                                                        ${cartTotal.toFixed(2)}
                                                    </CTypography>}
                                            </CGrid>
                                        </CGridContainer>
                                    </CBox>
                                    <Divider></Divider>
                                    <CGridContainer spacing={2} p={2}>
                                        <CGrid item xs={12}>
                                            <CFormControlLabel
                                                control={<CCheckbox checked={agreeChecked}
                                                                    onChange={handleAgreeCheckedChange}/>}
                                                label={`I agree with this charge on my card for the total of $${cartTotal.toFixed(2)}.`}/>
                                            {gateway && <CTypography sx={{color: 'grey'}}>
                                                {`I understand that the charge on my statement will be shown as ${gateway?.gatewayName}.`}
                                            </CTypography>}
                                        </CGrid>
                                        <CGrid item xs={12} md={8}>
                                            {/*<CFormControlLabel control={<CCheckbox/>} label="Print the receipt."/>*/}
                                            {hasValue(paymentError) && <CTypography color={'red'}
                                                                                    variant={'body1'}>{paymentError}</CTypography>}
                                        </CGrid>
                                        {/*<CGrid item xs={12} md={4}>*/}
                                        {/*    <CButton fullWidth variant={"outlined"} disabled={isCreatingOrder} onClick={() => {*/}
                                        {/*        // navigate(`/${navigationParams(params.mode)}disclaimer`)*/}
                                        {/*        // setShowCancelOrderDialog(true)*/}
                                        {/*        navigate('/')*/}
                                        {/*    }}>*/}
                                        {/*        Back to Store*/}
                                        {/*    </CButton>*/}
                                        {/*</CGrid>*/}
                                        <CGrid item xs={12} md={4}>
                                            <CButton disabled={
                                                !agreeChecked ||
                                                isCalculatingTotal ||
                                                isCreatingOrder ||
                                                (paymentError !== null && paymentError.length > 0)
                                            } fullWidth variant={"contained"}
                                                     onClick={handlePayPress}
                                            >
                                                {isCreatingOrder ?
                                                    <CCircularProgress
                                                        sx={{color: "white"}}
                                                        size={24}
                                                    /> : 'Pay'}

                                            </CButton>
                                        </CGrid>

                                    </CGridContainer>


                                </CCard>


                            </CGrid>
                        </CGridContainer>
                    </CContainer>
                    {/*</CBox>*/}
                </main>
                <CCancelOrderConfirmationDialog
                    mode={String(params.mode)}
                    open={showCancelOrderDialog}
                    onDecline={() => {
                        setShowCancelOrderDialog(false)
                    }}
                />
            </>
        );

    }
)
