import {CBox} from "../mui-extracted";
import React from "react";
import {BoxProps} from "@mui/material/Box";

export function ScrollDrag(props: BoxProps) {
    const { children, ...other } = props;

    // const [isScrolling, setIsScrolling] = useState(false)
    // const [clientX, setClientX] = useState(0)
    // const [scrollX, setScrollX] = useState(0)


    // const onMouseDown = e => {
    //     setIsScrolling(true)
    //     setClientX(e.clientX)
    // };
    //
    // const onMouseUp = () => {
    //     setIsScrolling(false)
    // };
    //
    // const onMouseMove = e => {
    //     if (isScrolling) {
    //         setClientX(e.clientX)
    //         setScrollX(scrollX + e.clientX - clientX)
    //     }
    // };

    return (
        <CBox
            // onMouseDown={onMouseDown}
            // onMouseUp={onMouseUp}
            // onMouseMove={onMouseMove}
            className={'hidden-scrollbar'}
            {...other}
        >
            {children}
        </CBox>
    );
}
