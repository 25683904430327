import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {SkuImagesApi} from "../../services/api/sku-image-api"
import {SkuImageModel, SkuImageSnapshot} from "../sku-image/sku-image"
import {logInfo} from "../../utils/logs"
import {
    SkuImageCreateParams,
    SkuImageDeleteParams,
    SkuImageFindAllParams,
    SkuImageFindOneParams,
    SkuImageUpdateParams
} from "../../services/api";

function skuImageStoreLog(functionName, message) {
    logInfo({
        fileName: "skuImage-store.ts",
        className: "skuImageStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const SkuImageStoreModel = types
    .model("SkuImageStore")
    .props({
        skuImage: types.optional(types.maybeNull(SkuImageModel), null),
        skuImages: types.optional(types.array(SkuImageModel), []),
        isGettingSkuImages: types.optional(types.boolean, false),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveSkuImage: (skuImageSnapshot: SkuImageSnapshot) => {
            try {
                self.skuImage = skuImageSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    skuImageStoreLog("saveSkuImage", e.message)
                }
                // recordError(e)
            }
        },
        saveSkuImages: (skuImagesSnapshot: SkuImageSnapshot[]) => {
            try {
                detach(self.skuImages)
                self.skuImages = cast([...skuImagesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    skuImageStoreLog("saveSkuImage", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: SkuImageCreateParams, token: string) {
            self.environment.addAuth(token)
            const skuImageApi = new SkuImagesApi(self.environment.api)
            const result = yield skuImageApi.create(params)


            // if (result.kind === "ok") {
            //     self.saveSkuImage(result.skuImage)
            // } else {
            //     skuImageStoreLog("login", result.kind)
            // }
            return result
        }),
        findAll: flow(function* (params: SkuImageFindAllParams, token: string) {
            self.isGettingSkuImages = true
            self.environment.addAuth(token)
            const skuImageApi = new SkuImagesApi(self.environment.api)
            const result = yield skuImageApi.findAll(params)


            if (result.kind === "ok") {
                self.saveSkuImages(result.skuImages)
            } else {
                skuImageStoreLog("login", result.kind)
            }
            self.isGettingSkuImages = false
            return result
        }),
        findOne: flow(function* (params: SkuImageFindOneParams, token: string) {
            self.environment.addAuth(token)
            const skuImageApi = new SkuImagesApi(self.environment.api)
            const result = yield skuImageApi.findOne(params)


            if (result.kind === "ok") {
                self.saveSkuImage(result.skuImage)
            } else {
                skuImageStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: SkuImageUpdateParams, token: string) {
            self.environment.addAuth(token)
            const skuImageApi = new SkuImagesApi(self.environment.api)
            const result = yield skuImageApi.update(params)


            if (result.kind === "ok") {
                self.saveSkuImage(result.skuImage)
            } else {
                skuImageStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: SkuImageDeleteParams, token: string) {
            self.environment.addAuth(token)
            const skuImageApi = new SkuImagesApi(self.environment.api)
            return yield skuImageApi.remove(params)
        }),

    }))

type SkuImageStoreType = Instance<typeof SkuImageStoreModel>

export interface SkuImageStore extends SkuImageStoreType {
}

type SkuImageStoreSnapshotType = SnapshotOut<typeof SkuImageStoreModel>

export interface SkuImageStoreSnapshot extends SkuImageStoreSnapshotType {
}

export const createSkuImageStoreDefaultModel = () => types.optional(SkuImageStoreModel, {})
