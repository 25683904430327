import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Promotional banner model.
 */
export const PromotionalBannerModel = types.model("PromotionalBanner").props({
    promotionalBannerId: types.number,
    promotionalBannerTitle: types.maybeNull(types.string),
    promotionalBannerBgColor: types.maybeNull(types.string),
    promotionalBannerTitleColor: types.maybeNull(types.string),
    promotionalBannerSubtitleColor: types.maybeNull(types.string),
    promotionalBannerDescriptions: types.maybeNull(types.string),
    promotionalBannerImageUrl: types.maybeNull(types.string),
    promotionalBannerStatus: types.maybeNull(types.string),
    promotionalBannerValidFrom: types.maybeNull(types.string),
    promotionalBannerValidTo: types.maybeNull(types.string),
    promotionalBannerCreatedAt: types.maybeNull(types.string),
    promotionalBannerUpdatedAt: types.maybeNull(types.string),
    promotionalBannerDeleted: types.maybeNull(types.number),
    locationId: types.maybeNull(types.number),
})


type PromotionalBannerType = Instance<typeof PromotionalBannerModel>
export interface PromotionalBanner extends PromotionalBannerType {}
type PromotionalBannerSnapshotType = SnapshotOut<typeof PromotionalBannerModel>
export interface PromotionalBannerSnapshot extends PromotionalBannerSnapshotType {}

export const defaultPromotionalBanner = {
    promotionalBannerId: undefined,
    promotionalBannerTitle: '',
    promotionalBannerDescriptions: '',
    promotionalBannerImage: '',
    promotionalBannerStatus: '',
    promotionalBannerValidFrom: '',
    promotionalBannerValidTo: '',
    promotionalBannerCreatedAt: '',
    promotionalBannerUpdatedAt: ''
}
