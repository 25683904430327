import dayjs, {Dayjs} from "dayjs";
import 'dayjs/plugin/timezone';

dayjs.extend(require('dayjs/plugin/timezone'));

export const addCurrentTimezone = (date: Dayjs): string => {
    // Get the current timezone offset in minutes
    const timezoneOffset = dayjs().format('Z');

    // Format the date string including the timezone offset
    return date.format(`YYYY-MM-DDTHH:mm:ss${timezoneOffset}`);
}

export const convertToCurrentTimezone = (date: string, format?: string): string => {
    const convertedDate = dayjs(date).tz();

    return convertedDate.format(format ? format : 'YYYY-MM-DD HH:mm:ss')
}

export function isTodayBetweenDates(startDate: string | null, endDate: string | null): boolean {
    const today = new Date()
    if (!startDate || !endDate) {
        return false
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);


    return start <= today && today <= end;
}

export function stringToDayjs(dateString: string): Dayjs | null {
    const date = dayjs(dateString);

    if (date.isValid()) {
        return date;
    }

    return null;
}