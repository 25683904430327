import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {BundlesApi} from "../../services/api/bundles-api"
import {BundleModel, BundleSnapshot} from "../bundle/bundle"
import {logInfo} from "../../utils/logs"
import {
    BundleCreateParams,
    BundleDeleteParams,
    BundleFindAllParams,
    BundleFindOneParams,
    BundleUpdateParams
} from "../../services/api";

function bundleStoreLog(functionName, message) {
    logInfo({
        fileName: "bundle-store.ts",
        className: "bundleStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const BundleStoreModel = types
    .model("BundleStore")
    .props({
        bundle: types.optional(types.maybeNull(BundleModel), null),
        bundles: types.optional(types.array(BundleModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveBundle: (bundleSnapshot: BundleSnapshot) => {
            try {
                self.bundle = cast(bundleSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    bundleStoreLog("saveBundle", e.message)
                }
                // recordError(e)
            }
        },
        saveBundles: (bundlesSnapshot: BundleSnapshot[]) => {
            try {
                detach(self.bundles)
                self.bundles = cast([...bundlesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    bundleStoreLog("saveBundle", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: BundleCreateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.bundleImages
            // @ts-ignore
            delete params.prices

            const bundleApi = new BundlesApi(self.environment.api)
            const result = yield bundleApi.create(params)


            if (result.kind === "ok") {
                self.saveBundle(result.bundle)
            } else {
                bundleStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: BundleFindAllParams, token: string) {
            self.environment.addAuth(token)
            const bundleApi = new BundlesApi(self.environment.api)
            const result = yield bundleApi.findAll(params)

            if (result.kind === "ok") {
                self.saveBundles(result.bundles)
            } else {
                bundleStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: BundleFindOneParams, token: string) {
            self.environment.addAuth(token)
            const bundleApi = new BundlesApi(self.environment.api)
            const result = yield bundleApi.findOne(params)


            if (result.kind === "ok") {
                self.saveBundle(result.bundle)
            } else {
                bundleStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: BundleUpdateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.bundleImages
            // @ts-ignore
            delete params.prices

            const bundleApi = new BundlesApi(self.environment.api)
            const result = yield bundleApi.update(params)


            if (result.kind === "ok") {
                self.saveBundle(result.bundle)
            } else {
                bundleStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: BundleDeleteParams, token: string) {
            self.environment.addAuth(token)
            const bundleApi = new BundlesApi(self.environment.api)
            return yield bundleApi.remove(params)
        }),

    }))

type BundleStoreType = Instance<typeof BundleStoreModel>

export interface BundleStore extends BundleStoreType {
}

type BundleStoreSnapshotType = SnapshotOut<typeof BundleStoreModel>

export interface BundleStoreSnapshot extends BundleStoreSnapshotType {
}

export const createBundleStoreDefaultModel = () => types.optional(BundleStoreModel, {})
