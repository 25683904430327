import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {BundleModel} from "../bundle/bundle";
import {SkuModel} from "../sku/sku";

/**
 * Rick and Morty character model.
 */
export const BundleSkuModel = types.model("Sku").props({
    bundleSkuId: types.number,
    bundleId: types.maybeNull(types.number),
    skuId: types.maybeNull(types.number),
    bundleSkuCreatedAt: types.maybeNull(types.string),
    bundleSkuUpdatedAt: types.maybeNull(types.string),
    bundle: types.optional(types.maybeNull(BundleModel), {bundleId: -1}),
    sku: types.optional(types.maybeNull(SkuModel), {skuId: -1})
})

type BundleSkuType = Instance<typeof BundleSkuModel>
export interface BundleSku extends BundleSkuType {}
type BundleSkuSnapshotType = SnapshotOut<typeof BundleSkuModel>
export interface BundleSkuSnapshot extends BundleSkuSnapshotType {}

export const defaultBundleSku = {
    bundleSkuId: undefined,
    bundleId: undefined,
    skuId: undefined
}