import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CAlert,
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CContainer,
    CGrid,
    CGridContainer,
    CSnackbar,
    CTextField,
    CTypography,
    DeliveryMethodDialog,
    DisclaimerDialog,
    Divider,
    Footer,
    POSTitle,
} from "../../../components";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppInit} from "../../../hooks/use-app-init";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {CheckCircleOutline} from "@mui/icons-material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {useStores} from "../../../models";
import {Order} from "../../../models/order/order";
import {getCustomerAddress, getCustomerEmail, getCustomerName, getCustomerPhone} from "../../../utils/customer";
import {getConfig} from "../../../utils/config";
import {getLocationFullAddress} from "../../../utils/location";
import {hasValue} from "../../../utils/empty-check";

export const OrderScreen = observer(
    function OrderScreen() {

        const navigate = useNavigate();

        const {locationStore, orderStore, configStore, cartStore, authStore} = useStores()
        const {configs} = configStore
        const {auth, adminAuth, storeAdminAuth, lockboxAuth} = authStore

        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, guestAuthState} = guestAuth

        const params = useParams();
        const {token} = params

        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState<{
            isOpen: boolean;
            skuTitle: null | string;
            vendorName: null | undefined | string
        }>({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const [messageSnackbar, setMessageSnackbar] = React.useState<{
            isOpen: boolean;
            message: string;
            severity: AlertColor,
            verticalAnchor: "bottom" | "top"
        }>({isOpen: false, message: '', severity: 'info', verticalAnchor: 'bottom'})
        const [isLocked, setIsLocked] = React.useState(true)
        const [email, setEmail] = React.useState('')
        const [isGettingOrder, setIsGettingOrder] = React.useState(true)
        const [order, setOrder] = React.useState<Order | null>(null)

        const shouldDisplayOrder = () => {
            /// is admin/ store admin
            if (adminAuth || storeAdminAuth ||lockboxAuth) {
                setIsLocked(false)
                return;
            }
            /// Is logged in & has the same email
            if (auth?.userEmail === getCustomerEmail(order?.customer, order?.user)) {
                setIsLocked(false)
                return;
            }
            /// Entered email is the same as the order email
            if (email === getCustomerEmail(order?.customer, order?.user)) {
                setIsLocked(false)
                return;
            }
        }

        const loadOrder = async () => {
            const result = await orderStore.findAll({
                orderHash: token,
            }, guestAuthToken, false)
            if (result.kind === 'ok' && result.orders.length > 0) {
                setOrder(result.orders[0])
            }
        }

        const init = async () => {
            setIsGettingOrder(true)
            await loadOrder()
            setIsGettingOrder(false)
        }

        useEffect(() => {
            ;(async () => {
                if (guestAuthState === 'loaded') {
                    await init()
                }
            })()
        }, [guestAuthToken, guestAuthState, params]);

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = `Order`;
        }, [])

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        let cartScreenStrings = getConfig(configs, 'cart-screen-strings')

        if (cartScreenStrings) {
            cartScreenStrings = JSON.parse(cartScreenStrings)
        }

        return (
            <>
                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>


                        <CGridContainer sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CCard sx={{
                                width: (!isGettingOrder && isLocked) ? 300 : "100%",
                                // height: '90vh',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                {isLocked ?
                                    <CBox sx={{
                                        padding: 3,
                                        flexGrow: 1,
                                        width: 260
                                    }}>
                                        <CTypography sx={{ mb: 3, }}>
                                            Enter your email address:
                                        </CTypography>
                                        <CTextField
                                            fullWidth
                                            label={"Email"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setEmail(event.target.value)
                                            }} value={email}
                                        >

                                        </CTextField>
                                        <CBox sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                            <CButton sx={{mt:2}} variant={'contained'} onClick={shouldDisplayOrder}>
                                                Unlock
                                            </CButton>
                                        </CBox>

                                    </CBox>
                                    : <>
                                    <CBox sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: '100%',
                                        alignItems: "center"
                                    }}>
                                        <CBox sx={{flex: 1, pl: 4}}>
                                            <CheckCircleOutline fontSize={"large"} sx={{color: "#4CAF50"}}>

                                            </CheckCircleOutline>
                                        </CBox>

                                        <POSTitle sx={{
                                            mb: 2,
                                            mt: 2,
                                            textAlign: "center",
                                            fontFamily: "Passion One"
                                        }}>
                                            Receipt
                                        </POSTitle>
                                        <CBox sx={{flex: 1}}></CBox>
                                    </CBox>
                                    <Divider></Divider>
                                    <CBox sx={{flex: 1, width: "100%", overflowY: "scroll"}}>

                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Order No
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        #{order?.orderNo}
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Name
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${getCustomerName(order?.customer, order?.user)}`}
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    Items
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                {order?.orderDetails && order?.orderDetails?.map(orderDetail => <CBox
                                                    sx={{
                                                        width: "auto",
                                                        border: "1px solid #757575",
                                                        height: "40px", borderRadius: "18px",
                                                        display: "flex", flexDirection: "row",
                                                        px: 2,
                                                        alignItems: "center",
                                                        mb: 1,
                                                        mr: 1
                                                    }}>
                                                    <CTypography>
                                                        {orderDetail.skuQuantity} x {orderDetail?.sku?.vendor?.vendorName} - {orderDetail?.sku?.parentSku?.skuTitle} - {orderDetail?.sku?.skuTitle} (
                                                    </CTypography>
                                                    {hasValue(orderDetail.skuQuantity) && hasValue(orderDetail.skuPrice) && <CTypography>
                                                        {/* @ts-ignore */}
                                                        {orderDetail.skuQuantity * orderDetail.skuPrice})
                                                    </CTypography>}
                                                </CBox>)}
                                            </CGrid>
                                        </CGridContainer>
                                        <Divider></Divider>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                    {order?.deliveryMethod === "lockbox" ? "Lockbox" : "Deliver To"}
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    minHeight: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {order?.deliveryMethod === "lockbox" ?
                                                            getLocationFullAddress(order?.lockbox)
                                                            :
                                                            `${order?.localDeliveryAddress}`
                                                        }
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>
                                        <CGridContainer p={2}>
                                            <CGrid item xs={3} md={2}>
                                                <CTypography sx={{ml: 1, fontWeight: "600",}}>
                                                    Customer info
                                                </CTypography>
                                            </CGrid>
                                            <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${getCustomerName(order?.customer, order?.user)}`}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${getCustomerPhone(order?.customer, order?.user)}`}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${getCustomerEmail(order?.customer, order?.user)}`}
                                                    </CTypography>
                                                </CBox>
                                                <CBox sx={{
                                                    width: "auto",
                                                    border: "1px solid #757575",
                                                    height: "40px", borderRadius: "18px",
                                                    display: "flex", flexDirection: "row",
                                                    px: 2,
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mr: 1
                                                }}>
                                                    <CTypography>
                                                        {`${getCustomerAddress(order?.customer, order?.user)}`}
                                                    </CTypography>
                                                </CBox>
                                            </CGrid>
                                        </CGridContainer>

                                    </CBox>
                                    <Divider></Divider>
                                    <CGridContainer p={2}>

                                        <CGrid item xs={3} md={2}>
                                            <CTypography sx={{fontWeight: "600", ml: 1}}>
                                                Tax & Fee
                                            </CTypography>
                                        </CGrid>
                                        <CGrid item xs={9} md={10} display={"flex"} flexWrap={'wrap'}>
                                            <CBox sx={{
                                                width: "auto",
                                                border: "1px solid #757575",
                                                height: "40px", borderRadius: "18px",
                                                display: "flex", flexDirection: "row",
                                                px: 2,
                                                alignItems: "center",
                                                mb: 1,
                                                mr: 1
                                            }}>
                                                <CTypography>
                                                    {/* @ts-ignore*/}
                                                    {cartScreenStrings?.deliveryFee ? cartScreenStrings?.deliveryFee : 'Delivery Fee'}
                                                </CTypography>
                                                <CTypography sx={{ml: 2}}>
                                                    ${order?.orderDeliveryFee?.toFixed(2)}
                                                </CTypography>
                                            </CBox>
                                            <CBox sx={{
                                                width: "auto",
                                                border: "1px solid #757575",
                                                height: "40px", borderRadius: "18px",
                                                display: "flex", flexDirection: "row",
                                                px: 2,
                                                alignItems: "center",
                                                mb: 1,
                                                mr: 1
                                            }}>
                                                <CTypography>
                                                    {/* @ts-ignore*/}
                                                    {cartScreenStrings?.fee ? cartScreenStrings?.fee : 'Fee'}
                                                </CTypography>
                                                <CTypography sx={{ml: 2}}>
                                                    ${order?.orderFee?.toFixed(2)}
                                                </CTypography>
                                            </CBox>
                                            <CBox sx={{
                                                width: "auto",
                                                border: "1px solid #757575",
                                                height: "40px", borderRadius: "18px",
                                                display: "flex", flexDirection: "row",
                                                px: 2,
                                                alignItems: "center",
                                                mb: 1,
                                                mr: 1
                                            }}>
                                                <CTypography>
                                                    {/* @ts-ignore*/}
                                                    {cartScreenStrings?.tax ? cartScreenStrings?.tax : 'Tax'}
                                                </CTypography>
                                                <CTypography sx={{ml: 2}}>
                                                    ${order?.orderTax?.toFixed(2)}
                                                </CTypography>
                                            </CBox>
                                        </CGrid>
                                    </CGridContainer>
                                    <Divider></Divider>
                                    <CGridContainer p={2}>
                                        <CTypography sx={{fontWeight: "bold", color: "#4CAF50", ml: 1,}}>
                                            Thank you for your purchase! Your order #{order?.orderNo} has been
                                            successfully received. Please check your email for detailed information
                                            regarding your order.
                                        </CTypography>
                                    </CGridContainer>
                                    <CGridContainer spacing={2} p={2}>
                                        <CGrid item xs={12} md={12}>
                                            <CTypography sx={{fontWeight: "600", fontSize: "24px"}}>
                                                {`Total Amount Paid:   $${order?.orderTotal?.toFixed(2)}`}
                                            </CTypography>
                                        </CGrid>
                                    </CGridContainer>
                                </>}

                            </CCard>
                        </CGridContainer>

                    </CContainer>
                    <Footer/>
                </main>
                <CSnackbar open={messageSnackbar.isOpen} autoHideDuration={3000} onClose={() => {
                    setMessageSnackbar({
                        isOpen: false,
                        message: "",
                        severity: 'info',
                        verticalAnchor: 'bottom'
                    })
                }} anchorOrigin={{
                    vertical: messageSnackbar.verticalAnchor,
                    horizontal: 'right'
                }}>
                    <CAlert onClose={() => {
                        setMessageSnackbar({
                            isOpen: false,
                            message: "",
                            severity: 'info',
                            verticalAnchor: 'bottom'
                        })
                    }} severity={messageSnackbar.severity} sx={{width: '100%'}}>
                        {messageSnackbar.message}
                    </CAlert>
                </CSnackbar>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );
    }
)
