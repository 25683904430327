import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {CogsApi} from "../../services/api/cogs-api"
import {CogsModel, CogsSnapshot} from "../cogs/cogs"
import {logInfo} from "../../utils/logs"
import {
    CogsCreateParams,
    CogsDeleteParams,
    CogsFindAllParams,
    CogsFindOneParams,
    CogsUpdateParams
} from "../../services/api";

function cogsStoreLog(functionName, message) {
    logInfo({
        fileName: "cogs-store.ts",
        className: "cogsStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const CogsStoreModel = types
    .model("CogsStore")
    .props({
        cogs: types.optional(types.maybeNull(CogsModel), null),
        cogses: types.optional(types.array(CogsModel), []),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveCogs: (cogsSnapshot: CogsSnapshot) => {
            try {
                self.cogs = cogsSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    cogsStoreLog("saveCogs", e.message)
                }
                // recordError(e)
            }
        },
        saveCogses: (cogsesSnapshot: CogsSnapshot[]) => {
            try {
                detach(self.cogses);
                self.cogses = cast([...cogsesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    cogsStoreLog("saveCogses", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: CogsCreateParams, token: string) {
            self.environment.addAuth(token)
            const cogsApi = new CogsApi(self.environment.api)
            const result = yield cogsApi.create(params)


            if (result.kind === "ok") {
                self.saveCogs(result.cogs)
            } else {
                cogsStoreLog("create", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: CogsFindAllParams, token: string) {
            self.environment.addAuth(token)
            const cogsApi = new CogsApi(self.environment.api)
            const result = yield cogsApi.findAll(params)


            if (result.kind === "ok") {
                self.saveCogses(result.cogs)
            } else {
                cogsStoreLog("findAll", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: CogsFindOneParams, token: string) {
            self.environment.addAuth(token)
            const cogsApi = new CogsApi(self.environment.api)
            const result = yield cogsApi.findOne(params)


            if (result.kind === "ok") {
                self.saveCogs(result.cogs)
            } else {
                cogsStoreLog("findOne", result.kind)
            }
            return result
        }),
        update: flow(function* (params: CogsUpdateParams, token: string) {
            self.environment.addAuth(token)
            const cogsApi = new CogsApi(self.environment.api)
            const result = yield cogsApi.update(params)


            if (result.kind === "ok") {
                self.saveCogs(result.cogs)
            } else {
                cogsStoreLog("update", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: CogsDeleteParams, token: string) {
            self.environment.addAuth(token)
            const cogsApi = new CogsApi(self.environment.api)
            return yield cogsApi.remove(params)
        }),

    }))

type CogsStoreType = Instance<typeof CogsStoreModel>

export interface CogsStore extends CogsStoreType {
}

type CogsStoreSnapshotType = SnapshotOut<typeof CogsStoreModel>

export interface CogsStoreSnapshot extends CogsStoreSnapshotType {
}

export const createCogsStoreDefaultModel = () => types.optional(CogsStoreModel, {})
