import { cast, detach, flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"
import { PromotionalBannersApi } from "../../services/api/promotional-banners-api"
import { PromotionalBannerModel, PromotionalBannerSnapshot } from "../promotional-banner/promotional-banner"
import { logInfo } from "../../utils/logs"
import {
    PromotionalBannerCreateParams,
    PromotionalBannerDeleteParams,
    PromotionalBannerFindAllParams,
    PromotionalBannerFindOneParams,
    PromotionalBannerUpdateParams
} from "../../services/api";

function promotionalBannerStoreLog(functionName, message) {
    logInfo({
        fileName: "promotional-banner-store.ts",
        className: "PromotionalBannerStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const PromotionalBannerStoreModel = types
    .model("PromotionalBannerStore")
    .props({
        promotionalBanner: types.optional(types.maybeNull(PromotionalBannerModel), null),
        promotionalBanners: types.optional(types.array(PromotionalBannerModel), []),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        savePromotionalBanner: (promotionalBannerSnapshot: PromotionalBannerSnapshot) => {
            try {
                self.promotionalBanner = promotionalBannerSnapshot
            } catch (e) {
                if (e instanceof Error) {
                    promotionalBannerStoreLog("savePromotionalBanner", e.message)
                }
                // recordError(e)
            }
        },
        savePromotionalBanners: (promotionalBannersSnapshot: PromotionalBannerSnapshot[]) => {
            try {
                detach(self.promotionalBanners);
                self.promotionalBanners = cast([...promotionalBannersSnapshot])
            } catch (e) {
                if (e instanceof Error) {
                    promotionalBannerStoreLog("savePromotionalBanners", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: PromotionalBannerCreateParams, token: string) {
            self.environment.addAuth(token)
            const promotionalBannerApi = new PromotionalBannersApi(self.environment.api)
            const result = yield promotionalBannerApi.create(params)

            if (result.kind === "ok") {
                self.savePromotionalBanner(result.promotionalBanner)
            } else {
                promotionalBannerStoreLog("create", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: PromotionalBannerFindAllParams, token: string) {
            self.environment.addAuth(token)
            const promotionalBannerApi = new PromotionalBannersApi(self.environment.api)
            const result = yield promotionalBannerApi.findAll(params)

            if (result.kind === "ok") {
                self.savePromotionalBanners(result.promotionalBanners)
            } else {
                promotionalBannerStoreLog("findAll", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: PromotionalBannerFindOneParams, token: string) {
            self.environment.addAuth(token)
            const promotionalBannerApi = new PromotionalBannersApi(self.environment.api)
            const result = yield promotionalBannerApi.findOne(params)

            if (result.kind === "ok") {
                self.savePromotionalBanner(result.promotionalBanner)
            } else {
                promotionalBannerStoreLog("findOne", result.kind)
            }
            return result
        }),
        update: flow(function* (params: PromotionalBannerUpdateParams, token: string) {
            self.environment.addAuth(token)
            const promotionalBannerApi = new PromotionalBannersApi(self.environment.api)
            const result = yield promotionalBannerApi.update(params)

            if (result.kind === "ok") {
                self.savePromotionalBanner(result.promotionalBanner)
            } else {
                promotionalBannerStoreLog("update", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: PromotionalBannerDeleteParams, token: string) {
            self.environment.addAuth(token)
            const promotionalBannerApi = new PromotionalBannersApi(self.environment.api)
            return yield promotionalBannerApi.remove(params)
        }),
    }))

type PromotionalBannerStoreType = Instance<typeof PromotionalBannerStoreModel>

export interface PromotionalBannerStore extends PromotionalBannerStoreType {
}

type PromotionalBannerStoreSnapshotType = SnapshotOut<typeof PromotionalBannerStoreModel>

export interface PromotionalBannerStoreSnapshot extends PromotionalBannerStoreSnapshotType {
}

export const createPromotionalBannerStoreDefaultModel = () => types.optional(PromotionalBannerStoreModel, {})
