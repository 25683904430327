import {Api} from "./api";
import {ApiResponse} from "apisauce";
import {getGeneralApiProblem} from "./api-problem";

export class CustomerProfilesApi {
    private api: Api

    constructor(api: Api) {
        this.api = api
    }


    async authorize(userId: number) {
        try {
            // const functionName = "login"
            const uri = "customer-profiles/authorize/"+userId
            // recordServiceRequestLog(uri, functionName, params)
            // make the api call
            const response: ApiResponse<any> = await this.api.apisauce.get(uri)
            // the typical ways to die when calling an api
            if (!response.ok) {
                const problem = getGeneralApiProblem(response)
                if (problem) {
                    // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
                    return problem
                }
            }

            // console.log('authorize', response)

            const profile = response.data

            // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
            return {kind: "ok", profile}
        } catch (e) {
            // recordError(e)
            return {kind: "bad-data"}
        }
    }

    async remove(userId: number) {
        try {
            // const functionName = "login"
            const uri = "customer-profiles/"+userId
            // recordServiceRequestLog(uri, functionName, params)
            // make the api call
            const response: ApiResponse<any> = await this.api.apisauce.delete(uri)
            // the typical ways to die when calling an api
            if (!response.ok) {
                const problem = getGeneralApiProblem(response)
                if (problem) {
                    // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
                    return problem
                }
            }

            // console.log('remove', response)

            // const profile = response.data

            // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
            return {kind: "ok"}
        } catch (e) {
            // recordError(e)
            return {kind: "bad-data"}
        }
    }

}