import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CCircularProgress,
    CContainer,
    CGridContainer,
    DisclaimerDialog,
    DeliveryMethodDialog,
    Footer, CGrid, CCard, CButton, CTypography, CCardContent, CBox,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {hasValue} from "../../../utils/empty-check";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {BundleCard} from "../../../components/bundle-card";
import {isTodayBetweenDates} from "../../../utils/date-time";

export const StorePromotions = observer(
    function StorePromotions() {
        const navigate = useNavigate();
        const {locationStore, cartStore} = useStores()

        const guestAuth = useAppInit()
        const {isLoadingGuestAuth, locationInfo, locationInfoState} = guestAuth
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState<{
            isOpen: boolean;
            skuTitle: null | string;
            vendorName: null | undefined | string
        }>({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const [gridItemWidth, setGridItemWidth] = React.useState(200)
        const [isLoadingPromotions, setIsLoadingPromotions] = React.useState(true)

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        // useEffect(() => {
        //     ;(async () => {
        //         if (guestAuthState === 'loaded') {
        //         }
        //     })()
        // }, [guestAuthToken, guestAuthState]);

        useEffect(() => {

            ;(async () => {
                setIsLoadingPromotions(true)
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `Promotions`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `Promotions | ${locationInfo.locationName}`;
                    setIsLoadingPromotions(false)
                }
            })()

        }, [locationInfo, locationInfoState])

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }

        let basicBundlesCount = 0

        if (locationInfoState === "loaded" && locationInfo && locationInfo.basicBundles.length > 0) {
            locationInfo.basicBundles.forEach(basicBundle => {
                if (basicBundle.basicBundleDeleted === 0 && basicBundle.basicBundleStatus === 'active' && isTodayBetweenDates(basicBundle.basicBundleValidFrom, basicBundle.basicBundleValidTo)) {
                    basicBundlesCount++
                }
            })
        }

        return (
            <>
                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column',}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}
                            // onLogin={()=>init()}
                        />

                        <CGridContainer zIndex={100} mt={0} spacing={2}>
                            <CGrid item xs={12} height={{xs: 100, sm: 100, md: 100}}>
                                <CCard
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        pl: 2
                                    }}
                                >
                                    <CButton variant={'text'} sx={{color: 'primary', fontWeight: 'bold', zIndex: 1}}
                                             onClick={() => navigate('/')}>
                                        Home
                                    </CButton>
                                    <CTypography color={'primary'} zIndex={1}>
                                        / Promotions
                                    </CTypography>
                                </CCard>
                            </CGrid>
                        </CGridContainer>

                        {(!isLoadingPromotions) ? <>
                            <CGridContainer mb={4}>
                                {basicBundlesCount > 0 && locationInfo.locationSetting.locationSettingBundlesEnabled === 1 ? locationInfo.basicBundles.map((basicBundle, index) => {
                                    if
                                    (
                                        basicBundle.basicBundleDeleted === 1 ||
                                        basicBundle.basicBundleStatus === 'inactive' ||
                                        !isTodayBetweenDates(basicBundle.basicBundleValidFrom, basicBundle.basicBundleValidTo)
                                    ) {
                                        return <></>
                                    }
                                    return <BundleCard
                                        onRef={(ref) => {
                                            if (ref?.offsetWidth) {
                                                setGridItemWidth(ref?.offsetWidth - 16)
                                            }
                                        }}
                                        key={`${basicBundle.basicBundleId}${index}_bundle_card${new Date().getTime()}`}
                                        md={4}
                                        xs={12}
                                        showInfoButton={false}
                                        imageUrl={hasValue(basicBundle.basicBundleSku.skuImages) ? basicBundle.basicBundleSku.skuImages[0].skuImageLink : ''}
                                        title={basicBundle.basicBundleTitle}
                                        validFrom={basicBundle.basicBundleValidFrom}
                                        validTo={basicBundle.basicBundleValidTo}
                                        descriptions={basicBundle.basicBundleDescriptions}
                                        onClick={() => {
                                            if (!hasValue(cartStore.cart?.cartLockboxId)) {
                                                setDeliveryMethodDialog({
                                                    isOpen: true,
                                                    vendorName: basicBundle.basicBundleSku?.vendor?.vendorName,
                                                    skuTitle: basicBundle.basicBundleSku?.skuTitle
                                                })
                                            } else {
                                                navigate(`/store/product/${basicBundle.basicBundleSku.vendor?.vendorName}-${basicBundle.basicBundleSku.skuTitle}`)
                                            }
                                        }}
                                    />
                                }) : (
                                    <CBox m={4}>
                                        <CTypography>No promotions yet.</CTypography>

                                    </CBox>
                                )}
                            </CGridContainer>
                        </> : <CGridContainer sx={{
                            height: '50vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <CCircularProgress></CCircularProgress>

                        </CGridContainer>
                        }

                    </CContainer>
                    <Footer/>
                </main>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }

                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );

    }
)
