import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard, CCircularProgress,
    CDialog, CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {VendorCreateParams, VendorUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultVendor} from "../../../models/vendor/vendor";
import {isEmpty} from "validate.js";
import {useEffect} from "react";
import {Refresh} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";

interface VendorDialogData {
    isOpen: boolean,
    data: VendorCreateParams | VendorUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

export const AdminPanelVendors = observer(
    function AdminPanelVendors() {
        const navigate = useNavigate();

        const {authStore, vendorStore, skuStore} = useStores()
        const {vendors} = vendorStore

        const [vendorDialog, setVendorDialog] = React.useState<VendorDialogData>({
            isOpen: false,
            data: defaultVendor,
            mode: 'add',
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            name: '',
            status: ''
        })

        const handleClickOpen = (mode: string, vendor?: VendorCreateParams | VendorUpdateParams) => {
            setVendorDialog({
                ...vendorDialog,
                isOpen: true,
                mode,
                data: vendor ? {...vendor} : defaultVendor
            });
        };

        const handleClose = () => {
            setVendorDialog({isOpen: false, data: defaultVendor, mode: 'add', isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setVendorDialog({
                    ...vendorDialog,
                    isLoading: true
                });

                if (vendorDialog.mode === 'add') {
                    await vendorStore.create({
                        ...vendorDialog.data
                    }, currentAuthToken)
                } else if (vendorDialog.mode === 'edit') {
                    await vendorStore.update({
                        ...vendorDialog.data
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (vendor) => {
            if (window.confirm('Are you sure you want to delete this vendor?')) {
                const vendorSkusResult = await skuStore.findAll({
                    vendorId: vendor.vendorId
                }, currentAuthToken, false)
                if (vendorSkusResult.kind === "ok") {
                    if (vendorSkusResult.skus && vendorSkusResult.skus.length > 0) {
                        window.alert("This vendor has skus. A vendor with Skus cannot be deleted.")
                    } else {
                        await vendorStore.remove({vendorId: vendor.vendorId}, currentAuthToken)
                        await refresh()
                    }
                }
            }

        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if(isEmpty(vendorDialog.data.vendorName)) {
                validationObject.name = "This field is required."
                isValid = false
            }
            if(isEmpty(vendorDialog.data.vendorStatus)) {
                validationObject.status = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await vendorStore.findAll({
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if(authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Vendors';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        const rows: GridRowsProp = vendors;

        const columns: GridColDef[] = [
            {
                field: 'vendorName',
                headerName: 'Name',
                width: 150,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'vendorUrl',
                headerName: 'Url',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'vendorDesc',
                headerName: 'Descriptions',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'vendorStatus',
                headerName: 'Status',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onRemoveClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleRemove(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}
                                 onClick={onRemoveClick}>Remove</CButton>
                    </>;
                }
            },

        ];
        
        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                                <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                    Vendors
                                </CTypography>
                            <CButton variant={"contained"} onClick={()=>handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.vendorId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={vendorDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Vendor
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={4}>
                            <CTextField fullWidth label={"Name"}
                                        error={hasValue(validation.name)}
                                        helperText={hasValue(validation.name)?validation.name:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setVendorDialog({
                                                ...vendorDialog,
                                                data: {...vendorDialog.data, vendorName: event.target.value}
                                            });
                                            if(hasValue(validation.name)) {
                                                setValidation({...validation, name: ''})
                                            }
                                        }} value={vendorDialog.data.vendorName}/>
                        </CGrid>
                        <CGrid item xs={5}>
                            <CTextField fullWidth label={"Url"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setVendorDialog({
                                                ...vendorDialog,
                                                data: {...vendorDialog.data, vendorUrl: event.target.value}
                                            });
                                        }} value={vendorDialog.data.vendorUrl}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Status"} select
                                        error={hasValue(validation.status)}
                                        helperText={hasValue(validation.status)?validation.status:null}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setVendorDialog({
                                                ...vendorDialog,
                                                data: {...vendorDialog.data, vendorStatus: value}
                                            });
                                            if(hasValue(validation.status)) {
                                                setValidation({...validation, status: ''})
                                            }
                                        }} value={vendorDialog.data.vendorStatus}>
                                <CMenuItem value={"active"}>Active</CMenuItem>
                                <CMenuItem value={"inactive"}>Inactive</CMenuItem>
                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12}>
                            <CTextField fullWidth multiline label={"Descriptions"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setVendorDialog({
                                                ...vendorDialog,
                                                data: {...vendorDialog.data, vendorDesc: event.target.value}
                                            });
                                        }} value={vendorDialog.data.vendorDesc} />
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {vendorDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : vendorDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
