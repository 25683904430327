import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CInputAdornment,
    CMenuItem,
    CTextField,
    CTypography,
    CIconButton,
    StripedDataGrid,
    Divider
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {Refresh, Visibility, VisibilityOff} from "@mui/icons-material";
import {useStores} from "../../../models";
import {useEffect} from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {UserCreateParams, UserUpdateParams} from "../../../services/api";
import {defaultUser} from "../../../models/user/user";
import {isEmpty} from "validate.js";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";

interface UserDialogData {
    isOpen: boolean,
    data: UserCreateParams | UserUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

export const AdminPanelUsers = observer(
    function AdminPanelUsers() {
        const navigate = useNavigate();

        const {authStore, userStore, locationStore} = useStores()
        const {users} = userStore
        const {locations} = locationStore
        const [userDialog, setUserDialog] = React.useState<UserDialogData>({
            isOpen: false,
            data: defaultUser,
            mode: 'add',
            isLoading: false
        });
        const [showPassword, setShowPassword] = React.useState(false);
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            roleId: '',
            locationId: '',
            userName: '',
            password: ''
        })

        const handleClickOpen = (mode: string, user?: UserCreateParams | UserUpdateParams) => {
            setUserDialog({
                ...userDialog,
                isOpen: true,
                mode,
                data: user ? {...user} : defaultUser
            });
        };

        const handleClose = () => {
            setUserDialog({isOpen: false, data: defaultUser, mode: 'add', isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setUserDialog({
                    ...userDialog,
                    isLoading: true
                });

                let locationId;

                if (userDialog.mode === 'add') {
                    const result = await userStore.create({
                        ...userDialog.data
                    }, currentAuthToken)
                    if(userDialog.data.roleId === 2) {
                        await locationStore.update({
                            locationId: userDialog.data.userLocationId,
                            locationAdminId: result.user.identifiers[0].userId
                        }, currentAuthToken)
                    }
                } else if (userDialog.mode === 'edit') {
                    await userStore.update({
                        ...userDialog.data
                    }, currentAuthToken)
                    if(userDialog.data.roleId === 2) {
                        await locationStore.update({
                            locationId: userDialog.data.userLocationId,
                            // @ts-ignore
                            locationAdminId: userDialog.data.userId
                        }, currentAuthToken)
                    }
                }


                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (user) => {
            await userStore.remove({userId: user.userId}, currentAuthToken)
            await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if(isEmpty(userDialog.data.userFirstName)) {
                validationObject.firstName = "This field is required."
                isValid = false
            }
            if(isEmpty(userDialog.data.userLastName)) {
                validationObject.lastName = "This field is required."
                isValid = false
            }

            if(isEmpty(userDialog.data.userEmail)) {
                validationObject.email = "This field is required."
                isValid = false
            }
            if(isEmpty(userDialog.data.userPhone)) {
                validationObject.phoneNumber = "This field is required."
                isValid = false
            }
            if(isEmpty(userDialog.data.roleId)) {
                validationObject.roleId = "This field is required."
                isValid = false
            } else if ((userDialog.data.roleId === 2 || userDialog.data.roleId === 4 || userDialog.data.roleId === 5) && isEmpty(userDialog.data.userLocationId)) {
                validationObject.locationId = "This field is required."
                isValid = false
            }
            if(isEmpty(userDialog.data.userUsername)) {
                validationObject.userName = "This field is required."
                isValid = false
            }
            if(userDialog.mode === 'add' && isEmpty(userDialog.data.userPassword)) {
                validationObject.password = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        };

        const rows: GridRowsProp = users;

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const columns: GridColDef[] = [
            {
                field: 'userFullName',
                valueGetter: (params) => {
                    const {userFirstName, userLastName} = params.row
                    return `${userFirstName ? userFirstName : ''} ${userLastName ? userLastName : ''}`
                },
                headerName: 'Name',
                width: 150,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'userUsername',
                headerName: 'Username',
                width: 150,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'userRole',
                headerName: 'Role',
                valueGetter: (params) => {
                    const {roleName} = params.row.role
                    return `${roleName ? roleName : ''}`
                },
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'userLastLoginTs',
                headerName: 'Last Seen',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col7', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onRemoveClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleRemove(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}
                                 onClick={onRemoveClick}>Remove</CButton>
                    </>;
                }
            },

        ];

        const getLocations = async () => {
            await locationStore.findAll({
                locationTypeId: 1,
                page: 0,
                perPage: 100
            }, currentAuthToken, true)

        }

        const refresh = async () => {
            setIsRefreshing(true)

            await userStore.findAll({
                notRoleId: 4,
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken, true)
            setIsRefreshing(false)
        }

        const checkAuth = () => {
            if(authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Users';
            ;(async () => {
                checkAuth()
                await refresh()
                await getLocations()
            })()

        }, [])

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                Users
                            </CTypography>
                            <CButton variant={"contained"} onClick={()=>handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.userId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={userDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add User
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"First Name"}
                                        error={hasValue(validation.firstName)}
                                        helperText={hasValue(validation.firstName)?validation.firstName:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userFirstName: event.target.value}
                                            });
                                            if(hasValue(validation.firstName)) {
                                                setValidation({...validation, firstName: ''})
                                            }
                                        }} value={userDialog.data.userFirstName}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Last Name"}
                                        error={hasValue(validation.lastName)}
                                        helperText={hasValue(validation.lastName)?validation.lastName:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userLastName: event.target.value}
                                            });
                                            if(hasValue(validation.lastName)) {
                                                setValidation({...validation, lastName: ''})
                                            }
                                        }} value={userDialog.data.userLastName}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Email"}
                                        error={hasValue(validation.email)}
                                        helperText={hasValue(validation.email)?validation.email:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userEmail: event.target.value}
                                            });
                                            if(hasValue(validation.email)) {
                                                setValidation({...validation, email: ''})
                                            }
                                        }} value={userDialog.data.userEmail}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Phone Number"}
                                        error={hasValue(validation.phoneNumber)}
                                        helperText={hasValue(validation.phoneNumber)?validation.phoneNumber:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userPhone: event.target.value}
                                            });
                                            if(hasValue(validation.phoneNumber)) {
                                                setValidation({...validation, phoneNumber: ''})
                                            }
                                        }} value={userDialog.data.userPhone}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Role"} select
                                        error={hasValue(validation.roleId)}
                                        helperText={hasValue(validation.roleId)?validation.roleId:null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, roleId: value, userLocationId: undefined}
                                            });
                                            if(hasValue(validation.roleId)) {
                                                setValidation({...validation, roleId: ''})
                                            }
                                        }} value={userDialog.data.roleId}>
                                <CMenuItem value={1}>Admin</CMenuItem>
                                {/*<CMenuItem value={2}>Kiosk Admin</CMenuItem>*/}
                                {/*<CMenuItem value={5}>Kiosk Client</CMenuItem>*/}
                                <CMenuItem value={3}>Lockbox</CMenuItem>
                                <CMenuItem value={4}>Customer</CMenuItem>
                                <CMenuItem value={7}>Store Admin</CMenuItem>

                            </CTextField>
                        </CGrid>
                        {(userDialog.data.roleId === 2 || userDialog.data.roleId === 4 || userDialog.data.roleId === 5) && <CGrid item xs={3}>
                            <CTextField fullWidth label={"Location"} select
                                        error={hasValue(validation.locationId)}
                                        helperText={hasValue(validation.locationId)?validation.locationId:null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userLocationId: value}
                                            });
                                            if(hasValue(validation.locationId)) {
                                                setValidation({...validation, locationId: ''})
                                            }
                                        }} value={userDialog.data.userLocationId}>
                                {
                                    locations.map(location=><CMenuItem value={location.locationId ? location.locationId : -1}>{location.locationName}</CMenuItem>)
                                }

                            </CTextField>
                        </CGrid>

                        }
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Username"}
                                        error={hasValue(validation.userName)}
                                        helperText={hasValue(validation.userName)?validation.userName:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userUsername: event.target.value}
                                            });
                                            if(hasValue(validation.userName)) {
                                                setValidation({...validation, userName: ''})
                                            }
                                        }} value={userDialog.data.userUsername}/>
                        </CGrid>
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Password"}
                                        InputProps={{
                                            type: showPassword ? 'text' : 'password',
                                            endAdornment: <CInputAdornment position="end">
                                            <CIconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </CIconButton>
                                            </CInputAdornment>
                                        }}
                                        error={hasValue(validation.password)}
                                        helperText={hasValue(validation.password)?validation.password:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userPassword: event.target.value}
                                            });
                                            if(hasValue(validation.password)) {
                                                setValidation({...validation, password: ''})
                                            }
                                        }} value={userDialog.data.userPassword}
                            >

                            </CTextField>
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox
                        sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {userDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : userDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
