import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {PricesApi} from "../../services/api/prices-api"
import {PriceModel, PriceSnapshot} from "../price/price"
import {logInfo} from "../../utils/logs"
import {
    PriceCreateParams,
    PriceDeleteParams,
    PriceFindAllParams,
    PriceFindOneParams,
    PriceUpdateParams
} from "../../services/api";

function priceStoreLog(functionName, message) {
    logInfo({
        fileName: "price-store.ts",
        className: "priceStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const PriceStoreModel = types
    .model("PriceStore")
    .props({
        price: types.optional(types.maybeNull(PriceModel), null),
        prices: types.optional(types.array(PriceModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        savePrice: (priceSnapshot: PriceSnapshot) => {
            try {
                self.price = priceSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    priceStoreLog("savePrice", e.message)
                }
                // recordError(e)
            }
        },
        savePrices: (pricesSnapshot: PriceSnapshot[]) => {
            try {
                self.prices = cast([...pricesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    priceStoreLog("savePrice", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: PriceCreateParams, token: string) {
            self.environment.addAuth(token)
            const priceApi = new PricesApi(self.environment.api)
            const result = yield priceApi.create(params)


            if (result.kind === "ok") {
                self.savePrice(result.price)
            } else {
                priceStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: PriceFindAllParams, token: string, saveToMst:boolean) {
            self.environment.addAuth(token)
            const priceApi = new PricesApi(self.environment.api)
            const result = yield priceApi.findAll(params)


            if (result.kind === "ok") {
                if (saveToMst) {
                    self.savePrices(result.prices)
                }
            } else {
                priceStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: PriceFindOneParams, token: string) {
            self.environment.addAuth(token)
            const priceApi = new PricesApi(self.environment.api)
            const result = yield priceApi.findOne(params)


            if (result.kind === "ok") {
                self.savePrice(result.price)
            } else {
                priceStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: PriceUpdateParams, token: string) {
            self.environment.addAuth(token)
            const priceApi = new PricesApi(self.environment.api)
            const result = yield priceApi.update(params)


            if (result.kind === "ok") {
                self.savePrice(result.price)
            } else {
                priceStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: PriceDeleteParams, token: string) {
            self.environment.addAuth(token)
            const priceApi = new PricesApi(self.environment.api)
            return yield priceApi.remove(params)
        }),

    }))

type PriceStoreType = Instance<typeof PriceStoreModel>

export interface PriceStore extends PriceStoreType {
}

type PriceStoreSnapshotType = SnapshotOut<typeof PriceStoreModel>

export interface PriceStoreSnapshot extends PriceStoreSnapshotType {
}

export const createPriceStoreDefaultModel = () => types.optional(PriceStoreModel, {})
