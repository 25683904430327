import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {SkuModel} from "../sku/sku";

/**
 * Rick and Morty character model.
 */
export const OrderDetailModel = types.model("OrderDetail").props({
    orderDetailId: types.number,
    orderId: types.number,
    skuId: types.maybeNull(types.number),
    skuQuantity: types.maybeNull(types.number),
    skuPrice: types.maybeNull(types.number),
    skuPriceId: types.maybeNull(types.number),
    orderDetailCreatedAt: types.maybeNull(types.string),
    orderDetailUpdatedAt: types.maybeNull(types.string),
    // order: types.optional(types.maybeNull(OrderModel), {orderId: -1}),
    sku: types.optional(types.maybeNull(SkuModel), {skuId: -1}),
    basicBundleId: types.maybeNull(types.number),
    orderDetailFreeItemsCount: types.maybeNull(types.number),
})

type OrderDetailType = Instance<typeof OrderDetailModel>
export interface OrderDetail extends OrderDetailType {}
type OrderDetailSnapshotType = SnapshotOut<typeof OrderDetailModel>
export interface OrderDetailSnapshot extends OrderDetailSnapshotType {}

