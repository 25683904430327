import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const PageModel = types.model("Page").props({
    pageId: types.number,
    pageTitle: types.maybeNull(types.string),
    pageDescriptions: types.maybeNull(types.string),
    pageSlug: types.maybeNull(types.string),
    pageContent: types.maybeNull(types.string),
    pageCreatedAt: types.maybeNull(types.string),
    pageUpdatedAt: types.maybeNull(types.string),
})

type PageType = Instance<typeof PageModel>
export interface Page extends PageType {}
type PageSnapshotType = SnapshotOut<typeof PageModel>
export interface PageSnapshot extends PageSnapshotType {}

export const defaultPage = {
    pageId: undefined,
    pageSlug: '',
    pageValue: ''
}