import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {
    CBox,
    CButton,
    CCard, CCardContent,
    CCircularProgress,
    CGrid,
    CGridContainer, CMenuItem,
    CTextField, CTypography,
} from "../../../components";
import * as React from "react";
import {useEffect} from "react";
import {useStores} from "../../../models";

let currentAuthToken = ''

interface LocationSettingsData {
    locationSettingId: number;
    locationSettingGaTrackingNo: string;
    locationSettingCopyright: string;
    locationSettingPhone: string;
    locationSettingEmail: string;
    locationSettingAddress: string;
    locationSettingFee: number;
    locationSettingTaxPercent: number;
    locationSettingBundlesEnabled: number;
    locationSettingDeliveryEnabled: number;
    locationSettingTimezone: string;
    locationSettingDeliveryFee: number;
    locationSettingAuthorizeSeal: string;
}

export const StorePanelSettings = observer(
    function StorePanelSettings() {

        const {authStore, locationStore, locationSettingStore} = useStores()

        const navigate = useNavigate();

        const [isLoadingSettings, setIsLoadingSettings] = React.useState(true)
        const [isSavingSettings, setIsSavingSettings] = React.useState(false)
        const [currentLocationSettings, setCurrentLocationSettings] = React.useState<LocationSettingsData | null>(null)

        const checkAuth = () => {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        const getSettings = async () => {
            if (authStore.storeAdminAuth?.userLocationId) {
                const result = await locationStore.findOne({
                    locationId: authStore.storeAdminAuth?.userLocationId
                }, currentAuthToken, false)

                if (result.kind === 'ok') {
                    setCurrentLocationSettings({
                        locationSettingId: result.location.locationSetting.locationSettingId,
                        locationSettingGaTrackingNo: result.location.locationSetting.locationSettingGaTrackingNo,
                        locationSettingCopyright: result.location.locationSetting.locationSettingCopyright,
                        locationSettingAddress: result.location.locationSetting.locationSettingAddress,
                        locationSettingPhone: result.location.locationSetting.locationSettingPhone,
                        locationSettingEmail: result.location.locationSetting.locationSettingEmail,
                        locationSettingFee: result.location.locationSetting.locationSettingFee,
                        locationSettingTaxPercent: result.location.locationSetting.locationSettingTaxPercent,
                        locationSettingTimezone: result.location.locationSetting.locationSettingTimezone ?
                            result.location.locationSetting.locationSettingTimezone :
                            "PDT",
                        locationSettingDeliveryFee: result.location.locationSetting.locationSettingDeliveryFee,
                        locationSettingAuthorizeSeal: result.location.locationSetting.locationSettingAuthorizeSeal,
                        locationSettingBundlesEnabled: result.location.locationSetting.locationSettingBundlesEnabled,
                        locationSettingDeliveryEnabled: result.location.locationSetting.locationSettingDeliveryEnabled
                    })
                }
                setIsLoadingSettings(false)
            } else {
                navigate(`/system-login`)
            }
        }

        const handleSubmit = async () => {
            setIsSavingSettings(true)
            await locationSettingStore.update({
                ...currentLocationSettings,
            }, currentAuthToken)
            setIsSavingSettings(false)
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Store Admin Panel - Settings';
            ;(async () => {
                checkAuth()
                await getSettings()
            })()

        }, [])

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center",}}>
                            <CCardContent
                                sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>

                                {!isLoadingSettings ? <CGridContainer spacing={2}>
                                    <CGrid item xs={12}>
                                        <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                            Settings
                                        </CTypography>
                                    </CGrid>
                                    <CGrid item xs={12} md={6}>
                                        <CTextField
                                            fullWidth
                                            label={"GA Tracking Number"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (currentLocationSettings) {
                                                    setCurrentLocationSettings({
                                                        ...currentLocationSettings,
                                                        locationSettingGaTrackingNo: event.target.value
                                                    })
                                                }
                                            }} value={currentLocationSettings?.locationSettingGaTrackingNo}
                                        >

                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={12} md={6}>
                                        <CTextField
                                            fullWidth
                                            label={"Copyright"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (currentLocationSettings) {
                                                    setCurrentLocationSettings({
                                                        ...currentLocationSettings,
                                                        locationSettingCopyright: event.target.value
                                                    })
                                                }
                                            }} value={currentLocationSettings?.locationSettingCopyright}
                                        >

                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={12} md={6}>
                                        <CTextField
                                            fullWidth
                                            label={"Authorize.net Seal"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (currentLocationSettings) {
                                                    setCurrentLocationSettings({
                                                        ...currentLocationSettings,
                                                        locationSettingAuthorizeSeal: event.target.value
                                                    })
                                                }
                                            }} value={currentLocationSettings?.locationSettingAuthorizeSeal}
                                        >

                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={12} md={4}>
                                        <CTextField
                                            fullWidth
                                            label={"Phone"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (currentLocationSettings) {
                                                    setCurrentLocationSettings({
                                                        ...currentLocationSettings,
                                                        locationSettingPhone: event.target.value
                                                    })
                                                }
                                            }} value={currentLocationSettings?.locationSettingPhone}
                                        >

                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={12} md={4}>
                                        <CTextField
                                            fullWidth
                                            label={"Email"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (currentLocationSettings) {
                                                    setCurrentLocationSettings({
                                                        ...currentLocationSettings,
                                                        locationSettingEmail: event.target.value
                                                    })
                                                }
                                            }} value={currentLocationSettings?.locationSettingEmail}
                                        >

                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={12} md={4}>
                                        <CTextField
                                            fullWidth
                                            label={"Address"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (currentLocationSettings) {
                                                    setCurrentLocationSettings({
                                                        ...currentLocationSettings,
                                                        locationSettingAddress: event.target.value
                                                    })
                                                }
                                            }} value={currentLocationSettings?.locationSettingAddress}
                                        >

                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={4}>
                                        {/*For now it's fixed but it should be dynamic*/}
                                        <CTextField fullWidth label={"Timezone"}
                                                    select
                                                    disabled
                                                    value={"PDT"}
                                        >
                                            <CMenuItem value={"PDT"}>PDT</CMenuItem>
                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={2}>
                                        <CTextField fullWidth label={"Fee (USD)"}
                                                    type={"number"}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (currentLocationSettings) {
                                                            setCurrentLocationSettings({
                                                                ...currentLocationSettings,
                                                                locationSettingFee: Number(event.target.value)
                                                            })
                                                        }
                                                    }} value={currentLocationSettings?.locationSettingFee}
                                        />
                                    </CGrid>
                                    <CGrid item xs={2}>
                                        <CTextField fullWidth label={"Delivery Fee (USD)"}
                                                    type={"number"}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (currentLocationSettings) {
                                                            setCurrentLocationSettings({
                                                                ...currentLocationSettings,
                                                                locationSettingDeliveryFee: Number(event.target.value)
                                                            })
                                                        }
                                                    }} value={currentLocationSettings?.locationSettingDeliveryFee}
                                        />
                                    </CGrid>
                                    <CGrid item xs={3}>
                                        <CTextField fullWidth label={"Tax (Percent)"}
                                                    type={"number"}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (currentLocationSettings) {
                                                            setCurrentLocationSettings({
                                                                ...currentLocationSettings,
                                                                locationSettingTaxPercent: Number(event.target.value)
                                                            })
                                                        }
                                                    }} value={currentLocationSettings?.locationSettingTaxPercent}
                                        />
                                    </CGrid>
                                    <CGrid item xs={3}>
                                        <CTextField fullWidth label={"Bundles Enabled"}
                                                    select
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (currentLocationSettings) {
                                                            setCurrentLocationSettings({
                                                                ...currentLocationSettings,
                                                                locationSettingBundlesEnabled: Number(event.target.value)
                                                            })
                                                        }
                                                    }} value={currentLocationSettings?.locationSettingBundlesEnabled}
                                        >
                                            <CMenuItem
                                                value={1}>True</CMenuItem>
                                            <CMenuItem
                                                value={0}>False</CMenuItem>
                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={2}>
                                        <CTextField fullWidth label={"Delivery Enabled"}
                                                    select
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (currentLocationSettings) {
                                                            setCurrentLocationSettings({
                                                                ...currentLocationSettings,
                                                                locationSettingDeliveryEnabled: Number(event.target.value)
                                                            })
                                                        }
                                                    }} value={currentLocationSettings?.locationSettingDeliveryEnabled}
                                        >
                                            <CMenuItem
                                                value={1}>True</CMenuItem>
                                            <CMenuItem
                                                value={0}>False</CMenuItem>
                                        </CTextField>
                                    </CGrid>
                                    <CGrid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <CButton variant={"contained"} onClick={handleSubmit}
                                                 sx={{minWidth: "100px"}}>
                                            {isSavingSettings ? <CCircularProgress sx={{color: "white"}}
                                                                                   size={24}/> : 'Save'}
                                        </CButton>
                                    </CGrid>
                                </CGridContainer> : <CCircularProgress sx={{mb: 2}}/>}
                            </CCardContent>
                        </CCard>
                    </CBox>
                </CBox>
            </>
        )
    }
)