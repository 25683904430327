import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {RoleModel} from "../role/role";
import {UserDetailModel} from "../user-detail/user-detail";
import {LocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const UserModel = types.model("User").props({
    userId: types.number,
    userUsername: types.maybe(types.string),
    // userPassword: types.maybe(types.string),
    userFirstName: types.maybeNull(types.string),
    userLastName: types.maybeNull(types.string),
    userEmail: types.maybeNull(types.string),
    userPhone: types.maybeNull(types.string),
    userNote: types.maybeNull(types.string),
    userLastLoginTs: types.maybeNull(types.string),
    userLastLoginIp: types.maybeNull(types.string),
    roleId: types.maybeNull(types.number),
    userLocationId: types.maybeNull(types.number),
    userLocationNote: types.maybeNull(types.string),
    userStatus: types.maybeNull(types.string),
    userCreatedAt: types.maybe(types.string),
    userUpdatedAt: types.maybe(types.string),
    role: types.optional(types.maybeNull(RoleModel), {roleId: -1, roleName: ""}),
    // location: types.optional(types.maybeNull(LocationModel), {locationId: -1, locationName: ""}),
    userDetail: types.maybeNull(UserDetailModel),
    userFlag: types.maybeNull(types.string)
})

type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}

export const defaultUser = {
    userId: undefined,
    userUsername: '',
    userPassword: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhone: '',
    userNote: '',
    userLastLoginTs: '',
    userLastLoginIp: '',
    roleId: undefined,
    userLocationId: undefined,
}