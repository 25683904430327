import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {BundleSkusApi} from "../../services/api/bundle-skus-api"
import {BundleSkuModel, BundleSkuSnapshot} from "../bundle-sku/bundle-sku"
import {logInfo} from "../../utils/logs"
import {
    BundleSkuCreateParams, BundleSkuDeleteParams, BundleSkuFindAllParams, BundleSkuFindOneParams, BundleSkuUpdateParams,
} from "../../services/api";

function bundleSkuStoreLog(functionName, message) {
    logInfo({
        fileName: "sku-store.ts",
        className: "bundleSkuStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const BundleSkuStoreModel = types
    .model("BundleSkuStore")
    .props({
        bundleSku: types.optional(types.maybeNull(BundleSkuModel), null),
        bundleSkus: types.optional(types.array(BundleSkuModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveBundleSku: (skuSnapshot: BundleSkuSnapshot) => {
            try {
                self.bundleSku = cast(skuSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    bundleSkuStoreLog("saveSku", e.message)
                }
                // recordError(e)
            }
        },
        saveBundleSkus: (skusSnapshot: BundleSkuSnapshot[]) => {
            try {
                detach(self.bundleSkus)
                self.bundleSkus = cast([...skusSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    bundleSkuStoreLog("saveSku", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: BundleSkuCreateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.skuImages
            // @ts-ignore
            delete params.prices

            const bundleSkuApi = new BundleSkusApi(self.environment.api)
            const result = yield bundleSkuApi.create(params)


            // if (result.kind === "ok") {
            //     self.saveSku(result.sku)
            // } else {
            //     bundleSkuStoreLog("login", result.kind)
            // }
            return result
        }),
        findAll: flow(function* (params: BundleSkuFindAllParams, token: string) {
            self.environment.addAuth(token)
            const bundleSkuApi = new BundleSkusApi(self.environment.api)
            const result = yield bundleSkuApi.findAll(params)

            if (result.kind === "ok") {
                self.saveBundleSkus(result.bundleSkus)
            } else {
                bundleSkuStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: BundleSkuFindOneParams, token: string) {
            self.environment.addAuth(token)
            const bundleSkuApi = new BundleSkusApi(self.environment.api)
            const result = yield bundleSkuApi.findOne(params)


            if (result.kind === "ok") {
                self.saveBundleSku(result.bundleSku)
            } else {
                bundleSkuStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: BundleSkuUpdateParams, token: string) {
            self.environment.addAuth(token)
            // @ts-ignore
            delete params.skuImages
            // @ts-ignore
            delete params.prices

            const bundleSkuApi = new BundleSkusApi(self.environment.api)
            const result = yield bundleSkuApi.update(params)


            if (result.kind === "ok") {
                self.saveBundleSku(result.bundleSku)
            } else {
                bundleSkuStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: BundleSkuDeleteParams, token: string) {
            self.environment.addAuth(token)
            const bundleSkuApi = new BundleSkusApi(self.environment.api)
            return yield bundleSkuApi.remove(params)
        }),

    }))

type BundleSkuStoreType = Instance<typeof BundleSkuStoreModel>

export interface BundleSkuStore extends BundleSkuStoreType {
}

type BundleSkuStoreSnapshotType = SnapshotOut<typeof BundleSkuStoreModel>

export interface BundleSkuStoreSnapshot extends BundleSkuStoreSnapshotType {
}

export const createBundleSkuStoreDefaultModel = () => types.optional(BundleSkuStoreModel, {})
