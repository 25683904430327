import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {LocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const PriceModel = types.model("Price").props({
    priceId: types.number,
    skuId: types.maybeNull(types.number),
    priceAmount: types.maybeNull(types.number),
    locationId: types.maybeNull(types.number),
    priceTs: types.maybeNull(types.string),
    priceDateFrom: types.maybeNull(types.string),
    priceDateTo: types.maybeNull(types.string),
    priceCreatedAt: types.maybeNull(types.string),
    priceUpdatedAt: types.maybeNull(types.string),
})

type PriceType = Instance<typeof PriceModel>
export interface Price extends PriceType {}
type PriceSnapshotType = SnapshotOut<typeof PriceModel>
export interface PriceSnapshot extends PriceSnapshotType {}

