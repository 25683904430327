import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  PaymentDeleteParams,
  PaymentCreateResult,
  PaymentUpdateResult,
  PaymentUpdateParams,
  PaymentFindAllResult,
  PaymentDeleteResult,
  PaymentFindAllParams,
  PaymentFindOneParams,
  PaymentCreateParams,
  PaymentFindOneResult, PaymentPurchaseParams, PaymentPurchaseResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class PaymentsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: PaymentCreateParams): Promise<PaymentCreateResult> {
    try {
      // const functionName = "login"
      const uri = "payments"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const payment = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", payment}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: PaymentFindAllParams): Promise<PaymentFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "payments"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const payments = response.data.payments
      const count = response.data.count

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", payments, count}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: PaymentFindOneParams): Promise<PaymentFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "payments/"+params.paymentId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const payment = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", payment}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: PaymentUpdateParams): Promise<PaymentUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "payments/"+params.paymentId
      delete params.paymentId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const payment = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", payment}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: PaymentDeleteParams): Promise<PaymentDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "payments/"+params.paymentId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async purchase(params: PaymentPurchaseParams): Promise<PaymentPurchaseResult> {
    try {
      // const functionName = "login"
      const uri = "payments/purchase"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      console.log('purchase', response)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const data = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", data}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

}
