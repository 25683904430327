import {
    CBox,
    CButton,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CTextField,
    CTypography
} from "../mui-extracted";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Dayjs} from "dayjs";
import {Divider} from "../divider";
import {isEmpty} from "validate.js";
import {Table} from "../table";
import * as React from "react";
import {convertToCurrentTimezone} from "../../utils/date-time";

export const PromotionalBannerDialog = (props) => {

    const {
        promotionalBannersDialog,
        setPromotionalBannersDialog,
        isPromotionalBannerFromDateCalendarOpen,
        setIsPromotionalBannerFromDateCalendarOpen,
        isPromotionalBannerToDateCalendarOpen,
        setIsPromotionalBannerToDateCalendarOpen,
        handleClose,
        handlePromotionalBannerCreate,
        isCreatingPromotionalBanner,
        handlePromotionalBannerInactivate,
        handlePromotionalBannerActivate,
        handlePromotionalBannerEdit,
        handlePromotionalBannerDelete,
        handlePromotionalBannerEditCancel
    } = props

    return (
        <CDialog
            open={promotionalBannersDialog.isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={"lg"}
            PaperProps={{
                sx: {
                    borderRadius: "37px"
                }
            }}
        >
            <CGridContainer spacing={2} p={2} alignItems={'center'}>
                <CGrid item xs={12}>
                    <CTypography fontSize={"24px"} ml={4}>
                        {promotionalBannersDialog.storeName} Banners
                    </CTypography>
                </CGrid>
                <CGrid item xs={9}>
                    <CTextField fullWidth label={"Title"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setPromotionalBannersDialog({
                                        ...promotionalBannersDialog,
                                        formData: {
                                            ...promotionalBannersDialog.formData,
                                            promotionalBannerTitle: value
                                        }
                                    });
                                }}
                                value={promotionalBannersDialog.formData?.promotionalBannerTitle ? promotionalBannersDialog.formData?.promotionalBannerTitle : ''}>
                    </CTextField>
                </CGrid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <>
                        <CGrid item xs={12} md={1.5}
                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <MobileDatePicker
                                label="From Date"
                                inputFormat="MM/DD/YYYY"
                                value={promotionalBannersDialog.formData.promotionalBannerValidFrom}
                                open={isPromotionalBannerFromDateCalendarOpen}
                                onOpen={() => setIsPromotionalBannerFromDateCalendarOpen(true)}
                                onChange={(newValue: Dayjs | null) => {
                                    // console.log('From Date', newValue)
                                    if (newValue) {
                                        setPromotionalBannersDialog({
                                            ...promotionalBannersDialog,
                                            formData: {
                                                ...promotionalBannersDialog.formData,
                                                promotionalBannerValidFrom: newValue
                                            }
                                        });
                                    }
                                    setIsPromotionalBannerFromDateCalendarOpen(false)
                                }}
                                onClose={() => setIsPromotionalBannerFromDateCalendarOpen(false)}
                                renderInput={(params) =>
                                    <CTextField fullWidth {...params}/>}
                            />
                        </CGrid>
                        <CGrid item xs={12} md={1.5}
                               sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <MobileDatePicker
                                label="To Date"
                                inputFormat="MM/DD/YYYY"
                                value={promotionalBannersDialog.formData.promotionalBannerValidTo}
                                open={isPromotionalBannerToDateCalendarOpen}
                                onOpen={() => setIsPromotionalBannerToDateCalendarOpen(true)}
                                onClose={() => setIsPromotionalBannerToDateCalendarOpen(false)}
                                onChange={(newValue: Dayjs | null) => {
                                    // console.log('To Date', newValue)
                                    if (newValue) {
                                        setPromotionalBannersDialog({
                                            ...promotionalBannersDialog,
                                            formData: {
                                                ...promotionalBannersDialog.formData,
                                                promotionalBannerValidTo: newValue
                                            }
                                        });
                                    }
                                    setIsPromotionalBannerToDateCalendarOpen(false)
                                }}
                                renderInput={(params) =>
                                    <CTextField fullWidth {...params} />}
                            />
                        </CGrid>
                    </>
                </LocalizationProvider>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Background Color"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setPromotionalBannersDialog({
                                        ...promotionalBannersDialog,
                                        formData: {
                                            ...promotionalBannersDialog.formData,
                                            promotionalBannerBgColor: value
                                        }
                                    });
                                }}
                                value={promotionalBannersDialog.formData?.promotionalBannerBgColor ? promotionalBannersDialog.formData?.promotionalBannerBgColor : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Title Color"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setPromotionalBannersDialog({
                                        ...promotionalBannersDialog,
                                        formData: {
                                            ...promotionalBannersDialog.formData,
                                            promotionalBannerTitleColor: value
                                        }
                                    });
                                }}
                                value={promotionalBannersDialog.formData?.promotionalBannerTitleColor ? promotionalBannersDialog.formData?.promotionalBannerTitleColor : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Descriptions Color"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setPromotionalBannersDialog({
                                        ...promotionalBannersDialog,
                                        formData: {
                                            ...promotionalBannersDialog.formData,
                                            promotionalBannerSubtitleColor: value
                                        }
                                    });
                                }}
                                value={promotionalBannersDialog.formData?.promotionalBannerSubtitleColor ? promotionalBannersDialog.formData?.promotionalBannerSubtitleColor : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={promotionalBannersDialog.mode === 'create' ? 11 : 10}>
                    <CTextField fullWidth label={"Descriptions"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setPromotionalBannersDialog({
                                        ...promotionalBannersDialog,
                                        formData: {
                                            ...promotionalBannersDialog.formData,
                                            promotionalBannerDescriptions: value
                                        }
                                    });
                                }}
                                value={promotionalBannersDialog.formData?.promotionalBannerDescriptions ? promotionalBannersDialog.formData?.promotionalBannerDescriptions : ''}>
                    </CTextField>
                </CGrid>
                <CGrid item xs={1}>
                    <CButton
                        fullWidth
                        variant={"contained"}
                        onClick={handlePromotionalBannerCreate}
                    >
                        {isCreatingPromotionalBanner ? <CCircularProgress sx={{color: "white"}}
                                                                          size={24}/> : promotionalBannersDialog.mode === 'create' ? 'Add': 'Edit'}
                    </CButton>

                </CGrid>
                {promotionalBannersDialog.mode === 'edit' && <CGrid item xs={1}><CButton
                    fullWidth
                    variant={"outlined"}
                    onClick={handlePromotionalBannerEditCancel}
                >
                    Cancel
                </CButton></CGrid>}
            </CGridContainer>
            <Divider></Divider>
            <CGridContainer spacing={0} p={0}>
                <CGrid item xs={12} maxHeight={'40vh'} sx={{
                    overflowY: 'scroll',
                }}>
                    {isEmpty(promotionalBannersDialog.data) ?
                        <CTypography m={4} textAlign={'center'} variant={'body1'}> No basic bundles found
                            for store. </CTypography> : <Table
                            columns={[
                                {
                                    field: 'promotionalBannerId',
                                    headerName: 'ID',
                                    width: 80,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'promotionalBannerTitle',
                                    headerName: 'Title',
                                    width: 150,
                                    headerClassName: "pos-table-header pos-table-header-first",
                                    cellClassName: "pos-table-cell pos-table-cell-first"
                                },
                                {
                                    field: 'promotionalBannerDescriptions',
                                    headerName: 'Descriptions',
                                    flex: 1,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'promotionalBannerValidFrom',
                                    headerName: 'From',
                                    valueGetter: (params) => {
                                        const {promotionalBannerValidFrom} = params.row
                                        return convertToCurrentTimezone(promotionalBannerValidFrom, 'MMM D')
                                    },
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'promotionalBannerValidTo',
                                    headerName: 'To',
                                    valueGetter: (params) => {
                                        const {promotionalBannerValidTo} = params.row
                                        return convertToCurrentTimezone(promotionalBannerValidTo, 'MMM D')
                                    },
                                    width: 80,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'promotionalBannerStatus',
                                    headerName: 'Status',
                                    width: 50,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell"
                                },
                                {
                                    field: 'actions',
                                    headerName: 'Action',
                                    width: 250,
                                    headerClassName: "pos-table-header",
                                    cellClassName: "pos-table-cell",
                                    renderCell: (params) => {

                                        const onInactivateClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handlePromotionalBannerInactivate(params.row.promotionalBannerId, Number(promotionalBannersDialog.storeId))
                                            // return alert(JSON.stringify(thisRow, null, 4));
                                        };

                                        const onActivateClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handlePromotionalBannerActivate(params.row.promotionalBannerId, Number(promotionalBannersDialog.storeId))
                                            // return alert(JSON.stringify(thisRow, null, 4));
                                        };

                                        const onEditClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handlePromotionalBannerEdit(params.row)
                                        };

                                        const onDeleteClick = (e) => {
                                            e.stopPropagation(); // don't select this row after clicking
                                            return handlePromotionalBannerDelete(params.row.promotionalBannerId)
                                        };
                                        return <>
                                            {params.row.promotionalBannerStatus === 'active' ?
                                                <CButton variant={"contained"} color={"error"} size={"small"}
                                                         sx={{height: '32px', mr: 2}}
                                                         onClick={onInactivateClick}>Inactivate</CButton> :
                                                <CButton variant={"contained"} size={"small"}
                                                         sx={{height: '32px', mr: 2}}
                                                         onClick={onActivateClick}>Activate</CButton>}

                                            <CButton variant={"contained"} color={"primary"} size={"small"}
                                                     sx={{height: '32px', mr: 2}}
                                                     onClick={onEditClick}>Edit</CButton>

                                            <CButton variant={"contained"} color={"error"} size={"small"}
                                                     sx={{height: '32px'}}
                                                     onClick={onDeleteClick}>Remove</CButton>

                                        </>;
                                    }
                                }
                            ]}
                            rows={promotionalBannersDialog.data}
                            count={promotionalBannersDialog.data.length}
                            page={0}
                            rowsPerPage={100}
                            onPageChange={() => {
                                console.log('tmp')
                            }}
                            onRowsPerPageChange={() => {
                                console.log('tmp')
                            }}
                            orderBy={'tmp'}
                            order={'DESC'}
                            onRequestSort={() => {
                                console.log('tmp')
                            }}
                            isLoading={false}
                            noRowTitleOnSmallSize={true}
                        />}
                </CGrid>
            </CGridContainer>
            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
            </CBox>
        </CDialog>
    )
}