import * as React from "react";
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions, {DialogActionsProps} from '@mui/material/DialogActions';
import DialogContent, {DialogContentProps} from '@mui/material/DialogContent';
import DialogTitle, {DialogTitleProps} from '@mui/material/DialogTitle';
import DialogContentText, {DialogContentTextProps} from '@mui/material/DialogContentText';
import {styled} from '@mui/material/styles';
import {
    CBox,
    CButton,
    CCard,
    CCardContent,
    CCircularProgress,
    CGrid,
    CGridContainer,
    CIconButton,
    CInputAdornment,
    CLinearProgress,
    CMenuItem,
    CTab,
    CTabs,
    CTextField,
    CTypography,
    POSTitle
} from "..";
import {navigationParams} from "../../../utils/navigation";
import {useNavigate} from "react-router-dom";
import {useAppInit} from "../../../hooks/use-app-init";
import {useEffect, useState} from "react";
import {currentDomain} from "../../../utils/domain";
import {useStores} from "../../../models";
import {getConfig} from "../../../utils/config";
import {ChipSelect} from "../../chip-select";
import {ScrollDrag} from "../../scroll-drag";
import {hasValue} from "../../../utils/empty-check";
import {LockboxLocationSelectable} from "../../lockbox-location-selectable";
import {Autocomplete, GoogleMap, Marker} from "@react-google-maps/api";
import {observer} from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import {defaultCart} from "../../../models/cart/cart";
import {CalendarMonth, ExpandMore, Visibility, VisibilityOff} from "@mui/icons-material";
import {Divider} from "../../divider";
import {isEmpty} from "validate.js";
import {isMapLoaded} from "../../../app";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Geocode from "react-geocode";
import {TabPanel} from "../../tab-panel";
import {Order} from "../../../models/order/order";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {OrderDetail} from "../../../models/order-detail/order-detail";
import {addCurrentTimezone, convertToCurrentTimezone} from "../../../utils/date-time";
import {Table} from "../../table";
import {getCustomerName} from "../../../utils/customer";
import {SummaryHeader} from "../../summary-header";
import {SummaryContent} from "../../summary-content";

export const CDialog = styled(Dialog)<DialogProps>(
    (/* { theme } */) => ({}),
);

export const CDialogActions = styled(DialogActions)<DialogActionsProps>(
    (/* { theme } */) => ({}),
);

export const CDialogContent = styled(DialogContent)<DialogContentProps>(
    (/* { theme } */) => ({}),
);

export const CDialogTitle = styled(DialogTitle)<DialogTitleProps>(
    (/* { theme } */) => ({}),
);

export const CDialogContentText = styled(DialogContentText)<DialogContentTextProps>(
    (/* { theme } */) => ({}),
);

interface CConfirmationDialogProps extends DialogProps {
    text: string
    title?: string
    acceptTitle?: string
    declineTitle?: string
    onAccept: () => void
    onDecline: () => void
}

export const CConfirmationDialog = (props: CConfirmationDialogProps) => {

    const {text, title, acceptTitle, declineTitle, onAccept, onDecline} = props

    return (
        <CDialog open={props.open} fullWidth PaperProps={{
            sx: {
                borderRadius: "37px"
            }
        }}>
            {title && <CDialogTitle>
                {title}
            </CDialogTitle>}
            <CDialogContent>
                <CDialogContentText>
                    {text}
                </CDialogContentText>
            </CDialogContent>
            <DialogActions>
                <CButton onClick={onDecline}>{declineTitle || `Disagree`}</CButton>
                <CButton variant={"contained"} onClick={onAccept}>{acceptTitle || `Agree`}</CButton>
            </DialogActions>
        </CDialog>
    )
}

interface CCancelOrderConfirmationDialogProps extends DialogProps {
    mode: string
    open: boolean
    onDecline: () => void
}

export const CCancelOrderConfirmationDialog = (props: CCancelOrderConfirmationDialogProps) => {

    const {mode, open, onDecline} = props

    const navigate = useNavigate();

    const {cartStore} = useStores()

    return (
        <CDialog open={open} fullWidth>
            <CDialogTitle>
                Confirmation
            </CDialogTitle>
            <CDialogContent>
                <CDialogContentText>
                    Are you sure you want to cancel this order?
                </CDialogContentText>
            </CDialogContent>
            <DialogActions>
                <CButton onClick={onDecline}>{'No'}</CButton>
                <CButton onClick={() => {
                    cartStore.saveCart({...defaultCart})
                    if (mode === 'store') {
                        navigate(`/`)
                    } else {
                        navigate(`/${navigationParams(mode)}disclaimer`)
                    }
                }}>{'Yes'}</CButton>
            </DialogActions>
        </CDialog>
    )
}

export const DisclaimerDialog = ({onEnter}) => {

    const guestAuth = useAppInit()
    const {locationInfoState, locationInfo} = guestAuth

    const {authStore, configStore} = useStores()

    const [isAgeDialogOpen, setIsAgeDialogOpen] = useState(false)

    const {configs} = configStore

    const termsValue = getConfig(configs, 'terms-and-conditions')

    useEffect(() => {

        ;(async () => {
            if (locationInfoState === 'loaded') {
                if (authStore.ageConfirmed !== true) {
                    setIsAgeDialogOpen(true)
                }
            }
        })()

    }, [locationInfoState])


    return (
        <CDialog disableEscapeKeyDown open={isAgeDialogOpen} fullWidth PaperProps={{
            sx: {
                borderRadius: "37px"
            }
        }}>
            <CDialogContent sx={{overflow: 'hidden'}}>
                <POSTitle sx={{
                    mb: 4,
                    textAlign: "flex-start",
                    fontFamily: "Passion One"
                }}>
                    {locationInfo?.locationName}
                </POSTitle>
                <CBox
                    mb={2}
                    mx={1}
                    sx={{
                        flexGrow: 1,
                        width: "100%",
                        // borderRadius: '22px',
                        // border: "1px solid #CACACA",
                        // overflowY: "scroll"
                    }}>
                    <CBox
                        // sx={{margin: '17px'}}
                    >
                        {termsValue.split("\n").map((i, key) => {
                            return <CTypography fontSize={16} key={key}>{i}</CTypography>;
                        })}
                    </CBox>

                </CBox>
                {/*<CBox width={"100%"} mb={4}>*/}
                {/*    <CFormControlLabel*/}
                {/*        control={<CCheckbox checked={termsChecked}*/}
                {/*                            onChange={handleTermsCheckedChangeChange}/>}*/}


                {/*        label={agreementCheckboxValue}/>*/}
                {/*    <br/>*/}
                {/*    <CFormControlLabel control={<CCheckbox checked={ageChecked}*/}
                {/*                                           onChange={handleAgeCheckedChange}/>}*/}
                {/*                       label={ageCheckboxValue}/>*/}
                {/*</CBox>*/}
                <CBox display={'flex'} flexDirection={'row'}>
                    <CButton sx={{flex: 1}} variant={"outlined"}
                             onClick={() => {
                                 window.location.href = 'about:blank'
                             }}>
                        No
                    </CButton>
                    <CBox width={8}/>
                    <CButton sx={{flex: 1}} variant={"contained"}
                        // disabled={!ageChecked || !termsChecked}
                             onClick={() => {
                                 authStore.saveAgeConfirmed(true)
                                 setIsAgeDialogOpen(false)
                                 if (onEnter) {
                                     onEnter()
                                 }
                             }}>
                        Yes
                    </CButton>
                </CBox>

            </CDialogContent>
        </CDialog>
    )
}

const containerStyle = {
    width: '100%',
    height: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

const center = {
    lat: -3.745,
    lng: -38.523
};

interface DeliveryMethodDialogProps {
    isOpen: boolean,
    onSelect: () => void,
    onClose: () => void
    autoSave?: boolean
    deliveryEnabled?: boolean
}

// const libraries = ['places']

let statelessSelectedLockboxId = -1
let statelessSelectedLockboxText = ''
let statelessSelectedLockboxLat: number | null = null
let statelessSelectedLockboxLng: number | null = null
// let statelessSelectedPickupHour: string | null = null
// let statelessSelectedPickupMinute: string | null = null
// let statelessSelectedPickupAmPm = "AM"
let statelessSelectedPickupDate: Dayjs = dayjs()
// const amHours = ['10', '11']
// const pmHours = ['12', '01', '02', '03', '04', '05', '06', '07', '09']
// const zeroMinutes = ['00']
// const fullMinutes = ['00', '10', '20', '30', '40', '50']
const hours = ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21']
const minutes = ['00', '15', '30', '45']
export const DeliveryMethodSelector = observer(function ({
                                                             isOpen,
                                                             autoSave,
                                                             onSelect,
                                                             onClose,
                                                             deliveryEnabled
                                                         }: DeliveryMethodDialogProps) {

    const guestAuth = useAppInit()

    const {guestAuthToken, guestAuthState} = guestAuth

    const {cartStore, locationStore} = useStores()

    const {lockboxes} = locationStore

    const [map, setMap] = React.useState(null)
    const [isGettingLocations, setIsGettingLocations] = React.useState(true)
    const [selectedLockboxId, setSelectedLockboxId] = React.useState(-1)
    const [selectedLockboxLatLong, setSelectedLockboxLatLong] = React.useState(null)
    // const [selectedLockboxText, setSelectedLockboxText] = React.useState("")
    // const [selectedLockboxLat, setSelectedLockboxLat] = React.useState<number | null>(null)
    // const [selectedLockboxLng, setSelectedLockboxLng] = React.useState<number | null>(null)
    const [searchResult, setSearchResult] = useState<any>(null)
    const [addressErrorMessage, setAddressErrorMessage] = useState<any>({
        show: false,
        message: ''
    })
    // const [selectedPickupHour, setSelectedPickupHour] = useState<string | null>(null)
    // const [selectedPickupMinute, setSelectedPickupMinute] = useState<string | null>(null)
    // const [selectedPickupAmPm, setSelectedPickupAmPm] = useState("AM")
    const [selectedPickupDate, setSelectedPickupDate] = React.useState<Dayjs | null>(null)


    useEffect(() => {

        ;(async () => {
            if (isOpen) {
                if (cartStore.cart?.cartLockboxId) {
                    setSelectedLockboxId(cartStore.cart.cartLockboxId)
                    statelessSelectedLockboxId = cartStore.cart.cartLockboxId
                }
                // setCurrentDateAndTime()
            }
        })()

    }, [isOpen])

    useEffect(() => {
        ;(async () => {
            Geocode.setApiKey("AIzaSyCCzq3G9J8lkO2AAU8WDyDhzBah09MS81g");
            if (guestAuthState === 'loaded') {
                await init()
                preSelectPickupDate()
            }
        })()
    }, [guestAuthToken, guestAuthState]);

    // function convertTo12Hour(time) {
    //     const [hours, minutes] = time.split(':');
    //     let newHours = parseInt(hours, 10);
    //     let period = 'AM';
    //
    //     if (newHours === 0) {
    //         newHours = 12;
    //     } else if (newHours > 12) {
    //         newHours -= 12;
    //         period = 'PM';
    //     } else if (newHours === 12) {
    //         period = 'PM';
    //     }
    //
    //     const formattedHours = newHours.toString().padStart(2, '0');
    //
    //     return `${formattedHours}:${minutes} ${period}`;
    // }

    // function roundMinuteToTen(currentMinute) {
    //     const remainder = currentMinute % 10;
    //     if (currentMinute + (10 - remainder) > 59) {
    //         return 50
    //     } else {
    //         return currentMinute + (10 - remainder);
    //     }
    //
    // }

    // const setCurrentDateAndTime = () => {
    //     try {
    //
    //
    //         if (cartStore.cart && !cartStore.cart.cartPickupDate) {
    //             const currentDate = dayjs()
    //             setSelectedPickupDate(currentDate)
    //             statelessSelectedPickupDate = currentDate
    //             // cartStore.saveCart({
    //             //     ...cartStore.cart,
    //             //     cartPickupDate: statelessSelectedPickupDate
    //             // })
    //         }
    //         console.log('===================>1')
    //         if (cartStore.cart && !cartStore.cart.cartPickupTime) {
    //             console.log('===================>1.1')
    //             const now = new Date(); // create a new Date object representing the current date and time
    //             console.log('===================>1.2')
    //             let currentHour = now.getHours(); // get the current hour (0-23)
    //             console.log('===================>1.3')
    //             const currentMinute = roundMinuteToTen(now.getMinutes()); // get the current minute (0-59)
    //             console.log('===================>2')
    //             if (currentHour > 21) {
    //                 console.log('===================>3')
    //                 setSelectedPickupAmPm('AM')
    //                 statelessSelectedPickupAmPm = 'AM'
    //                 setSelectedPickupHour(amHours[0])
    //                 statelessSelectedPickupHour = amHours[0]
    //                 setSelectedPickupMinute('00')
    //                 statelessSelectedPickupMinute = '00'
    //                 setSelectedPickupDate(dayjs().add(1, 'day'))
    //                 statelessSelectedPickupDate = dayjs().add(1, 'day')
    //                 cartStore.saveCart({
    //                     ...cartStore.cart,
    //                     cartPickupDate: statelessSelectedPickupDate,
    //                     // cartPickupTime: `09:00`
    //                 })
    //             } else {
    //                 console.log('===================>4')
    //
    //                 if (currentHour < Number(amHours[0])) {
    //                     currentHour = Number(amHours[0])
    //                 }
    //                 const current12HoursTime = convertTo12Hour(`${currentHour}:${currentMinute}`)
    //                 const current12HoursHour = current12HoursTime.split(':')[0].padStart(2, '0')
    //                 setSelectedPickupAmPm(current12HoursTime.split(' ')[1])
    //                 statelessSelectedPickupAmPm = current12HoursTime.split(' ')[1]
    //                 setSelectedPickupHour(current12HoursHour)
    //                 statelessSelectedPickupHour = current12HoursHour
    //                 setSelectedPickupMinute(current12HoursTime.split(':')[1])
    //                 statelessSelectedPickupMinute = current12HoursTime.split(':')[1]
    //                 cartStore.saveCart({
    //                     ...cartStore.cart,
    //                     cartPickupDate: statelessSelectedPickupDate,
    //                     // cartPickupTime: `${currentHour.toString().padStart(2, '0')}:${currentMinute}`
    //                 })
    //             }
    //
    //         }
    //     } catch (e) {
    //         console.log('setCurrentDateAndTime', e)
    //     }
    // }

    const init = async () => {
        setIsGettingLocations(true)
        await locationStore.findAll({
            locationTypeId: 2
        }, guestAuthToken, true)
        setIsGettingLocations(false)
        // if (cartStore.cart && cartStore.cart.cartPickupTime !== null) {
        //     const pickupTime12HourFormat = convertTo12Hour(cartStore.cart.cartPickupTime)
        //     const hour = pickupTime12HourFormat.split(':')[0]
        //     setSelectedPickupHour(hour)
        //     statelessSelectedPickupHour = hour
        //     const minute = pickupTime12HourFormat.split(':')[1].split(' ')[0]
        //     setSelectedPickupMinute(minute)
        //     statelessSelectedPickupMinute = minute
        //     const amPm = pickupTime12HourFormat.split(':')[1].split(' ')[1]
        //     setSelectedPickupAmPm(amPm)
        //     statelessSelectedPickupAmPm = amPm
        // }
        // setTimeout(()=>setCurrentDateAndTime(), 2000)

    }

    const geoCodeAndShowOnMap = (address, map) => {
        console.log(address)
        Geocode.setLocationType("ROOFTOP");
        Geocode.fromAddress(address).then(
            (response) => {
                const {lat, lng} = response.results[0].geometry.location;
                console.log("geoCodeAndShowOnMap", {
                    lat,
                    lng
                })
                // @ts-ignore
                map.panTo({
                    lat,
                    lng
                })
                // @ts-ignore
                setSelectedLockboxLatLong({
                    // @ts-ignore
                    lat,
                    // @ts-ignore
                    lng
                })
            },
            (error) => {
                console.error("geoCodeAndShowOnMap", error);
            }
        );

    }

    const preSelectTheFirstLockbox = (map) => {
        setSelectedLockboxId(Number(lockboxes[0].locationId))
        statelessSelectedLockboxId = Number(lockboxes[0].locationId)

        // statelessSelectedLockboxText = `${lockboxes[0].locationName} (${calcCrow({
        //     lat1: currentLocation.lat,
        //     lon1: currentLocation.lng,
        //     lat2: lockboxes[0].locationLat,
        //     lon2: lockboxes[0].locationLong
        // }, true)} miles away)`
        statelessSelectedLockboxText = `${lockboxes[0].locationAddress1}, ${lockboxes[0].locationCity}, ${lockboxes[0].locationState} ${lockboxes[0].locationZip}`

        statelessSelectedLockboxLat = Number(lockboxes[0]?.locationLat)

        statelessSelectedLockboxLng = Number(lockboxes[0]?.locationLong)
        geoCodeAndShowOnMap(`${lockboxes[0].locationAddress1}, ${lockboxes[0].locationCity}, ${lockboxes[0].locationState} ${lockboxes[0].locationZip}`, map)
        // setTimeout(()=>{
        //     // @ts-ignore
        //     map.panTo({
        //         lat: Number(lockboxes[0].locationLat),
        //         lng: Number(lockboxes[0].locationLong)
        //     })
        //     // map.addMarker({
        //     //     lat: Number(lockboxes[0].locationLat),
        //     //     lng: Number(lockboxes[0].locationLong)
        //     // })
        //     // @ts-ignore
        //     setSelectedLockboxLatLong({
        //         // @ts-ignore
        //         lat: Number(lockboxes[0].locationLat),
        //         // @ts-ignore
        //         lng: Number(lockboxes[0].locationLong)
        //     })
        //     console.log({
        //         // @ts-ignore
        //         lat: Number(lockboxes[0].locationLat),
        //         // @ts-ignore
        //         lng: Number(lockboxes[0].locationLong)
        //     })
        // }, 2000)

    }

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);

        map.setZoom(15)

        // navigator.geolocation.getCurrentPosition(function (position) {
        //     setCurrentLocation({
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude
        //     })
        //     // setCurrentLocation({
        //     //     lat: 33.669445,
        //     //     lng: -117.823059
        //     // })
        //     const bounds = new window.google.maps.LatLngBounds(currentLocation);
        //     map.fitBounds(bounds)
        //     map.setZoom(15)
        // });
        setMap(map)
        if (hasValue(lockboxes) && lockboxes.length === 1) {
            preSelectTheFirstLockbox(map)
            preSelectPickupDate()
            // setCurrentDateAndTime()
        }
    }, [])

    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])

    // function onAutocompleteLoad(autocomplete) {
    //     setSearchResult(autocomplete)
    // }
    //
    // function onPlaceChanged() {
    //     //variable to store the result
    //     const place = searchResult?.getPlace();
    //     // //variable to store the name from place details result
    //     // const name = place.name;
    //     // //variable to store the status from place details result
    //     // const status = place.business_status;
    //     //variable to store the formatted address from place details result
    //     const formattedAddress = place.formatted_address;
    //     // const latLong = place?.geometry?.location;
    //
    //     //console log all results
    //     // props.onPlaceSelected(formattedAddress, latLong);
    //
    //     // if (checkOrangeCountyAddress(place)) {
    //     cartStore.saveCart({
    //         ...cartStore.cart,
    //         cartDeliveryFullAddress: formattedAddress
    //     })
    //     setAddressErrorMessage({
    //         show: false,
    //         message: ''
    //     })
    //     // } else {
    //     //     cartStore.saveCart({
    //     //         ...cartStore.cart,
    //     //         cartDeliveryFullAddress: ''
    //     //     })
    //     //     setAddressErrorMessage({
    //     //         show: true,
    //     //         message: 'Please select an address within Orange county.'
    //     //     })
    //     // }
    //     if (autoSave) {
    //         handleSelect()
    //     }
    // }

    const preSelectPickupDate = () => {
        if (!selectedPickupDate) {
            let defaultDateTime;
            if (dayjs().hour() < 10) {
                defaultDateTime = dayjs().set("hour", 10).set("minute", 0)
            } else if (dayjs().hour() > 21) {
                defaultDateTime = dayjs().add(1, 'days').set("hour", 10).set("minute", 45)
            } else {
                defaultDateTime = dayjs().set("minute", 45)
            }
            setSelectedPickupDate(defaultDateTime)
            statelessSelectedPickupDate = defaultDateTime
            if (autoSave) {
                handleSelect()
            }
        }
    }


    function handleSelect() {
        // const current24HourPickupTime = (hasValue(statelessSelectedPickupHour) && hasValue(statelessSelectedPickupMinute)) ?
        //     convertTo24Hour(`${statelessSelectedPickupHour}:${statelessSelectedPickupMinute} ${statelessSelectedPickupAmPm}`) :
        //     null

        // console.log('handleSelect', statelessSelectedPickupDate.toISOString().split('.')[0] + '.000Z')
        cartStore.saveCart({
            ...cartStore.cart,
            cartDeliveryMethodSelected: true,
            cartLockboxId: statelessSelectedLockboxId,
            cartLockboxText: statelessSelectedLockboxText,
            cartLockboxLat: statelessSelectedLockboxLat,
            cartLockboxLng: statelessSelectedLockboxLng,
            // cartPickupTime: current24HourPickupTime,
            cartPickupDate: statelessSelectedPickupDate.toISOString().split('.')[0] + '.000Z'
            // cartSkus: []
        })
        if (onSelect) {
            onSelect()
        }
    }

    {console.log('guestAuth.locationInfo?.locationSetting?.locationSettingDeliveryEnabled', guestAuth.locationInfo?.locationSetting?.locationSettingDeliveryEnabled)}

    return (
        <>
            <CBox sx={{
                width: '100%'
            }}>
                <CTypography mb={1} color={'primary'} display={'flex'} alignItems={'center'} variant={'subtitle1'}>
                    Delivery Method:
                </CTypography>
            </CBox>
            <CBox sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                zIndex: 999,
                marginLeft: -1
            }}>
                <ChipSelect
                    selected={cartStore.cart?.cartDeliveryMethod === 'lockbox'}
                    title={'Pick Up'}
                    onClick={() => {
                        cartStore.saveCart({
                            ...cartStore.cart,
                            cartDeliveryMethod: "lockbox",
                            cartDeliveryFullAddress: ''
                        })
                        setAddressErrorMessage({
                            show: false,
                            message: ''
                        })
                        // setSelectedLockboxId(-1)
                        // statelessSelectedLockboxId = -1
                        if (autoSave) {
                            handleSelect()
                        }
                    }}
                />
                {(guestAuth.locationInfo?.locationSetting?.locationSettingDeliveryEnabled === 1 || deliveryEnabled) && <ChipSelect
                    selected={cartStore.cart?.cartDeliveryMethod === 'local-delivery'}
                    title={'Delivery'}
                    onClick={() => {
                        cartStore.saveCart({
                            ...cartStore.cart,
                            cartDeliveryMethod: "local-delivery",
                            // cartLockboxText: 'Delivery Lockbox'
                        })
                        if (hasValue(lockboxes) && lockboxes.length > 1) {
                            if (typeof lockboxes[0].locationId === 'number') {
                                setSelectedLockboxId(lockboxes[0].locationId)
                                statelessSelectedLockboxId = lockboxes[0].locationId
                                statelessSelectedLockboxText = `${lockboxes[0].locationAddress1}, ${lockboxes[0].locationCity}, ${lockboxes[0].locationState} ${lockboxes[0].locationZip}`
                            }
                        }
                        // setSelectedLockboxId(42)
                        // statelessSelectedLockboxId = 42
                        if (autoSave) {
                            handleSelect()
                        }
                    }}
                />}


                {/*cartStore.cart?.cartDeliveryMethod === 'local-delivery'  && <CPlacesACTextField sx={{flex:1}} size={'small'}
                                                                                                     label={"Full-Address"} onPlaceSelected={(address) => {
                        cartStore.saveCart({
                            ...cartStore.cart,
                            cartDeliveryFullAddress: address
                        })

                    }}
                                                                                                     value={cartStore.cart?.cartDeliveryFullAddress}>

                    </CPlacesACTextField>*/}
            </CBox>
            {cartStore.cart?.cartDeliveryMethod === 'local-delivery' &&
                <CBox sx={{width: '100%', my: 1}}><TextField
                    sx={{flex: 1}}
                    label="Delivery Address"
                    fullWidth
                    onChange={(event) => {
                        cartStore.saveCart({
                            ...cartStore.cart,
                            cartDeliveryFullAddress: event.target.value
                        })
                        if (autoSave) {
                            handleSelect()
                        }
                    }}
                    value={cartStore.cart?.cartDeliveryFullAddress}
                /></CBox>}
            {/*cartStore.cart?.cartDeliveryMethod === 'local-delivery' && isMapLoaded &&
                <CBox sx={{width: '100%', my: 1}}><Autocomplete
                    onLoad={onAutocompleteLoad}
                    options={{types: ['address']}}
                    fields={['formatted_address', 'address_component']}
                    onPlaceChanged={onPlaceChanged}
                    className={'google-map-ac'}
                >
                    <TextField
                        sx={{flex: 1}}
                        label="Delivery Address"
                        onChange={(event) => {
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartDeliveryFullAddress: event.target.value
                            })
                            if (autoSave) {
                                handleSelect()
                            }
                        }}
                        value={cartStore.cart?.cartDeliveryFullAddress}
                    />
                </Autocomplete></CBox>*/}
            {cartStore.cart?.cartDeliveryMethod === 'local-delivery' && addressErrorMessage.show &&

                <CBox sx={{
                    width: '100%',
                }}>
                    <CTypography mb={2} color={'red'}>
                        {addressErrorMessage.message}
                    </CTypography>
                </CBox>
            }

            <>
                {(cartStore.cart?.cartDeliveryMethod === 'lockbox' && hasValue(lockboxes) && lockboxes.length > 1) &&
                    <CBox sx={{
                        width: '100%'
                    }}>
                        <CTypography mt={1} mb={1} display={'flex'} variant={'subtitle1'} alignItems={'center'}
                                     color={'primary'}>
                            Lockbox Location:
                        </CTypography>
                    </CBox>}

                {(cartStore.cart?.cartDeliveryMethod === 'lockbox' && hasValue(lockboxes) && lockboxes.length === 1) &&
                    <CBox sx={{
                        width: '100%'
                    }}>
                        <CTypography mt={1} mb={1} display={'flex'} variant={'subtitle1'} alignItems={'center'}
                                     color={'primary'}>
                            Lockbox Location:
                        </CTypography>
                        <CTypography mt={1} mb={1} typography={'body2'}>
                            {lockboxes[0].locationAddress1}<br/>{lockboxes[0].locationCity}, {lockboxes[0].locationState} {lockboxes[0].locationZip}
                        </CTypography>
                    </CBox>}

                {cartStore.cart?.cartDeliveryMethod === 'lockbox' && isGettingLocations &&
                    <CLinearProgress></CLinearProgress>}
                {(cartStore.cart?.cartDeliveryMethod === 'lockbox' && hasValue(lockboxes) && lockboxes.length > 1) &&
                    <ScrollDrag
                        sx={{
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            width: "100%",
                            mb: 2,
                            scrollbarWidth: 'none'
                        }}>
                        {
                            hasValue(lockboxes) && lockboxes.map((location, index) => {
                                // if (location.locationId === 42) {
                                //     return <></>
                                // }
                                return <LockboxLocationSelectable
                                    key={`${location.locationId}${index}_lockbox_locations_dialog${new Date().getTime()}`}
                                    onClick={() => {
                                        setSelectedLockboxId(Number(location.locationId))
                                        statelessSelectedLockboxId = Number(location.locationId)
                                        // setSelectedLockboxText(`${location.locationName} (${calcCrow({
                                        //     lat1: currentLocation.lat,
                                        //     lon1: currentLocation.lng,
                                        //     lat2: location.locationLat,
                                        //     lon2: location.locationLong
                                        // }, true)} miles away)`)
                                        // statelessSelectedLockboxText = `${location.locationName} (${calcCrow({
                                        //     lat1: currentLocation.lat,
                                        //     lon1: currentLocation.lng,
                                        //     lat2: location.locationLat,
                                        //     lon2: location.locationLong
                                        // }, true)} miles away)`
                                        statelessSelectedLockboxText = `${location.locationAddress1}, ${location.locationCity}, ${location.locationState} ${location.locationZip}`

                                        // setSelectedLockboxLat(Number(location?.locationLat))
                                        statelessSelectedLockboxLat = Number(location?.locationLat)
                                        // setSelectedLockboxLng(Number(location?.locationLong))
                                        statelessSelectedLockboxLng = Number(location?.locationLong)
                                        // const bounds = new window.google.maps.LatLngBounds()
                                        geoCodeAndShowOnMap(`${location.locationAddress1}, ${location.locationCity}, ${location.locationState} ${location.locationZip}`, map)
                                        // if (hasValue(map)) {
                                        //     // @ts-ignore
                                        //     map.panTo({
                                        //         lat: Number(location.locationLat),
                                        //         lng: Number(location.locationLong)
                                        //     })
                                        //     // @ts-ignore
                                        //     setSelectedLockboxLatLong({
                                        //         // @ts-ignore
                                        //         lat: Number(location.locationLat),
                                        //         // @ts-ignore
                                        //         lng: Number(location.locationLong)
                                        //     })
                                        // }
                                        if (autoSave) {
                                            handleSelect()
                                        }
                                    }}
                                    title={`${location.locationAddress1}, ${location.locationCity}, ${location.locationState} ${location.locationZip}`}
                                    // subtitle={`${calcCrow({
                                    //     lat1: currentLocation.lat,
                                    //     lon1: currentLocation.lng,
                                    //     lat2: location.locationLat,
                                    //     lon2: location.locationLong
                                    // }, true)} miles away`}
                                    isSelected={selectedLockboxId === location.locationId}
                                />
                            })
                        }

                    </ScrollDrag>}

                {cartStore.cart?.cartDeliveryMethod === 'lockbox' && <CBox mb={4} sx={{
                    position: "relative",
                    flexGrow: 1,
                    width: "100%",
                    borderRadius: '4px',
                    border: "1px solid #CACACA",
                    overflow: "hidden"
                }}>
                    {
                        isMapLoaded ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                options={{
                                    disableDefaultUI: true
                                }}
                            >
                                {selectedLockboxLatLong && <Marker position={selectedLockboxLatLong}/>}
                                { /* Child components, such as markers, info windows, etc. */}
                                {/*<CBox sx={{zIndex: 999}}>*/}
                                {/*    <LocationOn sx={{fontSize: 48}} color={"primary"}/>*/}

                                {/*</CBox>*/}

                                {/*{*/}
                                {/*    (*/}
                                {/*        hasValue(currentLocation?.lng) &&*/}
                                {/*        // @ts-ignore*/}
                                {/*        hasValue(selectedLockboxLatLong?.lng)*/}
                                {/*    ) && (*/}
                                {/*        <DirectionsService*/}
                                {/*            // required*/}
                                {/*            options={{*/}
                                {/*                // @ts-ignore*/}
                                {/*                destination: selectedLockboxLatLong,*/}
                                {/*                origin: currentLocation,*/}
                                {/*                travelMode: google.maps.TravelMode.DRIVING*/}
                                {/*            }}*/}
                                {/*            // required*/}
                                {/*            callback={directionsCallback}*/}
                                {/*            // optional*/}
                                {/*            // onLoad={directionsService => {*/}
                                {/*            // }}*/}
                                {/*            // // optional*/}
                                {/*            // onUnmount={directionsService => {*/}
                                {/*            // }}*/}
                                {/*        />*/}
                                {/*    )*/}
                                {/*}*/}

                                {/*{*/}
                                {/*    response !== null && (*/}
                                {/*        <DirectionsRenderer*/}
                                {/*            // required*/}
                                {/*            options={{*/}
                                {/*                directions: response*/}
                                {/*            }}*/}
                                {/*            // optional*/}
                                {/*            // onLoad={directionsRenderer => {*/}
                                {/*            // }}*/}
                                {/*            // // optional*/}
                                {/*            // onUnmount={directionsRenderer => {*/}
                                {/*            // }}*/}
                                {/*        />*/}
                                {/*    )*/}
                                {/*}*/}

                            </GoogleMap>
                        ) : <></>
                    }

                </CBox>}
                {/*{cartStore.cart?.cartDeliveryMethod === 'lockbox' && <CBox sx={{*/}
                {/*    width: '100%'*/}
                {/*}}>*/}
                {/*    <CTypography mt={1} mb={1} ml={1}>*/}
                {/*        Pickup Date:*/}
                {/*    </CTypography>*/}
                {/*</CBox>}*/}

                {cartStore.cart?.cartDeliveryMethod === 'lockbox' && <CGridContainer spacing={2} px={0} mb={4}>
                    <CGrid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                maxDate={dayjs().add(7, 'days')}
                                minDate={dayjs().hour() > 21 ? dayjs().add(1, 'day') : dayjs()}
                                // minTime={dayjs().hour() < 10 ? dayjs().set("hour", 10).set("minute", 0) : dayjs()}
                                // maxTime={dayjs().hour() > 21 &&
                                // dayjs().minute() > 50 ?
                                //     dayjs().add(1, 'days').set("hour", 21).set("minute", 50) :
                                //     dayjs().set("hour", 21).set("minute", 50)
                                // }
                                // minutesStep={10}
                                label="Pickup Date"
                                inputFormat="YYYY-MM-DD"
                                value={selectedPickupDate}
                                onOpen={preSelectPickupDate}
                                onChange={(newValue: Dayjs | null) => {
                                    if (newValue) {
                                        console.log("Pickup Date", newValue)
                                        console.log("Pickup Date", newValue?.toISOString())
                                        setSelectedPickupDate(newValue)
                                        statelessSelectedPickupDate = newValue
                                        if (autoSave) {
                                            handleSelect()
                                        }
                                    }


                                    // setFromDate(newValue)
                                    // statelessFromDate = newValue
                                    // refresh()
                                }}
                                renderInput={(params) => <CTextField fullWidth {...params}/>}
                            />
                        </LocalizationProvider>
                    </CGrid>
                    {selectedPickupDate && <CGrid item xs={6}>
                        <CTextField fullWidth label={"HH"} select
                                    value={selectedPickupDate.hour().toString().padStart(2, '0')} onChange={(event) => {
                            const value = event.target.value;
                            const currentSelectedPickupDate = selectedPickupDate.set('hour', Number(value))
                            setSelectedPickupDate(currentSelectedPickupDate)
                            statelessSelectedPickupDate = currentSelectedPickupDate
                            if (autoSave) {
                                handleSelect()
                            }
                        }}>
                            {hours.map(hour => <CMenuItem
                                disabled={selectedPickupDate?.day() === dayjs().day() ? Number(hour) < dayjs().hour() : false}
                                value={hour}>{hour}</CMenuItem>)}
                        </CTextField>
                    </CGrid>}
                    {selectedPickupDate && <CGrid item xs={6}>
                        <CTextField fullWidth label={"MM"} select
                                    value={selectedPickupDate.minute().toString().padStart(2, '0')}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const currentSelectedPickupDate = selectedPickupDate.set('minutes', Number(value))
                                        setSelectedPickupDate(currentSelectedPickupDate)
                                        statelessSelectedPickupDate = currentSelectedPickupDate
                                        if (autoSave) {
                                            handleSelect()
                                        }
                                    }}>
                            {minutes.map(minute => <CMenuItem
                                disabled={(selectedPickupDate?.day() === dayjs().day() && selectedPickupDate?.hour() === dayjs().hour()) ?
                                    Number(minute) < dayjs().minute() :
                                    false}
                                value={minute}>
                                {minute}
                            </CMenuItem>)}
                        </CTextField>
                    </CGrid>}
                </CGridContainer>}
                {/*{cartStore.cart?.cartDeliveryMethod === 'lockbox' && <CBox sx={{*/}
                {/*    width: '100%'*/}
                {/*}}>*/}
                {/*    <CTypography mt={1} mb={1} ml={1}>*/}
                {/*        Pickup Time:*/}
                {/*    </CTypography>*/}
                {/*</CBox>}*/}
                {/*{cartStore.cart?.cartDeliveryMethod === 'lockbox' && <CGridContainer spacing={2} px={1} mb={4}>*/}
                {/*    <CGrid item xs={4}>*/}
                {/*        <CTextField fullWidth label={"Hour"} select*/}
                {/*                    InputLabelProps={{*/}
                {/*                        shrink: hasValue(selectedPickupHour)*/}
                {/*                    }}*/}

                {/*            // error={hasValue(validation.status)}*/}
                {/*            // helperText={hasValue(validation.status) ? validation.status : null}*/}
                {/*                    onChange={(event) => {*/}
                {/*                        const value = event.target.value;*/}
                {/*                        setSelectedPickupHour(value)*/}
                {/*                        statelessSelectedPickupHour = value*/}
                {/*                        // if (value === '05') {*/}
                {/*                        //     setSelectedPickupMinute('00')*/}
                {/*                        //     statelessSelectedPickupMinute = '00'*/}
                {/*                        // }*/}
                {/*                        if (autoSave) {*/}
                {/*                            handleSelect()*/}
                {/*                        }*/}
                {/*                    }} value={selectedPickupHour}>*/}
                {/*            {selectedPickupAmPm === 'AM' ?*/}
                {/*                amHours.map(hour => <CMenuItem value={hour}>{hour}</CMenuItem>) :*/}
                {/*                pmHours.map(hour => <CMenuItem value={hour}>{hour}</CMenuItem>)*/}
                {/*            }*/}
                {/*        </CTextField>*/}
                {/*    </CGrid>*/}
                {/*    <CGrid item xs={4}>*/}
                {/*        <CTextField fullWidth label={"Minute"} select*/}
                {/*                    InputLabelProps={{*/}
                {/*                        shrink: hasValue(selectedPickupMinute)*/}
                {/*                    }}*/}
                {/*            // error={hasValue(validation.status)}*/}
                {/*            // helperText={hasValue(validation.status) ? validation.status : null}*/}
                {/*                    onChange={(event) => {*/}
                {/*                        const value = event.target.value;*/}
                {/*                        setSelectedPickupMinute(value)*/}
                {/*                        statelessSelectedPickupMinute = value*/}
                {/*                        if (autoSave) {*/}
                {/*                            handleSelect()*/}
                {/*                        }*/}
                {/*                    }} value={selectedPickupMinute}>*/}
                {/*            {selectedPickupHour === '05' ?*/}
                {/*                zeroMinutes.map(minute => <CMenuItem value={minute}>{minute}</CMenuItem>) :*/}
                {/*                fullMinutes.map(minute => <CMenuItem value={minute}>{minute}</CMenuItem>)*/}
                {/*            }*/}

                {/*        </CTextField>*/}
                {/*    </CGrid>*/}
                {/*    <CGrid item xs={4}>*/}
                {/*        <CTextField fullWidth label={"AM/PM"} select*/}
                {/*            // error={hasValue(validation.status)}*/}
                {/*            // helperText={hasValue(validation.status) ? validation.status : null}*/}
                {/*                    onChange={(event) => {*/}
                {/*                        const value = event.target.value;*/}
                {/*                        setSelectedPickupAmPm(value)*/}
                {/*                        statelessSelectedPickupAmPm = value*/}
                {/*                        setSelectedPickupHour(null)*/}
                {/*                        statelessSelectedPickupHour = null*/}
                {/*                        if (autoSave) {*/}
                {/*                            handleSelect()*/}
                {/*                        }*/}
                {/*                    }} value={selectedPickupAmPm}>*/}
                {/*            <CMenuItem value={"AM"}>AM</CMenuItem>*/}
                {/*            <CMenuItem value={"PM"}>PM</CMenuItem>*/}
                {/*        </CTextField>*/}
                {/*    </CGrid>*/}
                {/*</CGridContainer>}*/}
            </>
            {!autoSave && <CBox width={"100%"} justifyContent={"center"} display={'flex'}>
                <CButton
                    sx={{width: 157, mr: 2}}
                    variant={"outlined"}
                    onClick={() => {
                        // navigate(`/${navigationParams(params.mode)}disclaimer`)
                        onClose()
                    }}
                >
                    Close
                </CButton>
                <CButton sx={{width: 157}} variant={"contained"}
                         disabled={
                             selectedLockboxId < 0 ||
                             addressErrorMessage.show === true ||
                             (cartStore.cart?.cartDeliveryMethod === 'local-delivery' && cartStore.cart?.cartDeliveryFullAddress?.length === 0)
                         }
                         onClick={handleSelect}>
                    Select
                </CButton>
            </CBox>}
        </>)
})

export const DeliveryMethodDialog = observer(function DeliveryMethodDialog({
                                                                               isOpen,
                                                                               onSelect,
                                                                               onClose
                                                                           }: DeliveryMethodDialogProps) {

    const [isDeliveryMethodDialogOpen, setIsDeliveryMethodDialogOpen] = useState(false)

    const guestAuth = useAppInit()

    const {locationInfo} = guestAuth

    useEffect(() => {

        ;(async () => {
            if (isOpen) {
                setIsDeliveryMethodDialogOpen(true)
            }
        })()

    }, [isOpen])

    return (
        <>

            <CDialog open={isDeliveryMethodDialogOpen} fullWidth PaperProps={{
                sx: {borderRadius: "37px"}
            }}>
                <CDialogContent>
                    {/*{cartStore.cart?.cartLockboxId && cartStore.cart?.cartSkus.length > 0 &&*/}

                    {/*    <CBox sx={{*/}
                    {/*        width: '100%',*/}
                    {/*    }}>*/}
                    {/*        <CTypography mb={2} ml={1} color={'red'}>*/}
                    {/*            Changing the delivery method will reset the shopping cart!*/}
                    {/*        </CTypography>*/}
                    {/*    </CBox>*/}
                    {/*}*/}
                    <DeliveryMethodSelector
                        isOpen={isOpen}
                        deliveryEnabled={locationInfo?.locationSetting?.locationSettingDeliveryEnabled === 1}
                        onSelect={() => {
                            setIsDeliveryMethodDialogOpen(false)
                            onSelect()
                        }}
                        onClose={() => {
                            setIsDeliveryMethodDialogOpen(false)
                            onClose()
                        }}
                    />


                </CDialogContent>

            </CDialog>
        </>
    )
})

interface LoginDialogProps {
    isOpen: boolean,
    onLogin: () => void,
    onForgotPassword: () => void,
    onClose: () => void
}

export const LoginDialog = observer(
    function LoginDialog({
                             isOpen,
                             onLogin,
                             onForgotPassword,
                             onClose
                         }: LoginDialogProps) {

        const {authStore} = useStores();

        const [showPassword, setShowPassword] = React.useState(false);
        const [username, setUsername] = React.useState("");
        const [password, setPassword] = React.useState("");
        const [isLoading, setIsLoading] = React.useState(false);
        const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

        const guestAuth = useAppInit()
        const {locationInfo} = guestAuth

        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        };

        const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
        };

        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        };

        const handleLoginClick = async () => {
            setErrorMessage(null)
            if (!hasValue(username) && !hasValue(password)) {
                setErrorMessage('Username and password are empty.')
                return
            } else if (!hasValue(username)) {
                setErrorMessage('Username is empty.')
                return

            } else if (!hasValue(password)) {
                setErrorMessage('Password is empty.')
                return
            }
            setIsLoading(true)
            const result = await authStore.customerLogin({
                username,
                password,
                locationId: locationInfo.locationId
            }, currentDomain())

            if (result.kind == "ok") {
                onLogin()
            } else {
                setErrorMessage('Wrong Username or Password.')
            }
            setIsLoading(false)
        }

        const handleForgotPasswordClick = () => {
            onForgotPassword()
        }

        return (
            <CDialog maxWidth={'xs'} open={isOpen} fullWidth PaperProps={{
                sx: {borderRadius: "37px"}
            }} onClose={() => onClose()}>
                <CDialogTitle>
                    <CGridContainer spacing={2}>
                        <CGrid item xs={12}>
                            <POSTitle sx={{
                                // mb: 4,
                                textAlign: "center",
                                fontFamily: "Passion One"
                            }}>
                                Login
                            </POSTitle>
                        </CGrid>
                    </CGridContainer>
                </CDialogTitle>
                <Divider></Divider>
                <CDialogContent>
                    <CGridContainer spacing={2}>
                        <CGrid item xs={12}>
                            {/*<CTextField fullWidth sx={{mb: 2}} label={"Username"} value={username}*/}
                            <CTextField fullWidth sx={{mb: 2}} label={"Email"} value={username}
                                        onChange={handleUsernameChange}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12}>
                            <CTextField
                                fullWidth
                                // sx={{mb: 4}}
                                label={"Password"}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: <CInputAdornment position="end">
                                        <CIconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </CIconButton>
                                    </CInputAdornment>
                                }}
                            >

                            </CTextField>
                        </CGrid>

                    </CGridContainer>
                </CDialogContent>
                <Divider></Divider>
                <CDialogActions>
                    <CGridContainer spacing={2} my={1} mx={1}>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"outlined"} onClick={() => {
                                onClose()
                            }}>
                                Close
                            </CButton>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"contained"} onClick={() => {
                                handleLoginClick()
                            }}>
                                {isLoading ? <CCircularProgress sx={{color: "white"}} size={24}/> : "Login"}
                            </CButton>

                        </CGrid>
                        <CGrid item xs={6}>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"text"} onClick={() => {
                                handleForgotPasswordClick()
                            }}>
                                Forgot Password?
                            </CButton>

                        </CGrid>
                        {hasValue(errorMessage) && (
                            <CGrid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <CTypography
                                    textAlign={'center'}
                                    mt={2}
                                    variant={'body1'}
                                    color={'red'}>
                                    {errorMessage}
                                </CTypography></CGrid>)}
                    </CGridContainer>
                </CDialogActions>
            </CDialog>
        )
    }
)

interface ForgotPasswordDialogProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: () => void
}

export const ForgotPasswordDialog = observer(
    function ForgotPasswordDialog({
                                      isOpen,
                                      onClose,
                                      onSubmit,
                                  }: ForgotPasswordDialogProps) {

        const {userStore, resetPasswordStore} = useStores()

        const [username, setUsername] = React.useState("");

        const guestAuth = useAppInit()

        const {guestAuthToken, locationInfo} = guestAuth

        const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
        };

        const handleResetPassword = async () => {
            // console.log('handleResetPassword', {
            //     userEmail: username,
            //     userLocationId: locationInfo.locationId
            // })
            const userResult = await userStore.findAll({
                userEmail: username,
                userLocationId: locationInfo.locationId
            }, guestAuthToken, false)
            if (userResult.kind === 'ok' && userResult.users && userResult.users.length > 0) {
                console.log(userResult.users[0])
                await resetPasswordStore.create({
                    userId: userResult.users[0].userId
                }, guestAuthToken)
            }
            onSubmit()
        }


        return (
            <CDialog maxWidth={'xs'} open={isOpen} fullWidth PaperProps={{
                sx: {borderRadius: "37px"}
            }} onClose={() => onClose()}>
                <CDialogTitle>
                    <CGridContainer spacing={2}>
                        <CGrid item xs={12}>
                            <POSTitle sx={{
                                // mb: 4,
                                textAlign: "center",
                                fontFamily: "Passion One"
                            }}>
                                Reset Password
                            </POSTitle>
                        </CGrid>
                    </CGridContainer>
                </CDialogTitle>
                <Divider></Divider>
                <CDialogContent>
                    <CGridContainer spacing={2}>
                        <CGrid item xs={12}>
                            <CTypography variant={'body1'}>
                                A password recovery email will be sent to the provided email.
                                Please follow the instructions in order to recover your password.
                            </CTypography>
                        </CGrid>
                        <CGrid item xs={12}>
                            {/*<CTextField fullWidth sx={{mb: 2}} label={"Username"} value={username}*/}
                            <CTextField fullWidth sx={{mb: 2}} label={"Email"} value={username}
                                        onChange={handleUsernameChange}>

                            </CTextField>
                        </CGrid>
                    </CGridContainer>
                </CDialogContent>
                <Divider></Divider>
                <CDialogActions>
                    <CGridContainer spacing={2} my={1} mx={1}>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"outlined"} onClick={() => {
                                onClose()
                            }}>
                                Close
                            </CButton>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"contained"} onClick={handleResetPassword}>
                                Submit
                            </CButton>

                        </CGrid>
                    </CGridContainer>
                </CDialogActions>
            </CDialog>
        )
    }
)

interface SignupDialogProps {
    isOpen: boolean,
    onSignup: () => void,
    onClose: () => void,
    onMessage: (message) => void,
}

export const SignupDialog = observer(
    function SignupDialog({
                              isOpen,
                              onSignup,
                              onClose,
                              onMessage
                          }: SignupDialogProps) {

        const {authStore, userStore, userDetailStore} = useStores();

        const [showPassword, setShowPassword] = React.useState(false);
        const [username, setUsername] = React.useState("");
        const [password, setPassword] = React.useState("");
        const [confirmPassword, setConfirmPassword] = React.useState("");
        const [firstName, setFirstName] = React.useState("");
        const [lastName, setLastName] = React.useState("");
        const [email, setEmail] = React.useState("");
        const [phone, setPhone] = React.useState("");
        // const [address1, setAddress1] = React.useState("");
        // const [address2, setAddress2] = React.useState("");
        // const [city, setCity] = React.useState("");
        // const [state, setState] = React.useState("CA");
        // const [zip, setZip] = React.useState("");
        const [validation, setValidation] = React.useState({
            username: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            // address1: '',
            // city: '',
            // state: '',
            // zip: '',
        })
        // const [searchResult, setSearchResult] = useState<any>(null)
        // const [currentIsMapLoaded, setCurrentIsMapLoaded] = useState(false)

        useEffect(() => {
            if (isOpen) {
                setValidation({
                    username: '',
                    password: '',
                    confirmPassword: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    // address1: '',
                    // city: '',
                    // state: '',
                    // zip: '',
                })
                setShowPassword(false)
                setUsername('')
                setFirstName('')
                setLastName('')
                setEmail('')
                setPhone('')
                setPassword('')
                setConfirmPassword('')
                // setAddress1('')
                // setAddress2('')
                // setCity('')
                // setZip('')
            }

            // setTimeout(() => {
            //     setCurrentIsMapLoaded(isMapLoaded)
            // }, 150)

        }, [isOpen])

        const [isLoading, setIsLoading] = React.useState(false);

        const guestAuth = useAppInit()
        const {locationInfo, guestAuthToken} = guestAuth

        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        };

        // const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setUsername(event.target.value);
        //     if (hasValue(validation.username)) {
        //         setValidation({...validation, username: ''})
        //     }
        // };

        const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setFirstName(event.target.value);
            if (hasValue(validation.firstName)) {
                setValidation({...validation, firstName: ''})
            }
        };

        const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setLastName(event.target.value);
            if (hasValue(validation.lastName)) {
                setValidation({...validation, lastName: ''})
            }
        };

        const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPhone(event.target.value);
            if (hasValue(validation.phone)) {
                setValidation({...validation, phone: ''})
            }
        };

        const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setUsername(event.target.value)
            if (hasValue(validation.email)) {
                setValidation({...validation, email: ''})
            }
            if (hasValue(validation.username)) {
                setValidation({...validation, username: ''})
            }
        };
        //
        // const handleAddress1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setAddress1(event.target.value);
        // };
        //
        // const handleAddress2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setAddress2(event.target.value);
        // };
        //
        // const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setCity(event.target.value);
        // };
        //
        // const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setState(event.target.value);
        // };
        //
        // const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setZip(event.target.value);
        // };

        const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
            if (hasValue(validation.password)) {
                setValidation({...validation, password: ''})
            }
        };

        const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value);
            if (hasValue(validation.confirmPassword)) {
                setValidation({...validation, confirmPassword: ''})
            }
        };

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(username)) {
                validationObject.username = "This field is required."
                isValid = false
            }
            if (isEmpty(password)) {
                validationObject.password = "This field is required."
                isValid = false
            }
            if (isEmpty(confirmPassword)) {
                validationObject.confirmPassword = "This field is required."
                isValid = false
            }
            if (password && confirmPassword && password !== confirmPassword) {
                validationObject.password = "Password fields don't match."
                validationObject.confirmPassword = "Password fields don't match."
                isValid = false
            } else {
                if (password.length < 8) {
                    validationObject.password = "Password must have more than 8 characters."
                    validationObject.confirmPassword = "Password must have more than 8 characters."
                    isValid = false
                }
            }
            if (isEmpty(firstName)) {
                validationObject.firstName = "This field is required."
                isValid = false
            }
            if (isEmpty(lastName)) {
                validationObject.lastName = "This field is required."
                isValid = false
            }
            if (isEmpty(phone)) {
                validationObject.phone = "This field is required."
                isValid = false
            }
            if (isEmpty(email)) {
                validationObject.email = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const handleSignUpClick = async () => {
            if (!validate()) {
                return
            }
            if (password !== confirmPassword) {
                return
            }
            setIsLoading(true)
            const userStoreResult = await userStore.create({
                userUsername: username,
                userPassword: password,
                userFirstName: firstName,
                userLastName: lastName,
                userEmail: email,
                userPhone: phone,
                userNote: "",
                roleId: 4,
                userLocationId: locationInfo?.locationId,
                userStatus: "active",
            }, guestAuthToken)

            if (userStoreResult.kind === 'wrong_username') {
                onMessage('A user with the same email already exists.')
                setIsLoading(false)
                return
            }
            const result = await authStore.customerLogin({
                username,
                password,
                locationId: locationInfo.locationId
            }, currentDomain())

            if (result.kind == "ok") {
                await userDetailStore.create({
                    userId: userStoreResult.user.identifiers[0].userId,
                    userDetailFirstName: firstName,
                    userDetailLastName: lastName,
                    userDetailEmail: email,
                    userDetailPhone: phone,
                    // userDetailAddress1: address1,
                    // userDetailAddress2: address2,
                    // userDetailCity: city,
                    // userDetailState: 'CA',
                    // userDetailZip: zip,
                }, String(result.auth?.accessToken))

                await authStore.customerLogin({
                    username,
                    password,
                    locationId: locationInfo.locationId
                }, currentDomain())

                onSignup()
            }
            setIsLoading(false)
        }

        // function onAutocompleteLoad(autocomplete) {
        //     setSearchResult(autocomplete)
        // }
        //
        // function onPlaceChanged() {
        //     //variable to store the result
        //     const place = searchResult?.getPlace();
        //     // //variable to store the name from place details result
        //     // const name = place.name;
        //     // //variable to store the status from place details result
        //     // const status = place.business_status;
        //     //variable to store the formatted address from place details result
        //     const formattedAddress = place.formatted_address;
        //     // const latLong = place?.geometry?.location;
        //
        //     //console log all results
        //     // props.onPlaceSelected(formattedAddress, latLong);
        //
        //     if (hasValue(place.address_components)) {
        //         place.address_components.forEach(component => {
        //             if (component.types.includes('route')) {
        //                 setAddress1(component.long_name)
        //             }
        //             if (component.types.includes('locality')) {
        //                 setCity(component.short_name)
        //             }
        //             if (component.types.includes('administrative_area_level_1')) {
        //                 setState(component.short_name)
        //             }
        //             if (component.types.includes('postal_code')) {
        //                 setZip(component.long_name)
        //             }
        //         })
        //     }
        //
        // }

        return (
            <CDialog open={isOpen} fullWidth PaperProps={{
                sx: {borderRadius: "37px"}
            }} onClose={() => onClose()}>
                <CDialogTitle>
                    <CGridContainer>
                        <CGrid item xs={12}>
                            <POSTitle sx={{
                                // mb: 4,
                                textAlign: "center",
                                fontFamily: "Passion One"
                            }}>
                                Sign Up
                            </POSTitle>
                        </CGrid>
                    </CGridContainer>
                </CDialogTitle>
                <Divider></Divider>
                <CDialogContent sx={{overflowY: 'scroll'}}>

                    <CGridContainer spacing={2}>

                        {/*<CGrid item xs={12}>*/}
                        {/*    <CTextField*/}
                        {/*        error={hasValue(validation.username)}*/}
                        {/*        helperText={hasValue(validation.username) ? validation.username : null}*/}
                        {/*        fullWidth label={"Username"} value={username} onChange={handleUsernameChange}>*/}

                        {/*    </CTextField>*/}
                        {/*</CGrid>*/}
                        <CGrid item xs={12} md={6}>
                            <CTextField
                                error={hasValue(validation.email)}
                                helperText={hasValue(validation.email) ? validation.email : null}

                                fullWidth label={"Email"} value={email} onChange={handleEmailChange}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={6}>
                            <CTextField
                                error={hasValue(validation.phone)}
                                helperText={hasValue(validation.phone) ? validation.phone : null}
                                fullWidth label={"Phone"} value={phone} onChange={handlePhoneChange}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={6}>
                            <CTextField
                                error={hasValue(validation.firstName)}
                                helperText={hasValue(validation.firstName) ? validation.firstName : null}
                                fullWidth label={"First Name"} value={firstName} onChange={handleFirstNameChange}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={6}>
                            <CTextField
                                error={hasValue(validation.lastName)}
                                helperText={hasValue(validation.lastName) ? validation.lastName : null}

                                fullWidth label={"Last Name"} value={lastName} onChange={handleLastNameChange}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={6}>
                            <CTextField
                                fullWidth
                                // sx={{mb: 4}}
                                error={hasValue(validation.password)}
                                helperText={hasValue(validation.password) ? validation.password : null}
                                label={"Password"}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: <CInputAdornment position="end">
                                        <CIconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </CIconButton>
                                    </CInputAdornment>
                                }}
                            >

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12} md={6}>
                            <CTextField
                                fullWidth
                                label={"Confirm Password"}
                                error={hasValue(validation.confirmPassword)}
                                helperText={hasValue(validation.confirmPassword) ? validation.confirmPassword : null}
                                onChange={handleConfirmPasswordChange}
                                InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: <CInputAdornment position="end">
                                        <CIconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </CIconButton>
                                    </CInputAdornment>
                                }}
                            >

                            </CTextField>
                        </CGrid>

                        {/*
                        <CGrid item xs={12}>
                            <CBox sx={{width: '100%', my: 1}}>
                                {currentIsMapLoaded && <Autocomplete
                                    onLoad={onAutocompleteLoad}
                                    options={{types: ['address']}}
                                    fields={['formatted_address', 'address_component']}
                                    onPlaceChanged={onPlaceChanged}
                                    className={'google-map-ac'}
                                >
                                    <TextField
                                        sx={{flex: 1}}
                                        // size="small"
                                        placeholder={'11111 Address, City, State'}
                                        error={
                                            hasValue(validation.address1) ||
                                            hasValue(validation.city) ||
                                            hasValue(validation.state) ||
                                            hasValue(validation.zip)
                                        }
                                        helperText={hasValue(validation.address1) ||
                                        hasValue(validation.city) ||
                                        hasValue(validation.state) ||
                                        hasValue(validation.zip) ? `Please enter a valid address. Make sure the zip code is correct.` : null}
                                        label="Full-Address"
                                        onChange={(event) => {
                                            // cartStore.saveCart({
                                            //     ...cartStore.cart,
                                            //     cartDeliveryFullAddress: event.target.value
                                            // })
                                            if (hasValue(validation.address1)) {
                                                setValidation({...validation, address1: ''})
                                            }
                                            if (hasValue(validation.city)) {
                                                setValidation({...validation, city: ''})
                                            }
                                            if (hasValue(validation.state)) {
                                                setValidation({...validation, state: ''})
                                            }
                                            if (hasValue(validation.zip)) {
                                                setValidation({...validation, zip: ''})
                                            }
                                        }}
                                        // value={cartStore.cart?.cartDeliveryFullAddress}
                                    />
                                </Autocomplete>}
                            </CBox>
                        </CGrid>
                        */}

                        {/*

                        <CGrid item xs={12}>
                            <CTextField fullWidth label={"Address1"} value={address1} onChange={handleAddress1Change}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={12}>
                            <CTextField fullWidth label={"Address2"} value={address2} onChange={handleAddress2Change}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={6} md={4}>
                            <CTextField fullWidth label={"City"} value={city} onChange={handleCityChange}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={6} md={4}>
                            <CTextField fullWidth disabled label={"State"} value={state}>

                            </CTextField>
                        </CGrid>
                        <CGrid item xs={6} md={4}>
                            <CTextField fullWidth label={"Zip"} value={zip} onChange={handleZipChange}>

                            </CTextField>
                        </CGrid>
                        */}


                    </CGridContainer>

                </CDialogContent>
                <Divider></Divider>
                <CDialogActions>
                    <CGridContainer spacing={2} my={1} mx={1}>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"outlined"} onClick={() => {
                                onClose()
                            }}>
                                Close
                            </CButton>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"contained"} onClick={() => {
                                handleSignUpClick()
                            }}>
                                {isLoading ? <CCircularProgress sx={{color: "white"}} size={24}/> : "Sign Up"}
                            </CButton>
                        </CGrid>
                    </CGridContainer>
                </CDialogActions>
            </CDialog>
        )
    }
)

interface CustomerDialogProps {
    isOpen: boolean,
    onClose: () => void,
    onSave?: () => void,
    id: number;
    customerType: string;
    authToken: string;
    module: string;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CustomerDialog = observer(
    function CustomerDialog({
                                isOpen,
                                onClose,
        onSave,
                                id,
                                customerType,
                                authToken,
                                module
                            }: CustomerDialogProps) {

        const {orderStore, customerStore, userStore, userDetailStore} = useStores();

        const [isLoading, setIsLoading] = React.useState(true);
        const [tabValue, setTabValue] = React.useState(0);
        const [details, setDetails] = React.useState<{
            firstName: string;
            lastName: string;
            userName?: string;
            email: string;
            phone: string;
            address1: string;
            address2: string;
            city: string;
            state: string;
            zip: string;
            note: string;
            type: string;
            store: string;
            createAt: string;
            detailId?: number;
            flag?: string | null;
        } | null>(null);
        const [orders, setOrders] = React.useState<Order[]>([]);
        const [statistics, setStatistics] = React.useState({
            total: 0,
            pending: 0
        });
        const [isSaving, setIsSaving] = React.useState(false);

        const getCustomerStatistics = async (customerEmail: string, locationId?: number) => {
            const result = await orderStore.customerStatistics({customerEmail, locationId}, authToken)
            if (result.kind === 'ok') {
                setStatistics(result.statistics)
            }
        }

        const getCustomerOrders = async (customerEmail: string, locationId?: number) => {
            const result = await orderStore.findAll({
                email: customerEmail,
                kioskId: locationId,
                sortBy: 'orderCreatedAt',
                sortDirection: 'DESC'
            }, authToken, false)
            if (result.kind === 'ok') {
                setOrders(result.orders)
            }
        }

        const handleChange = (event: React.SyntheticEvent, newValue: number) => {
            setTabValue(newValue);
        };

        const handleSaveChanges = async () => {
            setIsSaving(true)
            if (customerType === 'registered') {
                await userStore.update({
                    userId: id,
                    userFirstName: details?.firstName,
                    userLastName: details?.lastName,
                    userNote: details?.note,
                    userPhone: details?.phone,
                    userLocationNote: details?.note,
                    userFlag: details?.flag,
                }, authToken)
                await userDetailStore.update({
                    userDetailId: details?.detailId,
                    userDetailAddress1: details?.address1,
                    userDetailAddress2: details?.address2,
                    userDetailCity: details?.city,
                    userDetailState: details?.state,
                    userDetailZip: details?.zip,
                    userDetailPhone: details?.email,
                }, authToken)
            } else {
                await customerStore.update({
                    customerId: id,
                    customerFname: details?.firstName,
                    customerLname: details?.lastName,
                    customerPhone: details?.phone,
                    customerLocationNote: details?.note,
                    customerAddress1: details?.address1,
                    customerAddress2: details?.address2,
                    customerCity: details?.city,
                    customerState: details?.state,
                    customerZip: Number(details?.zip),
                    customerFlag: details?.flag
                }, authToken)
            }
            setIsSaving(false)
            onClose()
            if (onSave) {
                onSave()
            }
        }

        useEffect(() => {
            ;(async () => {
                if (isOpen) {
                    setIsLoading(true)
                    if (customerType === 'registered') {
                        const result = await userStore.findOne({
                            userId: id
                        }, authToken);
                        if (result.kind === "ok") {
                            const user = result.user;
                            setDetails({
                                firstName: user.userFirstName,
                                lastName: user.userLastName,
                                userName: user.userUsername,
                                email: user.userDetail.userDetailEmail,
                                phone: user.userDetail.userDetailPhone,
                                address1: user.userDetail.userDetailAddress1,
                                address2: user.userDetail.userDetailAddress2,
                                city: user.userDetail.userDetailCity,
                                state: user.userDetail.userDetailState,
                                zip: user.userDetail.userDetailZip,
                                detailId: user.userDetail.userDetailId,
                                note: user.userLocationNote,
                                type: customerType,
                                store: user.location.locationName,
                                createAt: user.userCreatedAt,
                                flag: user.userFlag
                            })
                            await getCustomerStatistics(user.userDetail.userDetailEmail, user.location.locationId)
                            await getCustomerOrders(user.userDetail.userDetailEmail, user.location.locationId)
                        }
                    } else {
                        const result = await customerStore.findOne({
                            customerId: id
                        }, authToken);
                        if (result.kind === "ok") {
                            const customer = result.customer;
                            setDetails({
                                firstName: customer.customerFname,
                                lastName: customer.customerLname,
                                email: customer.customerEmail,
                                phone: customer.customerPhone,
                                address1: customer.customerAddress1,
                                address2: customer.customerAddress2,
                                city: customer.customerCity,
                                state: customer.customerState,
                                zip: customer.customerZip,
                                note: customer.customerLocationNote,
                                type: customerType,
                                store: customer.location.locationName,
                                createAt: customer.customerCreatedAt,
                                flag: customer.customerFlag
                            })
                            await getCustomerStatistics(customer.customerEmail, customer.location.locationId)
                            await getCustomerOrders(customer.customerEmail, customer.location.locationId)
                        }
                    }
                    setIsLoading(false)
                }
            })()

        }, [isOpen])


        if (!id || !customerType) {
            return <></>
        }

        return (
            <CDialog open={isOpen} fullWidth PaperProps={{
                sx: {borderRadius: "37px"}
            }} onClose={() => onClose()}>
                <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                    <CGridContainer spacing={2}>
                        <CGrid item xs={12} md={5} display={'flex'} alignItems={'center'}>
                            <CTypography fontSize={"24px"} mx={4}>
                                Customer Details
                            </CTypography>
                        </CGrid>
                        <CGrid item xs={12} md={7}>
                            <CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                <CTab label="General" {...a11yProps(0)} />
                                <CTab label="Orders" {...a11yProps(1)} />
                                <CTab label="Statistics" {...a11yProps(2)} />
                            </CTabs>
                        </CGrid>
                    </CGridContainer>
                </CBox>
                <Divider></Divider>
                {(isLoading || !details) ? (
                        <CDialogContent>
                            <CCircularProgress/>
                        </CDialogContent>) :
                    (<CDialogContent sx={{overflowY: 'scroll'}}>

                        <TabPanel value={tabValue} index={0}>
                            <CGridContainer spacing={2} p={2}>
                                {customerType === 'registered' && <CGrid item xs={12} md={6}>
                                    <CTextField
                                        fullWidth
                                        disabled
                                        label={"User Name"}
                                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        //     setDetails({...details, userName: event.target.value})
                                        //
                                        // }}
                                        value={details.userName}/>
                                </CGrid>}
                                <CGrid item xs={12} md={6}>
                                    <CTextField
                                        fullWidth
                                        disabled={module === 'lockbox'}
                                        label={"FirstName"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setDetails({...details, firstName: event.target.value})

                                        }}
                                        value={details.firstName}/>
                                </CGrid>
                                <CGrid item xs={12} md={6}>
                                    <CTextField
                                        fullWidth
                                        label={"Last Name"}
                                        disabled={module === 'lockbox'}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setDetails({...details, lastName: event.target.value})

                                        }}
                                        value={details.lastName}/>
                                </CGrid>
                                <CGrid item xs={12} md={6}>
                                    <CTextField
                                        fullWidth
                                        label={"Email"}
                                        disabled
                                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        //     setDetails({...details, email: event.target.value})
                                        //
                                        // }}
                                        value={details.email}/>
                                </CGrid>
                                <CGrid item xs={12} md={6}>
                                    <CTextField
                                        fullWidth
                                        label={"Phone"}
                                        disabled={module === 'lockbox'}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setDetails({...details, phone: event.target.value})

                                        }}
                                        value={details.phone}/>
                                </CGrid>
                                {/*<CGrid item xs={12} md={6}>*/}
                                {/*    <CTextField*/}
                                {/*        fullWidth*/}
                                {/*        label={"Address 1"}*/}
                                {/*        disabled={module === 'lockbox'}*/}
                                {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*            setDetails({...details, address1: event.target.value})*/}

                                {/*        }}*/}
                                {/*        value={details.address1}/>*/}
                                {/*</CGrid>*/}
                                {/*<CGrid item xs={12} md={6}>*/}
                                {/*    <CTextField*/}
                                {/*        fullWidth*/}
                                {/*        label={"Address 2"}*/}
                                {/*        disabled={module === 'lockbox'}*/}
                                {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*            setDetails({...details, address2: event.target.value})*/}

                                {/*        }}*/}
                                {/*        value={details.address2}/>*/}
                                {/*</CGrid>*/}
                                {/*<CGrid item xs={12} md={3}>*/}
                                {/*    <CTextField*/}
                                {/*        fullWidth*/}
                                {/*        label={"City"}*/}
                                {/*        disabled={module === 'lockbox'}*/}
                                {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*            setDetails({...details, city: event.target.value})*/}

                                {/*        }}*/}
                                {/*        value={details.city}/>*/}
                                {/*</CGrid>*/}
                                {/*<CGrid item xs={12} md={3}>*/}
                                {/*    <CTextField*/}
                                {/*        fullWidth*/}
                                {/*        label={"State"}*/}
                                {/*        disabled={module === 'lockbox'}*/}
                                {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*            setDetails({...details, state: event.target.value})*/}

                                {/*        }}*/}
                                {/*        value={details.state}/>*/}
                                {/*</CGrid>*/}
                                {/*<CGrid item xs={12} md={3}>*/}
                                {/*    <CTextField*/}
                                {/*        fullWidth*/}
                                {/*        label={"Zip"}*/}
                                {/*        disabled={module === 'lockbox'}*/}
                                {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                                {/*            setDetails({...details, zip: event.target.value})*/}

                                {/*        }}*/}
                                {/*        value={details.zip}/>*/}
                                {/*</CGrid>*/}
                                <CGrid item xs={12} md={3}>
                                    <CTextField
                                        fullWidth
                                        disabled
                                        label={"Type"}
                                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        //     setDetails({...details, note: event.target.value})
                                        //
                                        // }}
                                        value={details.type}/>
                                </CGrid>
                                <CGrid item xs={12} md={3}>
                                    <CTextField
                                        fullWidth
                                        disabled
                                        label={"Store"}
                                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        //     setDetails({...details, note: event.target.value})
                                        //
                                        // }}
                                        value={details.store}/>
                                </CGrid>
                                <CGrid item xs={12} md={3}>
                                    <CTextField
                                        fullWidth
                                        disabled
                                        label={"Created At"}
                                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        //     setDetails({...details, note: event.target.value})
                                        //
                                        // }}
                                        value={dayjs(details.createAt).format('MMM D, h:mm A')}/>
                                </CGrid>
                                <CGrid item xs={12} md={12}>
                                    <CTextField
                                        fullWidth
                                        select
                                        label={"Flag"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (event.target.value?.length > 0) {
                                                setDetails({...details, flag: event.target.value})
                                            } else {
                                                setDetails({...details, flag: null})
                                            }

                                        }}
                                        value={details.flag}>
                                        <CMenuItem
                                            value={''}>None</CMenuItem>
                                        <CMenuItem
                                            style={{color: '#B71C1C'}}
                                            value={'red'}>Red</CMenuItem>
                                        <CMenuItem
                                            style={{color: '#FFC300'}}
                                            value={'orange'}>Yellow</CMenuItem>
                                        <CMenuItem
                                            style={{color: '#1B5E20'}}
                                            value={'green'}>Green</CMenuItem>
                                        <CMenuItem
                                            style={{color: '#0091EA'}}
                                            value={'blue'}>Blue</CMenuItem>
                                    </CTextField>
                                </CGrid>
                                <CGrid item xs={12}>
                                    <CTextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label={"Store Note"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setDetails({...details, note: event.target.value})

                                        }}
                                        value={details.note}/>
                                </CGrid>
                            </CGridContainer>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <CGridContainer spacing={2} p={2}>
                                {orders.map((order: Order) => (
                                    <CGrid xs={12}>
                                        <Accordion variant={"outlined"} sx={{width: '100%', mt: 1, mb: 1, backgroundColor: hasValue(order.orderFlag) ? order.orderFlag : 'transparent'}}>
                                            <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1a-content"
                                                              id="panel1a-header">
                                                <CGridContainer>
                                                    <CGrid item xs={12} md={4} display={'flex'}>
                                                        <Typography mr={1}>#</Typography><Typography
                                                        mr={2}>{order.orderNo}</Typography>
                                                    </CGrid>
                                                    <CGrid item xs={12} md={4} display={'flex'}>
                                                        <Typography
                                                            fontWeight={'bold'}
                                                            mr={2}>{order.orderStatus}</Typography>
                                                        {hasValue(order.orderFlag) && <Typography
                                                            fontWeight={'bold'}
                                                            mr={2}>(Refunded)</Typography>}
                                                    </CGrid>

                                                    {order.orderCreatedAt &&
                                                        <CGrid item xs={12} md={4} display={'flex'}>
                                                            <Typography fontWeight={'bold'}
                                                                        mr={2}>{`${convertToCurrentTimezone(order.orderCreatedAt, 'MMM D, h:mm A')}`}</Typography>
                                                        </CGrid>}
                                                </CGridContainer>


                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {hasValue(order.orderBillingAddress) && <>
                                                    <Typography
                                                        my={1}>Billing Address: {order.orderBillingAddress}</Typography>
                                                    <Divider></Divider>
                                                </>}
                                                {hasValue(order.localDeliveryAddress) && <>
                                                    <Typography
                                                        my={1}>Delivery Address: {order.localDeliveryAddress}</Typography>
                                                    <Divider></Divider>
                                                </>}
                                                {order.orderDetails.map((orderDetail: OrderDetail) => (
                                                    <>
                                                        <Typography
                                                            my={1}>{orderDetail.skuQuantity} X {orderDetail?.sku?.skuTitle} | {orderDetail?.sku?.skuId} (${orderDetail.skuPrice})</Typography>
                                                        <Divider></Divider>
                                                    </>
                                                ))}
                                                <CGrid item xs={12} md={12} display={'flex'}>
                                                    <Typography
                                                        mr={1}>Total:</Typography><Typography
                                                    fontWeight={'bold'}>${order.orderTotal}</Typography>
                                                    <Typography
                                                        ml={4}
                                                        mr={1}>Card No:</Typography><Typography
                                                    fontWeight={'bold'}>**** **** **** {order.orderCardNo}</Typography>
                                                </CGrid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CGrid>

                                ))}
                            </CGridContainer>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <CGridContainer spacing={2} p={2}>
                                <CGrid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                                    <CTypography variant={'h6'} mr={2}>
                                        Total Orders:
                                    </CTypography>
                                    <CTypography>
                                        {statistics.total}
                                    </CTypography>
                                </CGrid>
                                <CGrid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                                    <CTypography variant={'h6'} mr={2}>
                                        Pending Orders:
                                    </CTypography>
                                    <CTypography>
                                        {statistics.pending}
                                    </CTypography>
                                </CGrid>
                            </CGridContainer>
                        </TabPanel>
                    </CDialogContent>)}
                <Divider></Divider>
                <CDialogActions>
                    <CGridContainer spacing={2} my={1} mx={1}>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"outlined"} onClick={() => {
                                onClose()
                            }}>
                                Close
                            </CButton>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CButton fullWidth variant={"contained"} onClick={handleSaveChanges}>
                                {isSaving ? <CCircularProgress sx={{color: "white"}}
                                                               size={24}></CCircularProgress> : 'Save Notes'}
                            </CButton>

                        </CGrid>
                    </CGridContainer>
                </CDialogActions>
            </CDialog>
        )
    }
)