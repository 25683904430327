import {CBox} from ".."
import * as React from "react";

export const Divider = () => {

    return <CBox sx={{
        width: "100%",
        height: "1px",
        backgroundColor: "#E8E8E8",
        p: 0,
        m: 0
    }}/>
}