import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CDrawer,
    CList,
    CListItemButton,
    CListItemIcon,
    PanelDrawerItem
} from "../components";
import {Navigate, Route, Routes, useLocation, useMatch, useNavigate} from "react-router-dom";
import {
    Business,
    Category,
    ChevronLeft,
    ChevronRight,
    Inventory,
    LocationOn,
    LogoutOutlined, Pages,
    Payment, Payments,
    Person, ReceiptLong,
    Sell, Settings,
    ShoppingCart, Summarize, VapingRooms, Wallet, Warehouse
} from "@mui/icons-material";
import {CSSObject, Theme, useTheme} from "@mui/material";
import {
    AdminPanelCategories,
    AdminPanelInventory,
    AdminPanelOrders,
    AdminPanelPayments,
    AdminPanelSKU,
    AdminPanelUsers,
    AdminPanelVendors,
    AdminPanelStores,
    AdminPanelLockboxes,
    AdminPanelCustomers
} from "../screens";
import {AdminPanelConfigs} from "../screens/admin-panel/admin-panel-configs/admin-panel-configs-screen";
import {AdminPanelPages} from "../screens/admin-panel/admin-panel-pages/admin-panel-pages-screen";
import {AdminPanelGateways} from "../screens/admin-panel/admin-panel-gateways/admin-panel-gateways-screen";
import {useStores} from "../models";
import {AdminPanelSummary} from "../screens/admin-panel/admin-panel-summary/admin-panel-summary-screen";
import {AdminPanelCogs} from "../screens/admin-panel/admin-panel-cogs/admin-panel-cogs-screen";

export const AdminPanelNavigator = observer(
    function PanelNavigator() {

        const [open, setOpen] = React.useState(true);

        const {authStore} = useStores()
        // const {logout} = authStore

        const match = useMatch("/admin-panel/*");
        // console.log("match", match)
        const url = match?.pathnameBase
        const routeMatch = useMatch("*");
        const routeUrl = routeMatch?.pathnameBase
        const theme = useTheme();
        const navigate = useNavigate();
        const location = useLocation();


        // let isDashboardOpen = location.pathname.includes("panel/dashboard");
        // let isClaimsPageOpen = location.pathname.includes("panel/claims");
        // let isPaymentsPageOpen = location.pathname.includes("panel/payments");

        const openedMixin = (theme: Theme): CSSObject => ({
            width: 260,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.down('sm')]: {
                width: window.innerWidth - 24
            },
        });

        const closedMixin = (theme: Theme): CSSObject => ({
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: `calc(${theme.spacing(7)} + 3px)`,
            [theme.breakpoints.up('sm')]: {
                width: `calc(${theme.spacing(8)} + 3px)`,
            },
        });

        const mainOpenedMixin = (theme: Theme): CSSObject => ({
            width: window.innerWidth - 260,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        });

        const mainClosedMixin = (theme: Theme): CSSObject => ({
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: window.innerWidth - 80,
            [theme.breakpoints.up('sm')]: {
                width: window.innerWidth - 80,
            },
        });


        return (
            <>
                <CBox style={{minHeight: '100vh', display: 'flex'}}>
                    <CDrawer variant="permanent"

                             sx={{
                                 ...(open && {
                                     ...openedMixin(theme)
                                 }),
                                 ...(!open && {
                                     ...closedMixin(theme)
                                 }),
                                 mr: 2,
                                 mb: 2
                             }}
                             PaperProps={{
                                 sx: {
                                     margin: '12px',
                                     boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                     borderRadius: '37px',
                                     borderWidth: 0,
                                     height: "auto",
                                     maxHeight: '100vh',
                                     ...(open && {
                                         ...openedMixin(theme)
                                     }),
                                     ...(!open && {
                                         ...closedMixin(theme)
                                     }),
                                 }
                             }}>
                        <CList>
                            <CListItemButton
                                key={"Chev"}
                                onClick={() => setOpen(!open)}
                                sx={{
                                    m: 1,
                                    borderRadius: 4,
                                }}
                            >
                                <CListItemIcon sx={{color: theme => theme.palette.primary.light}}>
                                    {open ? <ChevronLeft/> : <ChevronRight/>}
                                </CListItemIcon>
                            </CListItemButton>
                            {/*<PanelDrawerItem*/}
                            {/*    text={"Dashboard"}*/}
                            {/*    icon={<Dashboard/>}*/}
                            {/*    onClick={() => navigate(`${url}/dashboard`)}*/}
                            {/*    selected={location.pathname === `${url}/dashboard`}*/}
                            {/*    open={open}*/}
                            {/*/>*/}
                            {/*<PanelDrawerItem*/}
                            {/*    text={"Locations"}*/}
                            {/*    icon={<LocationOn/>}*/}
                            {/*    onClick={() => navigate(`${url}/locations`)}*/}
                            {/*    selected={location.pathname === `${url}/locations`}*/}
                            {/*    open={open}*/}
                            {/*/>*/}
                            <PanelDrawerItem
                                text={"Stores"}
                                icon={<LocationOn/>}
                                onClick={() => navigate(`${url}/kiosks`)}
                                selected={location.pathname === `${url}/kiosks`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Lockboxes"}
                                icon={<LocationOn/>}
                                onClick={() => navigate(`${url}/lockboxes`)}
                                selected={location.pathname === `${url}/lockboxes`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Cogs"}
                                icon={<ReceiptLong/>}
                                onClick={() => navigate(`${url}/cogs`)}
                                selected={location.pathname === `${url}/cogs`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Users"}
                                icon={<Person/>}
                                onClick={() => navigate(`${url}/users`)}
                                selected={location.pathname === `${url}/users`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Customers"}
                                icon={<Person/>}
                                onClick={() => navigate(`${url}/customers`)}
                                selected={location.pathname === `${url}/customers`}
                                open={open}
                            />
                            {/*<PanelDrawerItem*/}
                            {/*    text={"Roles"}*/}
                            {/*    icon={<People/>}*/}
                            {/*    onClick={() => navigate(`${url}/roles`)}*/}
                            {/*    selected={location.pathname === `${url}/roles`}*/}
                            {/*    open={open}*/}
                            {/*/>*/}
                            <PanelDrawerItem
                                text={"Categories"}
                                icon={<Category/>}
                                onClick={() => navigate(`${url}/categories`)}
                                selected={location.pathname === `${url}/categories`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Vendors"}
                                icon={<Business/>}
                                onClick={() => navigate(`${url}/vendors`)}
                                selected={location.pathname === `${url}/vendors`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"SKU"}
                                icon={<VapingRooms/>}
                                onClick={() => navigate(`${url}/sku`)}
                                selected={location.pathname === `${url}/sku`}
                                open={open}
                            />
                            {/*<PanelDrawerItem*/}
                            {/*    text={"Bundles"}*/}
                            {/*    icon={<ShoppingCart/>}*/}
                            {/*    onClick={() => navigate(`${url}/bundle`)}*/}
                            {/*    selected={location.pathname === `${url}/bundle`}*/}
                            {/*    open={open}*/}
                            {/*/>*/}
                            <PanelDrawerItem
                                text={"Inventory"}
                                icon={<Warehouse/>}
                                onClick={() => navigate(`${url}/inventory`)}
                                selected={location.pathname === `${url}/inventory`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Payments"}
                                icon={<Wallet/>}
                                onClick={() => navigate(`${url}/payments`)}
                                selected={location.pathname === `${url}/payments`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Orders"}
                                icon={<ReceiptLong/>}
                                onClick={() => navigate(`${url}/orders`)}
                                selected={location.pathname === `${url}/orders`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Summary"}
                                icon={<Summarize/>}
                                onClick={() => navigate(`${url}/summary`)}
                                selected={location.pathname === `${url}/summary`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Pages"}
                                icon={<Pages/>}
                                onClick={() => navigate(`${url}/pages`)}
                                selected={location.pathname === `${url}/pages`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Gateways"}
                                icon={<Payments/>}
                                onClick={() => navigate(`${url}/gateways`)}
                                selected={location.pathname === `${url}/gateways`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Configs"}
                                icon={<Settings/>}
                                onClick={() => navigate(`${url}/configs`)}
                                selected={location.pathname === `${url}/configs`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Logout"}
                                icon={<LogoutOutlined/>}
                                onClick={() => {
                                    authStore.removeAdminAuth()
                                    navigate(`/system-login`)
                                }}
                                selected={false}
                                open={open}
                            />
                        </CList>

                    </CDrawer>
                    <CBox component={'main'} sx={{
                        ...(open && {
                            ...mainOpenedMixin(theme)
                        }),
                        ...(!open && {
                            ...mainClosedMixin(theme)
                        }),
                    }}>
                        <Routes>
                            <Route path={`${routeUrl}/`} element={<Navigate to={`${routeUrl}/kiosks`}/>}/>
                            {/*<Route path={`${routeUrl}/dashboard`} element={<AdminPanelDashboard/>}/>*/}
                            {/*<Route path={`${routeUrl}/locations`} element={<AdminPanelLocations/>}/>*/}
                            <Route path={`${routeUrl}/kiosks`} element={<AdminPanelStores/>}/>
                            <Route path={`${routeUrl}/lockboxes`} element={<AdminPanelLockboxes/>}/>
                            <Route path={`${routeUrl}/cogs`} element={<AdminPanelCogs/>}/>
                            <Route path={`${routeUrl}/users`} element={<AdminPanelUsers/>}/>
                            <Route path={`${routeUrl}/customers`} element={<AdminPanelCustomers/>}/>
                            {/*<Route path={`${routeUrl}/roles`} element={<AdminPanelRoles/>}/>*/}
                            <Route path={`${routeUrl}/sku`} element={<AdminPanelSKU/>}/>
                            {/*<Route path={`${routeUrl}/bundle`} element={<AdminPanelBundle/>}/>*/}
                            <Route path={`${routeUrl}/categories`} element={<AdminPanelCategories/>}/>
                            <Route path={`${routeUrl}/vendors`} element={<AdminPanelVendors/>}/>
                            <Route path={`${routeUrl}/inventory`} element={<AdminPanelInventory/>}/>
                            <Route path={`${routeUrl}/payments`} element={<AdminPanelPayments/>}/>
                            <Route path={`${routeUrl}/orders`} element={<AdminPanelOrders/>}/>
                            <Route path={`${routeUrl}/summary`} element={<AdminPanelSummary/>}/>
                            <Route path={`${routeUrl}/pages`} element={<AdminPanelPages/>}/>
                            <Route path={`${routeUrl}/gateways`} element={<AdminPanelGateways/>}/>
                            <Route path={`${routeUrl}/configs`} element={<AdminPanelConfigs/>}/>
                        </Routes>
                    </CBox>

                </CBox>
            </>
        );
    }
)
