import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const ConfigModel = types.model("Config").props({
    configId: types.number,
    configSlug: types.maybeNull(types.string),
    configValue: types.maybeNull(types.string),
    configCreatedAt: types.maybeNull(types.string),
    configUpdatedAt: types.maybeNull(types.string),
})

type ConfigType = Instance<typeof ConfigModel>
export interface Config extends ConfigType {}
type ConfigSnapshotType = SnapshotOut<typeof ConfigModel>
export interface ConfigSnapshot extends ConfigSnapshotType {}

export const defaultConfig = {
    configId: undefined,
    configSlug: '',
    configValue: ''
}