import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  BundleSkuDeleteParams,
  BundleSkuCreateResult,
  BundleSkuUpdateResult,
  BundleSkuUpdateParams,
  BundleSkuFindAllResult,
  BundleSkuDeleteResult,
  BundleSkuFindAllParams,
  BundleSkuFindOneParams,
  BundleSkuCreateParams,
  BundleSkuFindOneResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class BundleSkusApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: BundleSkuCreateParams): Promise<BundleSkuCreateResult> {
    try {
      // const functionName = "login"
      const uri = "bundle-skus"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundleSku = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundleSku}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: BundleSkuFindAllParams): Promise<BundleSkuFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "bundle-skus"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundleSkus = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundleSkus}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: BundleSkuFindOneParams): Promise<BundleSkuFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "bundle-skus/"+params.bundleSkuId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundleSku = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundleSku}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: BundleSkuUpdateParams): Promise<BundleSkuUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "bundle-skus/"+params.bundleSkuId
      delete params.bundleSkuId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const bundleSku = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", bundleSku}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: BundleSkuDeleteParams): Promise<BundleSkuDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "bundle-skus/"+params.bundleSkuId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
