import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {AuthApi} from "../../services/api/auth-api"
import {AuthModel, AuthSnapshot} from "../auth/auth"
import {load as loadFromStorage, save as saveToStorage, remove as removeFromStorage} from "../../utils/storage/storage"
import {logInfo} from "../../utils/logs"
import {hasValue} from "../../utils/empty-check";

const AUTH_KEY_SUFFIX = "auth"

function authStoreLog(functionName, message) {
    logInfo({
        fileName: "auth-store.ts",
        className: "authStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const AuthStoreModel = types
    .model("AuthStore")
    .props({
        auth: types.maybeNull(AuthModel),
        adminAuth: types.maybeNull(AuthModel),
        storeAdminAuth: types.maybeNull(AuthModel),
        lockboxAuth: types.maybeNull(AuthModel),
        authError: types.maybeNull(types.string),
        ageConfirmed: types.optional(types.boolean, false)
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveAuth: (authSnapshot: AuthSnapshot) => {
            try {
                if(Array.isArray(authSnapshot)) {
                    if(authSnapshot[0].role?.roleName === "Admin") {
                        self.adminAuth = cast(authSnapshot[0])
                    } else if (authSnapshot[0].role?.roleName === "Lockbox") {
                        self.lockboxAuth = cast(authSnapshot[0])
                    } else if (authSnapshot[0].role?.roleName === "Store Admin") {
                        self.storeAdminAuth = cast(authSnapshot[0])
                    } else {
                        self.auth = cast(authSnapshot[0])
                    }
                } else {
                    if(authSnapshot.role?.roleName === "Admin") {
                        self.adminAuth = cast(authSnapshot)
                    } else if (authSnapshot.role?.roleName === "Lockbox") {
                        self.lockboxAuth = cast(authSnapshot)
                    } else if (authSnapshot.role?.roleName === "Store Admin") {
                        self.storeAdminAuth = cast(authSnapshot)
                    }  else {
                        self.auth = cast(authSnapshot)
                    }
                }
                // Add environment auth on request
                // self.environment.addAuth(authSnapshot.accessToken)
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("saveAuth", e.message)
                }
                // recordError(e)
            }
        },
        saveAuthError: (error: string) => {
            try {
                self.authError = error
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("saveAuthError", e.message)
                }
                // recordError(e)
            }
        },
        saveAgeConfirmed: (value: boolean) => {
            self.ageConfirmed = value
        }
    }))
    .actions((self) => ({
        storeAuth: flow(function* (authSnapshot: AuthSnapshot, prefix: string, roleName: string) {
            try {
                yield saveToStorage(prefix+AUTH_KEY_SUFFIX+roleName, JSON.stringify(authSnapshot))
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("storeAuth", e.message)
                }
                // recordError(e)
            }
        }),
    }))
    .actions((self) => ({
        login: flow(function* (credentials) {
            const authApi = new AuthApi(self.environment.api)
            const result = yield authApi.login(credentials)
            if (result.kind === "ok") {
                self.saveAuth(result.auth)
                const prefix = result.auth.role.roleName
                yield self.storeAuth(result.auth, prefix, result.auth?.role?.roleName)
            } else {
                authStoreLog("login", result.kind)
                self.saveAuthError(result.kind)
            }
            return result
        }),
        customerLogin: flow(function* (credentials, prefix: string) {
            const authApi = new AuthApi(self.environment.api)
            const result = yield authApi.customerLogin(credentials)
            if (result.kind === "ok") {
                console.log('customerLogin', result.auth)
                self.saveAuth(result.auth)
                yield self.storeAuth(result.auth, prefix, result.auth?.role?.roleName)
            } else {
                authStoreLog("customerLogin", result.kind)
                self.saveAuthError(result.kind)
            }
            return result
        }),
    }))
    .actions((self) => ({
        loadAuth: flow(function* (prefix: string, roleName:string) {
            try {
                let storedAuth
                if (self.auth) {
                    storedAuth = self.auth
                } else {
                    storedAuth = yield loadFromStorage(prefix+AUTH_KEY_SUFFIX+roleName)
                }
                if (!storedAuth) {
                    return
                }
                // For test
                // const { refreshToken } = storedAuth
                // const expiresIn = 200
                let lastAuthTs = yield loadFromStorage("last_auth_ts")
                if (!lastAuthTs) {
                    lastAuthTs = 0
                }

                self.saveAuth(storedAuth)
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("loadAuth", e.message)
                }
                // recordError(e)
            }
        }),
        isAuthAvailable: flow(function* (prefix: string, roleName:string) {
            try {
                const storedAuth = yield loadFromStorage(prefix+AUTH_KEY_SUFFIX+roleName)
                if (hasValue(storedAuth)) {
                    let jsonStoredAuth
                     if (typeof storedAuth === "string") {
                         jsonStoredAuth  = JSON.parse(storedAuth)
                     } else {
                         jsonStoredAuth = storedAuth
                     }
                    return jsonStoredAuth.role?.roleName === "Customer"
                } else {
                    return false
                }
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("isCustomerAuthAvailable", e.message)
                }
                return false
            }
        }),
        removeAuth: flow(function* (prefix: string, roleName: string) {
            try {
                self.auth = null
                yield removeFromStorage(prefix+AUTH_KEY_SUFFIX+roleName);
                // yield removeInternetCredentials()
                // Add environment auth on request
                // self.environment.removeAuth()
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("removeAuth", e.message)
                }
                // recordError(e)
            }
        }),
        removeStoreAdminAuth: flow(function* () {
            try {
                self.storeAdminAuth = null
                yield removeFromStorage('Store Admin'+AUTH_KEY_SUFFIX+'Store Admin');
                // yield removeInternetCredentials()
                // Add environment auth on request
                // self.environment.removeAuth()
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("removeStoreAdminAuth", e.message)
                }
                // recordError(e)
            }
        }),
        removeAdminAuth: flow(function* () {
            try {
                self.adminAuth = null
                yield removeFromStorage('Admin'+AUTH_KEY_SUFFIX+'Admin');
                // yield removeInternetCredentials()
                // Add environment auth on request
                // self.environment.removeAuth()
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("removeAdminAuth", e.message)
                }
                // recordError(e)
            }
        }),
        removeLockboxAuth: flow(function* () {
            try {
                self.lockboxAuth = null
                yield removeFromStorage('Lockbox'+AUTH_KEY_SUFFIX+'Lockbox');
                // yield removeInternetCredentials()
                // Add environment auth on request
                // self.environment.removeAuth()
            } catch (e) {
                if(e instanceof Error) {
                    authStoreLog("removeLockboxAuth", e.message)
                }
                // recordError(e)
            }
        })
    }))

type AuthStoreType = Instance<typeof AuthStoreModel>

export interface AuthStore extends AuthStoreType {
}

type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>

export interface AuthStoreSnapshot extends AuthStoreSnapshotType {
}

export const createAuthStoreDefaultModel = () => types.optional(AuthStoreModel, {})
