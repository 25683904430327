import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {UserModel} from "../user/user";
import {LocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const UserLocationModel = types.model("UserLocation").props({
    userLocationId: types.identifierNumber,
    locationId: types.reference(LocationModel),
    userId: types.number,
    userLocationStatus: types.maybeNull(types.string),
    userLocationCreatedAt: types.maybeNull(types.string),
    userLocationUpdatedAt: types.maybeNull(types.string),
    userLocationDeleted: types.maybeNull(types.string),
    user: types.optional(types.maybeNull(UserModel), {userId: -1, roleId: -1}),
    // location: ,
})

type UserLocationType = Instance<typeof UserLocationModel>
export interface UserLocation extends UserLocationType {}
type UserLocationSnapshotType = SnapshotOut<typeof UserLocationModel>
export interface UserLocationSnapshot extends UserLocationSnapshotType {}

