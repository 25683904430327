import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {CustomerProfilesApi} from "../../services/api/customer-profiles-api"
import {logInfo} from "../../utils/logs"

function customerProfileStoreLog(functionName, message) {
    logInfo({
        fileName: "customer-profile-store.ts",
        className: "customerProfileStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const CustomerProfileStoreModel = types
    .model("CustomerProfileStore")
    .props({
        customerProfile: types.maybeNull(types.frozen())
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveCustomerProfile: (customerProfileSnapshot) => {
            try {
                console.log('saveCustomerProfile', customerProfileSnapshot)
                self.customerProfile = customerProfileSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    customerProfileStoreLog("saveCustomerProfile", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        authorize: flow(function* (id: number, token: string) {
            self.environment.addAuth(token)
            const customerProfileApi = new CustomerProfilesApi(self.environment.api)
            const result = yield customerProfileApi.authorize(id)


            if (result.kind === "ok") {
                self.saveCustomerProfile(result.profile)
            } else {
                customerProfileStoreLog("authorize", result.kind)
            }
            return result
        }),
        remove: flow(function* (id: number, token: string) {
            self.environment.addAuth(token)
            const customerProfileApi = new CustomerProfilesApi(self.environment.api)
            const result = yield customerProfileApi.remove(id)


            if (result.kind === "ok") {
                self.saveCustomerProfile(null)
            } else {
                customerProfileStoreLog("remove", result.kind)
            }
            return result
        }),
    }))

type CustomerProfileStoreType = Instance<typeof CustomerProfileStoreModel>

export interface CustomerProfileStore extends CustomerProfileStoreType {
}

type CustomerProfileStoreSnapshotType = SnapshotOut<typeof CustomerProfileStoreModel>

export interface CustomerProfileStoreSnapshot extends CustomerProfileStoreSnapshotType {
}

export const createCustomerProfileStoreDefaultModel = () => types.optional(CustomerProfileStoreModel, {})
