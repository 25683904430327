import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {ResetPasswordApi} from "../../services/api/reset-password-api"
import {logInfo} from "../../utils/logs"
import {ResetPasswordCreateParams, ResetPasswordResetParams} from "../../services/api";

function resetPasswordStoreLog(functionName, message) {
    logInfo({
        fileName: "resetPassword-store.ts",
        className: "resetPasswordStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const ResetPasswordStoreModel = types
    .model("ResetPasswordStore")
    .extend(withEnvironment)
    .actions((self) => ({
        create: flow(function* (params: ResetPasswordCreateParams, token: string) {
            // console.log('create', params)
            self.environment.addAuth(token)
            const resetPasswordApi = new ResetPasswordApi(self.environment.api)
            return yield resetPasswordApi.create(params)
        }),
        reset: flow(function* (params: ResetPasswordResetParams, token: string) {
            self.environment.addAuth(token)
            const resetPasswordApi = new ResetPasswordApi(self.environment.api)
            return yield resetPasswordApi.reset(params)
        }),
    }))

type ResetPasswordStoreType = Instance<typeof ResetPasswordStoreModel>

export interface ResetPasswordStore extends ResetPasswordStoreType {
}

type ResetPasswordStoreSnapshotType = SnapshotOut<typeof ResetPasswordStoreModel>

export interface ResetPasswordStoreSnapshot extends ResetPasswordStoreSnapshotType {
}

// export const createResetPasswordStoreDefaultModel = () => types.optional(ResetPasswordStoreModel, {})
