import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard, CCircularProgress,
    CDialog, CGrid,
    CGridContainer,
    CTextField,
    CTypography
} from "../../../components";
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {CogsCreateParams, CogsUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultCogs} from "../../../models/cogs/cogs";
import {isEmpty} from "validate.js";
import {useEffect} from "react";
import {Refresh} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";

interface CogsDialogData {
    isOpen: boolean,
    data: CogsCreateParams | CogsUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

export const AdminPanelCogs = observer(
    function AdminPanelCogs() {
        const navigate = useNavigate();

        const {authStore, cogsStore, skuStore} = useStores()
        const {cogses} = cogsStore

        const [cogsDialog, setCogsDialog] = React.useState<CogsDialogData>({
            isOpen: false,
            data: defaultCogs,
            mode: 'add',
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            title: '',
            cost: ''
        })

        const handleClickOpen = (mode: string, cogs?: CogsCreateParams | CogsUpdateParams) => {
            setCogsDialog({
                ...cogsDialog,
                isOpen: true,
                mode,
                data: cogs ? {...cogs} : defaultCogs
            });
        };

        const handleClose = () => {
            setCogsDialog({isOpen: false, data: defaultCogs, mode: 'add', isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setCogsDialog({
                    ...cogsDialog,
                    isLoading: true
                });

                if (cogsDialog.mode === 'add') {
                    await cogsStore.create({
                        ...cogsDialog.data
                    }, currentAuthToken)
                } else if (cogsDialog.mode === 'edit') {
                    await cogsStore.update({
                        ...cogsDialog.data
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (cogs) => {
            if (window.confirm('Are you sure you want to delete this cogs?')) {
                const cogsSkusResult = await skuStore.findAll({
                    cogsId: cogs.cogsId
                }, currentAuthToken, false)
                if (cogsSkusResult.kind === "ok") {
                    if (cogsSkusResult.skus && cogsSkusResult.skus.length > 0) {
                        window.alert("This cogs has skus. A cogs with Skus cannot be deleted.")
                    } else {
                        await cogsStore.remove({cogsId: cogs.cogsId}, currentAuthToken)
                        await refresh()
                    }
                }
            }

        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if(isEmpty(cogsDialog.data.cogsTitle)) {
                validationObject.title = "This field is required."
                isValid = false
            }
            if(isEmpty(cogsDialog.data.cogsCost)) {
                validationObject.cost = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await cogsStore.findAll({
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if(authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Cogs';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        const rows: GridRowsProp = cogses;

        const columns: GridColDef[] = [
            {
                field: 'cogsTitle',
                headerName: 'Title',
                width: 150,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },

            {
                field: 'cogsCost',
                headerName: 'Cost',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'cogsDescription',
                headerName: 'Description',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
        ];
        
        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                                <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                    Cogs
                                </CTypography>
                            <CButton variant={"contained"} onClick={()=>handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.cogsId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={cogsDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Cogs
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Name"}
                                        error={hasValue(validation.title)}
                                        helperText={hasValue(validation.title)?validation.title:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCogsDialog({
                                                ...cogsDialog,
                                                data: {...cogsDialog.data, cogsTitle: event.target.value}
                                            });
                                            if(hasValue(validation.title)) {
                                                setValidation({...validation, title: ''})
                                            }
                                        }} value={cogsDialog.data.cogsTitle}/>
                        </CGrid>
                        <CGrid item xs={6}>
                            <CTextField fullWidth label={"Cost"}
                                        error={hasValue(validation.cost)}
                                        helperText={hasValue(validation.cost)?validation.cost:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCogsDialog({
                                                ...cogsDialog,
                                                data: {...cogsDialog.data, cogsCost: event.target.value}
                                            });
                                            if(hasValue(validation.cost)) {
                                                setValidation({...validation, cost: ''})
                                            }
                                        }} value={cogsDialog.data.cogsCost}/>
                        </CGrid>
                        <CGrid item xs={12}>
                            <CTextField fullWidth multiline label={"Descriptions"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCogsDialog({
                                                ...cogsDialog,
                                                data: {...cogsDialog.data, cogsDescription: event.target.value}
                                            });
                                        }} value={cogsDialog.data.cogsDescription} />
                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {cogsDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : cogsDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
