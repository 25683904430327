import {
    CBox,
    CButton,
    CCircularProgress, CDialog,
    CGrid,
    CGridContainer,
    CMenuItem,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {isEmpty} from "validate.js";
import {useStores} from "../../models";

export const SkuDialog = (props) => {

    const {skuDialog, setSkuDialog, categories, vendors, cogses, handleClose, refresh, currentAuthToken} = props;

    const {skuStore} = useStores()

    const [validation, setValidation] = React.useState({
        title: '',
        status: '',
        defaultPrice: '',
        category: '',
        vendor: '',
        cogs: '',
    })

    const validate = () => {
        const validationObject = {...validation}
        let isValid = true
        if (isEmpty(skuDialog.data.vendorId)) {
            validationObject.vendor = "This field is required."
            isValid = false
        }
        if (isEmpty(skuDialog.data.categoryId)) {
            validationObject.category = "This field is required."
            isValid = false
        }
        if (isEmpty(skuDialog.data.skuTitle)) {
            validationObject.title = "This field is required."
            isValid = false
        }
        // if (isEmpty(skuDialog.data.skuDefaultPrice)) {
        //     validationObject.defaultPrice = "This field is required."
        //     isValid = false
        // }
        if (isEmpty(skuDialog.data.skuStatus)) {
            validationObject.status = "This field is required."
            isValid = false
        }
        if (isEmpty(skuDialog.data.cogsId)) {
            validationObject.cogs = "This field is required."
            isValid = false
        }
        setValidation(validationObject)
        return isValid;
    }

    const handleSubmit = async () => {
        try {


            if (!validate()) {
                return
            }


            setSkuDialog({
                ...skuDialog,
                isLoading: true
            });

            if (skuDialog.mode === 'add') {
                await skuStore.create({
                    ...skuDialog.data
                }, currentAuthToken)
            } else if (skuDialog.mode === 'edit') {
                const cleanedUpData = {...skuDialog.data};
                // @ts-ignore
                delete cleanedUpData.category
                // @ts-ignore
                delete cleanedUpData.vendor
                // @ts-ignore
                delete cleanedUpData.storeItemAvailabilities
                // @ts-ignore
                delete cleanedUpData.prices

                await skuStore.update({
                    ...cleanedUpData
                }, currentAuthToken)

                console.log('Sku.handleSubmit.0', cleanedUpData)
                if (skuDialog.data.skuHasParent === 0) {
                    console.log('Sku.handleSubmit.1', {
                        skuParentId: skuDialog.data.skuId,
                        page: 0,
                        perPage: 100
                    })
                    const skuVarietiesResult = await skuStore.findAllVarieties({
                        skuParentId: skuDialog.data.skuId,
                        page: 0,
                        perPage: 100
                    }, currentAuthToken)

                    if (skuVarietiesResult.kind === 'ok' && hasValue(skuVarietiesResult.skus)) {
                        for (const sku of skuVarietiesResult.skus) {
                            console.log('Sku.handleSubmit.2', {
                                skuId: sku.skuId,
                                vendorId: skuDialog.data.vendorId,
                                cogsId: skuDialog.data.cogsId,
                            })
                            await skuStore.update({
                                skuId: sku.skuId,
                                vendorId: skuDialog.data.vendorId,
                                cogsId: skuDialog.data.cogsId,
                            }, currentAuthToken)
                        }
                    }
                }
            }

            await refresh()

            handleClose()
        } catch (e) {
            console.log(e)
        }
    };



    return <CDialog open={skuDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
        sx: {
            borderRadius: "37px"
        }
    }}>
        <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
            <CTypography fontSize={"24px"} ml={4} mr={2}>
                {skuDialog.mode === 'add' ? 'Add Sku' : 'Edit Sku'}
            </CTypography>

            {/*<CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">*/}
            {/*    <CTab label="General" {...a11yProps(0)} />*/}
            {/*    <CTab label="Images" {...a11yProps(1)} />*/}
            {/*    /!*<CTab label="Prices" {...a11yProps(2)} />*!/*/}
            {/*</CTabs>*/}
        </CBox>
        <Divider></Divider>
        <CGridContainer spacing={2} p={2}>
            <CGrid item xs={4}>
                <CTextField fullWidth label={"Title"}
                            error={hasValue(validation.title)}
                            helperText={hasValue(validation.title) ? validation.title : null}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSkuDialog({
                                    ...skuDialog,
                                    data: {...skuDialog.data, skuTitle: event.target.value}
                                });
                                if (hasValue(validation.title)) {
                                    setValidation({...validation, title: ''})
                                }
                            }} value={skuDialog.data.skuTitle}/>
            </CGrid>
            {/*<CGrid item xs={2}>*/}
            {/*    <CTextField fullWidth label={"Default Price"}*/}
            {/*                type={'number'}*/}
            {/*                error={hasValue(validation.defaultPrice)}*/}
            {/*                helperText={hasValue(validation.defaultPrice) ? validation.defaultPrice : null}*/}
            {/*                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
            {/*                    setSkuDialog({*/}
            {/*                        ...skuDialog,*/}
            {/*                        data: {*/}
            {/*                            ...skuDialog.data,*/}
            {/*                            skuDefaultPrice: event.target.value*/}
            {/*                        }*/}
            {/*                    });*/}
            {/*                    if (hasValue(validation.defaultPrice)) {*/}
            {/*                        setValidation({...validation, defaultPrice: ''})*/}
            {/*                    }*/}
            {/*                }} value={skuDialog.data.skuDefaultPrice}/>*/}
            {/*</CGrid>*/}
            <CGrid item xs={2}>
                <CTextField fullWidth label={"Category"} select
                    error={hasValue(validation.category)}
                    helperText={hasValue(validation.category)?validation.category:null}
                            onChange={(event) => {
                                const value = Number(event.target.value);
                                setSkuDialog({
                                    ...skuDialog,
                                    data: {...skuDialog.data, categoryId: value}
                                });
                                if(hasValue(validation.category)) {
                                    setValidation({...validation, category: ''})
                                }
                            }} value={skuDialog.data.categoryId}>
                    {categories.map(category => {
                        return <CMenuItem
                            value={category.categoryId}>{category.categoryName}</CMenuItem>
                    })}
                </CTextField>
            </CGrid>
            <CGrid item xs={2}>
                <CTextField fullWidth label={"Vendor"} select
                            error={hasValue(validation.vendor)}
                            helperText={hasValue(validation.vendor) ? validation.vendor : null}
                            onChange={(event) => {
                                const value = Number(event.target.value);
                                setSkuDialog({
                                    ...skuDialog,
                                    data: {...skuDialog.data, vendorId: value}
                                });
                                if(hasValue(validation.vendor)) {
                                    setValidation({...validation, vendor: ''})
                                }
                            }} value={skuDialog.data.vendorId}>
                    {vendors.map(vendor => {
                        return <CMenuItem
                            value={vendor.vendorId}>{vendor.vendorName}</CMenuItem>
                    })}
                </CTextField>
            </CGrid>
            <CGrid item xs={2}>
                <CTextField fullWidth label={"Cogs"} select
                            error={hasValue(validation.cogs)}
                            helperText={hasValue(validation.cogs) ? validation.cogs : null}
                            onChange={(event) => {
                                const value = Number(event.target.value);
                                setSkuDialog({
                                    ...skuDialog,
                                    data: {...skuDialog.data, cogsId: value}
                                });
                                if(hasValue(validation.cogs)) {
                                    setValidation({...validation, cogs: ''})
                                }
                            }} value={skuDialog.data.cogsId}>
                    {cogses?.map(cogs => {
                        return <CMenuItem
                            value={cogs.cogsId}>{cogs.cogsTitle} (${cogs.cogsCost})</CMenuItem>
                    })}
                </CTextField>
            </CGrid>
            <CGrid item xs={2}>
                <CTextField fullWidth label={"Status"} select
                            error={hasValue(validation.status)}
                            helperText={hasValue(validation.status) ? validation.status : null}
                            onChange={(event) => {
                                const value = event.target.value;
                                setSkuDialog({
                                    ...skuDialog,
                                    data: {...skuDialog.data, skuStatus: value}
                                });
                                if (hasValue(validation.status)) {
                                    setValidation({...validation, status: ''})
                                }
                            }} value={skuDialog.data.skuStatus}>
                    <CMenuItem value={"active"}>Active</CMenuItem>
                    <CMenuItem value={"inactive"}>Inactive</CMenuItem>
                </CTextField>
            </CGrid>
            <CGrid item xs={12}>
                <CTextField fullWidth multiline label={"Descriptions"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSkuDialog({
                                    ...skuDialog,
                                    data: {...skuDialog.data, skuDesc: event.target.value}
                                });
                            }} value={skuDialog.data.skuDesc}/>
            </CGrid>
        </CGridContainer>
        <Divider></Divider>
        <CBox
            sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
            <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                Close
            </CButton>
            <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                {skuDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                          size={24}/> : skuDialog.mode === 'add' ? 'Add' : 'Edit'}
            </CButton>
        </CBox>
    </CDialog>
}