import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  UserDeleteParams,
  UserCreateResult,
  UserUpdateResult,
  UserUpdateParams,
  UserFindAllResult,
  UserDeleteResult,
  UserFindAllParams,
  UserFindOneParams,
  UserCreateParams,
  UserFindOneResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class UsersApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: UserCreateParams): Promise<UserCreateResult> {
    try {
      // const functionName = "login"
      const uri = "users"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const user = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", user}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: UserFindAllParams): Promise<UserFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "users"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const users = response.data.users
      const count = response.data.count

      console.log('findAll', response)

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", users, count}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: UserFindOneParams): Promise<UserFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "users/"+params.userId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const user = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", user}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: UserUpdateParams): Promise<UserUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "users/"+params.userId
      delete params.userId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const user = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", user}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: UserDeleteParams): Promise<UserDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "users/"+params.userId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
