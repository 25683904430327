import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {CategoryModel} from "../category/category";
import {VendorModel} from "../vendor/vendor";
import {PriceModel} from "../price/price";
import {SkuImageModel} from "../sku-image/sku-image";
import {AvailabilityModel} from "../availability/availability";
import {BasicBundleModel} from "../basic-bundle/basic-bundle";


export const SkuNoRelationModel = types.model("Sku").props({
    skuId: types.number,
    cogsId: types.maybeNull(types.number),
    skuTitle: types.maybeNull(types.string),
    skuDesc: types.maybeNull(types.string),
    skuStatus: types.maybeNull(types.string),
    skuHasVarieties: types.maybeNull(types.number),
    skuHasParent: types.maybeNull(types.number),
    skuParentId: types.maybeNull(types.number),
    categoryId: types.maybeNull(types.number),
    vendorId: types.maybeNull(types.number),
    // skuDefaultPrice: types.maybeNull(types.number),
    skuIsFeatured: types.maybeNull(types.number),
    skuCode: types.maybeNull(types.string),
    skuCreatedAt: types.maybeNull(types.string),
    skuUpdatedAt: types.maybeNull(types.string),
})

export const SkuVendorRelationModel = types.model("Sku").props({
    skuId: types.number,
    cogsId: types.maybeNull(types.number),
    skuTitle: types.maybeNull(types.string),
    skuDesc: types.maybeNull(types.string),
    skuStatus: types.maybeNull(types.string),
    skuHasVarieties: types.maybeNull(types.number),
    skuHasParent: types.maybeNull(types.number),
    skuParentId: types.maybeNull(types.number),
    categoryId: types.maybeNull(types.number),
    vendorId: types.maybeNull(types.number),
    vendor: types.optional(types.maybeNull(VendorModel), {vendorId: -1}),
    // skuDefaultPrice: types.maybeNull(types.number),
    skuIsFeatured: types.maybeNull(types.number),
    skuCode: types.maybeNull(types.string),
    skuCreatedAt: types.maybeNull(types.string),
    skuUpdatedAt: types.maybeNull(types.string),
})

export const SkuModel = types.model("Sku").props({
    skuId: types.number,
    cogsId: types.maybeNull(types.number),
    skuTitle: types.maybeNull(types.string),
    skuDesc: types.maybeNull(types.string),
    skuStatus: types.maybeNull(types.string),
    skuHasVarieties: types.maybeNull(types.number),
    skuHasParent: types.maybeNull(types.number),
    skuParentId: types.maybeNull(types.number),
    categoryId: types.maybeNull(types.number),
    vendorId: types.maybeNull(types.number),
    // skuDefaultPrice: types.maybeNull(types.number),
    skuIsFeatured: types.maybeNull(types.number),
    skuCode: types.maybeNull(types.string),
    skuCreatedAt: types.maybeNull(types.string),
    skuUpdatedAt: types.maybeNull(types.string),
    category: types.optional(types.maybeNull(CategoryModel), {categoryId: -1}),
    vendor: types.optional(types.maybeNull(VendorModel), {vendorId: -1}),
    parentSku: types.optional(types.maybeNull(SkuVendorRelationModel), {skuId: -1}),
    skuImages: types.optional(types.array(SkuImageModel), []),
    prices: types.optional(types.array(PriceModel), []),
    storeItemAvailabilities: types.optional(types.array(AvailabilityModel), []),
    basicBundles: types.optional(types.array(BasicBundleModel), []),
})

type SkuType = Instance<typeof SkuModel>
export interface Sku extends SkuType {}
type SkuSnapshotType = SnapshotOut<typeof SkuModel>
export interface SkuSnapshot extends SkuSnapshotType {}

export const defaultSku = {
    skuId: undefined,
    cogsId: undefined,
    skuTitle: '',
    skuDesc: '',
    skuStatus: '',
    skuCode: '',
    categoryId: undefined,
    vendorId: undefined,
    // skuDefaultPrice: undefined,
    skuIsFeatured: 0,
    skuImages: []
}