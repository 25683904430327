import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {DiscountsApi} from "../../services/api/discounts-api"
import {DiscountModel, DiscountSnapshot} from "../discount/discount"
import {logInfo} from "../../utils/logs"
import {
    DiscountCreateParams,
    DiscountDeleteParams,
    DiscountFindAllParams,
    DiscountFindOneParams,
    DiscountUpdateParams
} from "../../services/api";

function discountStoreLog(functionName, message) {
    logInfo({
        fileName: "discount-store.ts",
        className: "discountStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const DiscountStoreModel = types
    .model("DiscountStore")
    .props({
        discount: types.optional(types.maybeNull(DiscountModel), null),
        discounts: types.optional(types.array(DiscountModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveDiscount: (discountSnapshot: DiscountSnapshot) => {
            try {
                self.discount = cast(discountSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    discountStoreLog("saveDiscount", e.message)
                }
                // recordError(e)
            }
        },
        saveDiscounts: (discountsSnapshot: DiscountSnapshot[]) => {
            try {
                self.discounts = cast([...discountsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    discountStoreLog("saveDiscount", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: DiscountCreateParams, token: string) {
            self.environment.addAuth(token)
            const discountApi = new DiscountsApi(self.environment.api)
            const result = yield discountApi.create(params)


            if (result.kind === "ok") {
                self.saveDiscount(result.discount)
            } else {
                discountStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: DiscountFindAllParams, token: string) {
            self.environment.addAuth(token)
            const discountApi = new DiscountsApi(self.environment.api)
            const result = yield discountApi.findAll(params)


            if (result.kind === "ok") {
                self.saveDiscounts(result.discount)
            } else {
                discountStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: DiscountFindOneParams, token: string) {
            self.environment.addAuth(token)
            const discountApi = new DiscountsApi(self.environment.api)
            const result = yield discountApi.findOne(params)


            if (result.kind === "ok") {
                self.saveDiscount(result.discount)
            } else {
                discountStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: DiscountUpdateParams, token: string) {
            self.environment.addAuth(token)
            const discountApi = new DiscountsApi(self.environment.api)
            const result = yield discountApi.update(params)


            if (result.kind === "ok") {
                self.saveDiscount(result.discount)
            } else {
                discountStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: DiscountDeleteParams, token: string) {
            self.environment.addAuth(token)
            const discountApi = new DiscountsApi(self.environment.api)
            return yield discountApi.remove(params)
        }),

    }))

type DiscountStoreType = Instance<typeof DiscountStoreModel>

export interface DiscountStore extends DiscountStoreType {
}

type DiscountStoreSnapshotType = SnapshotOut<typeof DiscountStoreModel>

export interface DiscountStoreSnapshot extends DiscountStoreSnapshotType {
}

export const createDiscountStoreDefaultModel = () => types.optional(DiscountStoreModel, {})
