import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {OrderDetailsApi} from "../../services/api/order-details-api"
import {OrderDetailModel, OrderDetailSnapshot} from "../order-detail/order-detail"
import {logInfo} from "../../utils/logs"
import {
    OrderDetailCreateParams,
    OrderDetailDeleteParams,
    OrderDetailFindAllParams,
    OrderDetailFindOneParams,
    OrderDetailUpdateParams
} from "../../services/api";

function orderDetailStoreLog(functionName, message) {
    logInfo({
        fileName: "orderDetail-store.ts",
        className: "orderDetailStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const OrderDetailStoreModel = types
    .model("OrderDetailStore")
    .props({
        orderDetail: types.optional(types.maybeNull(OrderDetailModel), null),
        orderDetails: types.optional(types.array(OrderDetailModel), []),
        salesReport: types.optional(types.array(types.frozen()), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveOrderDetail: (orderDetailSnapshot: OrderDetailSnapshot) => {
            try {
                self.orderDetail = cast(orderDetailSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    orderDetailStoreLog("saveOrderDetail", e.message)
                }
                // recordError(e)
            }
        },
        saveOrderDetails: (orderDetailsSnapshot: OrderDetailSnapshot[]) => {
            try {
                detach(self.orderDetails)
                self.orderDetails = cast([...orderDetailsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    orderDetailStoreLog("saveOrderDetail", e.message)
                }
                // recordError(e)
            }
        },
        saveSalesReport: (salesReport: any[]) => {
            try {
                console.log('saveSalesReport', salesReport)
                detach(self.salesReport)
                self.salesReport = cast([...salesReport])
            } catch (e) {
                if(e instanceof Error) {
                    orderDetailStoreLog("saveSalesReport", e.message)
                }
           }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: OrderDetailCreateParams, token: string) {
            self.environment.addAuth(token)
            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            const result = yield orderDetailApi.create(params)


            if (result.kind === "ok") {
                self.saveOrderDetail(result.orderDetail)
            } else {
                orderDetailStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: OrderDetailFindAllParams, token: string) {
            self.environment.addAuth(token)
            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            const result = yield orderDetailApi.findAll(params)


            if (result.kind === "ok") {
                self.saveOrderDetails(result.orderDetails)
            } else {
                orderDetailStoreLog("login", result.kind)
            }
            return result
        }),
        getSalesReport: flow(function* (startDate: string, endDate: string, storeId: number, token: string) {
            self.environment.addAuth(token)
            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            const result = yield orderDetailApi.salesReport(startDate, endDate, storeId)


            if (result.kind === "ok") {
                self.saveSalesReport(result.report)
            } else {
                orderDetailStoreLog("salesReport", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: OrderDetailFindOneParams, token: string) {
            self.environment.addAuth(token)
            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            const result = yield orderDetailApi.findOne(params)


            if (result.kind === "ok") {
                self.saveOrderDetail(result.orderDetail)
            } else {
                orderDetailStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: OrderDetailUpdateParams, token: string) {
            self.environment.addAuth(token)
            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            const result = yield orderDetailApi.update(params)


            if (result.kind === "ok") {
                self.saveOrderDetail(result.orderDetail)
            } else {
                orderDetailStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: OrderDetailDeleteParams, token: string) {
            self.environment.addAuth(token)
            const orderDetailApi = new OrderDetailsApi(self.environment.api)
            return yield orderDetailApi.remove(params)
        }),

    }))

type OrderDetailStoreType = Instance<typeof OrderDetailStoreModel>

export interface OrderDetailStore extends OrderDetailStoreType {
}

type OrderDetailStoreSnapshotType = SnapshotOut<typeof OrderDetailStoreModel>

export interface OrderDetailStoreSnapshot extends OrderDetailStoreSnapshotType {
}

// export const createOrderDetailStoreDefaultModel = () => types.optional(OrderDetailStoreModel, {})
