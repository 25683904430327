import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  LoginResult,
  LoginParams, CustomerLoginParams,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class AuthApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async login(params: LoginParams): Promise<LoginResult> {
    try {
      // const functionName = "login"
      const uri = "auth/login"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const auth = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", auth}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async customerLogin(params: CustomerLoginParams): Promise<LoginResult> {
    try {
      // const functionName = "login"
      const uri = "auth/customer-login"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const auth = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", auth}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

}
