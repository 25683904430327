import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {GatewaysApi} from "../../services/api/gateways-api"
import {GatewayModel, GatewaySnapshot} from "../gateway/gateway"
import {logInfo} from "../../utils/logs"
import {
    GatewayCreateParams,
    GatewayDeleteParams,
    GatewayFindAllParams,
    GatewayFindOneParams,
    GatewayUpdateParams
} from "../../services/api";

function gatewayStoreLog(functionName, message) {
    logInfo({
        fileName: "gateway-store.ts",
        className: "gatewayStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const GatewayStoreModel = types
    .model("GatewayStore")
    .props({
        gateway: types.optional(types.maybeNull(GatewayModel), null),
        gateways: types.optional(types.array(GatewayModel), []),
        fetchingGateways: types.optional(types.boolean, false)
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveGateway: (gatewaySnapshot: GatewaySnapshot) => {
            try {
                self.gateway = gatewaySnapshot
            } catch (e) {
                if(e instanceof Error) {
                    gatewayStoreLog("saveGateway", e.message)
                }
                // recordError(e)
            }
        },
        saveGateways: (gatewaysSnapshot: GatewaySnapshot[]) => {
            try {
                detach(self.gateways);
                self.gateways = cast([...gatewaysSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    gatewayStoreLog("saveGateway", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: GatewayCreateParams, token: string) {
            self.environment.addAuth(token)
            const gatewayApi = new GatewaysApi(self.environment.api)
            const result = yield gatewayApi.create(params)


            if (result.kind === "ok") {
                self.saveGateway(result.gateway)
            } else {
                gatewayStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: GatewayFindAllParams, token: string) {
            self.fetchingGateways = true
            self.environment.addAuth(token)
            const gatewayApi = new GatewaysApi(self.environment.api)
            const result = yield gatewayApi.findAll(params)


            if (result.kind === "ok") {
                self.saveGateways(result.gateways)
            } else {
                gatewayStoreLog("login", result.kind)
            }
            self.fetchingGateways = false
            return result
        }),
        findOne: flow(function* (params: GatewayFindOneParams, token: string) {
            self.environment.addAuth(token)
            const gatewayApi = new GatewaysApi(self.environment.api)
            const result = yield gatewayApi.findOne(params)


            if (result.kind === "ok") {
                self.saveGateway(result.gateway)
            } else {
                gatewayStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: GatewayUpdateParams, token: string) {
            self.environment.addAuth(token)
            const gatewayApi = new GatewaysApi(self.environment.api)
            const result = yield gatewayApi.update(params)


            if (result.kind === "ok") {
                self.saveGateway(result.gateway)
            } else {
                gatewayStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: GatewayDeleteParams, token: string) {
            self.environment.addAuth(token)
            const gatewayApi = new GatewaysApi(self.environment.api)
            return yield gatewayApi.remove(params)
        }),

    }))

type GatewayStoreType = Instance<typeof GatewayStoreModel>

export interface GatewayStore extends GatewayStoreType {
}

type GatewayStoreSnapshotType = SnapshotOut<typeof GatewayStoreModel>

export interface GatewayStoreSnapshot extends GatewayStoreSnapshotType {
}

export const createGatewayStoreDefaultModel = () => types.optional(GatewayStoreModel, {})
