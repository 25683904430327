import Select, {SelectProps} from '@mui/material/Select';
import NativeSelect, {NativeSelectProps} from '@mui/material/NativeSelect';

import {styled} from '@mui/material/styles';

export const CSelect = styled(Select)<SelectProps>(
  (/* { theme } */) => ({
  }),
);

export const CNativeSelect = styled(NativeSelect)<NativeSelectProps>(
  (/* { theme } */) => ({
  }),
);

export const CSelectWhiteBorder = styled(Select)<SelectProps>(
    (/* { theme } */) => ({
        color: "white",
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
            color: "white",
            borderRadius: 32
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.50)',
            color: "white",
            borderRadius: 32
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
            color: "white",
            borderRadius: 32
        },
        '.MuiSvgIcon-root ': {
            fill: "white !important",
        }
    }),
);