import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
  OrderDetailDeleteParams,
  OrderDetailCreateResult,
  OrderDetailUpdateResult,
  OrderDetailUpdateParams,
  OrderDetailFindAllResult,
  OrderDetailDeleteResult,
  OrderDetailFindAllParams,
  OrderDetailFindOneParams,
  OrderDetailCreateParams,
  OrderDetailFindOneResult,
  OrderSkuCountResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
import {hasValue} from "../../utils/empty-check";
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class OrderDetailsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(params: OrderDetailCreateParams): Promise<OrderDetailCreateResult> {
    try {
      // const functionName = "login"
      const uri = "order-details"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(uri, params)
      console.log('create', response)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const orderDetail = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", orderDetail}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findAll(params: OrderDetailFindAllParams): Promise<OrderDetailFindAllResult> {
    try {
      // const functionName = "login"
      const uri = "order-details"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const orderDetails = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", orderDetails}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async skuCount(id: number, lockboxId: number): Promise<OrderSkuCountResult> {
    try {
      // const functionName = "login"
      const uri = "order-details/sku-count/"+id
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, {lockboxId})
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const count = hasValue(response.data.sum) ? Number(response.data.sum) : 0

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", count}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async salesReport(startDate, endDate, storeId): Promise<any> {
    try {
      // const functionName = "login"
      const uri = "order-details/sales-report"
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, {
        startDate,
        endDate,
        storeId
      })
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }
      console.log('getSalesReport', response)

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", report: response.data}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async findOne(params: OrderDetailFindOneParams): Promise<OrderDetailFindOneResult> {
    try {
      // const functionName = "login"
      const uri = "order-details/"+params.orderDetailId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const orderDetail = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", orderDetail}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async update(params: OrderDetailUpdateParams): Promise<OrderDetailUpdateResult> {
    try {
      // const functionName = "login"
      const uri = "order-details/"+params.orderDetailId
      delete params.orderDetailId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.patch(uri, params)
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      const orderDetail = response.data

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok", orderDetail}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }

  async remove(params: OrderDetailDeleteParams): Promise<OrderDetailDeleteResult> {
    try {
      // const functionName = "login"
      const uri = "order-details/"+params.orderDetailId
      // recordServiceRequestLog(uri, functionName, params)
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(uri, params)
      console.log('remove', response)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
          return problem
        }
      }

      // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
      return {kind: "ok"}
    } catch (e) {
      // recordError(e)
      return {kind: "bad-data"}
    }
  }
}
