import * as React from "react";
import {observer} from "mobx-react-lite";
import {SalesReportScreenContent} from "../../../sections/sales-report-screen-content";


export const StorePanelSalesReport = observer(
    function StorePanelSalesReport() {
        return (
            <SalesReportScreenContent
                module={'store admin'}
            />
        );
    }
)
