import {cast, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {logInfo} from "../../utils/logs"
import {CartModel, defaultCart} from "../cart/cart";

function cartStoreLog(functionName, message) {
    logInfo({
        fileName: "cart-store.ts",
        className: "cartStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const PreviousCartStoreModel = types
    .model("PreviousCartStore")
    .props({
        previousCart: types.optional(types.maybeNull(CartModel), defaultCart),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        savePreviousCart: (cartSnapshot) => {
            try {
                const currentSnapshot = JSON.parse(JSON.stringify(cartSnapshot))
                self.previousCart = cast({...currentSnapshot})
            } catch (e) {
                if (e instanceof Error) {
                    cartStoreLog("savePreviousCart", e.message)
                }
                // recordError(e)
            }
        }
    }))

type PreviousCartStoreType = Instance<typeof PreviousCartStoreModel>

export interface PreviousCartStore extends PreviousCartStoreType {
}

type PreviousCartStoreSnapshotType = SnapshotOut<typeof PreviousCartStoreModel>

export interface PreviousCartStoreSnapshot extends PreviousCartStoreSnapshotType {
}

// export const createPreviousCartStoreDefaultModel = () => types.optional(PreviousCartStoreModel, {})
