import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCircularProgress,
    CGrid,
    CGridContainer,
    CTypography,
    CIconButton,
    ChipSelect,
    CContainer,
    CCard,
    POSTitle,
    DisclaimerDialog,
    DeliveryMethodDialog,
    Footer,
    CSnackbar,
    CAlert,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import {
    AddCircle,
    AddShoppingCart, NewReleasesOutlined,
    RemoveCircle,
} from "@mui/icons-material";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {useAppInit} from "../../../hooks/use-app-init";
import {Sku} from "../../../models/sku/sku";
import {hasValue} from "../../../utils/empty-check";
import {currentDomain} from "../../../utils/domain";
import {WaveBackground} from "../../../components/wave-background";
import {StoreToolbar} from "../../../sections/store-toolbar";
import {scrollToTop} from "../../../utils/navigation";
import {CartSku} from "../../../models/cart-sku/cart-sku";
import {AlertColor} from "@mui/material/Alert/Alert";
import ReactGA from "react-ga4";
import {getSkuPrice} from "../../../utils/sku";
import {BasicBundle} from "../../../models/basic-bundle/basic-bundle";
import {convertToCurrentTimezone, isTodayBetweenDates} from "../../../utils/date-time";
import {calculateFreeItems} from "../../../utils/cart";

interface SkuDialogData {
    data?: Sku | null,
    price: number,
    priceId?: number,
    isLoadingVarieties: boolean,
    isLoadingInventory: boolean,
}

const skuDialogInitialState = {
    data: null,
    price: 0,
    isLoadingVarieties: false,
    isLoadingInventory: false
}

let statelessSku: Sku | null = null
let statelessVarieties: Sku[] | null = null

export const StoreProduct = observer(
    function StoreProduct() {
        const navigate = useNavigate();

        const {skuStore, cartStore, locationStore} = useStores()
        const {skuVarieties} = skuStore
        const {cart} = cartStore

        const params = useParams();

        const nameVendorParam = params.vendor_name !== undefined ? params.vendor_name.split("-") : []
        const hasNameVendorParam = nameVendorParam !== null && nameVendorParam !== undefined && nameVendorParam.length >= 2
        let nameParam = nameVendorParam && nameVendorParam.length > 2 ? hasNameVendorParam ? nameVendorParam[1] + '-' + nameVendorParam[2] : '' : hasNameVendorParam ? nameVendorParam[1] : ''
        if (params.vendor_name?.includes('E-')) {
            nameParam = 'E-' + params.vendor_name?.split('E-')[1]
        } else {
            nameParam = nameVendorParam && nameVendorParam.length > 2 ? hasNameVendorParam ? nameVendorParam[1] + '-' + nameVendorParam[2] : '' : hasNameVendorParam ? nameVendorParam[1] : ''
        }
        const vendorParam = hasNameVendorParam ? nameVendorParam[0] : ''

        const [isGettingSku, setIsGettingSku] = React.useState(true)
        const guestAuth = useAppInit()
        const {guestAuthToken, isLoadingGuestAuth, locationInfo, locationInfoState} = guestAuth
        const [skuDialog, setSkuDialog] = React.useState<SkuDialogData>(skuDialogInitialState);
        const [selectedSkuId, setSelectedSkuId] = React.useState(-1);
        const [selectedSku, setSelectedSku] = React.useState<Sku | null>(null);
        const [selectedSkuQuantity, setSelectedSkuQuantity] = React.useState(1);
        const [selectedSkuCount, setSelectedSkuCount] = React.useState(0);
        const [isOutOfStock, setIsOutOfStock] = React.useState(false);
        const [deliveryMethodDialog, setDeliveryMethodDialog] = React.useState({
            isOpen: false,
            skuTitle: null,
            vendorName: null
        })
        const [messageSnackbar, setMessageSnackbar] = React.useState<{
            isOpen: boolean;
            message: string;
            severity: AlertColor,
            verticalAnchor: "bottom" | "top"
        }>({isOpen: false, message: '', severity: 'info', verticalAnchor: 'bottom'})

        const calculateSkuCount = (skuCount, cartSkuIndex) => {
            if (cartSkuIndex !== null && cartSkuIndex !== undefined && cartSkuIndex > -1) {
                const cartSkuAmount = cartStore.cart?.cartSkus[cartSkuIndex].cartSkuAmount
                if (cartSkuAmount) {
                    skuCount = skuCount - cartSkuAmount
                    if (skuCount < 0) {
                        skuCount = 0
                    }
                }
            }
            setSelectedSkuCount(skuCount)
            if (skuCount <= 0) {
                setIsOutOfStock(true)
            } else {
                setIsOutOfStock(false)
            }
        }

        const loadSku = async () => {
            const {skus} = await skuStore.findAll({
                skuTitle: nameParam,
                vendorName: vendorParam,
                skuHasParent: 0
            }, guestAuthToken, true)
            if (!skus) {
                return
            }
            const sku = skus[0]
            console.log('loadSku', sku, getSkuPrice(sku.prices, locationInfo?.locationId)?.priceAmount || 0)
            statelessSku = sku
            setSkuDialog({
                ...skuDialog,
                isLoadingVarieties: true,
                isLoadingInventory: true,
                data: sku,
                price: getSkuPrice(sku.prices, locationInfo?.locationId)?.priceAmount || 0,
                priceId: getSkuPrice(sku.prices, locationInfo?.locationId)?.priceId
            });

            setSelectedSkuQuantity(1)
            setSelectedSkuCount(selectedSkuCount - 1)
            if (sku.skuHasVarieties === 0) {
                setSelectedSkuId(sku.skuId)
                setSelectedSku({...sku})
                // const skuCount = await cartStore.skuCount(sku.skuId, Number(cart?.cartLockboxId), guestAuthToken)
                let skuCount = 0;
                if (sku.storeItemAvailabilities && sku.storeItemAvailabilities.length > 0) {
                    const availabilityIndex = sku.storeItemAvailabilities.findIndex(availability => availability.locationId === Number(cart?.cartLockboxId))
                    if (availabilityIndex > -1) {
                        const count = sku.storeItemAvailabilities[availabilityIndex].availabilityCount
                        if (count && count > 0) {
                            skuCount = count
                        }
                    }
                }
                const cartSkuIndex = cartStore.cart?.cartSkus.findIndex(cartSku => cartSku.cartSkuSku.skuId === sku.skuId)
                calculateSkuCount(skuCount, cartSkuIndex)
            } else {
                setSelectedSkuId(-1)
                setSelectedSku(null)
                setSelectedSkuCount(0)
            }
            const varietiesResult = await skuStore.findAllVarieties({
                sortBy: 'skuTitle',
                sortDirection: 'ASC',
                skuParentId: Number(sku.skuId),
                page: 0,
                perPage: 100
            }, guestAuthToken)
            if (varietiesResult.kind === "ok") {
                statelessVarieties = varietiesResult.skus
            }
            statelessSku = sku
            setSkuDialog({
                ...skuDialog,
                isLoadingVarieties: false,
                isLoadingInventory: false,
                data: sku,
                price: getSkuPrice(sku.prices, locationInfo?.locationId)?.priceAmount || 0,
                priceId: getSkuPrice(sku.prices, locationInfo?.locationId)?.priceId
            });
        };


        const recordGAItemViewEvents = () => {
            const gaItems: any[] = []

            ReactGA.event("view_item", {
                items: [
                    {
                        item_id: statelessSku?.skuId,
                        item_name: statelessSku?.skuHasParent ? statelessSku?.skuCode + ' - ' + statelessSku?.vendor?.vendorName + ' - ' + statelessSku?.parentSku?.skuTitle + ' - ' + statelessSku?.skuTitle : statelessSku?.vendor?.vendorName + ' - ' + statelessSku?.skuTitle,
                        price: getSkuPrice(statelessSku?.prices, locationInfo?.locationId)?.priceAmount || 0,
                    }
                ]
            })

            // console.log("ReactGA", "StoreProductScreen", "view_item", {
            //     items: [
            //         {
            //             item_id: statelessSku?.skuId,
            //             item_name: statelessSku?.skuHasParent ? statelessSku?.skuCode + ' - ' + statelessSku?.vendor?.vendorName + ' - ' + statelessSku?.parentSku?.skuTitle + ' - ' + statelessSku?.skuTitle : statelessSku?.vendor?.vendorName + ' - ' + statelessSku?.skuTitle,
            //             price: getSkuPrice(statelessSku?.prices, locationInfo?.locationId)?.priceAmount || 0,
            //         }
            //     ]
            // })

            if (statelessVarieties) {
                statelessVarieties.forEach(sku => {
                    if (sku) {
                        gaItems.push({
                            item_id: sku.skuId,
                            item_name: sku.skuHasParent ? sku.skuCode + ' - ' + sku.vendor?.vendorName + ' - ' + sku.parentSku?.skuTitle + ' - ' + sku.skuTitle : sku.vendor?.vendorName + ' - ' + sku.skuTitle,
                            price: getSkuPrice(statelessSku?.prices, locationInfo?.locationId)?.priceAmount || 0,
                        })

                    }
                })
                ReactGA.event("view_item_list", {
                    item_list_id: `product_screen_${statelessSku?.skuId}`,
                    item_list_name: `Product Screen ${statelessSku?.skuTitle}`,
                    items: gaItems
                })

                // ReactGA.event("view_item", {
                //     items: gaItems
                // })

                // console.log("ReactGA", "StoreProductScreen", "view_item_list", {
                //     item_list_id: `product_screen_${statelessSku?.skuId}`,
                //     item_list_name: `Product Screen ${statelessSku?.skuTitle}`,
                //     items: gaItems
                // })
            }
        }

        const init = async () => {

            setIsGettingSku(true)
            await loadSku()
            setIsGettingSku(false)


            if (statelessSku) {
                recordGAItemViewEvents()
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        // useEffect(() => {
        // }, [])

        // useEffect(() => {
        //     ;(async () => {
        //         if (guestAuthState === 'loaded') {
        //         }
        //     })()
        // }, [guestAuthToken, guestAuthState, params]);

        useEffect(() => {

            ;(async () => {
                if (locationInfoState === 'loaded' && (locationInfo === null || locationInfo.locationSlug !== currentDomain())) {
                    navigate('/not-found')
                }
                document.title = `${vendorParam} - ${nameParam}`;
                if (locationInfoState === 'loaded' && locationInfo !== null) {
                    document.title = `${vendorParam} - ${nameParam} | ${locationInfo.locationName}`;
                    await init()
                }
            })()

        }, [locationInfo, locationInfoState, params])


        const addSku = (inputSku) => {

            let currentCartSkus: CartSku[] = []
            if (cart) {
                // @ts-ignore
                currentCartSkus = [...cart.cartSkus]
            }
            // @ts-ignore
            const index = currentCartSkus.findIndex(cartSku => inputSku.skuId === cartSku.cartSkuSku.skuId)
            if (index > -1) {
                if (currentCartSkus[index].cartSkuAmount + selectedSkuQuantity <= 10) {
                    cartStore.addToSkuNumber(index, selectedSkuQuantity)
                    setMessageSnackbar({
                        isOpen: true,
                        message: `${inputSku.skuTitle} added to your cart.`,
                        severity: "success",
                        verticalAnchor: 'top'
                    })

                    ReactGA.event("add_to_cart", {
                        currency: "USD",
                        value: skuDialog?.price || 0,
                        items: [
                            {
                                item_id: inputSku.skuId,
                                item_name: inputSku.skuHasParent ? inputSku.skuCode + ' - ' + inputSku.vendor?.vendorName + ' - ' + inputSku.parentSku?.skuTitle + ' - ' + inputSku.skuTitle : inputSku.vendor?.vendorName + ' - ' + inputSku.skuTitle,
                                price: skuDialog?.price || 0,
                                quantity: selectedSkuQuantity
                            }
                        ]
                    })

                    console.log("ReactGA", "StoreProductScreen", "add_to_cart", {
                        currency: "USD",
                        value: skuDialog?.price || 0,
                        items: [
                            {
                                item_id: inputSku.skuId,
                                item_name: inputSku.skuHasParent ? inputSku.skuCode + ' - ' + inputSku.vendor?.vendorName + ' - ' + inputSku.parentSku?.skuTitle + ' - ' + inputSku.skuTitle : inputSku.vendor?.vendorName + ' - ' + inputSku.skuTitle,
                                price: skuDialog?.price || 0,
                                quantity: selectedSkuQuantity
                            }
                        ]
                    })
                } else {
                    setMessageSnackbar({
                        isOpen: true,
                        message: "Add to cart failed. You cannot add more than 10 items for each SKU to your shopping cart.",
                        severity: "warning",
                        verticalAnchor: 'bottom'
                    })
                }


            } else {
                if (selectedSkuQuantity <= 10 && currentCartSkus?.length <= 10) {
                    cartStore.addSkuToCart(
                        {...inputSku, parentSku: skuDialog?.data},
                        Number(getSkuPrice(skuDialog?.data?.prices, locationInfo?.locationId)?.priceId),
                        selectedSkuQuantity)
                    setMessageSnackbar({
                        isOpen: true,
                        message: `${inputSku.skuTitle} added to your cart.`,
                        severity: "success",
                        verticalAnchor: 'top'
                    })

                    ReactGA.event("add_to_cart", {
                        currency: "USD",
                        value: skuDialog?.price || 0,
                        items: [
                            {
                                item_id: inputSku.skuId,
                                item_name: inputSku.skuHasParent ? inputSku.skuCode + ' - ' + inputSku.vendor?.vendorName + ' - ' + inputSku.parentSku?.skuTitle + ' - ' + inputSku.skuTitle : inputSku.vendor?.vendorName + ' - ' + inputSku.skuTitle,
                                price: skuDialog?.price || 0,
                                quantity: selectedSkuQuantity
                            }
                        ]
                    })

                    console.log("ReactGA", "StoreProductScreen", "add_to_cart", {
                        currency: "USD",
                        value: skuDialog?.price || 0,
                        items: [
                            {
                                item_id: inputSku.skuId,
                                item_name: inputSku.skuHasParent ? inputSku.skuCode + ' - ' + inputSku.vendor?.vendorName + ' - ' + inputSku.parentSku?.skuTitle + ' - ' + inputSku.skuTitle : inputSku.vendor?.vendorName + ' - ' + inputSku.skuTitle,
                                price: skuDialog?.price || 0,
                                quantity: selectedSkuQuantity
                            }
                        ]
                    })
                } else {
                    setMessageSnackbar({
                        isOpen: true,
                        message: "Add to cart failed. You cannot add more than 10 SKUs to your shopping cart.",
                        severity: "warning",
                        verticalAnchor: 'bottom'
                    })
                }
            }

            setSelectedSkuQuantity(1)
            setSelectedSkuCount(selectedSkuCount - 1)
        }

        const isVarietyAvailable = (variety) => {
            let isAvailable = false;
            if (variety.storeItemAvailabilities && variety.storeItemAvailabilities.length > 0) {
                const availabilityIndex = variety.storeItemAvailabilities.findIndex(availability => availability.locationId === Number(cart?.cartLockboxId))
                if (availabilityIndex > -1) {
                    const count = variety.storeItemAvailabilities[availabilityIndex].availabilityCount
                    if (count && count > 0) {
                        isAvailable = true
                    }
                }
            }
            return isAvailable
        }

        const getVarietySkuCount = (variety) => {
            let skuCount = 0;
            if (variety.storeItemAvailabilities && variety.storeItemAvailabilities.length > 0) {
                const availabilityIndex = variety.storeItemAvailabilities.findIndex(availability => availability.locationId === Number(cart?.cartLockboxId))
                if (availabilityIndex > -1) {
                    const count = variety.storeItemAvailabilities[availabilityIndex].availabilityCount
                    if (count && count > 0) {
                        skuCount = count
                    }
                }
            }
            return skuCount
        }

        const availableVarieties: Sku[] = []
        const unavailableVarieties: Sku[] = []

        if (skuVarieties) {
            skuVarieties.forEach(variety => {
                if (isVarietyAvailable(variety)) {
                    availableVarieties.push(variety)
                } else {
                    unavailableVarieties.push(variety)
                }

            })
        }

        if (isLoadingGuestAuth) {
            return (
                <>
                    <main style={{
                        minHeight: '100vh',
                        width: '100vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CCircularProgress
                            size={32}/>
                    </main>
                </>
            )
        }


        let hasBundles = false
        const basicBundles: BasicBundle[] = []

        if (skuDialog.data && skuDialog.data.basicBundles && skuDialog.data.basicBundles.length > 0 && locationInfo.locationSetting.locationSettingBundlesEnabled === 1) {
            skuDialog.data.basicBundles.forEach(bundle => {
                if (
                    bundle.basicBundleStoreId === locationInfo?.locationId &&
                    bundle.basicBundleDeleted === 0 &&
                    bundle.basicBundleStatus === 'active' &&
                    isTodayBetweenDates(bundle.basicBundleValidFrom, bundle.basicBundleValidTo)
                ) {
                    hasBundles = true
                    basicBundles.push(bundle)
                }
            })
        }

        return (
            <>

                <main style={{minHeight: '100vh'}}>
                    <WaveBackground/>

                    <CContainer sx={{display: 'flex', flexDirection: 'column', zIndex: 100}}>
                        <StoreToolbar locationInfo={locationInfo} onDeliveryMethodDialog={() => {
                            setDeliveryMethodDialog({
                                ...deliveryMethodDialog,
                                isOpen: true,
                            })
                        }}/>

                        {hasBundles && basicBundles && basicBundles.length > 0 && basicBundles.map(basicBundle =>
                            <CGridContainer sx={{
                                mt: 3,
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <CCard variant={"outlined"} sx={{
                                    width: "100%",
                                    boxShadow: 'none',
                                    // minHeight: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1,
                                    borderColor: '#388E3C',
                                    backgroundColor: '#DCEDC8',
                                    p: 3
                                }}>
                                    <NewReleasesOutlined sx={{color: '#388E3C', mr: 2, fontSize: '36px'}}/>

                                    <CGridContainer spacing={1}>
                                        <CGrid item xs={12} md={12}>
                                            <CTypography variant={'subtitle1'} color={'#388E3C'} sx={{flex: 1, textAlign: 'left',}}
                                                         fontWeight={"bold"}>
                                                {/*@ts-ignore*/}
                                                {basicBundle.basicBundleTitle}
                                            </CTypography>
                                            <CTypography fontWeight={"bold"} variant={'body2'} color={'#388E3C'} sx={{flex: 1, textAlign: 'left',}}>
                                                {/*@ts-ignore*/}
                                                {basicBundle.basicBundleDescriptions}
                                            </CTypography>
                                        </CGrid>
                                        <CGrid item xs={12} md={12}>
                                            <CTypography variant={'body2'} color={'#388E3C'} sx={{flex: 1, textAlign: {xs: 'left', sm: 'left', md: 'right', lg: 'right', xl: 'right'},}}>
                                                {/*@ts-ignore*/}
                                                Bundle is
                                                valid {basicBundle?.basicBundleValidFrom ? convertToCurrentTimezone(basicBundle?.basicBundleValidFrom, 'MMM D') : ''} - {basicBundle?.basicBundleValidTo ? convertToCurrentTimezone(basicBundle?.basicBundleValidTo, 'MMM D') : ''}
                                            </CTypography>
                                        </CGrid>

                                    </CGridContainer>


                                </CCard>
                            </CGridContainer>)}

                        <CGridContainer sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CCard sx={{
                                width: "100%",
                                // minHeight: {xs: '86vh', sm: '86vh', md: '86vh', lg: '86vh', xl: '86vh'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 1
                            }}>
                                <CBox sx={{display: "flex", flexDirection: "row", width: '100%', pt: '22px'}}>
                                    {/*<CIconButton*/}
                                    {/*    sx={{width: '64px', ml: '22px'}}*/}
                                    {/*    onClick={() => navigate(-1)}*/}
                                    {/*>*/}
                                    {/*    <ArrowBackIosNew></ArrowBackIosNew>*/}
                                    {/*</CIconButton>*/}
                                    <CBox sx={{width: '64px'}}/>
                                    <CTypography variant={"h3"} fontSize={{xs: '1.5rem', sm: '1.5rem', md: 32, lg: 32, xl: 32}} sx={{
                                        mb: 2,
                                        mt: 2,
                                        flex: 1,
                                        textAlign: "center",
                                    }}>
                                        {vendorParam} - {nameParam}
                                    </CTypography>
                                    <CBox sx={{width: '64px'}}/>
                                </CBox>
                                {isGettingSku ?
                                    <CBox sx={{
                                        display: 'flex',
                                        minHeight: '60vh',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%'
                                    }}>
                                        <CCircularProgress
                                            size={32}/>
                                    </CBox>
                                    :
                                    <>

                                        <CGridContainer spacing={2} p={2} height={'100%'}>

                                            <CGrid item xs={12} md={5}>
                                                <CBox
                                                    sx={{
                                                        display: "flex",
                                                        flex: 1,
                                                        background: `url(${hasValue(skuDialog.data?.skuImages) ? skuDialog.data?.skuImages[0].skuImageLink : ''})`,
                                                        borderRadius: "34px",
                                                        margin: "4px",
                                                        width: "100%",
                                                        height: {xs: "40vh", md: '100%'},
                                                        backgroundSize: "contain",
                                                        backgroundPosition: "center",
                                                        backgroundPositionX: "center",
                                                        backgroundPositionY: "center",
                                                        backgroundRepeat: "no-repeat",
                                                        // borderWidth: 1,
                                                        // borderColor: 'lightgrey',
                                                        // borderStyle: 'solid'
                                                    }}
                                                />
                                            </CGrid>
                                            <CGrid item xs={12} md={7}>
                                                <CBox
                                                    sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                                    {/*<CTypography fontSize={"24px"} mr={2}>*/}
                                                    {/*    {skuDialog.data?.skuTitle}*/}
                                                    {/*</CTypography>*/}
                                                    <CBox sx={{
                                                        flex: 1,
                                                        borderWidth: 1,
                                                        borderColor: 'lightgrey',
                                                        borderStyle: 'solid',
                                                        borderRadius: "34px",
                                                        margin: "4px",
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}>
                                                        <CTypography variant={'h6'} sx={{ml: 2, mt: 2}}>
                                                            ${skuDialog?.price || 0}
                                                        </CTypography>
                                                        {skuDialog.data?.skuHasVarieties === 1 &&
                                                            <CTypography variant={'h5'} sx={{ml: 2, mt: 2}}>
                                                                Varieties
                                                            </CTypography>}
                                                        {skuDialog.isLoadingVarieties ?

                                                            <CBox sx={{
                                                                flex: 1,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <CCircularProgress size={32}/>
                                                            </CBox>
                                                            :

                                                            <CGridContainer
                                                                sx={{flex: 1, display: 'flex', ml: 1, mt: 2}}>
                                                                {skuDialog.data?.skuHasVarieties === 1 ? availableVarieties.map((variety, index) => {
                                                                    const isAvailable = isVarietyAvailable(variety)
                                                                    return <ChipSelect
                                                                        key={index + variety.skuId + String(variety.skuTitle) + '_chip_select'}
                                                                        selected={selectedSkuId === variety.skuId}
                                                                        disabled={!isAvailable}
                                                                        title={variety.skuTitle}
                                                                        onClick={async () => {
                                                                            setSelectedSkuId(variety.skuId)
                                                                            setSelectedSku({...variety})
                                                                            setSkuDialog({
                                                                                ...skuDialog,
                                                                                isLoadingInventory: true
                                                                            })
                                                                            // const skuCount = await cartStore.skuCount(variety.skuId, Number(cart?.cartLockboxId), guestAuthToken)
                                                                            const skuCount = getVarietySkuCount(variety)
                                                                            const cartSkuIndex = cartStore.cart?.cartSkus.findIndex(cartSku => cartSku.cartSkuSku.skuId === variety.skuId)
                                                                            calculateSkuCount(skuCount, cartSkuIndex)
                                                                            setSkuDialog({
                                                                                ...skuDialog,
                                                                                isLoadingInventory: false
                                                                            })
                                                                            ReactGA.event("select_item", {
                                                                                item_list_id: `product_screen_${skuDialog.data?.skuId}`,
                                                                                item_list_name: `Product Screen ${skuDialog.data?.skuTitle}`,
                                                                                items: [
                                                                                    {
                                                                                        item_id: variety.skuId,
                                                                                        item_name: variety.skuHasParent ? variety.skuCode + ' - ' + variety.vendor?.vendorName + ' - ' + variety.parentSku?.skuTitle + ' - ' + variety.skuTitle : variety.vendor?.vendorName + ' - ' + variety.skuTitle,
                                                                                        price: Number(skuDialog?.price || 0),
                                                                                    }
                                                                                ]
                                                                            })

                                                                            ReactGA.event("view_item", {
                                                                                items: [
                                                                                    {
                                                                                        item_id: variety.skuId,
                                                                                        item_name: variety.skuHasParent ? variety.skuCode + ' - ' + variety.vendor?.vendorName + ' - ' + variety.parentSku?.skuTitle + ' - ' + variety.skuTitle : variety.vendor?.vendorName + ' - ' + variety.skuTitle,
                                                                                        price: Number(skuDialog?.price || 0),
                                                                                    }
                                                                                ]
                                                                            })

                                                                            console.log("ReactGA", "StoreProductScreen", "select_item", {
                                                                                item_list_id: `product_screen_${skuDialog.data?.skuId}`,
                                                                                item_list_name: `Product Screen ${skuDialog.data?.skuTitle}`,
                                                                                items: [
                                                                                    {
                                                                                        item_id: variety.skuId,
                                                                                        item_name: variety.skuHasParent ? variety.skuCode + ' - ' + variety.vendor?.vendorName + ' - ' + variety.parentSku?.skuTitle + ' - ' + variety.skuTitle : variety.vendor?.vendorName + ' - ' + variety.skuTitle,
                                                                                        price: Number(skuDialog?.price || 0),
                                                                                    }
                                                                                ]
                                                                            })
                                                                        }}
                                                                    />
                                                                }) : <></>}
                                                                {skuDialog.data?.skuHasVarieties === 1 ? unavailableVarieties.map((variety, index) => {
                                                                    const isAvailable = isVarietyAvailable(variety)
                                                                    return <ChipSelect
                                                                        key={index + variety.skuId + String(variety.skuTitle) + '_chip_select'}
                                                                        selected={selectedSkuId === variety.skuId}
                                                                        disabled={!isAvailable}
                                                                        title={variety.skuTitle}
                                                                        onClick={async () => {
                                                                            setSelectedSkuId(variety.skuId)
                                                                            setSelectedSku({...variety})
                                                                            setSkuDialog({
                                                                                ...skuDialog,
                                                                                isLoadingInventory: true
                                                                            })
                                                                            // const skuCount = await cartStore.skuCount(variety.skuId, Number(cart?.cartLockboxId), guestAuthToken)
                                                                            const skuCount = getVarietySkuCount(variety)
                                                                            const cartSkuIndex = cartStore.cart?.cartSkus.findIndex(cartSku => cartSku.cartSkuSku.skuId === variety.skuId)
                                                                            calculateSkuCount(skuCount, cartSkuIndex)
                                                                            setSkuDialog({
                                                                                ...skuDialog,
                                                                                isLoadingInventory: false
                                                                            })
                                                                        }}
                                                                    />
                                                                }) : <></>}
                                                            </CGridContainer>}
                                                        <CGridContainer sx={{
                                                            mb: 2,
                                                            mt: 4,
                                                        }} spacing={2} px={2}>
                                                            <CGrid item xs={12} md={6}>
                                                                {(skuDialog.isLoadingInventory) &&
                                                                    <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}>
                                                                        Loading ...
                                                                    </CTypography>}
                                                                {(isOutOfStock && !skuDialog.isLoadingInventory && selectedSkuId !== -1) &&
                                                                    <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}
                                                                                 color={'red'}>
                                                                        Out of stock
                                                                    </CTypography>}
                                                                {(!isOutOfStock && !skuDialog.isLoadingInventory && selectedSkuId !== -1) &&
                                                                    <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}>
                                                                        {/*Available: {selectedSkuCount}*/}
                                                                        In Stock
                                                                    </CTypography>}
                                                            </CGrid>

                                                            <CGrid item xs={6} md={3} sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>

                                                                <CIconButton
                                                                    disabled={
                                                                        skuDialog.isLoadingInventory ||
                                                                        selectedSkuId === -1 ||
                                                                        isOutOfStock
                                                                    }
                                                                    onClick={() => {
                                                                        if (selectedSkuQuantity - 1 >= 0) {
                                                                            setSelectedSkuQuantity(selectedSkuQuantity - 1)
                                                                        }
                                                                    }}>
                                                                    <RemoveCircle fontSize={'large'} sx={{
                                                                        color: (skuDialog.isLoadingInventory ||
                                                                            selectedSkuId === -1 ||
                                                                            isOutOfStock) ? 'grey' : "#F44336"
                                                                    }}></RemoveCircle>
                                                                </CIconButton>
                                                                <CTypography>
                                                                    {selectedSkuQuantity}
                                                                </CTypography>
                                                                <CIconButton
                                                                    disabled={
                                                                        skuDialog.isLoadingInventory ||
                                                                        selectedSkuId === -1 ||
                                                                        isOutOfStock
                                                                    }
                                                                    onClick={() => {
                                                                        if (selectedSkuQuantity + 1 > 10) {
                                                                            setMessageSnackbar({
                                                                                isOpen: true,
                                                                                message: "You cannot add more than 10 items for each SKU to your shopping cart.",
                                                                                severity: 'warning',
                                                                                verticalAnchor: 'bottom'
                                                                            })
                                                                            return
                                                                        }
                                                                        if (selectedSkuCount - 1 >= 0) {
                                                                            setSelectedSkuQuantity(selectedSkuQuantity + 1)
                                                                            setSelectedSkuCount(selectedSkuCount - 1)
                                                                        }
                                                                    }}>
                                                                    <AddCircle fontSize={'large'} sx={{
                                                                        color: (skuDialog.isLoadingInventory ||
                                                                            selectedSkuId === -1 ||
                                                                            isOutOfStock) ? 'grey' : "#4CAF50"
                                                                    }}></AddCircle>
                                                                </CIconButton>
                                                            </CGrid>
                                                            <CGrid item xs={6} md={3} sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                // ml: {xs: 1, md: 0},
                                                                // mr: 2
                                                            }}>
                                                                <CButton
                                                                    startIcon={<AddShoppingCart/>}
                                                                    disabled={
                                                                        skuDialog.isLoadingInventory ||
                                                                        isOutOfStock ||
                                                                        selectedSkuId === -1 ||
                                                                        selectedSkuQuantity === 0
                                                                    } variant={"outlined"}
                                                                    onClick={() => {
                                                                        addSku(selectedSku)
                                                                    }}
                                                                    sx={{width: '100%'}}>
                                                                    Add To Cart
                                                                </CButton>
                                                            </CGrid>
                                                        </CGridContainer>

                                                        <CGridContainer sx={{
                                                            display: 'flex',
                                                            // flexDirection: {xs: 'column', md: 'row'},
                                                            alignItems: {xs: 'flex-start', md: 'center'},
                                                            justifyContent: 'flex-end',
                                                            mb: 2,
                                                        }} px={2} spacing={2}>
                                                            <CGrid item xs={6} md={3}>
                                                                <CButton
                                                                    variant={"outlined"} onClick={() => {
                                                                    // navigate(-1)
                                                                    navigate('/')
                                                                }}
                                                                    sx={{width: '100%'}}>
                                                                    Continue
                                                                </CButton>
                                                            </CGrid>
                                                            <CGrid item xs={6} md={3}>
                                                                <CButton
                                                                    disabled={!cartStore.cart || cartStore.cart?.cartSkus.length === 0}
                                                                    variant={"contained"} onClick={() => {
                                                                    navigate('/store/cart')
                                                                    scrollToTop()
                                                                }}
                                                                    sx={{width: '100%'}}>
                                                                    Checkout
                                                                </CButton>
                                                            </CGrid>
                                                        </CGridContainer>
                                                    </CBox>
                                                </CBox>


                                            </CGrid>
                                            <CGrid item xs={12}>
                                                <CBox sx={{
                                                    borderWidth: 1,
                                                    borderColor: 'lightgrey',
                                                    borderStyle: 'solid',
                                                    borderRadius: "34px",
                                                    margin: "4px",
                                                }}>
                                                    <CTypography variant={'h4'} sx={{ml: 2, mt: 2}}>
                                                        Descriptions
                                                    </CTypography>
                                                    <CTypography variant={'body1'} sx={{m: 2}}>
                                                        {skuDialog.data?.skuDesc}
                                                    </CTypography>
                                                </CBox>

                                            </CGrid>
                                        </CGridContainer>
                                    </>

                                }

                            </CCard>
                        </CGridContainer>

                    </CContainer>
                    <Footer/>
                </main>
                <CSnackbar open={messageSnackbar.isOpen} autoHideDuration={3000} onClose={() => {
                    setMessageSnackbar({
                        isOpen: false,
                        message: "",
                        severity: 'info',
                        verticalAnchor: 'bottom'
                    })
                }} anchorOrigin={{
                    vertical: messageSnackbar.verticalAnchor,
                    horizontal: 'right'
                }}>
                    <CAlert onClose={() => {
                        setMessageSnackbar({
                            isOpen: false,
                            message: "",
                            severity: 'info',
                            verticalAnchor: 'bottom'
                        })
                    }} severity={messageSnackbar.severity} sx={{width: '100%'}}>
                        {messageSnackbar.message}
                    </CAlert>
                </CSnackbar>
                <DisclaimerDialog onEnter={() => {
                    if (locationStore.lockboxes && locationStore.lockboxes.length > 0) {
                        if (locationStore.lockboxes.length === 1) {
                            const lockbox = locationStore.lockboxes[0]
                            cartStore.saveCart({
                                ...cartStore.cart,
                                cartLockboxId: lockbox.locationId,
                                cartLockboxText: `${lockbox.locationName}`,
                                // cartSkus: []
                            })
                        } else {
                            setDeliveryMethodDialog(
                                {
                                    isOpen: true,
                                    vendorName: null,
                                    skuTitle: null
                                })
                        }
                    }
                }}/>
                <DeliveryMethodDialog
                    isOpen={deliveryMethodDialog.isOpen}
                    onSelect={() => {
                        if (deliveryMethodDialog.vendorName && deliveryMethodDialog.skuTitle) {
                            navigate(`/store/product/${deliveryMethodDialog.vendorName}-${deliveryMethodDialog.skuTitle}`)
                        }
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                    onClose={() => {
                        setDeliveryMethodDialog({
                            isOpen: false,
                            vendorName: null,
                            skuTitle: null
                        })
                    }}
                />
            </>
        );

        // return (
        //     <>
        //         <main style={{minHeight: '100vh'}}>
        //             <CBox height={90} sx={{
        //                 position: "absolute",
        //                 top: 0,
        //                 left: 0,
        //                 right: 0,
        //                 backgroundColor: "#212121",
        //                 zIndex: 0
        //             }}>
        //                 <CContainer>
        //                     <CGridContainer height={90}>
        //                         <CGrid item xs={2}/>
        //                         <CGrid item xs={8} sx={{
        //                             textAlign: "center",
        //                             color: "white",
        //                             alignItems: "center",
        //                             justifyContent: "center",
        //                             display: "flex"
        //                         }}>
        //
        //                         </CGrid>
        //
        //                         <CGrid item xs={2} sx={{
        //                             textAlign: "center",
        //                             color: "white",
        //                             alignItems: "center",
        //                             justifyContent: "center",
        //                             display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
        //                         }}>
        //                             <MomentTimer/>
        //
        //                         </CGrid>
        //                     </CGridContainer>
        //                 </CContainer>
        //             </CBox>
        //             <CContainer
        //                 style={{
        //                     position: "absolute",
        //                     top: 0,
        //                     right: 0,
        //                     left: 0,
        //                     bottom: 0,
        //                     display: 'flex',
        //                     flexDirection: "column",
        //                     minHeight: '100vh'
        //                 }}>
        //                 <CGridContainer sx={{flex: 1, display: "flex"}}>
        //                     <CGrid item xs={0} md={2} height={90} sx={{
        //                         display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center",
        //                     }}>
        //                         <CFormControl sx={{display: {xs: 'none', sm: 'none', md: 'flex', lg: 'block', xl: 'block' }, m: 1, minWidth: 80}}>
        //                             <CSelectWhiteBorder
        //                                 id="demo-simple-select-autowidth"
        //                                 value={"EN"}
        //                                 onChange={(event) => {
        //                                     const value = event.target.value;
        //                                 }}
        //                                 autoWidth
        //                                 sx={{
        //                                     fontColor: "white",
        //                                 }}
        //                                 size={"small"}
        //                             >
        //                                 <CMenuItem value={"EN"}>EN</CMenuItem>
        //                                 <CMenuItem value={"ES"}>ES</CMenuItem>
        //                             </CSelectWhiteBorder>
        //                         </CFormControl>
        //                     </CGrid>
        //                     <CGrid item xs={12} md={8} sx={{
        //                         display: 'flex',
        //                         alignItems: "center",
        //                         justifyContent: "center"
        //                     }}>
        //                         <CCard sx={{
        //                             width: "100%",
        //                             height: '90vh',
        //                             display: 'flex',
        //                             flexDirection: 'column',
        //                             alignItems: 'center',
        //                             justifyContent: 'center',
        //                         }}>
        //                             {isGettingSku ?
        //                                 <CCircularProgress
        //                                     size={32}/>
        //                                 :
        //                                 <>
        //                                     <CBox sx={{display: "flex", flexDirection: "row", width: '100%', pt: '22px'}}>
        //                                         <CIconButton
        //                                             sx={{width: '96px', ml: '22px'}}
        //                                             onClick={() => navigate(-1)}
        //                                         >
        //                                             <ArrowBackIosNew></ArrowBackIosNew>
        //                                         </CIconButton>
        //                                         <POSTitle sx={{
        //                                             mb: 2,
        //                                             mt: 2,
        //                                             flex: 1,
        //                                             textAlign: "center",
        //                                             fontFamily: "Passion One",
        //                                         }}>
        //                                             {skuDialog.data?.skuTitle}
        //                                         </POSTitle>
        //                                         <CIconButton
        //                                             sx={{width: '96px', mr: '22px'}}
        //                                             onClick={() => {
        //                                                 navigate(`/store/cart`)
        //                                             }}
        //                                         >
        //                                             <ShoppingCart></ShoppingCart>
        //                                             {cart && cart.cartSkus && cart.cartSkus.length > 0 &&
        //                                                 <CBox sx={{
        //                                                     position: 'absolute',
        //                                                     top: 8,
        //                                                     left: 8,
        //                                                     borderRadius: 20,
        //                                                     minWidth: 8,
        //                                                     padding: '2px',
        //                                                     backgroundColor: 'red',
        //                                                     color: 'white',
        //                                                     fontSize: 12
        //                                                 }}>
        //                                                     {cart && cart.cartSkus.length}
        //                                                 </CBox>}
        //                                         </CIconButton>
        //                                     </CBox>
        //                                     <CGridContainer spacing={2} p={2} height={'100%'}>
        //
        //                                         <CGrid item xs={12} md={5}>
        //                                             <CBox
        //                                                 sx={{
        //                                                     display: "flex",
        //                                                     flex: 1,
        //                                                     background: `url(${hasValue(skuDialog.data?.skuImages) ? skuDialog.data?.skuImages[0].skuImageLink : ''})`,
        //                                                     borderRadius: "34px",
        //                                                     margin: "4px",
        //                                                     width: "100%",
        //                                                     height: {xs: "40vh", md: '100%'},
        //                                                     backgroundSize: "contain",
        //                                                     backgroundPosition: "center",
        //                                                     backgroundPositionX: "center",
        //                                                     backgroundPositionY: "center",
        //                                                     backgroundRepeat: "no-repeat",
        //                                                     // borderWidth: 1,
        //                                                     // borderColor: 'lightgrey',
        //                                                     // borderStyle: 'solid'
        //                                                 }}
        //                                             />
        //                                         </CGrid>
        //                                         <CGrid item xs={12} md={7}>
        //                                             <CBox
        //                                                 sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        //                                                 {/*<CTypography fontSize={"24px"} mr={2}>*/}
        //                                                 {/*    {skuDialog.data?.skuTitle}*/}
        //                                                 {/*</CTypography>*/}
        //                                                 <CBox sx={{
        //                                                     flex: 1,
        //                                                     borderWidth: 1,
        //                                                     borderColor: 'lightgrey',
        //                                                     borderStyle: 'solid',
        //                                                     borderRadius: "34px",
        //                                                     margin: "4px",
        //                                                     display: 'flex',
        //                                                     flexDirection: 'column'
        //                                                 }}>
        //                                                     <CTypography variant={'h6'} sx={{ml: 2, mt: 2}}>
        //                                                         ${selectedSkuId === skuDialog.data?.skuId ? Number(skuDialog.data?.skuDefaultPrice).toFixed(2) : selectedSkuPrice.toFixed(2)}
        //                                                     </CTypography>
        //                                                     {skuDialog.data?.skuHasVarieties === 1 && <CTypography variant={'h4'} sx={{ml: 2, mt: 2}}>
        //                                                         Varieties
        //                                                     </CTypography>}
        //                                                     {skuDialog.isLoadingVarieties ?
        //
        //                                                         <CBox sx={{
        //                                                             flex: 1,
        //                                                             display: 'flex',
        //                                                             alignItems: 'center',
        //                                                             justifyContent: 'center'
        //                                                         }}>
        //                                                             <CCircularProgress size={32}/>
        //                                                         </CBox>
        //                                                         :
        //
        //                                                         <CBox sx={{flex: 1, display: 'flex', ml: 1, mt: 2}}>
        //
        //                                                             {skuDialog.data?.skuHasVarieties === 1 ? skuVarieties.map((variety, index) => {
        //                                                                 return <ChipSelect
        //                                                                     key={index + variety.skuId + String(variety.skuTitle)}
        //                                                                     selected={selectedSkuId === variety.skuId}
        //                                                                     title={variety.skuTitle}
        //                                                                     onClick={async () => {
        //                                                                         setSelectedSkuId(variety.skuId)
        //                                                                         setSelectedSku({...variety})
        //                                                                         setSelectedSkuPrice(Number(variety.skuDefaultPrice))
        //                                                                         setSkuDialog({
        //                                                                             ...skuDialog,
        //                                                                             isLoadingInventory: true
        //                                                                         })
        //                                                                         const skuCount = await cartStore.skuCount(variety.skuId, Number(cart?.cartLockboxId), guestAuthToken)
        //                                                                         const cartSkuIndex = cartStore.cart?.cartSkus.findIndex(cartSku => cartSku.cartSkuSku.skuId === variety.skuId)
        //                                                                         calculateSkuCount(skuCount, cartSkuIndex)
        //                                                                         setSkuDialog({
        //                                                                             ...skuDialog,
        //                                                                             isLoadingInventory: false
        //                                                                         })
        //                                                                     }}
        //                                                                 />
        //                                                             }) : <></>}
        //                                                         </CBox>}
        //                                                     <CBox sx={{
        //                                                         display: 'flex',
        //                                                         flexDirection: {xs: 'column', md: 'row'},
        //                                                         alignItems: {xs: 'flex-start', md: 'center'},
        //                                                         justifyContent: 'flex-end',
        //                                                         mb: 2
        //                                                     }}>
        //                                                         {(skuDialog.isLoadingInventory) &&
        //                                                             <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}>
        //                                                                 Loading Inventory Count ...
        //                                                             </CTypography>}
        //                                                         {(isOutOfStock && !skuDialog.isLoadingInventory && selectedSkuId !== -1) &&
        //                                                             <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}
        //                                                                          color={'red'}>
        //                                                                 Out of stock
        //                                                             </CTypography>}
        //                                                         {(!isOutOfStock && !skuDialog.isLoadingInventory && selectedSkuId !== -1) &&
        //                                                             <CTypography variant={'h6'} sx={{ml: 2, flex: 1}}>
        //                                                                 Available: {selectedSkuCount}
        //                                                             </CTypography>}
        //                                                         <CBox sx={{
        //                                                             display: 'flex',
        //                                                             flexDirection: 'row',
        //                                                             alignItems: 'center',
        //                                                             ml: {xs: 1, md: 0}
        //                                                         }}>
        //                                                             <CIconButton
        //                                                                 disabled={
        //                                                                     skuDialog.isLoadingInventory ||
        //                                                                     selectedSkuId === -1 ||
        //                                                                     isOutOfStock
        //                                                                 }
        //                                                                 onClick={() => {
        //                                                                     if (selectedSkuQuantity - 1 >= 0) {
        //                                                                         setSelectedSkuQuantity(selectedSkuQuantity - 1)
        //                                                                     }
        //                                                                 }}>
        //                                                                 <RemoveCircle sx={{
        //                                                                     color: (skuDialog.isLoadingInventory ||
        //                                                                         selectedSkuId === -1 ||
        //                                                                         isOutOfStock) ? 'grey' : "#F44336"
        //                                                                 }}></RemoveCircle>
        //                                                             </CIconButton>
        //                                                             <CTypography>
        //                                                                 {selectedSkuQuantity}
        //                                                             </CTypography>
        //                                                             <CIconButton
        //                                                                 disabled={
        //                                                                     skuDialog.isLoadingInventory ||
        //                                                                     selectedSkuId === -1 ||
        //                                                                     isOutOfStock
        //                                                                 }
        //                                                                 onClick={() => {
        //                                                                     if (selectedSkuCount - 1 >= 0) {
        //                                                                         setSelectedSkuQuantity(selectedSkuQuantity + 1)
        //                                                                         setSelectedSkuCount(selectedSkuCount - 1)
        //                                                                     }
        //                                                                 }}>
        //                                                                 <AddCircle sx={{
        //                                                                     color: (skuDialog.isLoadingInventory ||
        //                                                                         selectedSkuId === -1 ||
        //                                                                         isOutOfStock) ? 'grey' : "#4CAF50"
        //                                                                 }}></AddCircle>
        //                                                             </CIconButton>
        //                                                             <CButton
        //                                                                 disabled={
        //                                                                     skuDialog.isLoadingInventory ||
        //                                                                     isOutOfStock ||
        //                                                                     selectedSkuId === -1 ||
        //                                                                     selectedSkuQuantity === 0
        //                                                                 } variant={"contained"} onClick={() => {
        //                                                                 addSku(selectedSku)
        //                                                             }}
        //                                                                 sx={{mr: 2, minWidth: "100px"}}>
        //                                                                 Add To Cart
        //                                                             </CButton>
        //                                                         </CBox>
        //
        //
        //                                                     </CBox>
        //
        //
        //                                                 </CBox>
        //                                             </CBox>
        //
        //
        //                                         </CGrid>
        //
        //                                     </CGridContainer>
        //                                 </>
        //
        //                             }
        //
        //                         </CCard>
        //                     </CGrid>
        //                     <CGrid item xs={0} md={2}/>
        //                 </CGridContainer>
        //             </CContainer>
        //
        //         </main>
        //     </>
        // )
    }
)
