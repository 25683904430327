import {
    CBox,
    CButton,
    CCircularProgress,
    CDialog,
    CGrid,
    CGridContainer,
    CInterleavedTableRow,
    CLinearProgress,
    CMenuItem,
    CTable,
    CTableBody,
    CTableCell,
    CTableHead,
    CTableRow,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {isEmpty} from "validate.js";
import {useStores} from "../../models";
import {useEffect} from "react";

let lastIsOpenStatus = false

export const SkuPricesDialog = (props) => {

    const {
        stores,
        skuPricesDialog,
        setSkuPricesDialog,
        setSkuPriceDialog,
        handleClose,
        currentAuthToken
    } = props;

    const {skuStore, priceStore} = useStores()

    const [addPriceValidation, setAddPriceValidation] = React.useState({
        locationId: '',
        price: ''
    })

    const handlePriceRemove = async (id: number) => {
        await priceStore.remove({priceId: id}, currentAuthToken)
        await refreshSkuPrices()
    }

    const handlePriceEdit = (price) => {
        setSkuPricesDialog({...skuPricesDialog, isOpen: false})
        setSkuPriceDialog({
            isOpen: true,
            formData: {
                priceId: price.priceId,
                locationId: price.locationId,
                skuId: skuPricesDialog.skuId,
                priceAmount: price.priceAmount
            },
            isLoading: false
        })
    }

    const getSkuPrices = async () => {
        console.log('getSkuPrices.skuPricesDialog', skuPricesDialog)
        return await priceStore.findAll({
            skuId: skuPricesDialog.skuId,
            page: 0,
            perPage: 100
        }, currentAuthToken, false)

    }

    const refreshSkuPrices = async () => {
        const result = await getSkuPrices()

        if (result.kind === 'ok') {
            console.log('getSkuPrices.result', result)
            setSkuPricesDialog({
                ...skuPricesDialog,
                prices: result.prices,
            })
        }
    }

    const addPriceValidate = () => {
        const validationObject = {...addPriceValidation}
        let isValid = true
        if (isEmpty(skuPricesDialog.formData.locationId)) {
            validationObject.locationId = "This field is required."
            isValid = false
        }
        if (isEmpty(skuPricesDialog.formData.price)) {
            validationObject.price = "This field is required."
            isValid = false
        }
        setAddPriceValidation(validationObject)
        return isValid;
    }

    const handleAddSkuPrice = async () => {

        if (!addPriceValidate()) {
            return
        }

        setAddPriceValidation({
            locationId: '',
            price: ''
        })

        setSkuPricesDialog({
            ...skuPricesDialog,
            isLoading: true
        })

        const findAllResult = await priceStore.findAll({
            skuId: skuPricesDialog.skuId,
            locationId: skuPricesDialog.formData.locationId,
            page: 0,
            perPage: 100
        }, currentAuthToken, false)

        if (
            findAllResult.kind === 'ok' &&
            findAllResult.prices &&
            findAllResult.prices.length > 0) {
            await priceStore.update({
                priceId: findAllResult.prices[0].priceId,
                priceAmount: skuPricesDialog.formData.price,
            }, currentAuthToken)

        } else {
            await priceStore.create({
                skuId: skuPricesDialog.skuId,
                locationId: skuPricesDialog.formData.locationId,
                priceAmount: skuPricesDialog.formData.price,
            }, currentAuthToken)
        }


        const result = await getSkuPrices()

        if (result.kind === 'ok') {
            console.log('getSkuPrices.result', result)
            setSkuPricesDialog({
                ...skuPricesDialog,
                prices: result.prices,
                formData: {
                    locationId: '',
                    price: ''
                },
                isLoading: false
            })
        } else {
            setSkuPricesDialog({
                ...skuPricesDialog,
                isLoading: false
            })
        }
    }

    /**
     * Initiates the first events which happen inside this function
     */
    useEffect(() => {
        if (!lastIsOpenStatus && skuPricesDialog.isOpen === true) {
            lastIsOpenStatus = true
            refreshSkuPrices()
        } else {
            lastIsOpenStatus = false
        }
    }, [skuPricesDialog])

    return (
        <CDialog open={skuPricesDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
            sx: {
                borderRadius: "37px",
                overflowX: 'hidden'
            }
        }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4} mr={2}>
                    {skuPricesDialog?.sku?.skuTitle} Prices
                </CTypography>

                {/*<CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">*/}
                {/*    <CTab label="General" {...a11yProps(0)} />*/}
                {/*    <CTab label="Images" {...a11yProps(1)} />*/}
                {/*    /!*<CTab label="Prices" {...a11yProps(2)} />*!/*/}
                {/*</CTabs>*/}
            </CBox>
            <Divider></Divider>
            {skuStore.isGettingSkuVarieties && <CLinearProgress></CLinearProgress>}
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={5}>
                    <CTextField fullWidth label={"Store"}
                                select
                                error={hasValue(addPriceValidation.locationId)}
                                helperText={hasValue(addPriceValidation.locationId) ? addPriceValidation.locationId : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuPricesDialog({
                                        ...skuPricesDialog,
                                        formData: {
                                            ...skuPricesDialog.formData,
                                            locationId: event.target.value
                                        }
                                    });
                                    if (hasValue(addPriceValidation.locationId)) {
                                        setAddPriceValidation({...addPriceValidation, locationId: ''})
                                    }
                                }} value={skuPricesDialog.formData.locationId}>
                        {stores.map(store => {
                            return <CMenuItem
                                value={store.locationId}>{store.locationName}</CMenuItem>
                        })}
                    </CTextField>
                </CGrid>
                <CGrid item xs={5}>
                    <CTextField fullWidth label={"Price"}
                                error={hasValue(addPriceValidation.price)}
                                helperText={hasValue(addPriceValidation.price) ? addPriceValidation.price : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuPricesDialog({
                                        ...skuPricesDialog,
                                        formData: {
                                            ...skuPricesDialog.formData,
                                            price: event.target.value
                                        }
                                    });
                                    if (hasValue(addPriceValidation.price)) {
                                        setAddPriceValidation({...addPriceValidation, price: ''})
                                    }
                                }} value={skuPricesDialog.formData.price}/>
                </CGrid>
                <CGrid item xs={2}>
                    <CButton fullWidth variant={"contained"} onClick={handleAddSkuPrice}
                             sx={{mr: 4, height: '100%'}}>
                        {skuPricesDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                        size={24}/> : 'Save'}
                    </CButton>
                </CGrid>
            </CGridContainer>
            <CGridContainer spacing={2} p={0}>
                <CGrid item xs={12} maxHeight={'65vh'} maxWidth={'100%'} sx={{
                    overflowY: 'scroll',
                }}>
                    <CTable aria-label="simple table">
                        <CTableHead>
                            <CTableRow>
                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="left">ID</CTableCell>
                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="left">Store</CTableCell>

                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="left">Price</CTableCell>
                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="right">Actions</CTableCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {skuPricesDialog?.prices && skuPricesDialog.prices.map((row, index) => (
                                <CInterleavedTableRow
                                    key={row.priceId + index}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <CTableCell component="th" scope="row" sx={{
                                        paddingLeft: "24px",
                                        // flexDirection: "row",
                                        // display: "flex",
                                        width: "80px"
                                    }}>
                                        <CTypography>{
                                            row.priceId
                                        }</CTypography>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        paddingRight: "32px"
                                    }} align="left">
                                        <CTypography>{
                                            row?.location?.locationName
                                        }</CTypography>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        paddingRight: "32px"
                                    }} align="left">
                                        <CTypography>$ {
                                            row.priceAmount?.toFixed(2)
                                        }</CTypography>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        paddingRight: "32px"
                                    }} align="right">
                                        <CButton variant={"contained"} size={"small"}
                                                 sx={{height: '32px', mr: 2}}
                                                 onClick={() => handlePriceEdit(row)}>Edit</CButton>

                                        <CButton variant={"contained"} color={"error"} size={"small"}
                                                 sx={{height: '32px'}}
                                                 onClick={() => handlePriceRemove(row.priceId)}>Remove</CButton>
                                    </CTableCell>
                                </CInterleavedTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </CGrid>

            </CGridContainer>
            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
            </CBox>
        </CDialog>
    )
}