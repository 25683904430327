import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {LocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const AvailabilityModel = types.model("Availability").props({
    availabilityId: types.number,
    skuId: types.number,
    locationId: types.maybeNull(types.number),
    availabilityCount: types.maybeNull(types.number),
    // availabilityStatus: types.maybeNull(types.string),
    // availabilityCreatedAt: types.maybeNull(types.string),
    // availabilityUpdatedAt: types.maybeNull(types.string),
    location: types.optional(types.maybeNull(LocationModel),{locationId: -1}),
})

type AvailabilityType = Instance<typeof AvailabilityModel>
export interface Availability extends AvailabilityType {}
type AvailabilitySnapshotType = SnapshotOut<typeof AvailabilityModel>
export interface AvailabilitySnapshot extends AvailabilitySnapshotType {}