import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CTypography,
} from "../../../components";

export const WIP = observer(
    function WIP() {

        return (
            <>
                <main style={{minHeight: '100vh'}}>

                    <CTypography>
                        WIP
                    </CTypography>

                </main>
            </>
        );
    }
)
