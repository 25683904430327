import {ApiResponse} from "apisauce"
import {Api} from "./api"
import {
    StoreItemAvailabilityFindOneParams, StoreItemAvailabilityFindOneResult,
} from "./api.types"
import {getGeneralApiProblem} from "./api-problem"
// import {recordError, recordServiceRequestLog, recordServiceResponseStatus} from "../../utils/firebase/crashlytics";

// const API_PAGE_SIZE = 50

export class StoreItemAvailabilityApi {
    private api: Api

    constructor(api: Api) {
        this.api = api
    }

    async findOne(params: StoreItemAvailabilityFindOneParams): Promise<StoreItemAvailabilityFindOneResult> {
        try {
            // const functionName = "login"
            const uri = "store-item-availability/"+params.availabilityId
            // recordServiceRequestLog(uri, functionName, params)
            // make the api call
            const response: ApiResponse<any> = await this.api.apisauce.get(uri, params)
            // the typical ways to die when calling an api
            if (!response.ok) {
                const problem = getGeneralApiProblem(response)
                if (problem) {
                    // recordServiceResponseStatus(uri, functionName, params, problem.kind,"post")
                    return problem
                }
            }

            const availability = response.data

            // recordServiceResponseStatus(uri, functionName, params, "ok", "post")
            return {kind: "ok", availability}
        } catch (e) {
            // recordError(e)
            return {kind: "bad-data"}
        }
    }

}
