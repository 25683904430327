import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {SkuModel} from "../sku/sku";
import {UserModel} from "../user/user";
import {LocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const InventoryModel = types.model("Inventory").props({
    inventoryId: types.number,
    skuId: types.number,
    skuQuantity: types.maybeNull(types.number),
    inventoryTs: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    locationId: types.maybeNull(types.number),
    inventoryStatus: types.maybeNull(types.string),
    inventoryCreatedAt: types.maybeNull(types.string),
    inventoryUpdatedAt: types.maybeNull(types.string),
    sku: types.optional(types.maybeNull(SkuModel),{skuId: -1}),
    location: types.optional(types.maybeNull(LocationModel),{locationId: -1}),
    user: types.optional(types.maybeNull(UserModel),{userId: -1})
})

type InventoryType = Instance<typeof InventoryModel>
export interface Inventory extends InventoryType {}
type InventorySnapshotType = SnapshotOut<typeof InventoryModel>
export interface InventorySnapshot extends InventorySnapshotType {}

export const defaultInventory = {
    inventoryId: undefined,
    skuId: undefined,
    skuQuantity: undefined,
    // inventoryTs: '',
    userId: undefined,
    locationId: undefined,
    inventoryStatus: 'delivered',
}