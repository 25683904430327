export const currentDomain = () => {
    // return 'calibarco.com'
    const removedProtocol = /:\/\/([^\/]+)/.exec(window.location.href);
    if (removedProtocol) {
        const dotArray = removedProtocol[1].split('.')
        if(dotArray[0] === 'www') {
            return dotArray[1]+'.'+dotArray[2].split(':')[0]
        } else {
            return dotArray[0]+'.'+dotArray[1].split(':')[0]
        }
    } else {
        return 'calexpress'
    }
}