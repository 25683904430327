import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CCard,
    CustomerDialog,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import {OrderUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultOrder} from "../../../models/order/order";
import {isEmpty} from "validate.js";
import {hasValue} from "../../../utils/empty-check";
import {useEffect, useRef} from "react";
import {Dayjs} from "dayjs";
import {OrdersTable} from "../../../components/orders-table";
import {Payment} from "../../../models/payment/payment";
import {OrderDialog} from "../../../components/order-dialog";
import {getSkuAvailability} from "../../../utils/sku";
import {OrdersHeader} from "../../../components/orders-header";

interface OrderDialogData {
    isOpen: boolean,
    data: OrderUpdateParams,
    isLoading: boolean
}

let currentAuthToken = ''

export const StorePanelOrders = observer(
    function StorePanelOrders() {
        const navigate = useNavigate();

        const {authStore, orderStore, orderDetailStore, paymentStore} = useStores()

        const [orderNoFilter, setOrderNoFilter] = React.useState('');
        const [orderTransIdFilter, setOrderTransIdFilter] = React.useState('');
        const [orderStatusFilter, setOrderStatusFilter] = React.useState('received');
        const [orderCustomerFullNameFilter, setOrderCustomerFullNameFilter] = React.useState('');
        const [orderCardNoFilter, setOrderCardNoFilter] = React.useState('');
        const [orderBillingNameFilter, setOrderBillingNameFilter] = React.useState('');

        const [orderDialog, setOrderDialog] = React.useState<OrderDialogData>({
            isOpen: false,
            data: defaultOrder,
            isLoading: false
        });
        const [isCustomerDialogOpen, setIsCustomerDialogOpen] = React.useState(false);
        const [customerDialogData, setCustomerDialogData] = React.useState({
            id: 0,
            customerType: 'guest'
        });

        const [validation, setValidation] = React.useState({
            order: '',
            status: '',
            total: '',
            kiosk: '',
            lockbox: ''
        })
        const [fromDate, setFromDate] = React.useState<Dayjs | null>(null)
        const [toDate, setToDate] = React.useState<Dayjs | null>(null)
        const [orderPayments, setOrderPayments] = React.useState<Payment[]>([])
        const [isRefreshing, setIsRefreshing] = React.useState(false)
        const [isPaymentDialogOpen, setIsPaymentDialogOpen] = React.useState(false)

        const ordersTableRef = useRef(null);

        const refresh = () => {
            if (ordersTableRef.current) {
                // @ts-ignore
                ordersTableRef.current.refreshContents();
            }
        }

        const handleClickOpen = async (order?: OrderUpdateParams) => {
            setOrderDialog({
                ...orderDialog,
                isOpen: true,
                data: order ? {...order} : defaultOrder
            });
            await orderDetailStore.findAll({
                orderId: Number(order?.orderId)
            }, currentAuthToken)
            const result = await paymentStore.findAll({
                orderId: Number(order?.orderId)
            }, currentAuthToken, false)
            if (result.kind === 'ok') {
                setOrderPayments(result.payments)
            }
        };

        const handleCustomerDetailsClickOpen = async (order) => {
            const customer = order.customer
            const user = order.user
            if (hasValue(customer?.customerFname)) {
                setCustomerDialogData({
                    id: customer?.customerId,
                    customerType: 'guest'
                })
                setIsCustomerDialogOpen(true)
            } else if (hasValue(user?.userDetail)) {
                setCustomerDialogData({
                    id: user?.userId,
                    customerType: 'registered'
                })
                setIsCustomerDialogOpen(true)
            }
        };

        const handleClose = () => {
            setOrderDialog({isOpen: false, data: defaultOrder, isLoading: false});
        };

        const handleSubmit = async (newStatus?: string) => {
            try {


                if (!validate()) {
                    return
                }

                setOrderDialog({
                    ...orderDialog,
                    isLoading: true
                });

                // @ts-ignore
                delete orderDialog.data.orderDetails
                // @ts-ignore
                delete orderDialog.data.user
                // @ts-ignore
                delete orderDialog.data.lockbox
                // @ts-ignore
                delete orderDialog.data.kiosk
                // @ts-ignore
                delete orderDialog.data.customer
                // @ts-ignore
                delete orderDialog.data.orderTotalItems
                // @ts-ignore
                delete orderDialog.data.orderTotalGross

                let updateData
                if (newStatus) {
                    updateData = {
                        orderId: orderDialog.data.orderId,
                        orderStatus: newStatus
                    }
                } else {
                    updateData = {
                        ...orderDialog.data
                    }
                }
                console.log('handleSubmit.status', newStatus)
                console.log('handleSubmit.updateData', updateData)

                await orderStore.update(updateData, currentAuthToken)

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(orderDialog.data.orderStatus)) {
                validationObject.status = "This field is required."
                isValid = false
            }
            if (isEmpty(orderDialog.data.orderTotal)) {
                validationObject.total = "This field is required."
                isValid = false
            }
            if (isEmpty(orderDialog.data.kioskId)) {
                validationObject.kiosk = "This field is required."
                isValid = false
            }
            if (isEmpty(orderDialog.data.lockboxId)) {
                validationObject.lockbox = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const checkAuth = () => {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Store Admin Panel - Orders';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        function skuCompleteName(sku) {
            if (sku.skuHasParent === 1) {
                if (sku.skuCode) {
                    return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
                } else {
                    return `${sku.vendor?.vendorName} - ${sku.parentSku?.skuTitle} - ${sku.skuTitle}`
                }
            } else {
                if (sku.skuCode) {
                    return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
                } else {
                    return `${sku.vendor?.vendorName} - ${sku.skuTitle}`
                }
            }
        }

        function createOrderDetailData(
            id: number,
            code: string,
            name: string,
            quantity: number,
            price: number,
            availability: number
        ) {
            return {id, code, name, quantity, price, availability};
        }

        const orderDetailRows = orderDetailStore.orderDetails.map(
            orderDetail =>
                createOrderDetailData(
                    orderDetail.orderDetailId,
                    String(orderDetail.sku?.skuCode),
                    skuCompleteName(orderDetail.sku),
                    Number(orderDetail.skuQuantity),
                    Number(orderDetail.skuPrice),
                    getSkuAvailability(orderDetail.sku, orderDialog.data.lockboxId)
                )
        )
        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <OrdersHeader
                                refresh={refresh}
                                isRefreshing={isRefreshing}
                                fromDate={fromDate}
                                setFromDate={setFromDate}
                                toDate={toDate}
                                setToDate={setToDate}
                                orderStatusFilter={orderStatusFilter}
                                setOrderStatusFilter={setOrderStatusFilter}
                                setOrderNoFilter={setOrderNoFilter}
                                orderNoFilter={orderNoFilter}
                                setOrderTransIdFilter={setOrderTransIdFilter}
                                orderTransIdFilter={orderTransIdFilter}
                                setOrderCustomerFullNameFilter={setOrderCustomerFullNameFilter}
                                orderCustomerFullNameFilter={orderCustomerFullNameFilter}
                                setOrderCardNoFilter={setOrderCardNoFilter}
                                orderCardNoFilter={orderCardNoFilter}
                                setOrderBillingNameFilter={setOrderBillingNameFilter}
                                orderBillingNameFilter={orderBillingNameFilter}
                            />
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "16px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "20px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "16px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <OrdersTable
                                handleClickOpen={handleClickOpen}
                                handleCustomerDetailsClickOpen={handleCustomerDetailsClickOpen}
                                orderNoFilter={orderNoFilter}
                                orderTransIdFilter={orderTransIdFilter}
                                orderCustomerFullNameFilter={orderCustomerFullNameFilter}
                                orderCardNoFilter={orderCardNoFilter}
                                orderBillingNameFilter={orderBillingNameFilter}
                                orderStatusFilter={orderStatusFilter}
                                fromDate={fromDate}
                                toDate={toDate}
                                module={'store admin'}
                                ref={ordersTableRef}
                                autoRefresh={false}
                                onRefreshingChange={(val) => setIsRefreshing(val)}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <OrderDialog
                    orderDialog={orderDialog}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                    handleSaveChanges={handleSubmit}
                    orderDetailRows={orderDetailRows}
                    validation={validation}
                    setOrderDialog={setOrderDialog}
                    setValidation={setValidation}
                    orderPayments={orderPayments}
                    module={'store admin'}
                    handlePaymentDialogOpen={()=>{
                        setOrderDialog({
                            ...orderDialog,
                            isOpen: false
                        })
                        setIsPaymentDialogOpen(true)
                    }}
                />
                <CustomerDialog
                    isOpen={isCustomerDialogOpen}
                    onClose={() => {
                        setIsCustomerDialogOpen(false)
                    }}
                    id={customerDialogData.id}
                    customerType={customerDialogData.customerType}
                    authToken={currentAuthToken}
                    module={'store admin'}
                />
            </>
        );
    }
)
