import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid} from "../../../components";
import {Refresh} from "@mui/icons-material";
import {useEffect} from "react";
import {useStores} from "../../../models";
import {defaultLocation, Location} from "../../../models/location/location";
import {LocationCreateParams, LocationSettingUpdateParams, LocationUpdateParams} from "../../../services/api";
import LinearProgress from "@mui/material/LinearProgress";
import {isEmpty} from "validate.js";
import {defaultLocationSetting} from "../../../models/location-setting/location-setting";
import {useNavigate} from "react-router-dom";
import {User} from "../../../models/user/user";
import {BasicBundle, BasicBundleSnapshot} from "../../../models/basic-bundle/basic-bundle";
import {Sku} from "../../../models/sku/sku";
import {Dayjs} from "dayjs";
import {PromotionalBanner, PromotionalBannerSnapshot} from "../../../models/promotional-banner/promotional-banner";
import {LocationSettings} from "../../../components/location-settings-dialog";
import {LocationDialog} from "../../../components/location-dialog";
import {PromotionalBannerDialog} from "../../../components/promotional-banner-dialog";
import {BasicBundlesDialog} from "../../../components/basic-bundles-dialog";
import {LockboxesDialog} from "../../../components/lockboxes-dialog";
import {uploadImage} from "../../../utils/firebase-storage";
import {ensureString} from "../../../utils/type";
import {stringToDayjs} from "../../../utils/date-time";

const containerStyle = {
    width: '100%',
    height: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

interface LocationDialogData {
    isOpen: boolean,
    data: LocationCreateParams | LocationUpdateParams,
    mode: string,
    isLoading: boolean
}

interface LocationSettingDialogData {
    isOpen: boolean,
    data: LocationSettingUpdateParams,
    isLoading: boolean
}

interface LockboxesDialogData {
    isOpen: boolean,
    data: Location[],
    isLoading: boolean,
    storeId: number | null,
    lockboxId: number | null,
    storeName: string
}

interface BasicBundlesDialogData {
    isOpen: boolean,
    data: BasicBundle[],
    isLoading: boolean,
    storeId: number | null,
    lockboxId: number | null,
    storeName: string,
    mode: string;
    formData: {
        basicBundleId?: number,
        basicBundleSkuId: number | null,
        basicBundleTitle: string,
        basicBundlePaidCount: number | null,
        basicBundleFreeCount: number | null,
        basicBundleDescriptions: string,
        basicBundleValidFrom: Dayjs | null,
        basicBundleValidTo: Dayjs | null,
    }
}

interface PromotionalBannersDialogData {
    isOpen: boolean,
    data: PromotionalBanner[],
    isLoading: boolean,
    storeId: number | null,
    storeName: string,
    mode: string;
    formData: {
        promotionalBannerId?: number,
        promotionalBannerTitle: string,
        promotionalBannerDescriptions: string,
        promotionalBannerValidFrom: Dayjs | null,
        promotionalBannerValidTo: Dayjs | null,
        promotionalBannerBgColor: string,
        promotionalBannerTitleColor: string,
        promotionalBannerSubtitleColor: string,
    }
}

let currentAuthToken = ''
let previousStoreAdminId = 0

export const AdminPanelStores = observer(
    function AdminPanelStores() {

        const navigate = useNavigate();

        const {
            authStore,
            locationStore,
            locationSettingStore,
            gatewayStore,
            userStore,
            locationStoreLockboxStore,
            basicBundleStore,
            skuStore,
            promotionalBannerStore
        } = useStores()
        const {locations} = locationStore
        const {gateways} = gatewayStore

        const [locationDialog, setLocationDialog] = React.useState<LocationDialogData>({
            isOpen: false,
            data: defaultLocation,
            mode: 'add',
            isLoading: false
        });
        const [locationSettingDialog, setLocationSettingDialog] = React.useState<LocationSettingDialogData>({
            isOpen: false,
            data: defaultLocationSetting,
            isLoading: false
        });

        const [lockboxesDialog, setLockboxesDialog] = React.useState<LockboxesDialogData>({
            isOpen: false,
            data: [],
            isLoading: false,
            storeId: null,
            lockboxId: null,
            storeName: ''
        });

        const [basicBundlesDialog, setBasicBundlesDialog] = React.useState<BasicBundlesDialogData>({
            isOpen: false,
            data: [],
            isLoading: false,
            storeId: null,
            lockboxId: null,
            storeName: '',
            mode: 'create',
            formData: {
                basicBundleSkuId: null,
                basicBundleTitle: '',
                basicBundlePaidCount: null,
                basicBundleFreeCount: null,
                basicBundleDescriptions: '',
                basicBundleValidFrom: null,
                basicBundleValidTo: null,
            }
        });

        const [promotionalBannersDialog, setPromotionalBannersDialog] = React.useState<PromotionalBannersDialogData>({
            isOpen: false,
            data: [],
            isLoading: false,
            storeId: null,
            storeName: '',
            mode: 'create',
            formData: {
                promotionalBannerTitle: '',
                promotionalBannerDescriptions: '',
                promotionalBannerValidFrom: null,
                promotionalBannerValidTo: null,
                promotionalBannerBgColor: '',
                promotionalBannerTitleColor: '',
                promotionalBannerSubtitleColor: '',
            }
        });


        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [isUploadingLogo, setIsUploadingLogo] = React.useState(false);

        const [map, setMap] = React.useState(null)
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            name: '',
            storeType: '',
            admin: '',
            email: '',
            phoneNumber: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: ''
        })
        const [storeAdmins, setStoreAdmins] = React.useState<User[]>([])
        const [isCreatingLocationStoreLockbox, setIsCreatingLocationStoreLockbox] = React.useState<boolean>(false)
        const [isRemovingLocationStoreLockbox, setIsRemovingLocationStoreLockbox] = React.useState<boolean>(false)
        const [lockboxes, setLockboxes] = React.useState<Location[]>([])
        const [isGettingLockboxes, setIsGettingLockboxes] = React.useState<boolean>(false)
        const [isRemovingBasicBundle, setIsRemovingBasicBundle] = React.useState<boolean>(false)
        const [isRemovingPromotionalBanner, setIsRemovingPromotionalBanner] = React.useState<boolean>(false)
        const [isCreatingBasicBundle, setIsCreatingBasicBundle] = React.useState<boolean>(false)
        const [isCreatingPromotionalBanner, setIsCreatingPromotionalBanner] = React.useState<boolean>(false)
        const [parentSkus, setParentSkus] = React.useState<Sku[]>([])
        const [isFromDateCalendarOpen, setIsFromDateCalendarOpen] = React.useState<boolean>(false)
        const [isToDateCalendarOpen, setIsToDateCalendarOpen] = React.useState<boolean>(false)
        const [isPromotionalBannerFromDateCalendarOpen, setIsPromotionalBannerFromDateCalendarOpen] = React.useState<boolean>(false)
        const [isPromotionalBannerToDateCalendarOpen, setIsPromotionalBannerToDateCalendarOpen] = React.useState<boolean>(false)


        const handleClickOpen = (mode: string, location?: LocationCreateParams | LocationUpdateParams) => {
            if (location?.locationAdminId !== null && location?.locationAdminId !== undefined) {
                previousStoreAdminId = location?.locationAdminId
            } else {
                previousStoreAdminId = 0
            }
            setLocationDialog({
                ...locationDialog,
                isOpen: true,
                mode,
                data: location ? {...location} : {...defaultLocation, locationTypeId: 1}
            });
        };

        const handleClickLocationSettingOpen = (locationSetting?: LocationSettingUpdateParams) => {
            setLocationSettingDialog({
                ...locationSettingDialog,
                isOpen: true,
                data: locationSetting ? {...locationSetting} : defaultLocationSetting
            });
        };

        const fetchLockboxes = async () => {
            setIsGettingLockboxes(true)
            const result = await locationStore.findAll({
                locationTypeId: 2
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                setLockboxes(result.locations)
            }
            setIsGettingLockboxes(false)
        }

        const handleClickLockboxesOpen = (locationLockboxes: Location[], locationId, locationName) => {
            fetchLockboxes()
            setLockboxesDialog({
                ...lockboxesDialog,
                isOpen: true,
                data: locationLockboxes ? [...locationLockboxes] : [],
                storeId: locationId,
                storeName: locationName
            });
        }

        const handleClickBundlesOpen = async (basicBundles: BasicBundle[], locationId, locationName) => {
            const checkedBasicBundles: BasicBundle[] = []
            if (basicBundles) {
                basicBundles.forEach(basicBundle => {
                    if (basicBundle.basicBundleDeleted === 0) {
                        checkedBasicBundles.push(basicBundle)
                    }
                })
            }
            setBasicBundlesDialog({
                ...basicBundlesDialog,
                isOpen: true,
                data: checkedBasicBundles ? [...checkedBasicBundles] : [],
                storeId: locationId,
                storeName: locationName
            });
            const result = await skuStore.findAll({
                skuHasParent: 0
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                setParentSkus([...result.skus])
            }

        }

        const checkPromotionalBanners = (promotionalBanners: PromotionalBanner[]): PromotionalBanner[] => {
            const checkedPromotionalBanners: PromotionalBanner[] = []
            if (promotionalBanners) {
                promotionalBanners.forEach(promotionalBanner => {
                    if (promotionalBanner.promotionalBannerDeleted === 0) {
                        checkedPromotionalBanners.push(promotionalBanner)
                    }
                })
            }
            return checkedPromotionalBanners
        }

        const handleClickBannersOpen = async (promotionalBanners: PromotionalBanner[], locationId, locationName) => {
            const checkedPromotionalBanners = checkPromotionalBanners(promotionalBanners)
            setPromotionalBannersDialog({
                ...promotionalBannersDialog,
                isOpen: true,
                data: checkedPromotionalBanners ? [...checkedPromotionalBanners] : [],
                storeId: locationId,
                storeName: locationName
            });
        }

        const handleClose = () => {
            setLocationDialog({isOpen: false, data: defaultLocation, mode: 'add', isLoading: false});
            setLocationSettingDialog({isOpen: false, data: defaultLocationSetting, isLoading: false});
            setLockboxesDialog({
                isOpen: false,
                data: [],
                isLoading: false,
                storeId: null,
                lockboxId: null,
                storeName: ''
            });
            setBasicBundlesDialog({
                isOpen: false,
                data: [],
                isLoading: false,
                storeId: null,
                lockboxId: null,
                storeName: '',
                mode: 'create',
                formData: {
                    basicBundleSkuId: null,
                    basicBundleTitle: '',
                    basicBundlePaidCount: null,
                    basicBundleFreeCount: null,
                    basicBundleDescriptions: '',
                    basicBundleValidFrom: null,
                    basicBundleValidTo: null,
                }
            });

            setPromotionalBannersDialog({
                isOpen: false,
                data: [],
                isLoading: false,
                storeId: null,
                storeName: '',
                mode: 'create',
                formData: {
                    promotionalBannerTitle: '',
                    promotionalBannerDescriptions: '',
                    promotionalBannerValidFrom: null,
                    promotionalBannerValidTo: null,
                    promotionalBannerBgColor: '',
                    promotionalBannerTitleColor: '',
                    promotionalBannerSubtitleColor: '',
                }
            });

        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }

                // @ts-ignore
                const newCenter = map.getCenter();
                locationDialog.data.locationLat = newCenter.lat().toFixed(8)
                locationDialog.data.locationLong = newCenter.lng().toFixed(8)

                setLocationDialog({
                    ...locationDialog,
                    isLoading: true
                });

                let locationId;

                if (locationDialog.mode === 'add') {
                    const result = await locationStore.create({
                        ...locationDialog.data
                    }, currentAuthToken)
                    locationId = Number(result.location.identifiers[0].locationId)
                    if (locationDialog.data.locationTypeId === 1) {
                        const locationSettingResult = await locationSettingStore.create({
                            // @ts-ignore
                            locationId: locationId,
                            locationSettingLogo: '',
                            locationSettingColor: '#083B1C'
                        }, currentAuthToken)
                        await locationStore.update({
                            // @ts-ignore
                            locationId: locationId,
                            // @ts-ignore
                            locationSettingId: Number(locationSettingResult.locationSetting.identifiers[0].locationSettingId)
                        }, currentAuthToken)
                    }
                } else if (locationDialog.mode === 'edit') {
                    if (
                        locationDialog.data?.locationAdminId !== null &&
                        locationDialog.data?.locationAdminId !== undefined &&
                        locationDialog.data?.locationAdminId > 0
                    ) {
                        const locationResult = await locationStore.findAll({
                            locationAdminId: locationDialog.data?.locationAdminId
                        }, currentAuthToken, false)

                        if (locationResult.kind === 'ok') {
                            locationResult.locations.forEach(async item => {
                                return await locationStore.update({
                                    locationId: item.locationId,
                                    locationAdminId: 0
                                }, currentAuthToken)
                            })
                        }
                    }
                    await locationStore.update({
                        ...locationDialog.data
                    }, currentAuthToken)
                    // @ts-ignore
                    locationId = Number(locationDialog.data.locationId)
                }

                if (
                    locationDialog.data?.locationAdminId !== null &&
                    locationDialog.data?.locationAdminId !== undefined &&
                    locationDialog.data?.locationAdminId > 0
                ) {
                    if (previousStoreAdminId > 0) {
                        await userStore.update({
                            // @ts-ignore
                            userId: previousStoreAdminId,
                            // @ts-ignore
                            userLocationId: 0
                        }, currentAuthToken)
                    }
                    await userStore.update({
                        // @ts-ignore
                        userId: Number(locationDialog.data.locationAdminId),
                        // @ts-ignore
                        userLocationId: locationId
                    }, currentAuthToken)
                } else if (previousStoreAdminId > 0) {
                    await userStore.update({
                        // @ts-ignore
                        userId: previousStoreAdminId,
                        // @ts-ignore
                        userLocationId: 0
                    }, currentAuthToken)
                }

                await refresh()
                await getStoreAdmins()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleLocationStoreLockboxCreate = async () => {
            if (!lockboxesDialog.storeId || !lockboxesDialog.lockboxId) {
                return
            }
            setIsCreatingLocationStoreLockbox(true)
            const locationStoreLockboxResult = await locationStoreLockboxStore.findAll({
                storeId: lockboxesDialog.storeId,
                lockboxId: lockboxesDialog.lockboxId
            }, currentAuthToken)
            if (locationStoreLockboxResult.kind === "ok") {
                if (locationStoreLockboxResult.locationStoreLockboxes.length > 0) {
                    setIsCreatingLocationStoreLockbox(false)
                    return
                }
            }
            await locationStoreLockboxStore.create({
                storeId: lockboxesDialog.storeId,
                lockboxId: lockboxesDialog.lockboxId
            }, currentAuthToken)
            const result = await locationStore.findOne({
                locationId: lockboxesDialog.storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                setLockboxesDialog({
                    ...lockboxesDialog,
                    data: result.location.lockboxes
                })
            }
            await refresh()
            setIsCreatingLocationStoreLockbox(false)
        }

        const handleLocationStoreLockboxRemove = async (lockboxId: number) => {
            if (!lockboxesDialog.storeId) {
                return
            }
            setIsRemovingLocationStoreLockbox(true)
            const locationStoreLockboxResult = await locationStoreLockboxStore.findAll({
                storeId: lockboxesDialog.storeId,
                lockboxId
            }, currentAuthToken)
            if (locationStoreLockboxResult.kind === "ok") {
                if (locationStoreLockboxResult.locationStoreLockboxes.length > 0) {
                    await locationStoreLockboxStore.remove({
                        locationStoreLockboxId: locationStoreLockboxResult.locationStoreLockboxes[0].locationStoreLockboxId
                    }, currentAuthToken)
                }
            }
            const result = await locationStore.findOne({
                locationId: lockboxesDialog.storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                setLockboxesDialog({
                    ...lockboxesDialog,
                    data: result.location.lockboxes
                })
            }
            await refresh()
            setIsRemovingLocationStoreLockbox(false)
        }

        const handleBasicBundleCreate = async () => {
            if (
                !basicBundlesDialog.storeId ||
                basicBundlesDialog.formData.basicBundleFreeCount === null ||
                basicBundlesDialog.formData.basicBundlePaidCount === null
            ) {
                return
            }
            setIsCreatingBasicBundle(true)

            if (basicBundlesDialog.mode === 'create') {
                // @ts-ignore
                await basicBundleStore.create({
                    ...basicBundlesDialog.formData,
                    basicBundleValidFrom: basicBundlesDialog.formData.basicBundleValidFrom?.toISOString(),
                    basicBundleValidTo: basicBundlesDialog.formData.basicBundleValidTo?.toISOString(),
                    basicBundleStoreId: basicBundlesDialog.storeId
                }, currentAuthToken)

            } else {
                // @ts-ignore
                await basicBundleStore.update({
                    ...basicBundlesDialog.formData,
                    basicBundleValidFrom: basicBundlesDialog.formData.basicBundleValidFrom?.toISOString(),
                    basicBundleValidTo: basicBundlesDialog.formData.basicBundleValidTo?.toISOString(),
                }, currentAuthToken)

            }
            const result = await locationStore.findOne({
                locationId: basicBundlesDialog.storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedBasicBundles: BasicBundle[] = []
                if (result.location.basicBundles) {
                    result.location.basicBundles.forEach(basicBundle => {
                        if (basicBundle.basicBundleDeleted === 0) {
                            checkedBasicBundles.push(basicBundle)
                        }
                    })
                }
                setBasicBundlesDialog({
                    ...basicBundlesDialog,
                    data: checkedBasicBundles,
                    mode: 'create',
                    formData: {
                        basicBundleSkuId: null,
                        basicBundleTitle: '',
                        basicBundlePaidCount: null,
                        basicBundleFreeCount: null,
                        basicBundleDescriptions: '',
                        basicBundleValidFrom: null,
                        basicBundleValidTo: null,
                    }
                })
            }
            await refresh()
            setIsCreatingBasicBundle(false)
        }

        const handleBasicBundleInactivate = async (basicBundleId: number, storeId: number) => {

            setIsRemovingBasicBundle(true)
            await basicBundleStore.update({
                basicBundleId,
                basicBundleStatus: 'inactive'
            }, currentAuthToken)

            const result = await locationStore.findOne({
                locationId: storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedBasicBundles: BasicBundle[] = []
                if (result.location.basicBundles) {
                    result.location.basicBundles.forEach(basicBundle => {
                        if (basicBundle.basicBundleDeleted === 0) {
                            checkedBasicBundles.push(basicBundle)
                        }
                    })
                }
                setBasicBundlesDialog({
                    ...basicBundlesDialog,
                    data: checkedBasicBundles,
                })
            }
            await refresh()
            setIsRemovingBasicBundle(false)
        }

        const handleBasicBundleActivate = async (basicBundleId: number, storeId: number) => {

            setIsRemovingBasicBundle(true)
            await basicBundleStore.update({
                basicBundleId,
                basicBundleStatus: 'active'
            }, currentAuthToken)

            const result = await locationStore.findOne({
                locationId: storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedBasicBundles: BasicBundle[] = []
                if (result.location.basicBundles) {
                    result.location.basicBundles.forEach(basicBundle => {
                        if (basicBundle.basicBundleDeleted === 0) {
                            checkedBasicBundles.push(basicBundle)
                        }
                    })
                }
                setBasicBundlesDialog({
                    ...basicBundlesDialog,
                    data: checkedBasicBundles,
                })
            }
            await refresh()
            setIsRemovingBasicBundle(false)
        }

        const handlePromotionalBannerCreate = async () => {
            if (
                !promotionalBannersDialog.storeId
            ) {
                return
            }
            setIsCreatingPromotionalBanner(true)

            if (promotionalBannersDialog.mode === 'edit') {
                await promotionalBannerStore.update({
                    ...promotionalBannersDialog.formData,
                    promotionalBannerValidFrom: promotionalBannersDialog.formData.promotionalBannerValidFrom?.toISOString(),
                    promotionalBannerValidTo: promotionalBannersDialog.formData.promotionalBannerValidTo?.toISOString(),
                }, currentAuthToken)
            } else {
                await promotionalBannerStore.create({
                    ...promotionalBannersDialog.formData,
                    promotionalBannerValidFrom: promotionalBannersDialog.formData.promotionalBannerValidFrom?.toISOString(),
                    promotionalBannerValidTo: promotionalBannersDialog.formData.promotionalBannerValidTo?.toISOString(),
                    locationId: Number(promotionalBannersDialog.storeId)
                }, currentAuthToken)

            }
            const result = await locationStore.findOne({
                locationId: promotionalBannersDialog.storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedPromotionalBanners = checkPromotionalBanners(result.location.promotionalBanners)
                setPromotionalBannersDialog({
                    ...promotionalBannersDialog,
                    data: checkedPromotionalBanners,
                    formData: {
                        promotionalBannerTitle: '',
                        promotionalBannerDescriptions: '',
                        promotionalBannerValidFrom: null,
                        promotionalBannerValidTo: null,
                        promotionalBannerBgColor: '',
                        promotionalBannerTitleColor: '',
                        promotionalBannerSubtitleColor: '',
                    }
                })
            }
            await refresh()
            setIsCreatingPromotionalBanner(false)
        }

        const handlePromotionalBannerInactivate = async (promotionalBannerId: number, storeId: number) => {

            setIsRemovingPromotionalBanner(true)
            await promotionalBannerStore.update({
                promotionalBannerId,
                promotionalBannerStatus: 'inactive'
            }, currentAuthToken)

            const result = await locationStore.findOne({
                locationId: storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedPromotionalBanners = checkPromotionalBanners(result.location.promotionalBanners)
                setPromotionalBannersDialog({
                    ...promotionalBannersDialog,
                    data: checkedPromotionalBanners,
                })
            }
            await refresh()
            setIsRemovingPromotionalBanner(false)
        }

        const handlePromotionalBannerActivate = async (promotionalBannerId: number, storeId: number) => {

            setIsRemovingPromotionalBanner(true)
            await promotionalBannerStore.update({
                promotionalBannerId,
                promotionalBannerStatus: 'active'
            }, currentAuthToken)

            const result = await locationStore.findOne({
                locationId: storeId
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedPromotionalBanners = checkPromotionalBanners(result.location.promotionalBanners)
                setPromotionalBannersDialog({
                    ...promotionalBannersDialog,
                    data: checkedPromotionalBanners,
                })
            }
            await refresh()
            setIsRemovingPromotionalBanner(false)
        }

        const handleLocationSettingSubmit = async () => {
            try {

                setLocationSettingDialog({
                    ...locationSettingDialog,
                    isLoading: true
                });

                // @ts-ignore
                delete locationSettingDialog.data.gateway

                await locationSettingStore.update({...locationSettingDialog.data}, currentAuthToken)

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handlePromotionalBannerEdit = async (promotionalBanner: PromotionalBannerSnapshot) => {
            setPromotionalBannersDialog({
                ...promotionalBannersDialog,
                formData: {
                    promotionalBannerId: promotionalBanner.promotionalBannerId,
                    promotionalBannerTitle: ensureString(promotionalBanner.promotionalBannerTitle),
                    promotionalBannerDescriptions: ensureString(promotionalBanner.promotionalBannerDescriptions),
                    promotionalBannerValidFrom: stringToDayjs(ensureString(promotionalBanner.promotionalBannerValidFrom)),
                    promotionalBannerValidTo: stringToDayjs(ensureString(promotionalBanner.promotionalBannerValidTo)),
                    promotionalBannerBgColor: ensureString(promotionalBanner.promotionalBannerBgColor),
                    promotionalBannerTitleColor: ensureString(promotionalBanner.promotionalBannerTitleColor),
                    promotionalBannerSubtitleColor: ensureString(promotionalBanner.promotionalBannerSubtitleColor),
                },
                mode: 'edit'
            })
        }

        const handleBasicBundleEdit = async (basicBundle: BasicBundleSnapshot) => {
            setBasicBundlesDialog({
                ...basicBundlesDialog,
                formData: {
                    basicBundleId: basicBundle.basicBundleId,
                    basicBundleSkuId: basicBundle.basicBundleSkuId,
                    basicBundleTitle: ensureString(basicBundle.basicBundleTitle),
                    basicBundlePaidCount: basicBundle.basicBundlePaidCount,
                    basicBundleFreeCount: basicBundle.basicBundleFreeCount,
                    basicBundleDescriptions: ensureString(basicBundle.basicBundleDescriptions),
                    basicBundleValidFrom: stringToDayjs(ensureString(basicBundle.basicBundleValidFrom)),
                    basicBundleValidTo: stringToDayjs(ensureString(basicBundle.basicBundleValidTo)),
                },
                mode: 'edit'
            })
        }
        const handlePromotionalBannerDelete = async (promotionalBannerId: number) => {
            await promotionalBannerStore.remove({promotionalBannerId}, currentAuthToken)
            const result = await locationStore.findOne({
                locationId: Number(promotionalBannersDialog.storeId)
            }, currentAuthToken, false)
            if (result.kind === "ok") {
                const checkedPromotionalBanners = checkPromotionalBanners(result.location.promotionalBanners)
                setPromotionalBannersDialog({
                    ...promotionalBannersDialog,
                    data: checkedPromotionalBanners,
                })
            }
            await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if (isEmpty(locationDialog.data.locationName)) {
                validationObject.name = "This field is required."
                isValid = false
            }
            // if(isEmpty(locationDialog.data.locationEmail)) {
            //     validationObject.email = "This field is required."
            //     isValid = false
            // }
            if (isEmpty(locationDialog.data.locationTypeId)) {
                validationObject.storeType = "This field is required."
                isValid = false
            }
            // if (isEmpty(locationDialog.data.locationAdminId)) {
            //     validationObject.admin = "This field is required."
            //     isValid = false
            // }
            if (isEmpty(locationDialog.data.locationPhone)) {
                validationObject.phoneNumber = "This field is required."
                isValid = false
            }
            if (isEmpty(locationDialog.data.locationAddress1)) {
                validationObject.addressLine1 = "This field is required."
                isValid = false
            }
            // if(isEmpty(locationDialog.data.locationAddress2)) {
            //     validationObject.addressLine2 = "This field is required."
            //     isValid = false
            // }
            if (isEmpty(locationDialog.data.locationCity)) {
                validationObject.city = "This field is required."
                isValid = false
            }
            if (isEmpty(locationDialog.data.locationState)) {
                validationObject.state = "This field is required."
                isValid = false
            }
            if (isEmpty(locationDialog.data.locationZip)) {
                validationObject.zip = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const checkAuth = () => {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Stores';

            ;(async () => {
                checkAuth()
                await refresh()
                await getStoreAdmins()
                await getGateways()
            })()

        }, [])

        const rows: GridRowsProp = locations;

        const columns: GridColDef[] = [
            {
                field: 'locationName',
                headerName: 'Name',
                width: 150,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'locationAddress1',
                valueGetter: (params) => {
                    const {locationAddress1, locationAddress2, locationCity, locationState, locationZip} = params.row
                    return `${locationAddress1 ? locationAddress1 : ''} ${locationAddress2 ? locationAddress2 : ''}${locationAddress1 && locationAddress2 ? ', ' : ''}${locationCity}, ${locationZip} ${locationState}`
                },
                headerName: 'Address',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationPhone',
                headerName: 'Phone',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationSlug',
                headerName: 'Slug',
                width: 150,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationTypeName',
                valueGetter: (params) => {
                    if (params.row?.locationStoreType) {
                        return params.row.locationStoreType
                    }
                },
                headerName: 'Type',
                width: 100,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'locationId', headerName: 'Actions', width: 480, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onSettingsClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickLocationSettingOpen({
                            ...params.row.locationSetting,
                            locationSettingTimezone: "PDT"
                        })
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onLockboxesClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickLockboxesOpen(params.row.lockboxes, params.row.locationId, params.row.locationName)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onBundlesClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickBundlesOpen(params.row.basicBundles, params.row.locationId, params.row.locationName)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onBannersClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickBannersOpen(params.row.promotionalBanners, params.row.locationId, params.row.locationName)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return (
                        <>
                            <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 1}}
                                     onClick={onClick}>Edit</CButton>
                            <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 1}}
                                     onClick={onSettingsClick}>Settings</CButton>
                            <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 1}}
                                     onClick={onLockboxesClick}>Lockboxes</CButton>
                            <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 1}}
                                     onClick={onBundlesClick}>Bundles</CButton>
                            <CButton variant={"contained"} size={"small"} sx={{height: '32px'}}
                                     onClick={onBannersClick}>Banners</CButton>

                        </>
                    );
                }
            },

        ];

        const onLoad = React.useCallback(function callback(map) {
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            // const bounds = new window.google.maps.LatLngBounds(center);
            // map.fitBounds(bounds);

            map.setZoom(15)
            setMap(map)
        }, [])

        const onUnmount = React.useCallback(function callback(map) {
            setMap(null)
        }, [])

        const handlePageChange = async (page, details) => {
            setPaginationInfo({
                ...paginationInfo,
                page
            })
            await refresh()
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const getStoreAdmins = async () => {
            const result = await userStore.findAll({
                roleId: 7,
                page: 0,
                perPage: 100
            }, currentAuthToken, false)

            if (result.kind === 'ok') {
                setStoreAdmins(result.users)
            }
        }

        const getGateways = async () => {
            await gatewayStore.findAll({}, currentAuthToken)
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await locationStore.findAll({
                locationTypeId: 1,
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken, true)
            setIsRefreshing(false)
        }

        const handlePickedFile = ({target}) => {
            setIsUploadingLogo(true)

            uploadImage(
                target,
                locationSettingDialog.data.locationId,
                (url)=>{
                    setIsUploadingLogo(false)
                    setLocationSettingDialog({
                        ...locationSettingDialog,
                        data: {...locationSettingDialog.data, locationSettingLogo: url}
                    });
                },
                (error)=>{
                    setIsUploadingLogo(false)

                    console.log(error)
                    // Handle any errors
                }
            )
        }

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                Stores
                            </CTypography>
                            <CButton variant={"contained"} onClick={() => handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid
                                /// For now we're loading 100 items by default
                                /// More than 100 items needs pro plan
                                /// Also we never need to load like 1000 items at once, Worst case we can filter them
                                getRowId={(row) => row.locationId}
                                rows={rows}
                                columns={columns}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                pageSize={tablePageSize}
                                onPageSizeChange={handlePageSizeChange}
                                loading={isRefreshing}
                                components={{
                                    LoadingOverlay: LinearProgress,
                                }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <LockboxesDialog
                    lockboxesDialog={lockboxesDialog}
                    setLockboxesDialog={setLockboxesDialog}
                    lockboxes={lockboxes}
                    handleLocationStoreLockboxCreate={handleLocationStoreLockboxCreate}
                    handleLocationStoreLockboxRemove={handleLocationStoreLockboxRemove}
                    isCreatingLocationStoreLockbox={isCreatingLocationStoreLockbox}
                    handleClose={handleClose}
                />

                <BasicBundlesDialog
                    basicBundlesDialog={basicBundlesDialog}
                    setBasicBundlesDialog={setBasicBundlesDialog}
                    parentSkus={parentSkus}
                    isFromDateCalendarOpen={isFromDateCalendarOpen}
                    setIsFromDateCalendarOpen={setIsFromDateCalendarOpen}
                    isToDateCalendarOpen={isToDateCalendarOpen}
                    setIsToDateCalendarOpen={setIsToDateCalendarOpen}
                    handleClose={handleClose}
                    handleBasicBundleCreate={handleBasicBundleCreate}
                    isCreatingBasicBundle={isCreatingBasicBundle}
                    handleBasicBundleInactivate={handleBasicBundleInactivate}
                    handleBasicBundleActivate={handleBasicBundleActivate}
                    handleBasicBundleEdit={handleBasicBundleEdit}
                    handleBasicBundleEditCancel={()=>{
                        setBasicBundlesDialog({
                            ...basicBundlesDialog,
                            mode: 'create',
                            formData:{
                                basicBundleSkuId: null,
                                basicBundleTitle: '',
                                basicBundlePaidCount: null,
                                basicBundleFreeCount: null,
                                basicBundleDescriptions: '',
                                basicBundleValidFrom: null,
                                basicBundleValidTo: null,
                            }
                        })
                    }}
                />

                <PromotionalBannerDialog
                    promotionalBannersDialog={promotionalBannersDialog}
                    setPromotionalBannersDialog={setPromotionalBannersDialog}
                    isPromotionalBannerFromDateCalendarOpen={isPromotionalBannerFromDateCalendarOpen}
                    setIsPromotionalBannerFromDateCalendarOpen={setIsPromotionalBannerFromDateCalendarOpen}
                    isPromotionalBannerToDateCalendarOpen={isPromotionalBannerToDateCalendarOpen}
                    setIsPromotionalBannerToDateCalendarOpen={setIsPromotionalBannerToDateCalendarOpen}
                    handleClose={handleClose}
                    handlePromotionalBannerCreate={handlePromotionalBannerCreate}
                    isCreatingPromotionalBanner={isCreatingPromotionalBanner}
                    handlePromotionalBannerInactivate={handlePromotionalBannerInactivate}
                    handlePromotionalBannerActivate={handlePromotionalBannerActivate}
                    handlePromotionalBannerEdit={handlePromotionalBannerEdit}
                    handlePromotionalBannerDelete={handlePromotionalBannerDelete}
                    handlePromotionalBannerEditCancel={()=>{
                        setPromotionalBannersDialog({
                            ...promotionalBannersDialog,
                            mode: 'create',
                            formData: {
                                promotionalBannerTitle: '',
                                promotionalBannerDescriptions: '',
                                promotionalBannerValidFrom: null,
                                promotionalBannerValidTo: null,
                                promotionalBannerBgColor: '',
                                promotionalBannerTitleColor: '',
                                promotionalBannerSubtitleColor: '',
                            }
                        })
                    }}

                />

                <LocationDialog
                    locationDialog={locationDialog}
                    setLocationDialog={setLocationDialog}
                    handleClose={handleClose}
                    storeAdmins={storeAdmins}
                    validation={validation}
                    setValidation={setValidation}
                    containerStyle={containerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    handleSubmit={handleSubmit}
                />

                <LocationSettings
                    handleClose={handleClose}
                    locationSettingDialog={locationSettingDialog}
                    isUploadingLogo={isUploadingLogo}
                    handlePickedFile={handlePickedFile}
                    setLocationSettingDialog={setLocationSettingDialog}
                    gateways={gateways}
                    handleLocationSettingSubmit={handleLocationSettingSubmit}
                />
            </>
        );
    }
)
