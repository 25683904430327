import {
    CBox,
    CButton,
    CCircularProgress, CDialog,
    CGrid,
    CGridContainer, CInterleavedTableRow,
    CLinearProgress, CTable, CTableBody, CTableCell, CTableHead, CTableRow,
    CTextField,
    CTypography
} from "../mui-extracted";
import {Divider} from "../divider";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {isEmpty} from "validate.js";
import {useStores} from "../../models";

export const SkuVarietiesDialog = (props) => {

    const {
        skuVarietiesDialog,
        setSkuVarietiesDialog,
        setSkuVarietyDialog,
        handleClose,
        varietyItemRows,
        currentAuthToken
    } = props;

    const {skuStore} = useStores()

    const [addVarietyValidation, setAddVarietyValidation] = React.useState({
        code: '',
        title: '',
    })

    const handleVarietyRemove = async (id: number) => {
        await skuStore.remove({skuId: id}, currentAuthToken)
        await getSkuVarieties(skuVarietiesDialog.skuId)
        if (skuStore.skuVarieties.length === 0) {
            await skuStore.update({
                skuId: skuVarietiesDialog.skuId,
                skuHasVarieties: 0,
            }, currentAuthToken)
        }
    }

    const handleVarietyEdit = (variety) => {
        setSkuVarietiesDialog({...skuVarietiesDialog, isOpen: false})
        setSkuVarietyDialog({
            isOpen: true,
            formData: {
                id: variety.id,
                code: variety.code,
                title: variety.title
            },
            isLoading: false
        })
    }

    const getSkuVarieties = async (skuId) => {
        await skuStore.findAllVarieties({
            skuParentId: skuId,
            page: 0,
            perPage: 100
        }, currentAuthToken)
    }

    const addVarietyValidate = () => {
        const validationObject = {...addVarietyValidation}
        let isValid = true
        if (isEmpty(skuVarietiesDialog.formData.code)) {
            validationObject.code = "This field is required."
            isValid = false
        }
        if (isEmpty(skuVarietiesDialog.formData.title)) {
            validationObject.title = "This field is required."
            isValid = false
        }
        setAddVarietyValidation(validationObject)
        return isValid;
    }

    const handleAddSkuVariety = async () => {

        if (!addVarietyValidate()) {
            return
        }

        setAddVarietyValidation({
            code: '',
            title: '',
        })

        setSkuVarietiesDialog({
            ...skuVarietiesDialog,
            isLoading: true
        })

        await skuStore.create({
            skuParentId: skuVarietiesDialog.skuId,
            categoryId: skuVarietiesDialog.sku.categoryId,
            vendorId: skuVarietiesDialog.sku.vendorId,
            skuCode: skuVarietiesDialog.formData.code?.toUpperCase(),
            skuTitle: skuVarietiesDialog.formData.title,
            skuStatus: 'active',
            skuHasParent: 1
        }, currentAuthToken)

        await skuStore.update({
            skuId: skuVarietiesDialog.skuId,
            skuHasVarieties: 1,
        }, currentAuthToken)

        await getSkuVarieties(skuVarietiesDialog.skuId)

        setSkuVarietiesDialog({
            ...skuVarietiesDialog,
            formData: {
                code: '',
                title: '',
            },
            isLoading: false
        })
    }

    return (
        <CDialog open={skuVarietiesDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
            sx: {
                borderRadius: "37px",
                overflowX: 'hidden'
            }
        }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4} mr={2}>
                    Sku Varieties
                </CTypography>
            </CBox>
            <Divider></Divider>
            {skuStore.isGettingSkuVarieties && <CLinearProgress></CLinearProgress>}
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={4}>
                    <CTextField fullWidth label={"Code"}
                                error={hasValue(addVarietyValidation.code)}
                                helperText={hasValue(addVarietyValidation.code) ? addVarietyValidation.code : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuVarietiesDialog({
                                        ...skuVarietiesDialog,
                                        formData: {
                                            ...skuVarietiesDialog.formData,
                                            code: event.target.value
                                        }
                                    });
                                    if (hasValue(addVarietyValidation.code)) {
                                        setAddVarietyValidation({...addVarietyValidation, code: ''})
                                    }
                                }} value={skuVarietiesDialog.formData.code}/>
                </CGrid>
                <CGrid item xs={5}>
                    <CTextField fullWidth label={"Title"}
                                error={hasValue(addVarietyValidation.title)}
                                helperText={hasValue(addVarietyValidation.title) ? addVarietyValidation.title : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuVarietiesDialog({
                                        ...skuVarietiesDialog,
                                        formData: {
                                            ...skuVarietiesDialog.formData,
                                            title: event.target.value
                                        }
                                    });
                                    if (hasValue(addVarietyValidation.title)) {
                                        setAddVarietyValidation({...addVarietyValidation, title: ''})
                                    }
                                }} value={skuVarietiesDialog.formData.title}/>
                </CGrid>
                <CGrid item xs={3}>
                    <CButton fullWidth variant={"contained"} onClick={handleAddSkuVariety}
                             sx={{mr: 4, height: '100%'}}>
                        {skuVarietiesDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : 'Save'}
                    </CButton>
                </CGrid>
            </CGridContainer>
            <CGridContainer spacing={2} p={0}>
                <CGrid item xs={12} maxHeight={'65vh'} maxWidth={'100%'} sx={{
                    overflowY: 'scroll',
                }}>
                    <CTable aria-label="simple table">
                        <CTableHead>
                            <CTableRow>
                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="left">ID</CTableCell>
                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="left">Code</CTableCell>

                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="left">Title</CTableCell>
                                <CTableCell sx={{
                                    fontSize: "20px",
                                    paddingRight: "32px"
                                }} align="right">Actions</CTableCell>
                            </CTableRow>
                        </CTableHead>

                        <CTableBody>

                            {varietyItemRows.map((row, index) => (
                                <CInterleavedTableRow
                                    key={row.id + index}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <CTableCell component="th" scope="row" sx={{
                                        paddingLeft: "24px",
                                        // flexDirection: "row",
                                        // display: "flex",
                                        width: "80px"
                                    }}>
                                        <CTypography>{
                                            row.id
                                        }</CTypography>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        paddingRight: "32px"
                                    }} align="left">
                                        <CTypography>{
                                            row.code
                                        }</CTypography>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        paddingRight: "32px"
                                    }} align="left">
                                        <CTypography>{
                                            row.title
                                        }</CTypography>
                                    </CTableCell>
                                    <CTableCell sx={{
                                        paddingRight: "32px"
                                    }} align="right">
                                        <CButton variant={"contained"} size={"small"}
                                                 sx={{height: '32px', mr: 2}}
                                                 onClick={() => handleVarietyEdit(row)}>Edit</CButton>

                                        <CButton variant={"contained"} color={"error"} size={"small"}
                                                 sx={{height: '32px'}}
                                                 onClick={() => handleVarietyRemove(row.id)}>Remove</CButton>
                                    </CTableCell>
                                </CInterleavedTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </CGrid>

            </CGridContainer>
            <Divider></Divider>
            <CBox
                sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                    Close
                </CButton>
            </CBox>
        </CDialog>
    )
}