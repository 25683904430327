import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {UsersApi} from "../../services/api/users-api"
import {UserModel, UserSnapshot} from "../user/user"
import {logInfo} from "../../utils/logs"
import {
    UserCreateParams,
    UserDeleteParams,
    UserFindAllParams,
    UserFindOneParams,
    UserUpdateParams
} from "../../services/api";

function userStoreLog(functionName, message) {
    logInfo({
        fileName: "user-store.ts",
        className: "userStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const UserStoreModel = types
    .model("UserStore")
    .props({
        user: types.optional(types.maybeNull(UserModel), null),
        users: types.optional(types.array(UserModel), []),
        count: types.optional(types.number, 0),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveUser: (userSnapshot: UserSnapshot) => {
            try {
                self.user = userSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    userStoreLog("saveUser", e.message)
                }
                // recordError(e)
            }
        },
        saveUsers: (usersSnapshot: UserSnapshot[]) => {
            try {
                detach(self.users);
                self.users = cast([...usersSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    userStoreLog("saveUser", e.message)
                }
                // recordError(e)
            }
        },
        saveCount: (count: number) => {
            try {
                self.count = count
            } catch (e) {
                if(e instanceof Error) {
                    userStoreLog("saveCount", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: UserCreateParams, token: string) {
            self.environment.addAuth(token)
            const userApi = new UsersApi(self.environment.api)
            const result = yield userApi.create(params)

            if (result.kind === "ok") {
                if (typeof result.user === "string") {
                    result.kind = result.user
                } else {
                    self.saveUser(result.user)
                }
            } else {
                userStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: UserFindAllParams, token: string, saveToMst: boolean) {
            self.environment.addAuth(token)
            const userApi = new UsersApi(self.environment.api)
            const result = yield userApi.findAll(params)

            if (result.kind === "ok") {
                if (saveToMst) {
                    self.saveUsers(result.users)
                    self.saveCount(result.count)
                }
            } else {
                userStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: UserFindOneParams, token: string) {
            self.environment.addAuth(token)
            const userApi = new UsersApi(self.environment.api)
            const result = yield userApi.findOne(params)


            if (result.kind === "ok") {
                self.saveUser(result.user)
            } else {
                userStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: UserUpdateParams, token: string) {
            self.environment.addAuth(token)
            const userApi = new UsersApi(self.environment.api)

            // @ts-ignore
            delete params.userDetail
            // @ts-ignore
            delete params.location
            // @ts-ignore
            delete params.role

            const result = yield userApi.update(params)

            console.log('update', params)

            if (result.kind === "ok") {
                self.saveUser(result.user)
            } else {
                userStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: UserDeleteParams, token: string) {
            self.environment.addAuth(token)
            const userApi = new UsersApi(self.environment.api)
            return yield userApi.remove(params)
        }),

    }))

type UserStoreType = Instance<typeof UserStoreModel>

export interface UserStore extends UserStoreType {
}

type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>

export interface UserStoreSnapshot extends UserStoreSnapshotType {
}

export const createUserStoreDefaultModel = () => types.optional(UserStoreModel, {})
