import {Table} from "../table";
import * as React from "react";
import {CBox, CIconButton, CTypography} from "../mui-extracted";
import dayjs, {Dayjs} from "dayjs";
import {getCustomerName} from "../../utils/customer";
import {hasValue} from "../../utils/empty-check";
import {useStores} from "../../models";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {addCurrentTimezone, convertToCurrentTimezone} from "../../utils/date-time";
import {Edit, Flag, Person, SettingsOutlined} from "@mui/icons-material";
import {useMediaQuery} from "@mui/material";

let statelessPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessOrderInfo = {
    orderBy: 'orderCreatedAt',
    order: 'DESC'
}
let currentAuthToken = ''

interface OrdersTableProps {
    module: string,
    handleClickOpen: (row) => void,
    handleCustomerDetailsClickOpen: (row) => void
    orderNoFilter?: string
    orderTransIdFilter?: string,
    orderStatusFilter?: string
    fromDate?: Dayjs | null,
    toDate?: Dayjs | null,
    autoRefresh: boolean,
    onRefreshingChange: (isRefreshing: boolean) => void,
    orderCustomerFullNameFilter: string,
    orderCardNoFilter: string,
    orderBillingNameFilter: string,
}

let statelessFromDate: Dayjs | null | undefined = null
let statelessToDate: Dayjs | null | undefined = null
let statelessOrderNoFilter: string | undefined = ''
let statelessOrderTransIdFilter: string | undefined = ''
let statelessOrderStatusFilter: string | undefined = 'received'
let statelessOrderCustomerFullNameFilter: string | undefined = ''
let statelessOrderCardNoFilter: string | undefined = ''
let statelessOrderBillingNameFilter: string | undefined = ''

let previousData: any = null
let renderedOnce = false

export const OrdersTable = forwardRef(function OrdersTable({
                                                               handleClickOpen,
                                                               handleCustomerDetailsClickOpen,
                                                               orderNoFilter,
                                                               orderTransIdFilter,
                                                               orderStatusFilter,
                                                               fromDate,
                                                               toDate,
                                                               module,
                                                               autoRefresh,
                                                               onRefreshingChange,
                                                               orderCustomerFullNameFilter,
                                                               orderCardNoFilter,
                                                               orderBillingNameFilter,
                                                           }: OrdersTableProps, ref) {

    const {authStore, orderStore, orderDetailStore} = useStores()
    const {orders, count} = orderStore

    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'orderCreatedAt',
        order: 'DESC'
    })

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));


    useImperativeHandle(ref, () => ({
        refreshContents() {
            // Call your API here
            refresh(true)
        },
    }));

    const checkAuth = () => {
        if (module === 'admin') {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
        } else if (module === 'store admin') {
            if (authStore.storeAdminAuth !== null) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            }
        } else if (module === 'lockbox') {
            if (authStore.lockboxAuth !== null) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            }
        }

    }

    useEffect(() => {
        ;(async () => {
            if (!renderedOnce) {
                checkAuth()
                renderedOnce = true
                previousData = null
                await refresh()
            }
        })()

    }, [])

    useEffect(() => {
        ;(async () => {
            statelessOrderNoFilter = orderNoFilter
            if (statelessOrderNoFilter === null || statelessOrderNoFilter === undefined || statelessOrderNoFilter.length > 11) {
                // console.log('ordersTable.orderNoFilter', orderNoFilter)
                if (autoRefresh) {
                    await refresh()
                }
            }
        })()
    }, [orderNoFilter])

    useEffect(() => {
        ;(async () => {
            statelessOrderTransIdFilter = orderTransIdFilter
            if (autoRefresh) {
                await refresh()
            }

        })()
    }, [orderTransIdFilter])

    useEffect(() => {
        ;(async () => {
            statelessOrderCustomerFullNameFilter = orderCustomerFullNameFilter
            if (orderCustomerFullNameFilter === null || orderCustomerFullNameFilter === undefined) {
                // console.log('ordersTable.orderCustomerFullNameFilter', orderCustomerFullNameFilter)
                if (autoRefresh) {
                    await refresh()
                }
            }
        })()
    }, [orderCustomerFullNameFilter])

    useEffect(() => {
        ;(async () => {
            statelessOrderBillingNameFilter = orderBillingNameFilter
            if (orderBillingNameFilter === null || orderBillingNameFilter === undefined) {
                // console.log('ordersTable.orderBillingNameFilter', orderBillingNameFilter)
                if (autoRefresh) {
                    await refresh()
                }
            }
        })()
    }, [orderBillingNameFilter])

    useEffect(() => {
        ;(async () => {
            statelessOrderCardNoFilter = orderCardNoFilter
            if (orderCardNoFilter === null || orderCardNoFilter === undefined) {
                // console.log('ordersTable.orderCardNoFilter', orderCardNoFilter)
                if (autoRefresh) {
                    await refresh()
                }
            }
        })()
    }, [orderCardNoFilter])

    useEffect(() => {
        ;(async () => {
            // console.log('ordersTable.orderStatusFilter', orderStatusFilter)
            if (orderStatusFilter === 'summary') {
                return
            }
            if (orderStatusFilter === 'all') {
                statelessOrderStatusFilter = ''
            } else {
                statelessOrderStatusFilter = orderStatusFilter
            }
            await refresh()
        })()

    }, [orderStatusFilter])

    useEffect(() => {
        ;(async () => {
            // console.log('ordersTable.fromDate', fromDate)
            statelessFromDate = fromDate
            if (autoRefresh) {
                await refresh()
            }
        })()

    }, [fromDate])

    useEffect(() => {
        ;(async () => {
            // console.log('ordersTable.toDate', toDate)
            statelessToDate = toDate
            if (autoRefresh) {
                await refresh()
            }
        })()

    }, [toDate])

    const handlePageChange = async (details, page) => {
        // console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            page
        }
        await refresh()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await refresh()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessOrderInfo.orderBy === property && statelessOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        await refresh()
    };

    const refresh = async (force?: boolean) => {
        setIsRefreshing(true)
        onRefreshingChange(true)
        const data = {
            orderNo: '',
            paymentTransactionId: '',
            orderCardNo: '',
            orderCustomerFullName: '',
            orderBillingName: '',
            orderStatus: '',
            toDate: '',
            fromDate: '',
            page: statelessPaginationInfo.page,
            perPage: statelessPaginationInfo.perPage,
            sortBy: statelessOrderInfo.orderBy,
            sortDirection: statelessOrderInfo.order,
        }
        if (module === 'store admin') {
            data['kioskId'] = authStore?.storeAdminAuth?.userLocationId
        } else if (module === 'lockbox') {
            data['lockboxId'] = authStore?.lockboxAuth?.userLocationId
        }
        if (hasValue(statelessOrderNoFilter) && statelessOrderNoFilter && statelessOrderNoFilter.length > 0) {
            data['orderNo'] = statelessOrderNoFilter
        } else {
            // @ts-ignore
            delete data.orderNo
        }

        if (hasValue(statelessOrderTransIdFilter) && statelessOrderTransIdFilter && statelessOrderTransIdFilter.length > 0) {
            data['paymentTransactionId'] = statelessOrderTransIdFilter
        } else {
            // @ts-ignore
            delete data.paymentTransactionId
        }

        if (hasValue(statelessOrderCustomerFullNameFilter) && statelessOrderCustomerFullNameFilter && statelessOrderCustomerFullNameFilter.length > 0) {
            data['orderCustomerFullName'] = statelessOrderCustomerFullNameFilter
        } else {
            // @ts-ignore
            delete data.orderCustomerFullName
        }

        if (hasValue(statelessOrderCardNoFilter) && statelessOrderCardNoFilter && statelessOrderCardNoFilter.length > 0) {
            data['orderCardNo'] = statelessOrderCardNoFilter
        } else {
            // @ts-ignore
            delete data.orderCardNo
        }

        if (hasValue(statelessOrderBillingNameFilter) && statelessOrderBillingNameFilter && statelessOrderBillingNameFilter.length > 0) {
            data['orderBillingName'] = statelessOrderBillingNameFilter
        } else {
            // @ts-ignore
            delete data.orderBillingName
        }

        if (hasValue(statelessOrderStatusFilter) && statelessOrderStatusFilter && statelessOrderStatusFilter.length > 0) {
            data['orderStatus'] = statelessOrderStatusFilter
        } else {
            // @ts-ignore
            delete data.orderStatus
        }
        if (hasValue(statelessFromDate) && statelessFromDate) {
            // console.log('refresh', statelessFromDate.toString())
            // console.log('refresh', statelessFromDate?.toISOString())
            data['fromDate'] = addCurrentTimezone(statelessFromDate)
        } else {
            // @ts-ignore
            delete data.fromDate
        }

        if (hasValue(statelessToDate) && statelessToDate) {
            data['toDate'] = addCurrentTimezone(statelessToDate)
        } else {
            // @ts-ignore
            delete data.toDate
        }

        const isDataTheSame = previousData !== null && (Object.keys(previousData).length === Object.keys(data).length) &&
            Object.keys(previousData).every(key => previousData[key] === data[key]);

        if (!isDataTheSame || force === true) {
            previousData = data
            // console.log('ordersTable.refresh', data, new Date().getTime())
            await orderStore.findAll(data, currentAuthToken, true)
        }
        setIsRefreshing(false)
        onRefreshingChange(false)
    }

    const rows = orders;

    const columns = [
        // {
        //     field: 'orderId',
        //     headerName: 'Id',
        //     width: 50,
        //     headerClassName: "pos-table-header pos-table-header-first",
        //     cellClassName: "pos-table-cell pos-table-cell-first"
        // },
        {
            field: 'col5',
            headerName: 'Action',
            width: 250,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell",
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickOpen(params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };
                const onCustomerDetailsClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleCustomerDetailsClickOpen(params.row)
                }

                const isFlagged =  hasValue(params.row?.customer?.customerFlag) || hasValue(params.row?.user?.userFlag)
                let flagColor

                if (hasValue(params.row?.customer?.customerFlag)) {
                    flagColor = params.row?.customer?.customerFlag
                } else if (hasValue(params.row?.user?.userFlag)) {
                    flagColor = params.row?.user?.userFlag
                }

                // flagColor = flagColor === 'red' ? 'error' : flagColor === 'yellow' ? 'warning' : null
                return <div style={{width: '100%'}}>
                    {isFlagged &&<CIconButton>
                        <Flag style={{color: flagColor}}></Flag>
                    </CIconButton>}

                    <CIconButton onClick={onClick}>
                    <SettingsOutlined color={'primary'}/>
                </CIconButton>
                    <CIconButton onClick={onCustomerDetailsClick}>
                        <Person color={'primary'}></Person>
                    </CIconButton>
                </div>;


                // return <div style={{width: '250px'}}><CButton variant={"contained"} size={"small"}
                //                                               sx={{height: '32px', mr: 2}}
                //                                               onClick={onClick}>Edit & Details</CButton>
                //     <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                //              onClick={onCustomerDetailsClick}>Customer Details</CButton>
                // </div>;
            }
        },
        {
            field: 'orderNo',
            headerName: 'Order No',
            width: 150,
            headerClassName: "pos-table-header pos-table-header-first",
            cellClassName: "pos-table-cell pos-table-cell-first"
        },
        {
            field: 'orderPickupDateTime',
            headerName: 'Pickup Time',
            valueGetter: (params) => {
                const {orderPickupDateTime} = params.row
                return `${dayjs(orderPickupDateTime).format('MMM D, h:mm A')}`
            },
            width: 170,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderCustomerFullName',
            headerName: 'Customer Name',
            valueGetter: (params) => {
                const {customer, user} = params.row
                return `${getCustomerName(customer, user)}`
            },
            width: 210,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderStatus',
            headerName: 'Status',
            width: 80,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'deliveryMethod',
            headerName: 'Method',
            valueGetter: (params) => {
                const {deliveryMethod} = params.row
                if (deliveryMethod === 'local-delivery') {
                    return `Delivery`

                } else {
                    return `Pickup`
                }
            },
            width: 100,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'orderCreatedAt',
            headerName: 'Created At',
            valueGetter: (params) => {
                const {orderCreatedAt} = params.row
                // console.log('orderCreatedAt', orderCreatedAt)
                return `${convertToCurrentTimezone(orderCreatedAt, 'MMM D, h:mm A')}`
            },
            width: 130,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        // {
        //     field: 'customerName',
        //     headerName: 'Customer',
        //     valueGetter: (params) => {
        //         const order = params.row
        //         if (order) {
        //             const {customer, user} = order
        //             return getCustomerName(customer, user)
        //         } else {
        //             return ''
        //         }
        //     },
        //     width: 120,
        //     headerClassName: "pos-table-header",
        //     cellClassName: "pos-table-cell"
        // },
        {
            field: 'orderTotal',
            headerName: 'Total',
            valueGetter: (params) => {
                const {orderTotal} = params.row
                return `$${orderTotal?.toFixed(2)}`
            },
            width: 80,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'storeName',
            headerName: 'Store',
            valueGetter: (params) => {
                const order = params.row
                const {kiosk} = order
                if (kiosk) {
                    return kiosk.locationName
                } else {
                    return ''
                }
            },
            width: 100,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'lockboxName',
            headerName: 'Lockbox',
            valueGetter: (params) => {
                const order = params.row
                const {lockbox} = order
                if (lockbox) {
                    return lockbox.locationName
                } else {
                    return ''
                }
            },
            width: 120,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },


    ];

    const mobileColumns = [
        {
            field: 'col5',
            headerName: 'Actions',
            width: 250,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell",
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleClickOpen(params.row)
                    // return alert(JSON.stringify(thisRow, null, 4));
                };
                const onCustomerDetailsClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return handleCustomerDetailsClickOpen(params.row)
                }

                const orderStatus = params.row.orderStatus;
                const orderNo = params.row.orderNo;
                const customerName = getCustomerName(params.row.customer, params.row.user);
                const deliveryMethod = params.row.deliveryMethod === "lockbox" ? "Pick up" : "Deliver";
                const orderPickupTime = dayjs(params.row.orderPickupDateTime).format('MMM D, h:mm A')
                const orderCreatedAt = dayjs(params.row.orderCreatedAt).format('MMM D, h:mm A')

                let bgColor

                if (orderStatus === 'received') {
                    bgColor = 'red'
                } else if (orderStatus === 'ready') {
                    bgColor = 'orange'
                } else {
                    bgColor = 'green'
                }

                const isFlagged =  hasValue(params.row?.customer?.customerFlag) || hasValue(params.row?.user?.userFlag)
                let flagColor

                if (hasValue(params.row?.customer?.customerFlag)) {
                    flagColor = params.row?.customer?.customerFlag
                } else if (hasValue(params.row?.user?.userFlag)) {
                    flagColor = params.row?.user?.userFlag
                }

                // flagColor = flagColor === 'red' ? 'error' : flagColor === 'yellow' ? 'warning' : null

                return <CBox sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <CBox sx={{width: 8, borderRadius: 4, backgroundColor: bgColor}}></CBox>
                    <CBox sx={{flex: 1, display: 'flex', flexDirection: 'column', px: 2}}>
                        <CTypography variant={'h6'} sx={{mb: 1}}>#{orderNo} ({orderStatus})</CTypography>
                        <CTypography variant={'subtitle2'} sx={{mb: 1}}>{customerName}</CTypography>
                        <CTypography variant={'subtitle2'}
                                     sx={{mb: 1}}>{deliveryMethod} at {orderPickupTime}</CTypography>
                        <CTypography variant={'body2'} sx={{color: 'grey'}}>Created at {orderCreatedAt}</CTypography>

                    </CBox>
                    <CBox sx={{display: 'flex', flexDirection: 'column'}}>
                        {isFlagged &&<CIconButton>
                            <Flag style={{color: flagColor}}></Flag>
                        </CIconButton>}
                        <CIconButton onClick={onClick}>
                            <SettingsOutlined color={'primary'}/>
                        </CIconButton>
                        <CIconButton onClick={onCustomerDetailsClick}>
                            <Person color={'primary'}></Person>
                        </CIconButton>
                    </CBox>

                </CBox>;


                // return <div style={{width: '250px'}}><CButton variant={"contained"} size={"small"}
                //                                               sx={{height: '32px', mr: 2}}
                //                                               onClick={onClick}>Edit & Details</CButton>
                //     <CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                //              onClick={onCustomerDetailsClick}>Customer Details</CButton>
                // </div>;
            }
        },


    ];

    if (!rows || rows.length === 0) {
        return <CBox sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CTypography> No order found with current filters.</CTypography>
        </CBox>
    }

    return (
        <Table
            columns={(isSmallScreen || isExtraSmallScreen) ? mobileColumns : columns}
            rows={rows}
            count={count}
            page={paginationInfo.page}
            rowsPerPage={paginationInfo.perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            orderBy={orderInfo.orderBy}
            order={orderInfo.order}
            onRequestSort={handleRequestSort}
            isLoading={isRefreshing}
            noRowTitleOnSmallSize={true}
        />
    )
})