import {useNavigate} from "react-router-dom";
import {useStores} from "../../models";
import * as React from "react";
import {defaultUser} from "../../models/user/user";
import {UserCreateParams, UserUpdateParams} from "../../services/api";
import {isEmpty} from "validate.js";
import {hasValue} from "../../utils/empty-check";
import {
    CBox,
    CButton,
    CCard, CCircularProgress,
    CDialog, CGrid, CGridContainer, CIconButton, CInputAdornment, CMenuItem,
    CTab,
    CTabs, CTextField,
    CTypography, CustomerDialog,
    Divider,
    TabPanel
} from "../../components";
import {useEffect, useRef} from "react";
import {Flag, Refresh, Visibility, VisibilityOff} from "@mui/icons-material";
import {CustomersTable} from "../../components/customers-table";
import {GuestsTable} from "../../components/guests-table";
import {a11yProps} from "../../utils/tab";
import {useMediaQuery} from "@mui/material";
import {Auth} from "../../models/auth/auth";

interface UserDialogData {
    isOpen: boolean,
    data: UserCreateParams | UserUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

interface CustomersScreenContentProps {
    module: string
}

let statelessShowFlagged = false

export const CustomersScreenContent = ({module}: CustomersScreenContentProps) => {

    const navigate = useNavigate();

    const {authStore, userStore, locationStore} = useStores()
    const {locations} = locationStore
    const [userDialog, setUserDialog] = React.useState<UserDialogData>({
        isOpen: false,
        data: defaultUser,
        mode: 'add',
        isLoading: false
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const [isRefreshingGuests, setIsRefreshingGuests] = React.useState(false);
    const [validation, setValidation] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roleId: '',
        locationId: '',
        userName: '',
        password: ''
    })
    const [tabValue, setTabValue] = React.useState(0);
    const [customerStatistics, setCustomerStatistics] = React.useState({
        total: 0,
        pending: 0
    });
    const [customerStatisticsDialog, setCustomerStatisticsDialog] = React.useState<{ isOpen: boolean }>({
        isOpen: false
    });
    const [isCustomerDialogOpen, setIsCustomerDialogOpen] = React.useState(false);
    const [customerDialogData, setCustomerDialogData] = React.useState({
        id: 0,
        customerType: 'guest'
    });
    const [showFlagged, setShowFlagged] = React.useState(false)

    const customersTableRef = useRef(null);
    const guestsTableRef = useRef(null);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleClickOpen = (mode: string, user?: UserCreateParams | UserUpdateParams) => {
        setUserDialog({
            ...userDialog,
            isOpen: true,
            mode,
            data: user ? {...user} : defaultUser
        });
    };

    const handleClose = () => {
        setUserDialog({isOpen: false, data: defaultUser, mode: 'add', isLoading: false});
        setCustomerStatisticsDialog({isOpen: false})
    };

    const handleSubmit = async () => {
        try {


            if (!validate()) {
                return
            }


            setUserDialog({
                ...userDialog,
                isLoading: true
            });

            if (userDialog.mode === 'add') {
                const result = await userStore.create({
                    ...userDialog.data
                }, currentAuthToken)
                if (userDialog.data.roleId === 2) {
                    await locationStore.update({
                        locationId: userDialog.data.userLocationId,
                        locationAdminId: result.user.identifiers[0].userId
                    }, currentAuthToken)
                }
            } else if (userDialog.mode === 'edit') {
                const currentUserDialogData = {...userDialog.data}
                // @ts-ignore
                delete currentUserDialogData.userDetail

                await userStore.update({
                    ...currentUserDialogData
                }, currentAuthToken)
                if (userDialog.data.roleId === 2) {
                    await locationStore.update({
                        locationId: userDialog.data.userLocationId,
                        // @ts-ignore
                        locationAdminId: userDialog.data.userId
                    }, currentAuthToken)
                }
            }


            await refresh()

            handleClose()
        } catch (e) {
            console.log(e)
        }
    };

    const validate = () => {
        const validationObject = {...validation}
        let isValid = true
        if (isEmpty(userDialog.data.userFirstName)) {
            validationObject.firstName = "This field is required."
            isValid = false
        }
        if (isEmpty(userDialog.data.userLastName)) {
            validationObject.lastName = "This field is required."
            isValid = false
        }

        if (isEmpty(userDialog.data.userEmail)) {
            validationObject.email = "This field is required."
            isValid = false
        }
        if (isEmpty(userDialog.data.userPhone)) {
            validationObject.phoneNumber = "This field is required."
            isValid = false
        }
        if (isEmpty(userDialog.data.roleId)) {
            validationObject.roleId = "This field is required."
            isValid = false
        } else if ((userDialog.data.roleId === 2 || userDialog.data.roleId === 4 || userDialog.data.roleId === 5) && isEmpty(userDialog.data.userLocationId)) {
            validationObject.locationId = "This field is required."
            isValid = false
        }
        if (isEmpty(userDialog.data.userUsername)) {
            validationObject.userName = "This field is required."
            isValid = false
        }
        if (userDialog.mode === 'add' && isEmpty(userDialog.data.userPassword)) {
            validationObject.password = "This field is required."
            isValid = false
        }
        setValidation(validationObject)
        return isValid;
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleCustomerDetailsClickOpen = async (customer, type) => {
        if (type === 'guest') {
            setCustomerDialogData({
                id: customer?.customerId,
                customerType: 'guest'
            })
            setIsCustomerDialogOpen(true)
        } else {
            setCustomerDialogData({
                id: customer?.userId,
                customerType: 'registered'
            })
            setIsCustomerDialogOpen(true)
        }
    };

    const getLocations = async () => {
        await locationStore.findAll({
            locationTypeId: 1,
            page: 0,
            perPage: 100
        }, currentAuthToken, true)

    }

    const refresh = () => {
        if (customersTableRef.current) {
            // @ts-ignore
            customersTableRef.current.refreshContents(statelessShowFlagged);
        }
    }

    const refreshGuests = async () => {
        if (guestsTableRef.current) {
            // @ts-ignore
            guestsTableRef.current.refreshContents(statelessShowFlagged);
        }
    }

    const checkAuth = () => {

        let authObject: Auth | null = null

        if (module === 'admin') {
            authObject = authStore.adminAuth
        } else if (module === 'store admin') {
            authObject = authStore.storeAdminAuth
        } else if (module === 'lockbox') {
            authObject = authStore.lockboxAuth
        }

        if (authObject !== null) {
            currentAuthToken = authObject.accessToken
        } else {
            navigate(`/system-login`)
        }
    }

    /**
     * Initiates the first events which happen inside this function
     */
    useEffect(() => {
        document.title = 'Admin Panel - Customers';
        ;(async () => {
            statelessShowFlagged = false
            setShowFlagged(false)
            checkAuth()
            await getLocations()
        })()

    }, [])

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const small = isSmallScreen || isExtraSmallScreen


    return (
        <>
            <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                <CBox m={2}>
                    <CCard sx={{height: "100%", px: "32px"}}>
                        <CGridContainer spacing={2} py={2} display={'flex'} alignItems={'center'}>
                           <CGrid item xs={5.5} md={1.5}>
                                <CTypography sx={{fontFamily: "Passion One", fontSize: small ? '24px' : "32px"}}>
                                    Customers
                                </CTypography>
                            </CGrid>
                            {!small && <CGrid item xs={12} sm={12} md={3} display={'flex'} alignItems={'center'}>
                                <CBox
                                    sx={{flex: 1, display: "flex", flexDirection: "row", alignItems: "center", py: 1}}>
                                    <CTabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <CTab label="Registered" {...a11yProps(0)} />
                                        <CTab label="Guests" {...a11yProps(1)} />
                                    </CTabs>
                                </CBox>
                            </CGrid>}
                            {!small && <CGrid item xs={5}/>}
                            <CGrid item xs={3.5} md={1.7} display={'flex'} alignItems={'center'}>
                                <CButton fullWidth size={small ? 'small' : 'medium'} variant={"contained"} onClick={() => handleClickOpen('add')}>
                                    Add New
                                </CButton>
                            </CGrid>
                            <CGrid item xs={1} md={.3} display={'flex'} alignItems={'center'}>
                                <CIconButton onClick={() => {
                                    refresh()
                                    refreshGuests()
                                }}>
                                    <Refresh color={'primary'}></Refresh>
                                </CIconButton>
                            </CGrid>
                            <CGrid item xs={1} md={.3} display={'flex'} alignItems={'center'}>
                                <CIconButton onClick={() => {
                                    statelessShowFlagged = !statelessShowFlagged
                                    setShowFlagged(statelessShowFlagged)
                                    refresh()
                                    refreshGuests()
                                }}>
                                    <Flag color={showFlagged ? 'error' : 'disabled'}></Flag>
                                </CIconButton>
                            </CGrid>
                            {small && <CGrid item xs={12} sm={12} md={3} display={'flex'} alignItems={'center'}>
                                <CBox
                                    sx={{flex: 1, display: "flex", flexDirection: "row", alignItems: "center", py: 1}}>
                                    <CTabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <CTab label="Registered" {...a11yProps(0)} />
                                        <CTab label="Guests" {...a11yProps(1)} />
                                    </CTabs>
                                </CBox>
                            </CGrid>}
                        </CGridContainer>





                    </CCard>
                </CBox>
                <CBox flexGrow={1} mx={2} mb={2}>
                    <TabPanel style={{height: '100%'}} value={tabValue} index={0}>

                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>

                            <CustomersTable
                                ref={customersTableRef}
                                module={module}
                                onRefreshingChange={setIsRefreshing}
                                handleCustomerDetailsClickOpen={handleCustomerDetailsClickOpen}/>
                        </CCard>
                    </TabPanel>
                    <TabPanel style={{height: '100%'}} value={tabValue} index={1}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <GuestsTable
                                ref={guestsTableRef}
                                module={module}
                                handleCustomerDetailsClickOpen={handleCustomerDetailsClickOpen}
                                onRefreshingChange={setIsRefreshingGuests}
                            />
                        </CCard>
                    </TabPanel>

                </CBox>
            </CBox>

            <CDialog open={userDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                sx: {
                    borderRadius: "37px"
                }
            }}>
                <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                    <CTypography fontSize={"24px"} ml={4}>
                        {userDialog.mode === 'add' ? 'Add Customer' : 'Edit Customer'}
                    </CTypography>
                </CBox>
                <Divider></Divider>
                <CGridContainer spacing={2} p={2}>
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"First Name"}
                                    error={hasValue(validation.firstName)}
                                    helperText={hasValue(validation.firstName) ? validation.firstName : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userFirstName: event.target.value}
                                        });
                                        if (hasValue(validation.firstName)) {
                                            setValidation({...validation, firstName: ''})
                                        }
                                    }} value={userDialog.data.userFirstName}/>
                    </CGrid>
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"Last Name"}
                                    error={hasValue(validation.lastName)}
                                    helperText={hasValue(validation.lastName) ? validation.lastName : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userLastName: event.target.value}
                                        });
                                        if (hasValue(validation.lastName)) {
                                            setValidation({...validation, lastName: ''})
                                        }
                                    }} value={userDialog.data.userLastName}/>
                    </CGrid>
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"Email"}
                                    error={hasValue(validation.email)}
                                    helperText={hasValue(validation.email) ? validation.email : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userEmail: event.target.value}
                                        });
                                        if (hasValue(validation.email)) {
                                            setValidation({...validation, email: ''})
                                        }
                                    }} value={userDialog.data.userEmail}/>
                    </CGrid>
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"Phone Number"}
                                    error={hasValue(validation.phoneNumber)}
                                    helperText={hasValue(validation.phoneNumber) ? validation.phoneNumber : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userPhone: event.target.value}
                                        });
                                        if (hasValue(validation.phoneNumber)) {
                                            setValidation({...validation, phoneNumber: ''})
                                        }
                                    }} value={userDialog.data.userPhone}/>
                    </CGrid>
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"Role"} select
                                    error={hasValue(validation.roleId)}
                                    helperText={hasValue(validation.roleId) ? validation.roleId : null}
                                    onChange={(event) => {
                                        const value = Number(event.target.value);
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, roleId: value, userLocationId: undefined}
                                        });
                                        if (hasValue(validation.roleId)) {
                                            setValidation({...validation, roleId: ''})
                                        }
                                    }} value={userDialog.data.roleId}>
                            <CMenuItem value={1}>Admin</CMenuItem>
                            <CMenuItem value={2}>Kiosk Admin</CMenuItem>
                            <CMenuItem value={5}>Kiosk Client</CMenuItem>
                            <CMenuItem value={3}>Lockbox</CMenuItem>
                            <CMenuItem value={4}>Customer</CMenuItem>
                        </CTextField>
                    </CGrid>
                    {(userDialog.data.roleId === 2 || userDialog.data.roleId === 4 || userDialog.data.roleId === 5) &&
                        <CGrid item xs={3}>
                            <CTextField fullWidth label={"Location"} select
                                        error={hasValue(validation.locationId)}
                                        helperText={hasValue(validation.locationId) ? validation.locationId : null}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            setUserDialog({
                                                ...userDialog,
                                                data: {...userDialog.data, userLocationId: value}
                                            });
                                            if (hasValue(validation.locationId)) {
                                                setValidation({...validation, locationId: ''})
                                            }
                                        }} value={userDialog.data.userLocationId}>
                                {
                                    locations.map(location => <CMenuItem
                                        value={location.locationId ? location.locationId : -1}>{location.locationName}</CMenuItem>)
                                }

                            </CTextField>
                        </CGrid>

                    }
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"Username"}
                                    error={hasValue(validation.userName)}
                                    helperText={hasValue(validation.userName) ? validation.userName : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userUsername: event.target.value}
                                        });
                                        if (hasValue(validation.userName)) {
                                            setValidation({...validation, userName: ''})
                                        }
                                    }} value={userDialog.data.userUsername}/>
                    </CGrid>
                    <CGrid item xs={3}>
                        <CTextField fullWidth label={"Password"}
                                    InputProps={{
                                        type: showPassword ? 'text' : 'password',
                                        endAdornment: <CInputAdornment position="end">
                                            <CIconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </CIconButton>
                                        </CInputAdornment>
                                    }}
                                    error={hasValue(validation.password)}
                                    helperText={hasValue(validation.password) ? validation.password : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userPassword: event.target.value}
                                        });
                                        if (hasValue(validation.password)) {
                                            setValidation({...validation, password: ''})
                                        }
                                    }} value={userDialog.data.userPassword}
                        >

                        </CTextField>
                    </CGrid>
                    <CGrid item xs={12} md={12}>
                        <CTextField fullWidth label={"Notes"}
                                    multiline={true}
                                    rows={4}
                                    onChange={(event) => {
                                        setUserDialog({
                                            ...userDialog,
                                            data: {...userDialog.data, userLocationNote: event.target.value}
                                        });
                                    }}
                            // @ts-ignore
                                    value={userDialog.data.userLocationNote}/>
                    </CGrid>
                </CGridContainer>

                <Divider></Divider>
                <CBox
                    sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                    <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                        Close
                    </CButton>
                    <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                        {userDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                   size={24}/> : userDialog.mode === 'add' ? 'Add' : 'Edit'}
                    </CButton>
                </CBox>
            </CDialog>
            <CDialog open={customerStatisticsDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"}
                     PaperProps={{
                         sx: {
                             borderRadius: "37px"
                         }
                     }}>
                <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                    <CTypography fontSize={"24px"} ml={4}>
                        Statistics
                    </CTypography>
                </CBox>
                <Divider></Divider>
                <CGridContainer spacing={2} p={2}>
                    <CGrid item xs={6} display={'flex'}>
                        <CTypography fontWeight={'bold'} mr={2}>Total Orders:</CTypography>
                        <CTypography>{customerStatistics.total}</CTypography>
                    </CGrid>
                    <CGrid item xs={6} display={'flex'}>
                        <CTypography fontWeight={'bold'} mr={2}>Pending Orders:</CTypography>
                        <CTypography>{customerStatistics.pending}</CTypography>
                    </CGrid>
                </CGridContainer>

                <Divider></Divider>
                <CBox
                    sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                    <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                        Close
                    </CButton>
                </CBox>
            </CDialog>
            <CustomerDialog
                isOpen={isCustomerDialogOpen}
                onClose={() => {
                    setIsCustomerDialogOpen(false)
                }}
                id={customerDialogData.id}
                customerType={customerDialogData.customerType}
                authToken={currentAuthToken}
                module={module}
                onSave={()=>{
                    refresh()
                    refreshGuests()
                }}
            />
        </>
    );
}