import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {LocationModel} from "../location/location";

/**
 * Rick and Morty character model.
 */
export const CustomerModel = types.model("Customer").props({
    customerId: types.maybeNull(types.number),
    customerFname: types.maybeNull(types.string),
    customerLname: types.maybeNull(types.string),
    customerEmail: types.maybeNull(types.string),
    customerPhone: types.maybeNull(types.string),
    customerAddress1: types.maybeNull(types.string),
    customerAddress2: types.maybeNull(types.string),
    customerCity: types.maybeNull(types.string),
    customerState: types.maybeNull(types.string),
    customerZip: types.maybeNull(types.number),
    customerLocationId: types.maybeNull(types.number),
    customerLocationNote: types.maybeNull(types.string),
    customerNote: types.maybeNull(types.string),
    customerCreatedAt: types.maybeNull(types.string),
    customerUpdatedAt: types.maybeNull(types.string),
    customerFlag: types.maybeNull(types.string)
    // location: types.optional(types.maybeNull(LocationModel), {locationId: -1, locationName: ""}),
})

type CustomerType = Instance<typeof CustomerModel>
export interface Customer extends CustomerType {}
type CustomerSnapshotType = SnapshotOut<typeof CustomerModel>
export interface CustomerSnapshot extends CustomerSnapshotType {}

