import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Rick and Morty character model.
 */
export const GatewayModel = types.model("Gateway").props({
    gatewayId: types.number,
    gatewayName: types.maybeNull(types.string),
    gatewayType: types.maybeNull(types.string),
    gatewayData: types.maybeNull(types.string),
    gatewayDescriptions: types.maybeNull(types.string),
    gatewayCreatedAt: types.maybeNull(types.string),
    gatewayUpdatedAt: types.maybeNull(types.string),
})

type GatewayType = Instance<typeof GatewayModel>
export interface Gateway extends GatewayType {}
type GatewaySnapshotType = SnapshotOut<typeof GatewayModel>
export interface GatewaySnapshot extends GatewaySnapshotType {}

export const defaultGateway = {
    gatewayId: undefined,
    gatewayName: '',
    gatewayType: 'Payeezy',
    gatewayData: '',
    gatewayDescriptions: ''
}