import * as React from "react";
import {observer} from "mobx-react-lite";
import {SummaryScreenContent} from "../../../sections/summary-screen-content";


export const AdminPanelSummary = observer(
    function AdminPanelSummary() {
        return (
            <SummaryScreenContent
                module={'admin'}
            />
        );
    }
)
