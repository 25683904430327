// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCCzq3G9J8lkO2AAU8WDyDhzBah09MS81g",
    authDomain: "pos-sku.firebaseapp.com",
    projectId: "pos-sku",
    storageBucket: "pos-sku.appspot.com",
    messagingSenderId: "410156079181",
    appId: "1:410156079181:web:d327523cd9d5a3bfc32404",
    measurementId: "G-DTTCC032MX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);