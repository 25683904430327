import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {LocationSettingsApi} from "../../services/api/location-settings-api"
import {LocationSettingModel, LocationSettingSnapshot} from "../location-setting/location-setting"
import {logInfo} from "../../utils/logs"
import {
    LocationSettingCreateParams,
    LocationSettingDeleteParams,
    LocationSettingFindAllParams,
    LocationSettingFindOneParams,
    LocationSettingUpdateParams
} from "../../services/api";

function locationSettingStoreLog(functionName, message) {
    logInfo({
        fileName: "locationSetting-store.ts",
        className: "locationSettingStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const LocationSettingStoreModel = types
    .model("LocationSettingStore")
    .props({
        locationSetting: types.optional(types.maybeNull(LocationSettingModel), null),
        locationSettings: types.optional(types.array(LocationSettingModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveLocationSetting: (locationSettingSnapshot: LocationSettingSnapshot) => {
            try {
                self.locationSetting = locationSettingSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    locationSettingStoreLog("saveLocationSetting", e.message)
                }
                // recordError(e)
            }
        },
        saveLocationSettings: (locationSettingsSnapshot: LocationSettingSnapshot[]) => {
            try {
                self.locationSettings = cast([...locationSettingsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    locationSettingStoreLog("saveLocationSetting", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: LocationSettingCreateParams, token: string) {
            self.environment.addAuth(token)
            const locationSettingApi = new LocationSettingsApi(self.environment.api)
            const result = yield locationSettingApi.create(params)


            if (result.kind === "ok") {
                self.saveLocationSetting(result.locationSetting)
            } else {
                locationSettingStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: LocationSettingFindAllParams, token: string) {
            self.environment.addAuth(token)
            const locationSettingApi = new LocationSettingsApi(self.environment.api)
            const result = yield locationSettingApi.findAll(params)


            if (result.kind === "ok") {
                self.saveLocationSettings(result.locationSetting)
            } else {
                locationSettingStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: LocationSettingFindOneParams, token: string) {
            self.environment.addAuth(token)
            const locationSettingApi = new LocationSettingsApi(self.environment.api)
            const result = yield locationSettingApi.findOne(params)


            if (result.kind === "ok") {
                self.saveLocationSetting(result.locationSetting)
            } else {
                locationSettingStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: LocationSettingUpdateParams, token: string) {
            self.environment.addAuth(token)
            const locationSettingApi = new LocationSettingsApi(self.environment.api)
            const result = yield locationSettingApi.update(params)


            if (result.kind === "ok") {
                self.saveLocationSetting(result.locationSetting)
            } else {
                locationSettingStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: LocationSettingDeleteParams, token: string) {
            self.environment.addAuth(token)
            const locationSettingApi = new LocationSettingsApi(self.environment.api)
            return yield locationSettingApi.remove(params)
        }),

    }))

type LocationSettingStoreType = Instance<typeof LocationSettingStoreModel>

export interface LocationSettingStore extends LocationSettingStoreType {
}

type LocationSettingStoreSnapshotType = SnapshotOut<typeof LocationSettingStoreModel>

export interface LocationSettingStoreSnapshot extends LocationSettingStoreSnapshotType {
}

export const createLocationSettingStoreDefaultModel = () => types.optional(LocationSettingStoreModel, {})
