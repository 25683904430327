import * as React from "react";
import dayjs, {Dayjs} from "dayjs";
import {observer} from "mobx-react-lite";
import {CButton, CGrid, CGridContainer, CTab, CTabs, CTextField, CTypography, POSTitle} from "../mui-extracted";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {CalendarMonth} from "@mui/icons-material";
import { a11yProps } from "../../utils/tab";

interface SummaryHeaderProps {
    tabValue: number,
    handleChange: (event: React.SyntheticEvent, newValue: number) => void,
    summaryDialogToDate: Dayjs | null,
    summaryDialogFromDate: Dayjs | null,
    setSummaryDialogFromDate: (date: Dayjs | null) => void,
    setSummaryDialogToDate: (date: Dayjs | null) => void,
    getSummary: () => void,
    clearSummary: () => void

}

export const SummaryHeader = observer(function SummaryHeader({
                                                                 tabValue,
                                                                 handleChange,
                                                                 summaryDialogToDate,
                                                                 summaryDialogFromDate,
                                                                 setSummaryDialogFromDate,
                                                                 setSummaryDialogToDate,
                                                                 getSummary,
                                                                 clearSummary
                                                             }: SummaryHeaderProps) {

    return (
        <CGridContainer sx={{width: "100%", p: 2, pr: 0}} spacing={2}>
            <CGrid item xs={12} md={6}>
                <CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <CTab label="Statistics" {...a11yProps(0)} />
                    <CTab label="Orders" {...a11yProps(1)} />
                </CTabs>
            </CGrid>
            {/*<CGrid item xs={12} md={1} display={'flex'} alignItems={'center'}>*/}
            {/*    <CTypography variant={'body2'}>Date Range:</CTypography>*/}
            {/*</CGrid>*/}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <>
                    <CGrid item xs={6} md={2} display={'flex'} alignItems={'center'}>
                        <MobileDatePicker
                            label="From Date"
                            inputFormat="MM/DD/YYYY"
                            maxDate={summaryDialogToDate ? summaryDialogToDate : dayjs()}
                            value={summaryDialogFromDate}
                            onChange={(newValue: Dayjs | null) => {
                                // console.log('From Date', newValue)
                                if (newValue) {
                                    setSummaryDialogFromDate(newValue.set('hour', 0).set('minute', 0).set('second', 0))
                                    // statelessSummaryDialogFromDate = newValue.set('hour', 0).set('minute', 0).set('second', 0)
                                    // getSummary()
                                }
                            }}
                            renderInput={(params) =>
                                <CTextField fullWidth size={"small"} {...params}/>}
                        />
                    </CGrid>
                    <CGrid item xs={6} md={2} display={'flex'} alignItems={'center'}>
                        <MobileDatePicker
                            label="To Date"
                            inputFormat="MM/DD/YYYY"
                            maxDate={dayjs()}
                            minDate={summaryDialogFromDate ? summaryDialogFromDate : undefined}
                            value={summaryDialogToDate}
                            onChange={(newValue: Dayjs | null) => {
                                if (newValue) {
                                    setSummaryDialogToDate(newValue.set('hour', 23).set('minute', 59).set('second', 59))
                                    // statelessSummaryDialogToDate = newValue.set('hour', 23).set('minute', 59).set('second', 59)
                                    // getSummary()
                                }
                            }}
                            renderInput={(params) =>
                                <CTextField fullWidth size={"small"} {...params}/>}
                        />
                    </CGrid>
                    <CGrid item xs={12} md={1} display={'flex'} alignItems={'center'}>
                        <CButton
                            fullWidth
                            disabled={summaryDialogFromDate === null || summaryDialogToDate === null}
                            variant={"contained"}
                            startIcon={<CalendarMonth/>}
                            onClick={() => {
                                // setSummaryDialogFromDate(dayjs().subtract(2, 'days'))
                                // setSummaryDialogToDate(dayjs())
                                // statelessSummaryDialogFromDate = dayjs().subtract(2, 'days')
                                // statelessSummaryDialogToDate = dayjs()
                                getSummary()
                            }}
                        >
                            Show
                        </CButton>
                    </CGrid>
                    <CGrid item xs={12} md={1} display={'flex'} alignItems={'center'}>
                        <CButton fullWidth variant={"outlined"} onClick={() => {
                            setSummaryDialogFromDate(null)
                            setSummaryDialogToDate(null)
                            // statelessSummaryDialogFromDate = null
                            // statelessSummaryDialogToDate = null
                            clearSummary()
                        }}>
                            Clear
                        </CButton>
                    </CGrid>
                </>
            </LocalizationProvider>

        </CGridContainer>
    )
})
