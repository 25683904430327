import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox, CConfirmationDialog,
    CDrawer,
    CList,
    CListItemButton,
    CListItemIcon,
    PanelDrawerItem
} from "../components";
import {Navigate, Route, Routes, useLocation, useMatch, useNavigate} from "react-router-dom";
import {
    CategoryOutlined,
    ChevronLeft,
    ChevronRight,
    LocationOnOutlined,
    LogoutOutlined,
    Person,
    ReceiptLong, Report,
    SettingsOutlined,
    Summarize,
    VapingRooms, ViewCarousel, ViewCarouselOutlined,
    Wallet,
    Warehouse,
} from "@mui/icons-material";
import {CSSObject, Theme, useMediaQuery, useTheme} from "@mui/material";
import {useStores} from "../models";
import {useState} from "react";
import {
    StorePanelCustomers,
    StorePanelInventory,
    StorePanelLockboxes,
    StorePanelOrders, StorePanelPayments,
    StorePanelSettings,
    StorePanelSKU
} from "../screens";
import {StorePanelSummary} from "../screens/store-panel/store-panel-summary/store-panel-summary-screen";
import { StorePanelBundles } from "../screens/store-panel/store-panel-bundles/store-panel-bundles-screen";
import {StorePanelBanners} from "../screens/store-panel/store-panel-banners/store-panel-banners-screen";
import {StorePanelSalesReport} from "../screens/store-panel/store-panel-sales-report/store-panel-sales-report-screen";

export const StorePanelNavigator = observer(
    function PanelNavigator() {

        const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

        const [open, setOpen] = useState(!isSmallScreen);
        const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

        const {authStore} = useStores()
        // const {logout} = authStore

        const match = useMatch("/store-admin-panel/*");
        // console.log("match", match)
        const url = match?.pathnameBase
        const routeMatch = useMatch("*");
        const routeUrl = routeMatch?.pathnameBase
        const theme = useTheme();
        const navigate = useNavigate();
        const location = useLocation();


        // let isDashboardOpen = location.pathname.includes("panel/dashboard");
        // let isClaimsPageOpen = location.pathname.includes("panel/claims");
        // let isPaymentsPageOpen = location.pathname.includes("panel/payments");

        const openedMixin = (theme: Theme): CSSObject => ({
            width: 260,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.down('sm')]: {
                width: window.innerWidth - 24
            },
        });

        const closedMixin = (theme: Theme): CSSObject => ({
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: `calc(${theme.spacing(7)} + 3px)`,
            [theme.breakpoints.up('sm')]: {
                width: `calc(${theme.spacing(8)} + 3px)`,
            },
        });

        const mainOpenedMixin = (theme: Theme): CSSObject => ({
            width: window.innerWidth - 260,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        });

        const mainClosedMixin = (theme: Theme): CSSObject => ({
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: window.innerWidth - 80,
            [theme.breakpoints.up('sm')]: {
                width: window.innerWidth - 80,
            },
        });

        return (
            <>
                <CBox style={{minHeight: '100vh', display: 'flex'}}>
                    <CDrawer variant="permanent"

                             sx={{
                                 ...(open && {
                                     ...openedMixin(theme)
                                 }),
                                 ...(!open && {
                                     ...closedMixin(theme)
                                 }),
                                 mr: 2,
                                 mb: 2
                             }}
                             PaperProps={{
                                 sx: {
                                     margin: '12px',
                                     boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                     borderRadius: '37px',
                                     borderWidth: 0,
                                     height: "auto",
                                     maxHeight: '100vh',
                                     ...(open && {
                                         ...openedMixin(theme)
                                     }),
                                     ...(!open && {
                                         ...closedMixin(theme)
                                     }),
                                 }
                             }}>
                        <CList>
                            <CListItemButton
                                key={"Chev"}
                                onClick={() => setOpen(!open)}
                                sx={{
                                    m: 1,
                                    borderRadius: 4,
                                }}
                            >
                                <CListItemIcon sx={{color: theme => theme.palette.primary.light}}>
                                    {open ? <ChevronLeft/> : <ChevronRight/>}
                                </CListItemIcon>
                            </CListItemButton>
                            <PanelDrawerItem
                                text={"Orders"}
                                icon={<ReceiptLong/>}
                                onClick={() => navigate(`${url}/orders`)}
                                selected={location.pathname === `${url}/orders`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Summary"}
                                icon={<Summarize/>}
                                onClick={() => navigate(`${url}/summary`)}
                                selected={location.pathname === `${url}/summary`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Sales Report"}
                                icon={<Report/>}
                                onClick={() => navigate(`${url}/sales-report`)}
                                selected={location.pathname === `${url}/sales-report`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Inventory"}
                                icon={<Warehouse/>}
                                onClick={() => navigate(`${url}/inventory`)}
                                selected={location.pathname === `${url}/inventory`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Payments"}
                                icon={<Wallet/>}
                                onClick={() => navigate(`${url}/payments`)}
                                selected={location.pathname === `${url}/payments`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Customers"}
                                icon={<Person/>}
                                onClick={() => navigate(`${url}/customers`)}
                                selected={location.pathname === `${url}/customers`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Products"}
                                icon={<VapingRooms/>}
                                onClick={() => navigate(`${url}/sku`)}
                                selected={location.pathname === `${url}/sku`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Lockboxes"}
                                icon={<LocationOnOutlined/>}
                                onClick={() => navigate(`${url}/lockboxes`)}
                                selected={location.pathname === `${url}/lockboxes`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Bundles"}
                                icon={<CategoryOutlined/>}
                                onClick={() => navigate(`${url}/bundles`)}
                                selected={location.pathname === `${url}/bundles`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Banners"}
                                icon={<ViewCarouselOutlined/>}
                                onClick={() => navigate(`${url}/banners`)}
                                selected={location.pathname === `${url}/banners`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={"Settings"}
                                icon={<SettingsOutlined/>}
                                onClick={() => navigate(`${url}/settings`)}
                                selected={location.pathname === `${url}/settings`}
                                open={open}
                            />
                            <PanelDrawerItem
                                text={`Logout (${authStore?.storeAdminAuth?.userUsername})`}
                                icon={<LogoutOutlined/>}
                                onClick={() => {
                                    setIsLogoutDialogOpen(true)
                                }}
                                selected={false}
                                open={open}
                            />
                        </CList>

                    </CDrawer>
                    <CBox component={'main'} sx={{
                        ...(open && {
                            ...mainOpenedMixin(theme)
                        }),
                        ...(!open && {
                            ...mainClosedMixin(theme)
                        }),
                    }}>
                        <Routes>
                            <Route path={`${routeUrl}/`} element={<Navigate to={`${routeUrl}/inventory`}/>}/>
                            <Route path={`${routeUrl}/sku`} element={<StorePanelSKU/>}/>
                            <Route path={`${routeUrl}/inventory`} element={<StorePanelInventory/>}/>
                            <Route path={`${routeUrl}/orders`} element={<StorePanelOrders/>}/>
                            <Route path={`${routeUrl}/summary`} element={<StorePanelSummary/>}/>
                            <Route path={`${routeUrl}/payments`} element={<StorePanelPayments/>}/>
                            <Route path={`${routeUrl}/lockboxes`} element={<StorePanelLockboxes/>}/>
                            <Route path={`${routeUrl}/settings`} element={<StorePanelSettings/>}/>
                            <Route path={`${routeUrl}/customers`} element={<StorePanelCustomers/>}/>
                            <Route path={`${routeUrl}/bundles`} element={<StorePanelBundles/>}/>
                            <Route path={`${routeUrl}/banners`} element={<StorePanelBanners/>}/>
                            <Route path={`${routeUrl}/sales-report`} element={<StorePanelSalesReport/>}/>
                            {/*<Route path={`${routeUrl}/promotions`} element={<StorePanelPromotions/>}/>*/}

                        </Routes>
                    </CBox>

                </CBox>

                <CConfirmationDialog
                    text={"Are you sure you want to logout?"}
                    onAccept={() => {
                        authStore.removeStoreAdminAuth()
                        navigate(`/system-login`)
                    }}
                    acceptTitle={"Logout"}
                    declineTitle={"Close"}
                    onDecline={() => setIsLogoutDialogOpen(false)}
                    open={isLogoutDialogOpen}
                />
            </>
        );
    }
)
