import {cast, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {LocationTypesApi} from "../../services/api/location-types-api"
import {LocationTypeModel, LocationTypeSnapshot} from "../location-type/location-type"
import {logInfo} from "../../utils/logs"
import {
    LocationTypeCreateParams,
    LocationTypeDeleteParams,
    LocationTypeFindAllParams,
    LocationTypeFindOneParams,
    LocationTypeUpdateParams
} from "../../services/api";

function locationTypeStoreLog(functionName, message) {
    logInfo({
        fileName: "locationType-store.ts",
        className: "locationTypeStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const LocationTypeStoreModel = types
    .model("LocationTypeStore")
    .props({
        locationType: types.optional(types.maybeNull(LocationTypeModel), null),
        locationTypes: types.optional(types.array(LocationTypeModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveLocationType: (locationTypeSnapshot: LocationTypeSnapshot) => {
            try {
                self.locationType = locationTypeSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    locationTypeStoreLog("saveLocationType", e.message)
                }
                // recordError(e)
            }
        },
        saveLocationTypes: (locationTypesSnapshot: LocationTypeSnapshot[]) => {
            try {
                self.locationTypes = cast([...locationTypesSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    locationTypeStoreLog("saveLocationType", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: LocationTypeCreateParams, token: string) {
            self.environment.addAuth(token)
            const locationTypeApi = new LocationTypesApi(self.environment.api)
            const result = yield locationTypeApi.create(params)


            if (result.kind === "ok") {
                self.saveLocationType(result.locationType)
            } else {
                locationTypeStoreLog("login", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: LocationTypeFindAllParams, token: string) {
            self.environment.addAuth(token)
            const locationTypeApi = new LocationTypesApi(self.environment.api)
            const result = yield locationTypeApi.findAll(params)


            if (result.kind === "ok") {
                self.saveLocationTypes(result.locationType)
            } else {
                locationTypeStoreLog("login", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: LocationTypeFindOneParams, token: string) {
            self.environment.addAuth(token)
            const locationTypeApi = new LocationTypesApi(self.environment.api)
            const result = yield locationTypeApi.findOne(params)


            if (result.kind === "ok") {
                self.saveLocationType(result.locationType)
            } else {
                locationTypeStoreLog("login", result.kind)
            }
            return result
        }),
        update: flow(function* (params: LocationTypeUpdateParams, token: string) {
            self.environment.addAuth(token)
            const locationTypeApi = new LocationTypesApi(self.environment.api)
            const result = yield locationTypeApi.update(params)


            if (result.kind === "ok") {
                self.saveLocationType(result.locationType)
            } else {
                locationTypeStoreLog("login", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: LocationTypeDeleteParams, token: string) {
            self.environment.addAuth(token)
            const locationTypeApi = new LocationTypesApi(self.environment.api)
            return yield locationTypeApi.remove(params)
        }),

    }))

type LocationTypeStoreType = Instance<typeof LocationTypeStoreModel>

export interface LocationTypeStore extends LocationTypeStoreType {
}

type LocationTypeStoreSnapshotType = SnapshotOut<typeof LocationTypeStoreModel>

export interface LocationTypeStoreSnapshot extends LocationTypeStoreSnapshotType {
}

export const createLocationTypeStoreDefaultModel = () => types.optional(LocationTypeStoreModel, {})
