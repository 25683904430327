import {
    CButton,
    CCircularProgress,
    CFormControl,
    CGrid,
    CGridContainer,
    CIconButton,
    CTextField,
    CTypography
} from "../mui-extracted";
import {CalendarMonth, ClearAll, Refresh, Search} from "@mui/icons-material";
import * as React from "react";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {Dayjs} from "dayjs";
import {observer} from "mobx-react-lite";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

interface PaymentsHeaderProps {
    refresh: () => void,
    orderNoFilter: string,
    setOrderNoFilter: (value: string) => void,
    transIdFilter: string,
    setTransIdFilter: (value: string) => void,
    fromDate: Dayjs | null,
    setFromDate: (value: Dayjs | null) => void,
    toDate: Dayjs | null,
    setToDate: (value: Dayjs | null) => void,
    cardNoFilter: string,
    setCardNoFilter: (value: string) => void,
    isRefreshing: boolean
}

export const PaymentsHeader = observer(function PaymentsHeader({
                                                                   refresh,
                                                                   orderNoFilter,
                                                                   setOrderNoFilter,
                                                                   transIdFilter,
                                                                   setTransIdFilter,
                                                                   fromDate,
                                                                   setFromDate,
                                                                   toDate,
                                                                   setToDate,
                                                                   cardNoFilter,
                                                                   setCardNoFilter,
                                                                   isRefreshing
                                                               }: PaymentsHeaderProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CGridContainer spacing={2} py={2}>
                <CGrid item xs={10} md={2}>
                    <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                        Payments
                    </CTypography>
                </CGrid>
                <CGrid item xs={2} md={10}>
                    <CIconButton onClick={refresh}>
                        {isRefreshing ? <CCircularProgress
                            sx={{color: "primary"}}
                            size={20}/> : <Refresh color={'primary'}/>}
                    </CIconButton>
                </CGrid>
                <CGrid item xs={12} md={1.5}>
                <CFormControl fullWidth>

                    <MobileDatePicker
                        label="From Date"
                        inputFormat="MM/DD/YYYY"
                        value={fromDate}

                        onChange={(newValue: Dayjs | null) => {
                            setFromDate(newValue)
                        }}
                        renderInput={(params) =>
                            <CTextField  size={"small"} {...params} />}
                    />
                </CFormControl>
                </CGrid>
                <CGrid item xs={12} md={1.5}>
                <CFormControl fullWidth>

                    <MobileDatePicker
                        label="To Date"
                        inputFormat="MM/DD/YYYY"
                        value={toDate}

                        onChange={(newValue: Dayjs | null) => {
                            setToDate(newValue)
                        }}
                        renderInput={(params) =>
                            <CTextField  size={"small"} {...params}  />}
                    />
                </CFormControl>
                </CGrid>
                <CGrid item xs={10} md={2}>
                    <CTextField label={"Order No"}
                                type={"number"}
                                size={"small"}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOrderNoFilter(event.target.value)
                                }} value={orderNoFilter}/>
                </CGrid>
                <CGrid item xs={10} md={2}>
                    <CTextField label={"Card No (Last 4)"}
                                type={"number"}
                                size={"small"}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCardNoFilter(event.target.value)
                                }} value={cardNoFilter}/>
                </CGrid>
                <CGrid item xs={10} md={2}>
                    <CTextField label={"Transaction Id"}
                                type={"number"}
                                size={"small"}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setTransIdFilter(event.target.value)
                                }} value={transIdFilter}/>
                </CGrid>

                <CGrid item xs={6} md={1.5} display={'flex'} alignItems={'center'}>
                    <CButton fullWidth variant={"contained"} onClick={() => {
                        setOrderNoFilter('')
                        setTransIdFilter('')
                        setFromDate(null)
                        setToDate(null)
                        setCardNoFilter('')
                        setTimeout(() => {
                            refresh()
                        }, 100)
                    }} startIcon={<ClearAll/>}>
                        Clear
                    </CButton>
                </CGrid>
                <CGrid item xs={6} md={1.5} display={'flex'} alignItems={'center'}>
                    <CButton fullWidth variant={"contained"} onClick={() => {
                        refresh()
                    }} startIcon={<Search/>}>
                        Search
                    </CButton>
                </CGrid>
            </CGridContainer>
        </LocalizationProvider>
    )
})