import {
    CBox,
    CButton,
    CCircularProgress, CDialog,
    CDivider,
    CGrid,
    CGridContainer,
    CTextField,
    CTypography
} from "../mui-extracted";
import {hasValue} from "../../utils/empty-check";
import * as React from "react";
import {isEmpty} from "validate.js";
import {useStores} from "../../models";

export const SkuVarietyDialog = (props) => {

    const {
        skuVarietyDialog,
        setSkuVarietyDialog,
        setSkuVarietiesDialog,
        skuVarietiesDialog,
        currentAuthToken
    } = props;

    const {skuStore} = useStores()

    const [editVarietyValidation, setEditVarietyValidation] = React.useState({
        code: '',
        title: '',
    })

    const getSkuVarieties = async (skuId) => {
        await skuStore.findAllVarieties({
            skuParentId: skuId,
            page: 0,
            perPage: 100
        }, currentAuthToken)
    }

    const handleEditSkuVariety = async () => {
        if (!editVarietyValidate()) {
            return
        }

        setEditVarietyValidation({
            code: '',
            title: '',
        })

        setSkuVarietyDialog({
            ...skuVarietyDialog,
            isLoading: true
        })

        await skuStore.update({
            skuId: Number(skuVarietyDialog.formData.id),
            skuCode: skuVarietyDialog.formData.code?.toUpperCase(),
            skuTitle: skuVarietyDialog.formData.title,
        }, currentAuthToken)

        await getSkuVarieties(skuVarietiesDialog.skuId)

        setSkuVarietiesDialog({
            ...skuVarietiesDialog,
            formData: {
                code: '',
                title: '',
            },
            isOpen: true,
            isLoading: false
        })

        setSkuVarietyDialog({
            ...skuVarietyDialog,
            formData: {
                id: null,
                code: '',
                title: '',
            },
            isOpen: false,
            isLoading: false
        })

    }

    const editVarietyValidate = () => {
        const validationObject = {...editVarietyValidation}
        let isValid = true
        if (isEmpty(skuVarietyDialog.formData.id)) {
            isValid = false
        }
        if (isEmpty(skuVarietyDialog.formData.code)) {
            validationObject.code = "This field is required."
            isValid = false
        }
        if (isEmpty(skuVarietyDialog.formData.title)) {
            validationObject.title = "This field is required."
            isValid = false
        }
        setEditVarietyValidation(validationObject)
        return isValid;
    }

    return (
        <CDialog open={skuVarietyDialog.isOpen} maxWidth={"lg"} PaperProps={{
            sx: {
                borderRadius: "37px",
                overflowX: 'hidden'
            }
        }}>
            <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                <CTypography fontSize={"24px"} ml={4} mr={2}>
                    Edit Variety
                </CTypography>
            </CBox>
            <CDivider>

            </CDivider>
            <CGridContainer spacing={2} p={2}>
                <CGrid item xs={6}>
                    <CTextField fullWidth label={"Code"}
                                error={hasValue(editVarietyValidation.code)}
                                helperText={hasValue(editVarietyValidation.code) ? editVarietyValidation.code : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuVarietyDialog({
                                        ...skuVarietyDialog,
                                        formData: {
                                            ...skuVarietyDialog.formData,
                                            code: event.target.value
                                        }
                                    });
                                    if (hasValue(editVarietyValidation.code)) {
                                        setEditVarietyValidation({...editVarietyValidation, code: ''})
                                    }
                                }} value={skuVarietyDialog.formData.code}/>
                </CGrid>
                <CGrid item xs={6}>
                    <CTextField fullWidth label={"Title"}
                                error={hasValue(editVarietyValidation.title)}
                                helperText={hasValue(editVarietyValidation.title) ? editVarietyValidation.title : null}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSkuVarietyDialog({
                                        ...skuVarietyDialog,
                                        formData: {
                                            ...skuVarietyDialog.formData,
                                            title: event.target.value
                                        }
                                    });
                                    if (hasValue(editVarietyValidation.title)) {
                                        setEditVarietyValidation({...editVarietyValidation, title: ''})
                                    }
                                }} value={skuVarietyDialog.formData.title}/>
                </CGrid>
                <CGrid item xs={12}>
                    <CDivider></CDivider>

                </CGrid>
                <CGrid item xs={12} display={'flex'} justifyContent={'flex-end'}>

                    <CButton variant={"outlined"} onClick={() => {
                        setSkuVarietyDialog({
                            isOpen: false,
                            formData: {
                                id: null,
                                title: '',
                            },
                            isLoading: false
                        })
                        setSkuVarietiesDialog({
                            ...skuVarietiesDialog,
                            isOpen: true
                        })
                    }} sx={{mr: 2, height: '100%'}}>
                        Close
                    </CButton>
                    <CButton variant={"contained"} onClick={handleEditSkuVariety} sx={{height: '100%'}}>
                        {skuVarietyDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                         size={24}/> : 'Save'}
                    </CButton>
                </CGrid>
            </CGridContainer>
        </CDialog>
    )
}