import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard, CCircularProgress,
    CDialog, CGrid,
    CGridContainer,
    CTextField,
    CTypography
} from "../../../components";
import {GridApi, GridCellValue, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {StripedDataGrid, Divider} from "../../../components";
import {ConfigCreateParams, ConfigUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultConfig} from "../../../models/config/config";
import {isEmpty} from "validate.js";
import {useEffect} from "react";
import {Refresh} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import {hasValue} from "../../../utils/empty-check";
import {useNavigate} from "react-router-dom";

interface ConfigDialogData {
    isOpen: boolean,
    data: ConfigCreateParams | ConfigUpdateParams,
    mode: string,
    isLoading: boolean
}

let currentAuthToken = ''

export const AdminPanelConfigs = observer(
    function AdminPanelConfigs() {
        const navigate = useNavigate();

        const {authStore, configStore} = useStores()
        const {configs} = configStore

        const [configDialog, setConfigDialog] = React.useState<ConfigDialogData>({
            isOpen: false,
            data: defaultConfig,
            mode: 'add',
            isLoading: false
        });
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        const [paginationInfo, setPaginationInfo] = React.useState({
            page: 0,
            perPage: 100
        })
        const [tablePageSize, setTablePageSize] = React.useState(25)
        const [validation, setValidation] = React.useState({
            slug: '',
            value: ''
        })

        const handleClickOpen = (mode: string, config?: ConfigCreateParams | ConfigUpdateParams) => {
            setConfigDialog({
                ...configDialog,
                isOpen: true,
                mode,
                data: config ? {...config} : defaultConfig
            });
        };

        const handleClose = () => {
            setConfigDialog({isOpen: false, data: defaultConfig, mode: 'add', isLoading: false});
        };

        const handleSubmit = async () => {
            try {


                if (!validate()) {
                    return
                }


                setConfigDialog({
                    ...configDialog,
                    isLoading: true
                });

                if (configDialog.mode === 'add') {
                    await configStore.create({
                        ...configDialog.data
                    }, currentAuthToken)
                } else if (configDialog.mode === 'edit') {
                    await configStore.update({
                        ...configDialog.data
                    }, currentAuthToken)
                }

                await refresh()

                handleClose()
            } catch (e) {
                console.log(e)
            }
        };

        const handleRemove = async (config) => {
            await configStore.remove({configId: config.configId}, currentAuthToken)
            await refresh()
        }

        const validate = () => {
            const validationObject = {...validation}
            let isValid = true
            if(isEmpty(configDialog.data.configSlug)) {
                validationObject.slug = "This field is required."
                isValid = false
            }
            if(isEmpty(configDialog.data.configValue)) {
                validationObject.value = "This field is required."
                isValid = false
            }
            setValidation(validationObject)
            return isValid;
        }

        const refresh = async () => {
            setIsRefreshing(true)

            await configStore.findAll({
                page: paginationInfo.page,
                perPage: paginationInfo.perPage
            }, currentAuthToken)
            setIsRefreshing(false)
        }

        const handlePageSizeChange = async (pageSize, details) => {
            setTablePageSize(pageSize)
            /// It's for pro plan
            // setPaginationInfo({
            //     ...paginationInfo,
            //     perPage: pageSize
            // })
            // await refresh()
        }

        const checkAuth = () => {
            if(authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Configs';
            ;(async () => {
                checkAuth()
                await refresh()
            })()

        }, [])

        const rows: GridRowsProp = configs;

        const columns: GridColDef[] = [
            {
                field: 'configSlug',
                headerName: 'Slug',
                width: 256,
                headerClassName: "pos-table-header pos-table-header-first",
                cellClassName: "pos-table-cell pos-table-cell-first"
            },
            {
                field: 'configValue',
                headerName: 'Value',
                flex: 1,
                headerClassName: "pos-table-header",
                cellClassName: "pos-table-cell"
            },
            {
                field: 'col5', headerName: 'Actions', width: 300, headerClassName: "pos-table-header"
                , cellClassName: "pos-table-cell",
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleClickOpen('edit', params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    const onRemoveClick = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        const api: GridApi = params.api;
                        const thisRow: Record<string, GridCellValue> = {};

                        api
                            .getAllColumns()
                            .filter((c) => c.field !== "__check__" && !!c)
                            .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                            );
                        return handleRemove(params.row)
                        // return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <><CButton variant={"contained"} size={"small"} sx={{height: '32px', mr: 2}}
                                      onClick={onClick}>Edit</CButton>

                        <CButton variant={"contained"} color={"error"} size={"small"} sx={{height: '32px'}}
                                 onClick={onRemoveClick}>Remove</CButton>
                    </>;
                }
            },

        ];

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                                <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                    Configs
                                </CTypography>
                            <CButton variant={"contained"} onClick={()=>handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <StripedDataGrid getRowId={(row) => row.configId}
                                             rows={rows}
                                             columns={columns}
                                             getRowClassName={(params) =>
                                                 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                             }
                                // page={paginationInfo.page}
                                // pageSize={paginationInfo.perPage}
                                // onPageChange={handlePageChange}
                                             pageSize={tablePageSize}
                                             onPageSizeChange={handlePageSizeChange}
                                             loading={isRefreshing}
                                             components={{
                                                 LoadingOverlay: LinearProgress,
                                             }}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <CDialog open={configDialog.isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} PaperProps={{
                    sx: {
                        borderRadius: "37px"
                    }
                }}>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", py: 3}}>
                        <CTypography fontSize={"24px"} ml={4}>
                            Add Config
                        </CTypography>
                    </CBox>
                    <Divider></Divider>
                    <CGridContainer spacing={2} p={2}>
                        <CGrid item xs={12}>
                            <CTextField fullWidth label={"Slug"}
                                        error={hasValue(validation.slug)}
                                        helperText={hasValue(validation.slug)?validation.slug:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setConfigDialog({
                                                ...configDialog,
                                                data: {...configDialog.data, configSlug: event.target.value}
                                            });
                                            if(hasValue(validation.slug)) {
                                                setValidation({...validation, slug: ''})
                                            }
                                        }} value={configDialog.data.configSlug}/>
                        </CGrid>
                        <CGrid item xs={12} >
                            <CTextField multiline fullWidth label={"Value"}
                                        maxRows={15}
                                        error={hasValue(validation.value)}

                                        helperText={hasValue(validation.value)?validation.value:null}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setConfigDialog({
                                                ...configDialog,
                                                data: {...configDialog.data, configValue: event.target.value}
                                            });
                                            if(hasValue(validation.value)) {
                                                setValidation({...validation, value: ''})
                                            }
                                        }} value={configDialog.data.configValue}/>

                        </CGrid>
                    </CGridContainer>

                    <Divider></Divider>
                    <CBox sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", py: 3}}>
                        <CButton variant={"outlined"} onClick={handleClose} sx={{mr: 2, minWidth: "100px"}}>
                            Close
                        </CButton>
                        <CButton variant={"contained"} onClick={handleSubmit} sx={{mr: 4, minWidth: "100px"}}>
                            {configDialog.isLoading ? <CCircularProgress sx={{color: "white"}}
                                                                           size={24}/> : configDialog.mode === 'add' ? 'Add' : 'Edit'}
                        </CButton>
                    </CBox>
                </CDialog>
            </>
        );
    }
)
