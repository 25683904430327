import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {PaymentsApi} from "../../services/api/payments-api"
import {PaymentModel, PaymentSnapshot} from "../payment/payment"
import {logInfo} from "../../utils/logs"
import {
    PaymentCreateParams,
    PaymentDeleteParams,
    PaymentFindAllParams,
    PaymentFindOneParams,
    PaymentPurchaseParams,
    PaymentUpdateParams
} from "../../services/api";

function paymentStoreLog(functionName, message) {
    logInfo({
        fileName: "payment-store.ts",
        className: "paymentStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const PaymentStoreModel = types
    .model("PaymentStore")
    .props({
        payment: types.optional(types.maybeNull(PaymentModel), null),
        payments: types.optional(types.array(PaymentModel), []),
        count: types.optional(types.number, 0),
    })
    .extend(withEnvironment)
    .actions((self) => ({
        savePayment: (paymentSnapshot: PaymentSnapshot) => {
            try {
                self.payment = cast(paymentSnapshot)
            } catch (e) {
                if(e instanceof Error) {
                    paymentStoreLog("savePayment", e.message)
                }
                // recordError(e)
            }
        },
        savePayments: (paymentsSnapshot: PaymentSnapshot[]) => {
            try {
                detach(self.payments)
                self.payments = cast([...paymentsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    paymentStoreLog("savePayment", e.message)
                }
                // recordError(e)
            }
        },
        saveCount: (count: number) => {
            try {
                self.count = count
            } catch (e) {
                if(e instanceof Error) {
                    paymentStoreLog("saveCount", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: PaymentCreateParams, token: string) {
            self.environment.addAuth(token)
            const paymentApi = new PaymentsApi(self.environment.api)
            const result = yield paymentApi.create(params)


            if (result.kind === "ok") {
                self.savePayment(result.payment)
            } else {
                paymentStoreLog("create", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: PaymentFindAllParams, token: string, saveToMst: boolean) {
            self.environment.addAuth(token)
            const paymentApi = new PaymentsApi(self.environment.api)
            const result = yield paymentApi.findAll(params)


            if (result.kind === "ok") {
                if (saveToMst) {
                    self.savePayments(result.payments)
                    self.saveCount(result.count)
                }
            } else {
                paymentStoreLog("findAll", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: PaymentFindOneParams, token: string) {
            self.environment.addAuth(token)
            const paymentApi = new PaymentsApi(self.environment.api)
            const result = yield paymentApi.findOne(params)


            if (result.kind === "ok") {
                self.savePayment(result.payment)
            } else {
                paymentStoreLog("findOne", result.kind)
            }
            return result
        }),
        update: flow(function* (params: PaymentUpdateParams, token: string) {
            self.environment.addAuth(token)
            const paymentApi = new PaymentsApi(self.environment.api)
            const result = yield paymentApi.update(params)


            if (result.kind === "ok") {
                self.savePayment(result.payment)
            } else {
                paymentStoreLog("update", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: PaymentDeleteParams, token: string) {
            self.environment.addAuth(token)
            const paymentApi = new PaymentsApi(self.environment.api)
            return yield paymentApi.remove(params)
        }),
        purchase: flow(function* (params: PaymentPurchaseParams, token: string) {
            self.environment.addAuthWithTimeout(token, 15000)
            const paymentApi = new PaymentsApi(self.environment.api)
            return yield paymentApi.purchase(params)
        }),
    }))

type PaymentStoreType = Instance<typeof PaymentStoreModel>

export interface PaymentStore extends PaymentStoreType {
}

type PaymentStoreSnapshotType = SnapshotOut<typeof PaymentStoreModel>

export interface PaymentStoreSnapshot extends PaymentStoreSnapshotType {
}

// export const createPaymentStoreDefaultModel = () => types.optional(PaymentStoreModel, {})
