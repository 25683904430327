import {useStores} from "../../models";
import * as React from "react";
import dayjs, {Dayjs} from "dayjs";
import {addCurrentTimezone} from "../../utils/date-time";
import {useEffect} from "react";
import {
    CBox,
    CButton,
    CCard,
    CCircularProgress,
    CGrid,
    CGridContainer, CMenuItem,
    CTab,
    CTabs,
    CTextField,
    TabPanel
} from "../../components";
import {a11yProps} from "../../utils/tab";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {CalendarMonth} from "@mui/icons-material";
import {Table} from "../../components/table";
import {useMediaQuery} from "@mui/material";
import {getSkuAvailability} from "../../utils/sku";

interface SalesReportScreenContentProps {
    module: string
}

let statelessSummaryDialogFromDate: Dayjs | null = dayjs().subtract(2, 'days').set('hour', 0).set('minute', 0);
let statelessSummaryDialogToDate: Dayjs | null = dayjs().set('hour', 23).set('minute', 45);
let currentAuthToken = ''

let statelessSummaryDialogPaginationInfo = {
    page: 0,
    perPage: 10
}
let statelessSummaryDialogOrderInfo = {
    orderBy: 'orderCreatedAt',
    order: 'DESC'
}

export const SalesReportScreenContent = ({
                                         module
                                     }: SalesReportScreenContentProps) => {

    const {orderStore, authStore, orderDetailStore} = useStores();

    const [reportsLoading, setReportsLoading] = React.useState(false);
    const [summaryDialogFromDate, setSummaryDialogFromDate] = React.useState<Dayjs | null>(dayjs().subtract(2, 'days').set('hour', 0).set('minute', 0))
    const [summaryDialogToDate, setSummaryDialogToDate] = React.useState<Dayjs | null>(dayjs().set('hour', 23).set('minute', 45))
    const [tabValue, setTabValue] = React.useState(0);
    const [paginationInfo, setPaginationInfo] = React.useState({
        page: 0,
        perPage: 10
    })
    const [orderInfo, setOrderInfo] = React.useState({
        orderBy: 'orderCreatedAt',
        order: 'DESC'
    })

    const handlePageChange = async (details, page) => {
        console.log('handlePageChange', page, details)
        setPaginationInfo({
            ...paginationInfo,
            page
        })
        statelessSummaryDialogPaginationInfo = {
            ...paginationInfo,
            page
        }
        await getReportList()
    }

    const handlePageSizeChange = async (event) => {
        setPaginationInfo({
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        })
        statelessSummaryDialogPaginationInfo = {
            ...paginationInfo,
            perPage: parseInt(event.target.value, 10),
            page: 0
        }
        await getReportList()
    }

    const handleRequestSort = async (
        event: React.MouseEvent<unknown>,
        property,
    ) => {
        const isAsc = statelessSummaryDialogOrderInfo.orderBy === property && statelessSummaryDialogOrderInfo.order === 'ASC';
        setOrderInfo({
            ...orderInfo,
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        })
        statelessSummaryDialogOrderInfo = {
            order: isAsc ? 'DESC' : 'ASC',
            orderBy: property
        }
        if (property === 'sku_quantity') {
            const currentSalesReport = JSON.parse(JSON.stringify(orderDetailStore.salesReport))

            orderDetailStore.saveSalesReport(currentSalesReport.sort((a, b) => {
                if (!isAsc) {
                    return Number(a.sku_quantity) - Number(b.sku_quantity)
                } else {
                    return Number(b.sku_quantity) - Number(a.sku_quantity)
                }
            }))
        }
    };

    const getReportList = async () => {

        if (
            (authStore.storeAdminAuth && authStore.storeAdminAuth?.userLocationId)
        ) {

            setReportsLoading(true)
            const fromDate = statelessSummaryDialogFromDate ? addCurrentTimezone(statelessSummaryDialogFromDate.set('hour', 0).set('minute', 0).set('second', 0)) : null
                const toDate = statelessSummaryDialogToDate ? addCurrentTimezone(statelessSummaryDialogToDate.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999)) : null

            let storeId
            if (module === 'store admin') {
                storeId = authStore.storeAdminAuth?.userLocationId
            }
            if (fromDate && toDate && storeId) {
                console.log('getReportList', fromDate, toDate, storeId, currentAuthToken)
                await orderDetailStore.getSalesReport(fromDate, toDate, storeId, currentAuthToken)
            }

            setReportsLoading(false)
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        ;(async () => {
            if (module === 'store admin' && authStore.storeAdminAuth) {
                currentAuthToken = authStore.storeAdminAuth.accessToken
            } else if (module === 'lockbox' && authStore.lockboxAuth) {
                currentAuthToken = authStore.lockboxAuth.accessToken
            } else if (module === 'admin' && authStore.adminAuth) {
                currentAuthToken = authStore.adminAuth.accessToken
            }
            await getReportList()
        })()

    }, [])

    // @ts-ignore
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // @ts-ignore
    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const columns = [
        {
            field: 'sku_code',
            headerName: 'Code',
            width: 210,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },
        {
            field: 'sku_quantity',
            headerName: 'Quantity',
            width: 120,
            headerClassName: "pos-table-header",
            cellClassName: "pos-table-cell"
        },


    ];

    const tabHeight = module === 'lockbox' ? (isSmallScreen || isExtraSmallScreen) ? '52vh' : '70vh' : (isSmallScreen || isExtraSmallScreen) ? '64vh' : '83vh'
    const statisticsBorderRadius = (isSmallScreen || isExtraSmallScreen) ? 2 : 8

    const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    const minutes = ['00', '15', '30', '45']

    return (
        <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>
            <CBox m={2}>
                <CCard sx={{height: "100%", display: "flex", alignItems: "center"}}>
                    <CGridContainer sx={{width: "100%", p: 2, pr: 0}} spacing={2}>
                        <CGrid item xs={12} md={3}>
                            <CTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                <CTab label="Sales Report" {...a11yProps(0)} />
                            </CTabs>
                        </CGrid>
                        {/*<CGrid item xs={12} md={1} display={'flex'} alignItems={'center'}>*/}
                        {/*    <CTypography variant={'body2'}>Date Range:</CTypography>*/}
                        {/*</CGrid>*/}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <>
                                <CGrid item xs={6} md={2} display={'flex'} alignItems={'center'}>
                                    <MobileDatePicker
                                        label="From Date"
                                        inputFormat="MM/DD/YYYY"
                                        maxDate={summaryDialogToDate ? summaryDialogToDate : dayjs()}
                                        value={summaryDialogFromDate}
                                        onChange={(newValue: Dayjs | null) => {
                                            // console.log('From Date', newValue)
                                            if (newValue) {
                                                setSummaryDialogFromDate(newValue.set('hour', 0).set('minute', 0).set('second', 0))
                                                statelessSummaryDialogFromDate = newValue.set('hour', 0).set('minute', 0).set('second', 0)
                                                // getSummary()
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <CTextField fullWidth size={"small"} {...params}/>}
                                    />
                                </CGrid>
                                {summaryDialogFromDate && <CGrid item xs={3} md={1} display={'flex'} alignItems={'center'}>
                                    <CTextField size={'small'} fullWidth label={"HH"} select
                                                value={summaryDialogFromDate.hour().toString().padStart(2, '0')} onChange={(event) => {
                                        const value = event.target.value;
                                        const currentSelectedPickupDate = summaryDialogFromDate.set('hour', Number(value))
                                        setSummaryDialogFromDate(currentSelectedPickupDate)
                                        statelessSummaryDialogFromDate = currentSelectedPickupDate

                                    }}>
                                        {hours.map(hour => <CMenuItem
                                            // disabled={summaryDialogFromDate?.day() === dayjs().day() ? Number(hour) < dayjs().hour() : false}
                                            value={hour}>{hour}</CMenuItem>)}
                                    </CTextField>
                                </CGrid>}
                                {summaryDialogFromDate && <CGrid item xs={3} md={1} display={'flex'} alignItems={'center'}>
                                    <CTextField size={'small'} fullWidth label={"MM"} select
                                                value={summaryDialogFromDate.minute().toString().padStart(2, '0')}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    const currentSelectedPickupDate = summaryDialogFromDate.set('minutes', Number(value))
                                                    setSummaryDialogFromDate(currentSelectedPickupDate)
                                                    statelessSummaryDialogFromDate = currentSelectedPickupDate

                                                }}>
                                        {minutes.map(minute => <CMenuItem
                                            // disabled={(summaryDialogFromDate?.day() === dayjs().day() && summaryDialogFromDate?.hour() === dayjs().hour()) ?
                                            //     Number(minute) < dayjs().minute() :
                                            //     false}
                                            value={minute}>
                                            {minute}
                                        </CMenuItem>)}
                                    </CTextField>
                                </CGrid>}
                                <CGrid item xs={6} md={2} display={'flex'} alignItems={'center'}>
                                    <MobileDatePicker
                                        label="To Date"
                                        inputFormat="MM/DD/YYYY"
                                        maxDate={dayjs()}
                                        minDate={summaryDialogFromDate ? summaryDialogFromDate : undefined}
                                        value={summaryDialogToDate}
                                        onChange={(newValue: Dayjs | null) => {
                                            if (newValue) {
                                                setSummaryDialogToDate(newValue.set('hour', 23).set('minute', 59).set('second', 59))
                                                statelessSummaryDialogToDate = newValue.set('hour', 23).set('minute', 59).set('second', 59)
                                                // getSummary()
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <CTextField fullWidth size={"small"} {...params}/>}
                                    />
                                </CGrid>
                                {summaryDialogToDate && <CGrid item xs={3} md={1} display={'flex'} alignItems={'center'}>
                                    <CTextField size={'small'} fullWidth label={"HH"} select
                                                value={summaryDialogToDate.hour().toString().padStart(2, '0')} onChange={(event) => {
                                        const value = event.target.value;
                                        const currentSelectedPickupDate = summaryDialogToDate.set('hour', Number(value))
                                        setSummaryDialogToDate(currentSelectedPickupDate)
                                        statelessSummaryDialogToDate = currentSelectedPickupDate

                                    }}>
                                        {hours.map(hour => <CMenuItem
                                            // disabled={summaryDialogToDate?.day() === dayjs().day() ? Number(hour) < dayjs().hour() : false}
                                            value={hour}>{hour}</CMenuItem>)}
                                    </CTextField>
                                </CGrid>}
                                {summaryDialogToDate && <CGrid item xs={3} md={1} display={'flex'} alignItems={'center'}>
                                    <CTextField size={'small'} fullWidth label={"MM"} select
                                                value={summaryDialogToDate.minute().toString().padStart(2, '0')}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    const currentSelectedPickupDate = summaryDialogToDate.set('minutes', Number(value))
                                                    setSummaryDialogToDate(currentSelectedPickupDate)
                                                    statelessSummaryDialogToDate = currentSelectedPickupDate

                                                }}>
                                        {minutes.map(minute => <CMenuItem
                                            // disabled={(summaryDialogToDate?.day() === dayjs().day() && summaryDialogToDate?.hour() === dayjs().hour()) ?
                                            //     Number(minute) < dayjs().minute() :
                                            //     false}
                                            value={minute}>
                                            {minute}
                                        </CMenuItem>)}
                                    </CTextField>
                                </CGrid>}
                                <CGrid item xs={12} md={1} display={'flex'} alignItems={'center'}>
                                    <CButton
                                        fullWidth
                                        disabled={summaryDialogFromDate === null || summaryDialogToDate === null}
                                        variant={"contained"}
                                        startIcon={<CalendarMonth/>}
                                        onClick={() => {
                                            // getSummary()
                                            getReportList()
                                        }}
                                    >
                                        Show
                                    </CButton>
                                </CGrid>
                            </>
                        </LocalizationProvider>

                    </CGridContainer>
                </CCard>
            </CBox>
            <CBox flexGrow={1} mx={2} mb={2}>
                <CCard sx={{
                    flex: 1,
                    height: "100%",
                    '& .pos-table-header': {
                        fontSize: "16px",
                        fontWeight: "400"
                    },
                    '& .pos-table-header-first': {
                        ml: "20px"
                    },
                    '& .pos-table-cell': {
                        color: "#363636"
                    },
                    '& .pos-table-cell-first': {
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "400",
                        ml: "24px"
                    },
                }}>
                    <>

                        <TabPanel value={tabValue} index={0} style={{height: tabHeight, overflowY: 'scroll'}}>
                            {reportsLoading ? <CCircularProgress sx={{m: 4}}/> :
                                <Table
                                    columns={columns}
                                    rows={orderDetailStore.salesReport}
                                    count={orderDetailStore.salesReport?.length}
                                    page={0}
                                    rowsPerPage={orderDetailStore.salesReport?.length}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    orderBy={orderInfo.orderBy}
                                    order={orderInfo.order}
                                    onRequestSort={handleRequestSort}
                                    isLoading={reportsLoading}
                                />}
                        </TabPanel>
                    </>

                </CCard>
            </CBox>
        </CBox>
    )
}