import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CButton,
    CCircularProgress,
    CTypography,
} from "../../../components";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const NotFound = observer(
    function NotFound(props: {redirect?: boolean }) {

        const {redirect} = props;



        const navigate = useNavigate();

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = `404 - Not Found`;
            if (redirect) {
                setTimeout(()=>{
                    navigate('/', {replace: true})
                }, 3000)

            }
        }, [])

        return (
            <>
                <main style={{minHeight: '100vh', minWidth: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                    <CTypography sx={{fontFamily: "Passion One", fontSize: "128px", mr: 2}} color={'primary'}>
                        404
                    </CTypography>
                    {redirect&& <CTypography variant={'subtitle1'}>You will be redirected to the main page in a few seconds.</CTypography>}
                    <CButton variant={"contained"} onClick={()=>{
                        navigate('/', {replace: true})
                    }}>
                        Home
                    </CButton>
                </main>
            </>
        );
    }
)
