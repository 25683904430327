import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    CBox,
    CButton,
    CCard,
    CTypography
} from "../../../components";
import {SkuCreateParams, SkuUpdateParams} from "../../../services/api";
import {useStores} from "../../../models";
import {defaultSku, Sku} from "../../../models/sku/sku";
import {useEffect, useRef} from "react";
import {Refresh} from "@mui/icons-material";
import {SkuImage} from "../../../models/sku-image/sku-image";
import {useNavigate} from "react-router-dom";
import {SkusTable} from "../../../components/skus-table";
import {SkuDialog} from "../../../components/sku-dialog";
import {SkuImagesDialog} from "../../../components/sku-images-dialog";
import {SkuVarietiesDialog} from "../../../components/sku-varieties-dialog";
import {SkuVarietyDialog} from "../../../components/sku-variety-dialog";
import {SkuPricesDialog} from "../../../components/sku-prices-dialog";
import {SkuPriceDialog} from "../../../components/sku-price-dialog";

interface SkuDialogData {
    isOpen: boolean,
    data: SkuCreateParams | SkuUpdateParams,
    mode: string,
    isLoading: boolean
}

interface SkuVarietyDialogData {
    isOpen: boolean,
    formData: {
        id: null | number,
        code: string,
        title: string,
        price: string
    },
    isLoading: boolean
}

interface SkuPriceDialogData {
    isOpen: boolean,
    formData: {
        priceId: null | number,
        skuId: null | number,
        locationId: null | number,
        priceAmount: string
    },
    isLoading: boolean
}

interface SkuImagesDialogData {
    isOpen: boolean,
    data: Array<SkuImage>,
    skuId: number,
    mode: string,
    isLoading: boolean
}

interface SkuVarietiesDialogData {
    isOpen: boolean,
    data: Array<Sku>,
    sku: SkuCreateParams | SkuUpdateParams,
    formData: {
        code: string,
        title: string,
        price: string
    },
    skuId: number,
    isLoading: boolean
}

interface SkuPricesDialogData {
    isOpen: boolean,
    sku: SkuCreateParams | SkuUpdateParams,
    prices: [],
    formData: {
        locationId: string,
        price: string
    },
    skuId: number,
    isLoading: boolean
}

let currentAuthToken = ''

export const AdminPanelSKU = observer(
    function AdminPanelSKU() {
        const navigate = useNavigate();

        const {authStore, skuStore, skuImageStore, categoryStore, vendorStore, locationStore, cogsStore} = useStores()
        const {categories} = categoryStore
        const {vendors} = vendorStore
        const {locations} = locationStore
        const {cogses} = cogsStore

        const skusTableRef = useRef(null);

        const [skuDialog, setSkuDialog] = React.useState<SkuDialogData>({
            isOpen: false,
            data: defaultSku,
            mode: 'add',
            isLoading: false
        });
        const [skuVarietyDialog, setSkuVarietyDialog] = React.useState<SkuVarietyDialogData>({
            isOpen: false,
            formData: {
                id: null,
                code: '',
                title: '',
                price: ''
            },
            isLoading: false
        });
        const [skuPriceDialog, setSkuPriceDialog] = React.useState<SkuPriceDialogData>({
            isOpen: false,
            formData: {
                priceId: null,
                skuId: null,
                locationId: null,
                priceAmount: ''
            },
            isLoading: false
        });
        const [skuImagesDialog, setSkuImagesDialog] = React.useState<SkuImagesDialogData>({
            isOpen: false,
            data: defaultSku.skuImages,
            skuId: -1,
            mode: 'add',
            isLoading: false
        });
        const [skuVarietiesDialog, setSkuVarietiesDialog] = React.useState<SkuVarietiesDialogData>({
            isOpen: false,
            data: [],
            sku: defaultSku,
            formData: {
                code: '',
                title: '',
                price: ''
            },
            skuId: -1,
            isLoading: false
        });

        const [skuPricesDialog, setSkuPricesDialog] = React.useState<SkuPricesDialogData>({
            isOpen: false,
            sku: defaultSku,
            prices: [],
            formData: {
                locationId: '',
                price: ''
            },
            skuId: -1,
            isLoading: false
        });
        const handleClickOpen = (mode: string, sku?: SkuCreateParams | SkuUpdateParams) => {
            setSkuDialog({
                ...skuDialog,
                isOpen: true,
                mode,
                data: sku ? {...sku} : defaultSku
            });
        };

        const handleClickImageOpen = (mode: string, skuImages: Array<SkuImage>, skuId: number) => {
            getSkuImages(skuId)
            setSkuImagesDialog({
                ...skuImagesDialog,
                isOpen: true,
                mode,
                data: skuImages ? [...skuImages] : defaultSku.skuImages,
                skuId
            });
        };

        const handleClickVarietiesOpen = (sku) => {
            getSkuVarieties(sku.skuId)
            setSkuVarietiesDialog({
                ...skuVarietiesDialog,
                isOpen: true,
                data: [],
                sku,
                skuId: sku.skuId
            });
        };

        const handleClickPricesOpen = (sku, prices) => {
            console.log('handleClickPricesOpen', sku, prices)
            setSkuPricesDialog({
                ...skuPricesDialog,
                isOpen: true,
                sku: sku,
                prices: prices,
                skuId: sku.skuId
            });
        };

        const handleClose = () => {
            setSkuDialog({isOpen: false, data: defaultSku, mode: 'add', isLoading: false});
            setSkuImagesDialog({isOpen: false, data: defaultSku.skuImages, skuId: -1, mode: 'add', isLoading: false});
            setSkuVarietiesDialog({
                isOpen: false,
                data: [],
                sku: defaultSku,
                formData: {code: '', title: '', price: ''},
                skuId: -1,
                isLoading: false
            });
            setSkuVarietyDialog({isOpen: false, formData: {id: null, code: '', title: '', price: ''}, isLoading: false})
            setSkuPricesDialog({
                isOpen: false,
                sku: defaultSku,
                prices: [],
                formData: {
                    locationId: '',
                    price: ''
                },
                skuId: -1,
                isLoading: false
            });
        };

        const getSkuImages = async (skuId) => {
            await skuImageStore.findAll({
                skuId,
                page: 0,
                perPage: 100
            }, currentAuthToken)
        }

        const getSkuVarieties = async (skuId) => {
            await skuStore.findAllVarieties({
                skuParentId: skuId,
                page: 0,
                perPage: 100
            }, currentAuthToken)
        }

        const refresh = () => {
            if (skusTableRef.current) {
                // @ts-ignore
                skusTableRef.current.refreshContents();
            }
        }

        const getCategories = async () => {
            await categoryStore.findAll({
                page: 0,
                perPage: 100
            }, currentAuthToken)
        }

        const getVendors = async () => {
            await vendorStore.findAll({
                page: 0,
                perPage: 100
            }, currentAuthToken)
        }

        const getCogs = async () => {
            await cogsStore.findAll({
                page: 0,
                perPage: 100
            }, currentAuthToken)
        }

        const getStores = async () => {
            await locationStore.findAll({
                locationTypeId: 1,
                page: 0,
                perPage: 100
            }, currentAuthToken, true)

        }

        const checkAuth = () => {
            if (authStore.adminAuth !== null) {
                currentAuthToken = authStore.adminAuth.accessToken
            } else {
                navigate(`/system-login`)
            }
        }

        /**
         * Initiates the first events which happen inside this function
         */
        useEffect(() => {
            document.title = 'Admin Panel - Sku';
            ;(async () => {
                checkAuth()
                await refresh()
                await getCategories()
                await getVendors()
                await getCogs()
                await getStores()
            })()

        }, [])


        function createData(
            id: number,
            link: string
        ) {
            return {id, link};
        }

        const itemRows = skuImageStore.skuImages.map(skuImage => createData(skuImage.skuImageId, String(skuImage.skuImageLink)))

        function createVarietyData(
            id: number,
            code: string,
            title: string,
        ) {
            return {id, code, title};
        }

        const varietyItemRows = skuStore.skuVarieties.map(skuVariety => createVarietyData(skuVariety.skuId, String(skuVariety.skuCode), String(skuVariety.skuTitle)))

        return (
            <>
                <CBox sx={{height: "100%", display: "flex", flexDirection: "column"}}>

                    <CBox height={{height: "94px"}} m={2}>
                        <CCard sx={{height: "100%", display: "flex", alignItems: "center", px: "32px"}}>
                            <CTypography sx={{fontFamily: "Passion One", fontSize: "32px", mr: 2}}>
                                SKU
                            </CTypography>
                            <CButton variant={"contained"} onClick={() => handleClickOpen('add')} sx={{mr: 2}}>
                                Add New
                            </CButton>
                            <CButton variant={"contained"} onClick={refresh} startIcon={<Refresh/>}>
                                Refresh
                            </CButton>
                        </CCard>
                    </CBox>
                    <CBox flexGrow={1} mx={2} mb={2}>
                        <CCard sx={{
                            flex: 1,
                            height: "100%",
                            '& .pos-table-header': {
                                fontSize: "20px",
                                fontWeight: "400"
                            },
                            '& .pos-table-header-first': {
                                ml: "24px"
                            },
                            '& .pos-table-cell': {
                                color: "#363636"
                            },
                            '& .pos-table-cell-first': {
                                color: "#000000",
                                fontSize: "20px",
                                fontWeight: "400",
                                ml: "24px"
                            },
                        }}>
                            <SkusTable
                                ref={skusTableRef}
                                handleClickOpen={handleClickOpen}
                                handleClickVarietiesOpen={handleClickVarietiesOpen}
                                handleClickImageOpen={handleClickImageOpen}
                                handleClickPricesOpen={handleClickPricesOpen}
                                module={'admin'}
                            />
                        </CCard>
                    </CBox>
                </CBox>

                <SkuDialog
                    skuDialog={skuDialog}
                    setSkuDialog={setSkuDialog}
                    cogses={cogses}
                    categories={categories}
                    vendors={vendors}
                    currentAuthToken={currentAuthToken}
                    handleClose={handleClose}
                    refresh={refresh}
                />


                <SkuImagesDialog
                    skuImagesDialog={skuImagesDialog}
                    setSkuImagesDialog={setSkuImagesDialog}
                    handleClose={handleClose}
                    itemRows={itemRows}
                    currentAuthToken={currentAuthToken}
                />

                <SkuVarietiesDialog
                    skuVarietiesDialog={skuVarietiesDialog}
                    setSkuVarietiesDialog={setSkuVarietiesDialog}
                    setSkuVarietyDialog={setSkuVarietyDialog}
                    handleClose={handleClose}
                    varietyItemRows={varietyItemRows}
                    currentAuthToken={currentAuthToken}
                />
                <SkuVarietyDialog
                    skuVarietyDialog={skuVarietyDialog}
                    setSkuVarietyDialog={setSkuVarietyDialog}
                    setSkuVarietiesDialog={setSkuVarietiesDialog}
                    skuVarietiesDialog={skuVarietiesDialog}
                    currentAuthToken={currentAuthToken}
                />

                <SkuPricesDialog
                    stores={locations}
                    skuPricesDialog={skuPricesDialog}
                    setSkuPricesDialog={setSkuPricesDialog}
                    skuPriceDialog={skuPriceDialog}
                    setSkuPriceDialog={setSkuPriceDialog}
                    handleClose={handleClose}
                    currentAuthToken={currentAuthToken}
                />

                <SkuPriceDialog
                    skuPriceDialog={skuPriceDialog}
                    setSkuPriceDialog={setSkuPriceDialog}
                    setSkuPricesDialog={setSkuPricesDialog}
                    skuPricesDialog={skuPricesDialog}
                    currentAuthToken={currentAuthToken}
                    module={'admin'}
                />
            </>
        );
    }
)
