import {cast, detach, flow, Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {VendorsApi} from "../../services/api/vendors-api"
import {VendorModel, VendorSnapshot} from "../vendor/vendor"
import {logInfo} from "../../utils/logs"
import {
    VendorCreateParams,
    VendorDeleteParams,
    VendorFindAllParams,
    VendorFindOneParams,
    VendorUpdateParams
} from "../../services/api";

function vendorStoreLog(functionName, message) {
    logInfo({
        fileName: "vendor-store.ts",
        className: "vendorStoreModel",
        functionName,
        message,
    })
}

/**
 * Model description here for TypeScript hints.
 */
export const VendorStoreModel = types
    .model("VendorStore")
    .props({
        vendor: types.optional(types.maybeNull(VendorModel), null),
        vendors: types.optional(types.array(VendorModel), [])
    })
    .extend(withEnvironment)
    .actions((self) => ({
        saveVendor: (vendorSnapshot: VendorSnapshot) => {
            try {
                self.vendor = vendorSnapshot
            } catch (e) {
                if(e instanceof Error) {
                    vendorStoreLog("saveVendor", e.message)
                }
                // recordError(e)
            }
        },
        saveVendors: (vendorsSnapshot: VendorSnapshot[]) => {
            try {
                detach(self.vendors);
                self.vendors = cast([...vendorsSnapshot])
            } catch (e) {
                if(e instanceof Error) {
                    vendorStoreLog("saveVendors", e.message)
                }
                // recordError(e)
            }
        },
    }))
    .actions((self) => ({
        create: flow(function* (params: VendorCreateParams, token: string) {
            self.environment.addAuth(token)
            const vendorApi = new VendorsApi(self.environment.api)
            const result = yield vendorApi.create(params)


            if (result.kind === "ok") {
                self.saveVendor(result.vendor)
            } else {
                vendorStoreLog("create", result.kind)
            }
            return result
        }),
        findAll: flow(function* (params: VendorFindAllParams, token: string) {
            self.environment.addAuth(token)
            const vendorApi = new VendorsApi(self.environment.api)
            const result = yield vendorApi.findAll(params)


            if (result.kind === "ok") {
                self.saveVendors(result.vendors)
            } else {
                vendorStoreLog("findAll", result.kind)
            }
            return result
        }),
        findOne: flow(function* (params: VendorFindOneParams, token: string) {
            self.environment.addAuth(token)
            const vendorApi = new VendorsApi(self.environment.api)
            const result = yield vendorApi.findOne(params)


            if (result.kind === "ok") {
                self.saveVendor(result.vendor)
            } else {
                vendorStoreLog("findOne", result.kind)
            }
            return result
        }),
        update: flow(function* (params: VendorUpdateParams, token: string) {
            self.environment.addAuth(token)
            const vendorApi = new VendorsApi(self.environment.api)
            const result = yield vendorApi.update(params)


            if (result.kind === "ok") {
                self.saveVendor(result.vendor)
            } else {
                vendorStoreLog("update", result.kind)
            }
            return result
        }),
        remove: flow(function* (params: VendorDeleteParams, token: string) {
            self.environment.addAuth(token)
            const vendorApi = new VendorsApi(self.environment.api)
            return yield vendorApi.remove(params)
        }),

    }))

type VendorStoreType = Instance<typeof VendorStoreModel>

export interface VendorStore extends VendorStoreType {
}

type VendorStoreSnapshotType = SnapshotOut<typeof VendorStoreModel>

export interface VendorStoreSnapshot extends VendorStoreSnapshotType {
}

export const createVendorStoreDefaultModel = () => types.optional(VendorStoreModel, {})
