import * as React from "react";
import {observer} from "mobx-react-lite";
import {CustomersScreenContent} from "../../../sections/customers-screen-content";

export const AdminPanelCustomers = observer(
    function AdminPanelCustomers() {

        return (
            <CustomersScreenContent module={'admin'}/>
        );
    }
)
